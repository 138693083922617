export const createRipple = event => {
  console.log('playing')
  const button = event.currentTarget // click target
  const circle = document.createElement('span') // span used for animation
  const diameter = Math.max(button.clientWidth, button.clientHeight) // animation does not exceed button size
  const radius = diameter / 2 // animation shape

  circle.style.width = circle.style.height = `${diameter}px`
  circle.style.left = `${event.clientX - (button.offsetLeft + radius)}px`
  circle.style.top = `${event.clientY - (button.offsetTop + radius)}px`
  circle.classList.add('ripple') // add keyframe on click

  const ripple = button.getElementsByClassName('ripple')[0]

  if (ripple) {
    ripple.remove()
  }

  button.appendChild(circle)
}

export default createRipple

import React, { useState } from 'react'
import styled from 'styled-components/macro'
import _ from 'lodash'
import PropTypes from 'prop-types'
import 'antd/dist/antd.css'
import { Slider, Divider } from 'antd'

const MultiSlider = ({ array, currentAnswer, updateCurrent, question }) => {
  const [state, setState] = useState(currentAnswer.value ? currentAnswer.value : array)
  if (_.isUndefined(currentAnswer.value)) {
    const defaultValues = {
      ...currentAnswer,
      value: _.keyBy(array, item => item.id),
    }
    updateCurrent(defaultValues)
  }
  return (
    <div>
      {array.map(slider => {
        return (
          <Item key={slider.id}>
            <SliderContainer>
              <Text>{slider.text}</Text>
              <StyledSlider
                step={5}
                key={slider.id}
                value={state[slider.id] ? state[slider.id].value : slider.value}
                onChange={e => {
                  const inputValues = {
                    id: slider.id,
                    text: slider.text,
                    value: e,
                  }
                  const newValues = {
                    ...currentAnswer,
                    value: {
                      ...currentAnswer.value,
                      [slider.id]: inputValues,
                    },
                  }
                  setState(newValues.value)
                  updateCurrent(newValues)
                }}
                tipFormatter={null}
                max={slider.max}
              />
            </SliderContainer>
            <StyledDivider type="vertical" />
            <Container>
              {question.useNumbers ? (
                <Number>{state[slider.id] ? `${state[slider.id].value}` : '0'}</Number>
              ) : (
                <Number>{state[slider.id] ? `${state[slider.id].value}%` : '0%'}</Number>
              )}
              {/* <Number>{state[slider.id] ? `${state[slider.id].value}%` : '0%'}</Number> */}
            </Container>
          </Item>
        )
      })}
    </div>
  )
}

const Item = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  margin-bottom: calc(1vh + 0.5rem);
  border-radius: 3px;
  @media (min-width: 0px) and (max-width: 479px) {
    width: 85vw;
  }
`
const Text = styled.div`
  display: flex;
  flex: 1 1;
  width: 90%;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 15px;
  line-height: 22px;
  padding-left: 12px;
  padding-right: 12px;
`
const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  max-width: 80%;
`

const StyledSlider = styled(Slider)`
  width: 600px;
  @media (min-width: 0px) and (max-width: 479px) {
    width: 55vw;
  }
`

const StyledDivider = styled(Divider)`
  display: flex;
  justify-content: center;
  align-content: center;
  align-self: center;
  height: 50px;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 165px;
  height: inherit;
  align-items: center;
`

const Number = styled.p`
  color: rgba(0, 0, 0, 0.45);
  font-size: 30px;
  line-height: 22px;
  margin-bottom: 0;
  margin-top: 0;
`

MultiSlider.propTypes = {
  currentAnswer: PropTypes.instanceOf(Object),
  updateCurrent: PropTypes.func,
  array: PropTypes.instanceOf(Array),
}

export default MultiSlider

import React from 'react'
import _ from 'lodash'
import { Bar, HorizontalBar } from 'react-chartjs-2'
import PropTypes from 'prop-types'
import 'chartjs-plugin-datalabels'
// import 'chartjs-plugin-labels'

const BarChart = props => {
  const { data, xLabel, yLabel, type, displayLegend, usePercentage, xStepSize } = props
  console.log('data', data)
  const myRef = React.createRef()
  console.log('usePercentage', usePercentage)
  console.log('props', props)
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    layout: {
      padding: {
        left: -60,
      },
    },
    legend: {
      display: displayLegend,
      position: 'top',
      labels: {
        fontColor: '#91929b',
        padding: 20,
      },
    },
    plugins: {
      datalabels: {
        color: '#FFF',
        anchor: 'center',
        // clip: false,
        // align: 'right',
        // labels: {
        //   formatter: function(value) {
        //     return 'line1\nline2\n' + value
        //     // eq. return ['line1', 'line2', value]
        //   },
        // },
        display: ctx => {
          let formatter = true
          if (usePercentage) {
            formatter = false
          }
          return formatter
        },
        formatter: (ctx, chartData) => {
          const num = chartData.dataset.data[chartData.dataIndex]
          const total = _.sum(chartData.dataset.data)
          const numToPercentage = `${Math.floor((num / total) * 100)}%`
          if (usePercentage === false) {
            return num
          }

          return `${numToPercentage}`
        },
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            autoSkip: false,
            beginAtZero: true,
            stepSize: xStepSize ? xStepSize : 1,
            // maxRotation: 0,
            // minRotation: 0,
          },
          scaleLabel: {
            display: true,
            labelString: xLabel || '',
          },
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: yLabel || '',
          },
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            beginAtZero: true,
            stepSize: 1,
            autoSkip: false,
            maxRotation: 45,
            minRotation: 45,
            // max: Math.max(...data.labels) * 1.2,
          },
        },
      ],
    },
  }

  return type ? (
    <HorizontalBar data={data} ref={myRef} options={options} type={type} />
  ) : (
    <Bar data={data} ref={myRef} options={options} type={type} />
  )
}

BarChart.propTypes = {
  data: PropTypes.instanceOf(Object),
  chartData: PropTypes.instanceOf(Object),
  displayLegend: PropTypes.bool,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
  type: PropTypes.string,
}

export default BarChart

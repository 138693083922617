import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'
import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'rc-slider'
import styled from 'styled-components/macro'

const MobileSliderRating = ({ labelsArray, currentAnswer, updateCurrent }) => {
  return (
    <SliderContainer>
      <LabelContainer>
        {labelsArray.map(label => {
          return <p key={label.id}>{label.text}</p>
        })}
      </LabelContainer>
      <VerticalContainer>
        <Slider
          vertical
          min={0}
          max={10}
          value={currentAnswer.value}
          defaultValue={0}
          onChange={e => {
            const answer = {
              value: e,
            }
            updateCurrent({
              value: answer.value,
            })
          }}
        />
      </VerticalContainer>
    </SliderContainer>
  )
}

const VerticalContainer = styled.div`
  display: flex;
  height: 400px;
`

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  height: 400px;
  margin-right: 0.5rem;
`

const SliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 80vh;
  width: 80vw;
`

MobileSliderRating.propTypes = {
  labelsArray: PropTypes.instanceOf(Object).isRequired,
  currentAnswer: PropTypes.instanceOf(Object).isRequired,
  updateCurrent: PropTypes.func.isRequired,
}

export default MobileSliderRating

import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

const MultiTextBox = ({ choices, currentAnswer, updateCurrent }) => {
  return (
    <div>
      {choices.map(item => (
        <Row>
          <h4>{item.text}</h4>
          <Input
            type="text"
            value={currentAnswer[item.id] ? currentAnswer[item.id].value : ''}
            onChange={e => {
              const inputValues = {
                id: item.id,
                text: item.text,
                value: e.target.value,
              }
              const newValues = {
                ...currentAnswer,
                [item.id]: inputValues,
              }
              updateCurrent(newValues)
            }}
          />
        </Row>
      ))}
    </div>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const Input = styled.input`
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 10px;
  width: 80%;
  box-sizing: border-box;
  font-family: Roboto;
  color: #2c3e50;
  font-size: 15px;
`

MultiTextBox.propTypes = {
  choices: PropTypes.instanceOf(Object),
  currentAnswer: PropTypes.instanceOf(Object),
  updateCurrent: PropTypes.func,
}

export default MultiTextBox

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import _ from 'lodash'
import { Radio } from 'antd'

import TextArea from 'antd/lib/input/TextArea'

/**
 * This question requires a multiple arrays,
 * the 'options' array is used to render a scale,
 * multiChoice array is used to render choices,
 * results are recorded with radio buttons
 * @param {*} param
 */
const MatrixTable = ({
  answers,
  question,
  multiChoiceArray,
  columnsArray,
  updateCurrent,
  currentAnswer,
  injectCurrent,
  submitAnswer,
  settings,
  options,
}) => {
  const qChoices = _.isFunction(question.choices) ? question.choices({ answers }) : question.choices
  const qOptions = _.isFunction(question.options) ? question.options({ answers }) : question.options

  const [dynamicChoices, setDynamicChoices] = useState(_.uniqBy(qChoices, 'id'))

  const dynamicColumns = _.isFunction(options) ? options({ answers }) : options

  const { id } = currentAnswer

  const dynamicSection = _.isFunction(question.headerText)
    ? question.headerText({ answers })
    : question.headerText

  const fetchDefaultValues = () => {
    if (question.defaultValues) {
      const initialAnswer = {
        value: question.defaultValues({ answers }),
        questionType: question.type,
      }
      injectCurrent(initialAnswer)
    }
  }

  useEffect(() => {
    fetchDefaultValues()
    if (question.asyncChoices) {
      question.asyncChoices().then(choices => {
        setDynamicChoices(choices)
      })
    }
  }, [])

  const { primaryColour, contrastColour } = settings

  const dynamicColour = !_.isUndefined(primaryColour) ? primaryColour : '#40a9ff'
  const dynamicContrast = !_.isUndefined(contrastColour) ? contrastColour : '#FFF'

  const renderButton = () => {
    let button = (
      <Button
        onClick={() => {
          console.log('button clicked')
        }}
      ></Button>
    )
    return button
  }

  console.log('question', question)

  return (
    <Container>
      <table className="responsive-table-input-matrix">
        <thead>
          <TableRow>
            <th></th>
            {qOptions.map((item, index) => {
              return <Header>{item.text}</Header>
            })}
          </TableRow>
        </thead>
        <tbody>
          {qChoices.map((item, index) => {
            return (
              <TableRow>
                <Text>{item.text}</Text>
                {qOptions.map((option, oIndex) => {
                  return (
                    <TD>
                      <Button
                        dynamicColour={dynamicColour}
                        onClick={() => {
                          const answer = {
                            value: option.value,
                          }
                          const newAnswer = {
                            ...currentAnswer,
                          }

                          if (!currentAnswer.value || currentAnswer.value === 'skip') {
                            newAnswer.id = option.id
                            newAnswer.value = new Array(question.choices.length)
                            newAnswer.value.fill({ text: '', value: '' }) // for firebase
                          }
                          newAnswer.value[index] = {
                            id: item.id,
                            text: item.text,
                            value: answer.value,
                          }
                          updateCurrent(newAnswer)
                          console.log('newAnswer', newAnswer.value)
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={
                            currentAnswer.value &&
                            currentAnswer.value[index] &&
                            currentAnswer.value[index].value === option.value
                          }
                        />
                      </Button>
                    </TD>
                  )
                })}
              </TableRow>
            )
          })}
        </tbody>
      </table>
    </Container>
  )
}

MatrixTable.propTypes = {
  answers: PropTypes.instanceOf(Object),
  multiChoiceArray: PropTypes.instanceOf(Array).isRequired,
  currentAnswer: PropTypes.instanceOf(Object).isRequired,
  updateCurrent: PropTypes.func.isRequired,
  question: PropTypes.instanceOf(Object).isRequired,
  settings: PropTypes.instanceOf(Object),
  injectCurrent: PropTypes.func,
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const Header = styled.th`
  width: 10rem;
  padding: 12px 20px;
`

const TableRow = styled.tr`
  &:nth-of-type(odd) {
    background: #eee;
  }
  th {
    background-color: #fff;
  }
`

const Button = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  /* background-color: ${props => (props.active ? 'blue' : 'white')}; */
  input[type='checkbox']:checked {
    background-color: ${props => props.dynamicColour};
  }
  input {
    height: 25px;
    width: 25px;
    -webkit-appearance: checkbox !important;
  }
`

const TD = styled.td`
  vertical-align: middle;
  text-align: center;
`

const Text = styled.td`
  padding: 12px 20px;
`

const ColumnContainer = styled.div``

const StyledUL = styled.ul`
  margin-top: -1rem;
`

const Container = styled.div`
  margin-top: 1rem;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 800px;
`

const StyledTextArea = styled(TextArea)``

const MButton = styled.button``

const Circle = styled.div``

const Wrapper = styled.div``

export default MatrixTable

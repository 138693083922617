import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { ReactSortable } from 'react-sortablejs'
import _ from 'lodash'
import PropTypes from 'prop-types'
import InputField from './InputField'
import { ReactComponent as DragIcon } from '../../assets/images/StepChange/Drag icon.svg'
import { ReactComponent as Cross } from '../../assets/images/HCF/cross-icon.svg'
import { ReactComponent as Undo } from '../../assets/images/HCF/undo-icon.svg'

const Ranking = ({
  rankingChoices,
  updateCurrent,
  currentAnswer,
  settings,
  question,
  submitAnswer,
  injectCurrent,
  answers,
  choices,
  loopIndex,
}) => {
  const itemsToBeRemoved = []
  const dynamicChoices = _.isFunction(choices) ? choices({ answers, loopIndex }) : choices
  const state = _.isArray(currentAnswer.value) ? currentAnswer.value : dynamicChoices

  const [removedItems, setRemovedItems] = useState(
    _.isArray(currentAnswer.removedItems) ? currentAnswer.removedItems : itemsToBeRemoved,
  )

  const newState = state
  const { primaryColour, contrastColour } = settings
  const { id, value } = currentAnswer

  const dynamicColour = !_.isUndefined(primaryColour) ? primaryColour : '#E82D7C'
  const dynamicContrast = !_.isUndefined(contrastColour) ? contrastColour : '#fff'

  const updateList = newListState => {
    console.log('updateList ran')
    injectCurrent({ ...currentAnswer, value: newListState })
  }

  useEffect(() => {
    injectCurrent({ ...currentAnswer, value: state })
    console.log('fired')
  }, [])

  return (
    <Container>
      <Disclaimer>
        {question.disclaimer ? question.disclaimer : 'Drag and drop the answers to re-order'}
      </Disclaimer>
      <ReactSortable
        animation={200}
        ghostClass="ghost"
        list={state}
        setList={updateList}
        dynamicColour={dynamicColour}
        onEnd={e => {
          const newValue = state.map((newItem, newItemIndex) => {
            if (newItem.type !== 'input') {
              return { ...newItem, value: newItemIndex + 1 }
            }
            return newItem
          }) // returns new array without removedItems

          const updateState = {
            ...currentAnswer,
            value: newValue,
          }
          injectCurrent(updateState)
        }}
      >
        {state.map((item, index) => {
          if (item.type === 'input') {
            return (
              <Row mobileHeight={question.mobileHeight}>
                <h4>{index + 1}</h4>
                <InputWrapper
                  style={{ marginTop: question.marginTop, width: item.width }}
                  active={item.id === id}
                  key={item.id}
                >
                  <InputField
                    active={item.id === id}
                    settings={settings}
                    inputValue={
                      currentAnswer.value &&
                      _.isArray(currentAnswer.value) &&
                      currentAnswer.value.find(answer => answer.id === item.id) &&
                      currentAnswer.value.find(answer => answer.id === item.id).value
                    }
                    style={{ width: 'inherit' }}
                    updateInputValue={e => {
                      let answer = state.find(answer => answer.id === item.id)
                      answer.value = e.target.value

                      const newAnswer = {
                        ...currentAnswer,
                      }
                      newAnswer.value = state.map((newItem, newItemIndex) => {
                        if (newItem.type !== 'input') {
                          return { ...newItem, value: newItemIndex + 1 }
                        }
                        return newItem
                      })
                      updateCurrent(newAnswer)
                      console.log('newA', newAnswer.value)
                    }}
                    label={item.text}
                  />
                </InputWrapper>
                {question.removable ? (
                  <IconContainer key={index}>
                    <IconButton
                      key={item.id}
                      onClick={() => {
                        const newRemovedItems = [...removedItems, item]
                        setRemovedItems(newRemovedItems)

                        const newValue = state
                          .filter(i => i.id !== item.id)
                          .map((newItem, newItemIndex) => {
                            if (newItem.type !== 'input') {
                              return { ...newItem, id: item.id, value: newItemIndex + 1 }
                            }
                            return newItem
                          }) // returns new array without removedItems
                        const updateState = {
                          ...currentAnswer,
                          value: newValue,
                          removedItems: newRemovedItems,
                        }
                        injectCurrent(updateState)
                        console.log('updated')
                      }}
                    >
                      <CrossIcon />
                    </IconButton>
                  </IconContainer>
                ) : null}
              </Row>
            )
          }
          if (item.type === 'multiChoice') {
            return (
              <ButtonContainer style={{ marginBottom: item.marginBottom, width: question.width }}>
                <h4 style={{ visibility: 'hidden' }}>{index + 1}</h4>
                <Wrapper
                  active={_.isString(currentAnswer.value) ? item.id === id : ''}
                  key={item.id}
                  dynamicColour={dynamicColour}
                  dynamicContrast={dynamicContrast}
                  mobileProps={question.mobileProps}
                  gridDisplay={question.verticalGrid}
                  mobileHeight={question.mobileHeight}
                  mobilePadding={question.mobilePadding}
                  style={{
                    flex: question.flex,
                    height: question.height,
                    width: item.width,
                    padding: question.padding,
                  }}
                  onClick={() => {
                    const answer = {
                      ...currentAnswer,
                      id: item.id,
                      value: item.value,
                    }
                    updateCurrent(answer)
                    submitAnswer()
                  }}
                >
                  <Circle
                    dynamicColour={dynamicColour}
                    dynamicContrast={dynamicContrast}
                    gridDisplay={question.verticalGrid}
                    className="svg-wrapper"
                    active={_.isString(currentAnswer.value) ? item.id === id : ''}
                  >
                    <svg className="svg" version="1.1" id="tick" viewBox="6 5 26 26">
                      <polyline
                        className="path"
                        points="
          11.6,20 15.9,24.2 26.4,13.8 "
                      />
                    </svg>
                  </Circle>
                  <Button type="button">
                    <span style={{ fontWeight: '501' }}>{item.text}</span>
                  </Button>
                </Wrapper>
              </ButtonContainer>
            )
          }
          return (
            <Row
              mobileHeight={question.mobileHeight}
              key={item.id}
              style={{ width: question.width }}
              dynamicColour={dynamicColour}
            >
              <h4 dynamicColour={dynamicColour}>{index + 1}</h4>
              <Button key={item.id}>
                <span>{item.text}</span>
                <IconDrag />
              </Button>
              {question.removable ? (
                <IconContainer key={index}>
                  <IconButton
                    key={item.id}
                    onClick={() => {
                      const newRemovedItems = [...removedItems, item]
                      setRemovedItems(newRemovedItems)

                      const newValue = state
                        .filter(i => i.id !== item.id)
                        .map((newItem, newItemIndex) => {
                          if (newItem.type !== 'input') {
                            return { ...newItem, value: newItemIndex + 1 }
                          }
                          return newItem
                        }) // returns new array without removedItems
                      const updateState = {
                        ...currentAnswer,
                        value: newValue,
                        removedItems: newRemovedItems,
                      }
                      injectCurrent(updateState)
                      // console.log('removedItems', removedItems)
                    }}
                  >
                    <CrossIcon />
                  </IconButton>
                </IconContainer>
              ) : null}
            </Row>
          )
        })}
      </ReactSortable>
      {console.log('currentAnswer removeItems', currentAnswer)}
      {_.isArray(removedItems)
        ? removedItems.map((removedItem, index) => {
            return (
              <Column>
                <Row>
                  <HiddenElement>{index + 1}</HiddenElement>
                  <FadedButton>
                    <span>{removedItem.text}</span>
                  </FadedButton>
                  <IconContainer key={index}>
                    <IconButton
                      onClick={() => {
                        _.pull(removedItems, removedItem)
                        setRemovedItems(removedItems)
                        const updatedArray = [...state, removedItem]
                        updatedArray.map((item, index) => {
                          if (item.type !== 'input') {
                            return (item.value = index + 1)
                          }

                          return item.value
                        })

                        const updateState = {
                          ...currentAnswer,
                          value: updatedArray,
                          removedItems: removedItems,
                        }
                        injectCurrent(updateState)
                        console.log('currentAnswer after UNDO ', updateState)
                      }}
                    >
                      <UndoIcon />
                    </IconButton>
                  </IconContainer>
                </Row>
              </Column>
            )
          })
        : null}
      {question.skipButton ? (
        <ButtonContainer
          style={{ marginBottom: question.skipButton.marginBottom, width: question.width }}
        >
          <h4 style={{ visibility: 'hidden' }}>{1}</h4>
          <Wrapper
            active={_.isString(currentAnswer.value) ? question.skipButton.id === id : ''}
            key={question.skipButton.id}
            dynamicColour={dynamicColour}
            dynamicContrast={dynamicContrast}
            mobileProps={question.mobileProps}
            gridDisplay={question.verticalGrid}
            mobileHeight={question.mobileHeight}
            mobilePadding={question.mobilePadding}
            style={{
              flex: question.flex,
              height: question.height,
              width: question.skipButton.width,
              padding: question.padding,
            }}
            onClick={() => {
              const answer = {
                ...currentAnswer,
                id: question.skipButton.id,
                value: question.skipButton.value,
              }
              updateCurrent(answer)
              submitAnswer()
            }}
          >
            <Circle
              dynamicColour={dynamicColour}
              dynamicContrast={dynamicContrast}
              gridDisplay={question.verticalGrid}
              className="svg-wrapper"
              active={_.isString(currentAnswer.value) ? question.skipButton.id === id : ''}
            >
              <svg className="svg" version="1.1" id="tick" viewBox="6 5 26 26">
                <polyline
                  className="path"
                  points="
          11.6,20 15.9,24.2 26.4,13.8 "
                />
              </svg>
            </Circle>
            <Button type="button">
              <span style={{ fontWeight: '501' }}>{question.skipButton.text}</span>
            </Button>
          </Wrapper>
        </ButtonContainer>
      ) : null}
    </Container>
  )
}

Ranking.propTypes = {
  rankingChoices: PropTypes.instanceOf(Array),
  currentAnswer: PropTypes.instanceOf(Object),
  updateCurrent: PropTypes.func,
}

const IconButton = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background: none;
  cursor: pointer;
`

const CrossIcon = styled(Cross)`
  height: 1rem;
  width: 1rem;
  fill: red;
`

const UndoIcon = styled(Undo)`
  height: 1rem;
  width: 1rem;
  fill: green;
`

const IconContainer = styled.div`
  display: flex;
  height: inherit;
  justify-content: center;
  margin-left: 1rem;
  opacity: 0;
  transition: opacity 100ms ease-in-out;
  @media (min-width: 0px) and (max-width: 479px) {
    opacity: 1;
  }
`
const IconDrag = styled(DragIcon)`
  height: 1rem;
  width: 1rem;
`
const HiddenElement = styled.span`
  visibility: hidden;
  margin-right: 1rem;
`
const Button = styled.button`
  h4 {
    margin-bottom: 0;
  }
  height: inherit;
  display: flex;
  width: inherit;
  align-items: center;
  padding: 1rem;
  min-height: 3rem;
  background-color: #fff;
  margin-bottom: calc(1vh + 0.8rem);
  font-size: 1rem;
  border-style: solid;
  border-width: 1px;
  border-color: #c7c7c76b;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 3px;
  font-weight: 400;
  /* width: 31rem; */
  align-items: center;
  user-select: none;
  span {
    width: 100%;
    text-align: left;
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: break-work;
    color: #262626;
  }
`

const FadedButton = styled.button`
  h4 {
    margin-bottom: 0;
  }
  height: inherit;
  display: flex;
  width: inherit;
  align-items: center;
  padding: 1rem;
  min-height: 3rem;
  background-color: #fff;
  margin-bottom: calc(1vh + 0.8rem);
  font-size: 1rem;
  opacity: 0.7;
  border-style: solid;
  border-width: 1px;
  border-color: #c7c7c76b;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 3px;
  font-weight: 400;
  /* width: 24rem; */
  align-items: center;
  user-select: none;
  span {
    width: 100%;
    text-align: left;
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: break-work;
  }
`

const Wrapper = styled.div`
  width: 31.5rem;
  height: 3rem;
  align-items: baseline;
  justify-content: center;
  border-radius: 3px;
  display: flex;
  cursor: pointer;
  background-color: ${props => (props.active ? props.dynamicColour : 'white')};
  border: ${props => (props.active ? 'none' : '1px solid rgba(0, 0, 0, 0.3)')};
  color: ${props => (props.active ? props.dynamicContrast : '#262626')};
  position: relative;
  transition: background 250ms ease-in-out, transform 150ms ease;
  &:hover {
    /* transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased; */
    border-color: ${props => props.dynamicColour};
    border-width: 2px;
    border-color: none;
    /* transform: scale(1.01); */
    -webkit-box-shadow: 0px 6px 11px -8px rgba(0, 0, 0, 0.65);
    box-shadow: 0px 6px 11px -8px rgba(0, 0, 0, 0.65);
    transition: box-shadow 0.08s ease-in-out;
  }
  .svg {
    width: 20px;
  }
  .path {
    fill: transparent;
    stroke: ${props => (props.active ? props.dynamicColour : '#53bf8b')};
    stroke-width: 2;
    stroke-dasharray: 25;
    stroke-dashoffset: ${props => (props.active ? 0 : 26)};
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dashoffset 250ms ease-in-out;
  }

  ${Button} {
    text-align: left;
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: break-word;
    border: none;
    font-weight: ${props => (props.active ? '500' : '400')};
    height: inherit;
    width: 90%;
    background-color: inherit;
    margin-bottom: 0rem;
    padding: 0;
    margin-left: 1rem;
    min-height: 0;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: calc(1vh + 0rem);
    height: 50px;
    height: ${props => (props.mobileHeight ? props.mobileHeight : '50px')}!important;
    padding-left: 10px;
    padding-right: 10px;
    align-items: baseline;
    width: 76vw !important;
    max-width: 360px !important;
    .svg-wrapper {
      margin-left: 230px;
    }
    padding: ${props => (props.mobilePadding ? props.mobilePadding : '')};
    /* padding-top: 2.25rem;
    padding-bottom: 2.25rem; */
  }
  @media (min-width: 480px) and (max-width: 767px) {
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1rem;
  height: 3rem;
  width: 35rem;
  &:hover {
    ${IconContainer} {
      opacity: 1;
    }
  }

  h4 {
    margin-bottom: 0;
    height: 3rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    color: ${props => props.dynamicColour};
  }

  ${Wrapper} {
    ${Button} {
      padding: 0rem !important;
      margin: 0;
      margin-bottom: 0.8rem;
      /* margin-left: 1rem; */
      /* border: none; */
      /* height: inherit !important; */
      width: 31.5;
      min-height: 0;
    }
  }

  @media (min-width: 0px) and (max-width: 479px) {
    width: 85vw !important;
    height: ${props => props.mobileHeight};
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.8rem;
  ${Row} {
    flex-direction: row;
    margin-bottom: 0;
  }
`

const Disclaimer = styled.p`
  font-style: italic;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  /* width: 800px; */
  font-size: 14px;
  /* margin-top: -0.5rem; */
  margin-bottom: 1rem;
  @media (min-width: 0px) and (max-width: 479px) {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-left: 1.4rem;
  }
  @media (min-width: 480px) and (max-width: 768px) {
    display: flex;
    width: 90vw;
    justify-content: flex-start;
    align-items: baseline;
  }
  @media (min-width: 768px) and (max-width: 1025px) {
    display: flex;
    width: 80vw;
    justify-content: flex-start;
    align-items: baseline;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction:column;
  align-items: baseline;
  width: 800px;
  div {
    display: flex;
    flex-direction: column;
    .ghost {
      opacity: 0.5;
      background: ${props => props.dynamicColour};
      cursor: grabbing;
      /* background: ${props => props.dynamicColour}; */
    }
    ${Row} {
      display: flex;

      flex-direction: row;
      align-content: center;
      justify-content: space-between;

      h4 {
        margin-right: 1em;
      }
      ${Button} {
        width: inherit;
        min-height: 3rem;
        background-color: #fff;
        margin-bottom: calc(1vh + 0.8rem);
        font-size: 1rem;
        border-style: solid;
        border-width: 1px;
        border-color: #c7c7c76b;
        cursor: grab;
        -webkit-appearance: none;
        -moz-appearance: none;
        border-radius: 3px;
        font-weight: 400;
        user-select: none;
      }
    }
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 90vw;
  }
`

const InputWrapper = styled.div`
  margin-bottom: calc(1vh + 0rem);
  /* width: ${props => (props.gridDisplay ? '551px' : '30%')};
  max-width: ${props => (props.gridDisplay ? '551px' : '258px')}; */
  /* width: inherit; */
  height: inherit;

  .svg-wrapper {
    margin-left: 230px;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .svg {
    width: 20px;
  }
  .path {
    fill: transparent;
    stroke: ${props => (props.active ? props.dynamicColour : '#53bf8b')};
    stroke-width: 2;
    stroke-dasharray: 25;
    stroke-dashoffset: ${props => (props.active ? 0 : 26)};
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dashoffset 250ms ease-in-out;
  }
  fieldset {
    input {
      width: 31.5rem;
    }
  }

  @media (min-width: 0px) and (max-width: 479px) {
    width: 70vw !important;
    max-width: 369px !important;
    fieldset {
      input {
        width: 83%;
      }
    }
    /* fieldset {
      label {
        top: ${props => (props.mobileProps.top ? '45px' : '')};
      }
    }
    input {
      height: ${props => (props.mobileProps.height ? '80px' : '50px')};
    } */
  }
`

const Circle = styled.div`
  display: flex;
  align-items: center;
  /* margin-left: ${props => (props.gridDisplay ? '0px' : '90px')};
  margin-top: ${props => (props.gridDisplay ? '0px' : '-60px')}; */
  right: ${props => (props.gridDisplay ? '0px' : '20px')};
  /* top: ${props => (props.gridDisplay ? '12px' : '20px')}; */
  width: 25px;
  height: 25px;
  padding: 2px;
  border-radius: 25px;
  /* display: ${props => (props.active ? 'flex' : 'none')} */
  border: ${props => (props.active ? '1px solid white' : 'none')};
  position: ${props => (props.gridDisplay ? 'absolute' : 'absolute')};
  right: ${props => (props.gridDisplay ? '7px' : '')};
  top: ${props => (props.gridDisplay ? '' : '')};
  .svg-wrapper {
    width: 40px;
    display: contents;
    align-items: center;
    justify-content: center;
  }
  background-color: #fff;
  .svg {
    width: 30px;
  }
  .path {
    fill: transparent;
    stroke: ${props => (props.active ? props.dynamicColour : '#53bf8b')};
    stroke-width: 3;
    stroke-dasharray: 25;
    stroke-dashoffset: ${props => (props.active ? 0 : 26)};
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dashoffset 250ms ease-in-out;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    top: auto;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row !important;
  h4 {
    margin-bottom: 0;
    margin-right: 1em;
  }
  align-items: flex-start;
  height: 3rem;
  width: inherit;
  /* width: 400px; */
  justify-content: inherit;
  @media (min-width: 0px) and (max-width: 479px) {
    margin-bottom: 1rem !important;
  }
`

export default Ranking

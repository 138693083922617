import { useEffect } from 'react'
import PropTypes from 'prop-types'

const Hidden = ({ question, currentAnswer, updateCurrent, submitAnswer }) => {
  useEffect(() => {
    if (Object.entries(currentAnswer).length === 0 && currentAnswer.constructor === Object) {
      updateCurrent({ value: question.value })
    }
    submitAnswer()
  }, [currentAnswer, question.value, submitAnswer, updateCurrent])
  return null
}

Hidden.propTypes = {
  question: PropTypes.instanceOf(Object).isRequired,
  currentAnswer: PropTypes.instanceOf(Object).isRequired,
  updateCurrent: PropTypes.func.isRequired,
  submitAnswer: PropTypes.func.isRequired,
}

export default Hidden

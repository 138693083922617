import React, { useState, useEffect } from 'react'
import * as firebase from 'firebase/app'
import _ from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Tabs } from 'antd'
import BarChart from './BarChart'
import WordCloud from './WordCloud'
import DoughnutChart from './DoughnutChart'

const OutputResults = ({ currentSurveyQuestions, question, answers, settings }) => {
  const [results, updateResults] = useState()
  const [combinedResults, updateCombinedResults] = useState()
  const [smartQid, setQid] = useState()
  const [surveyLabels, setLabels] = useState()
  const [tabChoices, setTabChoices] = useState()
  const {
    surveyName,
    targetQuestionId,
    compareQuestionId,
    outputOnly,
    targetQuestionType,
    targetSurveyQuestions,
    chartLabels,
    usePercentage,
    maintainAspectRatio,
    responsive,
  } = question.output
  const { primaryColour, otherColour, chartColours, surveyWidth } = settings
  const { TabPane } = Tabs

  const dynamicColour = !_.isUndefined(chartColours) ? chartColours : primaryColour

  const db = firebase.firestore()
  const surveyData = db.collection('surveys').doc(surveyName)

  console.log('output', usePercentage)

  const updateQid = selectedItem => {
    setQid(selectedItem)
  }

  const fetchQuestions = () => {
    if (question.output.smart) {
      let surveyId

      if (targetSurveyQuestions) {
        surveyId = _.find(targetSurveyQuestions, { id: targetQuestionId })
      } else {
        surveyId = _.find(currentSurveyQuestions, { id: targetQuestionId })
      }
      setLabels(surveyId.options.map(item => item.text))
      if (question.output.asyncCategories) {
        question.output
          .asyncCategories()
          .then(choices => {
            setTabChoices(choices)
            setQid(choices[0])
          })
          .catch(err => {
            setTabChoices([])
          })
        return
      }

      // let surveyId

      // if (targetSurveyQuestions) {
      //   surveyId = _.find(targetSurveyQuestions, { id: targetQuestionId })
      // } else {
      //   surveyId = _.find(currentSurveyQuestions, { id: targetQuestionId })
      // }
      // console.log(
      //   'labels in fetch Questions',
      //   surveyId.options.map(item => item.text),
      // )
      // setLabels
      const questionChoices = surveyId.choices.map(choice => choice.text)
      setTabChoices(questionChoices)
    }
  }

  useEffect(() => {
    if (!_.isUndefined(question.output)) {
      fetchQuestions()
      let unsubscribe

      if (compareQuestionId) {
        unsubscribe = surveyData.onSnapshot(doc => {
          return updateCombinedResults([
            doc.data().aggResults[targetQuestionId],
            doc.data().aggResults[compareQuestionId],
          ])
        })
      } else {
        unsubscribe = surveyData.onSnapshot(doc => {
          return updateResults(doc.data().aggResults[targetQuestionId])
        })
      }

      return () => unsubscribe()
    }
  }, [question])

  const renderOutputType = () => {
    let output
    let chartData
    let data = results
    let groupedResults
    let selectedAnswer
    console.log('chartColours', chartColours)
    console.log('dynamic', dynamicColour)

    if (outputOnly) {
      selectedAnswer = null
    } else {
      selectedAnswer = answers[question.output.targetQuestionId].value
    }

    if (outputOnly && targetQuestionType === 'multiSelect') {
      data = results
    } else if (
      targetQuestionType === 'responsiveMatrix' ||
      answers[targetQuestionId].questionType === 'responsiveMatrix'
    ) {
      let nestedQid = question.output.nestedQuestionId // change to updated state var
      if (compareQuestionId) {
        data = combinedResults
        // if (_.isUndefined(nestedQid) && !_.isUndefined(combinedResults)) {
        //   nestedQid = Object.keys(combinedResults[0])[0]
        //   console.log('nestedQid', nestedQid)
        // }
      }
      if (!_.isUndefined(smartQid)) {
        // update selected answer for graph
        nestedQid = smartQid
      }

      // pass down questions object through props instead of import

      if (outputOnly) {
        selectedAnswer = null
      } else {
        selectedAnswer = _.find(selectedAnswer, { text: nestedQid }) // find selected answer for specific matrix questionId
        selectedAnswer = selectedAnswer.value
      }

      // selectedAnswer = _.find(selectedAnswer, { text: nestedQid }) // find selected answer for specific matrix questionId
      // selectedAnswer = selectedAnswer.value

      if (combinedResults) {
        console.log('combined', combinedResults)
        console.log('nQid', nestedQid)
        const compareA = _.get(combinedResults[0], nestedQid)
        const compareB = _.get(combinedResults[1], nestedQid)
        groupedResults = [compareA, compareB]
        console.log('groupedResults', groupedResults)
      } else {
        data = _.get(results, nestedQid) // fetch specific question from matrix
      }
    }

    if (!_.isUndefined(question.output)) {
      switch (question.output.type) {
        case 'text':
          output = <div>{JSON.stringify(data)}</div>
          break
        case 'bar-chart':
          if (_.isArray(selectedAnswer)) {
            selectedAnswer = selectedAnswer.map(answer => {
              return answer.value
            })
          }
          if (compareQuestionId && groupedResults) {
            const labels = surveyLabels
            const preloadedLabelData = _.mapValues(_.keyBy(labels), i => {
              return 0
            })
            console.log('labels', labels)
            console.log('new obj', preloadedLabelData)
            groupedResults = groupedResults.map(result => {
              return {
                ...preloadedLabelData,
                ...result,
              }
            })
            console.log('groupedResults', groupedResults)
            // const unzipCompareA = _.unzip(_.sortBy(_.toPairs(groupedResults[0]), [pair => pair[0]]))
            // const unzipCompareB = _.unzip(_.sortBy(_.toPairs(groupedResults[1]), [pair => pair[0]]))
            const dataBefore = labels.map(label => groupedResults[0][label])
            const dataAfter = labels.map(label => groupedResults[1][label])
            // const groupLabels = [unzipCompareA[0], unzipCompareB[0]]
            // const groupLabels
            console.log('unzipA', dataBefore)
            console.log('unzipB', dataAfter)
            // console.log('groupedLabels', groupLabels)
            console.log('labels from state', surveyLabels)

            chartData = {
              labels: labels,
              // labels: !_.isUndefined(chartLabels) ? chartLabels : unzipCompareA[0],

              // labels: unzipCompareA[0],
              datasets: [
                {
                  label: 'Before',
                  data: dataBefore,
                  backgroundColor: '#008FFB',
                },
                {
                  label: 'After',
                  data: dataAfter,
                  backgroundColor: '#775DD0',
                },
              ],
            }

            output = (
              <BarChart
                data={chartData}
                chartData={chartData[1]}
                displayLegend={question.output.displayLegend}
                xLabel={question.output.xLabel}
                yLabel={question.output.yLabel}
                xStepSize={question.output.xStepSize}
                usePercentage={usePercentage}
                surveyWidth={surveyWidth}
                // maintainAspectRatio={maintainAspectRatio}
                // responsive={responsive}
              />
            )
          } else {
            chartData = _.unzip(_.sortBy(_.toPairs(data), [pair => pair[0]]))

            chartData = {
              labels: !_.isUndefined(chartLabels) ? chartLabels : chartData[0],
              datasets: [
                {
                  data: chartData[1],
                  backgroundColor: _.map(chartData[0], aggData => {
                    if (_.isArray(selectedAnswer)) {
                      return selectedAnswer.includes(aggData) ? dynamicColour[1] : dynamicColour[0]
                    }
                    return aggData === selectedAnswer ? dynamicColour[1] : dynamicColour[0]
                  }),
                },
              ],
            }

            output = (
              <BarChart
                data={chartData}
                chartData={!_.isUndefined(chartLabels) ? chartLabels : chartData[0]}
                type={question.output.chartType}
                displayLegend={question.output.displayLegend}
                xLabel={question.output.xLabel}
                yLabel={question.output.yLabel}
                xStepSize={question.output.xStepSize}
                usePercentage={usePercentage}
                surveyWidth={surveyWidth}
              />
            )
          }

          break
        case 'conjoint':
          console.log('data', data)
          if (!_.isUndefined(data)) {
            data = question.output.transformData(data)
          }

          chartData = _.unzip(_.sortBy(_.toPairs(data), [pair => pair[0]]))

          selectedAnswer = question.output.transformData(_.keyBy(selectedAnswer, 'label'))

          console.log('selected', selectedAnswer)

          let userData
          if (chartData[0]) {
            userData = chartData[0].map(label => {
              return selectedAnswer[label]
            })
          }

          chartData = {
            labels: !_.isUndefined(chartLabels) ? chartLabels : chartData[0],
            datasets: [
              {
                label: 'Others',
                data: chartData[1],
                backgroundColor: question.output.othersColour ? question.output.othersColour : 'gray',
              },
              {
                label: 'You',
                data: userData,
                backgroundColor: question.output.userColour ? question.output.userColour : 'green',
              },
            ],
          }

          console.log('chartData-2', chartData)

          output = (
            <ChartContainer>
              <BarChart
                data={chartData}
                chartData={!_.isUndefined(chartLabels) ? chartLabels : chartData[0]}
                type={question.output.chartType}
                displayLegend={question.output.displayLegend}
                xLabel={question.output.xLabel}
                yLabel={question.output.yLabel}
                xStepSize={question.output.xStepSize}
                usePercentage={usePercentage}
                surveyWidth={surveyWidth}
              />
            </ChartContainer>
            
           
          )
          break
        case 'word-cloud':
          chartData = data
          if (!_.isUndefined(chartData)) {
            chartData = Object.entries(chartData).map(key => ({ text: key[0], value: key[1] })) // convert Obj to array of Obj
            const options = {
              fontSizes: [20, 60],
            }

            output = <WordCloud words={chartData} options={options} />
          }
          break
        case 'doughnut-chart':
          if (_.isArray(selectedAnswer)) {
            selectedAnswer = selectedAnswer.map(answer => {
              return answer.value
            })
          }
          chartData = _.unzip(_.sortBy(_.toPairs(data), [pair => pair[0]]))
          chartData = {
            labels: chartData[0],
            datasets: [
              {
                data: chartData[1],
                backgroundColor: _.map(chartData[0], aggData => {
                  if (_.isArray(selectedAnswer)) {
                    return selectedAnswer.includes(aggData) ? dynamicColour[1] : dynamicColour[0]
                  }
                  return aggData === selectedAnswer ? dynamicColour[1] : dynamicColour[0]
                }),
              },
            ],
          }
          output = <DoughnutChart data={chartData} />
          break

        default:
          output = <h1>{question.output.type}</h1>
      }
    }
    return (
      <OutputContainer style={{ height: question.output.height }} surveyWidth={surveyWidth}>
        {output}
      </OutputContainer>
    )
  }

  return (
    <Container>
      {question.output.smart && !_.isUndefined(tabChoices) ? (
        <StyledTabs
          surveyWidth={surveyWidth}
          colour={dynamicColour}
          onChange={updateQid}
          keyboard={true}
        >
          {tabChoices.map(tab => {
            return <TabPane tab={tab} key={tab} />
          })}
        </StyledTabs>
      ) : null}
      {renderOutputType()}
    </Container>
  )
}

const Container = styled.div`
  height: 40rem;
  canvas {
    height: 100%;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    /* small viewport  */
    height: 28rem;
    /* background-color: green; */
  }
`

const OutputContainer = styled.div`
  position: relative;
  width: inherit;
  height: 100%;
  @media (min-width: 0px) and (max-width: 479px) {
    /* small viewport  */
    height: 100%;
    /* background-color: green; */
  }
  /* width: ${props => (props.surveyWidth ? '1200px' : '800px')};
  height: ${props => (props.surveyWidth ? '650px' : '400px')}; */
`

const StyledTabs = styled(Tabs)`
  width: ${props => (props.surveyWidth ? '1200px' : '800px')};
  .ant-tabs-tab.ant-tabs-tab-active {
    color: ${props => props.colour};
  }
  .ant-tabs-ink-bar {
    background: ${props => props.colour};
  }
  .ant-tabs-dropdown-menu-item {
    width: 500px;
  }
`

const ChartContainer = styled.div`
  height: 100%;
  padding-bottom: 10vh;
`

OutputResults.propTypes = {
  question: PropTypes.instanceOf(Object),
  output: PropTypes.instanceOf(Object),
  answers: PropTypes.instanceOf(Object),
  settings: PropTypes.instanceOf(Object),
  currentSurveyQuestions: PropTypes.instanceOf(Array),
  surveyName: PropTypes.string,
  targetQuestionId: PropTypes.string,
}

export default OutputResults

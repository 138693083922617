/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components/macro'
import { ReactComponent as Pause } from '../../../src/assets/images/pause.svg'
import { ReactComponent as Play } from '../../../src/assets/images/play.svg'

const DesktopLayout = ({ settings, question, children }) => {
  const {
    backgroundImage,
    companyLogo,
    leftBackgroundImage,
    specialEffects,
    rightBackgroundImage,
    introPageOnly,
    fullLength,
    currentQuestionId,
    logoLocation,
    logoStyle,
    primaryColour,
    backgroundVideo,
  } = settings

  const [isPlaying, setVideo] = useState(true) // video plays by default

  const dynamicColour = !_.isUndefined(primaryColour) ? primaryColour : '#E82D7C'

  const IconPause = styled(Pause)`
    width: inherit;
    height: inherit;
    border-radius: 25px;
  `

  const IconPlay = styled(Play)`
    width: inherit;
    height: inherit;
    border-radius: 25px;
  `

  const dynamicMargin =
    !_.isUndefined(logoStyle) && !_.isUndefined(logoStyle.marginTop) ? logoStyle.marginTop : '0rem'

  const { hideLogo } = question
  // let isPlaying = true // video plays by default

  const testOnclick = () => {
    console.log('hello')
    console.log('isPlaying', isPlaying)
    const video = document.getElementById('video')
    console.log('video', video)
    if (isPlaying) {
      video.pause()
      setVideo(false)
    } else {
      video.play()
      setVideo(true)
    }
  }

  const myClickF = () => {
    console.log('testing button click')
  }

  return (
    <Container>
      {leftBackgroundImage && _.isUndefined(specialEffects) ? (
        <LeftBanner>
          <img src={leftBackgroundImage} alt="test.jpg" />
        </LeftBanner>
      ) : null}
      {backgroundVideo ? (
        <VideoContainer>
          {/* <div>hello</div> */}
          <Toggle
            onClick={() => {
              testOnclick()
            }}
          >
            {isPlaying ? <IconPause fill={dynamicColour} /> : <IconPlay fill={dynamicColour} />}
          </Toggle>
          <LeftVideo
            id="video"
            style={{ objectPosition: settings.objectPosition }}
            playsInline
            autoPlay
            muted
            loop
            preload="metadata"
          >
            <source src={`${backgroundVideo}#t=0.1`} type="video/mp4" />
            {/* add WEBM videos? */}
          </LeftVideo>
        </VideoContainer>
      ) : null}
      {specialEffects ? (
        <SpecialContainer>
          <LeftBanner>
            <img src={leftBackgroundImage} alt="test.jpg" />
          </LeftBanner>
        </SpecialContainer>
      ) : null}
      <Content
        style={{
          backgroundImage: settings.fullscreen
            ? `url(${backgroundImage})`
            : 'linear-gradient(to right bottom, #fff, #fff, #fff, #fff, #fff)',
        }}
        // style={{
        //   backgroundImage:
        //     introPageOnly && fullLength
        //       ? `url(${backgroundImage})`
        //       : fullLength
        //       ? `url(${backgroundImage})`
        //       : introPageOnly && currentQuestionId === 0
        //       ? `url(${backgroundImage})`
        //       : 'linear-gradient(to right bottom, #fff, #fff, #fff, #fff, #fff)',
        // }}
      >
        {/* Refactor this into a function, fix bug on questionType for reducer previous action  */}
        {settings.logoFirstPageOnly && question.id === 'intro-page' ? (
          <LogoContainer hideLogo={hideLogo} logoLocation={logoLocation} companyLogo={companyLogo}>
            {!_.isUndefined(companyLogo) && _.isArray(companyLogo.images) ? (
              companyLogo.images.map((item, index) => (
                <img
                  style={{ marginTop: dynamicMargin }}
                  key={index}
                  src={item.image}
                  alt="ltogo.jpg"
                />
              ))
            ) : (
              <img src={companyLogo} alt="logo.jpg" />
            )}
          </LogoContainer>
        ) : (
          <LogoContainer
            style={{ visibility: 'hidden' }}
            hideLogo={hideLogo}
            logoLocation={logoLocation}
            companyLogo={companyLogo}
          >
            {!_.isUndefined(companyLogo) && _.isArray(companyLogo.images) ? (
              companyLogo.images.map((item, index) => (
                <img key={index} src={item.image} alt="logo.jpg" />
              ))
            ) : (
              <img src={companyLogo} alt="logo.jpg" />
            )}
          </LogoContainer>
        )}
        <Test>{children}</Test>
      </Content>
      {rightBackgroundImage ? (
        <RightBanner>
          <img src={rightBackgroundImage} alt="test.jpg" />
        </RightBanner>
      ) : null}
    </Container>
  )
}

const Test = styled.div`
  /* background-color: red; */
`

const VideoContainer = styled.div`
  width: 30vw;
  height: 100%;
  position: relative;

  @media (max-width: 1025px) {
    display: none;
  }
`

const LeftVideo = styled.video`
  width: 30vw;
  height: 100%;
  object-fit: cover;
  object-position: left;
  /* img {
    height: 100%;
    object-fit: fill;
    object-position: left;
  } */

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1025px) {
    display: none;
  }
`

const LeftBanner = styled.div`
  width: 30vw;
  height: 100%;
  img {
    height: 100%;
    object-fit: fill;
    object-position: left;
  }

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1025px) {
    display: none;
  }
`

const SpecialContainer = styled.div`
  width: 30vw;
  height: 100%;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  ${LeftBanner} {
    transform: translate3d(0, 0, 0);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 300%;
    background-repeat: repeat;
    transform: translate3d(0, 0, 0);
    animation: slideshow 100s linear infinite;
    @keyframes slideshow {
      100% {
        transform: translateX(-66.66%);
      }
    }
  }
`

const RightBanner = styled.div`
  width: 30vw;
  height: 100%;
  img {
    height: 100%;
    object-fit: fill;
    object-position: left;
  }
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 1025px) {
    display: none;
  }
`

const LogoContainer = styled.div`
  display: ${props => (props.hideLogo ? 'none' : 'flex')};
  flex-direction: row;
  /* background-color: blue; */
  width: 800px;
  justify-content: ${props => (props.logoLocation ? props.logoLocation : 'flex-end')};
  img {
    height: 100px;
    margin-top: ${props => (props.logoStyle ? props.logoStyle : ' ')};
  }
  position: absolute;
  top: 0px;
`

const Container = styled.div`
  display: flex;
  /* user-select: none; */
  width: 100vw;
  height: 100vh;
  /* flex-direction: ${props => (props.leftBackgroundImage ? 'row' : 'column')}; */
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin: 0;
`

const Toggle = styled.button`
  position: absolute;
  width: 3rem;
  height: 3rem;
  bottom: 4rem;
  border-radius: 25px;
  background-color: white;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  z-index: 1;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`
DesktopLayout.propTypes = {
  settings: PropTypes.instanceOf(Object),
  question: PropTypes.instanceOf(Object),
  children: PropTypes.any,
}

export default DesktopLayout

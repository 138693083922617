/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import useFormValidation from './useFormValidation'
import formValidate from './formValidate'

const endSurveyForm = ({ question, updateCurrent, updateAnswer }) => {
  const submitFunction = async () => {
    const { name, email, textBox, phoneNumber } = values
  }

  const INITIAL_STATE = {
    name: '',
    email: '',
    phoneNumber: '',
    textBox: '',
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    isSubmitting,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useFormValidation(INITIAL_STATE, formValidate, submitFunction, updateCurrent, updateAnswer)

  // const { container, contentContainer, input, errorText, errorInput } = styles

  return (
    <Container>
      <h3>{question.title}</h3>
      <p>{question.subtext}</p>
      <p>{question.disclaimer}</p>
      <form onSubmit={handleSubmit}>
        <ContentContainer>
          {question.name ? (
            <Input
              name="name"
              placeholder="Name"
              type="text"
              onChange={handleChange}
              value={values.name}
              onBlur={handleBlur}
            />
          ) : null}

          {question.email ? (
            <Input
              name="email"
              placeholder="Email"
              onChange={handleChange}
              value={values.email}
              onBlur={handleBlur}
            />
          ) : null}
          {question.phone ? (
            <Input
              name="phoneNumber"
              placeholder="Phone Number"
              type="text"
              onChange={handleChange}
              value={values.phoneNumber}
              onBlur={handleBlur}
            />
          ) : null}

          {question.textBox ? (
            <>
              <p>{question.textBox.label}</p>
              <Textarea
                name="textBox"
                placeholder={question.textBox.placeholder}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </>
          ) : null}
          <SubmitButton disabled={isSubmitting} type="submit">
            Submit
            <i className="material-icons">done</i>
          </SubmitButton>
        </ContentContainer>
      </form>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Input = styled.input`
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  font-family: Roboto;
  color: #2c3e50;
  font-size: 13px;
`

const Textarea = styled.textarea`
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 10px;
  width: 300px;
  box-sizing: border-box;
  font-family: Roboto;
  color: #2c3e50;
  font-size: 13px;
  resize: none;
`

const SubmitButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  height: 2rem;
  width: 10rem;
  background-color: #7ed288;
  cursor: pointer;
  transition: background 250ms ease-in-out, transform 150ms ease;
  color: white;
  border-radius: 3px;
  i {
    margin-left: 3rem;
  }
`

endSurveyForm.propTypes = {
  question: PropTypes.instanceOf(Object),
  updateCurrent: PropTypes.func,
  updateAnswer: PropTypes.func,
}
export default endSurveyForm

import React, { useState, useEffect } from 'react'
import 'antd/dist/antd.css'
import { Steps } from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const StepProgressBar = ({ settings, currentQuestion, colour }) => {
  const [currentSection, updateCurrentSection] = useState(0)
  const [surveySection, updateSection] = useState(settings.sections)
  const { Step } = Steps

  const handleChange = () => {
    console.log('current Section', surveySection[currentSection])
    if (currentQuestion === surveySection[currentSection].nextSection) {
      updateCurrentSection(currentSection + 2) // 1
    }
    if (
      currentQuestion === surveySection[currentSection].nextQid ||
      currentQuestion === surveySection[currentSection].futureQid
    ) {
      updateCurrentSection(currentSection + 1) // 1
    }
    if (currentQuestion === surveySection[currentSection].prevSection) {
      updateCurrentSection(currentSection - 2)
    }
    if (
      currentQuestion === surveySection[currentSection].prevQid ||
      currentQuestion === surveySection[currentSection].possibleQid ||
      currentQuestion === surveySection[currentSection].otherQid
    ) {
      updateCurrentSection(currentSection - 1)
    }
  }
  useEffect(() => {
    handleChange()
  }, [currentQuestion])

  return (
    <Container colour={colour}>
      <Steps
        type="navigation"
        size="small"
        current={currentSection}
        className="site-navigation-steps"
      >
        {settings.sections.map(item => (
          <Step title={item.name} />
        ))}
      </Steps>
    </Container>
  )
}

const Container = styled.div`
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    margin-right: 5px;
  }
  .ant-steps-item-process .ant-steps-item-icon {
    background: ${props => (props.colour ? props.colour : '#E82D7C')};
    border-color: ${props => (props.colour ? props.colour : '#E82D7C')};
  }
  .ant-steps-navigation .ant-steps-item::before {
    background-color: ${props => (props.colour ? props.colour : '#E82D7C')};
  }
  .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    margin-right: 2rem;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: ${props => (props.colour ? props.colour : '#E82D7C')};
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${props => (props.colour ? props.colour : '#E82D7C')};
  }
  @media (min-width: 0px) and (max-width: 479px) {
    //Hide on mobile
    display: none;
  }
`

StepProgressBar.propTypes = {
  settings: PropTypes.instanceOf(Object),
  currentQuestion: PropTypes.number,
}

export default StepProgressBar

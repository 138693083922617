import React from 'react'
import 'antd/dist/antd.css'
import styled from 'styled-components/macro'
import _ from 'lodash'
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import { Radio } from 'antd'
import InputField from './InputField'

const MultiRadioList = ({
  answers,
  question,
  updateCurrent,
  currentAnswer,
  settings,
  submitAnswer,
}) => {
  const { id, value } = currentAnswer

  const { primaryColour, contrastColour } = settings

  const dynamicColour = !_.isUndefined(primaryColour) ? primaryColour : '#C4E8C5'

  return (
    <Container>
      {question.choices.map(item => {
        if (item.type === 'input') {
          return (
            <Wrapper active={item.id === id} key={item.id}>
              {item.tooltip !== undefined ? (
                <Tippy content={item.tooltip}>
                  <Info style={{ visibility: 'hidden' }} className="material-icons">
                    info
                  </Info>
                </Tippy>
              ) : null}
              <StyledInputField
                active={item.id === id}
                settings={settings}
                inputValue={currentAnswer.comments ? currentAnswer.comments.value : ''}
                updateInputValue={e => {
                  const comments = {
                    id: item.id,
                    value: e.target.value,
                  }

                  const newAnswer = {
                    ...currentAnswer,
                    comments,
                  }
                  updateCurrent(newAnswer)
                }}
                label={item.text}
              />
            </Wrapper>
          )
        }
        return (
          <Item dynamicColour={dynamicColour} numbersOnly={question.numbersOnly}>
            <Row>
              <p>{item.leftText}</p>
              <p>{item.centerText ? item.centerText : null}</p>
              <p>{item.rightText}</p>
            </Row>
            <Radio.Group
              buttonStyle="solid"
              value={item.id in currentAnswer && currentAnswer[item.id].value}
            >
              {item.answerChoices.map(choice => (
                <Radio.Button
                  key={choice.id}
                  value={choice.value}
                  onChange={e => {
                    const answer = {
                      id: item.id,
                      value: e.target.value,
                      valueId: item.id,
                    }
                    updateCurrent({
                      ...currentAnswer,
                      [item.id]: answer,
                    })
                    if (question.submit) {
                      return submitAnswer()
                    }
                  }}
                >
                  {choice.value}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Item>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 12px; */
  width: 800px;
  fieldset {
    background-color: white;
    opacity: 1;
    width: auto;
    input {
      opacity: 1;
    }
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 100vw;
    padding: 0px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  @media (min-width: 0px) and (max-width: 479px) {
    padding: 10px;
  }
`

const Info = styled.i`
  color: #00829b;
  cursor: pointer;
  margin-right: 1rem;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 12px;
  background-color: #fff;
  margin-bottom: calc(1vh + 0.5rem);
  border-radius: 3px;
  .ant-radio-group {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    text-align: center;
  }
  .ant-radio-button-wrapper > .ant-radio-button {
    width: ${props => (props.numbersOnly ? '40px' : '0')};
  }
  /* .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: ${props => props.dynamicColour};
    border-color: ${props => props.dynamicColour};
  }
  .ant-radio-button-wrapper:hover {
    color: ${props => props.dynamicColour};
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: ${props => props.dynamicColour};
  } */

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: ${props => props.dynamicColour};
    border-color: ${props => props.dynamicColour};
  }
  .ant-radio-button-wrapper:hover {
    color: ${props => props.dynamicColour};
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: ${props => props.dynamicColour};
    border-color: ${props => props.dynamicColour};
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: ${props => props.dynamicColour};
  }

  @media (min-width: 0px) and (max-width: 479px) {
    width: 100vw;
    padding: 0px;
  }
`

const StyledInputField = styled(InputField)`
  fieldset {
    background-color: white;
    opacity: 1;
    input {
      opacity: 1;
    }
  }
`

const Wrapper = styled.div``
export default MultiRadioList

// import React, { Component } from 'react'
// import SurveyWrapper from '../reducers/SurveyWrapper'
// import { conjointAnalysis, multiChoice } from '../question-types/QuestionTypes'
// import { v4 as uuidv4 } from 'uuid'

// const tempUserId = uuidv4()

// const Scratch = {
//   questions: [
//     {
//       id: 'test',
//       type: conjointAnalysis,
//       choices: 2,
//       attributes: [
//         {
//           id: 1,
//           value: 'Engine',
//           levels: [
//             { id: 1, value: 'Petrol' },
//             { id: 2, value: 'Electric' },
//           ],
//         },
//         {
//           id: 2,
//           value: 'Elf weeks',
//           levels: [
//             { id: 1, value: '2 weeks' },
//             { id: 2, value: '8 weeks' },
//           ],
//         },
//         {
//           id: 3,
//           value: 'Elf pay',
//           levels: [
//             { id: 1, value: '$10' },
//             { id: 2, value: '$20' },
//           ],
//         },
//       ],
//       levels: [],
//       text: 'Which of these 2 do you prefer?',
//     },
//     {
//       id: 'the-end',
//       type: 'welcomePage',
//       text: 'The end',
//     },
//   ],
//   settings: {
//     surveyName: 'scratch',
//     primaryColour: '#48a54c',
//     uuid: tempUserId,
//     companyLogo: {
//       images: null,
//     },
//   },
// }

// export default Scratch

import React, { Component } from 'react'
import SurveyWrapper from '../reducers/SurveyWrapper'
import {} from '../question-types/QuestionTypes'

const Scratch = {
  questions: [
    {
      id: 'map',
      type: 'simpleMap',
    },
  ],
  settings: {
    surveyName: 'scratch',
    companyLogo: {
      images: null,
    },
  },
}

export default Scratch

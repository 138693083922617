import styled, { keyframes } from 'styled-components/macro'

const slideUp = keyframes`
   0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`
const AnimatedDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  max-width: 800px;
  animation: 0.5s ${slideUp} ease;
  z-index: 1;
  @media (min-width: 0px) and (max-width: 479px) {
    width: 100vw;
    /* height: 80vh; */
    padding: 12px;
    margin: 0 auto;
    /* margin: auto; */
    align-items: center;
  }
  @media (min-width: 480px) and (max-width: 768px) {
    margin: auto;
    width: 100vw;
    align-items: center;
  }
`

export default AnimatedDiv

/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { Divider, Collapse } from 'antd'
import _ from 'lodash'
// import { Cheese } from '@styled-icons/fa-solid/Cheese'
import { Trees } from '@styled-icons/foundation/Trees'
import { Plant } from '@styled-icons/remix-line/Plant'
import { Fish } from '@styled-icons/fa-solid/Fish'
import { Cancel } from '@styled-icons/typicons/Cancel'
import SurveyWrapper from '../reducers/SurveyWrapper'
import { endLoop } from '../reducers/actions'
import { ReactComponent as CheeseImage } from '../../assets/images/PIAS/cheese.svg'
import { ReactComponent as BeefImage } from '../../assets/images/PIAS/beef-1.svg'
import { ReactComponent as SmileyOne } from '../../assets/images/PIAS/smiley-17.svg'
import { ReactComponent as SmileyTwo } from '../../assets/images/PIAS/smiley-4.svg'
import { ReactComponent as SmileyThree } from '../../assets/images/PIAS/smiley-6.svg'
import { ReactComponent as SmileyFour } from '../../assets/images/PIAS/smiley-2.svg'
import { ReactComponent as SmileyFive } from '../../assets/images/PIAS/smiley-13.svg'
import { ReactComponent as Help } from '../../assets/images/PIAS/help.svg'
import { ReactComponent as SmileyAngry } from '../../assets/images/PIAS/angry-smiley.svg'
// import { ReactComponent as Advisor } from '../../assets/images/PIAS/meeting-table.svg'
import { ReactComponent as Advisor } from '../../assets/images/PIAS/meeting-table.svg'
import { ReactComponent as Builder } from '../../assets/images/PIAS/builder.svg'
import { ReactComponent as Retailer } from '../../assets/images/PIAS/retail.svg'
import { ReactComponent as Researcher } from '../../assets/images/PIAS/research.svg'
import { ReactComponent as Educator } from '../../assets/images/PIAS/educator.svg'
import { ReactComponent as Advocate } from '../../assets/images/PIAS/advocator.svg'
import { ReactComponent as Exporter } from '../../assets/images/PIAS/exporter.svg'
import { ReactComponent as Developer } from '../../assets/images/PIAS/developer.svg'
import { ReactComponent as Deer } from '../../assets/images/PIAS/deer-1.svg'
import { ReactComponent as Pig } from '../../assets/images/PIAS/pork-1.svg'
import { ReactComponent as Bee } from '../../assets/images/PIAS/bee-1.svg'
import { ReactComponent as Science } from '../../assets/images/PIAS/science.svg'
import { ReactComponent as Trades } from '../../assets/images/PIAS/trades.svg'
import { ReactComponent as Arts } from '../../assets/images/PIAS/arts.svg'
import { ReactComponent as Commerce } from '../../assets/images/PIAS/commerce-new.svg'
import { ReactComponent as Agriculture } from '../../assets/images/PIAS/argri-new.svg'
import { ReactComponent as Poultry } from '../../assets/images/PIAS/poultry-1.svg'
import { ReactComponent as MPILogo } from '../../assets/images/PIAS/MPILogo.svg'
import { ReactComponent as ScarlattiLogo } from '../../assets/images/PIAS/scarlattiLogo.svg'
import { ReactComponent as GeoSocialLogo } from '../../assets/images/PIAS/geosocialLogo.svg'
import { ReactComponent as Arable } from '../../assets/images/PIAS/arable.svg'
import { ReactComponent as Vet } from '../../assets/images/PIAS/vet-science.svg'
import { ReactComponent as Viticulture } from '../../assets/images/PIAS/grapes.svg'
import { ReactComponent as QuestionMark } from '../../assets/images/PIAS/question-mark-icon.svg'

import geosocialLogo from '../../assets/images/PIAS/geo.png'
import scarlatti from '../../assets/images/PIAS/scarlatti.png'
import MpiLogo from '../../assets/images/PIAS/mpi.png'
import leftBackgroundImage from '../../assets/images/PIAS/image-1.png' // Todo compress image
import conferLogo from '../../assets/images/Confer-logo.png'

import {
  responsiveMatrix,
  responsiveSlider,
  multiselectDropdown,
  dragDrop,
  matrix,
  multiChoice,
  multiSelect,
  section,
  endSurvey,
  singleTextbox,
  autoComplete,
  slider,
  multiCardChoice,
  imageChoice,
  inputField,
  welcomePage,
  endPage,
  button,
  input,
  multiDropdown,
  socialNetwork,
  commentBox,
} from '../question-types/QuestionTypes'
import companies from '../../assets/data/PIAS/companies'
import companiesInfo from '../../assets/data/PIAS/companies-info'
import updatedCompanies from '../../assets/data/PIAS/companies_updated'
import { ReactComponent as SadFace } from '../../assets/images/SadFace.svg'

import { SmallText, Textbox } from '../../assets/styles/global'

const StyledTextbox = styled(Textbox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 800px;
  @media (min-width: 0px) and (max-width: 479px) {
    width: 100%;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1025px) {
    width: 100%;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 766px;
  overflow-wrap: break-word;
  word-break: normal;
  /* margin-bottom: 1rem; */
  .ant-divider-horizontal {
    margin: 15px 0;
    border-top: 1px solid #48a54c;
    width: 766px;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 80vw;
    .ant-divider-horizontal {
      width: 90vw;
    }
  }

  @media (min-width: 480px) and (max-width: 768px) {
    width: 80vw;
  }

  @media (min-width: 768px) and (max-width: 1025px) {
    width: 80vw;
  }

  p {
    overflow-wrap: break-word;
    word-break: normal;
  }
`

const StyledCollapse = styled(Collapse)`
  ${Wrapper} {
    width: auto;
  }
`

const StyledLi = styled.li`
  list-style: none;
  margin-bottom: 0.5rem;
`

// const IconBeef = styled(beefImage)`
//   width: 100px;
//   height: 100px;
// `

const IconMPI = styled(MPILogo)``
const IconScarlatti = styled(ScarlattiLogo)``
const IconGeosocial = styled(GeoSocialLogo)``

const IconScience = styled(Science)`
  width: 50px;
  height: 50px;
`
const IconVet = styled(Vet)`
  width: 50px;
  height: 50px;
`

const IconTrades = styled(Trades)`
  width: 50px;
  height: 50px;
`
const IconAgriculture = styled(Agriculture)`
  width: 50px;
  height: 50px;
`
const IconArts = styled(Arts)`
  width: 50px;
  height: 50px;
`
const IconCommerce = styled(Commerce)`
  width: 50px;
  height: 50px;
`

const IconAdvisor = styled(Advisor)`
  width: 2rem;
  height: 2rem;
`

const IconBuilder = styled(Builder)`
  width: 2rem;
  height: 2rem;
`

const IconDeveloper = styled(Developer)`
  width: 2rem;
  height: 2rem;
`

const IconRetailer = styled(Retailer)`
  width: 2rem;
  height: 2rem;
`
const IconResearcher = styled(Researcher)`
  width: 2rem;
  height: 2rem;
`
const IconAdvocate = styled(Advocate)`
  width: 2rem;
  height: 2rem;
`
const IconEducator = styled(Educator)`
  width: 2rem;
  height: 2rem;
`
const IconExporter = styled(Exporter)`
  width: 2rem;
  height: 2rem;
`
const IconBeef = styled(BeefImage)`
  width: 5rem;
  height: 3rem;
`

const IconHelp = styled(Help)`
  width: 30px;
  height: 30px;
`

const IconAngrySmiley = styled(SmileyAngry)`
  width: 2.5rem;
  height: 2.5rem;
  margin-top: -0.2rem;
  fill: red;
`

const IconSmileyOne = styled(SmileyOne)`
  width: 2.5rem;
  height: 2.5rem;
  margin-top: -0.2rem;
  fill: red;
`
const IconSmileyTwo = styled(SmileyTwo)`
  width: 2.5rem;
  height: 2.5rem;
  margin-top: -0.2rem;
  fill: orange;
`
const IconSmileyThree = styled(SmileyThree)`
  width: 2.5rem;
  height: 2.5rem;
  margin-top: -0.2rem;
`
const IconSmileyFour = styled(SmileyFour)`
  width: 2.5rem;
  height: 2.5rem;
  margin-top: -0.2rem;
  fill: #c7c239;
`
const IconSmileyFive = styled(SmileyFive)`
  width: 2.5rem;
  height: 2.5rem;
  margin-top: -0.2rem;
  fill: #1ab424;
`

const IconQuestionMark = styled(QuestionMark)`
  width: 3.5rem;
  height: 2.5rem;
  margin-top: -0.2rem;
  fill: #4d4d7d;
`

const IconCheese = styled(CheeseImage)`
  width: 100px;
  height: 55px;
  /* margin-top: 0.5rem; */
`

const IconArable = styled(Arable)`
  width: 100px;
  height: 55px;
  /* margin-top: 0.5rem; */
`

const IconCancel = styled(Cancel)`
  width: 100px;
  height: 55px;
`
const IconBee = styled(Bee)`
  width: 100px;
  height: 55px;
  /* margin-top: 0.5rem; */
`
const IconDeer = styled(Deer)`
  width: 100px;
  height: 55px;
  /* margin-top: 0.5rem; */
`

const IconPoultry = styled(Poultry)`
  width: 100px;
  height: 55px;
  /* margin-top: 0.5rem; */
`
const IconPork = styled(Pig)`
  width: 100px;
  height: 55px;
  /* margin-top: 0.5rem; */
`
const IconViticulture = styled(Viticulture)`
  width: 100px;
  height: 55px;
`

const IconPlant = styled(Plant)`
  width: 100px;
  height: 55px;
  /* margin-top: 0.5rem; */
`
const IconFish = styled(Fish)`
  width: 100px;
  height: 55px;
  /* margin-top: 0.5rem; */
`

const IconTrees = styled(Trees)`
  width: 100px;
  height: 68px;
  /* margin-top: 0.5rem; */
`

const SectionInfo = styled.p`
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0rem;
`

const QuestionInfo = styled.p`
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 1rem;
`

const SmallSection = styled.p`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0rem;
`

const SmallQuestionInfo = styled.p`
  font-size: 16px;
  margin-top: 0.5rem;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0;
`

const StyledDivider = styled(Divider)`
  .ant-divider-horizontal {
    margin: 7px 0;
  }
`

const Title = styled.p`
  margin-bottom: 0.5rem;
  font-weight: 700;
`

const StyledP = styled.p`
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0rem;
  margin-top: 0rem;
`

const HalfP = styled.p`
  font-size: 1rem;
  margin-bottom: 0.5rem;
`

const HeaderQ = styled.h1`
  font-size: 18px;
  font-weight: bold;
  color: #48a54c;
`

const QuestionText = styled.p`
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0;
`

const QuestionWithMargin = styled.p`
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 1rem;
`

const Disclaimer = styled.p`
  font-size: 14px;
  margin-top: 0.5rem;
  margin-bottom: 0rem;
  color: rgba(0, 0, 0, 0.65);
  font-style: italic;
  font-weight: 400;
`

const SectionHeader = styled.h1`
  font-size: 18px;
  font-weight: 600;
  color: #48a54c;
`
const Button = styled.button`
  font-size: 1rem;
  height: 100%;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 3px;
  font-weight: 400;
`

const LI = styled.li`
  margin-bottom: 0.5rem;
`

const GreenText = styled.span`
  color: #48a54c;
  margin-right: 0.5rem;
`

const StyledUL = styled.ul`
  margin-left: 1rem;
  li {
    margin-bottom: 0.5rem;
  }
`

const Container = styled.div`
  background-color: red;
  height: 100vh;
  width: 100vw;
`

const InterventionSection = styled.div`
  margin-top: 1rem;
`

const BlackBorder = styled.div`
  width: 766px;
  border: 1px solid #262626;
  padding: 15px;

  @media (min-width: 0px) and (max-width: 479px) {
    width: 90vw !important;
  }
  @media (min-width: 480px) and (max-width: 768px) {
    width: 90vw;
  }

  @media (min-width: 768px) and (max-width: 1025px) {
    width: 90vw;
  }
`
const { Panel } = Collapse

// const filterCompanies = () => {
//   companiesInfo.filter(item => item.Org_Name !== item.Org_Name)
//   // newAnswer.value = newAnswer.value.filter(
//   //   item => item.value !== answer.value,
//   console.log(companiesInfo.filter(item => item.Org_Name !== item.Org_Name))
// }

const Issue1 = (
  <BlackBorder>
    <p style={{ marginBottom: '1rem' }}>
      <GreenText>The Issue:</GreenText>The advisory business model makes it difficult to recruit new
      advisers
    </p>
    <StyledCollapse ghost>
      <Panel header="Click to read more" key="1">
        <Wrapper>
          {/* <Title>Scope of the issue</Title>
          <p style={{ marginBottom: '1rem' }}>
            This issue affects farm system consultants and horticulture advisers in at least some
            sub-sectors. It may also affect forestry advisers although this did not come through
            strongly in interviews. T his issue relates mainly to Welcome and Optional advice.
          </p>
          <Title>Discussion of the issue</Title> */}
          <p style={{ marginBottom: '1rem' }}>
            Neither farm system consultancy nor horticultural advisory lend themselves easily to the
            ‘pyramid’ business model that characterises many other advisory professions - they lack
            the types of roles and tasks that can be used to develop new staff to the point that
            they can operate independently with producer clients. Further, it takes three to five
            years to develop new farm system consultants and horticulture advisers. Taken together,
            these features of the advisory business model limit the incentives for private advisory
            firms to employ and develop new advisers.
          </p>
          <p style={{ marginBottom: '1rem' }}>
            This bottleneck appears to be a bigger constraint than the supply of people willing to
            work as farm system consultants. One validation of this hypothesis is seen in NZIPIM’s
            Survey of the Rural Profession in 2018 in which Uncertain workflow for new consultants
            ranked above other factors’, including Lack of consultants available, as a barrier to
            taking on new consultants. This may also be true for horticulture advisers although this
            is less clear from interviews and may vary from horticulture sub-sector to sub-sector.
          </p>
          <p style={{ marginBottom: '1rem' }}>
            In the past the MAF Advisory Services Division and its predecessors acted as an
            intervention to overcome this bottleneck. By employing and training inexperienced staff
            it developed many people that may not have become advisers otherwise. Even a quarter of
            a century after this group was privatised, it seems likely that the capacity of the
            advisory system today is stronger than it would have been had this group not existed.
          </p>
        </Wrapper>
      </Panel>
    </StyledCollapse>
  </BlackBorder>
)

const Issue2 = (
  <BlackBorder>
    <p style={{ marginBottom: '1rem' }}>
      <GreenText>The Issue:</GreenText>Uncertainty about demand for Farm Environment Plans limits
      investment in capability and capacity
    </p>
    <StyledCollapse ghost>
      <Panel header="Click to read more" key="1">
        <Wrapper>
          {/* <Title>Scope of the issue</Title>
          <p style={{ marginBottom: '1rem' }}>
            This issue is relevant to all land-based primary industries and to a variety of advisers
            that prepare farm environment plans. This issue relates mainly to No choice advice.
          </p>
          <Title>Discussion of the issue</Title> */}
          <p style={{ marginBottom: '1rem' }}>
            One widely discussed issue relating to advisory capacity is the ability to prepare the
            volume of farm environment plans (FEPs) expected to be needed to meet upcoming
            regulatory requirements. There is a need to build FEP capability and capacity. We think
            the market capable of doing this without direct intervention, but more can be done to
            reduce uncertainty about the scope and timing of demand for FEPs.
          </p>
          <p>
            <b>Capacity to prepare FEPs.</b>
          </p>
          <p>
            Interviews undertaken within this research revealed differing perspectives on how much
            of an issue this presents.
          </p>
          <p>
            Despite a lack of specialist environmental advisers, it seems likely there are
            sufficient advisers able to meet current demand, if not some future demand, for FEP
            preparation, due to the variety of generalist advisers with the capability to prepare
            FEPs found in several parts of the advisory system. Indeed, some firms interviewed
            within this work believed the business of preparing FEPs to be a well-served and
            competitive space, and as a result had actively chosen to not enter it.
          </p>
          <p>
            Some advisory organisations are confident they have the potential to grow capacity to
            prepare FEPs relatively quickly. This is due to the specific skill set required to
            prepare an FEP compared to the time required to develop the broader production systems
            capability required to help producers remain productive and sustainable while operating
            within the environmental constraints set by their FEPs.
          </p>
          <p>
            Moreover, training and certification programmes around FEPs already exist, and it seems
            likely that providers of these will respond to new opportunities to demand for new
            training and certification programmes as they are required, albeit with a role for
            government to endorse these.
          </p>
          <p>
            Other models to develop FEPs are being considered for example, by the horticulture
            sector in which producers are trained to become capable of developing their FEPs
            themselves, with advisers taking only a certification role. Alternatively, advisory
            firms could employ people with the skills to partially complete FEPs and have their
            fully capable advisers take an oversight and certification role.
          </p>
          <p>
            Taken together, the points above suggest that advisory organisations will be able to
            move relatively quickly to build capacity and capability if the market provides clear
            signals indicate that they should do so.
          </p>
          <p>
            <b>Producer demand for FEP capabilities</b>
          </p>
          <p style={{ marginBottom: '1rem' }}>
            The main constraint to capacity and capability building is therefore the uncertainty of
            the market signals about the nature and timing of regulation. In other words, although a
            market solution has potential to build FEP capability and capacity, signals need to be
            made clearer for advisory businesses to invest.
          </p>
        </Wrapper>
      </Panel>
    </StyledCollapse>
  </BlackBorder>
)

const Issue3 = (
  <BlackBorder>
    <p style={{ marginBottom: '1rem' }}>
      <GreenText>The Issue:</GreenText>Capability building may have costs greater than their
      benefits for advisers
    </p>
    <StyledCollapse ghost>
      <Panel header="Click to read more" key="1">
        <Wrapper>
          {/* <Title>Scope of the issue</Title>
          <p style={{ marginBottom: '1rem' }}>
            This issue is relevant across all primary industries and advisory services. It is most
            relevant to Optional advice.
          </p>
          <Title>Discussion of the issue</Title> */}
          <p style={{ marginBottom: '1rem' }}>
            During this work, many respondents noted that capability levels vary across the sector
            and are likely to fall short of industry need in the future. A range of areas were
            proposed in which advisers could strengthen their capabilities. These include
            capabilities where upskilling offers a direct path to greater billings as well those
            where the pathway to increased earnings is indirect including:
          </p>
          <StyledUL>
            <li>
              Extension and ‘soft’ skills – the skills relating to relationship engagement and
              management, facilitation, communication and support of practice change.
            </li>
            <li>
              Integration of advice relating to production systems, environmental management and
              business/financial planning.
            </li>
            <li>
              Deeper understanding of technology and data and their application within production
              systems.
            </li>
          </StyledUL>
          <p>
            Advisers can build capabilities such as these in various ways. A good system of support
            would combine:
          </p>
          <StyledUL>
            <li>
              <b>Training,</b> with or without <b>certification</b>
            </li>
            <li>
              <b>Peer to peer support </b>(for example, adviser small groups, communities of
              practice, good practice portal / resources)
            </li>
            <li>
              <b>Mentoring / coaching</b> (both formal and informal).{' '}
            </li>
          </StyledUL>
          <p>
            ...incorporated with a robust <b>registration</b> system.
          </p>
          <p>
            All of these capability-building mechanisms impose an opportunity cost for the time
            spent to participate, and many require a cash investment as well. Set against these
            opportunity costs, the benefits of capability-building may be transparent for some
            skills, such as the ability to prepare a farm environment plan or to assess
            body-condition scores, which offer a direct path to greater billings and so have a clear
            value proposition. But the benefits resulting from other skills, such as the three
            bullet points above, are more diffuse, hampering advisory recognition of their value.
          </p>
          <p>
            Proposals for interventions that build adviser capability therefore need to address the
            opportunity costs of capability-building and overcome the cognitive biases that hinder
            individuals from taking action. This will be possible by bundling interventions with
            appropriate incentives for advisers to participate.
          </p>
          {/* <p>
            To address the opportunity costs of capability-building, and to overcome the cognitive
            biases that hinder individuals taking action, any proposal for an intervention to build
            adviser capability needs to be bundled with appropriate incentives for advisers to
            participate.
          </p> */}
        </Wrapper>
      </Panel>
    </StyledCollapse>
  </BlackBorder>
)

const Issue4 = (
  <BlackBorder>
    <p style={{ marginBottom: '1rem' }}>
      <GreenText>The Issue:</GreenText>Limited collaboration between PIAS stakeholders leads to
      knowledge gaps
    </p>
    <StyledCollapse ghost>
      <Panel header="Click to read more" key="1">
        <Wrapper>
          {/* <Title>Scope of the issue</Title>
          <p style={{ marginBottom: '1rem' }}>
            This issue is relevant to all primary sectors, advisers and types of advice.
          </p>
          <Title>Discussion of the issue</Title> */}
          <p style={{ marginBottom: '1rem' }}>
            Since the 1980s, PIAS advisory services have become increasingly pluralised, with many
            now operating as private businesses. There is evidence that formal connections between
            advisers, researchers and producers have weakened, with advisory and research-based
            knowledge and expertise now operating in silos.
          </p>
          <p>
            To make matters more complex, advisers now need to develop their knowledge and expertise
            to support producers with more stringent and complex management of inputs, outputs and
            environmental resources. In some cases, such as land use change, the range of skills
            needed is so broad that advisers with a range of specialist skill sets are needed to
            address systemic implications of change. Realistically, one adviser could not develop
            all the advisory skills now required but concerns around a loss of competitive advantage
            appears to disincentivise collaboration between firms. This is particularly problematic
            because the trust-based nature of advisory services means that producers require
            confidence to seek and use advisory services and may not have time to be able to build
            multiple relationships or have access to advisers of different specialties.
          </p>
          <p>
            The information gaps that have resulted from this lack of collaboration are now
            recognised as barriers to advisers and producers being able to fully address the
            physical and economic implications of more productive and sustainable land use.
          </p>
          <p>
            Transdisciplinary collaboration between advisers, producers and researchers would
            firstly bring together diverse knowledge and experience about farm systems, economic
            issues and environmental issues. Collaboration between stakeholders would provide
            opportunities to learn from one another; research would become more relevant and
            effective; and innovation would be fostered. Secondly, collaboration would encourage
            networking, and provide opportunities to build alliances or partnerships. Such
            partnerships could be a suitable solution in situations where a single adviser cannot
            provide producer clients with the breadth of advice needed.
          </p>
        </Wrapper>
      </Panel>
    </StyledCollapse>
  </BlackBorder>
)

const Issue5 = (
  <BlackBorder>
    <p style={{ marginBottom: '1rem' }}>
      <GreenText>The Issue:</GreenText>The system challenges partnership between Māori agribusiness
      and advisers
    </p>
    <StyledCollapse ghost>
      <Panel header="Click to read more" key="1">
        <Wrapper>
          {/* <Title>Scope of the issue</Title>
          <p style={{ marginBottom: '1rem' }}>
            This issue relates to all sectors that contain Māori agribusinesses as well as all types
            of advice. It is particularly relevant to developing and underdeveloped Māori
            agribusinesses that require higher levels of support.
          </p>
          <Title>Discussion of the issue</Title> */}
          <p style={{ marginBottom: '1rem' }}>
            This issue is presented as a combination of two sub-issues, both of which are relatively
            polarising within the advisory community. Based on information shared by interviewees
            and feedback to an earlier draft of proposed interventions we recognise that there are a
            variety of views around both sub-issues. We propose interventions that respond to these
            different viewpoints. The core issue at hand is not one of ‘who is at fault’, but how
            can the system within which both Māori agribusinesses and advisers operate be more
            effective. As such we draw on the Te Tiriti o Waitangi principle of partnership to
            identify potential strategies. We combine the discussion of these two sub-issues here as
            some proposed interventions potentially address both.
          </p>
          <p>
            <b>Capability gap</b>
          </p>
          <p>
            Although many, and perhaps most, Māori agribusinesses already utilise advisers, and
            often have long-standing and strong relationships with them, there is some agreement
            across advisers interviewed in this research, that there is a shortage of advisers with
            the appropriate capabilities for this work. This view echoes that of producers
            interviewed by Manaaki Whenua, in their work.
          </p>
          <p>
            For example, some respondents believe that advisers consulting with Māori agribusinesses
            businesses do not necessarily have an understanding of Te Ao Māori or Tikanga. As a
            result, advisers’ skill sets and values may not always align with those of the
            businesses they serve. Respondents also noted that some advisers, accustomed to
            transaction-based relationships, may focus on profit and productivity, rather than
            longer term goals and relationships highly valued by Māori agribusinesses.
          </p>
          <p>
            <b>The role of the adviser</b>
          </p>
          <p>
            Currently most advisers mainly work with clients who are the sole owner/operators of
            their businesses. As such, challenges may arise for advisers when supporting Māori
            agribusinesses based on collective ownership structures. Unlike a sole owner/operator,
            trustees of developing Māori agribusinesses may have limited knowledge of farming or
            primary production despite being responsible for decision making for the farm. Trustees
            may need greater opportunities to develop governance and business knowledge of their
            farming system to enable them to become less reliant on advisers.
          </p>
          <p>
            Out of necessity to maintain the farm business some advisers have been focused on
            operational management and may lack the skills or mandate, to address broader capability
            needs of farm managers, workers and trustees. For many developing Māori agribusinesses
            hiring an adviser represents a very significant cost that constrains funds available for
            reinvestment into the farm. This may cause the relationship between adviser and Māori
            agribusinesses to break down. Some interviewees perceive that some advisers have
            intentionally made Māori agribusinesses dependent on them however the point was also
            made that without the advisers’ support, some farm businesses would not have survived.
          </p>
          <p>
            Additional challenges identified by respondents within this research include that some
            advisers, inexperienced with a collective ownership structure, are overly focused on
            supporting the decision-making of farm managers at the expense of supporting the
            strategic decision-making responsibility of trustees and directors. The structure of
            Māori agribusiness trusts is such that advisers need to spend time developing
            relationships, providing decision makers with multiple options and supporting trustees
            to explain decisions to the wider whānau who may contest decisions.
          </p>
        </Wrapper>
      </Panel>
    </StyledCollapse>
  </BlackBorder>
)

const Issue6 = (
  <BlackBorder>
    <p style={{ marginBottom: '1rem' }}>
      <GreenText>The Issue:</GreenText>Advisory capacity and capability are constrained by demand as
      well as supply
    </p>
    <StyledCollapse ghost>
      <Panel header="Click to read more" key="1">
        <Wrapper>
          {/* <Title>Scope of the issue</Title>
          <p style={{ marginBottom: '1rem' }}>
            This issue is relevant for the entire primary industry and all advisory services. It
            relates mostly to Welcome and Optional advice.
          </p>
          <Title>Discussion of the issue</Title> */}
          <p style={{ marginBottom: '1rem' }}>
            There are many complex and at times subtle reasons why producers do or do not use an
            adviser. This is especially so considering that decisions are made within a social
            context.
          </p>
          <p>
            While the current study has not sought to understand all aspects of producer decision
            making, two factors relating to producer perception (or ‘cognitive biases’) are:
          </p>
          <StyledUL>
            <li>
              Advice may be perceived as poor value for money when results are diffuse, uncertain
              and/or slow to eventuate, even if the expected benefits greatly outweigh the costs.
              Additionally, confounding variables can make it difficult to attribute positive change
              to the advisory service.
            </li>
            <li>
              Producers may perceive social pressure, due to an idea that if a producer uses
              advisory services he/she is a ‘failure’; Use of an adviser is therefore not supported
              by family and peers.
            </li>
          </StyledUL>
          <p>
            Such cognitive biases contribute to a gap between industry need and producer demand. If
            this gap did not exist market signals would more clearly incentivise advisers to build
            capacity the capability needed to support producers to meet upcoming productive and
            sustainable land use challenges.
          </p>
          <p>
            An alternative or complementary approach to intervening to grow advisory capability and
            capacity directly is, therefore, to grow producer demand for advisory services. Market
            signals will then encourage advisers to respond to this demand.
          </p>
          <p>
            However, growing producer demand is not an easy problem to solve and we do not claim to
            have all the ideas or context needed. We think that further work should explore this.
            The interventions proposed below are essentially targeted investigations to contribute
            to this. Such innovation projects carry high risk but potentially high returns if
            successful approaches are discovered.
          </p>
        </Wrapper>
      </Panel>
    </StyledCollapse>
  </BlackBorder>
)

const companyNames = updatedCompanies.map((item, index) => ({
  id: index + 1,
  value: item.Org_Name,
}))

const distinctCompanies = Object.values(
  companyNames.reduce((acc, item) => ((acc[item.value] = item), acc), {}),
) // only return distinct values from subCategories

console.log('distinct', distinctCompanies)

const subCategories = companiesInfo.map((item, index) => ({
  // id: index,
  value: item.Org_SubCategory,
})) // returns array of objects with value of item.org_subcategory

const distinctSubCategories = Object.values(
  subCategories.reduce((acc, item) => ((acc[item.value] = item), acc), {}),
).sort((a, b) => {
  if (a.value < b.value) {
    return -1
  } else if (a.value > b.value) {
    return 1
  } else {
    return 0
  }
}) // only return distinct values from subCategories

// console.log('sub', distinctSubCategories)

const mainCategories = companiesInfo.map((item, index) => ({
  // id: index,
  value: item.Org_MainCategory,
})) // returns array of objects with value of item.org_subcategory

const distinctMainCategories = Object.values(
  mainCategories.reduce((acc, item) => ((acc[item.value] = item), acc), {}),
).sort((a, b) => {
  if (a.value < b.value) {
    return -1
  } else if (a.value > b.value) {
    return 1
  } else {
    return 0
  }
}) // only return distinct values from subCategories

// console.log('main', distinctMainCategories)

// const professionalDevelopment = [
//   { id: 1, text: 'Future farming systems', value: 'Future farming systems' },
//   { id: 2, text: 'Environmental management', value: 'Environmental management' },
//   { id: 3, text: 'Farm technology', value: 'Farm technology' },
//   { id: 4, text: 'Regulatory requirements', value: 'Regulatory requirements' },
//   { id: 5, text: 'Production-focused topics', value: 'Production-focused topics' },
//   {
//     id: 6,
//     text: 'Precision technology (electronic sensors, drones, etc)',
//     value: 'Precision technology',
//   },
//   { id: 7, text: 'Carbon trading and ETS', value: 'Carbon trading and ETS' },
//   { id: 8, text: 'Biosecurity', value: 'Biosecurity' },
//   { id: 9, text: 'Health and safety', value: 'Health and safety' },
//   { id: 10, text: 'Animal welfare', value: 'Animal welfare' },
// ].sort(function shuffleArray(a, b) {
//   return 0.5 - Math.random()
// })

const professionalDevelopment = [
  {
    id: 1,
    text: 'Changing the farm system (e.g diversification or change of land use)',
    value: 'Changing farm system',
  },
  {
    id: 2,
    text: 'Ecosystem services, biodiversity, covenants',
    value: 'Ecosystem services, biodiversity, covenants',
  },
  {
    id: 3,
    text: 'Environmental sustainability (e.g. going organic, integrated farm-environment)',
    value: 'Environmental sustainability',
  },
  {
    id: 4,
    text: 'Managing water quality and water use',
    value: 'Managing water quality and water use',
  },
  {
    id: 5,
    text: 'Preparing / advising on farm environment plans',
    value: 'Preparing / advising on farm environment plans',
  },
  {
    id: 6,
    text: 'Financial management',
    value: 'Financial management',
  },
  { id: 7, text: 'Governance of Māori land', value: 'Governance of Māori land' },
  {
    id: 8,
    text: 'Changing production, productivity and efficiency',
    value: 'Changing production, productivity and efficiency',
  },
  {
    id: 9,
    text: 'Marketing and business opportunities',
    value: 'Marketing and business opportunities',
  },
  { id: 10, text: 'Health and wellbeing', value: 'Health and wellbeing' },
  {
    id: 11,
    text: 'On-farm biosecurity and pest management',
    value: 'biosecurity, pest management',
  },
  { id: 12, text: 'Managing greenhouse gas emissions', value: 'Managing greenhouse gas emissions' },
  {
    id: 13,
    text: 'Increasing resilience to severe weather patterns and/or climate change',
    value: 'Increasing resilience to severe weather patterns and/or climate change',
  },
  { id: 14, text: 'Changing regulations and consents', value: 'Changing regulations and consents' },
  { id: 15, text: 'Succession planning', value: 'Succession planning' },
].sort(function shuffleArray(a, b) {
  return 0.5 - Math.random()
})

const adviserSupport = [
  {
    id: 1,
    text: 'Changing the farm system (e.g diversification or change of land use)',
    value: 'Changing farm system',
  },
  {
    id: 2,
    text: 'Ecosystem services, biodiversity, covenants',
    value: 'Ecosystem services, biodiversity, covenants',
  },
  {
    id: 3,
    text: 'Environmental sustainability (e.g. going organic, integrated farm-environment)',
    value: 'Environmental sustainability',
  },
  {
    id: 4,
    text: 'Managing water quality and water use',
    value: 'Managing water quality and water use',
  },
  {
    id: 5,
    text: 'Preparing / advising on farm environment plans',
    value: 'Preparing / advising on farm environment plans',
  },
  {
    id: 6,
    text: 'Financial management',
    value: 'Financial management',
  },
  { id: 7, text: 'Governance of Māori land', value: 'Governance of Māori land' },
  {
    id: 8,
    text: 'Changing production, productivity and efficiency',
    value: 'Changing production, productivity and efficiency',
  },
  {
    id: 9,
    text: 'Marketing and business opportunities',
    value: 'Marketing and business opportunities',
  },
  { id: 10, text: 'Health and wellbeing', value: 'Health and wellbeing' },
  {
    id: 11,
    text: 'On-farm biosecurity and pest management',
    value: 'biosecurity, pest management',
  },
  { id: 12, text: 'Managing greenhouse gas emissions', value: 'Managing greenhouse gas emissions' },
  {
    id: 13,
    text: 'Increasing resilience to severe weather patterns and/or climate change',
    value: 'Increasing resilience to severe weather patterns and/or climate change',
  },
  { id: 14, text: 'Changing regulations and consents', value: 'Changing regulations and consents' },
  { id: 15, text: 'Succession planning', value: 'Succession planning' },
].sort(function shuffleArray(a, b) {
  return 0.5 - Math.random()
})

const issuesArray = [
  {
    id: 1,
    issueId: 'Issue #1',
    text: 'The advisory business model makes it difficult to recruit new advisors',
    value: 'The advisory business model makes it difficult to recruit new advisors',
  },
  {
    id: 2,
    issueId: 'Issue #2',
    text:
      'Uncertainty about demand for Farm Environment Plans inhibits advisers investing in upskilling',
    value:
      'Uncertainty about demand for Farm Environment Plans inhibits advisers investing in upskilling',
    // text: 'There is a shortage of advisers ready to prepare farm environment plans',
    // value: 'There is a shortage of advisers ready to prepare farm environment plans',
  },
  {
    id: 3,
    issueId: 'Issue #3',
    text:
      'The opportunity costs of capability development often outweigh the benefits for advisers',
    value:
      'The opportunity costs of capability development often outweigh the benefits for advisers',
  },
  {
    id: 4,
    issueId: 'Issue #4',
    text:
      'A lack of collaboration means opportunities for knowledge sharing and networking are missed',
    value:
      'A lack of collaboration means opportunities for knowledge sharing and networking are missed',
  },
  // {
  //   id: 5,
  //   issueId: 'Issue #5',
  //   text:
  //     'Low farmer and grower demand for advisory services is constraining advisor numbers.',
  //   value:
  //     'Low farmer and grower demand for advisory services is constraining advisor numbers.',
  // },
  {
    id: 6,
    issueId: 'Issue #6',
    text: 'Low farmer and grower demand for advisory services is constraining advisor numbers',
    value: 'Low farmer and grower demand for advisory services is constraining advisor numbers',
    // type: 'multiChoice',
    // marginBottom: '0.5rem',
    // width: '33.5rem',
  },
].sort(function shuffleArray(a, b) {
  return 0.5 - Math.random()
})

const skipChoice = {
  id: 8,
  text: 'No comment - skip to the end of the survey',
  value: 'skip',
  type: 'multiChoice',
  marginBottom: '0.5rem',
  width: '48.5rem',
}

// console.log('companies', distinctCompanies)

const Pias = {
  questions: [
    {
      id: 'intro-page',
      type: welcomePage,
      text: (
        <StyledTextbox>
          <h1>Sorry, this survey is now closed.</h1>
          <SadFace />
          {/* <HeaderQ>Supporting the primary industry advisory system</HeaderQ>
          <StyledP style={{ marginBottom: '1rem' }}>
            The challenges of 2020 have proven just how valuable New Zealand’s primary sector is,
            and will be into the future. The advisory system is essential. Supporting advisors is
            important to ensure producers are productive and sustainable.
          </StyledP>
          <StyledP style={{ marginBottom: '1rem' }}>
            If you provide{' '}
            <b>
              advice, research, or services to primary industry producers (farmers, growers,
              foresters)
            </b>
            , please take this survey to help us understand your skills, frustrations, and ideas
            around how the advisory system can be strengthened.
          </StyledP>
          <StyledP style={{ marginBottom: '1rem' }}>
            We have designed this survey so that it can be as short as 10 minutes. However, this
            depends on how much you would like to share.
          </StyledP>
        
          <StyledP style={{ marginBottom: '1rem' }}>
            Please click <i>NEXT</i> to begin
          </StyledP> */}
        </StyledTextbox>
      ),
    },

    // {
    //   id: 'interventions-intro',
    //   mobileHeight: '5.5rem',
    //   required: true,
    //   section: () => {
    //     return (
    //       <Wrapper>
    //         <QuestionInfo>
    //           <GreenText>Issues and interventions</GreenText>
    //         </QuestionInfo>
    //         <Divider style={{ marginBottom: '1rem', marginTop: '0rem', width: '24rem' }} />
    //       </Wrapper>
    //     )
    //   },
    //   width: '49.5rem',
    //   text: () => {
    //     return (
    //       <div>
    //         <p style={{ marginBottom: '0.5rem', fontSize: '1rem' }}>
    //           Below are some of the key issues affecting the advisory system (which we will describe
    //           in more detail shortly).
    //           {/* Over the past few years we have been talking to advisers about the key issues
    //           affecting the advisory system. We would like your feedback now on some possible
    //           interventions that could address these issues and strengthen the capacity and
    //           capability of the advisory system. */}
    //         </p>
    //         <p style={{ marginBottom: '0.5rem', fontSize: '1rem' }}>
    //           Please rank these issues in order of priority you think they should be addressed, from
    //           highest priority (at the top) to lowest (bottom).
    //         </p>
    //         {/* <p style={{ marginBottom: '0.5rem' }}>
    //           Which of the following issues do you think should be priority to address?
    //         </p> */}
    //         {/* <SmallText style={{ fontStyle: 'italic' }}>
    //           You will have the option to comment on as many or as few issues as you would like.{' '}
    //         </SmallText> */}
    //       </div>
    //     )
    //   },
    //   type: 'ranking',
    //   mobileHeight: '4rem',
    //   verticalGrid: true,
    //   choices: ({ answers }) => {
    //     const Issue5 = {
    //       id: 5,
    //       issueId: 'Issue #5',
    //       text:
    //         'There is a shortage of advisers with the required cultural competency to work with Māori agribusiness',
    //       value:
    //         'There is a shortage of advisers with the required cultural competency to work with Māori agribusiness',

    //       marginBottom: '0.5rem',
    //     }

    //     console.log('answers in ranking', answers)

    //     if (
    //       !_.isUndefined(answers) &&
    //       'advisor-maori-agg-business' in answers &&
    //       'value' in answers['advisor-maori-agg-business'] &&
    //       answers['advisor-maori-agg-business'].value !== 'None'
    //     ) {
    //       issuesArray.push(Issue5)
    //     }

    //     // console.log('issuesArray', issuesArray)

    //     const skipChoice = {
    //       id: 8,
    //       text: 'No comment - skip to the end of the survey',
    //       value: 'skip',
    //       type: 'multiChoice',
    //       marginBottom: '0.5rem',
    //       width: '48.5rem',
    //     }

    //     issuesArray.push(skipChoice)

    //     return _.uniqBy(issuesArray, 'id')
    //   },
    //   next: ({ answers }) => {
    //     // if (answers['interventions-intro'].id === 7) {
    //     //   let issuesArray = ['Issue #1', 'Issue #2', 'Issue #3', 'Issue #4', 'Issue #5', 'Issue #6']
    //     //   const random = Math.floor(Math.random() * issuesArray.length)
    //     //   return issuesArray[random]
    //     // }

    //     if (answers['interventions-intro'].value[0].issueId) {
    //       return answers['interventions-intro'].value[0].issueId
    //     }
    //     return 'feedback-comments'
    //   },
    // },
    // {
    //   id: 'which-org-do-you-work-at',
    //   required: true,
    //   width: '34.5rem',
    //   section: (
    //     <Wrapper>
    //       <QuestionInfo>
    //         To begin with, please tell us a bit about your role in the advisory system.
    //       </QuestionInfo>
    //       <Divider style={{ marginBottom: '1rem', marginTop: '0rem', width: '24rem' }} />
    //     </Wrapper>
    //   ),
    //   text: (
    //     <Wrapper>
    //       <StyledP>What organisation do you currently work at?</StyledP>
    //       <Disclaimer style={{ fontSize: '14px', marginTop: '0.5rem' }}>
    //         Be assured that this is for research purposes only, and we will not contact you or your
    //         organisation.
    //       </Disclaimer>
    //     </Wrapper>
    //   ),
    //   type: autoComplete,
    //   verticalGrid: true,
    //   choices: [
    //     // {
    //     //   id: 2,
    //     //   text: 'I am an independent consultant',
    //     //   value: 'I am an independent consultant',
    //     //   width: '489px',
    //     // },
    //     {
    //       id: 3,
    //       text: 'I would rather not disclose this',
    //       value: 'I would rather not disclose this',
    //       width: '489px',
    //     },
    //   ],
    //   options: distinctCompanies,
    //   next: ({ answers }) => {
    //     if (answers['which-org-do-you-work-at'].value === 'I would rather not disclose this') {
    //       return 'advisory-system'
    //     }
    //     return 'role'
    //   },
    // },
    // Q2.2
    // {
    //   id: 'advisory-system',
    //   text: 'How would you best describe the area of the advisory system it mainly operates in?',
    //   type: autoComplete,
    //   required: true,
    //   multi: true,
    //   // options: distinctMainCategories,
    //   choices: [
    //     {
    //       id: 1,
    //       text: 'Main advisor category',
    //       placeholder: distinctMainCategories[0].value,
    //       options: distinctMainCategories,
    //     },
    //     // {
    //     //   id: 2,
    //     //   value: 'Sub-category',
    //     //   text: 'Sub-category',
    //     //   placeholder: distinctSubCategories[0].value,
    //     //   options: distinctSubCategories,
    //     // },
    //   ],
    // },

    // Q1.3
    {
      id: 'role',
      type: multiSelect,
      required: true,
      verticalGrid: true,
      section: (
        <Wrapper>
          <QuestionInfo>
            To begin with, please tell us a bit about your role in the advisory system.
          </QuestionInfo>
          <Divider style={{ marginBottom: '1rem', marginTop: '0rem', width: '24rem' }} />
        </Wrapper>
      ),
      // useIcon: true,
      text: 'Which of the following best describes your role?',
      disclaimer: '',
      // next: ({ answers }) => {
      //   if (answers['role'][9]) {
      //     return 'which-primary-sector'
      //   }
      //   return 'specifics'
      // },
      width: '24rem',
      // height: '95px',
      // height: '120px',
      choices: [
        {
          id: 1,
          text: 'Farm consultant',
          value: 'Farm consultant',
          field: 'consultant',
          // icon: <IconAdvisor fill="currentColor" />,
          toolTip: 'Your role is primarily to give advice to producers.',
        },
        {
          id: 2,
          text: 'Environmental consultant',
          value: 'Environmental consultant',
          field: 'consultant',
          // icon: <IconAdvisor fill="currentColor" />,
          toolTip: 'Your role is primarily to give advice to producers.',
        },
        {
          id: 3,
          text: 'Forestry consultant',
          value: 'Forestry consultant',
          field: 'consultant',
          // icon: <IconAdvisor fill="currentColor" />,
          toolTip: 'Your role is primarily to give advice to producers.',
        },
        {
          id: 4,
          text: 'Horticultural consultant',
          value: 'Horticultural consultant',
          field: 'consultant',
          // icon: <IconAdvisor fill="currentColor" />,
          toolTip: 'Your role is primarily to give advice to producers.',
        },
        {
          id: 5,
          text: 'People management consultant',
          value: 'People management consultant',
          field: 'consultant',
          // icon: <IconAdvisor fill="currentColor" />,
          toolTip: 'Your role is primarily to give advice to producers.',
        },
        {
          id: 6,
          text: 'Banker',
          value: 'Banker',
          // icon: <IconBuilder fill="currentColor" />,
          toolTip: 'You provide a service to producers other than advice. E.g. rural contracting. ',
        },
        {
          id: 7,
          text: 'Fertiliser company representative',
          value: 'Fertiliser company representative',
          // icon: <IconBuilder fill="currentColor" />,
          toolTip: 'You provide a service to producers other than advice. E.g. rural contracting. ',
        },
        {
          id: 8,
          text: 'Rural accountant',
          value: 'Rural accountant',
          // icon: <IconBuilder fill="currentColor" />,
          toolTip: 'You provide a service to producers other than advice. E.g. rural contracting. ',
        },
        {
          id: 9,
          text: 'Veterinarian',
          value: 'Veterinarian',
          // icon: <IconBuilder fill="currentColor" />,
          toolTip: 'You provide a service to producers other than advice. E.g. rural contracting. ',
        },
        {
          id: 10,
          text: 'Agribusiness service provider (e.g FMG, PGG, Wrightson)',
          value: 'Agribusiness service provider ',
          // icon: <IconBuilder fill="currentColor" />,
          toolTip: 'You provide a service to producers other than advice. E.g. rural contracting. ',
        },
        {
          id: 11,
          text: 'Retailer',
          value: 'Retailer',
        },
        {
          id: 12,
          text: 'Researcher',
          value: 'researcher',
          // icon: <IconResearcher fill="currentColor" />,
          toolTip: 'You produce research used by advisors and/or producers.',
        },
        {
          id: 13,
          text: 'Developer',
          value: 'developer',
          // icon: <IconDeveloper fill="currentColor" />,
          toolTip: 'You develop training, extension, policy, tools, or similar for the industry.',
        },
        {
          id: 14,
          text: 'Training facilitator',
          value: 'Training facilitator',
          // icon: <IconRetailer fill="currentColor" />,
          toolTip: 'You sell products used in the primary sector.',
        },
        { id: 15, text: 'Other, please specify', value: 'other', type: input, width: '24rem' },
      ],
    },
    {
      id: 'which-primary-sector',
      type: multiCardChoice,
      required: true,
      height: '120px',
      // text: ({ answers }) => {
      //   return (
      //     <p>
      //       Which primary sector(s) do you <b>mainly</b> work in?

      //     </p>
      //   )
      // },
      text: 'Which primary sector(s) do you work in?',
      next: ({ answers }) => {
        if (
          answers['which-primary-sector'].value.some(item => item.value === 'No primary sector')
        ) {
          return 'end-page'
        }
        return 'map'
      },
      choices: [
        { id: 1, text: 'Dairy', value: 'Dairy', icon: <IconCheese fill="currentColor" /> },
        {
          id: 2,
          text: 'Sheep and beef',
          value: 'Sheep and beef',
          icon: <IconBeef fill="currentColor" />,
        },
        {
          id: 3,
          text: 'Forestry',
          value: 'Forestry',
          icon: <IconTrees />,
        },
        {
          id: 4,
          text: 'Horticulture',
          value: 'Horticulture',
          icon: <IconPlant />,
        },
        {
          id: 5,
          text: 'Arable',
          value: 'Arable',
          icon: <IconArable fill="currentColor" />,
        },
        {
          id: 6,
          text: 'Fisheries / aquaculture',
          value: 'Fisheries',
          icon: <IconFish />,
        },
        {
          id: 7,
          text: 'Deer',
          value: 'Deer',
          icon: <IconDeer fill="currentColor" />,
        },
        {
          id: 8,
          text: 'Beekeeping',
          value: 'Beekeeping',
          icon: <IconBee fill="currentColor" />,
        },
        {
          id: 9,
          text: 'Pork',
          value: 'Pork',
          icon: <IconPork fill="currentColor" />,
        },
        {
          id: 10,
          text: 'Poultry',
          value: 'Poultry',
          icon: <IconPoultry fill="currentColor" />,
        },
        {
          id: 11,
          text: 'Viticulture',
          value: 'Viticulture',
          icon: <IconViticulture fill="currentColor" />,
        },
        {
          id: 12,
          text: 'No primary sector',
          value: 'No primary sector',
          icon: <IconCancel />,
          submit: true,
          warning:
            'Are you sure your work does not relate to any of these primary sectors? Click Next to confirm your choice.',
        },
        {
          id: 13,
          text: 'Other, please specify',
          value: 'other',
          type: input,
        },
      ],
    },
    {
      id: 'map',
      type: 'responsiveMultiMap',
      required: true,
      verticalGrid: true,
      disclaimer: 'Hover over areas to select all that apply',
      choices: [
        { id: 1, name: 1, text: 'Northland ', value: 'Northland' },
        { id: 2, name: 2, text: 'Auckland ', value: 'Auckland' },
        { id: 3, name: 3, text: 'Waikato ', value: 'Waikato' },
        { id: 4, name: 4, text: 'Bay of Plenty ', value: 'Bay of Plenty' },
        { id: 5, name: 6, text: 'Gisborne ', value: 'Gisborne' },
        { id: 6, name: 6, text: "Hawke's Bay ", value: "Hawke's Bay" },
        { id: 7, name: 7, text: 'Taranaki ', value: 'Taranaki' },
        { id: 8, name: 8, text: 'Manawatu-Wanganui ', value: 'Manawatu-Wanganui' },
        { id: 9, name: 9, text: 'Wellington ', value: 'Wellington' },
        { id: 10, name: 10, text: 'West Coast ', value: 'West Coast' },
        { id: 11, name: 11, text: 'Canterbury ', value: 'Canterbury' },
        { id: 12, name: 12, text: 'Otago ', value: 'Otago' },
        { id: 13, name: 13, text: 'Southland ', value: 'Southland' },
        { id: 14, name: 14, text: 'Marlborough ', value: 'Marlborough' },
        { id: 15, name: 15, text: 'Nelson/Tasman ', value: 'Nelson/Tasman' },
        { id: 16, text: 'All regions', value: 'All regions' },
      ],
      text: ({ answers }) => {
        return (
          <p style={{ marginBottom: '0rem' }}>
            Which region(s) do you <b>mainly</b> work in?
          </p>
        )
      },
      // next: ({ answers }) => {
      //   if (answers.role.value.some(answer => answer.value === 'advisor')) {
      //     return 'advisor-capacity-reach'
      //   }
      //   return 'advice-frequency-in-role'
      // },
      // next: ({ answers }) => {
      //   if (
      //     !answers.role.value.some(
      //       answer =>
      //         answer.value === 'advisor' ||
      // answer.value === 'Farm consultant' ||
      // answer.value === 'Environmental consultant' ||
      // answer.value === 'Forestry consultant' ||
      // answer.value === 'Horticultural consultant' ||
      // answer.value === 'People management consultant',
      //     )
      //   ) {
      //     return 'advice-frequency-in-role'
      //   }
      //   return 'advisor-capacity-reach'
      // },
    },

    {
      id: 'advice-frequency-in-role',
      required: true,
      text: 'How often do you personally advise farmers / growers?',
      type: multiChoice,
      next: ({ answers }) => {
        // if (
        //   answers.role.value.some(
        //     answer =>
        //       answer.value === 'Farm consultant' ||
        //       answer.value === 'Environmental consultant' ||
        //       answer.value === 'Forestry consultant' ||
        //       answer.value === 'Horticultural consultant' ||
        //       answer.value === 'People management consultant',
        //   )
        // ) {
        //   return 'advisor-capacity-reach'
        // }
        if (
          answers['advice-frequency-in-role'].value === 'sometimes' ||
          answers['advice-frequency-in-role'].value === 'often' ||
          answers['advice-frequency-in-role'].value === 'all the time'
        ) {
          return 'advisor-capacity-reach'
        }
        return 'which-org-do-you-work-at'
        // return 'support-for-the-advisory-system' // change to support
      },
      choices: [
        { id: 1, text: 'Never', value: 'never' },
        { id: 2, text: 'Rarely - a few times a year', value: 'rarely' },
        { id: 3, text: 'Sometimes - a few times a month', value: 'sometimes' },
        { id: 4, text: 'Often - a few times a week', value: 'often' },
        { id: 5, text: 'All the time - several times a week / daily', value: 'all the time' },
      ],
      verticalGrid: true,
    },
    // 2.1
    {
      id: 'advisor-capacity-reach',
      type: multiSelect,
      required: true,
      disclaimer: '',
      verticalGrid: true,
      text: () => {
        return (
          <p>
            What type of advice do you <b>typically</b> provide? Choose all that apply.{' '}
          </p>
        )
      },
      // text: 'What type of advice do you typically provide? Choose all that apply. ',
      width: '24rem',
      choices: [
        // { id: 1, text: 'Animal welfare', value: 'Animal welfare' },
        { id: 1, text: 'Animal health and welfare', value: 'Animal health and welfare' },

        { id: 2, text: 'Biosecurity', value: 'Biosecurity' },
        { id: 3, text: 'Business / strategic planning', value: 'Business / strategic planning' },
        { id: 4, text: 'Environment management', value: 'Environment management' },
        {
          id: 5,
          text: 'Farm supervision / operations management',
          value: 'Farm supervision / operations management',
        },
        {
          id: 6,
          text: 'Financial planning / management',
          value: 'Financial planning / management',
        },
        {
          id: 7,
          text: 'Land use change',
          value: 'Land use change',
        },
        {
          id: 8,
          text: 'Land production and optimisation',
          value: 'Land production and optimisation',
        },
        { id: 9, text: 'People management', value: 'People management' },
        {
          id: 10,
          text: 'Policy, regulation and compliance',
          value: 'Policy, regulation and compliance',
        },
        { id: 11, text: 'Nutrient management', value: 'Nutrient management' },
        { id: 12, text: 'Succession planning', value: 'Succession planning' },
        {
          id: 13,
          text: 'Water / irrigation design and systems',
          value: 'Water / irrigation design and systems',
        },
        {
          id: 14,
          text: 'Whole farm/system advice',
          value: 'Whole farm/system advice',
        },
        {
          id: 15,
          type: input,
          text: 'Other, please specify',
          placeholder: 'Other, please specify',
          width: '24rem',
        },
      ],
    },
    // 2.2
    // {
    //   id: 'advisor-one-to-one',
    //   type: multiChoice,
    //   required: true,
    //   text: (
    //     <Wrapper>
    //       <StyledP>
    //         How many (if any) <b>one-to-one</b> clients do you advise on an <b>ongoing</b> basis?
    //       </StyledP>
    //       <Disclaimer>
    //         One-to-one meaning individuals you are on a first names basis with, IE they are on your
    //         books.
    //       </Disclaimer>
    //     </Wrapper>
    //   ),
    //   // text: 'How many (if any) one-to-one clients do you advise on an ongoing basis?',
    //   verticalGrid: true,
    //   choices: [
    //     { id: 1, text: 'None / Not applicable', value: 'None' },

    //     { id: 2, text: '1 - 9 clients', value: '1 - 9 clients' },
    //     { id: 3, text: '10 - 20 clients', value: '10 - 20 clients' },
    //     { id: 4, text: '21 - 30 clients', value: '21 - 30 clients' },
    //     { id: 5, text: '31 - 50 clients', value: '31 - 50 clients' },
    //     { id: 6, text: '51+ clients', value: '51+ clients' },

    //     // {
    //     //   id: 7,
    //     //   text: 'Not applicable',
    //     //   value: 'Not applicable',
    //     // },
    //   ],
    //   next: ({ answers }) => {
    //     if (answers['advisor-one-to-one'].value !== 'None') {
    //       return 'client-visit'
    //     }
    //     return 'advisor-maori-agg-business'
    //   },
    //   // Q 2.3
    //   // next logic
    // },

    // {
    //   id: 'client-visit',
    //   type: multiChoice,
    //   required: true,
    //   text: () => {
    //     return (
    //       <p>
    //         On average, how often do you talk to <b>each one-to-one</b> client per year?
    //       </p>
    //     )
    //   },
    //   // text: 'On average, how often do you talk to your one-to-one clients per year?',
    //   verticalGrid: true,
    //   choices: [
    //     { id: 1, text: 'Once a year', value: 'Once a year' },

    //     { id: 2, text: '2 - 3 times/year', value: '2 - 3 times/year' },
    //     { id: 3, text: '4 - 6 times/year', value: '4 - 6 times/year' },
    //     { id: 4, text: '7 - 11 times/year', value: '7 - 11 times/year' },
    //     { id: 5, text: '12+ times/year', value: '12+ times/year' },
    //   ],
    // },
    // {
    //   id: 'advisor-producer-network-pool',
    //   type: multiChoice,
    //   required: true,
    //   text: 'How many producers would you say you have an established relationship with?',
    //   verticalGrid: true,
    //   choices: [
    //     { id: 1, text: '1 - 10 producers', value: '1 - 10 producers' },
    //     { id: 2, text: '11 - 30 producers', value: '11 - 30 producers' },
    //     {
    //       id: 3,
    //       text: '31 -  100 producers',
    //       value: '31 -  100 producers',
    //     },
    //     { id: 4, text: 'More than 100 producers', value: 'More than 100 producers' },
    //     {
    //       id: 5,
    //       text: 'Not applicable',
    //       value: 'Not applicable',
    //     },
    //   ],
    // },
    // {
    //   id: 'advisor-producer-frequency',
    //   type: multiChoice,
    //   required: true,
    //   verticalGrid: true,
    //   text: 'On average, how often do you talk to each producer?',
    //   choices: [
    //     { id: 1, text: 'Once a year', value: 'Once a year' },
    //     { id: 2, text: '2 - 3 times a year', value: '2 - 3 times a year' },
    //     { id: 3, text: '4 - 6 times a year', value: '4 - 6 times a year' },
    //     { id: 4, text: '7 - 11 times a year', value: '7 - 11 times a year' },
    //     { id: 5, text: '12+ times a year', value: '12+ times a year' },
    //   ],
    // },
    {
      id: 'advisor-maori-agg-business',
      text: 'What proportion of your client base are Māori agribusiness entities? ',
      type: multiChoice,
      verticalGrid: true,
      required: true,
      choices: [
        { id: 1, text: 'None', value: 'None' },
        {
          id: 2,
          text: '1 - 10% of my client base',
          value: '1- 10% of my client base',
        },
        {
          id: 3,
          text: '11 - 50% of my client base',
          value: '11 - 50% of my client base',
        },
        {
          id: 4,
          text: 'More than 50% of my client base',
          value: 'More than 50% of my client base',
        },
      ],
    },
    // {
    //   id: 'relatable-statements',
    //   text: () => {
    //     return (
    //       <p>
    //         Which of the following statements <b>best</b> resonates with you?
    //       </p>
    //     )
    //   },
    //   mobileHeight: '6rem',
    //   type: multiChoice,
    //   verticalGrid: true,
    //   choices: [
    //     {
    //       id: 1,
    //       text:
    //         'If I can optimise productivity and profitability for my clients, I’m doing a good job.',
    //       value:
    //         'If I can optimise productivity and profitability for my clients, I’m doing a good job.',
    //     },
    //     {
    //       id: 2,
    //       text:
    //         'Optimising profitability for my clients is the main goal, but I do also try to find the best environmentally sustainable solution as well. ',
    //       value:
    //         'Optimising profitability for my clients is the main goal, but I do also try to find the best environmentally sustainable solution as well. ',
    //     },
    //     {
    //       id: 3,
    //       text:
    //         'Success looks different for each of my clients, and I often have to tailor my advice to their unique goals and values.',
    //       value:
    //         'Success looks different for each of my clients, and I often have to tailor my advice to their unique goals and values.',
    //     },
    //   ],
    // },
    // 3.1
    // {
    //   id: 'org-information',
    //   type: responsiveMatrix,
    //   altDesign: true,
    //   useColumns: true,
    //   hide: true,
    //   useValues: true,

    //   options: [
    //     {
    //       id: 1,
    //       text: '1-3',
    //       value: '1-3',
    //     },
    //     {
    //       id: 2,
    //       text: '4-10',
    //       value: '4-10',
    //     },
    //     {
    //       id: 3,
    //       text: '11-30',
    //       value: '11-30',
    //     },
    //     { id: 4, text: '31-100', value: '31-100' },
    //     { id: 5, text: '101-300', value: '101-300' },
    //     { id: 6, text: '301-1000', value: '301-1000' },
    //     { id: 7, text: '1000 +', value: '1000 +' },
    //   ],
    //   choices: ({ answers }) => {
    //     let company = answers['which-org-do-you-work-at'].value
    //     if (company === 'I would rather not disclose this') {
    //       return [
    //         {
    //           id: 1,
    //           text: `Roughly how many people are employed at your company in New Zealand`,
    //           value: 'Number-of-employees',
    //         },
    //         {
    //           id: 2,
    //           text:
    //             'And roughly how many advise producers in the primary sector as part of their role?',
    //           value: 'Number-of-advisors',
    //         },
    //       ]
    //     } else
    //       return [
    //         {
    //           id: 1,
    //           text: `Roughly how many people are employed at ${company} in New Zealand`,
    //           value: 'Number-of-employees',
    //         },
    //         {
    //           id: 2,
    //           text:
    //             'And roughly how many advise producers in the primary sector as part of their role?',
    //           value: 'Number-of-advisors',
    //         },
    //       ]
    //   },
    //   columns: [
    //     {
    //       id: 1,
    //       value: '1-3',
    //     },
    //     {
    //       id: 2,
    //       value: '4-10',
    //     },
    //     {
    //       id: 3,
    //       value: '11-30',
    //     },
    //     { id: 4, value: '31-100' },
    //     { id: 5, value: '101-300' },
    //     { id: 6, value: '301-1000' },
    //     { id: 7, value: '1000 +' },
    //   ],
    //   rows: ({ answers }) => {
    //     let company = answers['which-org-do-you-work-at'].value
    //     if (
    //       company === 'I would rather not disclose this' ||
    //       company === 'I am an independent consultant'
    //     ) {
    //       return [
    //         {
    //           id: 1,
    //           text: `Roughly how many people are employed at your company in New Zealand?`,
    //           hide: true,
    //           value: 'Number-of-employees',
    //         },
    //         {
    //           id: 2,
    //           text:
    //             'And roughly how many advise producers in the primary sector as part of their role?',
    //           hide: true,
    //           value: 'Number-of-advisors',
    //         },
    //       ]
    //     } else
    //       return [
    //         {
    //           id: 1,
    //           text: `Roughly how many people are employed at ${company} in New Zealand?`,
    //           hide: true,
    //           value: 'Number-of-employees',
    //         },
    //         {
    //           id: 2,
    //           text:
    //             'And roughly how many advise producers in the primary sector as part of their role?',
    //           hide: true,
    //           value: 'Number-of-advisors',
    //         },
    //       ]
    //   },
    // },
    {
      id: 'which-org-do-you-work-at',
      required: true,
      width: '34.5rem',
      section: (
        <Wrapper>
          <QuestionInfo>
            The following questions will help us gauge where the key gaps in the advisory system
            are.
          </QuestionInfo>
          <Divider style={{ marginBottom: '1rem', marginTop: '0rem', width: '24rem' }} />
        </Wrapper>
      ),
      text: (
        <Wrapper>
          <StyledP>What organisation do you currently work at?</StyledP>
          <Disclaimer style={{ fontSize: '14px', marginTop: '0.5rem' }}>
            Be assured that this is for <b>research purposes only</b>, and your information will not
            be passed onto MPI or any third parties.
          </Disclaimer>
        </Wrapper>
      ),
      type: autoComplete,
      verticalGrid: true,
      choices: [
        {
          id: 2,
          text: 'I am a sole trader / work by myself',
          value: 'I am a sole trader / work by myself',
          width: '489px',
        },
        {
          id: 3,
          text: 'I would rather not disclose this',
          value: 'I would rather not disclose this',
          width: '489px',
        },
      ],
      options: distinctCompanies,
      next: ({ answers }) => {
        if (answers['which-org-do-you-work-at'].value === 'I would rather not disclose this') {
          return 'advisory-system'
        }
        if (answers['which-org-do-you-work-at'].value === 'I am a sole trader / work by myself') {
          return 'sole-trader-advisor'
        }
        return 'advisor-capacity-and-capability-gaps'
      },
    },
    {
      id: 'advisory-system', // id: 2.2
      width: '34.5rem',
      // section: (
      //   <Wrapper style={{ marginBottom: '0.5rem' }}>
      //     <Disclaimer>
      //       Be assured this is simply to categorise your answers to better understand the advisory
      //       system.
      //     </Disclaimer>
      //   </Wrapper>
      // ),
      // text: 'How would you best describe the area of the advisory system it mainly operates in?',
      text: (
        <Wrapper>
          <StyledP>
            How would you best describe the area of the advisory system it mainly operates in?
          </StyledP>
          <Disclaimer>
            Be assured this is simply to categorise your answers to better understand the advisory
            system.
          </Disclaimer>
        </Wrapper>
      ),
      type: autoComplete,
      required: true,
      verticalGrid: true,
      placeholder: 'Please select a category from the dropdown',
      options: [
        { value: 'Business services and sales' },
        { value: 'Government' },
        { value: 'Independent advisers and consultants' },
        { value: 'Industry good organisations' },
        { value: 'Processors and exporters' },
        { value: 'Public good / NGOs' },
        { value: 'Research and education' },
      ],
      choices: [
        {
          id: 2,
          text: 'I would rather not disclose this',
          value: 'I would rather not disclose this',
          width: '34.5rem',
        },
      ],
      // next: ({ answers }) => {
      //   if (answers['advisory-system'].id === 2) {
      //     return 'sole-trader-advisor'
      //   }
      //   return 'advisor-capacity-and-capability-gaps'
      // },
    },

    {
      id: 'advisor-capacity-and-capability-gaps',
      type: responsiveMatrix,
      hide: true,
      uniqueValues: true,
      skipButton: {
        id: 15,
        text: 'I would rather not disclose this - skip to next section',
        value: 'skip',
      },
      // useNumbers: true,
      useValues: true,
      useColumns: true,
      required: true,
      // additionalContent: 'button',

      // text: ({ answers }) => {
      //   const company = answers['which-org-do-you-work-at'].value
      //   if (
      //     company === 'I would rather not disclose this' ||
      //     company === 'I am an independent consultant'
      //   ) {
      //     return (
      //       <>
      //         <StyledP style={{ marginTop: '1rem' }}>
      //           Approximately how many people at <b>your organisation</b> can...
      //         </StyledP>
      //       </>
      //     )
      //   }
      //   return (
      //     <>
      //       <StyledP style={{ marginTop: '1rem' }}>
      //         Approximately how many people at <b>{company}</b> can...
      //       </StyledP>
      //     </>
      //   )
      // },
      choices: ({ answers }) => {
        if (!_.isUndefined(answers)) {
          let company = answers['which-org-do-you-work-at'].value
          if (
            company === 'I would rather not disclose this' ||
            company === 'I am an independent consultant'
          ) {
            return [
              {
                hide: true,
                id: 1,
                text: 'Roughly how many people are employed at your organisation in New Zealand?',
                value: 'Roughly how many people are employed at your organisation in New Zealand?',
              },
              {
                hide: true,
                id: 2,
                uniqueValues: true,
                text:
                  'And roughly how many advise producers on a one-to-one basis? (i.e. are on first name basis)',
                value:
                  'And roughly how many advise producers on a one-to-one basis? (i.e. are on first name basis)',
                options: [
                  {
                    hide: true,
                    id: 1,
                    text: 'None',
                    value: 'None',
                  },
                  {
                    hide: true,
                    id: 2,
                    text: '1 - 3',
                    value: '1 - 3',
                  },
                  {
                    hide: true,
                    id: 3,
                    text: '4 - 10',
                    value: '4 - 10',
                  },
                  {
                    hide: true,
                    id: 4,
                    text: '11 - 30',
                    value: '11 - 30',
                  },
                  {
                    hide: true,
                    id: 5,
                    text: '31 - 100',
                    value: '31 - 100',
                  },
                  {
                    hide: true,
                    id: 6,
                    text: '101 - 300',
                    value: '101 - 300',
                  },
                  {
                    hide: true,
                    id: 7,
                    text: '301 +',
                    value: '301 +',
                  },
                  {
                    hide: true,
                    id: 8,
                    text: "Don't know",
                    value: "Don't know",
                  },
                ],
              },
            ]
          } else
            return [
              {
                hide: true,
                id: 1,
                text: `Roughly how many people are employed at ${company} in New Zealand?`,
                value: 'Roughly how many people are employed at your company in New Zealand?',
                // value: 'Number-of-employees',
              },
              {
                hide: true,
                id: 2,
                uniqueValues: true,
                text:
                  'And roughly how many advise producers on a one-to-one basis? (i.e. are on first name basis)',
                value:
                  'And roughly how many advise producers on a one-to-one basis? (i.e. are on first name basis)',
                options: [
                  {
                    hide: true,
                    id: 1,
                    text: 'None',
                    value: 'None',
                  },
                  {
                    hide: true,
                    id: 2,
                    text: '1 - 3',
                    value: '1 - 3',
                  },
                  {
                    hide: true,
                    id: 3,
                    text: '4 - 10',
                    value: '4 - 10',
                  },
                  {
                    hide: true,
                    id: 4,
                    text: '11 - 30',
                    value: '11 - 30',
                  },
                  {
                    hide: true,
                    id: 5,
                    text: '31 - 100',
                    value: '31 - 100',
                  },
                  {
                    hide: true,
                    id: 6,
                    text: '101 - 300',
                    value: '101 - 300',
                  },
                  {
                    hide: true,
                    id: 7,
                    text: '301 +',
                    value: '301 +',
                  },
                  {
                    hide: true,
                    id: 8,
                    text: "Don't know",
                    value: "Don't know",
                  },
                ],
              },
            ]
        } else {
          return [
            {
              hide: true,
              id: 1,
              text: `Roughly how many people are employed at your organisation in New Zealand?`,
              value: 'Roughly how many people are employed at your company in New Zealand?',
              // value: 'Number-of-employees',
            },
            {
              hide: true,
              id: 2,
              uniqueValues: true,
              text:
                'And roughly how many advise producers on a one-to-one basis? (i.e. are on first name basis)',
              value:
                'And roughly how many advise producers on a one-to-one basis? (i.e. are on first name basis)',
              options: [
                {
                  hide: true,
                  id: 1,
                  text: 'None',
                  value: 'None',
                },
                {
                  hide: true,
                  id: 2,
                  text: '1 - 3',
                  value: '1 - 3',
                },
                {
                  hide: true,
                  id: 3,
                  text: '4 - 10',
                  value: '4 - 10',
                },
                {
                  hide: true,
                  id: 4,
                  text: '11 - 30',
                  value: '11 - 30',
                },
                {
                  hide: true,
                  id: 5,
                  text: '31 - 100',
                  value: '31 - 100',
                },
                {
                  hide: true,
                  id: 6,
                  text: '101 - 300',
                  value: '101 - 300',
                },
                {
                  hide: true,
                  id: 7,
                  text: '301 +',
                  value: '301 +',
                },
                {
                  hide: true,
                  id: 8,
                  text: "Don't know",
                  value: "Don't know",
                },
              ],
            },
          ]
        }
      },

      options: [
        {
          hide: true,
          id: 1,
          text: 'None',
          value: 'None',
        },
        {
          hide: true,
          id: 2,
          text: '1 - 3',
          value: '1 - 3',
        },
        {
          hide: true,
          id: 3,
          text: '4 - 10',
          value: '4 - 10',
        },
        {
          hide: true,
          id: 4,
          text: '11 - 30',
          value: '11 - 30',
        },
        {
          hide: true,
          id: 5,
          text: '31 - 100',
          value: '31 - 100',
        },
        {
          hide: true,
          id: 6,
          text: '101 - 300',
          value: '101 - 300',
        },
        {
          hide: true,
          id: 7,
          text: '301 +',
          value: '301 +',
        },
        {
          hide: true,
          id: 8,
          text: "Don't know",
          value: "Don't know",
        },
      ],
      columns: [
        {
          hide: true,
          id: 1,
          text: 'None',
          value: 'None',
        },
        {
          hide: true,
          id: 2,
          text: '1 - 3',
          value: '1 - 3',
        },
        {
          hide: true,
          id: 3,
          text: '4 - 10',
          value: '4 - 10',
        },
        {
          hide: true,
          id: 4,
          text: '11 - 30',
          value: '11 - 30',
        },
        {
          hide: true,
          id: 5,
          text: '31 - 100',
          value: '31 - 100',
        },
        {
          hide: true,
          id: 6,
          text: '101 - 300',
          value: '101 - 300',
        },
        {
          hide: true,
          id: 7,
          text: '301 +',
          value: '301 +',
        },
        {
          hide: true,
          id: 8,
          text: "Don't know",
          value: "Don't know",
        },
      ],
      rows: ({ answers }) => {
        let company = answers['which-org-do-you-work-at'].value
        if (
          company === 'I would rather not disclose this' ||
          company === 'I am an independent consultant'
        ) {
          return [
            {
              hide: true,
              id: 1,
              text: 'Roughly how many people are employed at your organisation in New Zealand?',
              value: 'Roughly how many people are employed at your company in New Zealand?',
            },
            {
              hide: true,
              id: 2,
              uniqueValues: true,
              text:
                'And roughly how many advise producers on a one-to-one basis? (i.e. are on first name basis)',
              value:
                'And roughly how many advise producers on a one-to-one basis? (i.e. are on first name basis)',
              options: [
                {
                  hide: true,
                  id: 1,
                  text: 'None',
                  value: 'None',
                },
                {
                  hide: true,
                  id: 2,
                  text: '1 - 3',
                  value: '1 - 3',
                },
                {
                  hide: true,
                  id: 3,
                  text: '4 - 10',
                  value: '4 - 10',
                },
                {
                  hide: true,
                  id: 4,
                  text: '11 - 30',
                  value: '11 - 30',
                },
                {
                  hide: true,
                  id: 5,
                  text: '31 - 100',
                  value: '31 - 100',
                },
                {
                  hide: true,
                  id: 6,
                  text: '101 - 300',
                  value: '101 - 300',
                },
                {
                  hide: true,
                  id: 7,
                  text: '301 +',
                  value: '301 +',
                },
                {
                  hide: true,
                  id: 8,
                  text: "Don't know",
                  value: "Don't know",
                },
              ],
            },
          ]
        } else
          return [
            {
              hide: true,
              id: 1,
              text: `Roughly how many people are employed at ${company} in New Zealand?`,
              value: 'Roughly how many people are employed at your company in New Zealand?',
              // value: 'Number-of-employees',
            },
            {
              hide: true,
              id: 2,
              uniqueValues: true,
              text:
                'And roughly how many advise producers on a one-to-one basis? (i.e. are on first name basis)',
              value:
                'And roughly how many advise producers on a one-to-one basis? (i.e. are on first name basis)',
              options: [
                {
                  hide: true,
                  id: 1,
                  text: 'None',
                  value: 'None',
                },
                {
                  hide: true,
                  id: 2,
                  text: '1 - 3',
                  value: '1 - 3',
                },
                {
                  hide: true,
                  id: 3,
                  text: '4 - 10',
                  value: '4 - 10',
                },
                {
                  hide: true,
                  id: 4,
                  text: '11 - 30',
                  value: '11 - 30',
                },
                {
                  hide: true,
                  id: 5,
                  text: '31 - 100',
                  value: '31 - 100',
                },
                {
                  hide: true,
                  id: 6,
                  text: '101 - 300',
                  value: '101 - 300',
                },
                {
                  hide: true,
                  id: 7,
                  text: '301 +',
                  value: '301 +',
                },
                {
                  hide: true,
                  id: 8,
                  text: "Don't know",
                  value: "Don't know",
                },
              ],
            },
          ]
      },
      next: ({ answers }) => {
        const answersArray3 = answers['advice-frequency-in-role']
        const currentAnswer = answers['advisor-capacity-and-capability-gaps'].value[1].value

        // if (answers['advisor-capacity-and-capability-gaps'].value === 'skip') {
        //   return 'sole-trader-advisor'
        // }

        // if (
        //   answersArray3.value === 'never' ||
        //   answersArray3.value === 'rarely' ||
        //   !answers.role.value.some(
        //     uAnswer =>
        //       uAnswer.value === 'Farm consultant' ||
        //       uAnswer.value === 'Environmental consultant' ||
        //       uAnswer.value === 'Forestry consultant' ||
        //       uAnswer.value === 'Horticultural consultant' ||
        //       uAnswer.value === 'People management consultant',
        //   )
        // ) {
        //   return 'advice-frequency-land-use'
        // }

        if (
          (answersArray3.value === 'never' || answersArray3.value === 'rarely') &&
          !answers.role.value.some(
            uAnswer =>
              uAnswer.value === 'Farm consultant' ||
              uAnswer.value === 'Environmental consultant' ||
              uAnswer.value === 'Forestry consultant' ||
              uAnswer.value === 'Horticultural consultant' ||
              uAnswer.value === 'People management consultant',
          )
        ) {
          return 'advice-frequency-land-use'
        }
        // if (
        //   (answersArray3.value === 'never' && currentAnswer === 'None') ||
        //   (answersArray3.value === 'never' && currentAnswer === "Don't know") ||
        //   (answersArray3.value === 'rarely' && currentAnswer === 'None') ||
        //   (answersArray3.value === 'rarely' && currentAnswer === "Don't know")
        // ) {
        //   return 'advice-frequency-land-use'
        // }

        if (answers['advisor-capacity-and-capability-gaps'].value[1].value !== 'None') {
          return 'advisor-capacity-and-capability-gaps-p2'
        }
        return 'professional-dev'
        // return 'understanding-goals-and-values-for-producer'
      },
    },
    {
      id: 'advisor-capacity-and-capability-gaps-p2',
      type: responsiveMatrix,
      hide: true,
      useValues: true,
      useColumns: true,
      required: true,
      // additionalContent: 'button',
      text: ({ answers }) => {
        const company = answers['which-org-do-you-work-at'].value
        if (
          company === 'I would rather not disclose this' ||
          company === 'I am an independent consultant'
        ) {
          return (
            <>
              <StyledP style={{ marginTop: '1rem' }}>
                Roughly how many people at <b>your organisation:</b>
              </StyledP>
            </>
          )
        }
        return (
          <>
            <StyledP style={{ marginTop: '1rem' }}>
              Roughly how many people at <b>{company}:</b>
            </StyledP>
          </>
        )
      },
      choices: [
        {
          id: 1,
          text: `A) Provide advice on productive and sustainable land and water use`,
          value: 'Provide advice on productive and sustainable land and water use',
        },
        {
          id: 2,
          text: 'B) Provide advice on changing land use',
          value: 'Provide advice on changing land use',
        },
        {
          id: 3,
          text: `C) Provide advice on whole farm systems (including business planning)`,
          value: 'Provide advice on whole farm systems',
        },
      ],
      columns: [
        {
          hide: true,
          id: 1,
          text: 'None',
          value: 'None',
        },
        {
          hide: true,
          id: 2,
          text: '1 - 3',
          value: '1 - 3',
        },
        {
          hide: true,
          id: 3,
          text: '4 - 10',
          value: '4 - 10',
        },
        {
          hide: true,
          id: 4,
          text: '11 - 30',
          value: '11 - 30',
        },
        {
          hide: true,
          id: 5,
          text: '31 - 100',
          value: '31 - 100',
        },
        {
          hide: true,
          id: 6,
          text: '101 - 300',
          value: '101 - 300',
        },
        {
          hide: true,
          id: 7,
          text: '301 +',
          value: '301 +',
        },
        {
          hide: true,
          id: 8,
          text: "Don't know",
          value: "Don't know",
        },
      ],
      rows: [
        {
          hide: true,
          id: 1,
          text: `A) Provide advice on productive and sustainable land and water use`,
          value: 'Provide advice on productive and sustainable land and water use',
        },
        {
          hide: true,
          id: 2,
          text: 'B) Provide advice on changing land use',
          value: 'Provide advice on changing land use',
        },
        {
          hide: true,
          id: 3,
          text: `C) Provide advice on whole farm systems (including business planning)`,
          value: 'Provide advice on whole farm systems',
        },
      ],
      // choices: [
      //   {
      //     id: 1,
      //     text:
      //       'Advise on the production system as a whole (i.e are recognised as farm system consultants',
      //   },
      //   {
      //     id: 3,
      //     text: 'Use Farmax to compare different land use scenarios',
      //   },
      // ],
      options: [
        {
          id: 1,
          text: 'None',
          value: 'None',
        },
        {
          id: 2,
          text: '1 - 3',
          value: '1 - 3',
        },
        {
          id: 3,
          text: '4 - 10',
          value: '4 - 10',
        },
        {
          id: 4,
          text: '11 - 30',
          value: '11 - 30',
        },
        {
          id: 5,
          text: '31 - 100',
          value: '31 - 100',
        },
        {
          id: 6,
          text: '101 - 300',
          value: '101 - 300',
        },
        {
          id: 7,
          text: '301 +',
          value: '301 +',
        },
        {
          hide: true,
          id: 8,
          text: "Don't know",
          value: "Don't know",
        },
      ],
    },
    {
      id: 'advisor-capacity-and-capability-gaps-p3',
      type: responsiveMatrix,
      hide: true,
      useValues: true,
      useColumns: true,
      required: true,
      // additionalContent: 'button',
      text: ({ answers }) => {
        const company = answers['which-org-do-you-work-at'].value
        if (
          company === 'I would rather not disclose this' ||
          company === 'I am an independent consultant'
        ) {
          return (
            <>
              <StyledP style={{ marginTop: '1rem' }}>
                Roughly how many people at <b>your organisation:</b>
              </StyledP>
            </>
          )
        }
        return (
          <>
            <StyledP style={{ marginTop: '1rem' }}>
              Roughly how many people at <b>{company}:</b>
            </StyledP>
          </>
        )
      },
      choices: [
        {
          id: 1,
          text:
            'D) Regularly use software tools to analyse and/or compare different land use scenarios',
          value:
            'Regularly use software tools to analyse and/or compare different land use scenarios',
        },
        {
          id: 2,
          text: `E) Can prepare farm environment plans`,
          value:
            'Regularly use software tools to analyse and/or compare different land use scenarios',
        },
        {
          id: 3,
          text: 'F)	Work with Māori agribusinesses',
          value: 'Work with Māori agribusinesses',
        },
      ],
      columns: [
        {
          hide: true,
          id: 1,
          text: 'None',
          value: 'None',
        },
        {
          hide: true,
          id: 2,
          text: '1 - 3',
          value: '1 - 3',
        },
        {
          hide: true,
          id: 3,
          text: '4 - 10',
          value: '4 - 10',
        },
        {
          hide: true,
          id: 4,
          text: '11 - 30',
          value: '11 - 30',
        },
        {
          hide: true,
          id: 5,
          text: '31 - 100',
          value: '31 - 100',
        },
        {
          hide: true,
          id: 6,
          text: '101 - 300',
          value: '101 - 300',
        },
        {
          hide: true,
          id: 7,
          text: '301 +',
          value: '301 +',
        },
        {
          hide: true,
          id: 8,
          text: "Don't know",
          value: "Don't know",
        },
      ],
      rows: [
        {
          hide: true,
          id: 1,
          text:
            'D) Regularly use software tools to analyse and/or compare different land use scenarios',
          value:
            'Regularly use software tools to analyse and/or compare different land use scenarios',
        },
        {
          hide: true,
          id: 2,
          text: `E) Can prepare farm environment plans`,
          value:
            'Regularly use software tools to analyse and/or compare different land use scenarios',
        },
        {
          hide: true,
          id: 3,
          text: 'F)	Work with Māori agribusinesses',
          value: 'Work with Māori agribusinesses',
        },
      ],
      options: [
        {
          id: 1,
          text: 'None',
          value: 'None',
        },
        {
          id: 1,
          text: '1 - 3',
          value: '1 - 3',
        },
        {
          id: 1,
          text: '4 - 10',
          value: '4 - 10',
        },
        {
          id: 1,
          text: '11 - 30',
          value: '11 - 30',
        },
        {
          id: 1,
          text: '31 - 100',
          value: '31 - 100',
        },
        {
          id: 1,
          text: '101 - 300',
          value: '101 - 300',
        },
        {
          id: 1,
          text: '301 +',
          value: '301 +',
        },
        {
          hide: true,
          id: 8,
          text: "Don't know",
          value: "Don't know",
        },
      ],
      next: ({ answers }) => {
        const answersArray = answers['advisor-capacity-and-capability-gaps-p2'].value
        const answersArray2 = answers['advisor-capacity-and-capability-gaps-p3'].value
        const adviceFrequency = answers['advice-frequency-in-role']
        const currentAnswer = answers['advisor-capacity-and-capability-gaps-p3'].value

        if (answersArray.some(item => item.value !== 'None' && item.value !== "Don't know")) {
          return 'understanding-goals-and-values-for-producer'
        }
        if (answersArray2.some(item => item.value !== 'None' && item.value !== "Don't know")) {
          return 'understanding-goals-and-values-for-producer-p2'
        }

        if (
          (adviceFrequency.value === 'never' || adviceFrequency.value === 'rarely') &&
          !answers.role.value.some(
            uAnswer =>
              uAnswer.value === 'Farm consultant' ||
              uAnswer.value === 'Environmental consultant' ||
              uAnswer.value === 'Forestry consultant' ||
              uAnswer.value === 'Horticultural consultant' ||
              uAnswer.value === 'People management consultant',
          )
        ) {
          return 'advice-frequency-land-use'
        }

        // if (adviceFrequency.value === 'never' || adviceFrequency.value === 'rarely') {
        //   return 'advice-frequency-land-use'
        // }

        // if (
        //   (adviceFrequency.value === 'never' && currentAnswer === 'None') ||
        //   (adviceFrequency.value === 'never' && currentAnswer === "Don't know") ||
        //   (adviceFrequency.value === 'rarely' && currentAnswer === 'None') ||
        //   (adviceFrequency.value === 'rarely' && currentAnswer === "Don't know")
        // ) {
        //   return 'education'
        // }

        return 'professional-dev'
      },
    },
    {
      id: 'sole-trader-advisor',
      type: multiSelect,
      required: true,
      verticalGrid: true,
      text: 'Do you advise on any of the following?',
      choices: [
        {
          id: 1,
          text: 'Productive and sustainable land and water use',
          value: 'sustainable land and water use',
        },
        { id: 2, text: 'Changing land use', value: 'changing land use' },
        {
          id: 3,
          text: 'Whole farm systems (including business planning)',
          value: 'whole farm systems',
        },
        {
          id: 4,
          text: 'Different land use scenarios using software tools to analyse',
          value: 'different land use scenarios',
        },
        { id: 5, text: 'Farm environment plans', value: 'farm environment plans' },
        { id: 6, text: 'None of the above', value: 'none of the above', submit: true },
      ],
      next: ({ answers }) => {
        const answersArray3 = answers['advice-frequency-in-role']

        const soleTraderAnswers = answers['sole-trader-advisor'].value
        if (soleTraderAnswers.find(answer => answer.value === 'sustainable land and water use')) {
          return 'understanding-goals-and-values-for-producer'
        }
        if (soleTraderAnswers.find(answer => answer.value === 'changing land use')) {
          return 'understanding-goals-and-values-for-producer'
        }
        if (soleTraderAnswers.find(answer => answer.value === 'whole farm systems')) {
          return 'understanding-goals-and-values-for-producer'
        }

        if (
          answers['sole-trader-advisor'].value.find(
            item => item.value === 'different land use scenarios',
          ) ||
          answers['sole-trader-advisor'].value.find(item => item.value === 'farm environment plans')
        ) {
          return 'understanding-goals-and-values-for-producer-p2'
        }
        if (
          (answersArray3.value === 'never' || answersArray3.value === 'rarely') &&
          !answers.role.value.some(
            uAnswer =>
              uAnswer.value === 'Farm consultant' ||
              uAnswer.value === 'Environmental consultant' ||
              uAnswer.value === 'Forestry consultant' ||
              uAnswer.value === 'Horticultural consultant' ||
              uAnswer.value === 'People management consultant',
          )
        ) {
          return 'advice-frequency-land-use'
        }

        // if (answersArray3.value === 'never' || answersArray3.value === 'rarely') {
        //   return 'advice-frequency-land-use'
        // }

        return 'professional-dev'
      },
    },
    {
      id: 'understanding-goals-and-values-for-producer',
      required: true,
      useColumns: true,
      text: () => {
        return (
          <Wrapper>
            <p>
              In the regions where you work, how would you describe the <b>number of people</b>{' '}
              that:
            </p>
          </Wrapper>
        )
      },
      // text: 'In the regions where you work, how would you describe the number of people that:',
      type: responsiveMatrix,
      hide: true,
      marginBottom: '3.5rem',
      width: '8rem',
      height: '4rem',
      boxWidth: '795px',
      customBorder: true,
      ignoreWidth: true,
      useIcons: true,
      // commentBox: true,
      // placeholder: 'Additional comments',
      options: [
        {
          id: 1,
          text: 'Critically low',
          value: 'Critically low',
        },
        {
          id: 2,
          text: 'Low',
          value: 'Low',
        },
        {
          id: 3,
          text: 'Just enough',
          value: 'Just enough',
        },
        {
          id: 4,
          text: 'More than enough',
          value: 'More than enough',
        },
        {
          id: 5,
          text: "I don't know",
          paddingLeft: '2rem',
          value: "I don't know",
        },
      ],
      columns: [
        {
          hide: true,
          id: 1,
          rawText: 'Critically low',
          value: 'Critically low',
          text: 'Critically low',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          hide: true,
          id: 2,
          rawText: 'Low',
          text: 'Low',
          value: 'Low',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          hide: true,
          id: 3,
          rawText: 'Just enough',
          value: 'Just enough',
          text: 'Just enough',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          hide: true,
          id: 4,
          rawText: 'More than enough',
          value: 'More than enough',
          width: '130px',
          text: 'More than enough',
          icon: <IconSmileyFive fill="currentColor" />,
        },
        {
          hide: true,
          id: 5,
          value: "I don't know",
          text: "I don't know",
          rawText: "I don't know",
          paddingLeft: '4rem',
          // color: '#000',
          icon: <IconQuestionMark fill="currentColor" />,
          // icon: <IconSmileyThree fill="currentColor" />,
        },
      ],
      rows: ({ answers }) => {
        const waterUse = {
          hide: true,
          id: 1,
          text: 'A) Provide advice on productive and sustainable land and water use',
          value: 'A) Provide advice on productive and sustainable land and water use',
        }
        const landUse = {
          hide: true,
          id: 2,
          text: 'B) Provide advice on changing land use',
          value: 'B) Provide advice on changing land use',
        }
        const farmSystem = {
          hide: true,
          id: 3,
          text: 'C) Provide advice on whole farm systems (including business planning)',
          value: 'C) Provide advice on whole farm systems (including business planning)',
        }
        let questions = []
        if ('sole-trader-advisor' in answers) {
          if (answers['sole-trader-advisor'].value) {
            const soleTraderAnswers = answers['sole-trader-advisor'].value
            if (
              soleTraderAnswers.find(answer => answer.value === 'sustainable land and water use')
            ) {
              questions.push(waterUse)
            }
            if (soleTraderAnswers.find(answer => answer.value === 'changing land use')) {
              questions.push(landUse)
            }
            if (soleTraderAnswers.find(answer => answer.value === 'whole farm systems')) {
              questions.push(farmSystem)
            }
          }
        }
        if ('advisor-capacity-and-capability-gaps-p2' in answers) {
          if (
            answers['advisor-capacity-and-capability-gaps-p2'].value[0].value !== 'None' &&
            answers['advisor-capacity-and-capability-gaps-p2'].value[0].value !== "Don't know"
          ) {
            console.log(
              'waterUse value',
              answers['advisor-capacity-and-capability-gaps-p2'].value[0].value,
            )
            questions.push(waterUse)
          }
          if (
            answers['advisor-capacity-and-capability-gaps-p2'].value[1].value !== 'None' &&
            answers['advisor-capacity-and-capability-gaps-p2'].value[1].value !== "Don't know"
          ) {
            questions.push(landUse)
          }
          if (
            answers['advisor-capacity-and-capability-gaps-p2'].value[2].value !== 'None' &&
            answers['advisor-capacity-and-capability-gaps-p2'].value[2].value !== "Don't know"
          ) {
            questions.push(farmSystem)
          }
        }

        // console.log('questions', answers['advisor-capacity-and-capability-gaps-p2'].value)
        // console.log('questions array', questions)
        return questions
      },
      // rows: [
      //   {
      //     hide: true,
      //     id: 1,
      //     text: 'A) Provide robust advice on productive and sustainable land and water use',
      //     value: 'A) Provide robust advice on productive and sustainable land and water use',
      //   },
      //   {
      //     hide: true,
      //     id: 2,
      //     text: 'B) Provide robust advice on changing land use',
      //     value: 'B) Provide robust advice on changing land use',
      //   },
      //   {
      //     hide: true,
      //     id: 3,
      //     text: 'C) Provide robust advice on whole farm systems (including business planning)',
      //     value: 'C) Provide robust advice on whole farm systems (including business planning)',
      //   },
      //   // {
      //   //   id: 4,
      //   //   text:
      //   //     'D) Regularly use software tools to analyse and/or compare different land use scenarios',
      //   //   value:
      //   //     'D) Regularly use software tools to analyse and/or compare different land use scenarios',
      //   // },
      //   // {
      //   //   id: 5,
      //   //   text: 'E) Can prepare farm environment plans',
      //   //   value: 'E) Can prepare farm environment plans',
      //   // },
      //   // {
      //   //   id: 6,
      //   //   text: 'F)	Work with Māori agribusinesses',
      //   //   value: 'F)	Work with Māori agribusinesses',
      //   // },
      // ],

      choices: ({ answers }) => {
        const waterUse = {
          hide: true,
          id: 1,
          text: 'A) Provide advice on productive and sustainable land and water use',
          value: 'A) Provide advice on productive and sustainable land and water use',
        }
        const landUse = {
          hide: true,
          id: 2,
          text: 'B) Provide advice on changing land use',
          value: 'B) Provide advice on changing land use',
        }
        const farmSystem = {
          hide: true,
          id: 3,
          text: 'C) Provide advice on whole farm systems (including business planning)',
          value: 'C) Provide advice on whole farm systems (including business planning)',
        }
        let questions = []
        if (!_.isUndefined(answers)) {
          if ('sole-trader-advisor' in answers) {
            if (answers['sole-trader-advisor'].value) {
              const soleTraderAnswers = answers['sole-trader-advisor'].value
              if (
                soleTraderAnswers.find(answer => answer.value === 'sustainable land and water use')
              ) {
                questions.push(waterUse)
              }
              if (soleTraderAnswers.find(answer => answer.value === 'changing land use')) {
                questions.push(landUse)
              }
              if (soleTraderAnswers.find(answer => answer.value === 'whole farm systems')) {
                questions.push(farmSystem)
              }
            }
          }
          if ('advisor-capacity-and-capability-gaps-p2' in answers) {
            if (
              answers['advisor-capacity-and-capability-gaps-p2'].value[0].value !== 'None' &&
              answers['advisor-capacity-and-capability-gaps-p2'].value[0].value !== "Don't know"
            ) {
              questions.push(waterUse)
            }
            if (
              answers['advisor-capacity-and-capability-gaps-p2'].value[1].value !== 'None' &&
              answers['advisor-capacity-and-capability-gaps-p2'].value[1].value !== "Don't know"
            ) {
              questions.push(landUse)
            }
            if (
              answers['advisor-capacity-and-capability-gaps-p2'].value[2].value !== 'None' &&
              answers['advisor-capacity-and-capability-gaps-p2'].value[2].value !== "Don't know"
            ) {
              questions.push(farmSystem)
            }
          }
        } else {
          questions.push(waterUse, landUse, farmSystem)
        }

        return questions
      },
      // choices: [
      //   {
      //     id: 1,
      //     text: 'A) Provide robust advice on productive and sustainable land and water use',
      //     value: 'A) Provide robust advice on productive and sustainable land and water use',
      //   },
      //   {
      //     id: 2,
      //     text: 'B) Provide robust advice on changing land use',
      //     value: 'B) Provide robust advice on changing land use',
      //   },
      //   {
      //     id: 3,
      //     text: 'C) Provide robust advice on whole farm systems (including business planning)',
      //     value: 'C) Provide robust advice on whole farm systems (including business planning)',
      //   },
      //   // {
      //   //   id: 4,
      //   //   text:
      //   //     'D) Regularly use software tools to analyse and/or compare different land use scenarios',
      //   //   value:
      //   //     'D) Regularly use software tools to analyse and/or compare different land use scenarios',
      //   // },
      //   // {
      //   //   id: 5,
      //   //   text: 'E) Can prepare farm environment plans',
      //   //   value: 'E) Can prepare farm environment plans',
      //   // },
      //   // {
      //   //   id: 6,
      //   //   text: 'F)	Work with Māori agribusinesses',
      //   //   value: 'F)	Work with Māori agribusinesses',
      //   // },
      // ],
      // next: ({ answers }) => {
      //   console.log('check answer', answers['advice-frequency-in-role'].value)
      //   if (
      //     answers['advice-frequency-in-role'].value === 'Sometimes' ||
      //     answers['advice-frequency-in-role'].value === 'Often' ||
      //     answers['advice-frequency-in-role'].value === 'All the time'
      //   ) {
      //     return 'career-pathways' // career pathways
      //   }
      //   return 'support-for-the-advisory-system' // support
      //   // return 'support-for-the-advisory-system' // change to support
      // },
      // next: ({ answers }) => {
      //   const landRelated = [
      //     'Environment management',
      //     'Land production and optimisation',
      //     'Policy, regulation, and compliance',
      //     'Nutrient management',
      //     'Whole system advice (all of the above)',
      //   ]
      //   if (answers['advisor-capacity-reach'].value.some(ans => landRelated.includes(ans.value))) {
      //     return 'rate-own-expertise'
      //   }
      //   return 'education'
      // },
      next: ({ answers }) => {
        const answersArray3 = answers['advice-frequency-in-role']
        if ('advisor-capacity-and-capability-gaps-p3' in answers) {
          const answersArray2 = answers['advisor-capacity-and-capability-gaps-p3'].value
          if (answersArray2.some(item => item.value !== 'None' && item.value !== "Don't know")) {
            return 'understanding-goals-and-values-for-producer-p2'
          }
          if (
            (answersArray3.value === 'never' || answersArray3.value === 'rarely') &&
            !answers.role.value.some(
              uAnswer =>
                uAnswer.value === 'Farm consultant' ||
                uAnswer.value === 'Environmental consultant' ||
                uAnswer.value === 'Forestry consultant' ||
                uAnswer.value === 'Horticultural consultant' ||
                uAnswer.value === 'People management consultant',
            )
          ) {
            return 'advice-frequency-land-use'
          }
          // if (answersArray3.value === 'never' || answersArray3.value === 'rarely') {
          //   return 'advice-frequency-land-use'
          // }
          return 'professional-dev'
        }

        if (
          'sole-trader-advisor' in answers &&
          'understanding-goals-and-values-for-producer' in answers
        ) {
          if (
            answers['sole-trader-advisor'].value.find(
              item => item.value === 'different land use scenarios',
            ) ||
            answers['sole-trader-advisor'].value.find(
              item => item.value === 'farm environment plans',
            )
          ) {
            return 'understanding-goals-and-values-for-producer-p2'
          }
          if (
            (answersArray3.value === 'never' || answersArray3.value === 'rarely') &&
            !answers.role.value.some(
              uAnswer =>
                uAnswer.value === 'Farm consultant' ||
                uAnswer.value === 'Environmental consultant' ||
                uAnswer.value === 'Forestry consultant' ||
                uAnswer.value === 'Horticultural consultant' ||
                uAnswer.value === 'People management consultant',
            )
          ) {
            return 'advice-frequency-land-use'
          }

          // if (answersArray3.value === 'never' || answersArray3.value === 'rarely') {
          //   return 'advice-frequency-land-use'
          // }
          return 'professional-dev'
        }

        // if (answersArray3.value === 'never' || answersArray3.value === 'rarely') {
        //   return 'education'
        // }

        // if (answersArray.some(item => item.value !== 'None' && item.value !== "Don't know")) {
        //   return 'understanding-goals-and-values-for-producer-p2'
        // }

        // return 'career-pathways'
      },
    },
    {
      id: 'understanding-goals-and-values-for-producer-p2',
      required: true,
      text: () => {
        return (
          <Wrapper>
            <p>
              In the regions where you work, how would you describe the <b>number of people</b>{' '}
              that:
            </p>
          </Wrapper>
        )
      },
      // text: 'In the regions where you work, how would you describe the number of people that:',
      type: responsiveMatrix,
      hide: true,
      useColumns: true,
      marginBottom: '3.5rem',
      width: '8rem',
      height: '4rem',
      boxWidth: '795px',
      customBorder: true,
      ignoreWidth: true,
      useIcons: true,
      // commentBox: true,
      // placeholder: 'Additional comments',
      options: [
        {
          id: 1,
          text: 'Critically low',
          value: 'Critically low',
        },
        {
          id: 2,
          text: 'Low',
          value: 'Low',
        },
        {
          id: 3,
          text: 'Just enough',
          value: 'Just enough',
        },
        {
          id: 4,
          text: 'More than enough',
          value: 'More than enough',
        },
        {
          id: 5,
          text: "I don't know",
          paddingLeft: '2rem',
          value: "I don't know",
        },
      ],
      columns: [
        {
          hide: true,
          id: 1,
          rawText: 'Critically low',
          value: 'Critically low',
          text: 'Critically low',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          hide: true,
          id: 2,
          rawText: 'Low',
          text: 'Low',
          value: 'Low',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          hide: true,
          id: 3,
          rawText: 'Just enough',
          value: 'Just enough',
          text: 'Just enough',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          hide: true,
          id: 4,
          rawText: 'More than enough',
          value: 'More than enough',
          width: '130px',
          text: 'More than enough',
          icon: <IconSmileyFive fill="currentColor" />,
        },
        {
          hide: true,
          id: 5,
          value: "I don't know",
          text: "I don't know",
          rawText: "I don't know",
          paddingLeft: '4rem',
          // color: '#000',
          icon: <IconQuestionMark fill="currentColor" />,
          // icon: <IconSmileyThree fill="currentColor" />,
          // icon: <IconSmileyThree fill="currentColor" />,
        },
      ],

      rows: ({ answers }) => {
        let questions = []
        const tools = {
          hide: true,

          id: 1,
          text:
            'D) Regularly use software tools to analyse and/or compare different land use scenarios',
          value:
            'D) Regularly use software tools to analyse and/or compare different land use scenarios',
        }
        const envPlans = {
          hide: true,

          id: 2,
          text: 'E) Can prepare farm environment plans',
          value: 'E) Can prepare farm environment plans',
        }
        const mAgg = {
          hide: true,

          id: 3,
          text: 'F)	Work with Māori agribusinesses',
          value: 'F)	Work with Māori agribusinesses',
        }
        if ('sole-trader-advisor' in answers) {
          if (answers['sole-trader-advisor'].value) {
            const soleTraderAnswers = answers['sole-trader-advisor'].value
            if (soleTraderAnswers.find(answer => answer.value === 'different land use scenarios')) {
              questions.push(tools)
            }
            if (soleTraderAnswers.find(answer => answer.value === 'farm environment plans')) {
              questions.push(envPlans)
            }
          }
        }
        if ('advisor-capacity-and-capability-gaps-p3' in answers) {
          if (
            answers['advisor-capacity-and-capability-gaps-p3'].value[0].value !== 'None' &&
            answers['advisor-capacity-and-capability-gaps-p3'].value[0].value !== "Don't know"
          ) {
            questions.push(tools)
          }

          if (
            answers['advisor-capacity-and-capability-gaps-p3'].value[1].value !== 'None' &&
            answers['advisor-capacity-and-capability-gaps-p3'].value[1].value !== "Don't know"
          ) {
            questions.push(envPlans)
          }

          if (
            answers['advisor-capacity-and-capability-gaps-p3'].value[2].value !== 'None' &&
            answers['advisor-capacity-and-capability-gaps-p3'].value[2].value !== "Don't know"
          ) {
            questions.push(mAgg)
          }
        }

        return questions
      },
      // rows: [
      //   {
      //     hide: true,

      //     id: 1,
      //     text:
      //       'D) Regularly use software tools to analyse and/or compare different land use scenarios',
      //     value:
      //       'D) Regularly use software tools to analyse and/or compare different land use scenarios',
      //   },
      //   {
      //     hide: true,

      //     id: 2,
      //     text: 'E) Can prepare farm environment plans',
      //     value: 'E) Can prepare farm environment plans',
      //   },
      //   {
      //     hide: true,

      //     id: 3,
      //     text: 'F)	Work with Māori agribusinesses',
      //     value: 'F)	Work with Māori agribusinesses',
      //   },
      // ],
      choices: [
        {
          id: 1,
          text:
            'D) Regularly use software tools to analyse and/or compare different land use scenarios',
          value:
            'D) Regularly use software tools to analyse and/or compare different land use scenarios',
        },
        {
          id: 2,
          text: 'E) Can prepare farm environment plans',
          value: 'E) Can prepare farm environment plans',
        },
        {
          id: 3,
          text: 'F)	Work with Māori agribusinesses',
          value: 'F)	Work with Māori agribusinesses',
        },
      ],
      next: ({ answers }) => {
        // const answersArray = answers['advisor-capacity-and-capability-gaps-p3'].value
        const answersArray3 = answers['advice-frequency-in-role']
        // const answersArray2 = answers['advisor-capacity-and-capability-gaps-p3'].value

        // if (answersArray2.some(item => item.value !== 'None' && item.value !== "Don't know")) {
        //   return 'understanding-goals-and-values-for-producer-p2'
        // }

        if (
          (answersArray3.value === 'never' || answersArray3.value === 'rarely') &&
          !answers.role.value.some(
            uAnswer =>
              uAnswer.value === 'Farm consultant' ||
              uAnswer.value === 'Environmental consultant' ||
              uAnswer.value === 'Forestry consultant' ||
              uAnswer.value === 'Horticultural consultant' ||
              uAnswer.value === 'People management consultant',
          )
        ) {
          return 'advice-frequency-land-use'
        }

        // if (answersArray.some(item => item.value !== 'None' && item.value !== "Don't know")) {
        //   return 'understanding-goals-and-values-for-producer-p2'
        // }

        return 'professional-dev'
      },
      //Q 3.3
    },
    //Q3.3
    // {
    //   id: 'understanding-goals-and-values-for-producer',
    //   required: true,
    //   useColumns: true,
    //   text: () => {
    //     return (
    //       <Wrapper>
    //         <p>
    //           In the regions where you work, how would you describe the <b>number of people</b>{' '}
    //           that:
    //         </p>
    //       </Wrapper>
    //     )
    //   },
    //   // text: 'In the regions where you work, how would you describe the number of people that:',
    //   type: responsiveMatrix,
    //   hide: true,
    //   width: '2rem',
    //   height: '3rem',
    //   boxWidth: '795px',
    //   customBorder: true,
    //   ignoreWidth: true,
    //   useIcons: true,
    //   // commentBox: true,
    //   // placeholder: 'Additional comments',
    //   options: [
    //     {
    //       id: 1,
    //       text: 'Critically low',
    //       value: 'Critically low',
    //     },
    //     {
    //       id: 2,
    //       text: 'Low',
    //       value: 'Low',
    //     },
    //     {
    //       id: 3,
    //       text: 'Just enough',
    //       value: 'Just enough',
    //     },
    //     {
    //       id: 4,
    //       text: 'More than enough',
    //       value: 'More than enough',
    //     },
    //     {
    //       id: 5,
    //       text: "I don't know",
    //       paddingLeft: '2rem',
    //       value: "I don't know",
    //     },
    //   ],
    //   columns: [
    //     {
    //       hide: true,
    //       id: 1,
    //       value: 'Critically low',
    //       text: 'Critically low',
    //       icon: <IconAngrySmiley fill="currentColor" />,
    //     },
    //     {
    //       hide: true,
    //       id: 2,
    //       text: 'Low',
    //       value: 'Low',
    //       icon: <IconSmileyTwo fill="currentColor" />,
    //     },
    //     {
    //       hide: true,
    //       id: 3,
    //       value: 'Just enough',
    //       text: 'Just enough',
    //       icon: <IconSmileyFour fill="currentColor" />,
    //     },
    //     {
    //       hide: true,
    //       id: 4,
    //       value: 'More than enough',
    //       text: 'More than enough',
    //       icon: <IconSmileyFive fill="currentColor" />,
    //     },
    //     {
    //       hide: true,
    //       id: 5,
    //       value: "I don't know",
    //       text: "I don't know",
    //       paddingLeft: '4rem',
    //       color: '#000',
    //       icon: "Don't know",
    //       // icon: <IconSmileyThree fill="currentColor" />,
    //     },
    //   ],
    //   rows: [
    //     {
    //       hide: true,
    //       id: 1,
    //       text: 'A) Provide robust advice on productive and sustainable land and water use',
    //       value: 'A) Provide robust advice on productive and sustainable land and water use',
    //     },
    //     {
    //       hide: true,
    //       id: 2,
    //       text: 'B) Provide robust advice on changing land use',
    //       value: 'B) Provide robust advice on changing land use',
    //     },
    //     {
    //       hide: true,
    //       id: 3,
    //       text: 'C) Provide robust advice on whole farm systems (including business planning)',
    //       value: 'C) Provide robust advice on whole farm systems (including business planning)',
    //     },
    //     // {
    //     //   id: 4,
    //     //   text:
    //     //     'D) Regularly use software tools to analyse and/or compare different land use scenarios',
    //     //   value:
    //     //     'D) Regularly use software tools to analyse and/or compare different land use scenarios',
    //     // },
    //     // {
    //     //   id: 5,
    //     //   text: 'E) Can prepare farm environment plans',
    //     //   value: 'E) Can prepare farm environment plans',
    //     // },
    //     // {
    //     //   id: 6,
    //     //   text: 'F)	Work with Māori agribusinesses',
    //     //   value: 'F)	Work with Māori agribusinesses',
    //     // },
    //   ],
    //   choices: [
    //     {
    //       id: 1,
    //       text: 'A) Provide robust advice on productive and sustainable land and water use',
    //       value: 'A) Provide robust advice on productive and sustainable land and water use',
    //     },
    //     {
    //       id: 2,
    //       text: 'B) Provide robust advice on changing land use',
    //       value: 'B) Provide robust advice on changing land use',
    //     },
    //     {
    //       id: 3,
    //       text: 'C) Provide robust advice on whole farm systems (including business planning)',
    //       value: 'C) Provide robust advice on whole farm systems (including business planning)',
    //     },
    //     // {
    //     //   id: 4,
    //     //   text:
    //     //     'D) Regularly use software tools to analyse and/or compare different land use scenarios',
    //     //   value:
    //     //     'D) Regularly use software tools to analyse and/or compare different land use scenarios',
    //     // },
    //     // {
    //     //   id: 5,
    //     //   text: 'E) Can prepare farm environment plans',
    //     //   value: 'E) Can prepare farm environment plans',
    //     // },
    //     // {
    //     //   id: 6,
    //     //   text: 'F)	Work with Māori agribusinesses',
    //     //   value: 'F)	Work with Māori agribusinesses',
    //     // },
    //   ],
    //   // next: ({ answers }) => {
    //   //   console.log('check answer', answers['advice-frequency-in-role'].value)
    //   //   if (
    //   //     answers['advice-frequency-in-role'].value === 'Sometimes' ||
    //   //     answers['advice-frequency-in-role'].value === 'Often' ||
    //   //     answers['advice-frequency-in-role'].value === 'All the time'
    //   //   ) {
    //   //     return 'career-pathways' // career pathways
    //   //   }
    //   //   return 'support-for-the-advisory-system' // support
    //   //   // return 'support-for-the-advisory-system' // change to support
    //   // },
    //   // next: ({ answers }) => {
    //   //   const landRelated = [
    //   //     'Environment management',
    //   //     'Land production and optimisation',
    //   //     'Policy, regulation, and compliance',
    //   //     'Nutrient management',
    //   //     'Whole system advice (all of the above)',
    //   //   ]
    //   //   if (answers['advisor-capacity-reach'].value.some(ans => landRelated.includes(ans.value))) {
    //   //     return 'rate-own-expertise'
    //   //   }
    //   //   return 'education'
    //   // },
    // },
    // {
    //   id: 'understanding-goals-and-values-for-producer-p2',
    //   required: true,
    //   text: () => {
    //     return (
    //       <Wrapper>
    //         <p>
    //           In the regions where you work, how would you describe the <b>number of people</b>{' '}
    //           that:
    //         </p>
    //       </Wrapper>
    //     )
    //   },
    //   // text: 'In the regions where you work, how would you describe the number of people that:',
    //   type: responsiveMatrix,
    //   hide: true,
    //   useColumns: true,
    //   width: '2rem',
    //   height: '3rem',
    //   boxWidth: '795px',
    //   customBorder: true,
    //   ignoreWidth: true,
    //   useIcons: true,
    //   // commentBox: true,
    //   // placeholder: 'Additional comments',
    //   options: [
    //     {
    //       id: 1,
    //       text: 'Critically low',
    //       value: 'Critically low',
    //     },
    //     {
    //       id: 2,
    //       text: 'Low',
    //       value: 'Low',
    //     },
    //     {
    //       id: 3,
    //       text: 'Borderline / just enough',
    //       value: 'Borderline / just enough',
    //     },
    //     {
    //       id: 4,
    //       text: 'Well-served / more than enough',
    //       value: 'Well-served / more than enough',
    //     },
    //     {
    //       id: 5,
    //       text: "I don't know",
    //       paddingLeft: '2rem',
    //       value: "I don't know",
    //     },
    //   ],
    //   columns: [
    //     {
    //       hide: true,
    //       id: 1,
    //       value: 'Critically low',
    //       text: 'Critically low',
    //       icon: <IconAngrySmiley fill="currentColor" />,
    //     },
    //     {
    //       hide: true,
    //       id: 2,
    //       text: 'Low',
    //       value: 'Low',
    //       icon: <IconSmileyTwo fill="currentColor" />,
    //     },
    //     {
    //       hide: true,
    //       id: 3,
    //       value: 'Just enough',
    //       text: 'Just enough',
    //       icon: <IconSmileyFour fill="currentColor" />,
    //     },
    //     {
    //       hide: true,
    //       id: 4,
    //       value: 'More than enough',
    //       text: 'More than enough',
    //       icon: <IconSmileyFive fill="currentColor" />,
    //     },
    //     {
    //       hide: true,
    //       id: 5,
    //       value: "I don't know",
    //       text: "I don't know",
    //       paddingLeft: '4rem',
    //       color: '#000',
    //       icon: "Don't know",
    //       // icon: <IconSmileyThree fill="currentColor" />,
    //     },
    //   ],
    //   rows: [
    //     {
    //       hide: true,

    //       id: 1,
    //       text:
    //         'D) Regularly use software tools to analyse and/or compare different land use scenarios',
    //       value:
    //         'D) Regularly use software tools to analyse and/or compare different land use scenarios',
    //     },
    //     {
    //       hide: true,

    //       id: 2,
    //       text: 'E) Can prepare farm environment plans',
    //       value: 'E) Can prepare farm environment plans',
    //     },
    //     {
    //       hide: true,

    //       id: 3,
    //       text: 'F)	Work with Māori agribusinesses',
    //       value: 'F)	Work with Māori agribusinesses',
    //     },
    //   ],
    //   choices: [
    //     {
    //       id: 1,
    //       text:
    //         'D) Regularly use software tools to analyse and/or compare different land use scenarios',
    //       value:
    //         'D) Regularly use software tools to analyse and/or compare different land use scenarios',
    //     },
    //     {
    //       id: 2,
    //       text: 'E) Can prepare farm environment plans',
    //       value: 'E) Can prepare farm environment plans',
    //     },
    //     {
    //       id: 3,
    //       text: 'F)	Work with Māori agribusinesses',
    //       value: 'F)	Work with Māori agribusinesses',
    //     },
    //   ],
    //   //Q 3.3
    // },

    // 4

    // {
    //   id: 'support-for-the-advisory-system',
    //   required: true,
    //   section: (
    //     <Wrapper>
    //       <SectionHeader>Support for the advisory system</SectionHeader>
    //       <Divider />
    //       <QuestionInfo>To what extent does your role relate to…</QuestionInfo>
    //     </Wrapper>
    //   ),
    //   type: responsiveMatrix,
    //   choices: [
    //     {
    //       id: 1,
    //       text: 'Productive and sustainable land use',
    //       value: 'Productive and sustainable land use',
    //     },
    //     { id: 2, text: 'Changing land use', value: 'changing land use' },
    //     { id: 3, text: 'Whole farm systems', value: 'whole farm systems' },
    //   ],
    //   options: [
    //     {
    //       id: 1,
    //       text: 'No relation',
    //       value: 'No relation',
    //     },
    //     {
    //       id: 2,
    //       text: 'Very little',
    //       value: 'Very little',
    //     },
    //     {
    //       id: 3,
    //       text: 'Some',
    //       value: 'Some',
    //     },
    //     { id: 4, text: 'A lot ', value: 'A lot' },
    //     { id: 5, text: 'This is my key focus', value: 'This is my key focus' },
    //   ],
    //   columns: [
    //     {
    //       id: 1,
    //       text: 'No relation',
    //       value: '1',
    //     },
    //     {
    //       id: 2,
    //       text: 'Very little',
    //       value: '2',
    //     },
    //     {
    //       id: 3,
    //       text: 'Some',
    //       value: '3',
    //     },
    //     { id: 4, text: 'A lot ', value: '4' },
    //     { id: 5, text: 'This is my key focus', value: '5' },
    //   ],
    //   rows: [
    //     {
    //       id: 1,
    //       text: 'Productive and sustainable land use',
    //       value: 'Productive and sustainable land use',
    //     },
    //     { id: 2, text: 'Changing land use', value: 'changing land use' },
    //     { id: 3, text: 'Whole farm systems', value: 'whole farm systems' },
    //   ],
    // },
    {
      id: 'advice-frequency-land-use',
      type: multiChoice,
      verticalGrid: true,
      required: true,
      // section: (
      //   <Wrapper style={{ marginBottom: '1rem' }}>
      //     <SectionHeader style={{ fontSize: '18px' }}>Advisor networks</SectionHeader>
      //     <StyledP style={{ fontSize: '18px' }}>
      //       We are interested in understanding the networks formed in the advisory system, and how
      //       knowledge and capability about <b>sustainable land use practices</b> is being shared.
      //     </StyledP>
      //     {/* <StyledP style={{ fontSize: '16px', marginTop: '0.5rem' }}>
      //       To do this, we would like to ask you about where you work, and your key connections.
      //     </StyledP>
      //     <StyledP style={{ fontSize: '16px', marginTop: '0.5rem' }}>
      //       We understand that this is sensitive information, and we will respect this information
      //       appropriately.
      //     </StyledP> */}

      //     {/* <Disclaimer style={{ fontSize: '14px', marginTop: '0.5rem' }}>
      //       We understand that this is sensitive information, and we will respect this information
      //       appropriately.
      //     </Disclaimer> */}
      //   </Wrapper>
      // ),
      disclaimer: 'This information is for research purposes only.',
      text: (
        <div>
          <StyledP>
            How often do you provide support, advice, or recommendations <b>to advisers</b> in your
            role?
          </StyledP>
        </div>
      ),
      width: '24rem',
      keys: [
        {
          id: 1,
          text: 'Never',
          description: '',
        },
        {
          id: 2,
          text: 'Rarely',
          description: ': a few times a year.',
        },
        {
          id: 3,
          text: 'Sometimes',
          description: ': a few times a month.',
        },
        {
          id: 4,
          text: 'Often',
          description: ': once or twice a week.',
        },
        {
          id: 5,
          text: 'All the time',
          description: ': at least three times a week.',
        },
      ],
      columns: [
        {
          id: 'Never',
          text: 'Never',
          value: 'Never',
          hide: true,
        },
        {
          id: 'Rarely',
          text: 'Never',
          value: 'Rarely',
          hide: true,
        },
        {
          id: 'Sometimes',
          text: 'Never',
          value: 'Sometimes',
          hide: true,
        },
        {
          id: 'Often',
          text: 'Often',
          value: 'Often',
          hide: true,
        },
        {
          id: 'All the time',
          text: 'All the time',
          value: 'All the time',
          hide: true,
        },
      ],
      choices: [
        {
          id: 1,
          text: 'Never',
          value: 'Never',
        },
        {
          id: 2,
          text: 'Rarely - a few times a year',
          value: 'Rarely - a few times a year',
        },
        {
          id: 3,
          text: 'Sometimes - a few times a month',
          value: 'Sometimes - a few times a month',
        },
        {
          id: 4,
          text: 'Often - a few times a week',
          value: 'Often - a few times a week',
        },
        {
          id: 5,
          text: 'All the time - several times a week / daily',
          value: 'All the time - several times a week / daily',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Never',
          value: 'Never',
        },
        {
          id: 2,
          text: 'Rarely',
          value: 'Rarely',
        },
        {
          id: 3,
          text: 'Sometimes',
          value: 'Sometimes',
        },
        {
          id: 4,
          text: 'Often',
          value: 'Often',
        },
        {
          id: 5,
          text: 'All the time',
          value: 'All the time',
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'Sustainable land use frequency',
          value: 'Sustainable land use frequency',
        },
      ],
      next: ({ answers }) => {
        if (answers['advice-frequency-land-use'].value !== 'Never') {
          return 'support-for-advisers'
        }
        return 'education'
      },
    },
    {
      id: 'support-for-advisers',
      verticalGrid: true,
      required: true,
      text:
        'In the last 5 years, has demand for your support to advisers (including research, advice and recommendations) changed?',
      type: multiChoice,
      choices: [
        { id: 1, text: 'It has decreased substantially', value: 'It has decreased substantially' },
        { id: 2, text: 'It has decreased slightly', value: 'It has decreased slightly' },
        {
          id: 3,
          text: 'It is about the same as 5 years ago',
          value: 'It is about the same as 5 years ago',
        },
        { id: 4, text: 'It has increased slightly ', value: 'It has increased slightly ' },
        { id: 5, text: 'It has increased substantially', value: 'It has increased substantially' },
      ],
    },
    {
      id: 'adviser-assistance',
      verticalGrid: true,
      width: '24rem',
      fontSize: '15px',
      required: true,
      mobileHeight: '5rem',
      disclaimer: '',
      max: 3,
      text: () => {
        return (
          <p>
            Which areas of support do you mainly assist advisers with? <b>Pick up to 3 options.</b>
          </p>
        )
      },
      type: multiSelect,
      choices: ({ answers }) => {
        const output = [
          adviserSupport,
          { id: 16, text: 'Other, please specify', value: '', type: input, width: '24.5rem' },
        ]
        return _.flatten(output)
      },
      next: ({ answers }) => {
        // if (
        //   answers.role.value.some(
        //     answer =>
        //       answer.value === 'Farm consultant' ||
        //       answer.value === 'Environmental consultant' ||
        //       answer.value === 'Forestry consultant' ||
        //       answer.value === 'Horticultural consultant' ||
        //       answer.value === 'People management consultant',
        //   )
        // ) {
        //   return 'professional-dev'
        // }
        if (
          answers['advice-frequency-in-role'].value === 'sometimes' ||
          answers['advice-frequency-in-role'].value === 'often' ||
          answers['advice-frequency-in-role'].value === 'all the time'
        ) {
          return 'professional-dev'
        }
        return 'education'
      },
    },
    {
      id: 'professional-dev',
      required: true,
      max: 3,
      fontSize: '15px',
      width: '24.5rem',
      // height: '100px',
      verticalGrid: true,
      text: (
        <QuestionInfo>
          Where do you see your own professional development needs in the next few years? Pick up to{' '}
          <strong>3 options</strong>.
        </QuestionInfo>
      ),
      disclaimer: 'Please select up to 3 options.',
      type: multiSelect,
      choices: ({ answers }) => {
        const randomArray = [professionalDevelopment]
        const other = {
          id: 12,
          type: input,
          text: 'Other, please describe',
          value: 'other',
          mobileProps: { top: null },
          width: '24rem',
        }

        randomArray.push(other)
        return _.flatten(randomArray)
      },
      // next: ({ answers }) => {
      //   // if (answers.role.value.some(answer => answer.value === 'advisor')) {
      //   //   return 'advice-frequency-land-use'
      //   // }
      //   return 'support-for-the-advisory-system'
      // },
    },

    // {
    //   id: 'adviser-networks',
    //   section: (
    //     <Wrapper style={{ marginBottom: '1rem' }}>
    //       <SectionHeader style={{ fontSize: '16px' }}>Advisor networks</SectionHeader>
    //       <StyledP style={{ fontSize: '16px' }}>
    //         We are interested in understanding the networks formed in the advisory system. We also
    //         hope to identify how knowledge and capability about{' '}
    //         <b>sustainable land use practices</b> is shared across the advisory system.
    //       </StyledP>
    //       <StyledP style={{ fontSize: '16px', marginTop: '0.5rem' }}>
    //         To do this, we would like to ask you about where you work, and your key connections.
    //       </StyledP>
    //       <StyledP style={{ fontSize: '16px', marginTop: '0.5rem' }}>
    //         We understand that this is sensitive information, and we will respect this information
    //         appropriately.
    //       </StyledP>

    //       {/* <Disclaimer style={{ fontSize: '14px', marginTop: '0.5rem' }}>
    //         We understand that this is sensitive information, and we will respect this information
    //         appropriately.
    //       </Disclaimer> */}
    //     </Wrapper>
    //   ),
    //   type: section,
    // },
    {
      id: 'career-pathways',
      type: multiChoice,
      verticalGrid: true,
      required: true,
      width: '49rem',
      height: '5rem',
      mobileHeight: '8rem',
      section: (
        <Wrapper>
          {/* <SectionHeader>Career pathways</SectionHeader> */}
          <QuestionInfo>
            We know that there is not just one pathway into advisory roles. The next few questions
            aim to paint a picture of the different pathways advisors take.
          </QuestionInfo>
          <Divider style={{ width: '800px' }} />
        </Wrapper>
      ),
      text:
        'Which of the below best describes the pathway you took into an advisory capacity in the primary sector?',
      choices: ({ answers }) => {
        const output = [
          {
            id: 1,
            text: (
              <p style={{ marginBottom: 0 }}>
                <b>Direct from study to advisory role</b> - The ‘traditional’ pathway beginning with
                an agriculture-related degree at Massey or Lincoln, and leading directly into a
                graduate role in an advisory capacity.
              </p>
            ),
            rawText:
              'Direct from study to advisory role - The ‘traditional’ pathway beginning with an agriculture-related degree at Massey or Lincoln, and leading directly into a graduate role in an advisory capacity.',
            mobileProps: { height: '8rem' },
            value: 'Direct from study to advisory role',
          },

          {
            id: 2,
            text: (
              <p style={{ marginBottom: 0 }}>
                <b>Indirect path through primary sector</b> – While still beginning with an
                agriculture-related degree, this pathway diverts through a wide variety of other
                roles in the primary sector first.
              </p>
            ),
            rawText:
              'Indirect path through primary sector</b> – While still beginning with an agriculture-related degree, this pathway diverts through a wide variety of other roles in the primary sector first.',
            mobileProps: { height: '8rem' },
            value: 'Indirect path through primary sector',
          },
          {
            id: 3,
            text: (
              <p style={{ marginBottom: 0 }}>
                <b>Indirect path through other industries</b> – You worked in other sectors before
                working in an advisory capacity in the primary sector. You may have either an
                agriculture-related degree, or another educational background.
              </p>
            ),
            rawText:
              'Indirect path through other industries – You worked in other sectors before  working in an advisory capacity in the primary sector. You may have either an agriculture-related degree, or another educational background.',
            mobileProps: { height: '8rem' },
            value: 'Indirect path through other industries',
          },
          {
            id: 4,
            type: input,
            text: 'Other, please specify',
            width: '49rem',
          },
        ]
        return output
      },
      // next: ({ answers }) => {
      //   if (answers['career-pathways'].value === 'Indirect path through other industries') {
      //     return 'describe-career-pathway'
      //   }
      //   return 'education'
      // },
      // Qid 4.1f
    },
    {
      id: 'industry-experience',
      type: responsiveMatrix,
      required: true,
      width: '145px',
      hide: true,
      useColumns: true,
      columns: [
        {
          id: '< 1 year',
          hide: true,
          text: 'Less than 1 year',
          value: 'Less than 1 year',
        },
        {
          id: '1-4 years',
          hide: true,
          text: '1-4 years',
          value: '1-4 years',
        },
        {
          id: '5-10 years',
          hide: true,
          text: '5-10 years',
          value: '5-10 years',
        },
        {
          id: '11-20 years',
          hide: true,
          text: '11-20 years',
          value: '11-20 years',
        },
        {
          id: '20+ years',
          hide: true,
          text: '20+ years',
          value: '20+ years',
        },
      ],
      choices: ({ answers }) => {
        const output = [
          {
            id: 1,
            text: 'How long have you been working in an advisory capacity?',
            hide: true,
            value: 'Roughly how long have you been working in an advisory capacity?',
          },
        ]
        const otherRoles = {
          id: 2,
          text: 'And how long were you working in other roles before advising?',
          hide: true,
          value: 'And how long were you working in other roles before advising?',
        }

        if (!_.isUndefined(answers) && 'career-pathways' in answers) {
          if (answers['career-pathways'].value !== 'Direct from study to advisory role') {
            output.push(otherRoles)
          }
        } else {
          output.push(otherRoles)
        }
        // if (answers['advisory-entry-point'].value !== 'Studying') {
        //   output.push(otherRoles)
        //   return output
        // }
        // output.push(otherRoles)
        return output
      },
      options: [
        {
          id: 'Less than 1 year',
          text: 'Less than 1 year',
          value: 'Less than 1 year',
        },
        {
          id: '1-4 years',
          text: '1-4 years',
          value: '1-4 years',
        },
        {
          id: '5-10 years',
          text: '5-10 years',
          value: '5-10 years',
        },
        {
          id: '11-20 years',
          text: '11-20 years',
          value: '11-20 years',
        },
        {
          id: '20+ years',
          text: '20+ years',
          value: '20+ years',
        },
      ],
      // rows: [
      //   {
      //     id: 1,
      //     text: 'How long have you been working in an advisory capacity',
      //     hide: true,
      //     value: 'How long have you been working in an advisory capacity',
      //   },
      //   {
      //     id: 2,
      //     text: 'And how long were you working in other roles before advising?',
      //     hide: true,
      //     value: 'And how long were you working in other roles before advising?',
      //   },
      // ],
      rows: ({ answers }) => {
        const output = [
          {
            id: 1,
            text: 'How long have you been working in an advisory capacity?',
            hide: true,
            value: 'Roughly how long have you been working in an advisory capacity?',
          },
        ]
        const otherRoles = {
          id: 2,
          text: 'And how long were you working in other roles before advising?',
          hide: true,
          value: 'And how long were you working in other roles before advising?',
        }

        if (!_.isUndefined(answers) && 'career-pathways' in answers) {
          if (answers['career-pathways'].value !== 'Direct from study to advisory role') {
            output.push(otherRoles)
          }
        } else {
          output.push(otherRoles)
        }

        return output
      },
    },
    // {
    //   id: 'describe-career-pathway',
    //   text: 'Could you specify the pathway you took?',
    //   type: commentBox,
    //   placeholder: 'My pathway',
    // },
    {
      id: 'education',
      type: multiChoice,
      verticalGrid: true,
      required: true,
      text: (
        <StyledP>
          What is the <b>highest</b> level of education you have completed?
        </StyledP>
      ),
      choices: [
        { id: 1, text: 'Secondary school certificate', value: 'Secondary school certificate' },
        {
          id: 2,
          text: 'Post-secondary school certificate or diploma',
          value: 'Post-secondary school certificate or diploma',
        },
        { id: 3, text: "Bachelor's degree", value: "Bachelor's degree" },
        {
          id: 4,
          text: 'Post-graduate certificate / diploma or honours degree',
          value: 'Post-graduate certificate / diploma or honours degree',
        },
        { id: 5, text: "Master's degree", value: "Master's degree" },
        { id: 6, text: 'Doctoral degree', value: 'Doctoral degree' },
        {
          id: 7,
          text: 'Other, please specify',
          value: 'other',
          type: input,
          width: '551px',
          mobileProps: { top: null },
        },
      ],
      next: ({ answers }) => {
        if (
          answers.education.id === 2 ||
          answers.education.id === 3 ||
          answers.education.id === 4 ||
          answers.education.id === 5 ||
          answers.education.id === 6
        ) {
          return 'higher-education'
        }

        // if (answers['career-pathways'].value !== 'Direct from study to advisory role') {
        //   return 'industry-experience'
        // }
        // add support page next logic
        return 'gender'
      },
    },
    {
      id: 'higher-education',
      required: true,
      text: 'Which field(s) of study was this tertiary-level education in?',
      verticalGrid: true,
      type: multiCardChoice,
      choices: [
        {
          id: 1,
          text: 'Agricultural Science',
          value: 'Agricultural Science',
          icon: <IconAgriculture fill="currentColor" />,
        },
        { id: 2, text: 'Science', value: 'Science', icon: <IconScience fill="currentColor" /> },
        {
          id: 3,
          text: 'Veterinary Science',
          value: 'Veterinary Science',
          icon: <IconVet fill="currentColor" />,
        },
        {
          id: 4,
          text: 'Commerce / Economics',
          value: 'Commerce',
          icon: <IconCommerce fill="currentColor" />,
        },
        { id: 5, text: 'Arts', value: 'Arts', icon: <IconArts fill="currentColor" /> },
        {
          id: 6,
          text: 'Other, please describe',
          value: 'other',
          type: input,
        },
      ],
      // next: ({ answers }) => {
      //   const answersArray3 = answers['advice-frequency-in-role']

      //   if (answersArray3.value === 'never' || answersArray3.value === 'rarely') {
      //     return 'gender'
      //   }

      //   return 'industry-experience'
      // },
    },

    // {
    //   id: 'industry-experience',
    //   type: responsiveMatrix,
    //   required: true,
    //   width: '145px',
    //   hide: true,
    //   useColumns: true,
    //   columns: [
    //     {
    //       id: '< 1 year',
    //       hide: true,
    //       text: 'Less than 1 year',
    //       value: 'Less than 1 year',
    //     },
    //     {
    //       id: '1-4 years',
    //       hide: true,
    //       text: '1-4 years',
    //       value: '1-4 years',
    //     },
    //     {
    //       id: '5-10 years',
    //       hide: true,
    //       text: '5-10 years',
    //       value: '5-10 years',
    //     },
    //     {
    //       id: '11-20 years',
    //       hide: true,
    //       text: '11-20 years',
    //       value: '11-20 years',
    //     },
    //     {
    //       id: '20+ years',
    //       hide: true,
    //       text: '20+ years',
    //       value: '20+ years',
    //     },
    //   ],
    //   choices: ({ answers }) => {
    //     const output = [
    //       {
    //         id: 1,
    //         text: 'Roughly how long have you been working in an advisory capacity?',
    //         hide: true,
    //         value: 'Roughly how long have you been working in an advisory capacity?',
    //       },
    //     ]
    //     const otherRoles = {
    //       id: 2,
    //       text: 'And how long were you working in other roles before advising?',
    //       hide: true,
    //       value: 'And how long were you working in other roles before advising?',
    //     }

    //     // if (answers['advisory-entry-point'].value !== 'Studying') {
    //     //   output.push(otherRoles)
    //     //   return output
    //     // }
    //     output.push(otherRoles)
    //     return output
    //   },
    //   options: [
    //     {
    //       id: 'Less than 1 year',
    //       text: 'Less than 1 year',
    //       value: 'Less than 1 year',
    //     },
    //     {
    //       id: '1-4 years',
    //       text: '1-4 years',
    //       value: '1-4 years',
    //     },
    //     {
    //       id: '5-10 years',
    //       text: '5-10 years',
    //       value: '5-10 years',
    //     },
    //     {
    //       id: '11-20 years',
    //       text: '11-20 years',
    //       value: '11-20 years',
    //     },
    //     {
    //       id: '20+ years',
    //       text: '20+ years',
    //       value: '20+ years',
    //     },
    //   ],
    //   // rows: [
    //   //   {
    //   //     id: 1,
    //   //     text: 'How long have you been working in an advisory capacity',
    //   //     hide: true,
    //   //     value: 'How long have you been working in an advisory capacity',
    //   //   },
    //   //   {
    //   //     id: 2,
    //   //     text: 'And how long were you working in other roles before advising?',
    //   //     hide: true,
    //   //     value: 'And how long were you working in other roles before advising?',
    //   //   },
    //   // ],
    //   rows: ({ answers }) => {
    //     const output = [
    //       {
    //         id: 1,
    //         text: 'Roughly how long have you been working in an advisory capacity?',
    //         hide: true,
    //         value: 'Roughly how long have you been working in an advisory capacity?',
    //       },
    //     ]
    //     const otherRoles = {
    //       id: 2,
    //       text: 'And how long were you working in other roles before advising?',
    //       hide: true,
    //       value: 'And how long were you working in other roles before advising?',
    //     }

    //     if (answers['career-pathways'].value !== 'Direct from study to advisory role') {
    //       output.push(otherRoles)
    //       return output
    //     }
    //     return output
    //   },
    // },
    {
      id: 'gender',
      required: true,
      section: (
        <Wrapper style={{ marginBottom: '0rem' }}>
          {/* <SectionHeader>Demographics</SectionHeader> */}
          {/* <Divider style={{ borderTop: '1px solid #48a54c' }} /> */}
          <QuestionInfo>What gender do you identify as?</QuestionInfo>
        </Wrapper>
      ),
      type: multiChoice,
      verticalGrid: true,
      choices: [
        { id: 1, text: 'Male', value: 'Male' },
        { id: 2, text: 'Female', value: 'Female' },
        { id: 3, text: 'Other', value: 'Other' },
        // { id: 4, text: 'Prefer not to answer', value: 'Prefer not to answer' },
      ],
    },
    {
      id: 'ethnic-group',
      verticalGrid: true,
      type: multiSelect,
      required: true,
      text: 'Which ethnic group(s) do you belong to?',
      choices: [
        { id: 1, text: 'New Zealand European', value: 'New Zealand European' },
        { id: 2, text: 'Māori', value: 'Māori' },
        { id: 3, text: 'Pacific peoples', value: 'Pacific peoples' },
        {
          id: 4,
          text: 'Asian',
          value: 'Asian',
        },
        { id: 5, text: 'Other, please specify', value: 'other', type: input },
        // { id: 6, text: 'Prefer not to answer', value: 'Prefer not to answer', submit: true },
      ],
    },
    {
      id: 'age',
      type: multiChoice,
      text: 'What is your age?',
      required: true,
      verticalGrid: true,
      choices: [
        { id: 1, text: '19 years or younger', value: '19 years or younger' },
        { id: 2, text: '20 - 29 years old', value: '20 - 29 years old' },
        { id: 3, text: '30 - 39 years old', value: '30 - 39 years old' },
        { id: 4, text: '40 - 49 years old', value: '40 - 49 years old' },
        { id: 5, text: '50 - 59 years old', value: '50 - 59 years old' },
        { id: 6, text: '60 - 69 years old', value: '60 - 69 years old' },
        { id: 7, text: '70 years or older ', value: '70 years or older ' },
        { id: 8, text: 'Prefer not to answer', value: 'Prefer not to answer' },
      ],
      next: ({ answers }) => {
        if (
          answers['which-org-do-you-work-at'].value === 'I would rather not disclose this' ||
          answers['which-org-do-you-work-at'].value === 'I am a sole trader / work by myself'
        ) {
          return 'interventions-intro'
        }
        return 'advice-frequency-practices'
      },
      // next: ({ answers }) => {
      //   if (
      //     answers['advice-frequency-in-role'].value === 'sometimes' ||
      //     answers['advice-frequency-in-role'].value === 'often' ||
      //     answers['advice-frequency-in-role'].value === 'all the time'
      //   ) {
      //     return 'professional-dev'
      //   }
      //   return 'support-for-the-advisory-system'
      // },
    },
    {
      id: 'advice-frequency-practices',
      type: multiChoice,
      required: true,
      verticalGrid: true,
      section: (
        <Wrapper style={{ marginBottom: '1rem' }}>
          <SectionHeader style={{ fontSize: '16px' }}>Advisor networks</SectionHeader>
          {/* <StyledP>
            We know that sustainability means something different for everyone, so answer in regard
            to what is meaningful to you.
          </StyledP> */}
          <StyledP>
            We are interested in understanding the networks formed in the advisory system, and how
            knowledge about <b>sustainable land and water use practices</b> is shared across the
            system.
          </StyledP>
        </Wrapper>
      ),
      text: (
        <Wrapper>
          <StyledP>
            How often do you talk to <b>other advisers</b> about sustainable land use practices?
          </StyledP>
          <Disclaimer style={{ fontStyle: 'italic', fontSize: '14px' }}>
            We know that sustainability means something different for everyone, so answer in regard
            to what is meaningful to you.
          </Disclaimer>
        </Wrapper>
      ),
      disclaimer:
        'We know that sustainability means something different for everyone, so answer in regard to what is meaningful to you. ',
      choices: [
        { id: 1, text: 'Never', value: 'Never' },
        { id: 2, text: 'Rarely - a few times a year', value: 'rarely' },
        { id: 3, text: 'Sometimes - a few times a month', value: 'sometimes' },
        { id: 4, text: 'Often - a few times a week', value: 'often' },
        { id: 5, text: 'All the time - several times a week / daily', value: 'all the time' },
        {
          id: 6,
          text: 'No comment - skip this section',
          value: 'skip',
        },
      ],
      next: ({ answers }) => {
        if (answers['advice-frequency-practices'].value === 'skip') {
          return 'interventions-intro'
        }
        if (
          answers['advice-frequency-practices'].value !== 'Never' &&
          answers['advice-frequency-practices'].value !== 'rarely'
        ) {
          return 'social-network'
        }
        return 'interventions-intro'
      },
    },
    {
      id: 'social-network',
      text: (
        <Wrapper>
          <StyledP>
            Outside of your own organisation, which three advisers do you talk to most frequently
            about <b>sustainable land and water use practices?</b>
          </StyledP>
          <Disclaimer>
            Be assured that they will not be contacted or notified. This information is for research
            purposes only.
          </Disclaimer>
        </Wrapper>
      ),
      options: companyNames,
      roles: [
        {
          id: 1,
          text: 'Farm consultant',
          value: 'Farm consultant',
          field: 'consultant',
          // icon: <IconAdvisor fill="currentColor" />,
          toolTip: 'Your role is primarily to give advice to producers.',
        },
        {
          id: 2,
          text: 'Environmental consultant',
          value: 'Environmental consultant',
          field: 'consultant',
          // icon: <IconAdvisor fill="currentColor" />,
          toolTip: 'Your role is primarily to give advice to producers.',
        },
        {
          id: 3,
          text: 'Forestry consultant',
          value: 'Forestry consultant',
          field: 'consultant',
          // icon: <IconAdvisor fill="currentColor" />,
          toolTip: 'Your role is primarily to give advice to producers.',
        },
        {
          id: 4,
          text: 'Horticultural consultant',
          value: 'Horticultural consultant',
          field: 'consultant',
          // icon: <IconAdvisor fill="currentColor" />,
          toolTip: 'Your role is primarily to give advice to producers.',
        },
        {
          id: 5,
          text: 'People management consultant',
          value: 'People management consultant',
          field: 'consultant',
          // icon: <IconAdvisor fill="currentColor" />,
          toolTip: 'Your role is primarily to give advice to producers.',
        },
        {
          id: 6,
          text: 'Banker',
          value: 'Banker',
          // icon: <IconBuilder fill="currentColor" />,
          toolTip: 'You provide a service to producers other than advice. E.g. rural contracting. ',
        },
        {
          id: 7,
          text: 'Fertiliser company representative',
          value: 'Fertiliser company representative',
          // icon: <IconBuilder fill="currentColor" />,
          toolTip: 'You provide a service to producers other than advice. E.g. rural contracting. ',
        },
        {
          id: 8,
          text: 'Rural accountant',
          value: 'Rural accountant',
          // icon: <IconBuilder fill="currentColor" />,
          toolTip: 'You provide a service to producers other than advice. E.g. rural contracting. ',
        },
        {
          id: 9,
          text: 'Veterinarian',
          value: 'Veterinarian',
          // icon: <IconBuilder fill="currentColor" />,
          toolTip: 'You provide a service to producers other than advice. E.g. rural contracting. ',
        },
        {
          id: 10,
          text: 'Agribusiness service provider (e.g FMG, PGG, Wrightson)',
          value: 'Agribusiness service provider ',
          // icon: <IconBuilder fill="currentColor" />,
          toolTip: 'You provide a service to producers other than advice. E.g. rural contracting. ',
        },
        {
          id: 11,
          text: 'Retailer',
          value: 'Retailer',
        },
        {
          id: 12,
          text: 'Researcher',
          value: 'researcher',
          // icon: <IconResearcher fill="currentColor" />,
          toolTip: 'You produce research used by advisors and/or producers.',
        },
        {
          id: 13,
          text: 'Developer',
          value: 'developer',
          // icon: <IconDeveloper fill="currentColor" />,
          toolTip: 'You develop training, extension, policy, tools, or similar for the industry.',
        },
        {
          id: 14,
          text: 'Training facilitator',
          value: 'Training facilitator',
          // icon: <IconRetailer fill="currentColor" />,
          toolTip: 'You sell products used in the primary sector.',
        },
      ],
      type: 'socialNetworkForm',
      n_cards: 3,
      choices: [
        { id: 1, value: 'other', field: 'Organisation' },
        { id: 2, value: 'other', field: 'Role' },
        { id: 3, value: 'other', field: 'Region (optional)' },
      ],
    },
    {
      id: 'interventions-intro',
      mobileHeight: '5.5rem',
      required: true,
      skipButton: skipChoice,
      section: () => {
        return (
          <Wrapper>
            <QuestionInfo>
              <GreenText>Issues and interventions</GreenText>
            </QuestionInfo>
            <Divider style={{ marginBottom: '1rem', marginTop: '0rem', width: '24rem' }} />
          </Wrapper>
        )
      },
      width: '49.5rem',
      text: () => {
        return (
          <Wrapper>
            <p style={{ marginBottom: '0.5rem', fontSize: '1rem' }}>
              The following are key issues affecting the advisory system (which we will describe in
              detail shortly).
            </p>
            <p style={{ marginBottom: '0.5rem', fontSize: '1rem', fontWeight: '700' }}>
              These issues and the interventions suggested next are not the views of MPI, but the
              ideas of advisers who have participated in this research.
            </p>
            <p style={{ marginBottom: '0.5rem', fontSize: '1rem' }}>
              Please rank these issues in order of priority you think they should be addressed, from
              highest priority (at the top) to lowest (bottom).
            </p>
          </Wrapper>
        )
      },
      type: 'ranking',
      mobileHeight: '5rem',
      verticalGrid: true,
      choices: ({ answers }) => {
        let rankingArray = issuesArray
        const Issue5Btn = {
          id: 5,
          issueId: 'Issue #5',
          text: 'Systemic challenges inhibit partnership between Māori agribusinesses and advisers',
          value:
            'Systemic challenges inhibit partnership between Māori agribusinesses and advisers',

          marginBottom: '0.5rem',
        }

        // console.log('answers in ranking', answers)

        if (
          !_.isUndefined(answers) &&
          'advisor-maori-agg-business' in answers &&
          'value' in answers['advisor-maori-agg-business'] &&
          answers['advisor-maori-agg-business'].value !== 'None'
        ) {
          console.log('should add issue 5')
          rankingArray.push(Issue5Btn)
        }

        if (
          !_.isUndefined(answers) &&
          'advisor-maori-agg-business' in answers &&
          'value' in answers['advisor-maori-agg-business'] &&
          answers['advisor-maori-agg-business'].value === 'None' &&
          _.find(rankingArray, { id: 5 })
        ) {
          console.log('issue 5 exists, will filter out')
          const filteredArray = rankingArray.filter(obj => obj.id !== 5)
          return _.uniqBy(filteredArray, 'id')
        }

        // console.log('issuesArray', issuesArray)

        const skipChoice = {
          id: 8,
          text: 'No comment - skip to the end of the survey',
          value: 'skip',
          type: 'multiChoice',
          marginBottom: '0.5rem',
          width: '48.5rem',
        }

        if (rankingArray.find(item => item.id === 8)) {
        }

        if (_.isUndefined(answers)) {
          rankingArray.push(Issue5Btn)
        }

        // rankingArray.push(skipChoice)

        console.log('final', rankingArray)
        return _.uniqBy(rankingArray, 'id')
      },
      next: ({ answers }) => {
        // if (answers['interventions-intro'].id === 7) {
        //   let issuesArray = ['Issue #1', 'Issue #2', 'Issue #3', 'Issue #4', 'Issue #5', 'Issue #6']
        //   const random = Math.floor(Math.random() * issuesArray.length)
        //   return issuesArray[random]
        // }

        if (answers['interventions-intro'].value[0].issueId) {
          return answers['interventions-intro'].value[0].issueId
        }
        return 'feedback-comments'
      },
    },

    {
      id: 'Issue #1',
      type: responsiveMatrix,
      verticalGrid: true,
      required: true,
      useFlex: true,
      width: '150px',
      height: '3rem',
      boxWidth: '795px',
      customBorder: true,
      // ignoreWidth: true,
      useIcons: true,
      hide: true,
      options: [
        {
          id: 1,
          text: 'Terrible idea!',
          value: 'Terrible idea!',
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
        },
        {
          id: 3,
          text: 'Neither here nor there',
          value: 'Neither here nor there',
        },
        {
          id: 4,
          text: 'Good idea',
          value: 'Good idea',
        },
        {
          id: 5,
          text: 'Great idea!',
          value: 'Great idea!',
        },
      ],
      columns: [
        {
          id: 1,
          value: 'Terrible idea',
          text: 'Terrible idea!',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          id: 3,
          value: 'Neither here nor there',
          text: 'Neither here nor there',
          icon: <IconSmileyThree fill="currentColor" />,
        },
        {
          id: 4,
          value: 'Good idea',
          text: 'Good idea',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          id: 5,
          value: 'Great idea',
          text: 'Great idea',
          icon: <IconSmileyFive fill="currentColor" />,
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'Intervention 1',
          value: 'Intervention 1',
        },
      ],
      choices: [
        {
          id: 1,
          // hide: true,
          text: 'Intervention 1',
          value: 'Intervention 1',
        },
      ],
      section: () => {
        return (
          <Wrapper style={{ marginBottom: '1rem' }}>
            {Issue1}
            <InterventionSection>
              <GreenText style={{ marginBottom: '1rem' }}>
                What do you think of this intervention as a way of addressing the issue?
              </GreenText>
              <p style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <GreenText style={{ marginRight: '0rem' }}>Intervention #1</GreenText>: Run
                campaigns to promote advisory careers
                {/* <GreenText style={{ marginRight: '0rem' }}>Intervention #1</GreenText>: Offer
                project work to private sector advisory firms containing activities suited to newly
                employed staff. Require these firms to make a commitment to employ and develop new
                staff to win contracts. */}
              </p>
              <p style={{ marginBottom: '1rem' }}>
                These could range from campaigns focused on promoting farm system consultancy
                careers to broader campaigns that aim to improve public perception of the primary
                sector.
              </p>
              <StyledCollapse ghost>
                <Panel header="Click to read more" key="2">
                  <p style={{ marginBottom: '1rem' }}>
                    A variety of campaign ideas to promote advisory careers were proposed by
                    interviewees for this work. These ranged from narrowly focused schemes, such as
                    promoting farm system consultancy careers to people studying agri-commerce or
                    agri-science degrees, to campaigns with the broad objective of improving the
                    public perception of the primary sector.
                  </p>
                  <p style={{ marginBottom: '1rem' }}>
                    Advisers noted several reasons why such schemes should be treated with caution
                    though:
                  </p>
                  <StyledUL>
                    <LI>
                      Advisory firms have good networks that they already use to access needed
                      talent. It seems likely that these could be utilised more before additional
                      investment is made to bolster them.
                    </LI>
                    <LI>
                      They only address the (arguably) secondary problem of growing the supply of
                      talent rather than the primary problem of enabling advisory firms to
                      profitably utilise that talent.
                    </LI>
                    <LI>
                      Talent attraction programmes, for both rural professionals and farmers /
                      growers, have often proven a weak investment in the past. There is a risk that
                      scarce resources are wasted making the ‘uninterested’ only ‘slightly more
                      interested’ rather than having the ‘almost ready to act’ take that action.
                    </LI>
                    <LI>
                      Such campaigns may attract people to advisory careers who are already in the
                      wider primary industry, only to heighten shortages in other primary sectors,
                      such as has possibly been the case with veterinarians becoming advisers.
                    </LI>
                  </StyledUL>
                </Panel>
              </StyledCollapse>
            </InterventionSection>
          </Wrapper>
        )
      },
    },

    {
      id: 'Issue 1 - intervention 2',
      type: responsiveMatrix,
      required: true,
      // useColumns: true,
      section: () => {
        return (
          <Wrapper>
            {Issue1}
            <InterventionSection>
              <GreenText style={{ marginBottom: '1rem' }}>
                What do you think of this intervention as a way of addressing the issue?
              </GreenText>
              <p style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <GreenText style={{ marginRight: '0rem' }}>Intervention #2</GreenText>: Strengthen
                or modify schemes that attract new advisers and make employing them easier
              </p>
              <p style={{ marginBottom: '1rem' }}>
                For example, scholarships, internships, secondments or structured training.
              </p>
            </InterventionSection>
            <StyledCollapse ghost>
              <Panel header="Click to read more" key="1">
                <Wrapper>
                  <p>
                    Several schemes are already running or have been proposed to attract new
                    advisers and / or make it more attractive to employ them. These interventions,
                    which could be strengthened with greater investment and / or modified
                    approaches, include:
                  </p>
                  <StyledUL>
                    <li>
                      <b>Scholarships</b> to encourage people into advisory roles. Although some
                      scholarships already exist, these struggle to attract the right candidates and
                      do not ensure that scholars stay in the industry when the scholarship has
                      ended. A rethink of scholarships might be useful to:
                    </li>
                    <StyledUL>
                      <li>
                        Ensure interested parties are aware of the realities of advisory work before
                        uptake of the scholarship – this may involve changing the criteria for
                        candidates to have some familiarity with advisory work
                      </li>
                      <li>
                        Re-evaluate the effectiveness of scholarship programmes themselves, if
                        scholars are leaving the industry post-programme
                      </li>
                      <li>
                        Consider bonded scholarships, where scholars are bonded to a certain number
                        of years’ work within the industry post-programme.
                      </li>
                      <p>The size and number of scholarships would need to be set to:</p>
                      <li>Provide the ‘nudge’ needed to persuade suitable candidates to act</li>
                      <li>Create an element of exclusivity / prestige</li>
                      <li>Ease the cost to an employer of taking on a new person.</li>
                    </StyledUL>
                    <li>
                      <b>Internships and secondments</b> to increase the diversity of experience
                      provided to new entrants and shorten the time needed for them to become
                      self-sufficient with producer clients.
                    </li>
                    <li>
                      <b>Structured training</b> to accelerate capability building in target areas
                      including those that market signals from producers do not currently support
                      but are likely to be valuable to producers in the medium-term.
                    </li>
                  </StyledUL>
                  <p>
                    A variation of this intervention could be to support models in which a new
                    adviser is shared by two or more organisations.
                  </p>
                </Wrapper>
              </Panel>
            </StyledCollapse>
          </Wrapper>
        )
      },
      useFlex: true,
      width: '150px',
      height: '3rem',
      boxWidth: '795px',
      customBorder: true,
      // ignoreWidth: true,
      useIcons: true,
      hide: true,
      options: [
        {
          id: 1,
          text: 'Terrible idea!',
          value: 'Terrible idea!',
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
        },
        {
          id: 3,
          text: 'Neither here nor there',
          value: 'Neither here nor there',
        },
        {
          id: 4,
          text: 'Good idea',
          value: 'Good idea',
        },
        {
          id: 5,
          text: 'Great idea!',
          value: 'Great idea!',
        },
      ],
      columns: [
        {
          id: 1,
          value: 'Terrible idea',
          text: 'Terrible idea!',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          id: 3,
          value: 'Neither here nor there',
          text: 'Neither here nor there',
          icon: <IconSmileyThree fill="currentColor" />,
        },
        {
          id: 4,
          value: 'Good idea',
          text: 'Good idea',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          id: 5,
          value: 'Great idea',
          text: 'Great idea',
          icon: <IconSmileyFive fill="currentColor" />,
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'Intervention 2',
          value: 'Intervention 2',
        },
      ],
      choices: [
        {
          id: 1,
          // hide: true,
          text: 'Intervention 2',
          value: 'Intervention 2',
        },
      ],
    },
    {
      id: 'Issue 1 - intervention 3',
      type: responsiveMatrix,
      required: true,
      // useColumns: true,
      section: () => {
        return (
          <Wrapper>
            {Issue1}
            <InterventionSection>
              <GreenText style={{ marginBottom: '1rem' }}>
                What do you think of this intervention as a way of addressing the issue?
              </GreenText>
              <p style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <GreenText style={{ marginRight: '0rem' }}>Intervention #3</GreenText>: Offer
                project work to private sector advisory firms containing activities suited to
                inexperienced advisers
              </p>
              <p style={{ marginBottom: '1rem' }}>
                Employing and developing new staff could be an explicit requirement to win such
                contracts.
              </p>
              <StyledCollapse ghost>
                <Panel header="Click to read more" key="1">
                  <Wrapper>
                    <p style={{ marginBottom: '1rem' }}>
                      Advisory firms are more likely to employ and invest in developing additional
                      new advisers if they have a secure supply of work suitable for new staff. One
                      possible source of such work is project work through government-supported
                      contracts, which could explicitly make the employment of new entrant advisers
                      a requirement of the contract or grant.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      If projects funded by MPI or other government agencies were designed to
                      utilise the services of private advisory firms, and included work that could
                      be undertaken by relatively inexperienced staff, the award of such projects
                      could be used as an incentive to build advisory capacity. Examples of current
                      (at the time of writing) funding pools for such projects might include
                      Extension Services, the Sustainable Land Management and Climate Change
                      (SLMACC) research programme, and Sustainable Food and Fibre Futures (SFF
                      Futures). Ideally the duration of project work would cover several years to
                      match the time that most advisory firms have suggested it takes for new staff
                      to develop.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      One appropriate role for new advisers within such project work may be as a
                      facilitator. In such contracts, MPI could fund the creation of facilitator
                      roles for new advisers. This would benefit the new entrant advisers by:
                    </p>
                    <StyledUL>
                      <li>
                        Enabling them to facilitate extension with farmers, which in turn would
                        enable the ongoing development of their facilitation skills and provide
                        opportunities to engage in other advisory services offered by an advisory
                        employer.
                      </li>
                      <li>
                        Exposing them to other advisers and areas of expertise, increasing their
                        knowledge and confidence over time.
                      </li>
                      <li>
                        Creating mentoring opportunities, either within their organisation or
                        externally.
                      </li>
                    </StyledUL>
                  </Wrapper>
                </Panel>
              </StyledCollapse>
            </InterventionSection>
          </Wrapper>
        )
      },
      useFlex: true,
      width: '150px',
      height: '3rem',
      boxWidth: '795px',
      customBorder: true,
      // ignoreWidth: true,
      useIcons: true,
      hide: true,
      options: [
        {
          id: 1,
          text: 'Terrible idea!',
          value: 'Terrible idea!',
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
        },
        {
          id: 3,
          text: 'Neither here nor there',
          value: 'Neither here nor there',
        },
        {
          id: 4,
          text: 'Good idea',
          value: 'Good idea',
        },
        {
          id: 5,
          text: 'Great idea!',
          value: 'Great idea!',
        },
      ],
      columns: [
        {
          id: 1,
          value: 'Terrible idea',
          text: 'Terrible idea!',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          id: 3,
          // value: 3,
          value: 'Neither here nor there',
          text: 'Neither here nor there',
          icon: <IconSmileyThree fill="currentColor" />,
        },
        {
          id: 4,
          value: 'Good idea',
          text: 'Good idea',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          id: 5,
          value: 'Great idea',
          text: 'Great idea',
          icon: <IconSmileyFive fill="currentColor" />,
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'Intervention 3',
          value: 'Intervention 3',
        },
      ],
      choices: [
        {
          id: 1,
          // hide: true,
          text: 'Intervention 3',
          value: 'Intervention 3',
        },
      ],
    },
    {
      id: 'Issue 1 - intervention 4',
      type: responsiveMatrix,
      required: true,
      // useColumns: true,
      section: () => {
        return (
          <Wrapper>
            {Issue1}
            <InterventionSection>
              <GreenText style={{ marginBottom: '1rem' }}>
                What do you think of this intervention as a way of addressing the issue?
              </GreenText>
              <p style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <GreenText style={{ marginRight: '0rem' }}>Intervention #4</GreenText>: Expand the
                talent pool by considering new advisers with alternative backgrounds
              </p>
              <p>
                These alternative new advisers would have experience and passion for the primary
                industries, but not necessarily an agriculture-related degree.
              </p>
              <StyledCollapse ghost>
                <Panel header="Click to read more" key="1">
                  <Wrapper>
                    <p style={{ marginBottom: '1rem' }}>
                      Discussions on attracting new entrants typically focus on an assumed audience
                      of recent graduates – particularly those with agri-commerce or agri-science
                      degrees. Although not an intervention that could stand alone, one action that
                      could work in conjunction with the other interventions proposed could be to
                      expand the talent pool to consider people taking indirect routes into advisory
                      roles. For example, people transferring from other areas of the primary
                      industries such as producers or people in service roles; or people who have
                      developed experience in the primary industries in other ways such as through
                      family connections or their upbringing.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>This may offer several advantages:</p>

                    <StyledUL>
                      <li>The size of the talent pool will be expanded.</li>
                      <li>
                        People with existing experience of farm and horticulture systems will get
                        over the experience hurdle more quickly than the typical 3-5 years
                        identified by interviewees in this work.
                      </li>
                      <li>
                        The people recruited into advisory roles will be more diverse and will bring
                        new ideas to the primary industries.
                      </li>
                    </StyledUL>
                  </Wrapper>
                </Panel>
              </StyledCollapse>
            </InterventionSection>
          </Wrapper>
        )
      },
      useFlex: true,
      width: '150px',
      height: '3rem',
      boxWidth: '795px',
      customBorder: true,
      // ignoreWidth: true,
      useIcons: true,
      hide: true,
      options: [
        {
          id: 1,
          text: 'Terrible idea!',
          value: 'Terrible idea!',
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
        },
        {
          id: 3,
          text: 'Neither here nor there',
          value: 'Neither here nor there',
        },
        {
          id: 4,
          text: 'Good idea',
          value: 'Good idea',
        },
        {
          id: 5,
          text: 'Great idea!',
          value: 'Great idea!',
        },
      ],
      columns: [
        {
          id: 1,
          value: 'Terrible idea',
          text: 'Terrible idea!',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          id: 3,
          // value: 3,
          value: 'Neither here nor there',
          text: 'Neither here nor there',
          icon: <IconSmileyThree fill="currentColor" />,
        },
        {
          id: 4,
          value: 'Good idea',
          text: 'Good idea',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          id: 5,
          value: 'Great idea',
          text: 'Great idea',
          icon: <IconSmileyFive fill="currentColor" />,
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'Intervention 4',
          value: 'Intervention 4',
        },
      ],
      choices: [
        {
          id: 1,
          // hide: true,
          text: 'Intervention 4',
          value: 'Intervention 4',
        },
      ],
    },
    {
      id: 'Issue 1 - additional input',
      type: multiChoice,
      verticalGrid: true,
      required: true,
      text: () => {
        return (
          <p>
            Is there anything else you think could be done to{' '}
            <b>increase the intake of new advisers</b> to the sector?
          </p>
        )
      },
      choices: [
        { id: 1, type: commentBox, value: '', placeholder: 'Additional thoughts' },
        { id: 2, text: 'No, nothing else', value: 'No' },
      ],
      next: ({ answers }) => {
        const indexOfCurrentItem = answers['interventions-intro'].value
          .map(item => {
            return item.issueId
          })
          .indexOf('Issue #1')

        const nextIndex = indexOfCurrentItem + 1
        if (answers['interventions-intro'].value[nextIndex].issueId === undefined) {
          return 'feedback-comments'
        }
        return 'Issue 1 - additional issues'
      },
    },
    {
      id: 'Issue 1 - additional issues',
      required: true,
      mobileHeight: '5.5rem',
      text: 'Great! Would you like to consider another issue regarding the advisory system?',
      type: multiChoice,
      verticalGrid: true,
      choices: ({ answers }) => {
        if (!_.isUndefined(answers)) {
          const choices = [
            {
              id: 1,
              text: 'Sure, show me the next one!',
              value: answers['interventions-intro'].value[1].issueId,
            },
            { id: 7, text: 'No thanks, skip to the end.', value: 'No thanks' },
          ]

          return choices
        } else {
          const placeholderChoices = issuesArray
          return issuesArray
        }
      },
      next: ({ answers }) => {
        // change this
        // return answers['interventions-intro'].value[0].issueId
        if (answers['Issue 1 - additional issues'].id === 1) {
          const indexOfCurrentItem = answers['interventions-intro'].value
            .map(item => {
              return item.issueId
            })
            .indexOf('Issue #1')
          const nextIndex = indexOfCurrentItem + 1
          const nextIssueId = answers['interventions-intro'].value[nextIndex].issueId
          if (
            nextIssueId in answers &&
            nextIssueId in answers['interventions-intro'].value &&
            'value' in answers[nextIssueId]
          ) {
            return 'feedback-comments'
          }
          if (answers['interventions-intro'].value[nextIndex].issueId === undefined) {
            return 'feedback-comments'
          }
          return answers['interventions-intro'].value[nextIndex].issueId
        }

        return 'feedback-comments'
      },
    },
    {
      id: 'Issue #2',
      type: responsiveMatrix,
      required: true,
      useFlex: true,
      width: '150px',
      height: '3rem',
      boxWidth: '795px',
      customBorder: true,
      // ignoreWidth: true,
      useIcons: true,
      hide: true,
      options: [
        {
          id: 1,
          text: 'Terrible idea!',
          value: 'Terrible idea!',
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
        },
        {
          id: 3,
          text: 'Neither here nor there',
          value: 'Neither here nor there',
        },
        {
          id: 4,
          text: 'Good idea',
          value: 'Good idea',
        },
        {
          id: 5,
          text: 'Great idea!',
          value: 'Great idea!',
        },
      ],
      columns: [
        {
          id: 1,
          value: 'Terrible idea',
          text: 'Terrible idea!',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          id: 3,
          value: 'Neither here nor there',
          text: 'Neither here nor there',
          icon: <IconSmileyThree fill="currentColor" />,
        },
        {
          id: 4,
          value: 'Good idea',
          text: 'Good idea',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          id: 5,
          value: 'Great idea',
          text: 'Great idea',
          icon: <IconSmileyFive fill="currentColor" />,
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'Intervention 1',
          value: 'Intervention 1',
        },
      ],
      choices: [
        {
          id: 1,
          // hide: true,
          text: 'Intervention 1',
          value: 'Intervention 1',
        },
      ],
      section: () => {
        return (
          <Wrapper style={{ marginBottom: '1rem' }}>
            {Issue2}

            <InterventionSection>
              <GreenText style={{ marginBottom: '1rem' }}>
                What do you think of this intervention as a way of addressing the issue?
              </GreenText>
              <p style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <GreenText style={{ marginRight: '0rem' }}>Intervention #1</GreenText>: Forecast the
                requirement for FEPs to the extent possible
              </p>
              <p>
                While eliminating uncertainty about FEPs may not be possible - both in terms of
                timing and specific requirements - better forecasts would likely decrease it. To the
                extent that better forecasts can provide greater certainty about the future supply
                and demand for the advisory capacity and capability, they will encourage advisory
                organisations to plan for this.
              </p>
              <StyledCollapse ghost>
                <Panel header="Click to read more" key="2">
                  <Wrapper>
                    <p style={{ marginBottom: '1rem' }}>
                      Forecasts could provide an estimate on the likelihood of:
                    </p>
                    <StyledUL>
                      <li>The scope and content of FEPs</li>
                      <li>The volume and timing of producer demand</li>
                      <li>
                        The capacity of different service models (private advisory firms, dairy
                        companies, regional councils, fertilizer companies…).
                      </li>
                    </StyledUL>
                    <p style={{ marginBottom: '1rem' }}>
                      The National Certification Scheme may provide one suitable source for this
                      information.
                    </p>
                  </Wrapper>
                </Panel>
              </StyledCollapse>
            </InterventionSection>
          </Wrapper>
        )
      },
      // text: 'Do you think that intervention is needed to address this issue?',
      verticalGrid: true,
    },
    {
      id: 'Issue 2 - intervention 2',
      type: responsiveMatrix,
      required: true,
      // useColumns: true,
      section: () => {
        return (
          <Wrapper>
            {Issue2}
            <InterventionSection>
              <GreenText style={{ marginBottom: '1rem' }}>
                What do you think of this intervention as a way of addressing the issue?
              </GreenText>
              <p style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <GreenText style={{ marginRight: '0rem' }}>Intervention #2</GreenText>: Create
                demand for FEPs to encourage firms to employ and develop new advisers
              </p>
              <p>
                One approach to eliminate ambiguity about the demand for FEPs in the short-term is
                government intervention to underwrite a level of demand sufficient for advisory
                organisations to act to build capacity and capability.
              </p>
              <StyledCollapse ghost>
                <Panel header="Click to read more" key="2">
                  <p style={{ marginBottom: '1rem' }}>
                    Such an intervention could take different forms. One approach would be to offer
                    time-bound financial support to producers to encourage them to act ahead of
                    regulatory requirements.
                  </p>
                  <p>
                    Another approach would be to contract directly with advisory firms. This could
                    also present an opportunity to build broader farm systems or horticultural
                    advisory capability. Advisory firms could be offered contracts to prepare FEPs
                    on the condition that they employ and develop new advisers to undertake this
                    work. Contracts would need to ensure that FEPs:
                  </p>
                  <StyledUL>
                    <li>Meet quality standards</li>
                    <li>Meet regulatory requirements (to the extent that they are known)</li>
                    <li>Include indemnity provisions for new advisers and their firms.</li>
                  </StyledUL>
                </Panel>
              </StyledCollapse>
            </InterventionSection>
          </Wrapper>
        )
      },
      useFlex: true,
      width: '150px',
      height: '3rem',
      boxWidth: '795px',
      customBorder: true,
      // ignoreWidth: true,
      useIcons: true,
      hide: true,
      options: [
        {
          id: 1,
          text: 'Terrible idea!',
          value: 'Terrible idea!',
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
        },
        {
          id: 3,
          text: 'Neither here nor there',
          value: 'Neither here nor there',
        },
        {
          id: 4,
          text: 'Good idea',
          value: 'Good idea',
        },
        {
          id: 5,
          text: 'Great idea!',
          value: 'Great idea!',
        },
      ],
      columns: [
        {
          id: 1,
          value: 'Terrible idea',
          text: 'Terrible idea!',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          id: 3,
          value: 'Neither here nor there',
          text: 'Neither here nor there',
          icon: <IconSmileyThree fill="currentColor" />,
        },
        {
          id: 4,
          value: 'Good idea',
          text: 'Good idea',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          id: 5,
          value: 'Great idea',
          text: 'Great idea',
          icon: <IconSmileyFive fill="currentColor" />,
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'Intervention 2',
          value: 'Intervention 2',
        },
      ],
      choices: [
        {
          id: 1,
          // hide: true,
          text: 'Intervention 2',
          value: 'Intervention 2',
        },
      ],
    },
    {
      id: 'Issue 2 - additional input',
      required: true,
      type: multiChoice,
      verticalGrid: true,
      text: () => {
        return (
          <p>
            Is there anything else you think could be done to{' '}
            <b>increase the capacity of advisers</b> who can prepare farm environment plans?
          </p>
        )
      },
      choices: [
        { id: 1, type: commentBox, value: '', placeholder: 'Additional thoughts' },
        { id: 2, text: 'No, nothing else', value: 'No' },
      ],
      next: ({ answers }) => {
        const indexOfCurrentItem = answers['interventions-intro'].value
          .map(item => {
            return item.issueId
          })
          .indexOf('Issue #2')

        const nextIndex = indexOfCurrentItem + 1
        if (answers['interventions-intro'].value[nextIndex].issueId === undefined) {
          return 'feedback-comments'
        }
        return 'Issue 2 - additional issues'
      },
    },
    {
      id: 'Issue 2 - additional issues',
      type: multiChoice,
      required: true,
      mobileHeight: '5.5rem',
      text: 'Great! Would you like to consider another issue regarding the advisory system?',
      verticalGrid: true,
      choices: ({ answers }) => {
        if (!_.isUndefined(answers)) {
          const choices = [
            {
              id: 1,
              text: 'Sure, show me the next one!',
              value: answers['interventions-intro'].value[1].issueId,
            },
            { id: 7, text: 'No thanks, skip to the end.', value: 'No thanks' },
          ]

          return choices
        } else {
          const placeholderChoices = issuesArray
          return issuesArray
        }
        // const choices = [
        //   {
        //     id: 1,
        //     text: 'Sure, show me the next one!',
        //     value: answers['interventions-intro'].value[1].issueId,
        //   },
        //   { id: 7, text: 'No thanks, skip to the end.', value: 'No thanks' },
        // ]

        // return choices
      },
      next: ({ answers }) => {
        // change this
        // return answers['interventions-intro'].value[0].issueId
        if (answers['Issue 2 - additional issues'].id === 1) {
          const indexOfCurrentItem = answers['interventions-intro'].value
            .map(item => {
              return item.issueId
            })
            .indexOf('Issue #2')
          const nextIndex = indexOfCurrentItem + 1
          const nextIssueId = answers['interventions-intro'].value[nextIndex].issueId
          if (
            nextIssueId in answers &&
            nextIssueId in answers['interventions-intro'].value &&
            'value' in answers[nextIssueId]
          ) {
            return 'feedback-comments'
          }
          if (answers['interventions-intro'].value[nextIndex].issueId === undefined) {
            return 'feedback-comments'
          }
          return answers['interventions-intro'].value[nextIndex].issueId
        }

        return 'feedback-comments'
      },
    },
    {
      id: 'Issue #3',
      type: responsiveMatrix,
      required: true,
      verticalGrid: true,
      useFlex: true,
      width: '150px',
      height: '3rem',
      boxWidth: '795px',
      customBorder: true,
      // ignoreWidth: true,
      useIcons: true,
      hide: true,
      options: [
        {
          id: 1,
          text: 'Terrible idea!',
          value: 'Terrible idea!',
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
        },
        {
          id: 3,
          text: 'Neither here nor there',
          value: 'Neither here nor there',
        },
        {
          id: 4,
          text: 'Good idea',
          value: 'Good idea',
        },
        {
          id: 5,
          text: 'Great idea!',
          value: 'Great idea!',
        },
      ],
      columns: [
        {
          id: 1,
          value: 'Terrible idea',
          text: 'Terrible idea!',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          id: 3,
          value: 'Neither here nor there',
          text: 'Neither here nor there',
          icon: <IconSmileyThree fill="currentColor" />,
        },
        {
          id: 4,
          value: 'Good idea',
          text: 'Good idea',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          id: 5,
          value: 'Great idea',
          text: 'Great idea',
          icon: <IconSmileyFive fill="currentColor" />,
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'Intervention 1',
          value: 'Intervention 1',
        },
      ],
      choices: [
        {
          id: 1,
          // hide: true,
          text: 'Intervention 1',
          value: 'Intervention 1',
        },
      ],
      section: () => {
        return (
          <Wrapper style={{ marginBottom: '1rem' }}>
            {Issue3}
            <InterventionSection>
              <GreenText style={{ marginBottom: '1rem' }}>
                What do you think of this intervention as a way of addressing the issue?
              </GreenText>
              <p style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <GreenText style={{ marginRight: '0rem' }}>Intervention #1</GreenText>: Offer,
                promote and incentivise capability-building in soft skills
              </p>
              <p>
                Incentives could include MPI favouring suitably trained persons for contracts,
                making capability-building a requirement for professional registration, or
                subsidising advisers to participate in training.
              </p>
              <StyledCollapse ghost>
                <Panel header="Click to read more" key="2">
                  <Wrapper>
                    <p style={{ marginBottom: '1rem' }}>
                      One of the areas identified by stakeholders as having high
                      <i style={{ marginRight: '0.25rem', marginLeft: '0.25rem' }}>
                        industry need
                      </i>{' '}
                      but
                      <i style={{ marginRight: '0.25rem', marginLeft: '0.25rem' }}>
                        weak producer and adviser demand
                      </i>{' '}
                      is ‘soft skills. This includes both communication and relationship management
                      skills, which are vital as they are the medium through which advisers support
                      practice change.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      An intervention for soft skills could be for MPI to take a role in
                      commissioning and / or incentivising participation in soft skill
                      capability-building initiatives. Of these two roles, incentivising
                      participation is likely to be more important than commissioning new
                      approaches. This is because if the incentives exist for advisers to
                      participate, training providers are likely to meet the resulting adviser
                      demand.
                    </p>
                    <p>
                      Incentives could include MPI giving preferential treatment for project work to
                      suitably trained persons or making capability-building a requirement for
                      professional registration. Alternatively, subsidies could be made by paying
                      advisers to offset some of the opportunity costs of participation.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      An example of the success of this approach has been within the Red Meat Profit
                      Partnership (RMPP). Advisers wishing to work as RMPP Action Network
                      facilitators were required to undergo facilitator training - a soft skill
                      required for extension. The potential work following from the training
                      functioned as an incentive and stimulated the training programme. Once
                      running, advisers were able to see the value of the training, and many
                      participated without plans of necessarily becoming an RMPP facilitator.
                    </p>
                  </Wrapper>
                </Panel>
              </StyledCollapse>
            </InterventionSection>
          </Wrapper>
        )
      },
    },

    {
      id: 'Issue 3 - intervention 2',
      type: responsiveMatrix,
      required: true,
      // useColumns: true,
      section: () => {
        return (
          <Wrapper>
            {Issue3}

            <InterventionSection style={{ marginTop: '1rem' }}>
              <GreenText style={{ marginBottom: '1rem' }}>
                What do you think of this intervention as a way of addressing the issue?
              </GreenText>
              <p style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <GreenText style={{ marginRight: '0rem' }}>Intervention #2</GreenText>: Offer,
                promote and incentivise capability-specific training in technical skills
              </p>
              <p>
                Incentives could include MPI favouring suitably trained persons for contracts,
                making capability-building a requirement for professional registration, or
                subsidising advisers to participate in training.
              </p>
              <StyledCollapse>
                <Panel header="Click to read more" key="2">
                  <Wrapper>
                    <p style={{ marginBottom: '1rem' }}>
                      Advances in technology mean it is possible to develop agritech products and
                      services that can increase profitability and sustainability of farms through
                      greater efficiency and precision of farm management. The potential impact of
                      agritech is such that the government launched an Agritech Industry
                      Transformation Plan in 2020. Nevertheless, many advisers lack the
                      understanding of complex technology and data, and its role within production
                      systems. To make matters more complex, there is limited producer, and
                      therefore adviser, demand for these technologies and skills, due to questions
                      around the business case for them, for example, return on investment,
                      opportunity costs, capital needed, and how easily farmers can learn to use the
                      technologies.
                    </p>
                    <p>
                      Similar to the intervention on soft skills, MPI or other agencies could take a
                      role in commissioning and incentivising participation in training courses in
                      developing agritech skills. Again, incentivising is likely to be more
                      important than commissioning, because if incentives exist for advisers to
                      participate, training providers are likely to respond to their demand. These
                      incentives could include MPI giving preferential treatment for project work to
                      suitably trained persons or making capability-building a requirement for
                      professional registration. Another option would be for subsidies to be more
                      direct, paying advisers to offset some of the opportunity costs of
                      participation.
                    </p>
                    <p>
                      Alternatives to developing the technical skills of current advisers may be to
                      recruit or create partnerships with technologically trained specialists; to
                      encourage people with technological backgrounds to undertake qualifications in
                      agricultural science and promote their career path into advisory businesses;
                      or fund technology companies to work with advisory organisations to adapt
                      their technologies for issues within the primary sector.
                    </p>
                  </Wrapper>
                </Panel>
              </StyledCollapse>
            </InterventionSection>
          </Wrapper>
        )
      },
      useFlex: true,
      width: '150px',
      height: '3rem',
      boxWidth: '795px',
      customBorder: true,
      // ignoreWidth: true,
      useIcons: true,
      hide: true,
      options: [
        {
          id: 1,
          text: 'Terrible idea!',
          value: 'Terrible idea!',
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
        },
        {
          id: 3,
          text: 'Neither here nor there',
          value: 'Neither here nor there',
        },
        {
          id: 4,
          text: 'Good idea',
          value: 'Good idea',
        },
        {
          id: 5,
          text: 'Great idea!',
          value: 'Great idea!',
        },
      ],
      columns: [
        {
          id: 1,
          value: 'Terrible idea',
          text: 'Terrible idea!',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          id: 3,
          value: 'Neither here nor there',
          text: 'Neither here nor there',
          icon: <IconSmileyThree fill="currentColor" />,
        },
        {
          id: 4,
          value: 'Good idea',
          text: 'Good idea',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          id: 5,
          value: 'Great idea',
          text: 'Great idea',
          icon: <IconSmileyFive fill="currentColor" />,
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'Intervention 2',
          value: 'Intervention 2',
        },
      ],
      choices: [
        {
          id: 1,
          // hide: true,
          text: 'Intervention 2',
          value: 'Intervention 2',
        },
      ],
    },
    {
      id: 'Issue 3 - intervention 3',
      type: responsiveMatrix,
      required: true,
      // useColumns: true,
      section: () => {
        return (
          <Wrapper>
            {Issue3}
            <InterventionSection>
              <GreenText style={{ marginBottom: '1rem' }}>
                What do you think of this intervention as a way of addressing the issue?
              </GreenText>
              <p style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <GreenText style={{ marginRight: '0rem' }}>Intervention #3</GreenText>: Offer,
                promote and incentivise mentorship for early career advisers
              </p>
              <p>
                Mentorship is an alternative form of capability-building that may be attractive
                compared to formal training.
              </p>
              <StyledCollapse ghost>
                <Panel header="Click to read more" key="2">
                  <Wrapper>
                    <p style={{ marginBottom: '1rem' }}>
                      A form of capability-building that may have inherent incentives and therefore
                      require less direct intervention is mentoring. Mentoring is a flexible,
                      low-cost form of capability-building that enables sharing of knowledge and
                      expertise between a mentor, who has relevant expertise and experience, with a
                      mentee who is in the early stages of their career.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      MPI could take a role in commissioning and incentivising mentoring
                      relationships between senior advisers and early career advisers. Not only does
                      this have potential to ‘fast track’ the confidence, knowledge and skills of
                      early career advisers, enabling them to support producer productivity and
                      sustainability, but it may also mitigate knowledge loss of senior advisers as
                      they near retirement. For advisers in small or sole trader advisory
                      businesses, mentoring may enable them to train a successor for their clients.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      Mentoring between farmers and early career advisers in both the public and
                      private sectors, particularly those from non-farming backgrounds, could also
                      facilitate advisers’ development of farm systems knowledge and professional
                      networks. It could also provide an opportunity for early career advisers to
                      develop their soft skills. This scheme could work either on a voluntary basis
                      or be tied to an incentive e.g., subsidy / payment to mentor and mentee.
                    </p>
                  </Wrapper>
                </Panel>
              </StyledCollapse>
            </InterventionSection>
          </Wrapper>
        )
      },
      useFlex: true,
      width: '150px',
      height: '3rem',
      boxWidth: '795px',
      customBorder: true,
      // ignoreWidth: true,
      useIcons: true,
      hide: true,
      options: [
        {
          id: 1,
          text: 'Terrible idea!',
          value: 'Terrible idea!',
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
        },
        {
          id: 3,
          text: 'Neither here nor there',
          value: 'Neither here nor there',
        },
        {
          id: 4,
          text: 'Good idea',
          value: 'Good idea',
        },
        {
          id: 5,
          text: 'Great idea!',
          value: 'Great idea!',
        },
      ],
      columns: [
        {
          id: 1,
          value: 'Terrible idea',
          text: 'Terrible idea!',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          id: 3,
          value: 'Neither here nor there',
          text: 'Neither here nor there',
          icon: <IconSmileyThree fill="currentColor" />,
        },
        {
          id: 4,
          value: 'Good idea',
          text: 'Good idea',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          id: 5,
          value: 'Great idea',
          text: 'Great idea',
          icon: <IconSmileyFive fill="currentColor" />,
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'Intervention 3',
          value: 'Intervention 3',
        },
      ],
      choices: [
        {
          id: 1,
          // hide: true,
          text: 'Intervention 3',
          value: 'Intervention 3',
        },
      ],
    },
    {
      id: 'Issue 3 - intervention 4',
      type: responsiveMatrix,
      required: true,
      section: () => {
        return (
          <Wrapper>
            {Issue3}

            <InterventionSection>
              <GreenText style={{ marginBottom: '1rem' }}>
                What do you think of this intervention as a way of addressing the issue?
              </GreenText>
              <p style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <GreenText style={{ marginRight: '0rem' }}>Intervention #4</GreenText>: Strengthen
                the current registration programme, and support ongoing professional development,
                for generalist advisers
              </p>
              <p>
                Incentives could include MPI or other organisations making registered advisers
                preferred contractors or using them as specialist advisers.
              </p>
              <StyledCollapse ghost>
                <Panel header="Click to read more" key="1">
                  <Wrapper>
                    <p>
                      Strengthening the current registration programme for advisers could raise both
                      producer confidence in advisers and advisers’ confidence in each another.
                      Here, ‘strengthening’ could mean raising the capability standard required for
                      registration, increasing the proportion of advisers that are registered, or
                      both.
                    </p>
                    <p>
                      However, as with other approaches to capability-building, the collective case
                      for a stronger registration programme is easier to identify than the value
                      proposition for an individual adviser. At the moment, few producers would make
                      different buying decisions based on whether or not an adviser is registered.
                    </p>
                    <p>
                      MPI could incentivise uptake by widely promoting the registration programme
                      and making registered advisers their preferred contractors. MPI could also
                      encourage other organisations that commission project work – such as DairyNZ,
                      Beef + Lamb New Zealand and regional councils – to favour registered advisers.
                    </p>
                    <p>
                      Another variation would be for registered advisers to act as specialists,
                      directly advising government and industry bodies on matters in a similar way
                      in which registered professionals are asked to provide expert witness
                      testimony in court cases.
                    </p>
                    <p>
                      Although some advisers uninterested in such work may not feel the need for
                      registration, creating an expectation of registration for firms that contract
                      to or advise government and industry bodies could provide an effective
                      incentive.
                    </p>
                  </Wrapper>
                </Panel>
              </StyledCollapse>
            </InterventionSection>
          </Wrapper>
        )
      },
      useFlex: true,
      width: '150px',
      height: '3rem',
      boxWidth: '795px',
      customBorder: true,
      // ignoreWidth: true,
      useIcons: true,
      hide: true,
      options: [
        {
          id: 1,
          text: 'Terrible idea!',
          value: 'Terrible idea!',
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
        },
        {
          id: 3,
          text: 'Neither here nor there',
          value: 'Neither here nor there',
        },
        {
          id: 4,
          text: 'Good idea',
          value: 'Good idea',
        },
        {
          id: 5,
          text: 'Great idea!',
          value: 'Great idea!',
        },
      ],
      columns: [
        {
          id: 1,
          value: 'Terrible idea',
          text: 'Terrible idea!',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          id: 3,
          value: 'Neither here nor there',
          text: 'Neither here nor there',
          icon: <IconSmileyThree fill="currentColor" />,
        },
        {
          id: 4,
          value: 'Good idea',
          text: 'Good idea',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          id: 5,
          value: 'Great idea',
          text: 'Great idea',
          icon: <IconSmileyFive fill="currentColor" />,
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'Intervention 4',
          value: 'Intervention 4',
        },
      ],
      choices: [
        {
          id: 1,
          // hide: true,
          text: 'Intervention 4',
          value: 'Intervention 4',
        },
      ],
    },
    {
      id: 'Issue 3 - additional input',
      verticalGrid: true,
      required: true,
      type: multiChoice,
      text: () => {
        return (
          <p>
            Is there anything else you think could be done to <b>incentivise advisers</b> to build
            on their existing capabilities?
          </p>
        )
      },
      choices: [
        { id: 1, type: commentBox, value: '', placeholder: 'Additional thoughts' },
        { id: 2, text: 'No, nothing else', value: 'No' },
      ],
      next: ({ answers }) => {
        const indexOfCurrentItem = answers['interventions-intro'].value
          .map(item => {
            return item.issueId
          })
          .indexOf('Issue #3')

        const nextIndex = indexOfCurrentItem + 1
        if (answers['interventions-intro'].value[nextIndex].issueId === undefined) {
          return 'feedback-comments'
        }
        return 'Issue 3 - additional issues'
      },
    },
    {
      id: 'Issue 3 - additional issues',
      type: multiChoice,
      verticalGrid: true,
      mobileHeight: '5.5rem',
      text: 'Great! Would you like to consider another issue regarding the advisory system?',
      choices: ({ answers }) => {
        if (!_.isUndefined(answers)) {
          const choices = [
            {
              id: 1,
              text: 'Sure, show me the next one!',
              value: answers['interventions-intro'].value[1].issueId,
            },
            { id: 7, text: 'No thanks, skip to the end.', value: 'No thanks' },
          ]

          return choices
        } else {
          const placeholderChoices = issuesArray
          return issuesArray
        }
        // const choices = [
        //   {
        //     id: 1,
        //     text: 'Sure, show me the next one!',
        //     value: answers['interventions-intro'].value[1].issueId,
        //   },
        //   { id: 7, text: 'No thanks, skip to the end.', value: 'No thanks' },
        // ]

        // return choices
      },
      next: ({ answers }) => {
        if (answers['Issue 3 - additional issues'].id === 1) {
          const indexOfCurrentItem = answers['interventions-intro'].value
            .map(item => {
              return item.issueId
            })
            .indexOf('Issue #3')
          const nextIndex = indexOfCurrentItem + 1
          // if (answers[nextIndex].value) {
          //   return 'feedback-comments'
          // }
          const nextIssueId = answers['interventions-intro'].value[nextIndex].issueId
          if (
            nextIssueId in answers &&
            nextIssueId in answers['interventions-intro'].value &&
            'value' in answers[nextIssueId]
          ) {
            return 'feedback-comments'
          }
          if (answers['interventions-intro'].value[nextIndex].issueId === undefined) {
            return 'feedback-comments'
          }
          return answers['interventions-intro'].value[nextIndex].issueId
        }

        return 'feedback-comments'
      },
    },
    {
      id: 'Issue #4',
      type: responsiveMatrix,
      required: true,
      verticalGrid: true,
      useFlex: true,
      width: '150px',
      height: '3rem',
      boxWidth: '795px',
      customBorder: true,
      useIcons: true,
      hide: true,
      options: [
        {
          id: 1,
          text: 'Terrible idea!',
          value: 'Terrible idea!',
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
        },
        {
          id: 3,
          text: 'Neither here nor there',
          value: 'Neither here nor there',
        },
        {
          id: 4,
          text: 'Good idea',
          value: 'Good idea',
        },
        {
          id: 5,
          text: 'Great idea!',
          value: 'Great idea!',
        },
      ],
      columns: [
        {
          id: 1,
          value: 'Terrible idea',
          text: 'Terrible idea!',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          id: 3,
          value: 'Neither here nor there',
          text: 'Neither here nor there',
          icon: <IconSmileyThree fill="currentColor" />,
        },
        {
          id: 4,
          value: 'Good idea',
          text: 'Good idea',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          id: 5,
          value: 'Great idea',
          text: 'Great idea',
          icon: <IconSmileyFive fill="currentColor" />,
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'Intervention 1',
          value: 'Intervention 1',
        },
      ],
      choices: [
        {
          id: 1,
          // hide: true,
          text: 'Intervention 1',
          value: 'Intervention 1',
        },
      ],
      section: () => {
        return (
          <Wrapper style={{ marginBottom: '1rem' }}>
            {Issue4}
            <InterventionSection>
              <GreenText style={{ marginBottom: '1rem' }}>
                What do you think of this intervention as a way of addressing the issue?
              </GreenText>
              <p style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <GreenText style={{ marginRight: '0rem' }}>Intervention #1</GreenText>: Invest in
                advisory knowledge sharing forums within and across industries
              </p>
              <p>
                Forums are one method of encouraging knowledge sharing and networking. They may be
                particularly beneficial for topic areas where one stakeholder cannot develop all the
                knowledge and skills required, such as land use change.
              </p>
              <StyledCollapse ghost>
                <Panel header="Click to read more" key="2">
                  <Wrapper>
                    <p style={{ marginBottom: '1rem' }}>
                      In forums, advisers from different organisations are invited to discussions
                      about specific technical questions. These forums provide opportunities for
                      building social capital, and result in farmers being provided with more
                      consistent advice.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      There are examples of existing successful forums, such as Zespri’s ‘Tech
                      Forum’ for advisers across the kiwifruit industry. This forum enables advisers
                      and consultants to share knowledge and experience, identify research needs,
                      and develop stronger collegial interactions.
                    </p>
                    <p>
                      MPI could take a role in commissioning and / or incentivising further
                      collaborative knowledge sharing forums in which government, private and
                      commercial sector advisory stakeholders work together to share knowledge and
                      expertise, and network.
                    </p>
                    <p>
                      In particular, it is those topic areas where one stakeholder cannot develop
                      all the knowledge and skills required that would benefit from forums, as these
                      events would facilitate networking and the possibility of future partnerships.
                      One such example is land use change where there may be a range of potential
                      scenarios for land use change (the conversion of one type of land use to
                      another). The skills therefore required by advisers to support producers
                      through land use change are multifaceted, and unlikely to be possessed by a
                      single adviser. While large consultancies can bring together multiple advisers
                      with different skillsets, this is a challenge for smaller firms. Forums on
                      land use change could help fill the current capability gap for many advisers
                      and facilitate access to researchers. This however is likely to highlight the
                      lack of extant research available as observed by interviewees in this study
                      and AgFirst’s 2017 report ‘Analysis of drivers and barriers to land use
                      change’, needed to guide land use change decisions.
                    </p>
                  </Wrapper>
                </Panel>
              </StyledCollapse>
            </InterventionSection>
          </Wrapper>
        )
      },
    },
    {
      id: 'Issue 4 - intervention 2',
      type: responsiveMatrix,
      required: true,
      // useColumns: true,
      section: () => {
        return (
          <Wrapper>
            {Issue4}
            <InterventionSection>
              <GreenText style={{ marginBottom: '1rem' }}>
                What do you think of this intervention as a way of addressing the issue?
              </GreenText>
              <p style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <GreenText style={{ marginRight: '0rem' }}>Intervention #2</GreenText>: Fund
                collaborative projects between advisers and researchers
              </p>
              <p>
                Collaborative programmes that tag funding to the distribution of findings will
                ensure research is relevant and effective.
              </p>
              <StyledCollapse ghost>
                <Panel header="Click to read more" key="2">
                  <Wrapper>
                    <p style={{ marginBottom: '1rem' }}>
                      Prior to privatisation of advisory services in the 1980s, advisers and
                      researchers often worked closely together to identify research needs, carry
                      out practice-based research and co-deliver findings to producers. This
                      resulted in strong professional connections that continued to be accessed
                      formally and informally over the next three decades - to the benefit of
                      producers, advisers, and researchers. However, these connections are becoming
                      weaker as senior advisers and researchers reach retirement.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      Some interviewees identified that they, and producers, have limited engagement
                      with researchers or access to research findings. Incentives created by
                      competitive funding for research and extension projects are noted as playing
                      some part in this.
                    </p>
                    <p>
                      Nevertheless, examples of recent successful collaboration between advisers and
                      researchers do exist, for example:
                    </p>
                    <StyledUL>
                      <li>
                        Toi Ohomai and Scion partnership – Tertiary education provider Toi Ohomai
                        provides vocational training for future foresters while Crown Research
                        Institute Scion provides summer internships for forestry students.
                      </li>
                      <li>
                        Our Land and Water – This is a transdisciplinary programme that involves
                        universities, Crown Research Institutes, businesses and non-government
                        organisations. One element of this programme is the Rural Professionals
                        Fund, which enables individuals and businesses to partner with scientists to
                        develop and test innovative ideas that could revolutionize farming systems.
                        The programme also has a communications manager, dedicated to distributing
                        the findings of the programme.{' '}
                      </li>
                    </StyledUL>
                    <p>
                      To better address current and future challenges within the primary sector,
                      similar projects should be funded that encourage researchers (productivity,
                      environmental, economic, and social researchers) and advisers to collaborate
                      and incorporate funding for the distribution of findings.
                    </p>
                  </Wrapper>
                </Panel>
              </StyledCollapse>
            </InterventionSection>
          </Wrapper>
        )
      },
      useFlex: true,
      width: '150px',
      height: '3rem',
      boxWidth: '795px',
      customBorder: true,
      // ignoreWidth: true,
      useIcons: true,
      hide: true,
      options: [
        {
          id: 1,
          text: 'Terrible idea!',
          value: 'Terrible idea!',
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
        },
        {
          id: 3,
          text: 'Neither here nor there',
          value: 'Neither here nor there',
        },
        {
          id: 4,
          text: 'Good idea',
          value: 'Good idea',
        },
        {
          id: 5,
          text: 'Great idea!',
          value: 'Great idea!',
        },
      ],
      columns: [
        {
          id: 1,
          value: 'Terrible idea',
          text: 'Terrible idea!',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          id: 3,
          value: 'Neither here nor there',
          text: 'Neither here nor there',
          icon: <IconSmileyThree fill="currentColor" />,
        },
        {
          id: 4,
          value: 'Good idea',
          text: 'Good idea',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          id: 5,
          value: 'Great idea',
          text: 'Great idea',
          icon: <IconSmileyFive fill="currentColor" />,
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'Intervention 2',
          value: 'Intervention 2',
        },
      ],
      choices: [
        {
          id: 1,
          // hide: true,
          text: 'Intervention 2',
          value: 'Intervention 2',
        },
      ],
    },
    {
      id: 'Issue 4 - intervention 3',
      type: responsiveMatrix,
      required: true,
      // useColumns: true,
      section: () => {
        return (
          <Wrapper>
            {Issue4}
            <InterventionSection>
              <GreenText style={{ marginBottom: '1rem' }}>
                What do you think of this intervention as a way of addressing the issue?
              </GreenText>
              <p style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <GreenText style={{ marginRight: '0rem' }}>Intervention #3</GreenText>: Establish a
                ‘Good practice extension hub’ linked to the capability development system
              </p>
              <p>
                A hub with 1-2 brokers would provide leadership that supports good practice
                extension, and connection across sectors and extension initiatives, thereby enabling
                learning and creating consistencies of skills and extension approaches.
              </p>
              <StyledCollapse ghost>
                <Panel header="Click to read more" key="2">
                  <Wrapper>
                    <p style={{ marginBottom: '1rem' }}>
                      A lack of collaboration between those involved in extension means there are
                      inconsistencies in terms of skills and extension approaches across extension
                      design, delivery and evaluation. As a result, approaches are short-term
                      focused rather than focused on supporting and achieving the longer-term
                      change. A continuous review and reflection cycle is needed, to support the
                      development of suitable and consistent advisory skills and approaches.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      One intervention proposed by facilitators of RMPP Action Networks is to
                      develop a good practice extension hub to provide:
                    </p>

                    <StyledUL>
                      <li>
                        Leadership that promotes and supports good practice extension, including
                        evaluation design and monitoring
                      </li>
                      <li>
                        Facilitate connections between advisers within and across sectors and
                        extension initiatives.
                      </li>
                    </StyledUL>
                    <p>
                      The hub would focus on extension delivery approaches and reflective practice,
                      rather than the specific change required (i.e., the "how", not the "what")
                      thereby increasing extension and evaluation amongst advisers. The result of
                      this would be improved outcomes for farmers, in terms of productivity,
                      profitability and the environment.
                    </p>
                    <p>
                      In practical terms, a hub may involve 1 - 2 people acting as brokers to
                      facilitate advisory interactions, provide access to resources and build
                      systemic advisory capability.
                    </p>
                    <p>
                      An example of an extension hub includes The Department of Agriculture and
                      Fisheries (DAF) in Queensland current work to enhance extension coordination
                      in the Great Barrier Reef (GBR). The focus of this project is to extend the
                      reach and capacity of current GBR extension programs and support effective
                      peer-to-peer learning amongst producers and advisors. DAF are also developing
                      extension resources (e.g., a peer-to-peer learning start-up kit) as well as
                      providing opportunity for capability development in good practice extension,
                      particularly in facilitation.
                    </p>
                  </Wrapper>
                </Panel>
              </StyledCollapse>
            </InterventionSection>
          </Wrapper>
        )
      },
      useFlex: true,
      width: '150px',
      height: '3rem',
      boxWidth: '795px',
      customBorder: true,
      // ignoreWidth: true,
      useIcons: true,
      hide: true,
      options: [
        {
          id: 1,
          text: 'Terrible idea!',
          value: 'Terrible idea!',
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
        },
        {
          id: 3,
          text: 'Neither here nor there',
          value: 'Neither here nor there',
        },
        {
          id: 4,
          text: 'Good idea',
          value: 'Good idea',
        },
        {
          id: 5,
          text: 'Great idea!',
          value: 'Great idea!',
        },
      ],
      columns: [
        {
          id: 1,
          value: 'Terrible idea',
          text: 'Terrible idea!',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          id: 3,
          value: 'Neither here nor there',
          text: 'Neither here nor there',
          icon: <IconSmileyThree fill="currentColor" />,
        },
        {
          id: 4,
          value: 'Good idea',
          text: 'Good idea',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          id: 5,
          value: 'Great idea',
          text: 'Great idea',
          icon: <IconSmileyFive fill="currentColor" />,
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'Intervention 3',
          value: 'Intervention 3',
        },
      ],
      choices: [
        {
          id: 1,
          // hide: true,
          text: 'Intervention 3',
          value: 'Intervention 3',
        },
      ],
    },

    {
      id: 'Issue 4 - additional input',
      type: multiChoice,
      verticalGrid: true,
      required: true,
      text: () => {
        return (
          <p>
            Is there anything else you think could be done to <b>increase collaboration</b> within
            the PIAS system?
          </p>
        )
      },
      choices: [
        { id: 1, type: commentBox, value: '', placeholder: 'Additional thoughts' },
        { id: 2, text: 'No, nothing else', value: 'No' },
      ],
      next: ({ answers }) => {
        const indexOfCurrentItem = answers['interventions-intro'].value
          .map(item => {
            return item.issueId
          })
          .indexOf('Issue #4')

        const nextIndex = indexOfCurrentItem + 1
        if (answers['interventions-intro'].value[nextIndex].issueId === undefined) {
          return 'feedback-comments'
        }
        return 'Issue 4 - additional issues'
      },
    },
    {
      id: 'Issue 4 - additional issues',
      type: multiChoice,
      verticalGrid: true,
      required: true,
      text: 'Great! Would you like to consider another issue regarding the advisory system?',
      mobileHeight: '5.5rem',
      choices: ({ answers }) => {
        if (!_.isUndefined(answers)) {
          const choices = [
            {
              id: 1,
              text: 'Sure, show me the next one!',
              value: answers['interventions-intro'].value[1].issueId,
            },
            { id: 7, text: 'No thanks, skip to the end.', value: 'No thanks' },
          ]

          return choices
        } else {
          const placeholderChoices = issuesArray
          return issuesArray
        }
        // const choices = [
        //   {
        //     id: 1,
        //     text: 'Sure, show me the next one!',
        //     value: answers['interventions-intro'].value[1].issueId,
        //   },
        //   { id: 7, text: 'No thanks, skip to the end.', value: 'No thanks' },
        // ]

        // return choices
      },
      next: ({ answers }) => {
        if (answers['Issue 4 - additional issues'].id === 1) {
          const indexOfCurrentItem = answers['interventions-intro'].value
            .map(item => {
              return item.issueId
            })
            .indexOf('Issue #4')
          const nextIndex = indexOfCurrentItem + 1
          const nextIssueId = answers['interventions-intro'].value[nextIndex].issueId
          if (
            nextIssueId in answers &&
            nextIssueId in answers['interventions-intro'].value &&
            'value' in answers[nextIssueId]
          ) {
            return 'feedback-comments'
          }
          if (answers['interventions-intro'].value[nextIndex].issueId === undefined) {
            return 'feedback-comments'
          }
          return answers['interventions-intro'].value[nextIndex].issueId
        }

        return 'feedback-comments'
      },
    },
    {
      id: 'Issue #5',
      type: responsiveMatrix,
      verticalGrid: true,
      required: true,
      useFlex: true,
      width: '150px',
      height: '3rem',
      boxWidth: '795px',
      customBorder: true,
      // ignoreWidth: true,
      useIcons: true,
      hide: true,
      options: [
        {
          id: 1,
          text: 'Terrible idea!',
          value: 'Terrible idea!',
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
        },
        {
          id: 3,
          text: 'Neither here nor there',
          value: 'Neither here nor there',
        },
        {
          id: 4,
          text: 'Good idea',
          value: 'Good idea',
        },
        {
          id: 5,
          text: 'Great idea!',
          value: 'Great idea!',
        },
      ],
      columns: [
        {
          id: 1,
          value: 'Terrible idea',
          text: 'Terrible idea!',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          id: 3,
          value: 'Neither here nor there',
          text: 'Neither here nor there',
          icon: <IconSmileyThree fill="currentColor" />,
        },
        {
          id: 4,
          value: 'Good idea',
          text: 'Good idea',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          id: 5,
          value: 'Great idea',
          text: 'Great idea',
          icon: <IconSmileyFive fill="currentColor" />,
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'Intervention 1',
          value: 'Intervention 1',
        },
      ],
      choices: [
        {
          id: 1,
          // hide: true,
          text: 'Intervention 1',
          value: 'Intervention 1',
        },
      ],
      section: () => {
        return (
          <Wrapper style={{ marginBottom: '1rem' }}>
            {Issue5}
            <InterventionSection>
              <GreenText style={{ marginBottom: '1rem' }}>
                What do you think of this intervention as a way of addressing the issue?
              </GreenText>
              <p style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <GreenText style={{ marginRight: '0rem' }}>Intervention #1</GreenText>: Create
                demand for adviser training in Te Ao Māori and Māori agribusinesses
              </p>
              <p>
                Incentives could include MPI making suitably trained advisers preferred contractors,
                or making the training compulsory for new entrants’ schemes.
              </p>
              <StyledCollapse ghost>
                <Panel header="Click to read more" key="2">
                  <Wrapper>
                    <p style={{ marginBottom: '1rem' }}>
                      Participation in appropriate training programmes is needed to build advisers’
                      capability in Te Ao Māori and understanding of Māori agribusiness enterprises.
                      Such programmes could include understanding of how Māori producers can best
                      use advisory services, what their unique needs are in terms of advisory
                      services, how these differ from non-Māori producers and the implications of
                      these differences for advice provision. Development of appropriate training
                      and creating demand for such training needs to be explored.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      Advisers who already work with Māori agribusiness may have sufficient cultural
                      knowledge, while those who do not, might not see value in a training
                      programmes not relevant to their current client base. It must also be
                      acknowledged that while the supply of advisers may grow through opportunities
                      for training, Māori agribusiness clients will choose advisers based on their
                      own cultural and technical needs.
                    </p>
                    <p>
                      Further exploration is needed to design appropriate incentives. Nevertheless,
                      two ideas include that:{' '}
                    </p>
                    <StyledUL>
                      <li>
                        MPI identifies advisers who participate in cultural training as preferred
                        advisers for government-sponsored projects working with Māori agribusiness.
                      </li>
                      <li>
                        Cultural training is made compulsory for any new entrants’ schemes thus
                        concentrating on younger generations rather than existing advisers.
                      </li>
                    </StyledUL>
                  </Wrapper>
                </Panel>
              </StyledCollapse>
            </InterventionSection>
          </Wrapper>
        )
      },
    },
    // {
    //   id: 'Issue 5 - intervention 1',
    //   type: responsiveMatrix,
    //   required: true,
    //   // useColumns: true,
    //   section: () => {
    //     return (
    //       <Wrapper>
    //         <p style={{ marginBottom: '1rem' }}>
    //           Intervention 1: Support capability building within Māori agribusinesses
    //         </p>
    //         <p style={{ marginBottom: '1rem' }}>
    //           The most powerful influencers on advisers serving Māori agribusinesses are likely to
    //           be those businesses themselves. This suggests that the most potent interventions to
    //           appropriately influence advisory capability and capacity, and influence the types of
    //           services that advisers offer, are likely to be programmes that build the capability of
    //           farm managers and governors for Māori agribusiness. For example, trustees with
    //           stronger capability are less likely to fall dependent on an adviser acting as a
    //           ‘locked-in’ supervisor, enabling advisers to focus on strategic advice rather than
    //           operational management.
    //         </p>
    //         <p style={{ marginBottom: '1rem' }}>
    //           We acknowledge that MPI and other organisations are already working with Māori
    //           agribusiness to build capability in this way but including this intervention here
    //           notes that this will have an indirect benefit by building the capability of advisers
    //           as well as the direct benefit of building the capability of producers. That said, the
    //           timeframe to build advisory capability using this indirect via pathway will be linked
    //           to the (probably long) time needed to build the capability of managers and governors
    //           which suggest more direct interventions are also desirable.
    //         </p>
    //       </Wrapper>
    //     )
    //   },
    //   useFlex: true,
    //   width: '150px',
    //   height: '3rem',
    //   boxWidth: '795px',
    //   customBorder: true,
    //   // ignoreWidth: true,
    //   useIcons: true,
    //   hide: true,
    //   options: [
    //     {
    //       id: 1,
    //       text: 'Terrible idea!',
    //       value: 'Terrible idea!',
    //     },
    //     {
    //       id: 2,
    //       text: 'Bad idea',
    //       value: 'Bad idea',
    //     },
    //     {
    //       id: 3,
    //       text: 'Neither here nor there',
    //       value: 'Neither here nor there',
    //     },
    //     {
    //       id: 4,
    //       text: 'Good idea',
    //       value: 'Good idea',
    //     },
    //     {
    //       id: 5,
    //       text: 'Great idea!',
    //       value: 'Great idea!',
    //     },
    //   ],
    //   columns: [
    //     {
    //       id: 1,
    //       value: 1,
    //       text: 'Terrible idea!',
    //       icon: <IconAngrySmiley fill="currentColor" />,
    //     },
    //     {
    //       id: 2,
    //       text: 'Bad idea',
    //       value: 2,
    //       icon: <IconSmileyTwo fill="currentColor" />,
    //     },
    //     {
    //       id: 3,
    //       value: 3,
    //       text: 'Neither here nor there',
    //       icon: <IconSmileyThree fill="currentColor" />,
    //     },
    //     {
    //       id: 4,
    //       value: 4,
    //       text: 'Good idea',
    //       icon: <IconSmileyFour fill="currentColor" />,
    //     },
    //     {
    //       id: 5,
    //       value: 5,
    //       text: 'Great idea',
    //       icon: <IconSmileyFive fill="currentColor" />,
    //     },
    //   ],
    //   rows: [
    //     {
    //       id: 1,
    //       hide: true,
    //       text: 'Intervention 1',
    //       value: 'Intervention 1',
    //     },
    //   ],
    //   choices: [
    //     {
    //       id: 1,
    //       // hide: true,
    //       text: 'Intervention 1',
    //       value: 'Intervention 1',
    //     },
    //   ],
    // },
    {
      id: 'Issue 5 - intervention 2',
      type: responsiveMatrix,
      required: true,
      // useColumns: true,
      section: () => {
        return (
          <Wrapper>
            {Issue5}
            <InterventionSection>
              <GreenText style={{ marginBottom: '1rem' }}>
                What do you think of this intervention as a way of addressing the issue?
              </GreenText>
              <p style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <GreenText style={{ marginRight: '0rem' }}>Intervention #2</GreenText>: Collaborate
                on a partnership framework for advisers and Māori agribusiness
              </p>
              <p>
                A framework could be created with involvement from Māori agribusinesses, MPI,
                industry bodies and the New Zealand Institute of Primary Industry Management. This
                framework would aim to change perspectives on relationships between governors and
                advisers, and support a transition of responsibilities from advisers to governors.
              </p>
              <StyledCollapse ghost>
                <Panel header="Click to read more" key="2">
                  <Wrapper>
                    <p style={{ marginBottom: '1rem' }}>
                      Developing a partnership framework is needed that aligns aspirations across
                      Māori agribusinesses, advisers, government agencies, industry organisations
                      and other participants with respect to how advisers can best support Māori
                      agribusinesses.
                    </p>

                    <p style={{ marginBottom: '1rem' }}>
                      The intention of this partnership framework would be to enable existing
                      trustees/governors and advisers to build/rebuild their relationships based on
                      mutually agreed terms of reference. It may include or extend the role of
                      advisers to support capability building of trustees rather than taking a
                      supervisory role.
                    </p>
                    <p>
                      The partnership framework could include a need for an agreement between
                      advisers and their clients that establishes specific capability-building
                      requirements and a timeline for developing clients’ skills to run their
                      business without an adviser if they choose. Development of a partnership
                      framework would need to involve all key stakeholders including Māori
                      agribusinesses, MPI, industry bodies, advisers and the New Zealand Institute
                      of Primary Industry Management.
                    </p>
                    <p>
                      We note that this recommendation has some similarities to the code of ethics
                      recommended by Manaaki Whenua. However, the framing of this as a partnership
                      recognises that all parties have a role in creating a better context for
                      advisory services to be successful.
                    </p>
                  </Wrapper>
                </Panel>
              </StyledCollapse>
            </InterventionSection>
          </Wrapper>
        )
      },
      useFlex: true,
      width: '150px',
      height: '3rem',
      boxWidth: '795px',
      customBorder: true,
      // ignoreWidth: true,
      useIcons: true,
      hide: true,
      options: [
        {
          id: 1,
          text: 'Terrible idea!',
          value: 'Terrible idea!',
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
        },
        {
          id: 3,
          text: 'Neither here nor there',
          value: 'Neither here nor there',
        },
        {
          id: 4,
          text: 'Good idea',
          value: 'Good idea',
        },
        {
          id: 5,
          text: 'Great idea!',
          value: 'Great idea!',
        },
      ],
      columns: [
        {
          id: 1,
          value: 'Terrible idea',
          text: 'Terrible idea!',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          id: 3,
          value: 'Neither here nor there',
          text: 'Neither here nor there',
          icon: <IconSmileyThree fill="currentColor" />,
        },
        {
          id: 4,
          value: 'Good idea',
          text: 'Good idea',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          id: 5,
          value: 'Great idea',
          text: 'Great idea',
          icon: <IconSmileyFive fill="currentColor" />,
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'Intervention 2',
          value: 'Intervention 2',
        },
      ],
      choices: [
        {
          id: 1,
          // hide: true,
          text: 'Intervention 2',
          value: 'Intervention 2',
        },
      ],
    },
    {
      id: 'Issue 5 - intervention 3',
      type: responsiveMatrix,
      required: true,
      // useColumns: true,
      section: () => {
        return (
          <Wrapper style={{ marginBottom: '1rem' }}>
            {Issue5}
            <InterventionSection>
              <GreenText style={{ marginBottom: '1rem' }}>
                What do you think of this intervention as a way of addressing the issue?
              </GreenText>
              <p style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <GreenText style={{ marginRight: '0rem' }}>Intervention #3</GreenText>: Continue to
                support capability building within Māori agribusinesses
              </p>
              <p>
                MPI and other organisations should continue to provide capability building support.
              </p>
              <StyledCollapse ghost>
                <Panel header="Click to read more" key="1">
                  <Wrapper>
                    <p style={{ marginBottom: '1rem' }}>
                      One of the most powerful influencers on advisers serving Māori agribusinesses
                      are likely to be those businesses themselves. This suggests that one of the
                      most potent interventions to appropriately influence advisory capability and
                      capacity, and influence the types of services that advisers offer, are likely
                      to be programmes that build the capability of farm managers and governors for
                      Māori agribusiness was highlighted by several interviewees. There is a need to
                      build strategic governance and business skills of trustees as well as the
                      operational capability and knowledge of farm staff.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      We acknowledge that MPI and other organisations are already working with Māori
                      agribusiness to build capability in this way. Nevertheless, including this
                      intervention here notes that this will have an impact on advisers as well
                      producers, with both needing to reframe their business relationship as enabled
                      partnership. That said, the timeframe to build governance and production
                      capability to enable some developing Māori agribusiness to run independently
                      of advisers may require long term advisory commitment and more direct
                      interventions will also be needed.
                    </p>
                  </Wrapper>
                </Panel>
              </StyledCollapse>
            </InterventionSection>
          </Wrapper>
        )
      },
      useFlex: true,
      width: '150px',
      height: '3rem',
      boxWidth: '795px',
      customBorder: true,
      // ignoreWidth: true,
      useIcons: true,
      hide: true,
      options: [
        {
          id: 1,
          text: 'Terrible idea!',
          value: 'Terrible idea!',
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
        },
        {
          id: 3,
          text: 'Neither here nor there',
          value: 'Neither here nor there',
        },
        {
          id: 4,
          text: 'Good idea',
          value: 'Good idea',
        },
        {
          id: 5,
          text: 'Great idea!',
          value: 'Great idea!',
        },
      ],
      columns: [
        {
          id: 1,
          value: 'Terrible idea',
          text: 'Terrible idea!',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          id: 3,
          value: 'Neither here nor there',
          text: 'Neither here nor there',
          icon: <IconSmileyThree fill="currentColor" />,
        },
        {
          id: 4,
          value: 'Good idea',
          text: 'Good idea',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          id: 5,
          value: 'Great idea',
          text: 'Great idea',
          icon: <IconSmileyFive fill="currentColor" />,
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'Intervention 3',
          value: 'Intervention 3',
        },
      ],
      choices: [
        {
          id: 1,
          // hide: true,
          text: 'Intervention 3',
          value: 'Intervention 3',
        },
      ],
    },
    {
      id: 'Issue 5 - intervention 4',
      type: responsiveMatrix,
      required: true,
      // useColumns: true,
      section: () => {
        return (
          <Wrapper>
            {Issue5}
            <InterventionSection>
              <GreenText style={{ marginBottom: '1rem' }}>
                What do you think of this intervention as a way of addressing the issue?
              </GreenText>
              <p style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <GreenText style={{ marginRight: '0rem' }}>Intervention #4</GreenText>: Attract
                Māori to become advisers
              </p>
              <p>
                This intervention includes two parts: Firstly, the talent pool for current
                scholarships could be expanded to include people with experience and passion for the
                primary industries, but not necessarily an agriculture-related degree. Secondly,
                consulting firms could be supported to recruit from this wider pool.
              </p>
              <StyledCollapse ghost>
                <Panel header="Click to read more" key="1">
                  <Wrapper>
                    <p style={{ marginBottom: '1rem' }}>
                      If more Māori became advisers, there could be more advisers with the necessary
                      cultural and commercial skills and knowledge available to Māori
                      agribusinesses. This would support the aspiration of advisory services that
                      are ‘for Māori by Māori’.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      Currently, scholarships are on offer for candidates of Tangata Whenua heritage
                      who have studied or are studying agriculture related degrees, amongst other
                      criteria. However, these scholarships have had limited traction, in part due
                      to the pool of scholars that meet these criteria being small. Attracting more
                      Māori into advisory services will require changes to these scholarships so
                      that they work better for Māori and better for advisory firms.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      One possible intervention would be to expand the pool of scholarship
                      candidates by encouraging people to take indirect routes into advisory roles.
                      For example, those transferring from other areas of the primary industries
                      such as producers or people in commercial and service roles; or people who
                      have experience in other ways such as through family connections or their
                      upbringing. That said, people without agriculture-related degrees may be less
                      attractive to advisory firms. This could possibly be offset by bundling
                      scholarships for new entrants with grants to employers to recruit from this
                      wider talent pool.
                    </p>
                    <p>
                      A complementary intervention could be to take a more proactive approach to
                      finding and recruiting suitable people. This could be similar to the way that
                      an executive search firm would recruit for an executive role. This approach
                      would include drawing on a network of contacts to reach out to potential
                      talent, developing a database, holding one-to-one discussions to understand
                      individuals’ motivations and creating personalised plans to place candidates
                      into suitable roles.
                    </p>
                  </Wrapper>
                </Panel>
              </StyledCollapse>
            </InterventionSection>
          </Wrapper>
        )
      },
      useFlex: true,
      width: '150px',
      height: '3rem',
      boxWidth: '795px',
      customBorder: true,
      // ignoreWidth: true,
      useIcons: true,
      hide: true,
      options: [
        {
          id: 1,
          text: 'Terrible idea!',
          value: 'Terrible idea!',
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
        },
        {
          id: 3,
          text: 'Neither here nor there',
          value: 'Neither here nor there',
        },
        {
          id: 4,
          text: 'Good idea',
          value: 'Good idea',
        },
        {
          id: 5,
          text: 'Great idea!',
          value: 'Great idea!',
        },
      ],
      columns: [
        {
          id: 1,
          value: 'Terrible idea',
          text: 'Terrible idea!',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          id: 3,
          value: 'Neither here nor there',
          text: 'Neither here nor there',
          icon: <IconSmileyThree fill="currentColor" />,
        },
        {
          id: 4,
          value: 'Good idea',
          text: 'Good idea',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          id: 5,
          value: 'Great idea',
          text: 'Great idea',
          icon: <IconSmileyFive fill="currentColor" />,
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'Intervention 4',
          value: 'Intervention 4',
        },
      ],
      choices: [
        {
          id: 1,
          // hide: true,
          text: 'Intervention 4',
          value: 'Intervention 4',
        },
      ],
    },
    {
      id: 'Issue 5 - additional input',
      verticalGrid: true,
      required: true,
      type: multiChoice,
      text: () => {
        return (
          <p>
            Is there anything else you think could be done to{' '}
            <b>increase the capacity of advisers</b> who work with Māori agribusiness?
          </p>
        )
      },
      choices: [
        { id: 1, type: commentBox, value: '', placeholder: 'Additional thoughts' },
        { id: 2, text: 'No, nothing else', value: 'No' },
      ],
      next: ({ answers }) => {
        const indexOfCurrentItem = answers['interventions-intro'].value
          .map(item => {
            return item.issueId
          })
          .indexOf('Issue #5')

        const nextIndex = indexOfCurrentItem + 1
        if (answers['interventions-intro'].value[nextIndex].issueId === undefined) {
          return 'feedback-comments'
        }
        return 'Issue 5 - additional issues'
      },
    },
    {
      id: 'Issue 5 - additional issues',
      type: multiChoice,
      verticalGrid: true,
      text: 'Great! Would you like to consider another issue regarding the advisory system?',
      mobileHeight: '5.5rem',
      choices: ({ answers }) => {
        if (!_.isUndefined(answers)) {
          const choices = [
            {
              id: 1,
              text: 'Sure, show me the next one!',
              value: answers['interventions-intro'].value[1].issueId,
            },
            { id: 7, text: 'No thanks, skip to the end.', value: 'No thanks' },
          ]

          return choices
        } else {
          const placeholderChoices = issuesArray
          return issuesArray
        }
        // const choices = [
        //   {
        //     id: 1,
        //     text: 'Sure, show me the next one!',
        //     value: answers['interventions-intro'].value[1].issueId,
        //   },
        //   { id: 7, text: 'No thanks, skip to the end.', value: 'No thanks' },
        // ]

        // return choices
      },
      next: ({ answers }) => {
        if (answers['Issue 5 - additional issues'].id === 1) {
          const indexOfCurrentItem = answers['interventions-intro'].value
            .map(item => {
              return item.issueId
            })
            .indexOf('Issue #5')
          const nextIndex = indexOfCurrentItem + 1
          const nextIssueId = answers['interventions-intro'].value[nextIndex].issueId
          if (
            nextIssueId in answers &&
            nextIssueId in answers['interventions-intro'].value &&
            'value' in answers[nextIssueId]
          ) {
            return 'feedback-comments'
          }
          if (answers['interventions-intro'].value[nextIndex].issueId === undefined) {
            return 'feedback-comments'
          }
          return answers['interventions-intro'].value[nextIndex].issueId
        }

        return 'feedback-comments'
      },
    },
    {
      id: 'Issue #6',
      type: responsiveMatrix,
      required: true,
      verticalGrid: true,
      useFlex: true,
      width: '150px',
      height: '3rem',
      boxWidth: '795px',
      customBorder: true,
      // ignoreWidth: true,
      useIcons: true,
      hide: true,
      options: [
        {
          id: 1,
          text: 'Terrible idea!',
          value: 'Terrible idea!',
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
        },
        {
          id: 3,
          text: 'Neither here nor there',
          value: 'Neither here nor there',
        },
        {
          id: 4,
          text: 'Good idea',
          value: 'Good idea',
        },
        {
          id: 5,
          text: 'Great idea!',
          value: 'Great idea!',
        },
      ],
      columns: [
        {
          id: 1,
          value: 'Terrible idea',
          text: 'Terrible idea!',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          id: 3,
          value: 'Neither here nor there',
          text: 'Neither here nor there',
          icon: <IconSmileyThree fill="currentColor" />,
        },
        {
          id: 4,
          value: 'Good idea',
          text: 'Good idea',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          id: 5,
          value: 'Great idea',
          text: 'Great idea',
          icon: <IconSmileyFive fill="currentColor" />,
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'Intervention 1',
          value: 'Intervention 1',
        },
      ],
      choices: [
        {
          id: 1,
          // hide: true,
          text: 'Intervention 1',
          value: 'Intervention 1',
        },
      ],
      section: () => {
        return (
          <Wrapper style={{ marginBottom: '1rem' }}>
            {Issue6}

            <InterventionSection>
              <GreenText style={{ marginBottom: '1rem' }}>
                What do you think of this intervention as a way of addressing the issue?
              </GreenText>
              <p style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <GreenText style={{ marginRight: '0rem' }}>Intervention #1</GreenText>: Explore new
                ways to communicate the value of using an adviser
              </p>
              <p>
                Research could be conducted, firstly to explore ways to evaluate the benefits of
                using an adviser so that it is easier for producers to see, and secondly to explore
                which methods of communication are most effective.
              </p>
              <StyledCollapse ghost>
                <Panel header="Click to read more" key="2">
                  <Wrapper>
                    <p style={{ marginBottom: '1rem' }}>
                      In order to increase producer demand for advisory services, it will be
                      important to provide producers with compelling evidence of the benefits to be
                      gained.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      Wherever possible, this will involve the evaluation of benefits, either by
                      quantifying them to allow farmers to weigh them against the cost of obtaining
                      the advice; or exploring new ways to evaluate those benefits that are diffuse,
                      uncertain and/or slow to eventuate. In either case, a key issue will be to
                      understand what ‘value’ means to different clients.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      Yet perhaps more important than the evaluation of benefits, will be the
                      communication of findings. There is some evidence to suggest that particular
                      methods of communication may be better at building producer confidence,
                      leading to behaviour change.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      One oft-cited example is the farmer reaction to the DairyPush programme.
                      DairyPush was launched in April 2007 by the South Waikato District Council to
                      increase dairy farmers’ profitability with a significant financial flow-on to
                      the South Waikato community. DairyPush aimed to have all participating farmers
                      gain more farming and business skills as well as achieve a more profitable
                      business. The project included farm consultants and rural professionals to
                      build three-year business plans with participants. An evaluation using
                      DairyBase data showed that farmers’ profitability had grown by many times the
                      value of the advisory services provided in the initiative. Participating
                      farmers typically accepted this finding but, when asked what they would have
                      been willing to pay for the advice they received, were often not willing to
                      pay the market rate.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      An evaluation using DairyBase data showed that farmers’ profitability had
                      grown by many times the value of the advisory services provided in the
                      initiative. Participating farmers typically accepted this finding but, when
                      asked what they would have been willing to pay for the advice they received,
                      were often not willing to pay the market rate.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      A counter-example known to the authors is more recent. A facilitator of RMPP
                      Action Groups used a short video in 2020 to present participating farmers with
                      the findings of an evaluation of the returns from the RMPP Action Network. The
                      facilitator then worked through a short exercise asking farmers to value the
                      benefits they had received from participation. The result was that the farmers
                      agreed to continue to pay to keep running the Action Group after RMPP funding
                      stopped.
                    </p>
                    <p>
                      What seems clear so far, is that further programmes or activities that have
                      reflection built in are key to having farmers recognise the value of advisers
                      as they enable them to recognise the value in context. Soft skills are also
                      needed by advisers to be able to demonstrate their value to producers.
                      However, the exact communication methods that work best need further analysis.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      An applied research project(s) could therefore be undertaken to:
                    </p>
                    <StyledUL>
                      <li>
                        Develop and test different approaches to both the evaluation and
                        communication of benefits.
                      </li>
                      <li>
                        Share the findings with advisers for them to use in their own marketing
                        efforts.
                      </li>
                      <li>
                        Share the findings with ‘neutral’ participants such as MPI, industry bodies
                        or regional councils, and encourage them to endorse the value of advisers.
                      </li>
                    </StyledUL>
                  </Wrapper>
                </Panel>
              </StyledCollapse>
            </InterventionSection>
          </Wrapper>
        )
      },
    },

    {
      id: 'Issue 6 - intervention 2',
      type: responsiveMatrix,
      required: true,
      section: () => {
        return (
          <Wrapper>
            {Issue6}
            <InterventionSection>
              <GreenText style={{ marginBottom: '1rem' }}>
                What do you think of this intervention as a way of addressing the issue?
              </GreenText>
              <p style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <GreenText style={{ marginRight: '0rem' }}>Intervention #2</GreenText>: Explore
                ‘nudges’ that can address cognitive biases related to advisory services
              </p>
              <p>
                One approach to address cognitive biases, called as such due to being systematic
                errors in thinking, may be ‘nudges’. For example, these could be promoting the use
                of advisers after a ‘trigger event’ such as an adverse climate event, or promoting
                the use of advisers as the ‘default’ norm.
              </p>
              <StyledCollapse ghost>
                <Panel header="Click to read more" key="2">
                  <Wrapper>
                    <p style={{ marginBottom: '1rem' }}>
                      As cognitive biases have become better understood, businesses, governments,
                      educators and others have developed interventions, or ‘nudges’ to utilise or
                      offset them. Nudge theory is a concept from behavioural science which suggests
                      that by designing choices in certain ways, one can influence human behaviour –
                      possibly exploiting or bypassing cognitive biases. A nudge:
                    </p>
                    <StyledUL>
                      <li>Is indirect encouragement</li>
                      <li>Does not limit your options</li>
                      <li>Is transparent</li>
                    </StyledUL>
                    <p>Some examples of nudges include:</p>
                    <StyledUL>
                      <li>
                        Enrolling new workers in Kiwi Saver by default, taking advantage of the
                        default effect.
                      </li>
                      <li>
                        Making recycling bins bigger than regular waste bins, to encourage
                        recycling.
                      </li>
                      <li>Gamification to exploit cognitive bias around competition.</li>
                      <li>
                        Placing common purchases, such as milk, at the furthest end of a
                        supermarket, to encourage purchase of more items.
                      </li>
                    </StyledUL>
                    <p style={{ marginBottom: '1rem' }}>
                      Some suggestions made by Manaaki Whenua to encourage uptake of advisory
                      services could already be considered nudges. These include promoting the use
                      of advisers directly after a ‘trigger event’ – such as a major policy reform,
                      a major disease outbreak or an adverse climate event - because at this stage
                      the producer is much more willing to listen and adopt new ideas.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      An exploration into other nudges to address cognitive biases could offer a
                      rich array of potential approaches to encourage the use of advisory services.
                      Although some nudge ideas will not work, it will be valuable to conduct
                      experiments to find which ones do.
                    </p>
                    <p>
                      Some such nudges that could be explored include making the use of advisory
                      services the ‘default’ or ‘norm’ that has to be opted out of in some way. In
                      this scenario, it will be emphasized that not using an adviser is unusual and
                      weak. Promotions will emphasize that advisers can bring fresh perspectives and
                      superior knowledge of regulations to even the most well-established farms.
                      Another nudge could be to use reminders to maintain client-adviser
                      relationships.
                    </p>
                  </Wrapper>
                </Panel>
              </StyledCollapse>
            </InterventionSection>
          </Wrapper>
        )
      },
      useFlex: true,
      width: '150px',
      height: '3rem',
      boxWidth: '795px',
      customBorder: true,
      // ignoreWidth: true,
      useIcons: true,
      hide: true,
      options: [
        {
          id: 1,
          text: 'Terrible idea!',
          value: 'Terrible idea!',
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
        },
        {
          id: 3,
          text: 'Neither here nor there',
          value: 'Neither here nor there',
        },
        {
          id: 4,
          text: 'Good idea',
          value: 'Good idea',
        },
        {
          id: 5,
          text: 'Great idea!',
          value: 'Great idea!',
        },
      ],
      columns: [
        {
          id: 1,
          value: 'Terrible idea',
          text: 'Terrible idea!',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          id: 3,
          value: 'Neither here nor there',
          text: 'Neither here nor there',
          icon: <IconSmileyThree fill="currentColor" />,
        },
        {
          id: 4,
          value: 'Good idea',
          text: 'Good idea',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          id: 5,
          value: 'Great idea',
          text: 'Great idea',
          icon: <IconSmileyFive fill="currentColor" />,
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'Intervention 2',
          value: 'Intervention 2',
        },
      ],
      choices: [
        {
          id: 1,
          // hide: true,
          text: 'Intervention 2',
          value: 'Intervention 2',
        },
      ],
    },
    {
      id: 'Issue 6 - intervention 3',
      type: responsiveMatrix,
      required: true,
      section: () => {
        return (
          <Wrapper>
            {Issue6}
            <InterventionSection>
              <GreenText style={{ marginBottom: '1rem' }}>
                What do you think of this intervention as a way of addressing the issue?
              </GreenText>
              <p style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <GreenText style={{ marginRight: '0rem' }}>Intervention #3</GreenText>: Compile,
                synthesize and publicise regulations for each sector
              </p>
              <p>
                Over recent years, policy makers have signalled new regulations for the agrisector,
                especially regarding climate change, freshwater and biodiversity. Although they are
                not finalised, more could be done to predict the likelihood of such regulations.
              </p>
              <StyledCollapse ghost>
                <Panel header="Click to read more" key="2">
                  <Wrapper>
                    <p style={{ marginBottom: '1rem' }}>
                      Many of the above mentioned regulations have not been fully developed and
                      finalised. This means that the exact details of the regulations such as
                      timelines, incentives and associated changes that will be needed are unclear.
                      Additionally, these regulations typically exist within separate policies. This
                      uncertainty constrains demand for advisory services as producers consciously
                      or unconsciously use uncertainty to avoid taking actions that will challenge
                      their productivity and/or profitability. This, in turn, prevents advisers from
                      investing further in their capacity and capability as they are not certain it
                      will aid their own profitability.
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      While it is not possible to eliminate regulatory uncertainty entirely, more
                      could be done to:
                    </p>
                    <StyledUL>
                      <li>Bring different policies and regulations together</li>
                      <li>Pull out the implications for different segments of producers</li>
                      <li>Forecast what future changes are likely</li>
                      <li>Recommend appropriate actions to producers in the face of uncertainty</li>
                      <li>Identify the role advisers are to play.</li>
                    </StyledUL>
                    <p>
                      In this sense, this intervention would give people the best ability to predict
                      likelihoods within an uncertain environment. An analogy for this is the
                      reports developed by the International Panel for Climate Change. Although
                      there are uncertainties around climate change that cannot be removed, the
                      panel reduces them by assessing the likelihood of different scenarios.
                    </p>
                    <p>
                      Presumably, MPI would not be able to undertake a role like this itself as this
                      would prejudice its ability to set policy. But MPI could sponsor a third-party
                      if appropriate safeguards for independence were put in place. The Integrated
                      Farm Planning work, likely to be implemented through industry bodies and
                      assurance programmes soon, may be a first step towards this.
                    </p>
                  </Wrapper>
                </Panel>
              </StyledCollapse>
            </InterventionSection>
          </Wrapper>
        )
      },
      useFlex: true,
      width: '150px',
      height: '3rem',
      boxWidth: '795px',
      customBorder: true,
      // ignoreWidth: true,
      useIcons: true,
      hide: true,
      options: [
        {
          id: 1,
          text: 'Terrible idea!',
          value: 'Terrible idea!',
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
        },
        {
          id: 3,
          text: 'Neither here nor there',
          value: 'Neither here nor there',
        },
        {
          id: 4,
          text: 'Good idea',
          value: 'Good idea',
        },
        {
          id: 5,
          text: 'Great idea!',
          value: 'Great idea!',
        },
      ],
      columns: [
        {
          id: 1,
          value: 'Terrible idea',
          text: 'Terrible idea!',
          icon: <IconAngrySmiley fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Bad idea',
          value: 'Bad idea',
          icon: <IconSmileyTwo fill="currentColor" />,
        },
        {
          id: 3,
          value: 'Neither here nor there',
          text: 'Neither here nor there',
          icon: <IconSmileyThree fill="currentColor" />,
        },
        {
          id: 4,
          value: 'Good idea',
          text: 'Good idea',
          icon: <IconSmileyFour fill="currentColor" />,
        },
        {
          id: 5,
          value: 'Great idea',
          text: 'Great idea',
          icon: <IconSmileyFive fill="currentColor" />,
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'Intervention #3: Compile, synthesize and publicise regulations for each sector',
          value: 'Intervention #3: Compile, synthesize and publicise regulations for each sector',
        },
      ],
      choices: [
        {
          id: 1,
          // hide: true,
          text: 'Intervention #3: Compile, synthesize and publicise regulations for each sector',
          value: 'Intervention #3: Compile, synthesize and publicise regulations for each sector',
        },
      ],
    },
    {
      id: 'Issue 6 - additional input',
      type: multiChoice,
      required: true,
      verticalGrid: true,
      text: () => {
        return (
          <p>
            Is there anything else you think could be done to <b>increase producer demand</b> for
            advisory services?
          </p>
        )
      },
      choices: [
        { id: 1, type: commentBox, value: '', placeholder: 'Additional thoughts' },
        { id: 2, text: 'No, nothing else', value: 'No' },
      ],
      next: ({ answers }) => {
        const indexOfCurrentItem = answers['interventions-intro'].value
          .map(item => {
            return item.issueId
          })
          .indexOf('Issue #6')

        const nextIndex = indexOfCurrentItem + 1
        if (answers['interventions-intro'].value[nextIndex].issueId === undefined) {
          return 'feedback-comments'
        }
        return 'Issue 6 - additional issues'
      },
    },
    {
      id: 'Issue 6 - additional issues',
      type: multiChoice,
      mobileHeight: '5.5rem',
      required: true,
      text: 'Great! Would you like to consider another issue regarding the advisory system?',
      verticalGrid: true,
      choices: ({ answers }) => {
        if (!_.isUndefined(answers)) {
          const choices = [
            {
              id: 1,
              text: 'Sure, show me the next one!',
              value: answers['interventions-intro'].value[1].issueId,
            },
            { id: 7, text: 'No thanks, skip to the end.', value: 'No thanks' },
          ]

          return choices
        } else {
          const placeholderChoices = issuesArray
          return issuesArray
        }
        // const choices = [
        //   {
        //     id: 1,
        //     text: 'Sure, show me the next one!',
        //     value: answers['interventions-intro'].value[1].issueId,
        //   },
        //   { id: 7, text: 'No thanks, skip to the end.', value: 'No thanks' },
        // ]

        // return choices
      },
      next: ({ answers }) => {
        if (answers['Issue 6 - additional issues'].id === 1) {
          const indexOfCurrentItem = answers['interventions-intro'].value
            .map(item => {
              return item.issueId
            })
            .indexOf('Issue #6')
          const nextIndex = indexOfCurrentItem + 1
          const nextIssueId = answers['interventions-intro'].value[nextIndex].issueId
          if (
            nextIssueId in answers &&
            nextIssueId in answers['interventions-intro'].value &&
            'value' in answers[nextIssueId]
          ) {
            return 'feedback-comments'
          }
          if (answers['interventions-intro'].value[nextIndex].issueId === undefined) {
            return 'feedback-comments'
          }
          return answers['interventions-intro'].value[nextIndex].issueId
        }

        return 'feedback-comments'
      },
    },
    // {
    //   id: 'interventions-intro',
    //   type: multiChoice,
    //   verticalGrid: true,
    //   required: true,
    //   section: (
    //     <Wrapper>
    //       <QuestionInfo style={{ fontWeight: '500' }}>
    //         <GreenText style={{ fontWeight: '501' }}>Key issues and interventions.</GreenText>
    //       </QuestionInfo>
    //       <p style={{ fontSize: '16px' }}>
    //         We are keen to understand the issues affecting the advisory system, and how we can best
    //         support advisers going forward.
    //       </p>
    //       <Divider style={{ marginBottom: '1rem', marginTop: '0rem', width: '24rem' }} />
    //     </Wrapper>
    //   ),
    //   text: 'What is the biggest issue you face as an adviser to the primary sector? ',
    //   choices: [
    //     { id: 1, type: commentBox, placeholder: 'The biggest issue right now...' },
    //     { id: 2, text: "I don't know", value: "I don't know" },
    //   ],
    //   next: ({ answers }) => {
    //     if (answers['interventions-intro'].value === "I don't know") {
    //       return 'feedback-comments'
    //     }
    //     return 'interventions-p2'
    //   },
    // },
    // {
    //   id: 'interventions-p2',
    //   type: multiChoice,
    //   verticalGrid: true,
    //   required: true,
    //   text: 'What support could be provided to the advisory sector to address this issue?',
    //   choices: [
    //     { id: 1, type: commentBox, placeholder: 'Support that could be provided' },
    //     { id: 2, text: "I don't know", value: "I don't know" },
    //   ],
    // },
    {
      id: 'feedback-comments',
      type: commentBox,
      height: '3rem',
      placeholder: 'Final comments',
      inputText:
        'Finally, if you would like to receive a summary of the results of this survey, please provide an email address',
      text: ({ answers }) => {
        return (
          <div>
            <p>Thank you for your time!</p>
            <p style={{ marginBottom: '0rem' }}>
              Do you have any final comments regarding how the advisory system can be supported and
              strengthened?
            </p>
          </div>
        )
      },
      emailInput: true,
      emailPlaceholder: 'John.smith@gmail.com',
    },
    {
      id: 'end-page',
      type: endPage,
      text: ({ answers }) => {
        let output
        if (
          answers['which-primary-sector'].value.some(item => item.value === 'No primary sector')
        ) {
          output = (
            <Textbox style={{ padding: '0px' }}>
              <p>
                Sorry, we are only looking to speak to those working in the primary sector for this
                survey.
              </p>
              <p>
                If you know anyone working in an advisory role for the primary sector, we would
                appreciate it if you could pass this survey on to them.
              </p>
              <p>Thank you for your time</p>
              <a
                href="https://confer.online"
                rel="noreferrer"
                target="_blank"
                style={{ color: '#E82D7C', textDecoration: 'underline' }}
              >
                <p style={{ textDecoration: 'underline' }}>
                  Like this survey and want to create your own? Click here and let&apos;s Confer!
                </p>
              </a>
              <div style={{ fontSize: '18px' }}>
                <img width="255px" src={conferLogo} alt="confer-logo.jpg" />
              </div>
            </Textbox>
          )
          return output
        }
        output = (
          <Textbox style={{ padding: '0px' }}>
            <h1 style={{ color: 'rgba(0, 0, 0, 0.65) ', fontSize: '18px', fontWeight: 600 }}>
              Thank you for completing the survey! Your response will help inform new initiatives to
              support the primary sector advisory system.
            </h1>
            <a
              href="https://app.powerbi.com/view?r=eyJrIjoiZjYyZWE1ZjQtZWQ2YS00Njk0LWE3ODAtODE0OGM1NTA5YjM0IiwidCI6IjE5MzU4YzgxLTViMWItNGZjYS1iZmM2LTcwOTEzZDU1ZDA3ZCJ9&pageName=ReportSectioneda988c4ca08d93c03c4"
              rel="noreferer"
              target="_blank"
              style={{ color: 'rgba(0, 0, 0, 0.65) ', fontSize: '18px', fontWeight: 700 }}
            >
              Interested in seeing what everyone has else said? {''}
              <span style={{ textDecoration: 'underline', color: '#E82D7C' }}>
                Click here to see the dashboard!
              </span>
            </a>

            <p>
              If you know anyone else working in an advisory capacity for the primary sector, we
              would greatly appreciate if you could pass this survey on to them.
            </p>
            <a
              href="https://confer.online"
              rel="noreferrer"
              target="_blank"
              style={{ color: '#E82D7C' }}
            >
              <p style={{ textDecoration: 'underline' }}>
                Like this survey and want to create your own? Click here and let&apos;s Confer!
              </p>{' '}
            </a>
            <div style={{ fontSize: '18px', textDecoration: 'underline' }}>
              <img width="255px" src={conferLogo} alt="confer-logo.jpg" />
            </div>
          </Textbox>
        )
        return output
      },
    },
  ],
  settings: {
    writeToFirestore: true,
    surveyName: 'pias',
    primaryColour: '#48a54c',
    hideButton: true,
    // showQid: true,
    useSections: false,
    sections: [
      {
        name: 'Your role ',
        startQid: 'intro-page',
        nextQid: 'which-org-do-you-work-at',
        endQid: 'advice-frequency-in-role',
      },
      {
        name: 'Capabilities',
        possibleQid: 'advisor-maori-agg-business',
        prevQid: 'advice-frequency-in-role',
        startQid: 'advisor-capacity-and-capability-gaps',
        endQid: 'advisor-capacity-and-capability-gaps-p3',
        nextQid: 'career-pathways',
        futureQid: 'education',
        // possibleQid: 'education',
      },
      {
        name: 'Demographics',
        startQid: 'career-pathways',
        possibleQid: 'professional-dev', // advice-frequency-land-use
        otherQid: 'advice-frequency-land-use',
        endQid: 'social-network',
        nextQid: 'advice-frequency-practices',
        futureQid: 'advice-frequency-land-use',
        nextSection: 'interventions-intro',
        prevQid: 'advisor-capacity-and-capability-gaps-p3',
      },
      {
        name: 'Connections (optional)',
        startQid: 'advice-frequency-practices',
        nextQid: 'interventions-intro',
        prevQid: 'age',
      },
      {
        name: 'Key issues (optional)',
        startQid: 'interventions-intro',
        possibleQid: 'advice-frequency-practices',
        prevSection: 'age',
        // otherQid: 'age',
        prevQid: 'social-network',
        endQid: 'feedback-comments',
      },
    ],
    // secondaryColour: '#f38231',
    // contrastColour: '#fff',
    hideSubmit: false,
    // hideLogo: true,
    logoFirstPageOnly: true,
    companyLogo: {
      images: [{ image: MpiLogo }, { image: scarlatti }, { image: geosocialLogo }],
    },
    logoLocation: 'space-between',
    leftBackgroundImage,
    backgroundImage: leftBackgroundImage,
    progressBar: false,
  },
}

export default Pias

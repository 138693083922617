import React from 'react'
import { useMediaQuery } from 'beautiful-react-hooks'
import PropTypes from 'prop-types'
import MatrixTable from './Matrix-table'
import MultiDropdown from './MultiDropdown'
import styled from 'styled-components/macro'

const ResponsiveMatrixTable = ({
  answers,
  question,
  currentAnswer,
  updateCurrent,
  submitAnswer,
  injectCurrent,
  dynamicColour,
  dynamicContrast,
  settings,
  loopIndex,
}) => {
  const mobileMultiCheckBox = useMediaQuery('(max-width: 768px)') // small and medium devices

  const questionProps = {
    currentAnswer: currentAnswer,
    choices: question.choices,
    options: question.options,
    question,
    answers,
    updateCurrent,
    submitAnswer,
    settings,
    loopIndex,
    injectCurrent,
    dynamicColour,
    dynamicContrast,
  }
  return (
    <Container>
      {mobileMultiCheckBox ? (
        <MultiDropdown {...questionProps} />
      ) : (
        <MatrixTable {...questionProps} />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
`

ResponsiveMatrixTable.propTypes = {
  answers: PropTypes.instanceOf(Object),
  question: PropTypes.instanceOf(Object).isRequired,
  currentAnswer: PropTypes.instanceOf(Object).isRequired,
  updateCurrent: PropTypes.func.isRequired,
  submitAnswer: PropTypes.func.isRequired,
}

export default ResponsiveMatrixTable

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
/**
 * Navigation button
 * @param {*} props
 */
const BackButton = props => {
  const { onClick, color } = props
  return (
    <Button color={color} type="button" onClick={onClick}>
      <i className="material-icons">keyboard_arrow_left</i>
      Previous
    </Button>
  )
}

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #ffffff;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  height: 2rem;
  font-weight: 501;
  border: ${props => (props.color ? `1px solid ${props.color}` : '1px solid #E82D7C')};
  width: 160px;
  border-radius: 3px;
  transition: background 250ms ease-in-out, transform 150ms ease;
  color: ${props => (props.color ? props.color : '#E82D7C')};
  i {
    margin-right: 1rem;
  }
  user-select: none;
  @media (min-width: 0px) and (max-width: 478px) {
    width: 45%;
    height: 3.125rem;
  }
`

export default BackButton

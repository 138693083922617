import React, { useState, useEffect, Suspense, lazy } from 'react'
import styled, { keyframes } from 'styled-components/macro'
import PropTypes from 'prop-types'
import _ from 'lodash'
import AnimatedDiv from '../ui/Layout/AnimatedDiv'
import OutputResults from '../Charts/OutputResults'
import QuestionInfo from './QuestionInfo'

import {
  DragDrop,
  NumberRating,
  MultiChoiceQuestions,
  MultiCheckBox,
  MultiSelect,
  MultiDropdown,
  MultiselectDropdown,
  MultiTextBox,
  MultiSlider,
  MultiRadioList,
  MultiSelectMap,
  MultiCardChoice,
  PowerBI,
  SocialNetwork,
  ImageRadioList,
  EndSurvey,
  Map,
  SimpleMap,
  SimpleMapMulti,
  AutoComplete,
  SmartSlider,
  CircleMenu,
  StarRating,
  EndSurveyForm,
  RadioList,
  Ranking,
  SingleTextbox,
  SingleChoiceMap,
  InputField,
  InputFieldNumber,
  Hidden,
  CommentBox,
  ImageChoice,
  WelcomePage,
  EndPage,
  Section,
  ResponsiveMatrixTable,
  ResponsiveMatrix,
  ResponsiveSlider,
  ResponsiveSimpleMap,
  ResponsiveMultiMap,
  ConjointAnalysis,
} from '../question-types/QuestionComponents'

export const checkRequired = () => {
  console.log('checkRequired from question.js')
}

const Question = ({
  question,
  settings,
  currentSurveyQuestions,
  currentAnswer,
  updateCurrent,
  submitAnswer,
  injectCurrent,
  answers,
  loopIndex,
  loopArray,
  updateAnswer,
}) => {
  const qChoices = _.isFunction(question.choices) ? question.choices({ answers }) : question.choices

  const updatedCurrentAnswer = {
    ...currentAnswer,
    questionId: question.id,
    questionType: question.type,
    questionLoopIndex: loopIndex,
    loopIndexLabel: loopIndex,
    choices: _.isArray(qChoices)
      ? qChoices.map(choice => {
          return choice.type
            ? _.pick(choice, ['id', 'text', 'value', 'type'])
            : _.pick(choice, ['id', 'text', 'value'])
        })
      : null,
  }

  if (loopIndex != null) {
    updatedCurrentAnswer.questionLoopIndex = loopArray[loopIndex]
  }

  const { primaryColour, contrastColour } = settings
  const dynamicColour = !_.isUndefined(primaryColour) ? primaryColour : '#E82D7C'
  const dynamicContrast = !_.isUndefined(contrastColour) ? contrastColour : '#fff'

  const questionProps = {
    currentAnswer: updatedCurrentAnswer,
    choices: question.choices,
    question,
    answers,
    updateCurrent,
    submitAnswer,
    settings,
    loopIndex,
    injectCurrent,
    currentSurveyQuestions,
    dynamicColour,
    dynamicContrast,
  }

  const getQuestionComponent = questionType => {
    const questionTypes = {
      multiChoice: MultiChoiceQuestions,
      radioList: RadioList,
      powerBi: PowerBI,
      imageRadioList: ImageRadioList,
      multiRadioList: MultiRadioList,
      matrix: MultiCheckBox,
      ranking: Ranking,
      multiTextBox: MultiTextBox,
      imageChoice: ImageChoice,
      multiCardChoice: MultiCardChoice,
      circleMenu: CircleMenu,
      singleTextbox: SingleTextbox,
      inputField: InputField,
      endSurveyForm: EndSurveyForm,
      hidden: Hidden,
      conjointAnalysis: ConjointAnalysis,
      responsiveMatrix: ResponsiveMatrix,
      commentBox: CommentBox,
      inputFieldNumber: InputFieldNumber,
      multiSelect: MultiSelect,
      multiSlider: MultiSlider,
      smartSlider: SmartSlider,
      multiDropdown: MultiDropdown,
      multiSelectDropdown: MultiselectDropdown,
      starRating: StarRating,
      numberRating: NumberRating,
      endSurvey: EndSurvey,
      endPage: EndPage,
      dragDrop: DragDrop,
      map: Map,
      hidden: Hidden,
      starRating: StarRating,
      endSurveyForm: EndSurveyForm,
      inputFieldNumber: InputFieldNumber,
      singleChoiceMap: SingleChoiceMap,
      multiSelectMap: MultiSelectMap,
      autoComplete: AutoComplete,
      socialNetwork: SocialNetwork,
      simpleMap: SimpleMap,
      simpleMapMulti: SimpleMapMulti,
      welcomePage: WelcomePage,
      responsiveMatrixTable: ResponsiveMatrixTable,
      responsiveSlider: ResponsiveSlider,
      responsiveMatrix: ResponsiveMatrix,
      responsiveSlider: ResponsiveSlider,
      responsiveMultiMap: ResponsiveMultiMap,
      responsiveSimpleMap: ResponsiveSimpleMap,
      default: <div>Error: no question type: {question.type}</div>,
    }

    const MyQuestionComponent = questionTypes[questionType]
    return <MyQuestionComponent {...questionProps} /> || questionTypes['default']
  }

  const text = _.isFunction(question.text) ? question.text({ answers }) : question.text

  const dragDropComponent = question.type === 'dragDrop'
  const jsxMarkup = dragDropComponent ? (
    <div key={question.id}>
      <QuestionInfo {...questionProps}>{text}</QuestionInfo>
      <Suspense fallback={<div>Loading...</div>}>{getQuestionComponent(question.type)}</Suspense>
    </div>
  ) : (
    <AnimatedDiv key={question.id}>
      <QuestionInfo {...questionProps}>{text}</QuestionInfo>
      <Suspense fallback={<div>Loading...</div>}>
        {getQuestionComponent(question.type)}
        {question.output ? <OutputResults {...questionProps} /> : null}
      </Suspense>
    </AnimatedDiv>
  )
  return jsxMarkup
}

Question.propTypes = {
  question: PropTypes.instanceOf(Object),
  answers: PropTypes.instanceOf(Object),
  currentAnswer: PropTypes.instanceOf(Object),
  updateAnswer: PropTypes.func,
  updateCurrent: PropTypes.func,
  submitAnswer: PropTypes.func,
  loopArray: PropTypes.oneOfType([
    isArray => {
      return isArray === null
    },
    PropTypes.instanceOf(Array),
  ]),
  loopIndex: PropTypes.oneOfType([
    isNumber => {
      return isNumber === null
    },
    PropTypes.number,
  ]),
}

export default Question

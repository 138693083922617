/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import SurveyWrapper from '../reducers/SurveyWrapper'
import { endLoop } from '../reducers/actions'
import leftBackgroundImage from '../../assets/images/StepChange/cows-bg-2.jpg'
import checkImage from '../../assets/images/StepChange/check.svg'
import crossImage from '../../assets/images/StepChange/cross.svg'
import companyLogo from '../../assets/images/StepChange/DairyNZ.jpg'
import testImage from '../../assets/images/CNA/agree_green.svg'
import beefImage from '../../assets/images/PIAS/beef_nobg.svg'
import dairyImage from '../../assets/images/PIAS/dairy_nobg.svg'
import fisheriesImage from '../../assets/images/PIAS/fisheries_nobg.svg'
import forestryImage from '../../assets/images/PIAS/forestry.svg'
import horticultureImage from '../../assets/images/PIAS/horticulture_nobg.svg'
import backgroundVideo from '../../assets/images/StepChange/video.mp4'
import 'antd/dist/antd.css'
import 'typeface-open-sans'

import {
  endPage,
  responsiveMatrix,
  responsiveSlider,
  multiselectDropdown,
  dragDrop,
  matrix,
  inputField,
  multiChoice,
  multiSelect,
  section,
  endSurvey,
  singleTextbox,
  autoComplete,
  slider,
  multiCardChoice,
  imageChoice,
  welcomePage,
  inputFieldNumber,
  button,
  ranking,
  input,
  multiDropdown,
  socialNetwork,
} from '../question-types/QuestionTypes'
import companies from '../../assets/data/PIAS/companies'

import { SmallText, Textbox } from '../../assets/styles/global'
import { ReactComponent as Check } from '../../assets/images/HCF/check-icon.svg'
import { ReactComponent as Cross } from '../../assets/images/HCF/cross-icon.svg'

const IconCheck = styled(Check)`
  width: 45px;
  height: 80px;
`

const IconCross = styled(Cross)`
  width: 45px;
  height: 80px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  p {
    margin-bottom: 0;
  }
  /* align-items: center; */
`
const GreenText = styled.p`
  font-size: 1rem;
  color: #6fb139;
  font-family: 'Open Sans', sans-serif;
`

const StyledCheckOutlined = styled(CheckOutlined)`
  color: #46e4b5;
`

const StyledCloseOutlined = styled(CloseOutlined)`
  color: #5b3bdf;
`

const Q = styled.span`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`
const SectionInfo = styled.p`
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #9b9b9b;
`

const YellowText = styled.p`
  font-size: 1rem;
  color: #eaae19;
`

const DarkBlueText = styled.p`
  font-size: 1rem;
  color: #474f40;
  letter-spacing: 0.1em;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0;
  width: 90vw;
`

const RedText = styled.p`
  font-size: 1rem;
  color: #84367a;
`

const BlueText = styled.p`
  font-size: 1rem;
  color: #00a7b8;
  font-family: 'Open Sans', sans-serif;
`

const DisclaimerText = styled.p`
  font-size: 1rem;
  width: 90vw;
  margin-bottom: 1rem;
  /* font-style: italic; */
`

const DarkGreenText = styled.p`
  font-size: 1rem;
  color: #4a6f2f;
  font-family: 'Open Sans', sans-serif;
`

const Grey = styled.h1`
  color: #535d52;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.1em;
`

const StyledP = styled.p`
  font-size: 20px;
`

const BoldText = styled.p`
  font-weight: 700;
  margin-top: 1rem;
`

const Container = styled.div`
  background-color: red;
  height: 100vh;
  width: 100vw;
`

const StepChange = {
  questions: [
    {
      id: 'q1',
      text: 'To start this off, which one are you?',
      type: multiChoice,
      choices: [
        {
          id: 1,
          text: 'Learner',
          value: 'Learner',
        },
        {
          id: 2,
          text: 'Tutor',
          value: 'Tutor',
        },
      ],
    },
    {
      id: 'q2',
      text: 'What are you interested in?',
      type: multiChoice,
      choices: [
        {
          id: 1,
          text: 'Choosing a suitable Learning management software',
          value: 'Choosing a suitable Learning management software',
        },
        {
          id: 2,
          text: 'Solutions for distance learning',
          value: 'Solutions for distance learning',
        },
        {
          id: 3,
          text: 'How to create an accessible course',
          value: 'How to create an accessible course',
        },
      ],
    },
    {
      id: 'q3',
      text: 'Here are some suitable resources to get you started',
      type: multiChoice,
      choices: [
        {
          id: 1,
          text: 'Resource 1',
          value: 'Resource 1',
        },
        {
          id: 2,
          text: 'Resource 2',
          value: 'Resource 2',
        },
        {
          id: 3,
          text: 'Resource 3',
          value: 'Resource 3',
        },
      ],
    },
  ],
  settings: {
    // showQid: true,
    primaryColour: '#05B153',
    surveyName: 'navigation-tool',
    // specialEffects: true,
    objectPosition: 'right',
    // hideSubmit: true,
    // useVideo: true,
    // companyLogo,
    // logoFirstPageOnly: true,
    // backgroundVideo,
  },
}

export default StepChange

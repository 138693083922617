import React from 'react'
import styled from 'styled-components/macro'
import 'antd/dist/antd.css'
import PropTypes from 'prop-types'

const PowerBI = ({ question }) => {
  return (
    <div>
      <StlyedIframe
        title="Greenbay"
        width="800"
        height="600"
        src={question.url}
        frameborder="0"
        allowFullScreen="true"
      />
    </div>
  )
}

PowerBI.propTypes = {
  question: PropTypes.instanceOf(Object),
}
const StlyedIframe = styled.iframe`
  @media (min-width: 0px) and (max-width: 479px) {
    width: 95vw;
    height: 50vh;
  }
`

export default PowerBI

import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import _ from 'lodash'
import styled from 'styled-components'
import propTypes from 'prop-types'
import { geoMercator, geoPath } from 'd3-geo'
import { Checkbox, Radio } from 'antd'
import Tooltip from 'rc-tooltip'

const SimpleMap = ({ updateCurrent, currentAnswer, submitAnswer, settings, question }) => {
  const [hoverInfo, updateHoverInfo] = useState({ id: null, region: null })
  const mapSize = { width: 800, height: 450 }
  // const [mapSize, setMapSize] = useState({ width: 800, height: 450 })
  const [regions, setRegions] = useState([])
  const [mapData, setMapData] = useState({})
  const mapSVG = useRef()

  // const { primaryColour, contrastColour } = settings
  // const dynamicColour = !_.isUndefined(primaryColour) ? primaryColour : '#40a9ff'

  const onHover = event => {
    const regionId = parseInt(event.target.id)
    if (_.isNumber(regionId)) {
      updateHoverInfo({
        id: regionId,
        region: regions.find(region => region.properties.id === regionId).properties.name,
        // lngLat: event.lngLat,
      })
    }
  }

  const onMouseLeave = event => {
    updateHoverInfo({
      id: null,
      region: null,
    })
  }

  const onClick = event => {
    const regionId = parseInt(event.target.id)
    if (_.isNumber(regionId)) {
      if (
        currentAnswer.value &&
        _.includes(
          currentAnswer.value.map(s => s.id),
          regionId,
        )
      ) {
        updateCurrent({
          ...currentAnswer,
          value: _.reject(currentAnswer.value, ans => ans.id === regionId),
        })
      } else {
        updateCurrent({
          ...currentAnswer,
          value: [
            ...currentAnswer.value,
            {
              id: regionId,
              name: regionId,
              region: regions.find(region => region.properties.id === regionId).properties.name,
              value: regions.find(region => region.properties.id === regionId).properties.name,
            },
          ],
        })
      }
    }
  }

  // function handleResize() {
  //   setMapSize({
  //     ...mapSize,
  //     width: mapSVG.parentNode.clientWidth,
  //     height: mapSVG.parentNode.clientHeight,
  //   })
  // }

  const selectAll = () => {
    const allRegions = regions.map(item => ({
      id: item.properties.id,
      region: item.properties.name,
      value: item.properties.name,
    }))
    updateCurrent({
      ...currentAnswer,
      value: allRegions,
      radioState: 1,
    })
    if (currentAnswer.radioState === 1) {
      const emptyArray = []
      updateCurrent({ ...currentAnswer, value: emptyArray, radioState: 0 })
    }
  }

  const selectNorth = () => {
    const northRegions = [
      { id: 0, name: 1, region: 'Northland Region', value: 'Northland ' },
      { id: 1, name: 1, region: 'Auckland Region', value: 'Auckland ' },
      { id: 2, name: 2, region: 'Waikato Region', value: 'Waikato ' },
      { id: 3, name: 3, region: 'Bay of Plenty Region', value: 'Bay of Plenty ' },
      { id: 4, name: 4, region: 'Gisborne Region', value: 'Gisborne ' },
      { id: 5, name: 5, region: "Hawke's Bay Region", value: "Hawke's Bay " },
      { id: 6, name: 6, region: 'Taranaki Region', value: 'Taranaki ' },
      { id: 7, name: 7, region: 'Manawatū-Whanganui Region', value: 'Manawatū-Whanganui ' },
      { id: 8, name: 8, region: 'Wellington Region', value: 'Wellington Region' },
    ]
    updateCurrent({ ...currentAnswer, value: northRegions, radioState: 2 })
    if (currentAnswer.radioState === 2) {
      const emptyArray = []
      updateCurrent({ ...currentAnswer, value: emptyArray, radioState: 0 })
    }
  }

  const selectSouth = () => {
    const SouthRegions = [
      { id: 9, name: 9, region: 'West Coast Region', value: 'West Coast' },
      { id: 10, name: 10, region: 'Canterbury Region', value: 'Canterbury' },
      { id: 11, name: 11, region: 'Otago Region', value: 'Otago' },
      { id: 12, name: 12, region: 'Southland Region', value: 'Southland' },
      { id: 15, name: 15, region: 'Marlborough Region', value: 'Marlborough' },
      { id: 16, name: 16, region: 'Nelson/Tasman Region', value: 'Nelson/Tasman' },
    ]
    updateCurrent({ ...currentAnswer, value: SouthRegions, radioState: 3 })
    if (currentAnswer.radioState === 3) {
      const emptyArray = []
      updateCurrent({ ...currentAnswer, value: emptyArray, radioState: 0 })
    }
  }

  useEffect(() => {
    // handleResize()
    // setState(radioState)
    if (!currentAnswer.value) {
      updateCurrent({
        ...currentAnswer,
        value: [],
      })
    }
    if (question.customSource) {
      axios.get(`${process.env.PUBLIC_URL}/${question.customSource}`).then(response => {
        setMapData(response.data)
        setRegions(response.data.features)
      })
    } else {
      axios.get(`${process.env.PUBLIC_URL}/nz-geodata-simple.json`).then(response => {
        setMapData(response.data)
        setRegions(response.data.features)
      })
    }
  }, [])

  const { height, width } = mapSize
  const projection = geoMercator().fitSize([width, height], mapData)

  const pathGenerator = geoPath().projection(projection)

  const currentIds = currentAnswer.value ? currentAnswer.value.map(selection => selection.id) : null
  const regionPaths = regions.map(d => {
    return (
      <Tooltip placement="left" trigger={['hover']} overlay={<span>{d.properties.name}</span>}>
        <path
          key={d.properties.id}
          id={d.properties.id}
          d={pathGenerator(d)}
          fill={
            d.properties.id === hoverInfo.id || _.includes(currentIds, d.properties.id)
              ? '#48a54c'
              : 'white'
          }
          fillOpacity={_.includes(currentIds, d.properties.id) ? 1 : 0.3}
          stroke="black"
          cursor="pointer"
          className="regions"
          onMouseOver={onHover}
          onFocus={onHover}
          onMouseOut={onMouseLeave}
          onClick={onClick}
        />
      </Tooltip>
    )
  })
  return (
    <Container className="map">
      {/* <Disclaimer>{question.disclaimer}</Disclaimer> */}
      {question.customSource || question.noRadio ? null : (
        <StyledRadioGroup
          name="radiogroup"
          value={currentAnswer.radioState}
          dynamicColour={settings.dynamicColour}
        >
          <Radio value={1} onClick={selectAll} dynamicColour={settings.dynamicColour}>
            All of NZ
          </Radio>
          <Radio value={2} onClick={selectNorth} dynamicColour={settings.dynamicColour}>
            All North Island
          </Radio>
          <Radio value={3} onClick={selectSouth} dynamicColour={settings.dynamicColour}>
            All South Island
          </Radio>
        </StyledRadioGroup>
      )}

      <svg width="100%" height="90%" ref={mapSVG} className="svg-map">
        <g>{regionPaths}</g>
      </svg>
    </Container>
  )
}

SimpleMap.propTypes = {
  question: propTypes.instanceOf(Object),
  updateCurrent: propTypes.func,
  currentAnswer: propTypes.instanceOf(Object),
}

const Container = styled.div`
  height: 36rem;
  width: 800px;
  margin-top: 0rem;
  /* svg {
    g {
      path:hover {
        fill: #48a54c;
        opacity: 0.3;

      }
    }
  } */
`

const Disclaimer = styled.p`
  font-size: 14px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: rgba(0, 0, 0, 0.65);
  font-style: italic;
  font-weight: 400;
`

const StyledRadioGroup = styled(Radio.Group)`
  display: inline-block;

  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${props => props.dynamicColour};
  }

  .ant-radio-inner::after {
    background-color: ${props => props.dynamicColour};
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${props => props.dynamicColour};
  }
  .ant-radio-inner::after {
    border-color: ${props => props.dynamicColour};
  }
  .ant-radio-checked::after {
    border: 1px solid ${props => props.dynamicColour};
  }
`

export default SimpleMap

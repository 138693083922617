import React, { useState, useEffect } from 'react'
import 'antd/dist/antd.css'
import { message, notification } from 'antd'
import 'react-toastify/dist/ReactToastify.css'
import propTypes from 'prop-types'
import styled, { keyframes } from 'styled-components/macro'
// import { notification } from 'antd'
import _ from 'lodash'

const DragDrop = ({ question, currentAnswer, updateCurrent }) => {
  const { CompanyName, zoneOrder, dropZones } = question
  const [showInput, setShowInput] = useState(false)
  const [inputState, setInputState] = useState('')
  const [nextId, setNextId] = useState(100)
  const [draggedItem, setDraggedItem] = useState({})
  const [dragSource, setDragSource] = useState({})

  const resetColor = event => {
    const element = event
    element.target.style.backgroundColor = currentAnswer.dropZones[event.target.id].color
  }

  /**
   * Changes dropZone background color when 'item' is being hovered over target dropZone
   * @param {*} event
   */

  const changeColorOnHover = event => {
    const element = event
    element.target.style.backgroundColor = 'skyblue'
  }

  /**
   * Reset dropZone color back to original, color is set on question.dropZone.color
   * @param {*} event
   */
  const onDragLeave = event => {
    if (event.target.hasAttribute('data-droppable')) {
      resetColor(event)
    }
  }

  /**
   * Toast message to appear when dropZone has reached maxItems
   */
  const notifyError = () => {
    message.error('Cannot add more items to target Drop Zone')
  }

  /**
   * Toast message to appear when item is succesfully added to dropZone
   */

  const notifySuccess = (event, placement) => {
    const element = event
    notification.success({
      message: 'Label moved',
      description: `${draggedItem.value} added to ${
        currentAnswer.dropZones[element.target.id].title
      }`,
      placement,
    })
  }

  /**
   * Toast message to appear when label is succesfully created
   */

  // TODO: refactor this to multiple functions
  const notifyLabelCreation = (placement, newLabel) => {
    notification.success({
      message: 'Created new label',
      description: `${newLabel.value}`,
      placement,
    })
  }

  /**
   * draggedItem may only be dropped if it's in a dropZone && dropZone.maxItems is not reached
   * @param {*} event
   */
  const onDrop = event => {
    const dropZone = event.target.hasAttribute('data-droppable')
    const maxDropzoneItems =
      currentAnswer.dropZones[event.target.id].items.length >=
      currentAnswer.dropZones[event.target.id].maxItems

    if (dropZone) {
      if (maxDropzoneItems) {
        notifyError()
        resetColor(event)
        return
      }

      const newAnswer = { ...currentAnswer }
      newAnswer.dropZones = {
        ...currentAnswer.dropZones,
        [dragSource]: {
          ...currentAnswer.dropZones[dragSource],
          items: currentAnswer.dropZones[dragSource].items.filter(item => {
            return item.value !== draggedItem.value
          }),
        },
        [event.target.id]: {
          ...currentAnswer.dropZones[event.target.id],
          items: [...new Set([...currentAnswer.dropZones[event.target.id].items, draggedItem])],
        },
        draggedItem: {},
      }
      onDragLeave(event)
      updateCurrent(newAnswer)
      notifySuccess(event, 'topLeft')
    }
  }

  useEffect(() => {
    if (!_.has(currentAnswer, 'dropZones')) {
      updateCurrent({ ...currentAnswer, dropZones: question.dropZones })
    }
  })

  /**
   * Check if DropZone before changing dropZone color
   * @param {*} event
   */

  const onDragOver = event => {
    const dropZone = event.target.hasAttribute('data-droppable')
    event.preventDefault()
    if (dropZone) {
      changeColorOnHover(event)
    }
  }

  /**
   * setNextId auto increments id for newly created item,
   * Once the item is created, updateCurrent is fired to update UI and redux Store,
   * item is placed in 'inactive',
   */

  const createLabel = () => {
    setNextId(nextId + 1)
    if (inputState !== '') {
      // check inputstate
      const newLabel = {
        id: nextId,
        value: inputState,
        text: inputState,
      }
      const updatedAnswer = { ...currentAnswer }
      const startZone = currentAnswer.dropZones['start-zone']
      updatedAnswer.dropZones = {
        ...currentAnswer.dropZones,
        'start-zone': {
          ...startZone,
          items: startZone.items.concat(newLabel),
        },
      }
      updateCurrent(updatedAnswer)
      notifyLabelCreation('topLeft', newLabel)
    }

    document.getElementById('inputElement').value = '' // clear input box
    setInputState('') // empty state
    setShowInput(!showInput) // hide input state
  }

  /**
   * Event Listener to create label on 'Enter'
   * @param {*} event
   */
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      createLabel()
    }
  }

  /**
   * Event Listener update state on input element
   * @param {*} event
   */
  const handleChange = event => {
    setInputState(event.target.value)
  }

  return (
    <Container>
      <Company>{CompanyName}</Company>

      <div onDrop={onDrop}>
        {_.has(currentAnswer, 'dropZones')
          ? zoneOrder.map(zoneId => {
              const dropZone = currentAnswer.dropZones[zoneId]
              let additionalMarkup
              if (zoneId === 'start-zone') {
                additionalMarkup = (
                  <div>
                    <Placeholder>
                      <Title>Labels</Title>
                      <PlaceholderButton type="button" onClick={() => setShowInput(!showInput)}>
                        +
                      </PlaceholderButton>
                    </Placeholder>
                    <div>
                      {showInput && (
                        <InputContainer role="button" onKeyPress={handleKeyPress}>
                          <InputElement
                            id="inputElement"
                            type="text"
                            onChange={handleChange}
                            placeholder="Create new Label"
                            autoFocus
                            autoComplete="off"
                          />
                        </InputContainer>
                      )}
                    </div>
                  </div>
                )
              }
              return (
                <DropZone
                  onDragOver={event => onDragOver(event)}
                  data-droppable
                  id={dropZone.id}
                  key={dropZone.id}
                  onDragLeave={onDragLeave}
                >
                  {additionalMarkup}
                  {dropZone.items.map(item => (
                    <Item
                      draggable
                      onDrag={() => {
                        setDragSource(dropZone.id)
                        setDraggedItem(item)
                      }}
                      key={item.id}
                    >
                      <Label>{item.value}</Label>
                      <DropZoneLabel>{dropZone.title}</DropZoneLabel>
                    </Item>
                  ))}
                </DropZone>
              )
            })
          : null}
        <LegendContainer>
          {Object.values(dropZones).map(dropZone => (
            <Row>
              <Color />
              <Legend>{dropZone.title}</Legend>
            </Row>
          ))}
        </LegendContainer>
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
  width: 100vw;
  height: 67vh;
  position: static;
  flex-direction: row;
  .ant-message {
    bottom: 20px;
    top: initial;
  }
`

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Company = styled.h4`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-content: center;
  z-index: 4;
`

const Placeholder = styled.div`
  background-color: #a992de;
  align-items: center;
  width: 210px;
  padding: 15px;
  display: flex;
  height: 50px;
  margin-bottom: 1em;
  justify-content: space-between;
`

const slideDown = keyframes`
   0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const slideAndFlash = keyframes`
 0% {
  transform: translateY(-20px);
    background-color: yellow;
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    background-color: white;
  }
`

const InputElement = styled.input`
  width: 85%;
  margin-top: 0.1em;
  margin-bottom: 1em;
  animation: 0.5s ${slideDown} ease-in-out;
`

const Title = styled.h4`
  text-transform: uppercase;
  letter-spacing: 0.15em;
  color: white;
  display: flex;
  align-self: center;
`

const PlaceholderButton = styled.button`
  width: 25px;
  height: 25px;
`

const Color = styled.div`
  width: 25px;
  height: 25px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 1em;
  :nth-of-type(1n) {
    ${Color} {
      background-color: #8bea97;
    }
  }
  :nth-of-type(2n) {
    ${Color} {
      background-color: #aeeeb694;
    }
  }
  :nth-of-type(3n) {
    ${Color} {
      background-color: #aeeeb626;
    }
  }
  :nth-of-type(4n) {
    ${Color} {
      background-color: white;
    }
  }
`

const DropZoneLabel = styled.span`
  font-size: 0.65em;
  color: grey;
  text-transform: uppercase;
  letter-spacing: 0.1em;
`
const Label = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
`
const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 10rem;
  height: 3rem;
  margin-bottom: 1rem;
  border-right: 5px solid #8bea97;
  background-color: white;
  z-index: 5;
  user-select: none;
  cursor: grab;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  align-items: baseline;
  padding-left: 15px;
  animation-play-state: paused;
  animation: 0.9s ${slideAndFlash} ease-in-out;
`

const Legend = styled.p`
  margin-left: 1em;
`

const LegendContainer = styled.div`
  position: absolute;
  left: 50px;
  top: 30%;
  display: flex;
  flex-direction: column;
`

const DropZone = styled.div`
  margin: 8px;
  background-color: #8bea97;
  border-radius: 50%;
  width: 250px;
  height: 250px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;

  :nth-of-type(1n) {
    ${Item}:nth-of-type(2n) {
      position: relative;
      bottom: -55%;
    }
    ${Item}:nth-of-type(3n) {
      position: relative;
      top: 1%;
      left: 50%;
    }
  }

  :nth-of-type(2n) {
    background-color: #bef7c6;
    width: 450px;
    height: 450px;
    z-index: 2;
    ${Item}:nth-of-type(1n) {
      border-right: 5px solid #bef7c6;
    }
    ${Item}:nth-of-type(2n) {
      position: relative;
      bottom: -350px;
      border-right: 5px solid #bef7c6;
    }
    ${Item}:nth-of-type(3n) {
      position: relative;
      top: 2%;
      left: 50%;
      border-right: 5px solid #bef7c6;
    }
    ${Item}:nth-of-type(4n) {
      position: relative;
      right: 47%;
      top: -10%;
    }
    ${Item}:nth-of-type(5n) {
      position: relative;
      top: 2%;
      right: -50%;
    }
  }
  :nth-of-type(3n) {
    background-color: #e6ffe9;
    width: 650px;
    height: 650px;
    z-index: 1;
    ${Item}:nth-of-type(1n) {
      border-right: 5px solid #e6ffe9;
    }
    ${Item}:nth-of-type(2n) {
      border-right: 5px solid #e6ffe9;
      position: relative;
      bottom: -85%;
    }
    ${Item}:nth-of-type(3n) {
      border-right: 5px solid #e6ffe9;
      position: relative;
      top: 2%;
      left: 50%;
    }
    ${Item}:nth-of-type(4n) {
      border-right: 5px solid #e6ffe9;
      position: relative;
      top: 4%;
      left: -50%;
    }
    ${Item}:nth-of-type(5n) {
      border-right: 5px solid #e6ffe9;
      position: relative;
      top: 31%;
      left: -50%;
    }
  }
  :nth-of-type(4n) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    right: 0;
    height: 60vh;
    background-color: white;
    border-radius: 0;
    width: 210px;
    z-index: 1;
    box-shadow: 0 16px 40px rgba(0, 0, 0, 0.19), 0 0px 1px rgba(0, 0, 0, 0.23);
    ${Item}:nth-of-type(1n) {
      position: static;
    }
    ${Item}:nth-of-type(2n) {
      position: static;
    }
    ${Item}:nth-of-type(3n) {
      position: static;
    }
    ${Item}:nth-of-type(4n) {
      background-color: white;
      position: static;
    }
    ${Item}:nth-of-type(5n) {
      background-color: white;
      position: static;
    }
    ${Item}:nth-of-type(odd) {
      position: static;
    }
    ${Item}:nth-of-type(even) {
      position: static;
    }
  }
`

DragDrop.propTypes = {
  question: propTypes.instanceOf(Object).isRequired,
  currentAnswer: propTypes.instanceOf(Object).isRequired,
  updateCurrent: propTypes.func.isRequired,
}

export default DragDrop

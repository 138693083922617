/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components/macro'
import InputField from './InputField'
/**
 * Accepts an array, but are limited to 2 objects,
 * each 'Card' has the flexibility to render an image if needed,
 * image.src needs to be specified in question.props
 * @param {*} param
 */
const ImageChoice = ({
  question,
  choices,
  currentAnswer,
  updateCurrent,
  submitAnswer,
  settings,
}) => {
  const { id } = currentAnswer
  const { primaryColour } = settings

  const dynamicColour = !_.isUndefined(primaryColour) ? primaryColour : '#C4E8C5'
  return (
    <ButtonContainer>
      {choices.map(questionChoice => {
        if (questionChoice.type === 'input') {
          return (
            <InputField
              active={questionChoice.id === id}
              inputValue={currentAnswer.id === questionChoice.id ? [currentAnswer.id].value : ''}
              updateInputValue={e => {
                const commentBox = {
                  value: e.target.value,
                }

                const newAnswer = {
                  ...currentAnswer,
                  commentBox,
                }
                updateCurrent(newAnswer)
              }}
              label={questionChoice.text}
            />
          )
        }
        return (
          <Wrapper
            active={questionChoice.id === id}
            key={questionChoice.id}
            dynamicColour={dynamicColour}
          >
            <div className="svg-wrapper">
              <svg className="svg" version="1.1" id="tick" viewBox="6 5 26 26">
                <polyline
                  className="path"
                  points="
          11.6,20 15.9,24.2 26.4,13.8 "
                />
              </svg>
            </div>
            <Button
              type="button"
              key={questionChoice.id}
              onClick={() => {
                const answer = {
                  id: questionChoice.id,
                  value: questionChoice.value,
                }
                updateCurrent(answer)
                // eslint-disable-next-line no-unused-expressions
                question.noSkip === 'true' ? null : submitAnswer()
              }}
            >
              <img
                src={require(`../../assets/images/${questionChoice.image}`)}
                alt={questionChoice.text}
              />
              <p>{questionChoice.text}</p>
            </Button>
          </Wrapper>
        )
      })}
    </ButtonContainer>
  )
}

ImageChoice.propTypes = {
  question: PropTypes.instanceOf(Object),
  currentAnswer: PropTypes.instanceOf(Object).isRequired,
  updateCurrent: PropTypes.func.isRequired,
  submitAnswer: PropTypes.func.isRequired,
  choices: PropTypes.instanceOf(Array).isRequired,
  settings: PropTypes.instanceOf(Object),
}

const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 200px;
  cursor: pointer;
  font-weight: 400;
  border-radius: 6px;
  margin-top: 1rem;
  p {
    margin-top: 25px;
  }
  img {
    padding-top: 35px;
    width: 100px;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    height: 1rem;
    width: 1rem;
    img {
      padding-top: 0px;
      width: 1rem;
    }
  }
`

const ButtonContainer = styled.div`
  display: flex;
  height: auto;
  width: 800px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 0px) and (max-width: 479px) {
    flex-direction: row; /* not robust, use flex-wrap */
    width: 90vw;
    justify-content: center;
  }
  @media (min-width: 480px) and (max-width: 768px) {
    width: auto;
    flex-direction: row;
    padding-left: 3rem;
  }
  @media (min-width: 768px) and (max-width: 1025px) {
    width: auto;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-bottom: 1rem;
  .svg-wrapper {
    width: 200px;
    /* height: 25px; */
    display: inline-flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .svg {
    width: 30px;
  }
  .path {
    fill: transparent;
    stroke: ${props => (props.active ? props.dynamicColour : '53bf8b')};
    stroke-width: 2;
    stroke-dasharray: 25;
    stroke-dashoffset: ${props => (props.active ? 0 : 26)};
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dashoffset 250ms ease-in-out;
  }

  ${Button} {
    transition: background 250ms ease-in-out, transform 150ms ease;
    background-color: ${props => (props.active ? 'white' : 'white')};
    opacity: ${props => (props.active ? 1 : 0.45)};
    margin-right: 10rem;
    border-color: ${props => (props.active ? props.dynamicColour : '#ffffff00')};
    /* border-style: solid; */
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    &:hover {
      background-color: white;
      opacity: 1;
      border-color: ${props => props.dynamicColour};
      /* border-style: solid; */
      /* border-color: #c4e8c5; */
      /* border-width: 2px; */
      transform: scale(1.01);
    }
    @media (min-width: 0px) and (max-width: 479px) {
      height: auto;
      margin-right: 0;
      width: 2rem;
    }
    @media (min-width: 480px) and (max-width: 768px) {
      height: auto;
      margin-right: 4rem;
    }
    @media (min-width: 768px) and (max-width: 1025px) {
      margin-right: 5rem;
    }
  }
`

export default ImageChoice

import React from 'react'
import _ from 'lodash'
import { Doughnut } from 'react-chartjs-2'
import PropTypes from 'prop-types'
import 'chartjs-plugin-datalabels'

const DoughnutChart = props => {
  const { data } = props
  const myRef = React.createRef()
  const options = {
    plugins: {
      datalabels: {
        color: '#FFF',
        display: ctx => {
          return true
        },
        formatter: (ctx, chartData) => {
          const num = chartData.dataset.data[chartData.dataIndex]
          const total = _.sum(chartData.dataset.data)
          const numToPercentage = `${Math.floor((num / total) * 100)}%`
          return `${numToPercentage}`
        },
      },
    },
  }
  return <Doughnut data={data} ref={myRef} options={options} />
}

DoughnutChart.propTypes = {
  data: PropTypes.instanceOf(Object),
}

export default DoughnutChart

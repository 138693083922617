import React from 'react'
import * as firebase from 'firebase/app'
import {
  multiChoice,
  multiSelect,
  welcomePage,
  imageChoice,
  singleTextbox,
  responsiveMatrix,
} from '../question-types/QuestionTypes'

import E350Workshop from './E350Workshop'

const db = firebase.firestore()
const surveyData = db.collection('surveys').doc('e350-workshop')

const E350WorkshopGraphs = {
  questions: [
    {
      id: 'q1-graphs',
      type: welcomePage,
      firstQuestion: true,
      text: 'Characteristics that are most important when evaluating cross agency collaboration',
      output: {
        outputOnly: true,
        type: 'bar-chart',
        chartType: 'horizontal',
        // chartLabels: [
        //   ['Acceptance', 'of different', 'stakeholders’', 'objectives, opinions,'],
        //   ['Commitment', 'to collaboration'],
        //   ['Communication', 'between organisations'],
        //   'Creation of shared objectives',
        //   ['Development of', 'new collective knowledge'],
        //   [
        //     'Knowledge of particular',
        //     'people in other organisations',
        //     '(person to person connection)',
        //   ],
        //   ['Knowledge transfer', 'between organisations'],
        //   'Networks between organisations',
        //   'Opportunity for change',
        //   ['Resource sharing', 'between organisations'],
        //   'Trust within the partnership',
        //   ['Understanding of', 'of what other', 'organisations have to offer'],
        //   ['Additional', 'characteristic A'],
        //   ['Additional', 'characteristic B'],
        //   ['Additional', 'characteristic C'],
        //   ['Additional', 'characteristic D'],
        // ],
        xLabel: 'Count',
        smart: false,
        // yLabel: 'Count',
        usePercentage: false,
        displayLegend: false,
        targetQuestionType: multiSelect,
        targetSurveyQuestions: E350Workshop.questions,
        targetQuestionId: 'important characteristics',
        // nestedQuestionId: 'test 1',
        // compareQuestionId: 'after',
        surveyName: 'e350-workshop',
      },
    },
    {
      id: 'compare-results-before-after-e350',
      type: welcomePage,
      text: 'Aggregate results',
      output: {
        outputOnly: true,
        type: 'bar-chart',
        xLabel: 'Option',
        // chartLabels: [
        //   'Did not occur',
        //   'Rarely',
        //   'From time to time',
        //   'Regularly',
        //   'All the time',
        //   "Don't know",
        // ],
        smart: true,
        yLabel: 'Count',
        displayLegend: true,
        targetQuestionType: responsiveMatrix,
        targetSurveyQuestions: E350Workshop.questions,
        targetQuestionId: 'before',
        height: '600px',
        usePercentage: false,
        // nestedQuestionId: 'Characteristic one',
        compareQuestionId: 'after',
        surveyName: 'e350-workshop',
        asyncCategories: () => {
          return surveyData.get().then(doc => {
            if (doc.exists) {
              const results = doc.data().aggResults['important characteristics']
              const pairs = Object.keys(results).map(key => [key, results[key]])
              pairs.sort((a, b) => (b[1] - a[1] !== 0 ? b[1] - a[1] : b[0].localeCompare(a[0])))
              const finalResult = pairs.slice(0, 6).map(val => val[0])
              return finalResult
            }
            return []
          })
        },
      },
    },
    {
      id: 'end-page',
      type: welcomePage,
      text:
        'Which two of these areas should Extension 350 stakeholders focus on over the next few years?',
      output: {
        outputOnly: true,
        type: 'bar-chart',
        xLabel: 'Count',
        usePercentage: false,
        chartType: 'horizontal',
        maintainAspectRatio: false,
        responsive: true,
        // chartLabels: [
        //   'Characteristic one',
        //   'Characteristic two',
        //   'Characteristic three',
        //   'Characteristic four',
        //   'Characteristic five',
        //   'Characteristic six',
        // ],
        smart: false,
        yLabel: '',
        displayLegend: false,
        targetQuestionType: multiSelect,
        targetSurveyQuestions: E350Workshop.questions,
        targetQuestionId: 'e350-stakeholders',
        // nestedQuestionId: 'test 1',
        // compareQuestionId: 'after',
        surveyName: 'e350-workshop',
      },
    },
  ],
  settings: {
    surveyName: 'e350-workshop-presenters',
    surveyWidth: '1200px',
    primaryColour: '#48a54c',
    otherColour: '#72a174',
    chartColours: ['#008FFB', '#775DD0'],
    hideSubmit: true,
  },
}

export default E350WorkshopGraphs

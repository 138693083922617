import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/functions'
import firebaseConfig from './firebaseConfig'

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()

// const db = firebase.firestore()
// if (window.location.hostname === 'localhost') {
//   db.settings({
//     host: 'localhost:8080',
//     ssl: false,
//   })
// }

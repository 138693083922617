/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import styled from 'styled-components/macro'
import _ from 'lodash'
import PropTypes from 'prop-types'
import ButtonWrapper from '../ui/Buttons/StandardButton'
import { smButton, regButton, lgButton, xlButton } from '../ui/Layout/ButtonContainer'

/**
 * Simple input field that may be imported into any other question Component
 * @param {*} props
 */
const InputField = props => {
  const {
    inputValue,
    updateInputValue,
    label,
    placeholder,
    settings,
    type,
    size,
    marginBottom,
  } = props
  const { primaryColour } = settings
  const dynamicColour = !_.isUndefined(primaryColour) ? primaryColour : '#E82D7C'
  console.log('size from inputfield', size)
  return (
    // <InputWrapper>
    <Input
      style={{ marginBottom: marginBottom }}
      dynamicColour={dynamicColour}
      settings={settings}
      size={size ? size : regButton.width}
    >
      <input
        type={type ? type : 'text'}
        placeholder={placeholder}
        value={inputValue}
        onChange={e => updateInputValue(e)}
      />
      <hr />
      <label>{label}</label>
    </Input>
    // </InputWrapper>
  )
}

InputField.propTypes = {
  inputValue: PropTypes.string,
  updateInputValue: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  settings: PropTypes.instanceOf(Object),
}

// const InputWrapper = styled.div`
//   width: auto;
//   height: 4.125rem;
// `

const Input = styled.fieldset`
  height: 3.125rem;
  /* padding-left: 1rem;
  padding-right: 1rem; */
  width: ${props => props.size};
  /* width: auto; */
  /* height: inherit; */
  position: relative;
  padding: 0;
  border: none;
  overflow: visible;
  margin-right: 0.5rem;

  @media (min-width: 0px) and (max-width: 479px) {
    width: 200px;
  }

  input {
    box-sizing: border-box;
    width: inherit;
    height: inherit;
    opacity: 0.45;
    border-radius: 3px;
    padding: 12px 10px 8px;
    box-shadow: none;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.65);
    font-size: 120%;
    outline: none;
    cursor: text;
    &:focus {
      opacity: 1;
    }

    &::-moz-placeholder {
      transition: color 300ms ease;
    }
    &:not(:focus)::-moz-input-placeholder {
      color: transparent;
    }

    &::-webkit-input-placeholder {
      transition: color 300ms ease;
    }
    &:not(:focus)::-webkit-input-placeholder {
      color: transparent;
    }
  }

  hr {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: inherit;
    height: 2px;
    border: none;
    background: ${props => props.dynamicColour};
    font-size: 1px;
    will-change: transform, visibility;
    transition: all 200ms ease-out;
    transform: scaleX(0);
    visibility: hidden;
    z-index: 10;
  }
  input:focus ~ hr {
    transform: scaleX(1);
    visibility: visible;
  }

  label {
    position: absolute;
    top: 20px;
    width: 155%;
    left: 10px;
    font-size: 160%;
    color: rgb(84, 110, 123);
    transform-origin: 0 -150%;
    transition: transform 300ms ease;
    pointer-events: none;
    /* opacity: 0.45; */
  }
  input:focus ~ label,
  input:valid ~ label {
    transform: scale(0.6);
    /* opacity: 1; */
  }
  @media (min-width: 0px) and (max-width: 479px) {
    /* fieldset */
    width: 100%;
    margin-bottom: 1rem;
    margin-right: 0px;

    input {
      width: 100%;
      height: 3.125rem;
    }
  }
  @media (min-width: 480px) and (max-width: 768px) {
    width: auto;
    /* justify-content: center; */
  }
`

export default InputField

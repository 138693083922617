import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import InputField from './InputField'
/**
 * Stand alone input component
 * @param {*} param
 */
const SingleTextbox = ({ question, currentAnswer, updateCurrent, settings }) => {
  const { value } = currentAnswer
  console.log('settings from singleText', settings)
  return (
    <Container>
      <InputField
        type={question.inputType}
        key={question.id}
        inputValue={currentAnswer.id === question.id ? value : ''}
        updateInputValue={e =>
          updateCurrent({ id: question.id, value: e.target.value, questionType: question.type })
        }
        label={question.label}
        placeholder={question.placeholder}
        settings={settings}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 800px;
  height: auto;
  align-items: baseline;
  @media (min-width: 0px) and (max-width: 479px) {
    height: 50vh;
    width: 80vw;
  }
`

SingleTextbox.propTypes = {
  currentAnswer: PropTypes.instanceOf(Object).isRequired,
  updateCurrent: PropTypes.func.isRequired,
  settings: PropTypes.instanceOf(Object),
  question: PropTypes.instanceOf(Object).isRequired,
}

export default SingleTextbox

import React, { useEffect } from 'react'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import PropTypes from 'prop-types'
import reducer from './reducers'
import { store } from '../store'
import Survey from '../surveys/Survey'
import { useMediaQuery } from 'beautiful-react-hooks'
import ErrorPage from '../question-types/ErrorPage'

const SurveyWrapper = ({ questions, settings }) => {
  const mobile = useMediaQuery('(max-width: 900px)')
  const desktop = useMediaQuery('(min-width: 901px)')

  console.log('store', store)

  return (
    <Provider store={store}>
      {store.getState().error === 'error' ? (
        <ErrorPage />
      ) : (
        <Survey
          questions={questions}
          settings={settings}
          currentDevice={mobile ? 'mobile' : 'desktop'}
        />
      )}
    </Provider>
  )
}

SurveyWrapper.propTypes = {
  questions: PropTypes.instanceOf(Object).isRequired,
  settings: PropTypes.instanceOf(Object).isRequired,
}

export default SurveyWrapper

/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import SurveyWrapper from '../reducers/SurveyWrapper'
import { endLoop } from '../reducers/actions'
import backgroundImage from '../../assets/images/PIAS/PIAS.jpg'
import MPILogo from '../../assets/images/PIAS/MPI_logo.png'
import testImage from '../../assets/images/CNA/agree_green.svg'
import beefImage from '../../assets/images/PIAS/beef_nobg.svg'
import dairyImage from '../../assets/images/PIAS/dairy_nobg.svg'
import fisheriesImage from '../../assets/images/PIAS/fisheries_nobg.svg'
import forestryImage from '../../assets/images/PIAS/forestry.svg'
import horticultureImage from '../../assets/images/PIAS/horticulture_nobg.svg'

import {
  responsiveMatrix,
  responsiveSlider,
  multiselectDropdown,
  dragDrop,
  matrix,
  multiChoice,
  multiSelect,
  section,
  endSurvey,
  singleTextbox,
  autoComplete,
  slider,
  multiCardChoice,
  imageChoice,
  welcomePage,
  button,
  input,
  multiDropdown,
  socialNetwork,
} from '../question-types/QuestionTypes'
import companies from '../../assets/data/PIAS/companies'

import { SmallText, Textbox } from '../../assets/styles/global'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const GreenText = styled.p`
  font-size: 1rem;
  color: #8cc63f;
  font-weight: bold;
`

const SectionInfo = styled.p`
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #9b9b9b;
`

const YellowText = styled.p`
  font-size: 1rem;
  color: #eaae19;
  font-weight: bold;
`

const RedText = styled.p`
  font-size: 1rem;
  color: #b74425;
  font-weight: bold;
`

const BlueText = styled.p`
  font-size: 1rem;
  color: #00829b;
  font-weight: bold;
`

const DarkGreenText = styled.p`
  font-size: 1rem;
  color: #4a6f2f;
  font-weight: bold;
`

const StyledP = styled.p`
  font-size: 20px;
`

const Container = styled.div`
  background-color: red;
  height: 100vh;
  width: 100vw;
`

const Pias = {
  questions: [
    {
      id: 0,
      type: welcomePage,
      text: (
        <Textbox>
          <h1>How can we best support the primary industry?</h1>
          <StyledP>
            The challenges of 2020 have proven just how valuable New Zealand’s primary sector is,
            and will be in the future. This government want to do all they can to support the
            industry, including those who provide advice, business services, and research to
            producers working on the ground.
          </StyledP>
          <StyledP>
            If you <b>advise, research, or provide services</b> to primary industry producers in
            some shape or form, please take this survey to help us understand your skills,
            frustrations, and ideas around how the advisory system can be improved.
          </StyledP>
          <SmallText>This should only take 10-15 mins to complete</SmallText>
          <SmallText>
            Be assured that all answers you provide will be kept in the strictest confidentiality.
          </SmallText>
          <SmallText>Please click Next to begin</SmallText>
        </Textbox>
      ),
    },
    {
      id: 1,
      section: (
        <Wrapper>
          <GreenText>About You</GreenText>
          <SectionInfo>Question 1 / 5</SectionInfo>
        </Wrapper>
      ),
      options: companies,
      type: autoComplete,
      text: 'Which organisation do you currently work for?',
    },
    {
      id: 2,
      section: (
        <Wrapper>
          <GreenText>About You</GreenText>
          <SectionInfo>Question 2 / 5</SectionInfo>
        </Wrapper>
      ),
      type: multiChoice,
      text: 'Which of the below best describes your role?',
      choices: [
        {
          id: 1,
          text: 'Advisor / consultor',
          value: 'Advisor / consultor',
          tooltip:
            'Your main role is to provide independent advice to producers (which is probably paid for).',
        },
        {
          id: 2,
          text: 'Service provider',
          value: 'Service provider',
          tooltip: 'You are paid for a service other than providing advice.',
        },
        {
          id: 3,
          text: 'Retailer / wholesaler',
          value: 'Retailer / wholesaler',
          tooltip: 'Your main job is to sell a product used in the primary industries.',
        },
        {
          id: 4,
          text: 'Researcher',
          value: 'Researcher',
          tooltip:
            'You generate research that is used by producers, government, and other primary industry groups. ',
        },
        {
          id: 5,
          text: 'Developer',
          value: 'Developer',
          tooltip:
            'Your role is to develop training or extension programmes, tools or software products for the industry.',
        },
        {
          id: 6,
          text: 'Educator',
          value: 'Educator',
          tooltip: 'Your role is to deliver the training teach and educate producers. ',
        },
        {
          id: 7,
          text: 'Processor / exportor',
          value: 'Processor / exportor',
          tooltip:
            'your role is involved with the supply of produce and establishing connections across the value chain.',
        },
        { id: 8, text: 'Other, please specify', value: 'other', type: input, tooltip: ' ' },
        {
          id: 9,
          text: 'My role is not related to the primary industry',
          value: 'My role is not related to the primary industry',
          tooltip: ' ',
        },
      ],
    },
    {
      id: 3,
      section: (
        <Wrapper>
          <GreenText>About You</GreenText>
          <SectionInfo>Question 3 / 5</SectionInfo>
        </Wrapper>
      ),
      type: multiChoice,
      text: 'Which gender do you identify as?',
      choices: [
        { id: 1, text: 'Male', value: 'Male' },
        { id: 2, text: 'Female', value: 'Female' },
        { id: 3, text: 'Other', value: 'Other' },
        { id: 4, text: 'Prefer not to answer', value: 'Prefer not to answer' },
      ],
    },
    {
      id: 4,
      section: (
        <Wrapper>
          <GreenText>About You</GreenText>
          <SectionInfo>Question 4 / 5</SectionInfo>
        </Wrapper>
      ),
      type: multiSelect,
      text: 'Which ethnic group(s) do you belong to?',
      choices: [
        { id: 1, text: 'New Zealand European', value: 'New Zealand European' },
        { id: 2, text: 'Maori', value: 'Maori' },
        { id: 3, text: 'Pacific peoples', value: 'Pacific peoples' },
        {
          id: 4,
          text: 'Middle Eastern / Latin American / African',
          value: 'Middle Eastern / Latin American / African',
        },
        {
          id: 5,
          text: 'Asian',
          value: 'Asian',
        },
        { id: 6, text: 'Other, please specify', value: 'other', type: input },
        { id: 7, text: 'Prefer not to answer', value: 'Prefer not to answer' },
      ],
    },
    {
      id: 5,
      section: (
        <Wrapper>
          <GreenText>About You</GreenText>
          <SectionInfo>Question 5 / 5</SectionInfo>
        </Wrapper>
      ),
      type: multiChoice,
      text: 'What is your age?',
      choices: [
        { id: 1, text: '18-24 years old', value: '18-24 years old' },
        { id: 2, text: '25-34 years old', value: '25-34 years old' },
        { id: 3, text: '35-44 years old', value: '35-44 years old' },
        { id: 4, text: '45-54 years old', value: '45-54 years old' },
        { id: 5, text: '55-64 years old', value: '55-64 years old' },
        { id: 6, text: '65 years or older', value: '65 years or older' },
        { id: 7, text: 'Prefer not to answer', value: 'Prefer not to answer' },
      ],
    },
    {
      id: 6,
      section: ({ answers }) => {
        const selectedRole = answers[2].value
        return (
          <Wrapper>
            <RedText>
              Your role as a <b>{selectedRole}</b>
            </RedText>
            <SectionInfo>Question 1 / 4</SectionInfo>
          </Wrapper>
        )
      },
      type: multiChoice,
      header: ({ answers }) => {
        const selectedRole = answers[2].value
        return (
          <Textbox>
            <h3>
              Now we would like to understand a bit more about your role in the primary sector
            </h3>
            <p>
              You said that you would be describe your self as an <b>{selectedRole}</b>
            </p>
          </Textbox>
        )
      },
      statement: ({ answers }) => {
        const possibleAnswer = answers[2].id
        let output
        if (possibleAnswer === 1) {
          output = 'What do you specifically Advise / Consult on?'
        }

        if (possibleAnswer === 2) {
          output = 'What kind of service do you specifically provide?'
        }

        if (possibleAnswer === 3) {
          output = 'Who do you specifically sell to?'
        }

        if (possibleAnswer === 4) {
          output = 'What do you specifically conduct research on?'
        }

        if (possibleAnswer === 5) {
          output = 'Who do you specifically develop products for?'
        }

        if (possibleAnswer === 6) {
          output = 'What do you specifically communicate on?'
        }

        if (possibleAnswer === 7) {
          output = 'Who do you specifically market for?'
        }

        if (possibleAnswer === 8 || possibleAnswer === 9) {
          output = 'Where do most of responsibilities lie?'
        }

        return (
          <Textbox>
            <p>{output}</p>
          </Textbox>
        )
      },
      choices: [
        { id: 1, text: 'Whole business system', value: 'Whole business system' },
        { id: 2, text: 'People and team management', value: 'People and team management' },
        { id: 3, text: 'Environmental management', value: 'Environmental management' },
        { id: 4, text: 'Animal Care', value: 'Animal Care' },
        { id: 5, text: 'Nutrients', value: 'Nutrients' },
        { id: 6, text: 'Fertiliser', value: 'Fertiliser' },
        { id: 7, text: 'Other, please specify', value: 'other', type: input },
      ],
    },
    {
      id: 7,
      section: ({ answers }) => {
        const selectedRole = answers[2].value
        return (
          <Wrapper>
            <RedText>
              Your role as a <b>{selectedRole}</b>
            </RedText>
            <SectionInfo>Question 2 / 4</SectionInfo>
          </Wrapper>
        )
      },
      type: multiChoice,
      text: 'How often do you talk to producers?',
      choices: [
        {
          id: 1,
          text: 'Daily - at least three times a week',
          value: 'Daily - at least three times a week',
        },
        {
          id: 2,
          text: 'Weekly - once or twice a week',
          value: 'Weekly - once or twice a week',
        },
        {
          id: 3,
          text: 'Monthly - only a few times a month',
          value: 'Monthly - only a few times a month',
        },
        {
          id: 4,
          text: 'Yearly - only a few times a year',
          value: 'Yearly - only a few times a year',
        },
        {
          id: 5,
          text: 'Never - very rarely',
          value: 'Never - very rarely',
        },
      ],
    },
    {
      id: 8,
      section: ({ answers }) => {
        const selectedRole = answers[2].value
        return (
          <Wrapper>
            <RedText>
              Your role as a <b>{selectedRole}</b>
            </RedText>
            <SectionInfo>Question 3 / 4</SectionInfo>
          </Wrapper>
        )
      },
      type: 'multiSelectMap',
      text: 'Which region(s) do you mainly work in?',
    },
    {
      id: 9,
      section: ({ answers }) => {
        const selectedRole = answers[2].value
        return (
          <Wrapper>
            <RedText>
              Your role as a <b>{selectedRole}</b>
            </RedText>
            <SectionInfo>Question 4 / 4</SectionInfo>
          </Wrapper>
        )
      },
      type: multiCardChoice,
      header: (
        <Textbox>
          <h4>This question may be moved</h4>
        </Textbox>
      ),
      text: 'Which primary sector(s) do you work in?',
      choices: [
        { id: 1, text: 'Dairy', value: 'Dairy', image: dairyImage },
        { id: 2, text: 'Beef & lamb', value: 'Beef & lamb', image: beefImage },
        { id: 3, text: 'Forestry', value: 'Forestry', image: forestryImage },
        { id: 4, text: 'Horticulture', value: 'Horticulture', image: horticultureImage },
        { id: 5, text: 'Fisheries', value: 'Fisheries', image: fisheriesImage },
        { id: 6, text: 'Other, please specify', value: 'other', type: input },
      ],
      next: ({ answers }) => {
        if (answers[7].id === 1 || answers[7].id === 2 || answers[7].id === 3) {
          return 10
        }
        return 13
      },
    },
    {
      id: 10,
      section: (
        <Wrapper>
          <YellowText>Producer-facing capability and capacity</YellowText>
        </Wrapper>
      ),
      text: (
        <div>
          <p>
            Now we are interested in understanding your capabilities in specific areas. This will
            help us identify where there is strong capability already, and where we should be
            focusing extra support.
          </p>
          <p>
            <b>Productive land use </b>refers to supporting producers with land use practices that
            are profitable and deliver value.
          </p>
          <p>
            <b>Sustainable land use</b> refers to supporting producers with land use practices that
            improve environmental outcomes.
          </p>
          <p>
            <b>Changing land use</b> refers to supporting producers change land use and manage its
            impact in a just and sustainable way.
          </p>
        </div>
      ),
      type: section,
    },
    {
      id: 11,
      section: (
        <Wrapper>
          <YellowText>Producer-facing capability and capacity</YellowText>
          <SectionInfo>Question 1 / 2</SectionInfo>
        </Wrapper>
      ),
      text: (
        <p>
          How <b>often</b> do you provide advice:
        </p>
      ),
      options: [
        {
          id: 1,
          value: 'Never',
        },
        {
          id: 2,
          value: 'Very rarely',
        },
        {
          id: 3,
          value: 'Ocassionally',
        },
        { id: 4, value: 'Often' },
        { id: 5, value: 'All the time - this is a core part of my role' },
      ],
      choices: [
        {
          id: 1,
          text: 'on productive land and water use',
          value: 'on productive land and water use',
          // labels: [
          //   { id: 1, text: 'Understand the enviromental impacts of my farm.' },
          //   { id: 2, text: 'Understand the obligations to reduce our enviromental impacts.' },
          //   { id: 3, text: 'Know how to reduce our impacts.' },
          // ],
        },
        {
          id: 2,
          text: 'on sustainable land and water use',
          value: 'on sustainable land and water use',
          // labels: [
          //   { id: 1, text: 'Know how to monitor my farms cash flow and performance.' },
          //   { id: 2, text: 'Know how to manage business risk.' },
          // ],
        },
        {
          id: 3,
          text: 'on changing land use',
          value: 'on changing land use',
          // labels: [
          //   { id: 1, text: 'Know my legal responsibility around people.' },
          //   { id: 2, text: 'Know how to create a positive team culture.' },
          //   { id: 3, text: 'Practice good team communication.' },
          // ],
        },
        {
          id: 4,
          text: 'on whole farm systems(including business planning)',
          value: 'on whole farm systems(including business planning)',
          // labels: [
          //   { id: 1, text: 'Understand methods of breeding cows for herd improvement.' },
          //   {
          //     id: 2,
          //     text: 'Know how to monitor the breeding plan to meet business performance targets.',
          //   },
          // ],
        },
        {
          id: 5,
          text: 'to Maori',
          value: 'to Maori',
          // labels: [
          //   { id: 1, text: 'Understand how to calculate the feed budget fpr the farm.' },
          //   { id: 2, text: 'Know how to adjust the feed budget to maximise performance.' },
          //   { id: 3, text: 'Keep up to date with feed research.' },
          // ],
        },
      ],
      type: responsiveMatrix,
    },
    {
      id: 12,
      section: (
        <Wrapper>
          <YellowText>Producer-facing capability and capacity</YellowText>
          <SectionInfo>Question 2 / 2</SectionInfo>
        </Wrapper>
      ),
      text: (
        <p>
          How would you rate your <b>expertise</b>(including knowledge of these issues and practical
          tips for producers) relating to:
        </p>
      ),
      options: [
        {
          id: 1,
          value: 'I know very little about this',
        },
        {
          id: 2,
          value: 'I know the basics',
        },
        {
          id: 3,
          value: 'About average',
        },
        { id: 4, value: 'I could do with a bit of up skilling' },
        { id: 5, value: 'I know all there is to know' },
      ],
      choices: [
        {
          id: 1,
          text: 'Productive land and water use',
          value: 'Productive land and water use',
          // labels: [
          //   { id: 1, text: 'Understand the enviromental impacts of my farm.' },
          //   { id: 2, text: 'Understand the obligations to reduce our enviromental impacts.' },
          //   { id: 3, text: 'Know how to reduce our impacts.' },
          // ],
        },
        {
          id: 2,
          text: 'Sustainable land and water use',
          value: 'Sustainable land and water use',
          // labels: [
          //   { id: 1, text: 'Know how to monitor my farms cash flow and performance.' },
          //   { id: 2, text: 'Know how to manage business risk.' },
          // ],
        },
        {
          id: 3,
          text: 'Changing land use',
          value: 'Changing land use',
          // labels: [
          //   { id: 1, text: 'Know my legal responsibility around people.' },
          //   { id: 2, text: 'Know how to create a positive team culture.' },
          //   { id: 3, text: 'Practice good team communication.' },
          // ],
        },
        {
          id: 4,
          text: 'Whole farm systems(including business planning)',
          value: 'Whole farm systems(including business planning)',
          // labels: [
          //   { id: 1, text: 'Understand methods of breeding cows for herd improvement.' },
          //   {
          //     id: 2,
          //     text: 'Know how to monitor the breeding plan to meet business performance targets.',
          //   },
          // ],
        },
        {
          id: 5,
          text: 'Working with Maori',
          value: 'Working with Maori',
          // labels: [
          //   { id: 1, text: 'Understand how to calculate the feed budget fpr the farm.' },
          //   { id: 2, text: 'Know how to adjust the feed budget to maximise performance.' },
          //   { id: 3, text: 'Keep up to date with feed research.' },
          // ],
        },
      ],
      type: responsiveMatrix,
    },
    {
      id: 13,
      type: socialNetwork,
      section: (
        <div>
          <Wrapper>
            <GreenText>Who do you talk to?</GreenText>
            <SectionInfo>Question 1 / 1</SectionInfo>
          </Wrapper>
          <div>
            Think back to all the other advisors in your network that you have spoken to in the last
            year.
            <p>
              Who have you spoken to <b>Most frequently </b> about
              <ul>
                <li>Productive land use?</li>
                <li>Sustainable land use?</li>
                <li>Changing land use?</li>
              </ul>
            </p>
            <p>
              <b>Productive land use</b> refers to supporting producers with land use practices that
              are profitable and deliver value.
            </p>
            <p>
              <b>Sustainable land use</b> refers to supporting producers with land use practices
              that improve environmental outcomes.
            </p>
            <p>
              <b>Changing land use </b>refers to supporting producers change land use and manage its
              impact in a just and sustainable way.
            </p>
          </div>
        </div>
      ),
      socialConnections: [],
      contactFrequency: ['Weekly', 'Monthly', 'Yearly'],
      categories: ['Productive land use', 'Sustainable land use', 'Changing land use'],
      listOfCompanies: companies,
    },
    // {
    //   id: 14,
    //   section: (
    //     <Wrapper>
    //       <YellowText>Roles and responsibilites</YellowText>
    //       <SectionInfo>Question 1 / 1</SectionInfo>
    //     </Wrapper>
    //   ),
    //   type: multiselectDropdown,
    //   text: 'Whoses reponsibility do you think it is to:',
    //   options: [
    //     {
    //       id: 1,
    //       value: 'Independent advisors & consultants',
    //     },
    //     { id: 2, value: 'Business services & sales' },
    //     { id: 3, value: 'Industry good organisations' },
    //     {
    //       id: 4,
    //       value: 'Public good / non-government organisations',
    //     },
    //     { id: 5, value: 'Government' },
    //     { id: 6, value: 'Processors and exporters' },
    //   ],
    //   choices: [
    //     {
    //       id: 1,
    //       value: 'Inform producers about the range of sustainability practices',
    //       text: 'Inform producers about the range of sustainability practices',
    //     },
    //     {
    //       id: 2,
    //       value: 'Inform producers about enviromental management',
    //       text: 'Inform producers about enviromental management',
    //     },
    //     {
    //       id: 3,
    //       value: 'Provide mentoring services to new adviors',
    //       text: 'Provide mentoring services to new adviors',
    //     },
    //     {
    //       id: 4,
    //       value: 'Enforce standards',
    //       text: 'Enforce standards',
    //     },
    //   ],
    // },
    {
      id: 14,
      section: (
        <Wrapper>
          <BlueText>Career pathways and advisor development</BlueText>
        </Wrapper>
      ),
      type: section,
      statement: ({ answers }) => {
        return (
          <Textbox>
            <h4>
              This section is about the career pathways and opportunities to develop as an advior.
            </h4>
            <p>
              We are intested in understand how you got to where you are, and how this pathway could
              be improved for the next generation of advisors
            </p>
          </Textbox>
        )
      },
    },
    {
      id: 15,
      section: (
        <Wrapper>
          <BlueText>Career pathways and advisor development</BlueText>
          <SectionInfo>Question 1 / 8</SectionInfo>
        </Wrapper>
      ),
      type: multiChoice,
      text: 'Roughly how long have you been working in the primary sector for?',
      choices: [
        { id: 1, text: '0-5 years', value: '0-5 years' },
        { id: 2, text: '6-10 years', value: '6-10 years' },
        { id: 3, text: '11-20 years', value: '11-20 years' },
        { id: 4, text: '20+ years', value: '20+ years' },
      ],
      next: ({ answers }) => {
        const prevAnswer = answers[15].id
        if (prevAnswer === 1) {
          return 16
        }
        return 17
      },
    },
    {
      id: 16,
      section: (
        <Wrapper>
          <BlueText>Career pathways and advisor development</BlueText>
          <SectionInfo>Question 2 / 8</SectionInfo>
        </Wrapper>
      ),
      type: multiChoice,
      text: 'And how many years in an advisory capacity?',
      choices: [{ id: 1, text: '0-5 years', value: '0-5 years' }],
    },
    {
      id: 17,
      section: (
        <Wrapper>
          <BlueText>Career pathways and advisor development</BlueText>
          <SectionInfo>Question 3 / 8</SectionInfo>
        </Wrapper>
      ),
      type: multiChoice,
      text: 'What were you doing before this?',
      choices: [
        { id: 1, text: 'Straight out of school', value: 'Straight out of school' },
        { id: 2, text: 'Tertiary education', value: 'Tertiary education' },
        { id: 3, text: 'Working in another sector, please specify', value: 'other', type: input },
      ],
      next: ({ answers }) => {
        const prevAnswer = answers[17].id
        if (prevAnswer === 1) {
          return 18
        }
        if (prevAnswer === 2) {
          return 19
        }
        return 20
      },
    },
    {
      id: 18,
      section: (
        <Wrapper>
          <BlueText>Career pathways and advisor development</BlueText>
          <SectionInfo>Question 4 / 8</SectionInfo>
        </Wrapper>
      ),
      type: multiChoice,
      text: 'What is the highest level of education you have completed?',
      choices: [
        { id: 1, text: 'Secondary school certifcate', value: 'Secondary school certifcate' },
        {
          id: 2,
          text: 'Post-secondary school certificate or diploma',
          value: 'Post-secondary school certificate or diploma',
        },
        { id: 3, text: 'Bachelor degree', value: 'Bachelor degree' },
        {
          id: 4,
          text: 'Post-graduate and honours degrees',
          value: 'Post-graduate and honours degrees',
        },
        { id: 5, text: 'Masters degree', value: 'Masters degree' },
        { id: 6, text: 'Other, please specify', value: 'other', type: input },
        { id: 7, text: 'No qualification', value: 'No qualification' },
      ],
      next: ({ answers }) => {
        const prevAnswer = answers[18].id
        if (prevAnswer === 3 || prevAnswer === 4 || prevAnswer === 5) {
          return 19
        }
        return 20
      },
    },
    {
      id: 19,
      section: (
        <Wrapper>
          <BlueText>Career pathways and advisor development</BlueText>
          <SectionInfo>Question 5 / 8</SectionInfo>
        </Wrapper>
      ),
      type: multiSelect,
      text: 'What was your degree(s) in?',
      choices: [
        { id: 1, text: 'B.AgSci', value: 'B.AgSci' },
        { id: 2, text: 'B.AgCom', value: 'B.AgCom' },
        { id: 3, text: 'B.AgTech', value: 'B.AgTech' },
        { id: 4, text: 'B.HortSci', value: 'B.HortSci' },
        { id: 5, text: 'B.EnvSci', value: 'B.EnvSci' },
        { id: 6, text: 'Other, please specify', value: 'other', type: input },
      ],
    },
    {
      id: 20,
      section: (
        <Wrapper>
          <BlueText>Career pathways and advisor development</BlueText>
          <SectionInfo>Question 6 / 8</SectionInfo>
        </Wrapper>
      ),
      type: multiChoice,
      text: 'What (if any) type of upskilling have you done while working as an advisor?',
      choices: [
        { id: 1, text: 'On-job training', value: 'On-job training' },
        { id: 2, text: 'Mentoring', value: 'Mentoring' },
        { id: 3, text: 'Reading resources / books', value: 'Reading resources / books' },
        {
          id: 4,
          text: 'Nothing, just learn on the job',
          value: 'Nothing, just learn on the job',
        },
      ],
    },
    {
      id: 21,
      section: (
        <Wrapper>
          <BlueText>Career pathways and advisor development</BlueText>
          <SectionInfo>Question 7 / 8</SectionInfo>
        </Wrapper>
      ),
      type: multiChoice,
      text: 'What has been the single most useful thing in developing your skills as an advisor?',
      choices: [
        { id: 1, text: 'Formal qualification', value: 'Formal qualification' },
        {
          id: 2,
          text: 'Years of experience in the industry',
          value: 'Years of experience in the industry',
        },
        {
          id: 3,
          text: 'Mentoring from another advisor',
          value: 'Mentoring from another advisor',
        },
      ],
    },
    {
      id: 22,
      section: (
        <Wrapper>
          <BlueText>Career pathways</BlueText>
          <SectionInfo>Question 8 / 8</SectionInfo>
        </Wrapper>
      ),
      type: multiChoice,
      text:
        'What is the one thing government could introduce that would be most beneficial in supporting new advisors?',
      choices: [
        { id: 1, text: 'A structured mentoring scheme', value: 'A structured mentoring scheme' },
        {
          id: 2,
          text: 'A new qualifcation specialising in rural advisory services',
          value: 'A new qualifcation specialising in rural advisory services',
        },
        {
          id: 3,
          text: 'Short courses teaching core advisory skills',
          value: 'Short courses teaching core advisory skills',
        },
        { id: 4, text: 'Other, please specify', value: 'other', type: input },
      ],
    },
    {
      id: 23,
      section: <DarkGreenText>Oppotunities & Barriers</DarkGreenText>,
      type: multiChoice,
      text: 'What do you think is the biggest issue for the advisory system at the moment?',
      choices: [
        {
          id: 1,
          text: 'Not regulated enough - different standards',
          value: 'Not regulated enough - different standards',
        },
        {
          id: 2,
          text: 'Conflicting messages from advisors in terms of sustantainability',
          value: 'Conflicting messages from advisors in terms of sustantainability',
        },
        {
          id: 3,
          text: 'Not enough supply of advisors',
          value: 'Not enough supply of advisors',
        },
        {
          id: 4,
          text: 'Not enough demand for advisors',
          value: 'Not enough demand for advisors',
        },
        { id: 5, text: 'Other, please specify', value: 'other', type: input },
      ],
      next: ({ answers }) => {
        const prevAnswer = answers[23].id
        if (prevAnswer === 1) {
          return 24
        }
        return 25
      },
    },
    {
      id: 24,
      section: <DarkGreenText>Oppotunities & Barriers</DarkGreenText>,
      type: multiChoice,
      text: 'What is the one thing government could do to address this issue?',
      choices: [
        {
          id: 1,
          text: 'Introduce formal registration scheme',
          value: 'Introduce formal registration scheme',
        },
        { id: 5, text: 'Other, please specify', value: 'other', type: input },
      ],
    },
    {
      id: 25,
      type: endSurvey,
    },
    {
      id: 26,
      text: 'The end.',
      type: 'end',
    },
  ],
  settings: {
    writeToFirestore: false,
    surveyName: 'old-pias',
    fullLength: true,
    companyLogo: MPILogo,
    colors: ['#00829B', '#EAAE19', '#8CC63F', '#B74425', '#4A6F2F'],
    progressBar: true,
    sections: [
      { numOfQuestions: 10, name: 'Advisors', startQId: 0, endQId: 10 },
      {
        numOfQuestions: 11,
        name: 'Advisory system challenges & opportunities',
        startQId: 10,
        endQId: 21,
      },
    ],
    backgroundImage,
  },
}

export default Pias

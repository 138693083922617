// if (typeof choices === 'function') {
//   dynamicChoices = choices(answers, loopIndex)
// } else {
//   dynamicChoices = choices
// }

export const parseOnlyLetterAndSpace = expression => expression.replace(/[^A-ZA-z]/g, '')
export const parseLength = (expression, length) => expression.substring(0, length)
export const checkAtLength = (expression, length) =>
  expression && expression.trim().length <= length
export const checkIsFilled = expression => expression
export const checkIsTrue = expression => expression
export const checkEmailPattern = mail => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(mail)
}

export const calcTime = (subStarted, subEnded) => {
  const submissionStarted = new Date(subStarted)
  const submissionEnded = new Date(subEnded)
  // const timeDiff = submissionEnded.getTime() - submissionStarted.getTime()
  const timeTaken = submissionEnded.getTime() - submissionStarted.getTime()

  // let msec = timeDiff
  // const hh = Math.floor(msec / 1000 / 60 / 60)
  // msec -= hh * 1000 * 60 * 60
  // const mm = Math.floor(msec / 1000 / 60)
  // msec -= mm * 1000 * 60
  // const ss = Math.floor(msec / 1000)
  // msec -= ss * 1000

  // const timeTaken = hh + ':' + mm + ':' + ss
  // return timeDiff / 1000
  return timeTaken / 1000
}

export const getUserDeviceType = () => {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet'
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua,
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}

/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { Divider, Collapse } from 'antd'
import _, { update } from 'lodash'

import {
  responsiveMatrix,
  responsiveSlider,
  multiselectDropdown,
  dragDrop,
  matrix,
  multiChoice,
  multiSelect,
  section,
  endSurvey,
  singleTextbox,
  autoComplete,
  slider,
  multiCardChoice,
  imageChoice,
  inputField,
  welcomePage,
  endPage,
  button,
  input,
  multiDropdown,
  socialNetwork,
  commentBox,
} from '../question-types/QuestionTypes'
import companies from '../../assets/data/PIAS/companies'
import companiesInfo from '../../assets/data/PIAS/companies-info'
import updatedCompanies from '../../assets/data/PIAS/companies_updated'
import { ReactComponent as SadFace } from '../../assets/images/SadFace.svg'
import { ReactComponent as Check } from '../../assets/images/HCF/check-icon.svg'
import { ReactComponent as Cross } from '../../assets/images/HCF/cross-icon.svg'
import { ReactComponent as Happy } from '../../assets/images/Scarlatti/Happy Face.svg'
import { ReactComponent as Ok } from '../../assets/images/Scarlatti/Ok face.svg'
import { ReactComponent as Sad } from '../../assets/images/Scarlatti/Sad Face.svg'
import { ReactComponent as VeryHappy } from '../../assets/images/Scarlatti/Very happy Face.svg'
import { ReactComponent as VerySad } from '../../assets/images/Scarlatti/Very sadFace 1.svg'
import { smButton, regButton, lgButton, xlButton } from '../ui/Layout/ButtonContainer'

import { SmallText, Textbox } from '../../assets/styles/global'
// import leftBackgroundImage from '../../assets/images/PIAS/image-1.png' // Todo compress image
import backgroundVideo from '../../assets/images/Scarlatti/confer-scarlatti.mp4'
import leftBackgroundImage from '../../assets/images/INZB/background.jpg'
import logo from '../../assets/images/INZB/genetics.png'

const IconHappy = styled(Happy)`
  width: 3rem;
  height: 3rem;
`
const IconOk = styled(Ok)`
  width: 3rem;
  height: 3rem;
`
const IconSad = styled(Sad)`
  width: 3rem;
  height: 3rem;
`
const IconVeryHappy = styled(VeryHappy)`
  width: 3rem;
  height: 3rem;
`
const IconVerySad = styled(VerySad)`
  width: 3rem;
  height: 3rem;
`

const StyledTextbox = styled(Textbox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  width: 800px;
  li {
    font-size: 1rem;
    font-weight: 400;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 100%;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1025px) {
    width: 100%;
  }
`

const IconCheck = styled(Check)`
  width: 45px;
  height: 80px;
`

const IconCross = styled(Cross)`
  width: 45px;
  height: 80px;
`
const StyledP = styled.p`
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0rem;
  margin-top: 1rem;
`

const Q = styled.p`
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: #757575;
  margin-bottom: 0.5rem;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  p {
    margin-bottom: 0rem;
  }
  .ant-divider-horizontal {
    margin: 0px 0;
    border-top: 1px solid #c3c3c3;
    width: 49rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 80vw;
    .ant-divider-horizontal {
      width: 90vw;
    }
  }
  /* align-items: center; */
`
const Text = styled.p`
  font-size: 1rem;
  color: ${props => props.color};
`

// console.log('companies', distinctCompanies)

const Pias = {
  questions: [
    {
      id: 'intro-page',
      type: welcomePage,
      text: (
        <StyledTextbox>
          <h4>
            Beef + Lamb Genetics, a subsidiary of Beef + Lamb NZ, aims to see the New Zealand beef
            industry recognised as a leader in productivity, sustainability, and resilience through
            the use of superior genetics. They are conducting a programme to develop genetic tools,
            that aims to achieve this.
          </h4>
          <h4>
            If you are a commercial beef farmer, a bull breeder or advise/influence bull breeding
            decisions, please take this survey to help Beef + Lamb Genetics understand your current
            awareness about genetic tools.
          </h4>
          <h4>This survey will take approximately 10 minutes. </h4>
          <p style={{ marginBottom: '1rem' }}>
            Please click <i>NEXT</i> to begin
          </p>
        </StyledTextbox>
      ),
    },
    // {
    //   id: 'test',
    //   type: 'responsiveMatrixTable',
    //   // additionalContent: 'button',
    //   text: 'What has your involvement been in each of the following?',
    //   choices: [
    //     {
    //       id: 1,
    //       text: 'A) B+LNZ Genetics organisation ',
    //       value: 'B+LNZ Genetics organisation ',
    //     },
    //     {
    //       id: 2,
    //       text: 'B) nProve',
    //       value: 'nProve',
    //     },
    //     {
    //       id: 3,
    //       text: 'C) Informing NZ Beef Programme (INZB)',
    //       value: 'Informing NZ Beef Programme (INZB)',
    //     },
    //   ],

    //   options: [
    //     {
    //       id: 1,
    //       text: 'I haven’t heard of this before',
    //       value: 'I haven’t heard of this before',
    //     },
    //     {
    //       id: 2,
    //       text: 'It sounds vaguely familiar but doesn’t mean anything to me',
    //       value: 'It sounds vaguely familiar but doesn’t mean anything to me',
    //     },
    //     {
    //       id: 3,
    //       text:
    //         'I have heard about it and understand what it does but I have never interacted with it',
    //       value:
    //         'I have heard about it and understand what it does but I have never interacted with it',
    //     },
    //     {
    //       id: 4,
    //       text: 'I have / or do interact with it',
    //       value: 'I have / or do interact with it',
    //     },
    //   ],
    // },
    // 1.1
    {
      id: 'group',
      type: multiChoice,
      required: true,
      mobileHeight: '7rem',
      buttonSize: xlButton.width,
      // section: (
      //   <Wrapper>
      //     <Q>Questions 1 of 3</Q>
      //     <Text color={'#F9744C'}>Operations</Text>
      //     <Divider />
      //   </Wrapper>
      // ),
      // useValues: true,
      disclaimer: 'Please choose the one that is most relevant',
      useIcons: true,
      text: 'Which group best describes you?',
      choices: [
        {
          id: 1,
          text: 'Commercial farmer – someone that farms beef.',
          html: (
            <p style={{ marginBottom: 0 }}>
              <b>Commercial farmer</b> - someone that farms cattle
            </p>
          ),
          rawText: 'Commercial farmer',
          value: 'Commercial farmer',
        },
        {
          id: 2,
          html: (
            <p style={{ marginBottom: 0 }}>
              <b>Breeder</b> - someone that is directly involved in breeding stud bulls for supply
              to the beef industry
            </p>
          ),
          text: 'Breeder',
          value: 'Breeder',
        },
        {
          id: 3,
          html: (
            <p style={{ marginBottom: 0 }}>
              <b>Influencer</b> - a rural professional who is likely to have an influence on a
              farmer’s decision-making process
            </p>
          ),
          text: 'Influencer',
          value: 'Influencer',
        },
      ],
      next: ({ answers }) => {
        let qId = answers['group']
        if (qId.value === 'Commercial farmer') {
          return 'commercial-farmers'
        }
        if (qId.value === 'Breeder') {
          return 'breeders'
        }
        return 'influencers'
      },
    },
    {
      id: 'influencers',
      type: multiChoice,
      required: true,
      text: 'Which of the following best describes your role?',
      choices: [
        {
          id: 1,
          text: 'Consultant',
          value: 'Consultant',
        },
        {
          id: 2,
          text: 'Stock agent',
          value: 'Stock agent',
        },
        {
          id: 3,
          text: 'Vet',
          value: 'Vet',
        },
        {
          id: 4,
          text: 'Ag Facilitator',
          value: 'Ag Facilitator',
        },
        { id: 5, type: input, text: 'Other, please specify', value: 'other' },
      ],
    },
    {
      id: 'influencers-map',
      type: 'responsiveMultiMap',
      required: true,
      verticalGrid: true,
      disclaimer: 'Hover over areas to select all regions that apply',
      choices: [
        { id: 1, name: 1, text: 'Northland ', value: 'Northland' },
        { id: 2, name: 2, text: 'Auckland ', value: 'Auckland' },
        { id: 3, name: 3, text: 'Waikato ', value: 'Waikato' },
        { id: 4, name: 4, text: 'Bay of Plenty ', value: 'Bay of Plenty' },
        { id: 5, name: 6, text: 'Gisborne ', value: 'Gisborne' },
        { id: 6, name: 6, text: "Hawke's Bay ", value: "Hawke's Bay" },
        { id: 7, name: 7, text: 'Taranaki ', value: 'Taranaki' },
        { id: 8, name: 8, text: 'Manawatū-Wanganui ', value: 'Manawatū-Wanganui' },
        { id: 9, name: 9, text: 'Wellington ', value: 'Wellington' },
        { id: 10, name: 10, text: 'West Coast ', value: 'West Coast' },
        { id: 11, name: 11, text: 'Canterbury ', value: 'Canterbury' },
        { id: 12, name: 12, text: 'Otago ', value: 'Otago' },
        { id: 13, name: 13, text: 'Southland ', value: 'Southland' },
        { id: 14, name: 14, text: 'Marlborough ', value: 'Marlborough' },
        { id: 15, name: 15, text: 'Nelson/Tasman ', value: 'Nelson/Tasman' },
        { id: 16, text: 'All regions', value: 'All regions' },
      ],
      text: 'What region(s) does your role operate in?',
    },
    {
      id: 'influencer-knowledge-2.1',
      type: multiChoice,
      required: true,
      text:
        'Do you believe in describing animal performance by measuring attributes and looking at numbers (performance recording)?',
      choices: [
        {
          id: 1,
          text: 'No',
          value: 'No',
          icon: <IconCross fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Yes',
          value: 'Yes',
          icon: <IconCheck fill="currentColor" />,
        },
      ],
    },
    {
      id: 'influencer-knowledge',
      type: multiChoice,
      required: true,
      mobileHeight: '7rem',
      disclaimer:
        'For example, Estimated Breeding Values (EBV), Breeding Indexes, Artificial Insemination, Genomics and/or Embryo transfer',

      // section: (
      //   <Wrapper>
      //     <Q>Questions 1 of 3</Q>
      //     <Text color={'#F9744C'}>Demographics</Text>
      //     <Divider />
      //   </Wrapper>
      // ),
      // useValues: true,
      text: 'Do you think using genetic tools is important for the future of the beef industry?',
      choices: [
        {
          id: 1,
          text: 'I’ve never thought about the industry using genetic tools ',
          value: 'I’ve never thought about the industry using genetic tools ',
        },
        {
          id: 2,
          text: 'I don’t think genetic tools will enhance the future of the beef industry',
          value: 'I don’t think genetic tools will enhance the future of the beef industry',
        },
        {
          id: 3,
          text: 'I think using genetic tools are needed to some degree',
          value: 'I think using genetic tools are needed to some degree',
        },
        {
          id: 4,
          text: 'I think genetic tools are vital for the future of the beef industry',
          value: 'I think genetic tools are vital for the future of the beef industry',
        },
      ],
    },
    {
      id: 'influencer-knowledge-p2',
      required: true,
      type: multiChoice,
      mobileHeight: '5rem',
      text:
        'Do you understand how to advise the use of genetic tools in your farmer client’s system?',
      choices: [
        {
          id: 1,
          text: 'I have no idea how I would advise the use of genetic tools',
          value: 'I have no idea how I would advise the use of genetic tools',
        },
        {
          id: 2,
          text: 'I have a vague idea of how I would advise the use of genetic tools',
          value: 'I have a vague idea of how I would advise the use of genetic tools',
        },
        {
          id: 3,
          text: 'I know generally how to advise the use of genetic tools ',
          value: 'I know generally how to advise the use of genetic tools ',
        },
        {
          id: 4,
          text: 'I am well aware of how to advise the use of genetic tools ',
          value: 'I am well aware of how to advise the use of genetic tools ',
        },
      ],
    },
    {
      id: 'influencer-knowledge 2.5',
      buttonSize: '30rem',
      mobileHeight: '7rem',
      type: multiChoice,
      required: true,
      text: 'Could you explain how genetic tools are put together and work?',
      disclaimer:
        'For example, Estimated Breeding Values (EBV), Breeding Indexes, Artificial Insemination, Genomics and/or Embryo transfer',
      choices: [
        {
          id: 1,
          text: 'I have no idea how to explain how genetic tools are put together and work',
          value: 'I have no idea how to explain how genetic tools are put together and work',
        },
        {
          id: 2,
          text: 'I have a vague idea how to explain how genetic tools are put together and work',
          value: 'I have a vague idea how to explain how genetic tools are put together and work',
        },
        {
          id: 3,
          text: 'I know generally how to explain how genetic tools are put together and work',
          value: 'I know generally how to explain how genetic tools are put together and work',
        },
        {
          id: 4,
          text: 'I can confidently explain how genetic tools are put together and work',
          value: 'I can confidently explain how genetic tools are put together and work',
        },
      ],
    },
    {
      id: 'influencer-knowledge-p3',
      type: multiChoice,
      required: true,
      text: 'Have you encouraged your clients to use genetic tools in the past 12 months?',
      disclaimer:
        'For example, Estimated Breeding Values (EBV), Breeding Indexes, Artificial Insemination, Genomics and/or Embryo transfer',
      choices: [
        {
          id: 1,
          text: 'No',
          value: 'No',
          icon: <IconCross fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Yes',
          value: 'Yes',
          icon: <IconCheck fill="currentColor" />,
        },
      ],
      next: ({ answers }) => {
        if (answers['influencer-knowledge-p3'].value === 'Yes') {
          return 'influencer-uses-genetic-tools'
        }
        return 'influencer-no-genetic-tools'
        // return 'support-for-the-advisory-system' // change to support
      },
      // if no go to no-genetic tools
    },
    {
      id: 'influencer-no-genetic-tools',
      type: multiChoice,
      required: true,
      verticalGrid: true,
      text:
        'What is the main reason you haven’t advised the use of genetic tools in the past 12 months?',
      width: '24rem',
      choices: [
        {
          id: 1,
          text: 'I am not aware of any genetic tools',
          value: 'I am not aware of any genetic tools',
        },
        {
          id: 2,
          text: 'I do not see the value that they would create for my clients',
          value: 'I do not see the value that they would create for my clients',
        },
        {
          id: 3,
          text: 'I do not know enough about them to advise on it',
          value: 'I do not know enough about them to advise on it',
        },

        {
          id: 4,
          type: input,
          text: 'Other, please specify',
          placeholder: 'Other, please specify',
          width: '24rem',
        },
      ],
    },
    // 2.5A
    {
      id: 'influencer-no-genetic-tools-p2',
      type: multiSelect,
      required: true,
      disclaimer: 'Select all that apply',
      verticalGrid: true,
      text: 'Which of the following genetic tools / practices have you heard about?',
      width: '24rem',
      choices: [
        {
          id: 1,
          text: 'Estimated Breeding Values (EBV)',
          value: 'Estimated Breeding Values (EBV)',
        },
        {
          id: 2,
          text: 'Breeding Indexes',
          value: 'Breeding Indexes',
        },
        {
          id: 3,
          text: 'Artificial Insemination (AI)',
          value: 'Artificial Insemination (AI)',
        },
        {
          id: 4,
          text: 'Genomics',
          value: 'Genomics',
        },
        {
          id: 5,
          text: 'Embryo transfer ',
          value: 'Embryo transfer ',
        },
        {
          id: 6,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        },
      ],
      next: ({ answers }) => {
        if (
          answers['influencer-no-genetic-tools-p2'].value.some(
            item => item.value !== 'None of the above',
          )
        ) {
          return 'influencer-no-genetic-tools-p3'
        }
        return 'influencer-no-genetic-tools-p4'
      },
    },
    // 2.6A
    {
      id: 'influencer-no-genetic-tools-p3',
      type: multiSelect,
      required: true,
      disclaimer: 'Select all that apply',
      verticalGrid: true,
      text:
        'Which of the following genetic tools / practices would you consider advising your clients to use in the future?',
      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          let defaultAnswers = [
            {
              id: 1,
              text: 'Estimated Breeding Values (EBV)',
              value: 'Estimated Breeding Values (EBV)',
            },
            {
              id: 2,
              text: 'Breeding Indexes',
              value: 'Breeding Indexes',
            },
            {
              id: 3,
              text: 'Artificial Insemination (AI)',
              value: 'Artificial Insemination (AI)',
            },
            {
              id: 4,
              text: 'Genomics',
              value: 'Genomics',
            },
            {
              id: 5,
              text: 'Embryo transfer ',
              value: 'Embryo transfer ',
            },
            {
              id: 6,
              text: 'None of the above',
              value: 'None of the above',
              submit: true,
            },
          ]
          return defaultAnswers
        }

        let pipedAnswers = answers['influencer-no-genetic-tools-p2'].value.map(item => ({
          id: item.id,
          text: item.value,
          value: item.value,
        }))

        const other = {
          id: 6,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        }

        const updatedArray = [...pipedAnswers, other]

        return _.uniq(updatedArray)
      },
    },
    // 2.7A
    {
      id: 'influencer-no-genetic-tools-p4',
      type: multiSelect,
      required: true,
      disclaimer: 'Select all that apply',
      verticalGrid: true,
      text: 'Which of the following genetic tools / practices would you like to learn more about?',
      width: '24rem',
      choices: [
        {
          id: 1,
          text: 'Estimated Breeding Values (EBV)',
          value: 'Estimated Breeding Values (EBV)',
        },
        {
          id: 2,
          text: 'Breeding Indexes',
          value: 'Breeding Indexes',
        },
        {
          id: 3,
          text: 'Artificial Insemination (AI)',
          value: 'Artificial Insemination (AI)',
        },
        {
          id: 4,
          text: 'Genomics',
          value: 'Genomics',
        },
        {
          id: 5,
          text: 'Embryo transfer ',
          value: 'Embryo transfer ',
        },
        {
          id: 6,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        },
      ],
      next: ({ answers }) => {
        return 'awareness-intro'
      },
    },
    //2.4B
    {
      id: 'influencer-uses-genetic-tools',
      type: multiSelect,
      required: true,
      disclaimer: 'Select all that apply',
      verticalGrid: true,
      text: 'Which of the following genetic tools / practices have you heard about?',
      width: '24rem',
      choices: [
        {
          id: 1,
          text: 'Estimated Breeding Values (EBV)',
          value: 'Estimated Breeding Values (EBV)',
        },
        {
          id: 2,
          text: 'Breeding Indexes',
          value: 'Breeding Indexes',
        },
        {
          id: 3,
          text: 'Artificial Insemination (AI)',
          value: 'Artificial Insemination (AI)',
        },
        {
          id: 4,
          text: 'Genomics',
          value: 'Genomics',
        },
        {
          id: 5,
          text: 'Embryo transfer ',
          value: 'Embryo transfer ',
        },
        {
          id: 6,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        },
      ],
      next: ({ answers }) => {
        if (
          answers['influencer-uses-genetic-tools'].value.some(
            item => item.value !== 'None of the above',
          )
        ) {
          return 'influencer-uses-genetic-tools-2'
        }
        return 'influencer-uses-genetic-tools-6'
      },
    },
    // 2.5B
    {
      id: 'influencer-uses-genetic-tools-2',
      type: multiSelect,
      required: true,
      disclaimer: 'Select all that apply',
      verticalGrid: true,
      text:
        'Which of the following genetic tools / practices have you advised your clients to use in the past 12 months?',
      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          let defaultAnswers = [
            {
              id: 1,
              text: 'Estimated Breeding Values (EBV)',
              value: 'Estimated Breeding Values (EBV)',
            },
            {
              id: 2,
              text: 'Breeding Indexes',
              value: 'Breeding Indexes',
            },
            {
              id: 3,
              text: 'Artificial Insemination (AI)',
              value: 'Artificial Insemination (AI)',
            },
            {
              id: 4,
              text: 'Genomics',
              value: 'Genomics',
            },
            {
              id: 5,
              text: 'Embryo transfer ',
              value: 'Embryo transfer ',
            },
            {
              id: 6,
              text: 'None of the above',
              value: 'None of the above',
              submit: true,
            },
          ]
          return defaultAnswers
        }

        let pipedAnswers = answers['influencer-uses-genetic-tools'].value.map(item => ({
          id: item.id,
          text: item.value,
          value: item.value,
        }))

        let filter = _.filter(pipedAnswers, obj => {
          return obj.id !== 6
        })
        console.log('filter', filter)

        const other = {
          id: 6,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        }

        const updatedArray = [...filter, other]

        // const updatedArray = [...pipedAnswers]

        return _.uniq(updatedArray)

        // should we include default answers?
      },
      next: ({ answers }) => {
        let currentQuestionsLength = answers['influencer-uses-genetic-tools-2'].choices.length - 1
        if (answers['influencer-uses-genetic-tools-2'].value.length === currentQuestionsLength) {
          return 'influencer-uses-genetic-tools-4'
        }
        return 'influencer-uses-genetic-tools-3'
      },
      // add next if none of the above
    },
    //2.6B
    {
      id: 'influencer-uses-genetic-tools-3',
      type: multiSelect,
      required: true,
      disclaimer: 'Select all that apply',
      verticalGrid: true,
      text:
        'Which of the following genetic tools / practices would you consider advising your clients to use of in the future?',
      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          let defaultAnswers = [
            {
              id: 1,
              text: 'Estimated Breeding Values (EBV)',
              value: 'Estimated Breeding Values (EBV)',
            },
            {
              id: 2,
              text: 'Breeding Indexes',
              value: 'Breeding Indexes',
            },
            {
              id: 3,
              text: 'Artificial Insemination (AI)',
              value: 'Artificial Insemination (AI)',
            },
            {
              id: 4,
              text: 'Genomics',
              value: 'Genomics',
            },
            {
              id: 5,
              text: 'Embryo transfer ',
              value: 'Embryo transfer ',
            },
            {
              id: 6,
              text: 'None of the above',
              value: 'None of the above',
              submit: true,
            },
          ]
          return defaultAnswers
        }
        let pipedAnswers = answers['influencer-uses-genetic-tools-2'].value.map(item => ({
          id: item.id,
          text: item.value,
          value: item.value,
          submit: item.id === 6 ? true : false,
        }))
        // should we include default answers?

        let choices = answers['influencer-uses-genetic-tools-2'].choices
        let difference = choices.filter(item => !pipedAnswers.find(a => item.id === a.id))

        let filter = _.filter(difference, obj => {
          return obj.id !== 6
        })
        console.log('filter', filter)

        const other = {
          id: 6,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        }

        const updatedArray = [...filter, other]

        return _.uniq(updatedArray)
      },
      // add next if none of the above
    },
    {
      id: 'influencer-uses-genetic-tools-4',
      type: multiSelect,
      required: true,
      disclaimer: 'Select all that apply',
      verticalGrid: true,
      text: 'Which of the following genetic tools / practices would you like to learn more about?',
      width: '24rem',
      choices: [
        {
          id: 1,
          text: 'Estimated Breeding Values (EBV)',
          value: 'Estimated Breeding Values (EBV)',
        },
        {
          id: 2,
          text: 'Breeding Indexes',
          value: 'Breeding Indexes',
        },
        {
          id: 3,
          text: 'Artificial Insemination (AI)',
          value: 'Artificial Insemination (AI)',
        },
        {
          id: 4,
          text: 'Genomics',
          value: 'Genomics',
        },
        {
          id: 5,
          text: 'Embryo transfer ',
          value: 'Embryo transfer ',
        },
        {
          id: 6,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        },
      ],
    },
    // 2.7B
    {
      id: 'influencer-uses-genetic-tools-5',
      // type: responsiveMatrix,
      type: 'responsiveMatrixTable',

      useIcons: true,
      text: 'How confident are you using genetic tools / practices?',
      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          let defaultAnswers = [
            {
              id: 1,
              text: 'Estimated Breeding Values (EBV)',
              value: 'Estimated Breeding Values (EBV)',
            },
            {
              id: 2,
              text: 'Breeding Indexes',
              value: 'Breeding Indexes',
            },
            {
              id: 3,
              text: 'Artificial Insemination (AI)',
              value: 'Artificial Insemination (AI)',
            },
            {
              id: 4,
              text: 'Genomics',
              value: 'Genomics',
            },
            {
              id: 5,
              text: 'Embryo transfer ',
              value: 'Embryo transfer ',
            },
            {
              id: 6,
              text: 'None of the above',
              value: 'None of the above',
              submit: true,
            },
          ]
          return defaultAnswers
        }

        let choicesArray = answers['influencer-uses-genetic-tools'].value.map(item => ({
          id: item.id,
          text: item.value,
          value: item.value,
        }))
        return choicesArray
      },
      options: [
        {
          id: 1,
          text: 'I do not feel confident to explain',
          value: 'I do not feel confident to explain',
          icon: <IconSad />,
        },
        {
          id: 2,
          text: 'I have some confidence to explain',
          value: 'I have some confidence to explain',
          icon: <IconHappy />,
        },
        {
          id: 3,
          text: 'I have confidence to explain',
          value: 'I have confidence to explain',
          icon: <IconVeryHappy />,
        },
      ],
    },

    {
      id: 'influencer-uses-genetic-tools-6',
      type: multiChoice,
      required: true,
      text: 'How long have you been encouraging the use of genetic tools?',
      choices: [
        {
          id: 1,
          text: 'Less than 6 months',
          value: 'Less than 6 months',
        },
        {
          id: 2,
          text: '6 - 12 months',
          value: '6 - 12 months',
        },
        {
          id: 3,
          text: '1 - 3 years',
          value: '1 - 3 years',
        },
        {
          id: 4,
          text: '3 - 10 years',
          value: '3 - 10 years',
        },
        {
          id: 5,
          text: '10 + years',
          value: '10 + years',
        },
      ],
      next: ({ answers }) => {
        return 'awareness-intro'
      },
    },
    {
      id: 'breeders',
      required: true,
      type: multiChoice,
      text: 'Which breed do you sell as a stud?',
      choices: [
        {
          id: 1,
          text: 'Angus',
          value: 'Angus',
        },
        {
          id: 2,
          text: 'Hereford',
          value: 'Hereford',
        },
        {
          id: 3,
          text: 'Simmental',
          value: 'Simmental',
        },
        { id: 4, type: input, text: 'Other, please specify', value: 'other' },
      ],
    },
    {
      id: 'breeders-map',
      type: 'responsiveMultiMap',
      noRadio: true,
      required: true,
      verticalGrid: true,
      disclaimer: 'Hover over areas to select all regions that apply',
      choices: [
        { id: 1, name: 1, text: 'Northland ', value: 'Northland' },
        { id: 2, name: 2, text: 'Auckland ', value: 'Auckland' },
        { id: 3, name: 3, text: 'Waikato ', value: 'Waikato' },
        { id: 4, name: 4, text: 'Bay of Plenty ', value: 'Bay of Plenty' },
        { id: 5, name: 6, text: 'Gisborne ', value: 'Gisborne' },
        { id: 6, name: 6, text: "Hawke's Bay ", value: "Hawke's Bay" },
        { id: 7, name: 7, text: 'Taranaki ', value: 'Taranaki' },
        { id: 8, name: 8, text: 'Manawatū-Whanganui ', value: 'Manawatū-Whanganui' },
        { id: 9, name: 9, text: 'Wellington ', value: 'Wellington' },
        { id: 10, name: 10, text: 'West Coast ', value: 'West Coast' },
        { id: 11, name: 11, text: 'Canterbury ', value: 'Canterbury' },
        { id: 12, name: 12, text: 'Otago ', value: 'Otago' },
        { id: 13, name: 13, text: 'Southland ', value: 'Southland' },
        { id: 14, name: 14, text: 'Marlborough ', value: 'Marlborough' },
        { id: 15, name: 15, text: 'Nelson/Tasman ', value: 'Nelson/Tasman' },
        { id: 16, text: 'All regions', value: 'All regions' },
      ],
      text: 'What region(s) do you operate in?',
    },
    {
      id: 'breeder-demographics',
      type: multiChoice,
      required: true,
      disclaimer: 'In stock UNITS',
      // section: (
      //   <Wrapper>
      //     <Q>Questions 1 of 3</Q>
      //     <Text color={'#F9744C'}>Demographics</Text>
      //     <Divider />
      //   </Wrapper>
      // ),
      // useValues: true,
      text: 'What is the total size of your operation?',
      choices: [
        {
          id: 1,
          text: '0 - 1999',
          value: '0 - 1999',
        },
        {
          id: 2,
          text: '2000 - 4999',
          value: '2000 - 4999',
        },
        {
          id: 3,
          text: '5,000 - 9,999',
          value: '5,000 - 9,999',
        },
        {
          id: 4,
          text: '10,000 - 19,999',
          value: '10,000 - 19,999',
        },
        {
          id: 5,
          text: '20,000+',
          value: '20,000+',
        },
        // { id: 6, type: input, text: 'Other, please specify', value: 'other' },
      ],
    },
    {
      id: 'stud-cattle',
      // section: (
      //   <Wrapper>
      //     <Q>Questions 1 of 6</Q>
      //     <Text color={'#F9744C'}>Wellbeing</Text>
      //     <p style={{ marginTop: '0.5rem' }}>
      //       Please indicate your agreement with the following statements:
      //     </p>
      //     <Divider />
      //   </Wrapper>
      // ),
      text: 'What percentage of your total stock units is stud cattle?',
      type: responsiveSlider,
      showValue: true,
      default: 0,
      // step: 5,
      usePercent: true,
      choices: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    },
    {
      id: 'breeder-knowledge-2.1',
      type: multiChoice,
      required: true,
      text:
        'Do you believe in describing animal performance by measuring attributes and looking at numbers (performance recording)?',
      choices: [
        {
          id: 1,
          text: 'No',
          value: 'No',
          icon: <IconCross fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Yes',
          value: 'Yes',
          icon: <IconCheck fill="currentColor" />,
        },
      ],
    },
    {
      id: 'breeder-knowledge',
      required: true,
      type: multiChoice,
      mobileHeight: '7rem',
      disclaimer:
        'For example, Estimated Breeding Values (EBV), Breeding Indexes, Artificial Insemination, Genomics and/or Embryo transfer',
      // section: (
      //   <Wrapper>
      //     <Q>Questions 1 of 3</Q>
      //     <Text color={'#F9744C'}>Demographics</Text>
      //     <Divider />
      //   </Wrapper>
      // ),
      // useValues: true,
      text: 'Do you think using genetic tools is important for the future of the beef industry?',
      choices: [
        {
          id: 1,
          text: 'I’ve never thought about the industry using genetic tools ',
          value: 'I’ve never thought about the industry using genetic tools ',
        },
        {
          id: 2,
          text: 'I don’t think genetic tools will enhance the future of the beef industry',
          value: 'I don’t think genetic tools will enhance the future of the beef industry',
        },
        {
          id: 3,
          text: 'I think using genetic tools are needed to some degree',
          value: 'I think using genetic tools are needed to some degree',
        },
        {
          id: 4,
          text: 'I think genetic tools are vital for the future of the beef industry',
          value: 'I think genetic tools are vital for the future of the beef industry',
        },
      ],
    },
    {
      id: 'breeder-knowledge-p2',
      required: true,
      mobileHeight: '5rem',
      type: multiChoice,
      // section: (
      //   <Wrapper>
      //     <Q>Questions 1 of 3</Q>
      //     <Text color={'#F9744C'}>Demographics</Text>
      //     <Divider />
      //   </Wrapper>
      // ),
      // useValues: true,
      text: 'Do you understand how to use genetic tools in your farm system?',
      choices: [
        {
          id: 1,
          text: 'I have no idea how I would use genetic tools in my farm system',
          value: 'I have no idea how I would use genetic tools in my farm system',
        },
        {
          id: 2,
          text: 'I have a vague idea of how I would use genetic tools in my farm system',
          value: 'I have a vague idea of how I would use genetic tools in my farm system',
        },
        {
          id: 3,
          text: 'I know generally how genetic tools would be used in my farm system',
          value: 'I know generally how genetic tools would be used in my farm system',
        },
        {
          id: 4,
          text: 'I am well aware of how genetic tools would fit into my farm system',
          value: 'I am well aware of how genetic tools would fit into my farm system',
        },

        // { id: 6, type: input, text: 'Other, please specify', value: 'other' },
      ],
    },
    {
      id: 'breeder-knowledge 2.5',
      buttonSize: '30rem',
      mobileHeight: '7rem',
      type: multiChoice,
      required: true,
      text: 'Could you explain how genetic tools are put together and work?',
      disclaimer:
        'For example, Estimated Breeding Values (EBV), Breeding Indexes, Artificial Insemination, Genomics and/or Embryo transfer',
      choices: [
        {
          id: 1,
          text: 'I have no idea how to explain how genetic tools are put together and work',
          value: 'I have no idea how to explain how genetic tools are put together and work',
        },
        {
          id: 2,
          text: 'I have a vague idea how to explain how genetic tools are put together and work',
          value: 'I have a vague idea how to explain how genetic tools are put together and work',
        },
        {
          id: 3,
          text: 'I know generally how to explain how genetic tools are put together and work',
          value: 'I know generally how to explain how genetic tools are put together and work',
        },
        {
          id: 4,
          text: 'I can confidently explain how genetic tools are put together and work',
          value: 'I can confidently explain how genetic tools are put together and work',
        },
      ],
    },
    // 2.3
    {
      id: 'breeder-knowledge-p3',
      type: multiChoice,
      required: true,
      text: 'Have you used genetic tools in your farm system within the past 12 months?',
      disclaimer:
        'For example, Estimated Breeding Values (EBV), Breeding Indexes, Artificial Insemination, Genomics and/or Embryo transfer',
      // text: 'Do you use genetic tools in your farm system?',
      // text: (
      //   <p>
      //     Do you use genetic tools in your farm system? (
      //     <i>
      //       For example, Estimated Breeding Values (EBV), Breeding Indexes, Artificial Insemination,
      //       Genomics and/or Embryo transfer
      //     </i>
      //     {''})
      //   </p>
      // ),
      choices: [
        {
          id: 1,
          text: 'No',
          value: 'No',
          icon: <IconCross fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Yes',
          value: 'Yes',
          icon: <IconCheck fill="currentColor" />,
        },
      ],
      next: ({ answers }) => {
        if (answers['breeder-knowledge-p3'].value === 'Yes') {
          return 'breeder-uses-genetic-tools'
        }
        return 'breeder-no-genetic-tools'
        // return 'support-for-the-advisory-system' // change to support
      },
      // if no go to no-genetic tools
    },
    // 2.4A
    {
      id: 'breeder-no-genetic-tools',
      type: multiChoice,
      required: true,
      verticalGrid: true,
      text:
        'What is the main reason you haven’t used genetic tools in the past 12 months in your farm system?',
      width: '24rem',
      choices: [
        {
          id: 1,
          text: 'I am not aware of any genetic tools',
          value: 'I am not aware of any genetic tools',
        },
        {
          id: 2,
          text: 'I do not see the value that they could add to my farm system',
          value: 'I do not see the value that they could add to my farm system',
        },
        {
          id: 3,
          text: 'I do not know how to use them in my farm system',
          value: 'I do not know how to use them in my farm system',
        },
        // {
        //   id: 1,
        //   text: 'I do not know anything about genetic tools',
        //   value: 'I do not know anything about genetic tools',
        // },
        // {
        //   id: 2,
        //   text: 'I do not see the value that they would create for my farm system',
        //   value: 'I do not see the value that they would create for my farm system',
        // },
        // {
        //   id: 3,
        //   text: 'I don’t know enough about them to use them',
        //   value: 'I don’t know enough about them to use them',
        // },

        {
          id: 4,
          type: input,
          text: 'Other, please specify',
          placeholder: 'Other, please specify',
          width: '24rem',
        },
      ],
    },
    // 2.5A
    {
      id: 'breeder-no-genetic-tools-p2',
      type: multiSelect,
      required: true,
      disclaimer: 'Select all that apply',
      verticalGrid: true,
      text: 'Which of the following genetic tools / practices have you heard about?',
      width: '24rem',
      choices: [
        {
          id: 1,
          text: 'Estimated Breeding Values (EBV)',
          value: 'Estimated Breeding Values (EBV)',
        },
        {
          id: 2,
          text: 'Breeding Indexes',
          value: 'Breeding Indexes',
        },
        {
          id: 3,
          text: 'Artificial Insemination (AI)',
          value: 'Artificial Insemination (AI)',
        },
        {
          id: 4,
          text: 'Genomics',
          value: 'Genomics',
        },
        {
          id: 5,
          text: 'Embryo transfer ',
          value: 'Embryo transfer ',
        },
        {
          id: 6,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        },
      ],
      next: ({ answers }) => {
        if (
          answers['breeder-no-genetic-tools-p2'].value.some(
            item => item.value !== 'None of the above',
          )
        ) {
          return 'breeder-no-genetic-tools-p3'
        }
        return 'breeder-no-genetic-tools-p4'
      },
    },
    // 2.6A
    {
      id: 'breeder-no-genetic-tools-p3',
      type: multiSelect,
      required: true,
      disclaimer: 'Select all that apply',
      verticalGrid: true,
      text:
        'Which of the following genetic tools / practices would you consider using in the future?',
      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          let defaultAnswers = [
            {
              id: 1,
              text: 'Estimated Breeding Values (EBV)',
              value: 'Estimated Breeding Values (EBV)',
            },
            {
              id: 2,
              text: 'Breeding Indexes',
              value: 'Breeding Indexes',
            },
            {
              id: 3,
              text: 'Artificial Insemination (AI)',
              value: 'Artificial Insemination (AI)',
            },
            {
              id: 4,
              text: 'Genomics',
              value: 'Genomics',
            },
            {
              id: 5,
              text: 'Embryo transfer ',
              value: 'Embryo transfer ',
            },
            {
              id: 6,
              text: 'None of the above',
              value: 'None of the above',
              submit: true,
            },
          ]
          return defaultAnswers
        }
        let pipedAnswers = answers['breeder-no-genetic-tools-p2'].value.map(item => ({
          id: item.id,
          text: item.value,
          value: item.value,
        }))

        const other = {
          id: 6,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        }

        const updatedArray = [...pipedAnswers, other]

        return _.uniq(updatedArray)
      },
    },
    // 2.7A
    {
      id: 'breeder-no-genetic-tools-p4',
      type: multiSelect,
      required: true,
      disclaimer: 'Select all that apply',
      verticalGrid: true,
      text: 'Which of the following genetic tools / practices would you like to learn more about?',
      width: '24rem',
      choices: [
        {
          id: 1,
          text: 'Estimated Breeding Values (EBV)',
          value: 'Estimated Breeding Values (EBV)',
        },
        {
          id: 2,
          text: 'Breeding Indexes',
          value: 'Breeding Indexes',
        },
        {
          id: 3,
          text: 'Artificial Insemination (AI)',
          value: 'Artificial Insemination (AI)',
        },
        {
          id: 4,
          text: 'Genomics',
          value: 'Genomics',
        },
        {
          id: 5,
          text: 'Embryo transfer ',
          value: 'Embryo transfer ',
        },
        {
          id: 6,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        },
      ],
    },
    {
      id: 'breeder-no-genetic-tools-p5',
      mobileHeight: '5.5rem',
      required: true,
      // section: () => {
      //   return (
      //     <Wrapper>
      //       <QuestionInfo>
      //         <GreenText>Issues and interventions</GreenText>
      //       </QuestionInfo>
      //       <Divider style={{ marginBottom: '1rem', marginTop: '0rem', width: '24rem' }} />
      //     </Wrapper>
      //   )
      // },
      width: '49.5rem',
      text:
        'Rank the following attributes based on what drives your clients’ bull buying decisions.',
      type: 'ranking',
      mobileHeight: '5rem',
      verticalGrid: true,
      choices: [
        {
          id: 1,
          text: 'Conformation / structure',
          value: 'Conformation / structure',
        },
        {
          id: 2,
          text: 'Maternal ability',
          value: 'Maternal ability',
        },
        {
          id: 3,
          text: 'Constitution / Hardiness',
          value: 'Constitution / Hardiness',
        },
        {
          id: 4,
          text: 'EBVs / indexes',
          value: 'EBVs / indexes',
        },
        {
          id: 5,
          text: 'Temperament ',
          value: 'Temperament ',
        },
      ],
      // next: ({ answers }) => {
      //   return 'awareness-intro'
      // },
    },
    {
      id: 'bull-buying',
      type: multiChoice,
      required: true,
      text:
        'Have you recommended your bull buying clients use genetic tools when purchasing bulls in the past 12 months?',
      choices: [
        {
          id: 1,
          text: 'No',
          value: 'No',
          icon: <IconCross fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Yes',
          value: 'Yes',
          icon: <IconCheck fill="currentColor" />,
        },
      ],
      next: ({ answers }) => {
        return 'awareness-intro'
      },
    },
    // 2.4 B
    {
      id: 'breeder-uses-genetic-tools',
      type: multiSelect,
      required: true,
      disclaimer: 'Select all that apply',
      verticalGrid: true,
      text: 'Which of the following genetic tools / practices have you heard about?',
      width: '24rem',
      choices: [
        {
          id: 1,
          text: 'Estimated Breeding Values (EBV)',
          value: 'Estimated Breeding Values (EBV)',
        },
        {
          id: 2,
          text: 'Breeding Indexes',
          value: 'Breeding Indexes',
        },
        {
          id: 3,
          text: 'Artificial Insemination (AI)',
          value: 'Artificial Insemination (AI)',
        },
        {
          id: 4,
          text: 'Genomics',
          value: 'Genomics',
        },
        {
          id: 5,
          text: 'Embryo transfer ',
          value: 'Embryo transfer ',
        },
        {
          id: 6,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        },
      ],
      next: ({ answers }) => {
        if (
          answers['breeder-uses-genetic-tools'].value.some(
            item => item.value !== 'None of the above',
          )
        ) {
          return 'breeder-uses-genetic-tools-2'
        }
        return 'breeder-uses-genetic-tools-7'
      },
    },
    {
      id: 'breeder-uses-genetic-tools-2',
      type: multiSelect,
      required: true,
      disclaimer: 'Select all that apply',
      verticalGrid: true,
      text:
        'Which of the following genetic tools / practices have you used in your farm system in the past 12 months?',
      width: '24rem',
      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          let defaultAnswers = [
            {
              id: 1,
              text: 'Estimated Breeding Values (EBV)',
              value: 'Estimated Breeding Values (EBV)',
            },
            {
              id: 2,
              text: 'Breeding Indexes',
              value: 'Breeding Indexes',
            },
            {
              id: 3,
              text: 'Artificial Insemination (AI)',
              value: 'Artificial Insemination (AI)',
            },
            {
              id: 4,
              text: 'Genomics',
              value: 'Genomics',
            },
            {
              id: 5,
              text: 'Embryo transfer ',
              value: 'Embryo transfer ',
            },
            {
              id: 6,
              text: 'None of the above',
              value: 'None of the above',
              submit: true,
            },
          ]
          return defaultAnswers
        }

        let pipedAnswers = answers['breeder-uses-genetic-tools'].value.map(item => ({
          id: item.id,
          text: item.value,
          value: item.value,
        }))

        const other = {
          id: 6,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        }

        const updatedArray = [...pipedAnswers, other]

        return updatedArray
        // should we include default answers?
      },
      next: ({ answers }) => {
        let currentQuestionsLength = answers['breeder-uses-genetic-tools-2'].choices.length - 1

        if (answers['breeder-uses-genetic-tools-2'].value.length === currentQuestionsLength) {
          return 'breeder-uses-genetic-tools-4'
        }
        return 'breeder-uses-genetic-tools-3'
      },
    },
    // 2.5B
    {
      id: 'breeder-uses-genetic-tools-3',
      type: multiSelect,
      required: true,
      disclaimer: 'Select all that apply',
      verticalGrid: true,
      text:
        'Which of the following genetic tools / practices would you consider using in the future?',
      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          let defaultAnswers = [
            {
              id: 1,
              text: 'Estimated Breeding Values (EBV)',
              value: 'Estimated Breeding Values (EBV)',
            },
            {
              id: 2,
              text: 'Breeding Indexes',
              value: 'Breeding Indexes',
            },
            {
              id: 3,
              text: 'Artificial Insemination (AI)',
              value: 'Artificial Insemination (AI)',
            },
            {
              id: 4,
              text: 'Genomics',
              value: 'Genomics',
            },
            {
              id: 5,
              text: 'Embryo transfer ',
              value: 'Embryo transfer ',
            },
            {
              id: 6,
              text: 'None of the above',
              value: 'None of the above',
              submit: true,
            },
          ]
          return defaultAnswers
        }

        let pipedAnswers = answers['breeder-uses-genetic-tools-2'].value.map(item => ({
          id: item.id,
          text: item.value,
          value: item.value,
          submit: item.id === 6 ? true : false,
        }))

        let choices = answers['breeder-uses-genetic-tools-2'].choices
        let difference = choices.filter(item => !pipedAnswers.find(a => item.id === a.id))

        let filter = _.filter(difference, obj => {
          return obj.id !== 6
        })
        console.log('filter', filter)

        const other = {
          id: 6,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        }

        const updatedArray = [...filter, other]

        return _.uniq(updatedArray)
      },
      // add next if none of the above
    },

    {
      id: 'breeder-uses-genetic-tools-4',
      type: multiSelect,
      required: true,
      disclaimer: 'Select all that apply',
      verticalGrid: true,
      text: 'Which of the following genetic tools / practices do you want to learn more about?',
      width: '24rem',
      choices: [
        {
          id: 1,
          text: 'Estimated Breeding Values (EBV)',
          value: 'Estimated Breeding Values (EBV)',
        },
        {
          id: 2,
          text: 'Breeding Indexes',
          value: 'Breeding Indexes',
        },
        {
          id: 3,
          text: 'Artificial Insemination (AI)',
          value: 'Artificial Insemination (AI)',
        },
        {
          id: 4,
          text: 'Genomics',
          value: 'Genomics',
        },
        {
          id: 5,
          text: 'Embryo transfer ',
          value: 'Embryo transfer ',
        },
        {
          id: 6,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        },
      ],
    },

    //2.7B
    {
      id: 'breeder-uses-genetic-tools-5',
      // type: responsiveMatrix,
      type: 'responsiveMatrixTable',

      // required: true,

      // section: (
      //   <Wrapper>
      //     <Q>Questions 1 of 3</Q>
      //     <Text color={'#F9744C'}>Operations</Text>
      //     <Divider />
      //   </Wrapper>
      // ),
      // useValues: true,
      useIcons: true,
      text: 'How confident are you using genetic tools / practices?',

      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          let defaultAnswers = [
            {
              id: 1,
              text: 'Estimated Breeding Values (EBV)',
              value: 'Estimated Breeding Values (EBV)',
            },
            {
              id: 2,
              text: 'Breeding Indexes',
              value: 'Breeding Indexes',
            },
            {
              id: 3,
              text: 'Artificial Insemination (AI)',
              value: 'Artificial Insemination (AI)',
            },
            {
              id: 4,
              text: 'Genomics',
              value: 'Genomics',
            },
            {
              id: 5,
              text: 'Embryo transfer ',
              value: 'Embryo transfer ',
            },
            {
              id: 6,
              text: 'None of the above',
              value: 'None of the above',
              submit: true,
            },
          ]
          return defaultAnswers
        }

        let choicesArray = answers['breeder-uses-genetic-tools'].value.map(item => ({
          id: item.id,
          text: item.value,
          value: item.value,
        }))
        return choicesArray
      },

      // choices: [
      //   {
      //     id: 1,
      //     text: 'Estimated Breeding Values (EBV)',
      //     value: 'Estimated Breeding Values (EBV)',
      //   },
      //   {
      //     id: 2,
      //     text: 'Breeding Indexes',
      //     value: 'Breeding Indexes',
      //   },
      //   {
      //     id: 3,
      //     text: 'Artificial Insemination (AI)',
      //     value: 'Artificial Insemination (AI)',
      //   },
      //   {
      //     id: 4,
      //     text: 'Genomics',
      //     value: 'Genomics',
      //   },
      //   {
      //     id: 5,
      //     text: 'Embryo transfer ',
      //     value: 'Embryo transfer ',
      //   },
      // ],
      options: [
        {
          id: 1,
          text: 'I need to get help to use this tool / practice',
          value: 'I need to get help to use this tool / practice',
          icon: <IconSad />,
        },
        {
          id: 2,
          text: 'I have confidence in using this tool / practice on my own ',
          value: 'I have confidence in using this tool / practice on my own ',
          icon: <IconHappy />,
        },
        {
          id: 3,
          text: 'I have confidence to explain how to use this tool / practice to another farmer',
          value: 'I have confidence to explain how to use this tool / practice to another farmer',
          icon: <IconVeryHappy />,
        },
      ],
    },

    {
      id: 'breeder-uses-genetic-tools-6',
      type: multiChoice,
      required: true,
      text: 'How long have you been using genetic tools / practices?',
      choices: [
        {
          id: 1,
          text: 'Less than 6 months',
          value: 'Less than 6 months',
        },
        {
          id: 2,
          text: '6 - 12 months',
          value: '6 - 12 months',
        },
        {
          id: 3,
          text: '1 - 3 years',
          value: '1 - 3 years',
        },
        {
          id: 4,
          text: '3 - 10 years',
          value: '3 - 10 years',
        },
        {
          id: 5,
          text: '10 + years',
          value: '10 + years',
        },
      ],
    },
    {
      id: 'breeder-uses-genetic-tools-7',
      mobileHeight: '5.5rem',
      required: true,
      // section: () => {
      //   return (
      //     <Wrapper>
      //       <QuestionInfo>
      //         <GreenText>Issues and interventions</GreenText>
      //       </QuestionInfo>
      //       <Divider style={{ marginBottom: '1rem', marginTop: '0rem', width: '24rem' }} />
      //     </Wrapper>
      //   )
      // },
      width: '49.5rem',
      text:
        'Rank the following attributes based on what drives your clients’ bull buying decisions.',
      type: 'ranking',
      mobileHeight: '5rem',
      verticalGrid: true,
      choices: [
        {
          id: 1,
          text: 'Conformation / structure',
          value: 'Conformation / structure',
        },
        {
          id: 2,
          text: 'Maternal ability',
          value: 'Maternal ability',
        },
        {
          id: 3,
          text: 'Constitution / Hardiness',
          value: 'Constitution / Hardiness',
        },
        {
          id: 4,
          text: 'EBVs / indexes',
          value: 'EBVs / indexes',
        },
        {
          id: 5,
          text: 'Temperament ',
          value: 'Temperament ',
        },
      ],
      // next: ({ answers }) => {
      //   return 'awareness-intro'
      // },
    },
    {
      id: 'bull-buying-uses',
      type: multiChoice,
      required: true,
      text:
        'Have you recommended your bull buying clients use genetic tools when purchasing bulls in the past 12 months?',
      choices: [
        {
          id: 1,
          text: 'No',
          value: 'No',
          icon: <IconCross fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Yes',
          value: 'Yes',
          icon: <IconCheck fill="currentColor" />,
        },
      ],
      next: ({ answers }) => {
        return 'awareness-intro'
      },
    },
    {
      id: 'commercial-farmers',
      required: true,
      type: multiChoice,
      text: 'What is your farm system?',
      choices: [
        {
          id: 1,
          text: 'Breeder / finisher',
          value: 'Breeder / finisher',
        },
        {
          id: 2,
          text: 'Breeder (selling stores)',
          value: 'Breeder (selling stores)',
        },
        {
          id: 3,
          text: 'Finisher (buying stores)',
          value: 'Finisher (buying stores)',
        },
        {
          id: 4,
          text: 'Breeder - producing bulls for dairy',
          value: 'Breeder - producing bulls for dairy',
        },
        {
          id: 5,
          text: 'Dairy',
          value: 'Dairy',
        },
        { id: 6, type: input, text: 'Other, please specify', value: 'other' },
      ],
    },
    // 1.2
    {
      id: 'map',
      type: 'responsiveMultiMap',
      noRadio: true,
      required: true,
      verticalGrid: true,
      disclaimer: 'Hover over areas to select all regions that apply',
      choices: [
        { id: 1, name: 1, text: 'Northland ', value: 'Northland' },
        { id: 2, name: 2, text: 'Auckland ', value: 'Auckland' },
        { id: 3, name: 3, text: 'Waikato ', value: 'Waikato' },
        { id: 4, name: 4, text: 'Bay of Plenty ', value: 'Bay of Plenty' },
        { id: 5, name: 6, text: 'Gisborne ', value: 'Gisborne' },
        { id: 6, name: 6, text: "Hawke's Bay ", value: "Hawke's Bay" },
        { id: 7, name: 7, text: 'Taranaki ', value: 'Taranaki' },
        { id: 8, name: 8, text: 'Manawatū-Wanganui ', value: 'Manawatū-Wanganui' },
        { id: 9, name: 9, text: 'Wellington ', value: 'Wellington' },
        { id: 10, name: 10, text: 'West Coast ', value: 'West Coast' },
        { id: 11, name: 11, text: 'Canterbury ', value: 'Canterbury' },
        { id: 12, name: 12, text: 'Otago ', value: 'Otago' },
        { id: 13, name: 13, text: 'Southland ', value: 'Southland' },
        { id: 14, name: 14, text: 'Marlborough ', value: 'Marlborough' },
        { id: 15, name: 15, text: 'Nelson/Tasman ', value: 'Nelson/Tasman' },
        { id: 16, text: 'All regions', value: 'All regions' },
      ],
      text: 'What region(s) do you farm in?',
    },
    // 1.3
    {
      id: 'farmer-demographics',
      type: multiChoice,
      required: true,
      disclaimer: 'In stock UNITS',
      // section: (
      //   <Wrapper>
      //     <Q>Questions 1 of 3</Q>
      //     <Text color={'#F9744C'}>Demographics</Text>
      //     <Divider />
      //   </Wrapper>
      // ),
      // useValues: true,
      text: 'What is the total size of your operation?',
      choices: [
        {
          id: 1,
          text: '0 - 1999',
          value: '0 - 1999',
        },
        {
          id: 2,
          text: '2000 - 4999',
          value: '2000 - 4999',
        },
        {
          id: 3,
          text: '5,000 - 9,999',
          value: '5,000 - 9,999',
        },
        {
          id: 4,
          text: '10,000 - 19,999',
          value: '10,000 - 19,999',
        },
        {
          id: 5,
          text: '20,000+',
          value: '20,000+',
        },
        // { id: 6, type: input, text: 'Other, please specify', value: 'other' },
      ],
    },
    // 1.4
    {
      id: 'cattle-units',
      // required: true,

      // section: (
      //   <Wrapper>
      //     <Q>Questions 1 of 6</Q>
      //     <Text color={'#F9744C'}>Wellbeing</Text>
      //     <p style={{ marginTop: '0.5rem' }}>
      //       Please indicate your agreement with the following statements:
      //     </p>
      //     <Divider />
      //   </Wrapper>
      // ),
      text: 'What percentage of your total stock units are cattle?',
      type: responsiveSlider,
      showValue: true,
      usePercent: true,
      default: 0,
      // step: 5,
      choices: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    },
    // 2.1
    {
      id: 'farmer-knowledge-2.1',
      type: multiChoice,
      required: true,
      text:
        'Do you believe in describing animal performance by measuring attributes and looking at numbers (performance recording)?',
      choices: [
        {
          id: 1,
          text: 'No',
          value: 'No',
          icon: <IconCross fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Yes',
          value: 'Yes',
          icon: <IconCheck fill="currentColor" />,
        },
      ],
    },
    {
      id: 'farmer-knowledge-2.2',
      type: multiChoice,
      required: true,
      text: 'I buy my bulls from breeders who use a high level of performance recording.',
      choices: [
        {
          id: 1,
          text: 'Strongly agree',
          value: 'Strongly agree',
        },
        {
          id: 2,
          text: 'Agree',
          value: 'Agree',
        },
        {
          id: 3,
          text: 'Disagree',
          value: 'Disagree',
        },
        {
          id: 4,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
        },
      ],
    },
    {
      id: 'farmer-knowledge',
      type: multiChoice,
      required: true,
      mobileHeight: '7rem',
      disclaimer:
        'For example, Estimated Breeding Values (EBV), Breeding Indexes, Artificial Insemination, Genomics and/or Embryo transfer',

      // section: (
      //   <Wrapper>
      //     <Q>Questions 1 of 3</Q>
      //     <Text color={'#F9744C'}>Demographics</Text>
      //     <Divider />
      //   </Wrapper>
      // ),
      // useValues: true,
      text: 'Do you think using genetic tools is important for the future of the beef industry?',
      choices: [
        {
          id: 1,
          text: 'I’ve never thought about the industry using genetic tools ',
          value: 'I’ve never thought about the industry using genetic tools ',
        },
        {
          id: 2,
          text: 'I don’t think genetic tools will enhance the future of the beef industry',
          value: 'I don’t think genetic tools will enhance the future of the beef industry',
        },
        {
          id: 3,
          text: 'I think using genetic tools are needed to some degree',
          value: 'I think using genetic tools are needed to some degree',
        },
        {
          id: 4,
          text: 'I think genetic tools are vital for the future of the beef industry',
          value: 'I think genetic tools are vital for the future of the beef industry',
        },

        // { id: 6, type: input, text: 'Other, please specify', value: 'other' },
      ],
    },
    {
      id: 'farmer-knowledge-p2',
      type: multiChoice,
      required: true,
      mobileHeight: '5rem',

      // section: (
      //   <Wrapper>
      //     <Q>Questions 1 of 3</Q>
      //     <Text color={'#F9744C'}>Demographics</Text>
      //     <Divider />
      //   </Wrapper>
      // ),
      // useValues: true,
      text: 'Do you understand how to use genetic tools in your farm system?',
      choices: [
        {
          id: 1,
          text: 'I have no idea how I would use genetic tools in my farm system',
          value: 'I have no idea how I would use genetic tools in my farm system',
        },
        {
          id: 2,
          text: 'I have a vague idea of how I would use genetic tools in my farm system',
          value: 'I have a vague idea of how I would use genetic tools in my farm system',
        },
        {
          id: 3,
          text: 'I know generally how genetic tools would be used in my farm system',
          value: 'I know generally how genetic tools would be used in my farm system',
        },
        {
          id: 4,
          text: 'I am well aware of how genetic tools would fit into my farm system',
          value: 'I am well aware of how genetic tools would fit into my farm system',
        },

        // { id: 6, type: input, text: 'Other, please specify', value: 'other' },
      ],
    },
    {
      id: 'farmer-knowledge 2.5',
      buttonSize: '30rem',
      mobileHeight: '7rem',
      type: multiChoice,
      required: true,
      text: 'Could you explain how genetic tools are put together and work?',
      disclaimer:
        'For example, Estimated Breeding Values (EBV), Breeding Indexes, Artificial Insemination, Genomics and/or Embryo transfer',
      choices: [
        {
          id: 1,
          text: 'I have no idea how to explain how genetic tools are put together and work',
          value: 'I have no idea how to explain how genetic tools are put together and work',
        },
        {
          id: 2,
          text: 'I have a vague idea how to explain how genetic tools are put together and work',
          value: 'I have a vague idea how to explain how genetic tools are put together and work',
        },
        {
          id: 3,
          text: 'I know generally how to explain how genetic tools are put together and work',
          value: 'I know generally how to explain how genetic tools are put together and work',
        },
        {
          id: 4,
          text: 'I can confidently explain how genetic tools are put together and work',
          value: 'I can confidently explain how genetic tools are put together and work',
        },
      ],
    },
    // 2.3
    {
      id: 'farmer-knowledge-p3',
      type: multiChoice,
      required: true,
      text: 'Have you used any genetic tools in the past 12 months in your farm system?',
      disclaimer:
        'For example, Estimated Breeding Values (EBV), Breeding Indexes, Artificial Insemination, Genomics and/or Embryo transfer',
      // text: 'Do you use genetic tools in your farm system?',
      // text: (
      //   <p>
      //     Do you use genetic tools in your farm system? (
      //     <i>
      //       For example, Estimated Breeding Values (EBV), Breeding Indexes, Artificial Insemination,
      //       Genomics and/or Embryo transfer
      //     </i>
      //     {''})
      //   </p>
      // ),
      choices: [
        {
          id: 1,
          text: 'No',
          value: 'No',
          icon: <IconCross fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Yes',
          value: 'Yes',
          icon: <IconCheck fill="currentColor" />,
        },
      ],
      next: ({ answers }) => {
        if (answers['farmer-knowledge-p3'].value === 'Yes') {
          return 'uses-genetic-tools'
        }
        return 'no-genetic-tools'
        // return 'support-for-the-advisory-system' // change to support
      },
      // if no go to no-genetic tools
    },
    // 2.4A
    {
      id: 'no-genetic-tools',
      type: multiChoice,
      required: true,
      verticalGrid: true,
      text:
        'What is the main reason you haven’t used genetic tools in the past 12 months in your farm system?',
      width: '24rem',
      choices: [
        {
          id: 1,
          text: 'I am not aware of any genetic tools',
          value: 'I am not aware of any genetic tools',
        },
        {
          id: 2,
          text: 'I do not see the value that they could add to my farm system',
          value: 'I do not see the value that they could add to my farm system',
        },
        {
          id: 3,
          text: 'I do not know how to use them in my farm system',
          value: 'I do not know how to use them in my farm system',
        },

        {
          id: 4,
          type: input,
          text: 'Other, please specify',
          placeholder: 'Other, please specify',
          width: '24rem',
        },
      ],
    },
    // 2.5A
    {
      id: 'no-genetic-tools-p2',
      type: multiSelect,
      required: true,
      disclaimer: 'Select all that apply',
      verticalGrid: true,
      text: 'Which of the following genetic tools / practices have you heard about?',
      width: '24rem',
      choices: [
        {
          id: 1,
          text: 'Estimated Breeding Values (EBV)',
          value: 'Estimated Breeding Values (EBV)',
        },
        {
          id: 2,
          text: 'Breeding Indexes',
          value: 'Breeding Indexes',
        },
        {
          id: 3,
          text: 'Artificial Insemination (AI)',
          value: 'Artificial Insemination (AI)',
        },
        {
          id: 4,
          text: 'Genomics',
          value: 'Genomics',
        },
        {
          id: 5,
          text: 'Embryo transfer ',
          value: 'Embryo transfer ',
        },
        {
          id: 6,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        },
      ],
      next: ({ answers }) => {
        if (answers['no-genetic-tools-p2'].value.some(item => item.value !== 'None of the above')) {
          return 'no-genetic-tools-p3'
        }
        return 'no-genetic-tools-p4'
      },
    },
    // 2.6A
    {
      id: 'no-genetic-tools-p3',
      type: multiSelect,
      required: true,
      disclaimer: 'Select all that apply',
      verticalGrid: true,
      text:
        'Which of the following genetic tools / practices would you consider using in the future?',
      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          let defaultAnswers = [
            {
              id: 1,
              text: 'Estimated Breeding Values (EBV)',
              value: 'Estimated Breeding Values (EBV)',
            },
            {
              id: 2,
              text: 'Breeding Indexes',
              value: 'Breeding Indexes',
            },
            {
              id: 3,
              text: 'Artificial Insemination (AI)',
              value: 'Artificial Insemination (AI)',
            },
            {
              id: 4,
              text: 'Genomics',
              value: 'Genomics',
            },
            {
              id: 5,
              text: 'Embryo transfer ',
              value: 'Embryo transfer ',
            },
            {
              id: 6,
              text: 'None of the above',
              value: 'None of the above',
              submit: true,
            },
          ]
          return defaultAnswers
        }
        let pipedAnswers = answers['no-genetic-tools-p2'].value.map(item => ({
          id: item.id,
          text: item.value,
          value: item.value,
        }))

        const other = {
          id: 6,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        }

        const updatedArray = [...pipedAnswers, other]
        return _.uniq(updatedArray)

        // should we include default answers?
      },
    },
    // 2.7A
    {
      id: 'no-genetic-tools-p4',
      type: multiSelect,
      required: true,
      disclaimer: 'Select all that apply',
      verticalGrid: true,
      text: 'Which of the following genetic tools / practices would you like to learn more about?',
      width: '24rem',
      choices: [
        {
          id: 1,
          text: 'Estimated Breeding Values (EBV)',
          value: 'Estimated Breeding Values (EBV)',
        },
        {
          id: 2,
          text: 'Breeding Indexes',
          value: 'Breeding Indexes',
        },
        {
          id: 3,
          text: 'Artificial Insemination (AI)',
          value: 'Artificial Insemination (AI)',
        },
        {
          id: 4,
          text: 'Genomics',
          value: 'Genomics',
        },
        {
          id: 5,
          text: 'Embryo transfer ',
          value: 'Embryo transfer ',
        },
        {
          id: 6,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        },
      ],
    },
    {
      id: 'no-genetic-tools-p5',
      mobileHeight: '5.5rem',
      required: true,
      // section: () => {
      //   return (
      //     <Wrapper>
      //       <QuestionInfo>
      //         <GreenText>Issues and interventions</GreenText>
      //       </QuestionInfo>
      //       <Divider style={{ marginBottom: '1rem', marginTop: '0rem', width: '24rem' }} />
      //     </Wrapper>
      //   )
      // },
      width: '49.5rem',
      text: 'When buying a bull, what importance do you place on the following?',
      type: 'ranking',
      mobileHeight: '5rem',
      verticalGrid: true,
      choices: [
        {
          id: 1,
          text: 'Conformation / structure',
          value: 'Conformation / structure',
        },
        {
          id: 2,
          text: 'Maternal ability',
          value: 'Maternal ability',
        },
        {
          id: 3,
          text: 'Constitution / Hardiness',
          value: 'Constitution / Hardiness',
        },
        {
          id: 4,
          text: 'EBVs / indexes',
          value: 'EBVs / indexes',
        },
        {
          id: 5,
          text: 'Temperament ',
          value: 'Temperament ',
        },
      ],
      next: ({ answers }) => {
        return 'awareness-intro'
      },
    },
    // 2.4 B
    {
      id: 'uses-genetic-tools',
      type: multiSelect,
      required: true,
      disclaimer: 'Select all that apply',
      verticalGrid: true,
      text: 'Which of the following genetic tools / practices have you heard about?',
      width: '24rem',
      choices: [
        {
          id: 1,
          text: 'Estimated Breeding Values (EBV)',
          value: 'Estimated Breeding Values (EBV)',
        },
        {
          id: 2,
          text: 'Breeding Indexes',
          value: 'Breeding Indexes',
        },
        {
          id: 3,
          text: 'Artificial Insemination (AI)',
          value: 'Artificial Insemination (AI)',
        },
        {
          id: 4,
          text: 'Genomics',
          value: 'Genomics',
        },
        {
          id: 5,
          text: 'Embryo transfer ',
          value: 'Embryo transfer ',
        },
        {
          id: 6,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        },
      ],
      next: ({ answers }) => {
        if (answers['uses-genetic-tools'].value.some(item => item.value !== 'None of the above')) {
          return 'uses-genetic-tools-2'
        }
        return 'uses-genetic-tools-7'
      },
    },
    // 2.5B
    {
      id: 'uses-genetic-tools-2',
      type: multiSelect,
      required: true,
      disclaimer: 'Select all that apply',
      verticalGrid: true,
      text: 'Which of the following genetic tools / practices have you used in the past 12 months?',
      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          let defaultAnswers = [
            {
              id: 1,
              text: 'Estimated Breeding Values (EBV)',
              value: 'Estimated Breeding Values (EBV)',
            },
            {
              id: 2,
              text: 'Breeding Indexes',
              value: 'Breeding Indexes',
            },
            {
              id: 3,
              text: 'Artificial Insemination (AI)',
              value: 'Artificial Insemination (AI)',
            },
            {
              id: 4,
              text: 'Genomics',
              value: 'Genomics',
            },
            {
              id: 5,
              text: 'Embryo transfer ',
              value: 'Embryo transfer ',
            },
            {
              id: 6,
              text: 'None of the above',
              value: 'None of the above',
              submit: true,
            },
          ]
          return defaultAnswers
        }
        let pipedAnswers = answers['uses-genetic-tools'].value.map(item => ({
          id: item.id,
          text: item.value,
          value: item.value,
        }))

        const other = {
          id: 6,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        }

        const updatedArray = [...pipedAnswers, other]

        console.log('up', updatedArray)
        return _.uniq(updatedArray)
        // should we include default answers?
      },
      next: ({ answers }) => {
        let currentQuestionsLength = answers['uses-genetic-tools-2'].choices.length - 1

        if (answers['uses-genetic-tools-2'].value.length === currentQuestionsLength) {
          return 'uses-genetic-tools-4'
        }
        return 'uses-genetic-tools-3'
      },
      // add next if none of the above
    },
    // 2.6B
    {
      id: 'uses-genetic-tools-3',
      type: multiSelect,
      // required: true,
      disclaimer: 'Select all that apply',
      verticalGrid: true,
      text:
        'Which of the following genetic tools / practices would you consider using in the future?',
      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          let defaultAnswers = [
            {
              id: 1,
              text: 'Estimated Breeding Values (EBV)',
              value: 'Estimated Breeding Values (EBV)',
            },
            {
              id: 2,
              text: 'Breeding Indexes',
              value: 'Breeding Indexes',
            },
            {
              id: 3,
              text: 'Artificial Insemination (AI)',
              value: 'Artificial Insemination (AI)',
            },
            {
              id: 4,
              text: 'Genomics',
              value: 'Genomics',
            },
            {
              id: 5,
              text: 'Embryo transfer ',
              value: 'Embryo transfer ',
            },
            {
              id: 6,
              text: 'None of the above',
              value: 'None of the above',
              submit: true,
            },
          ]
          return defaultAnswers
        }
        let pipedAnswers = answers['uses-genetic-tools-2'].value.map(item => ({
          id: item.id,
          text: item.value,
          value: item.value,
          submit: item.id === 6 ? true : false,
        }))

        let choices = answers['uses-genetic-tools-2'].choices
        let difference = choices.filter(item => !pipedAnswers.find(a => item.id === a.id))

        console.log('array', difference)
        let filter = _.filter(difference, obj => {
          return obj.id !== 6
        })
        console.log('filter', filter)

        const other = {
          id: 6,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        }

        const updatedArray = [...filter, other]

        console.log('test', _.uniq(updatedArray, 'id'))

        return _.uniq(updatedArray, 'id')
      },
      // add next if none of the above
    },
    // 2.7B
    {
      id: 'uses-genetic-tools-4',
      type: multiSelect,
      required: true,
      disclaimer: 'Select all that apply',
      verticalGrid: true,
      text: 'Which of the following genetic tools / practices would you like to learn more about?',
      width: '24rem',
      choices: [
        {
          id: 1,
          text: 'Estimated Breeding Values (EBV)',
          value: 'Estimated Breeding Values (EBV)',
        },
        {
          id: 2,
          text: 'Breeding Indexes',
          value: 'Breeding Indexes',
        },
        {
          id: 3,
          text: 'Artificial Insemination (AI)',
          value: 'Artificial Insemination (AI)',
        },
        {
          id: 4,
          text: 'Genomics',
          value: 'Genomics',
        },
        {
          id: 5,
          text: 'Embryo transfer ',
          value: 'Embryo transfer ',
        },
        {
          id: 6,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        },
      ],
    },
    // {
    //   id: 'uses-genetic-tools-5',
    //   type: multiSelect,
    //   required: true,
    //   disclaimer: 'Select all that apply',
    //   verticalGrid: true,
    //   text:
    //     'Which of the following genetic tools / practices would you like to learn more about? (select all that apply)',
    //   width: '24rem',
    //   choices: [
    //     {
    //       id: 1,
    //       text: 'Estimated Breeding Values (EBV)',
    //       value: 'Estimated Breeding Values (EBV)',
    //     },
    //     {
    //       id: 2,
    //       text: 'Breeding Indexes',
    //       value: 'Breeding Indexes',
    //     },
    //     {
    //       id: 3,
    //       text: 'Artificial Insemination (AI)',
    //       value: 'Artificial Insemination (AI)',
    //     },
    //     {
    //       id: 4,
    //       text: 'Genomics',
    //       value: 'Genomics',
    //     },
    //     {
    //       id: 5,
    //       text: 'Embryo transfer ',
    //       value: 'Embryo transfer ',
    //     },
    //     {
    //       id: 4,
    //       text: 'None of the above',
    //       value: 'None of the above',
    //       submit: true,
    //     },
    //   ],
    // },
    // 2.7B
    {
      id: 'uses-genetic-tools-6',
      type: 'responsiveMatrixTable',
      required: true,
      // section: (
      //   <Wrapper>
      //     <Q>Questions 1 of 3</Q>
      //     <Text color={'#F9744C'}>Operations</Text>
      //     <Divider />
      //   </Wrapper>
      // ),
      // useValues: true,
      useIcons: true,
      text: 'How confident are you using genetic tools / practices?',

      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          console.log('inside default answers')
          // defaultAnswers is used to build excel table
          let defaultAnswers = [
            {
              id: 1,
              text: 'Estimated Breeding Values (EBV)',
              value: 'Estimated Breeding Values (EBV)',
            },
            {
              id: 2,
              text: 'Breeding Indexes',
              value: 'Breeding Indexes',
            },
            {
              id: 3,
              text: 'Artificial Insemination (AI)',
              value: 'Artificial Insemination (AI)',
            },
            {
              id: 4,
              text: 'Genomics',
              value: 'Genomics',
            },
            {
              id: 5,
              text: 'Embryo transfer ',
              value: 'Embryo transfer ',
            },
            {
              id: 6,
              text: 'None of the above',
              value: 'None of the above',
              submit: true,
            },
          ]
          console.log('default answers returns', defaultAnswers)
          return defaultAnswers
        }
        let choicesArray = answers['uses-genetic-tools'].value.map(item => ({
          id: item.id,
          text: item.value,
          value: item.value,
        }))
        console.log('dynamic Choices generated', choicesArray)
        return choicesArray
      },

      //   choices: [
      //   {
      //     id: 1,
      //     text: 'Estimated Breeding Values (EBV)',
      //     value: 'Estimated Breeding Values (EBV)',
      //   },
      //   {
      //     id: 2,
      //     text: 'Breeding Indexes',
      //     value: 'Breeding Indexes',
      //   },
      //   {
      //     id: 3,
      //     text: 'Artificial Insemination (AI)',
      //     value: 'Artificial Insemination (AI)',
      //   },
      //   {
      //     id: 4,
      //     text: 'Genomics',
      //     value: 'Genomics',
      //   },
      //   {
      //     id: 5,
      //     text: 'Embryo transfer ',
      //     value: 'Embryo transfer ',
      //   },
      // ],
      options: [
        {
          id: 1,
          text: 'I need to get help to use this tool / practice',
          value: 'I need to get help to use this tool / practice',
          icon: <IconSad />,
        },
        {
          id: 2,
          text: 'I have confidence in using this tool / practice on my own ',
          value: 'I have confidence in using this tool / practice on my own ',
          icon: <IconHappy />,
        },
        {
          id: 3,
          text: 'I have confidence to explain how to use this tool / practice to another farmer',
          value: 'I have confidence to explain how to use this tool / practice to another farmer',
          icon: <IconVeryHappy />,
        },
      ],
    },

    {
      id: 'uses-genetic-tools-7',
      type: multiChoice,
      required: true,
      text: 'How long have you been using genetic tools / practices?',
      choices: [
        {
          id: 1,
          text: 'Less than 6 months',
          value: 'Less than 6 months',
        },
        {
          id: 2,
          text: '6 - 12 months',
          value: '6 - 12 months',
        },
        {
          id: 3,
          text: '1 - 4 years',
          value: '1 - 4 years',
        },
        {
          id: 4,
          text: '5 - 9 years',
          value: '5 - 9 years',
        },
        {
          id: 5,
          text: '10 + years',
          value: '10 + years',
        },
      ],
    },
    {
      id: 'uses-genetic-tools-8',
      mobileHeight: '5.5rem',
      required: true,
      // section: () => {
      //   return (
      //     <Wrapper>
      //       <QuestionInfo>
      //         <GreenText>Issues and interventions</GreenText>
      //       </QuestionInfo>
      //       <Divider style={{ marginBottom: '1rem', marginTop: '0rem', width: '24rem' }} />
      //     </Wrapper>
      //   )
      // },
      width: '49.5rem',
      text: 'When buying a bull, what importance do you place on the following?',
      type: 'ranking',
      mobileHeight: '5rem',
      verticalGrid: true,
      choices: [
        {
          id: 1,
          text: 'Conformation / structure',
          value: 'Conformation / structure',
        },
        {
          id: 2,
          text: 'Maternal ability',
          value: 'Maternal ability',
        },
        {
          id: 3,
          text: 'Constitution / Hardiness',
          value: 'Constitution / Hardiness',
        },
        {
          id: 4,
          text: 'EBVs / indexes',
          value: 'EBVs / indexes',
        },
        {
          id: 5,
          text: 'Temperament ',
          value: 'Temperament ',
        },
      ],
      next: ({ answers }) => {
        return 'awareness-intro'
      },
    },
    {
      id: 'awareness-intro',
      type: welcomePage,
      section: (
        <Wrapper>
          {/* <Q>Questions 1 of 6</Q> */}
          <Text color={'#188FA3'}>
            Awareness of B+LNZ Genetics and the Informing New Zealand Beef programme (INZB)
          </Text>
          {/* <p style={{ marginTop: '0.5rem' }}>
            Please indicate your agreement with the following statements:
          </p> */}
          <Divider />
        </Wrapper>
      ),
      text:
        'This section aims to understand your awareness and involvement with both Beef + Lamb Genetics (a subsidiary of Beef + Lamb New Zealand) and their Informing New Zealand Beef (INZB) programme. ',
    },

    {
      id: 'awareness-inzb-programme',
      // type: responsiveMatrix,
      type: 'responsiveMatrixTable',

      hide: true,
      useValues: true,
      useColumns: true,
      // required: true,
      width: '12rem',
      height: '6rem',
      // additionalContent: 'button',
      text: 'What has your involvement been in each of the following?',
      choices: [
        {
          id: 1,
          text: 'A) B+LNZ Genetics organisation ',
          value: 'B+LNZ Genetics organisation ',
        },
        {
          id: 2,
          text: 'B) nProve',
          value: 'nProve',
        },
        {
          id: 3,
          text: 'C) Informing NZ Beef Programme (INZB)',
          value: 'Informing NZ Beef Programme (INZB)',
        },
      ],

      options: [
        {
          id: 1,
          text: 'I haven’t heard of this before',
          value: 'I haven’t heard of this before',
        },
        {
          id: 2,
          text: 'It sounds vaguely familiar but doesn’t mean anything to me',
          value: 'It sounds vaguely familiar but doesn’t mean anything to me',
        },
        {
          id: 3,
          text:
            'I have heard about it and understand what it does but I have never interacted with it',
          value:
            'I have heard about it and understand what it does but I have never interacted with it',
        },
        {
          id: 4,
          text: 'I have / or do interact with it',
          value: 'I have / or do interact with it',
        },
      ],
    },
    {
      id: 'awareness-2',
      type: multiChoice,
      required: true,
      mobileHeight: '5rem',
      buttonSize: '30rem',
      // width: '50rem',
      text:
        'Which of the following best describes you regarding the Informing New Zealand Beef (INZB) programme?',
      choices: [
        {
          id: 1,
          text: 'I am not aware of the tools created by INZB',
          value: 'I am not aware of the tools created by INZB',
        },
        {
          id: 2,
          text:
            'I know about the tools created by INZB and how they should be used, but I have never used them',
          value:
            'I know about the tools created by INZB and how they should be used, but I have never used them',
        },
        {
          id: 3,
          text: 'I am using the tools created by INZB on my farm',
          value: 'I am using the tools created by INZB on my farm',
        },
        {
          id: 4,
          text: 'I know enough about the INZB tools to tell another farmer about it',
          value: 'I know enough about the INZB tools to tell another farmer about it',
        },
      ],
    },

    {
      id: 'awareness-3',
      required: true,
      type: multiSelect,
      text: (
        <StyledTextbox style={{ alignItems: 'baseline' }}>
          <StyledP>
            What would be your preferred way to <b>hear</b> about INZB projects and tools?
          </StyledP>
        </StyledTextbox>
      ),
      disclaimer: 'Select all that apply',
      choices: [
        {
          id: 1,
          text: 'At events ',
          value: 'At events ',
        },
        {
          id: 2,
          text: 'Print publications (e.g. news articles)',
          value: 'Print publications (e.g. news articles)',
        },
        {
          id: 3,
          text: 'Social media',
          value: 'Social media',
        },
        {
          id: 4,
          text: 'Podcasts',
          value: 'Podcasts',
        },
        {
          id: 5,
          text: 'Direct email / newsletters',
          value: 'Direct email / newsletters',
        },
        {
          id: 6,
          text: 'B+LNZ Website',
          value: 'B+LNZ Website',
        },
        { id: 7, type: input, text: 'Other, please specify', value: 'other' },
        // {
        //   id: 8,
        //   text: 'I do not want to hear about INZB projects and tools',
        //   value: 'I do not want to hear about INZB projects and tools',
        //   submit: true,
        // },
      ],
    },
    {
      id: 'awareness-4',
      required: true,
      type: multiSelect,
      text: (
        <StyledTextbox style={{ alignItems: 'baseline' }}>
          <StyledP>
            Which way do you prefer to <b>learn</b> more about INZB projects and how to use these
            tools?
          </StyledP>
        </StyledTextbox>
      ),
      disclaimer: 'Select all that apply',
      choices: [
        {
          id: 1,
          text: 'Face-to-face Workshops',
          value: 'Face-to-face Workshops',
        },
        {
          id: 2,
          text: 'Webinars',
          value: 'Webinars',
        },
        {
          id: 11,
          text: 'Field days',
          value: 'Field days',
        },
        {
          id: 3,
          text: 'Online learning modules',
          value: 'Online learning modules',
        },
        {
          id: 4,
          text: 'Podcasts',
          value: 'Podcasts',
        },
        {
          id: 5,
          text: 'Reading printed resources/books',
          value: 'Reading printed resources/books',
        },
        {
          id: 6,
          text: 'Social media',
          value: 'Social media',
        },

        {
          id: 7,
          text: 'Watching a video',
          value: 'Watching a video',
        },
        {
          id: 8,
          text: 'Website',
          value: 'Website',
        },
        { id: 9, type: input, text: 'Other, please specify', value: 'other' },
        // {
        //   id: 10,
        //   text: 'I do not want to learn about INZB projects and tools',
        //   value: 'I do not want to learn about INZB projects and tools',
        //   submit: true,
        // },
      ],
    },
    {
      id: 'comments',
      text: 'Do you have any other comments you would like to make?',
      type: commentBox,
    },
    {
      id: 'age',
      text: 'What age range do you fall in to?',
      type: multiChoice,
      required: true,
      choices: [
        { id: 1, text: '< 18', value: '< 18' },
        { id: 7, text: '18 - 30', value: '18 - 30' },
        { id: 2, text: '31 - 40', value: '31 - 40' },
        { id: 3, text: '41 - 50', value: '41 - 50' },
        { id: 4, text: '51 - 60', value: '51 - 60' },
        { id: 5, text: '61+', value: '61+' },
        { id: 6, text: 'Prefer not to say', value: 'Prefer not to say' },
      ],
    },
    // {
    //   id: 'rate-experience',
    //   // section: (
    //   //   <Wrapper>
    //   //     <Q>Questions 1 of 6</Q>
    //   //     <Text color={'#F9744C'}>Wellbeing</Text>
    //   //     <p style={{ marginTop: '0.5rem' }}>
    //   //       Please indicate your agreement with the following statements:
    //   //     </p>
    //   //     <Divider />
    //   //   </Wrapper>
    //   // ),
    //   text: 'Please rate your survey experience from 1-10',
    //   type: responsiveSlider,
    //   showValue: true,
    //   default: 1,
    //   step: 1,
    //   // step: 5,
    //   // usePercent: true,
    //   max: 10,
    //   choices: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    // },
    // {
    //   id: 'improvements-comments',
    //   type: commentBox,
    //   text: 'Do you have any feedback for us to improve the survey?',
    //   placeholder: 'Please specify',
    // },
    {
      id: 'end-page',
      type: 'endPage',
      text: ({ answers }) => {
        return (
          <Wrapper>
            <Text style={{ fontWeight: 'bold' }} color={'#188FA3'}>
              Thank you!
            </Text>
            <Divider />
            <p style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              Thank you for taking the survey. Your answers are automatically submitted. You are
              still able to go back and review your answers, any changes will be automatically
              updated.
            </p>
          </Wrapper>
        )
      },
    },
  ],
  settings: {
    writeToFirestore: true,
    surveyName: 'inzb-industry-survey',
    primaryColour: '#188FA3',
    hideButton: true,
    // showQid: true,
    useSections: false,
    // secondaryColour: '#f38231',
    // contrastColour: '#fff',
    // hideSubmit: true,
    hideLogo: false,
    logoFirstPageOnly: true,
    companyLogo: logo,
    logoLocation: 'space-between',
    progressBar: true,
    objectPosition: 'center',
    leftBackgroundImage,
    backgroundImage: leftBackgroundImage,
    // primaryColour: '#05B153',
    // useVideo: true,
    // backgroundVideo,
  },
}

export default Pias

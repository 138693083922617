import _ from 'lodash'
import testSurvey from './refactor-test'
import GreenBayHigh from './GreenBayHigh'
import GreenBayHighCommunity from './GreenBayHigh-com'
import Pias from './PIAS'
import GreenbayHighStaff from './GreenBayHigh-staff'
import Stepchange from './StepChange'
import StepchangeCo from './StepChangeCo'
import StepChangeV2 from './StepChangev2'
import StepChangeV3 from './StepChangev3'
import StepChangeDel from './Stepchange-DEL'
import CNA from './CNA'
import DragDropDemo from './dragdropdemo'
import SurveyDemo from './surveydemo'
import BCITO from './BCITO'
import AggTest from './AggTest'
import Scratch from './Scratch'
import OldPias from './OldPias'
import LandingPage from './LandingPage'
import DemoSurvey from './DemoSurvey'
import HillCountryFuture from './HillCountryFutures'
import E350Preworkshop from './PreE350'
import E350Workshop from './E350Workshop'
import E350Graphs from './E350Graphs'
import Scarlatti from './InternalSurvey-Scarlatti'
import NavigationTool from './navigation-tool'
import ESInternal from './es-internal'
import INZBIndustry from './INZB-longitudinal'
import ConjointTest from './ConjointTest'
import ConjointV2 from './Conjointv2'

export const SurveysObj = {
  ConjointTest,
  ConjointV2,
  INZBIndustry,
  ESInternal,
  Scarlatti,
  testSurvey,
  GreenBayHigh,
  GreenBayHighCommunity,
  OldPias,
  HillCountryFuture,
  Pias,
  E350Preworkshop,
  E350Workshop,
  E350Graphs,
  DemoSurvey,
  GreenbayHighStaff,
  Stepchange,
  StepChangeDel,
  StepchangeCo,
  StepChangeV2,
  StepChangeV3,
  CNA,
  DragDropDemo,
  SurveyDemo,
  BCITO,
  AggTest,
  Scratch,
  LandingPage,
  NavigationTool,
}

export const Surveys = _.mapKeys(SurveysObj, val => val.settings.surveyName.toLowerCase())

// const Surveys = {}
export default Surveys

import React from 'react'
import 'antd/dist/antd.css'
import { Radio } from 'antd'
import _ from 'lodash'
import styled from 'styled-components/macro'
import RadioGroup from 'antd/lib/radio/group'
import TextArea from 'antd/lib/input/TextArea'
import PropTypes from 'prop-types'

const ImageRadioList = ({ question, choices, updateCurrent, currentAnswer, settings }) => {
  const { primaryColour } = settings

  const dynamicColour = !_.isUndefined(primaryColour) ? primaryColour : '#E82D7C'
  return (
    <Container>
      {choices.map((item, index) => {
        if (item.type && item.type === 'commentBox') {
          return (
            <div style={{ marginTop: '1rem' }}>
              <StyledTextArea
                type="text"
                allowClear
                dynamicColour={dynamicColour}
                placeholder={question.placeholder}
                value={currentAnswer.comments ? currentAnswer.comments : ''}
                autoSize={{ minRows: 4, maxRows: 6 }}
                onChange={e => {
                  const inputValues = {
                    value: e.target.value,
                  }
                  const newAnswer = {
                    ...currentAnswer,
                  }
                  newAnswer.comments = inputValues.value
                  updateCurrent(newAnswer)
                }}
              />
            </div>
          )
        }
        return (
          <Item dynamicColour={dynamicColour}>
            <Text>{item.text}</Text>
            <StyledRadioGroup buttonStyle="solid">
              {item.answerChoices.map(choice => (
                <Radio.Button
                  key={choice.id}
                  value={choice.value}
                  checked={
                    currentAnswer.value &&
                    currentAnswer.value[index] &&
                    currentAnswer.value[index].value === choice.value
                  }
                  onChange={e => {
                    const answer = {
                      value: e.target.value,
                    }
                    const newAnswer = {
                      ...currentAnswer,
                    }

                    if (!currentAnswer.value) {
                      newAnswer.value = new Array(choices.length)
                      newAnswer.value.fill({ text: '', value: '' })
                    }
                    newAnswer.value[index] = {
                      id: item.id,
                      text: item.text,
                      value: answer.value,
                    }

                    updateCurrent(newAnswer)
                  }}
                >
                  <Icon
                    checked={
                      currentAnswer.value &&
                      currentAnswer.value[index] &&
                      currentAnswer.value[index].value === choice.value
                    }
                    fill={choice.fill}
                  >
                    {choice.icon}
                  </Icon>
                </Radio.Button>
              ))}
            </StyledRadioGroup>
          </Item>
        )
      })}
    </Container>
  )
}

const Text = styled.div`
  display: flex;
  flex: 1 1;
  align-items: flex-start;
  color: #262626;
  font-size: 15px;
  line-height: 22px;
  padding-left: 12px;
  padding-right: 12px;
`

const Container = styled.div`
  margin-top: 1rem;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #fff;
  margin-bottom: calc(1vh + 0.5rem);
  border-radius: 3px;
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: ${props => props.dynamicColour};
    border-color: ${props => props.dynamicColour};
  }
  .ant-radio-button-wrapper:hover {
    color: ${props => props.dynamicColour};
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: ${props => props.dynamicColour};
    border-color: ${props => props.dynamicColour};
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: ${props => props.dynamicColour};
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 85vw;
  }
`

const Icon = styled.span`
  color: ${props => (props.checked ? props.dynamicColour : props.fill)};

  svg {
    height: 1rem;
    width: 1rem;
    margin-top: 0.5rem;
    fill: ${props => (props.checked ? props.dynamicColour : props.fill)};
  }
`

const StyledRadioGroup = styled(RadioGroup)`
  padding-right: 12px;
`

const StyledTextArea = styled(TextArea)`
  width: 790px;
  padding-right: 10px;
  .ant-input:focus,
  .ant-input-focused {
    border-color: ${props => props.dynamicColour};
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 90vw;
    margin: auto;
    align-items: center;
  }
  @media (min-width: 480px) and (max-width: 768px) {
    margin: auto;
    width: 90vw;
    align-items: center;
  }
  @media (min-width: 768px) and (max-width: 1025px) {
    margin: auto;
    width: 80vw;
    align-items: center;
  }
`

ImageRadioList.propTypes = {
  currentAnswer: PropTypes.instanceOf(Object),
  choices: PropTypes.instanceOf(Array),
  updateCurrent: PropTypes.func,
  settings: PropTypes.instanceOf(Object),
}

export default ImageRadioList

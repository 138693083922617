import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import _ from 'lodash'
import { conjointAnalysis } from './QuestionTypes'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

const ConjointAnalysis = ({
  question,
  updateCurrent,
  currentAnswer,
  settings,
  answers,
  multiChoiceArray,
  submitAnswer,
}) => {
  const [choicePair, updateChoicePair] = useState()
  const [req, updateReq] = useState(true)
  const [choicesMade, updateChoicesMade] = useState(0)

  const mockRequest = objC => {
    // skip button
    // equal choices button
    console.log('settings', settings)
    console.log('mock request sent')

    const API_ROUTE = 'https://backend-conjoint.confer.online/get_question'

    let test = {
      attributes: question.attributes,
      id: settings.uuid,
      answer: null,
      choicePair: null,
    }

    let userChoice = !_.isUndefined(objC) ? objC : test

    return axios.post(API_ROUTE, userChoice).then(
      res => {
        return res.data
      },
      err => {
        return err
      },
    )
  }

  const transformPartworths = partworths => {
    return _.flatten(
      partworths.map(attr =>
        attr.levels.map(level => ({
          label: attr.value + ' - ' + level.value,
          value: level.partworth,
        })),
      ),
    )
  }

  const postRequest = async objC => {
    const data = await mockRequest(objC)
    console.log('data', data)
    console.log('pw', transformPartworths(data.partworths))
    updateCurrent({
      ...currentAnswer,
      value: transformPartworths(data.partworths),
    })

    if ((data.choicePair === null && req === false) || choicesMade >= question.maxChoices) {
      submitAnswer()
    } else {
      updateChoicePair(data.choicePair)
      updateChoicesMade(choicesMade + 1)
    }
  }

  const renderLabel = (a, b) => {
    let myVar = a.attributes.find(item => item.id === b['attributes'][0]).value
    return myVar
  }

  useEffect(() => {
    console.log('use effect fired')
    console.log('waiting to send request')
    updateReq(false) // first API request sent, flag is needed for survey progression
    postRequest()
  }, [])

  console.log('choicePair', choicePair)

  return (
    <Container>
      {!_.isUndefined(choicePair) ? (
        <div>
          <ButtonContainer>
            <Wrapper>
              <Button
                type="button"
                onClick={() => {
                  updateChoicePair()

                  const newAnswer = {
                    ...currentAnswer,
                  }

                  const savedChoicePair = {
                    ...choicePair,
                    levels: _.fromPairs(_.map(choicePair.levels, (val, ind) => [ind, val])),
                  }

                  newAnswer.value = {
                    attributes: question.attributes,
                    id: settings.uuid,
                    answer: 1,
                    choicePair: choicePair,
                  }

                  updateCurrent({
                    ...newAnswer,
                    value: { ...newAnswer.value, choicePair: savedChoicePair },
                  })
                  postRequest(newAnswer.value)
                }}
              >
                <InnerContainer>
                  <InnerCard>
                    <h1>
                      {
                        question.attributes
                          .find(item => item.id === choicePair['attributes'][0])
                          .levels.find(level => level.id === choicePair['levels'][0][0]).text
                      }
                      {/* {renderLabel(question, choicePair)} */}

                      {/* {
                        question.attributes.find(item => item.id === choicePair['attributes'][0])
                          .value
                      } */}
                    </h1>
                    <p>
                      {
                        question.attributes
                          .find(item => item.id === choicePair['attributes'][0])
                          .levels.find(level => level.id === choicePair['levels'][0][0]).icon
                      }
                    </p>
                  </InnerCard>
                  <p>&</p>
                  <InnerCard>
                    <h1>
                      {
                        question.attributes
                          .find(item => item.id === choicePair['attributes'][1])
                          .levels.find(level => level.id === choicePair['levels'][0][1]).text
                      }
                      {/* {
                        question.attributes.find(item => item.id === choicePair['attributes'][1])
                          .value
                      } */}
                    </h1>
                    <p>
                      {
                        question.attributes
                          .find(item => item.id === choicePair['attributes'][1])
                          .levels.find(level => level.id === choicePair['levels'][0][1]).icon
                      }
                    </p>
                  </InnerCard>
                </InnerContainer>
              </Button>
            </Wrapper>
            <Text>
              <h1>Or</h1>
            </Text>
            <Wrapper>
              <Button
                type="button"
                onClick={() => {
                  updateChoicePair()

                  const newAnswer = {
                    ...currentAnswer,
                  }

                  const savedChoicePair = {
                    ...choicePair,
                    levels: _.fromPairs(_.map(choicePair.levels, (val, ind) => [ind, val])),
                  }

                  newAnswer.value = {
                    attributes: question.attributes,
                    id: settings.uuid,
                    answer: 2,
                    choicePair: choicePair,
                  }

                  updateCurrent({
                    ...newAnswer,
                    value: { ...newAnswer.value, choicePair: savedChoicePair },
                  })
                  postRequest(newAnswer.value)
                }}
              >
                <InnerContainer>
                  <InnerCard>
                    <h1>
                      {
                        question.attributes
                          .find(item => item.id === choicePair['attributes'][0])
                          .levels.find(level => level.id === choicePair['levels'][1][0]).text
                      }
                      {/* {
                        question.attributes.find(item => item.id === choicePair['attributes'][0])
                          .value
                      } */}
                    </h1>
                    <p>
                      {
                        question.attributes
                          .find(item => item.id === choicePair['attributes'][0])
                          .levels.find(level => level.id === choicePair['levels'][1][0]).icon
                      }
                    </p>
                  </InnerCard>
                  <p>&</p>
                  <InnerCard>
                    <h1>
                      {
                        question.attributes
                          .find(item => item.id === choicePair['attributes'][1])
                          .levels.find(level => level.id === choicePair['levels'][1][1]).text
                      }
                      {/* {
                        question.attributes.find(item => item.id === choicePair['attributes'][1])
                          .value
                      } */}
                    </h1>
                    <p>
                      {
                        question.attributes
                          .find(item => item.id === choicePair['attributes'][1])
                          .levels.find(level => level.id === choicePair['levels'][1][1]).icon
                      }
                    </p>
                  </InnerCard>
                </InnerContainer>
              </Button>
            </Wrapper>
          </ButtonContainer>
        </div>
      ) : (
        <Spin />
      )}
    </Container>
  )
}

const Wrapper = styled.div`
  width: 23rem;
  height: 24rem;

  @media (min-width: 0px) and (max-width: 479px) {
    /* small viewport  */
    height: 10rem;
    width: inherit;
  }
`
const Text = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 1.5rem;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 80vw;
  max-width: 800px;
  justify-content: space-between;

  @media (min-width: 0px) and (max-width: 479px) {
    /* small viewport  */
    flex-direction: column;
    /* background-color: green; */
    width: 90vw;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50rem;
  @media (min-width: 0px) and (max-width: 479px) {
    /* small viewport  */
    width: 90vw;
  }
`

const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  p {
    margin-bottom: 0;
  }
  height: 100%;
`
const InnerCard = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 12px 10px; */
  width: 45%;
  h1 {
    font-size: 1.05rem;
    text-align: center;
    margin: 0;
  }
  height: 80%;
  justify-content: space-evenly;
`

const Equal = styled.button`
  width: 10rem;
  height: 3.125rem;
`

const Button = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: inherit;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  &:hover {
    cursor: pointer;
    background-color: #48a54c;
  }

  box-shadow: 0px 100px 80px rgba(85, 123, 130, 0.07),
    0px 41.7776px 33.4221px rgba(85, 123, 130, 0.0503198),
    0px 22.3363px 17.869px rgba(85, 123, 130, 0.0417275),
    0px 12.5216px 10.0172px rgba(85, 123, 130, 0.035),
    0px 6.6501px 5.32008px rgba(85, 123, 130, 0.0282725),
    0px 2.76726px 2.21381px rgba(85, 123, 130, 0.0196802);

  /* background-color: #e7eff7; */
  background-color: #f2fdff;

  @media (min-width: 0px) and (max-width: 479px) {
    /* small viewport  */

    height: 10rem;
    /* padding: 5px; */
    border-radius: 10px;
    border: none;

    /* background-color: green; */
  }
`

ConjointAnalysis.propTypes = {
  question: PropTypes.instanceOf(Object),
  updateCurrent: PropTypes.instanceOf(Function),
  currentAnswer: PropTypes.instanceOf(Object),
}

export default ConjointAnalysis

import React from 'react'
import { useMediaQuery } from 'beautiful-react-hooks'
import PropTypes from 'prop-types'
import SimpleMap from './SimpleMap'
import MultiSelect from './MultiSelect'

const ResponsiveMultiMap = ({
  answers,
  question,
  currentAnswer,
  updateCurrent,
  submitAnswer,
  settings,
}) => {
  const mobileMultiCheckBox = useMediaQuery('(max-width: 768px)') // small and medium devices
  const desktopMultiCheckBox = useMediaQuery('(min-width: 769px)') // large and extra large devices
  return (
    <div>
      {mobileMultiCheckBox ? (
        <MultiSelect
          answers={answers}
          question={question}
          choices={question.choices}
          currentAnswer={currentAnswer}
          updateCurrent={updateCurrent}
          settings={settings}
          submitAnswer={submitAnswer}
        />
      ) : null}
      {desktopMultiCheckBox ? (
        <SimpleMap
          answers={answers}
          question={question}
          settings={settings}
          choices={question.choices}
          currentAnswer={currentAnswer}
          updateCurrent={updateCurrent}
          submitAnswer={submitAnswer}
        />
      ) : null}
    </div>
  )
}

ResponsiveMultiMap.propTypes = {
  answers: PropTypes.instanceOf(Object),
  question: PropTypes.instanceOf(Object).isRequired,
  currentAnswer: PropTypes.instanceOf(Object).isRequired,
  updateCurrent: PropTypes.func.isRequired,
  submitAnswer: PropTypes.func.isRequired,
}

export default ResponsiveMultiMap

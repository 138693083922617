/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

// import { updateCurrent } from '../actions'
/**
 * Hook to validate form
 * accepts initial state for values, validation function
 * method to run when !errors
 * touched is an array of all fields that has been touched by user
 */
const useFormValidation = (intialState, validate, runOnSubmit, updateCurrent, updateAnswer) => {
  const [values, setValues] = useState(intialState)
  const [errors, setErrors] = useState({})
  const [touched, setTouched] = useState([])
  const [isSubmitting, setSubmitting] = useState(false)

  useEffect(() => {
    // fires if user submits, check if any errors
    if (isSubmitting) {
      const noErrors = Object.keys(errors).length === 0
      if (noErrors) {
        setTouched([])
        runOnSubmit()
        setSubmitting(false)
      }
      setSubmitting(false)
    }
  }, [errors])
  // rerurns to check if any new fields have been touched/ value changes
  // check errors, highligt them
  useEffect(() => {
    const validationErrors = validate(values)
    const touchedErrors = Object.keys(validationErrors)
      .filter(key => touched.includes(key))
      .reduce((acc, key) => {
        if (!acc[key]) {
          acc[key] = validationErrors[key]
        }
        return acc
      }, {})
    setErrors(touchedErrors)
  }, [touched, values])
  // fires everytime input changes
  const handleChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }
  // fires when form is tapped, or tabbed into / 'touched'
  const handleBlur = event => {
    // if !touched, add it to the array
    if (!touched.includes(event.target.name)) {
      setTouched([...touched, event.target.name])
    }
  }

  const submitForm = () => {
    toast.success(`Survey form Data sent`, {
      containerId: 'A',
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    const validationErrors = validate(values)
    setErrors(validationErrors)
    setSubmitting(true)
    updateCurrent(values)
    updateAnswer()
    submitForm()
  }

  return {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    isSubmitting,
  }
}

export default useFormValidation

import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import { AutoComplete, Input } from 'antd'
import _ from 'lodash'

const Complete = ({
  question,
  updateCurrent,
  currentAnswer,
  settings,
  answers,
  choices,
  submitAnswer,
}) => {
  const { primaryColour, contrastColour } = settings
  const fetchDefaultValues = () => {
    if (question.defaultValues) {
      const initialAnswer = {
        value: question.defaultValues({ answers }),
      }
      updateCurrent(initialAnswer)
    }
  }

  const { id } = currentAnswer

  const dynamicChoices = _.isFunction(choices) ? choices({ answers }) : choices

  const dynamicContrast = !_.isUndefined(contrastColour) ? contrastColour : '#fff'

  useEffect(() => {
    fetchDefaultValues()
  }, [])
  const dynamicColour = !_.isUndefined(primaryColour) ? primaryColour : '#C4E8C5'
  return (
    <div>
      {question.multi ? (
        question.choices.map((questionChoice, index) => {
          return (
            <Container>
              <StyledP>{questionChoice.text}</StyledP>
              <StyledAutoComplete
                key={questionChoice.id}
                value={
                  currentAnswer.value &&
                  currentAnswer.value[index] &&
                  currentAnswer.value[index][questionChoice.value]
                    ? currentAnswer.value[index][questionChoice.value]
                    : ''
                }
                allowClear="true"
                options={questionChoice.options.sort((a, b) => (a.value > b.value ? 1 : -1))}
                dynamicColour={dynamicColour}
                onChange={inputValue => {
                  const answer = {
                    id: questionChoice.id,
                    text: questionChoice.value,
                    value: inputValue,
                  }
                  const newAnswer = {
                    ...currentAnswer,
                  }

                  if (!currentAnswer.value) {
                    newAnswer.value = [] // init empty array
                  }

                  newAnswer.value[index] = { id: answer.id, [questionChoice.value]: answer.value }

                  updateCurrent(newAnswer)
                }}
              >
                <Input.Search placeholder={questionChoice.placeholder} enterButton />
              </StyledAutoComplete>
            </Container>
          )
        })
      ) : (
        <Container>
          <StyledAutoComplete
            active={id === 0}
            value={currentAnswer ? currentAnswer.value : ''}
            options={question.options.sort((a, b) => (a.value > b.value ? 1 : -1))}
            style={{ width: question.width }}
            dynamicColour={dynamicColour}
            allowClear="true"
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            onChange={inputValue => {
              const inputValues = {
                ...currentAnswer,
                id: 0,
                value: inputValue,
              }
              updateCurrent(inputValues)
            }}
          >
            <Input.Search placeholder={question.placeholder} enterButton />
          </StyledAutoComplete>
          <div>
            {dynamicChoices.map(questionChoice => {
              return (
                <Wrapper
                  active={questionChoice.id === id}
                  key={questionChoice.id}
                  dynamicChoices={dynamicChoices}
                  dynamicColour={dynamicColour}
                  dynamicContrast={dynamicContrast}
                  gridDisplay={question.verticalGrid}
                  style={{ flex: question.flex, height: question.height }}
                  onClick={() => {
                    const answer = {
                      ...currentAnswer,
                      id: questionChoice.id,
                      value: questionChoice.value,
                    }
                    if (question.noSkip) {
                      updateCurrent(answer)
                    } else {
                      updateCurrent(answer)
                      submitAnswer()
                    }
                  }}
                >
                  <Circle
                    dynamicColour={dynamicColour}
                    dynamicContrast={dynamicContrast}
                    gridDisplay={question.verticalGrid}
                    className="svg-wrapper"
                    active={questionChoice.id === id}
                  >
                    <svg className="svg" version="1.1" id="tick" viewBox="6 5 26 26">
                      <polyline
                        className="path"
                        points="
          11.6,20 15.9,24.2 26.4,13.8 "
                      />
                    </svg>
                  </Circle>
                  <Button gridDisplay={question.verticalGrid} type="button">
                    <div>{questionChoice.text}</div>
                  </Button>
                </Wrapper>
              )
            })}
          </div>
        </Container>
      )}
    </div>
  )
}

const Container = styled.div`
  margin-top: 1rem;
`

const Button = styled.button`
  display: contents;
  height: auto;
  font-size: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 3px;
  font-weight: 400;
  img {
    height: 100px;
  }
  div {
    display: flex;
    align-content: baseline;
    text-align: justify;
    text-justify: inter-word;
    max-width: ${props => (props.gridDisplay ? '94%' : '')};
    width: 94%;
    span {
      text-align: left;
      text-overflow: ellipsis;
      word-wrap: break-word;
      word-break: break-work;
    }
  }
`

const Circle = styled.div`
  display: flex;
  align-items: center;
  right: ${props => (props.gridDisplay ? '0px' : '20px')};
  top: ${props => (props.gridDisplay ? '12px' : '20px')};
  width: 25px;
  height: 25px;
  padding: 2px;
  border-radius: 25px;
  border: ${props => (props.active ? '1px solid white' : 'none')};
  position: ${props => (props.gridDisplay ? 'absolute' : 'absolute')};
  right: ${props => (props.gridDisplay ? '7px' : '')};
  top: ${props => (props.gridDisplay ? '' : '')};
  .svg-wrapper {
    width: 40px;
    display: contents;
    align-items: center;
    justify-content: center;
  }
  background-color: #fff;
  .svg {
    width: 30px;
  }
  .path {
    fill: transparent;
    stroke: ${props => (props.active ? props.dynamicColour : '#53bf8b')};
    stroke-width: 3;
    stroke-dasharray: 25;
    stroke-dashoffset: ${props => (props.active ? 0 : 26)};
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dashoffset 250ms ease-in-out;
  }
`

const Wrapper = styled.div`
  padding-left: ${props => (props.gridDisplay ? '3px' : '1px')};
  width: ${props => (props.gridDisplay ? '551px' : '30%')};
  max-width: ${props => (props.gridDisplay ? '551px' : '258px')};
  padding: ${props => (props.dynamicPadding ? props.dynamicPadding : '')};
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  height: ${props => (props.gridDisplay ? '50px' : '175px')};
  display: flex;
  flex-direction: ${props => (props.gridDisplay ? 'row-reverse' : 'column')};
  cursor: pointer;
  background-color: ${props => (props.active ? props.dynamicColour : 'white')};
  margin-bottom: 8px;
  border: ${props => (props.active ? 'none' : '1px solid rgba(0, 0, 0, 0.3)')};
  color: ${props => (props.active ? props.dynamicContrast : '#262626')};
  position: relative;
  transition: background 250ms ease-in-out, transform 150ms ease;
  &:hover {
    /* transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased; */
    border-color: ${props => props.dynamicColour};
    border-width: 2px;
    border-color: none;
    /* transform: scale(1.01); */
    -webkit-box-shadow: 0px 6px 11px -8px rgba(0, 0, 0, 0.65);
    box-shadow: 0px 6px 11px -8px rgba(0, 0, 0, 0.65);
    transition: box-shadow 0.08s ease-in-out;
  }
  .svg {
    width: 20px;
  }
  .path {
    fill: transparent;
    stroke: ${props => (props.active ? props.dynamicColour : '#53bf8b')};
    stroke-width: 2;
    stroke-dasharray: 25;
    stroke-dashoffset: ${props => (props.active ? 0 : 26)};
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dashoffset 250ms ease-in-out;
  }

  ${Button} {
    border: none;
    font-weight: ${props => (props.active ? '500' : '400')};
  }
  @media (min-width: 0px) and (max-width: 479px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: calc(1vh + 0rem);
    height: 50px;
    width: 90vw;
    .svg-wrapper {
      margin-left: 230px;
    }
  }
  @media (min-width: 480px) and (max-width: 767px) {
  }
`

const StyledAutoComplete = styled(AutoComplete)`
  width: 400px;
  height: 3rem;
  align-items: center;
  display: flex;
  border-color: ${props => props.dynamicColour};
  margin-bottom: 0.5rem;

  input {
    height: 3.125rem;
  }

  .ant-select-clear {
    right: 55px;
  }
  .ant-select-selector {
    width: inherit;
  }

  .ant-btn {
    span {
      margin-bottom: 0rem;
    }
  }

  .ant-select-clear {
    margin-bottom: 0rem;
    font-size: 1.5rem;
    top: 25%;
    width: initial;
    height: initial;
    margin-top: 0;
    opacity: 1;
  }

  .ant-btn-primary {
    background: ${props => props.dynamicColour};
    border-color: ${props => props.dynamicColour};
    height: 3.125rem;
  }

  .ant-input-search-enter-button input:focus {
    border-color: ${props => props.dynamicColour};
  }

  .ant-input-search-enter-button input:hover {
    border-color: ${props => props.dynamicColour};
  }

  .ant-input-wrapper {
    input {
      padding-left: 20px;
      padding-right: 20px;
      font-size: 1rem;
      color: #262626;
    }

  }

  .ant-input:focus,
  .ant-input-focused {
    box-shadow: 0 0 0 0px ${props => props.dynamicColour};
  }

  .ant-input-search-enter-button {
    border-color: ${props => props.dynamicColour};
  }

  .ant-select-auto-complete .ant-select-clear {
    right: 55px;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${props => props.dynamicColour};
    border-right-width: 1px !important;
  }

  .ant-select-allow-clear {
    color: red;
  }
  .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${props => props.dynamicColour};
  }

  @media (min-width: 0px) and (max-width: 479px) {
    width: 90vw !important;
    margin: auto;
    align-items: center;
    margin-bottom: 1rem;
    .ant-input-wrapper {
      input {
        padding-left: 10px;
        padding-right: 0px;
        font-size: 1rem;
        color: #262626;
    }
  }
  @media (min-width: 480px) and (max-width: 768px) {
    margin: auto;
    width: 90vw;
    align-items: center;
  }
  @media (min-width: 768px) and (max-width: 1025px) {
    margin: auto;
    width: 80vw;
    align-items: center;
  }
`

const StyledP = styled.p`
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0.5rem;
`

Complete.propTypes = {
  question: PropTypes.instanceOf(Object),
  updateCurrent: PropTypes.instanceOf(Function),
  currentAnswer: PropTypes.instanceOf(Object),
}

export default Complete

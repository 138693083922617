import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import 'antd/dist/antd.css'
import _ from 'lodash'
import { Select } from 'antd'

const MultiselectDropdown = ({ answers, question, choices, updateCurrent, currentAnswer }) => {
  const { Option } = Select
  const dynamicChoices = _.isFunction(choices) ? choices({ answers }) : choices
  const [selectedItems, selectItem] = useState([])
  const emptyArray = [] // used as default value
  // const filterOptions = question.options.filter(item => !currentAnswer.includes(item))
  function onBlur() {}
  function onFocus() {}
  return (
    <ButtonContainer>
      {dynamicChoices.map(questionChoice => {
        return (
          <Item id={questionChoice.id} key={questionChoice.id}>
            <p>{questionChoice.text}</p>
            <StyledSelected
              id={questionChoice.id.toString()}
              key={questionChoice.id}
              mode="multiple"
              placeholder="Please select all that may apply"
              value={
                [questionChoice.id] in currentAnswer
                  ? currentAnswer[questionChoice.id].value
                  : emptyArray
              }
              onChange={selectedItems => {
                const answer = {
                  id: questionChoice.id,
                  text: questionChoice.text,
                  value: selectedItems,
                }
                selectItem(answer)
                updateCurrent({
                  ...currentAnswer,
                  [questionChoice.id]: answer,
                })
              }}
              optionFilterProp="children"
              onFocus={onFocus}
              onBlur={onBlur}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {question.options.map(option => {
                return (
                  <Option id={option.id} key={option.id} value={option.value}>
                    {option.value}
                  </Option>
                )
              })}
            </StyledSelected>
          </Item>
        )
      })}
    </ButtonContainer>
  )
}

MultiselectDropdown.propTypes = {
  answers: PropTypes.instanceOf(Array),
  choices: PropTypes.instanceOf(Array).isRequired,
  question: PropTypes.instanceOf(Object).isRequired,
  currentAnswer: PropTypes.instanceOf(Object).isRequired,
  updateCurrent: PropTypes.func.isRequired,
}

const ButtonContainer = styled.div`
  display: flex;
  overflow-y: visible;
  align-items: center;
  flex-direction: column;
`

const Item = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  overflow-y: visible;
  p {
    margin-top: 0.5rem;
  }
`

const StyledSelected = styled(Select)`
  width: 700px;
  .ant-select-selection--single {
    margin-bottom: 0.5rem;
  }
`

export default MultiselectDropdown

const formValidate = values => {
  const errors = {}
  // if name populate check length
  if (values.name && values.name.length < 3) {
    errors.name = 'Name is too short'
  }
  // if email populated validate regex
  if (values.email && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(values.email)) {
    errors.email = 'Please enter a valid email address'
  }
  return errors
}

export default formValidate

import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import _ from 'lodash'
import 'antd/dist/antd.css'
import { Slider, Divider } from 'antd'

const SmartSlider = ({ array, currentAnswer, updateCurrent }) => {
  const [state, setState] = useState(currentAnswer.value ? currentAnswer.value : array)
  const [totalSum, updateTotalSum] = useState(0)
  if (_.isUndefined(currentAnswer.value)) {
    const defaultValues = {
      ...currentAnswer,
      value: _.keyBy(array, item => item.id),
    }
    updateCurrent(defaultValues)
  }
  useEffect(() => {
    const sum = _.sum(_.map(currentAnswer.value, 'value'))
    updateTotalSum(sum)
  }, [currentAnswer.value])
  return (
    <div>
      {array.map(slider => {
        return (
          <Item key={slider.id}>
            <SliderContainer>
              <Text>{slider.text}</Text>
              <StyledSlider
                key={slider.id}
                step={5}
                value={state[slider.id] ? state[slider.id].value : slider.value}
                onChange={e => {
                  const inputValues = {
                    id: slider.id,
                    text: slider.text,
                    value: e,
                  }
                  const newValues = {
                    ...currentAnswer,
                    value: {
                      ...currentAnswer.value,
                      [slider.id]: inputValues,
                    },
                  }
                  setState(newValues.value)
                  updateCurrent(newValues)
                }}
                tipFormatter={null}
                max={slider.max}
              />
            </SliderContainer>
            <StyledDivider type="vertical" />
            <Container>
              <Number>
                {state[slider.id] ? state[slider.id].value : '0%'}
                {state[slider.id] ? '%' : null}
              </Number>
            </Container>
          </Item>
        )
      })}
      <div>
        <Total total={totalSum}>total: {totalSum}%</Total>
      </div>
    </div>
  )
}

const Item = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  margin-bottom: calc(0.5vh + 0rem);
  border-radius: 3px;
  @media (min-width: 0px) and (max-width: 479px) {
    width: 85vw;
  }
`
const Text = styled.div`
  display: flex;
  flex: 1 1;
  width: 100%;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 15px;
  line-height: 22px;
  padding-left: 12px;
  padding-right: 12px;
`
const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  max-width: 80%;
`

const StyledSlider = styled(Slider)`
  width: 600px;
  @media (min-width: 0px) and (max-width: 479px) {
    width: 55vw;
  }
`

const StyledDivider = styled(Divider)`
  display: flex;
  justify-content: center;
  align-content: center;
  align-self: center;
  height: 50px;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 165px;
  height: inherit;
  align-items: center;
`

const Number = styled.p`
  color: rgba(0, 0, 0, 0.45);
  font-size: 30px;
  line-height: 22px;
  margin-bottom: 0;
  margin-top: 0;
`

const Total = styled.p`
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: ${props => (props.total === 100 ? '#01d62f' : '#f44336')};
`

SmartSlider.propTypes = {
  currentAnswer: PropTypes.instanceOf(Object),
  updateCurrent: PropTypes.func,
  array: PropTypes.instanceOf(Array),
}

export default SmartSlider

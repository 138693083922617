import { connect } from 'react-redux'
import { updateCurrent, endLoop, updateAnswer, injectCurrent } from './actions'
import Question from '../questions/Question'

const mapDispatchToProps = {
  updateCurrent,
  endLoop,
  updateAnswer,
  injectCurrent,
}

function mapStateToProps(state) {
  return {
    currentAnswer: state.currentAnswer,
    answers: state.userAnswers,
    loopIndex: state.loopIndex,
    loopArray: state.loopArray,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Question)

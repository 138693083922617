import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

/**
 * This component is used as a 'Page' to display some info before a question Component
 * @param {*} props
 */
const Section = props => {
  // eslint-disable-next-line no-unused-vars
  const { question } = props
  return (
    <Container>
      {/* <h1>{question.section}</h1> */}
      {/* <p>{question.statement}</p> */}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 30rem;
  justify-content: center;
  align-self: center;
  text-align: center;
  @media (min-width: 0px) and (max-width: 478px) {
    margin: 10px;
    width: 15rem;
    height: 35vh;
  }
`

Section.propTypes = {
  question: PropTypes.instanceOf(Object).isRequired,
}

export default Section

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import animation from '../ui/success-check.json'
/**
 * under construction
 * @param {*} param
 */
const EndSurvey = ({ question }) => {
  return (
    <Background>
      <Container>
        <div id="svgContainer">test</div>
        {/* <h3>{question.title}</h3>
      <p>{question.subtext}</p>
      <p>{question.disclaimer}</p>
      <Fieldset>
        {question.email ? <Input type="email" name="email" placeholder="Email" /> : null}
        {question.phone ? (
          <Input
            type="tel"
            name="phone"
            placeholder="Phone Number"
            pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
          />
        ) : null}
        {question.name ? <Input type="text" name="name" placeholder="Name" /> : null}
        {question.textBox ? (
          <>
            <p>{question.textBox.label}</p>
            <Textarea name="other" placeholder={question.textBox.placeholder} />
          </>
        ) : null}
      </Fieldset> */}
      </Container>
    </Background>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  #svgContainer {
    max-width: 100%;
    max-height: 100%;
    background-image: linear-gradient(70deg, #f0f0f0 10%, #ffffff 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
  }

  #svgContainer svg {
    width: 20% !important;
  }
  /* width: 600px; */
  /* min-height: 100vh; */
`

const Background = styled.div`
  height: 100vh;
  width: 100vw;
`

const Fieldset = styled.fieldset`
  border: 0 none;
  border-radius: 3px;
  position: relative;
  margin: none;
  padding: none;
`

const Input = styled.input`
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2c3e50;
  font-size: 13px;
`

const Textarea = styled.textarea`
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2c3e50;
  font-size: 13px;
  resize: none;
`
EndSurvey.propTypes = {
  question: PropTypes.instanceOf(Object).isRequired,
}

export default EndSurvey

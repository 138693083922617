import React from 'react'
import styled from 'styled-components/macro'
import _ from 'lodash'
import { Form, Input, AutoComplete, Card } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import propTypes from 'prop-types'
import { Select } from 'antd'

const SocialNetworkForm = ({ question, currentAnswer, updateCurrent }) => {
  // const getFormData = (value, id) => {
  //   console.log('value', value, 'id', id)
  //   const newInputValues = {
  //     ...currentAnswer,
  //     value: currentAnswer.value
  //       ? { ...currentAnswer.value, [id]: { ...currentAnswer.value[id], ...value } }
  //       : { [id]: { ...value } },
  //   }
  //   updateCurrent(newInputValues)
  // }

  const updateFieldValues = (value, id, fieldName) => {
    const newInputValues = {
      ...currentAnswer,
      value: currentAnswer.value
        ? { ...currentAnswer.value, [id]: { ...currentAnswer.value[id], [fieldName]: value } }
        : { [id]: { [fieldName]: value } },
    }
    updateCurrent(newInputValues)
  }

  const { Option } = Select
  // const dynamicChoices = _.isFunction(multiChoiceArray)
  //   ? multiChoiceArray({ answers })
  //   : multiChoiceArray
  function onBlur() {}
  function onFocus() {}

  const regions = [
    { id: 1, name: 1, text: 'Northland ', value: 'Northland' },
    { id: 2, name: 2, text: 'Auckland ', value: 'Auckland' },
    { id: 3, name: 3, text: 'Waikato ', value: 'Waikato' },
    { id: 4, name: 4, text: 'Bay of Plenty ', value: 'Bay of Plenty' },
    { id: 5, name: 6, text: 'Gisborne ', value: 'Gisborne' },
    { id: 6, name: 6, text: "Hawke's Bay ", value: "Hawke's Bay" },
    { id: 7, name: 7, text: 'Taranaki ', value: 'Taranaki' },
    { id: 8, name: 8, text: 'Manawatu-Wanganui ', value: 'Manawatu-Wanganui' },
    { id: 9, name: 9, text: 'Wellington ', value: 'Wellington' },
    { id: 10, name: 10, text: 'West Coast ', value: 'West Coast' },
    { id: 11, name: 11, text: 'Canterbury ', value: 'Canterbury' },
    { id: 12, name: 12, text: 'Otago ', value: 'Otago' },
    { id: 13, name: 13, text: 'Southland ', value: 'Southland' },
    { id: 14, name: 14, text: 'Marlborough ', value: 'Marlborough' },
    { id: 15, name: 15, text: 'Nelson/Tasman ', value: 'Nelson/Tasman' },
  ]

  return (
    <Container>
      {_.range(0, question.n_cards).map(n => (
        <StyledCard title={`Advisor #${n + 1}`} bordered="true" key={n}>
          <FormContainer>
            <AutoComplete
              style={{ width: '100%' }}
              key={n}
              placeholder="Organisation"
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              onChange={e => {
                const answer = {
                  id: n,
                  name: 'Organisation',
                  value: e,
                }
                updateFieldValues(answer.value, answer.id, answer.name)
              }}
              value={
                currentAnswer.value && currentAnswer.value[n] && currentAnswer.value[n].Organisation
              }
              options={question.options}
            />
            <Select
              key={n}
              style={{ marginTop: '1rem', marginBottom: '1rem', width: '100%' }}
              value={
                currentAnswer.value && currentAnswer.value[n]
                  ? currentAnswer.value[n].Role
                  : 'Please select a Role'
              }
              placeholder="Please select a Role"
              onChange={e => {
                const answer = {
                  id: n,
                  name: 'Role',
                  value: e,
                }

                updateFieldValues(answer.value, answer.id, answer.name)
              }}
              optionFilterProp="children"
              onFocus={onFocus}
              onBlur={onBlur}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {question.roles.map(option => {
                return (
                  <Option id={option.id} key={option.id} value={option.value}>
                    {option.text}
                  </Option>
                )
              })}
            </Select>
            <Select
              key={n}
              style={{ marginTop: '0rem', marginBottom: '1rem', width: '100%' }}
              placeholder="Region (optional)"
              value={
                currentAnswer.value && currentAnswer.value[n]
                  ? currentAnswer.value[n].Region
                  : 'Region (optional)'
              }
              onChange={e => {
                const answer = {
                  id: n,
                  name: 'Region',
                  value: e,
                }

                updateFieldValues(answer.value, answer.id, answer.name)
              }}
              optionFilterProp="children"
              onFocus={onFocus}
              onBlur={onBlur}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {regions.map(option => {
                return (
                  <Option id={option.id} key={option.id} value={option.value}>
                    {option.text}
                  </Option>
                )
              })}
            </Select>
            {/* <Input
              style={{ marginTop: '1rem', marginBottom: '1rem' }}
              key={n}
              onChange={e => {
                const answer = {
                  id: n,
                  name: 'Role',
                  value: e.target.value,
                }
                updateFieldValues(answer.value, answer.id, answer.name)
              }}
              value={
                currentAnswer.value && currentAnswer.value[n] ? currentAnswer.value[n].Role : ''
              }
              placeholder="Role"
              name="Role"
            /> */}
            {/* <Input
              key={n}
              prefix={<UserOutlined className="site-form-item-icon" />}
              onChange={e => {
                const answer = {
                  id: n,
                  name: 'Name',
                  value: e.target.value,
                }
                updateFieldValues(answer.value, answer.id, answer.name)
              }}
              value={
                currentAnswer.value && currentAnswer.value[n] ? currentAnswer.value[n].Name : ''
              }
              placeholder="Name (optional)"
              name="Name"
            /> */}
          </FormContainer>
        </StyledCard>
      ))}
    </Container>
  )
}

const FormContainer = styled.div`
  width: 200px;
`

const StyledForm = styled(Form)``

const Container = styled.div`
  display: flex;
  width: 800px;
  align-items: baseline;
  justify-content: flex-start;
  margin-top: 1rem;
  flex-wrap: wrap;
  @media (min-width: 0px) and (max-width: 479px) {
    flex-direction: column;
    margin-bottom: 1rem;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`

const StyledCard = styled(Card)`
  width: 30%;
  margin-right: 1rem;
  @media (min-width: 0px) and (max-width: 479px) {
    width: 80%;
    margin-bottom: 0.5rem;
  }
`
SocialNetworkForm.propTypes = {
  question: propTypes.instanceOf(Object),
  currentAnswer: propTypes.instanceOf(Object),
  updateCurrent: propTypes.func,
}
export default SocialNetworkForm

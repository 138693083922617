import React from 'react'
import { useMediaQuery } from 'beautiful-react-hooks'
import PropTypes from 'prop-types'
import Slider from './Slider'
import MobileSliderRating from './MobileSliderRating'

const ResponsiveSlider = ({ question, answers, currentAnswer, updateCurrent, submitAnswer }) => {
  const mobileMultiCheckBox = useMediaQuery('(max-width: 768px)')
  const desktopMultiCheckBox = useMediaQuery('(min-width: 769px)')
  return (
    <div>
      {/* {mobileMultiCheckBox ? (
        <MobileSliderRating
          question={question}
          labelsArray={question.choices}
          currentAnswer={currentAnswer}
          updateCurrent={updateCurrent}
          submitAnswer={submitAnswer}
        />
      ) : null}
      {desktopMultiCheckBox ? (
        <Slider
          question={question}
          answers={answers}
          labelsArray={question.choices}
          currentAnswer={currentAnswer}
          updateCurrent={updateCurrent}
          submitAnswer={submitAnswer}
        />
      ) : null} */}
      <Slider
        question={question}
        answers={answers}
        labelsArray={question.choices}
        currentAnswer={currentAnswer}
        updateCurrent={updateCurrent}
        submitAnswer={submitAnswer}
      />
    </div>
  )
}

ResponsiveSlider.propTypes = {
  question: PropTypes.instanceOf(Object).isRequired,
  answers: PropTypes.instanceOf(Object).isRequired,
  currentAnswer: PropTypes.instanceOf(Object).isRequired,
  updateCurrent: PropTypes.func.isRequired,
  submitAnswer: PropTypes.func.isRequired,
}

export default ResponsiveSlider

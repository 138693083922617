import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { InputNumber } from 'antd'
import _ from 'lodash'
import PropTypes from 'prop-types'

const InputFieldNumber = ({ question, updateCurrent, currentAnswer, settings }) => {
  const [value, setCurrentValue] = useState()

  const { primaryColour, contrastColour } = settings

  const dynamicColour = !_.isUndefined(primaryColour) ? primaryColour : '#C4E8C5'
  const dynamicContrast = !_.isUndefined(contrastColour) ? contrastColour : '#fff'

  const updateCurrentValue = value => {
    setCurrentValue(value)
  }
  return (
    <Container>
      {!_.isUndefined(question.choices) ? (
        <div>
          {question.choices.map((item, index) => {
            return (
              <>
                <p>{item.text}</p>
                <Row>
                  <StyledInputNumber
                    size="large"
                    dynamicColour={dynamicColour}
                    placeholder={item.placeholder ? item.placeholder : question.placeholder}
                    value={
                      currentAnswer.value &&
                      currentAnswer.value[item.id] &&
                      _.isNumber(currentAnswer.value[item.id].value)
                        ? currentAnswer.value[item.id].value
                        : ''
                    }
                    formatter={value => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={e => {
                      const inputValues = {
                        id: item.id,
                        text: item.text,
                        value: e,
                      }
                      const newValues = {
                        ...currentAnswer,
                        value: {
                          ...currentAnswer.value,
                          [item.id]: inputValues,
                        },
                      }
                      // updateCurrentValue(value)
                      updateCurrent(newValues)
                      console.log('currentAnswer', currentAnswer.value)
                    }}
                  />
                  {question.additionalContent && question.additionalContent === 'button' ? (
                    <Wrapper
                      active={
                        currentAnswer &&
                        currentAnswer.value &&
                        currentAnswer.value[item.id] &&
                        currentAnswer.value[item.id].value === "Don't know / not applicable"
                      }
                      // active={item.id}
                      // key={item.id}
                      dynamicColour={dynamicColour}
                      dynamicContrast={dynamicContrast}
                      onClick={() => {
                        const answer = {
                          id: item.id,
                          text: item.text,
                          value: "Don't know / not applicable",
                        }
                        const newValues = {
                          ...currentAnswer,
                          value: {
                            ...currentAnswer.value,
                            [item.id]: answer,
                          },
                        }
                        updateCurrent(newValues)
                      }}
                    >
                      <Circle
                        dynamicColour={dynamicColour}
                        gridDisplay={question.verticalGrid}
                        className="svg-wrapper"
                        active={item.id}
                      >
                        <svg className="svg" version="1.1" id="tick" viewBox="6 5 26 26">
                          <polyline
                            className="path"
                            points="
                 11.6,20 15.9,24.2 26.4,13.8 "
                          />
                        </svg>
                      </Circle>
                      <Button type="button">
                        <span>Don't know / not applicable</span>
                      </Button>
                    </Wrapper>
                  ) : null}
                </Row>
              </>
            )
          })}
        </div>
      ) : (
        <>
          <p>{question.label ? question.label : null}</p>
          <StyledInputNumber
            size="large"
            dynamicColour={dynamicColour}
            placeholder={question.placeholder}
            value={currentAnswer ? currentAnswer.value : ''}
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            onChange={e => {
              const inputValues = {
                value: e,
              }
              updateCurrentValue(value)
              updateCurrent(inputValues)
            }}
          />
        </>
      )}
    </Container>
  )
}

const StyledInputNumber = styled(InputNumber)`
  width: 10rem;
  height: 3.1rem;
  .ant-input-number-input-wrap {
    height: 100%;
    input {
      height: 100%;
    }
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`

const Container = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  justify-content: baseline;
  align-self: baseline;
  @media (min-width: 0px) and (max-width: 479px) {
    padding: 20px;
  }
`

const Button = styled.button`
  display: contents;
  height: auto;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 3px;
  font-weight: 400;
  img {
    height: 100px;
  }
  span {
    padding-left: 1rem;
  }
  div {
    display: flex;
    align-content: baseline;
    padding-left: ${props => (props.gridDisplay ? '1rem' : '')};
    text-align: justify;
    text-justify: inter-word;
    max-width: ${props => (props.gridDisplay ? '94%' : '')};
    span {
      text-align: left;
      text-overflow: ellipsis;
      word-wrap: break-word;
      word-break: break-work;
    }
  }
`

const Circle = styled.div`
  display: flex;
  align-items: center;
  /* margin-left: ${props => (props.gridDisplay ? '0px' : '90px')};
  margin-top: ${props => (props.gridDisplay ? '0px' : '-60px')}; */
  right: ${props => (props.gridDisplay ? '0px' : '20px')};
  /* top: ${props => (props.gridDisplay ? '12px' : '20px')}; */
  width: 25px;
  height: 25px;
  padding: 2px;
  border-radius: 25px;
  /* display: ${props => (props.active ? 'flex' : 'none')} */
  border: ${props => (props.active ? '1px solid white' : 'none')};
  position: ${props => (props.gridDisplay ? 'absolute' : 'absolute')};
  right: ${props => (props.gridDisplay ? '7px' : '')};
  top: ${props => (props.gridDisplay ? '' : '')};
  .svg-wrapper {
    width: 40px;
    display: contents;
    align-items: center;
    justify-content: center;
  }
  background-color: #fff;
  .svg {
    width: 30px;
  }
  .path {
    fill: transparent;
    stroke: ${props => (props.active ? props.dynamicColour : '#53bf8b')};
    stroke-width: 3;
    stroke-dasharray: 25;
    stroke-dashoffset: ${props => (props.active ? 0 : 26)};
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dashoffset 250ms ease-in-out;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    top: auto;
  }
`

const Wrapper = styled.div`
  padding-left: ${props => (props.gridDisplay ? '3px' : '1px')};
  margin-left: 1rem;
  /* flex: 0 0 32%;
  flex: ${props => (props.gridDisplay ? '' : '0 0 32%')}; */
  width: 18rem;
  /* max-width: ${props => (props.gridDisplay ? '551px' : '258px')}; */
  padding: ${props => (props.dynamicPadding ? props.dynamicPadding : '')};
  align-items: baseline;
  /* justify-content: center; */
  justify-content: ${props => (props.gridDisplay ? 'flex-end' : 'center')};
  border-radius: 3px;
  height: 50px;
  /* display: ${props => (props.gridDisplay ? 'flex' : 'block')}; */
  display: flex;
  flex-direction: ${props => (props.gridDisplay ? 'row-reverse' : 'column')};
  cursor: pointer;
  background-color: ${props => (props.active ? props.dynamicColour : 'white')};
  margin-bottom: 8px;
  margin-right: 8px;
  border: ${props => (props.active ? 'none' : '1px solid rgba(0, 0, 0, 0.3)')};
  color: ${props => (props.active ? props.dynamicContrast : '#262626')};
  position: relative;
  transition: background 250ms ease-in-out, transform 150ms ease;
  &:hover {
    /* transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased; */
    border-color: ${props => props.dynamicColour};
    border-width: 2px;
    border-color: none;
    /* transform: scale(1.01); */
    -webkit-box-shadow: 0px 6px 11px -8px rgba(0,0,0,0.65);
    box-shadow: 0px 6px 11px -8px rgba(0,0,0,0.65);
    transition: box-shadow 0.08s ease-in-out;
  }
  .svg {
    width: 20px;
  }
  .path {
    fill: transparent;
    stroke: ${props => (props.active ? props.dynamicColour : '#53bf8b')};
    stroke-width: 2;
    stroke-dasharray: 25;
    stroke-dashoffset: ${props => (props.active ? 0 : 26)};
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dashoffset 250ms ease-in-out;
  }

  ${Button} {
    border: none;
    font-weight: ${props => (props.active ? '500' : '400')};
  }
  @media (min-width: 0px) and (max-width: 479px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: calc(1vh + 0rem);
    height: 50px;
    height: ${props => (props.mobileHeight ? props.mobileHeight : '50px')}!important;
    padding-left: 10px;
    padding-right: 10px;
    align-items: baseline;
    width: 88vw !important;
    max-width: 360px !important;
    .svg-wrapper {
      margin-left: 230px;
    }
    padding: ${props => (props.mobilePadding ? props.mobilePadding : '')};
    /* padding-top: 2.25rem;
    padding-bottom: 2.25rem; */
  }
  @media (min-width: 480px) and (max-width: 767px) {
  }
`

InputFieldNumber.propTypes = {
  updateCurrent: PropTypes.func,
  currentAnswer: PropTypes.instanceOf(Object),
  question: PropTypes.instanceOf(Object),
}

export default InputFieldNumber

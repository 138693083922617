import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components/macro'

/**
 * This component is used to render dynamic data eg if header needs to show data from different question, else return default
 * @param {*} param
 */
const QuestionInfo = ({ question, answers, loopIndex, settings }) => {
  const dynamicHeader = _.isFunction(question.header)
    ? question.header({ answers, loopIndex })
    : question.header

  const dynamicStatement = _.isFunction(question.statement)
    ? question.statement({ answers, loopIndex })
    : question.statement

  const dynamicText = _.isFunction(question.text)
    ? question.text({ answers, loopIndex })
    : question.text

  const dynamicSection = _.isFunction(question.section)
    ? question.section({ answers, loopIndex })
    : question.section

  const { primaryColour } = settings

  const dynamicColour = !_.isUndefined(primaryColour) ? primaryColour : '#40a9ff'

  return (
    <Container>
      {dynamicSection}
      {dynamicHeader}
      <Text>{question.hideQuestionText ? null : dynamicText}</Text>
      <Disclaimer>{question.disclaimer}</Disclaimer>
      {dynamicStatement}
    </Container>
  )
}

// const KeysContainer = styled.div``
// const Box = styled.div`
//   width: 15px;
//   height: 15px;
//   background: ${props => props.dynamicColour};
//   margin-right: 1rem;
// `
// const SemiBold = styled.span`
//   font-weight: 700;
// `

const Disclaimer = styled.p`
  font-size: 14px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: rgba(0, 0, 0, 0.65);
  font-style: italic;
  font-weight: 400;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 600;
  width: 49rem;
  /* margin-top: 25px; */
  z-index: 1;
  /* align-self: center; */
  /* margin-bottom: 1rem; */
  align-items: baseline;

  @media (min-width: 0px) and (max-width: 480px) {
    /* margin: 30px; */
    width: 90vw;
    /* margin-top: 1rem; */
    /* margin-left: 2rem; */
    /* margin-top: 1rem; */
    /* padding-bottom: 1.5rem; */
    justify-content: center;
    align-items: baseline;
  }
  @media (min-width: 480px) and (max-width: 768px) {
    width: 90vw;
    justify-content: center;
    align-items: baseline;
  }
  @media (min-width: 769px) and (max-width: 1279px) {
    width: 100vw;
    justify-content: center;
    align-items: baseline;
  }
  @media (min-width: 768px) and (max-width: 1025px) {
    width: 80vw;
    justify-content: center;
    align-items: baseline;
  }

  /* @media (min-width: 768px) {
    min-width: 500px;
    max-width: 700px;
  }

  @media (min-width: 992px) {
    min-width: 500px;
    max-width: 700px;
  }

  @media (min-width: 1200px) {
    min-width: 500px;
    max-width: 700px;
  } */
`
const Text = styled.div`
  /* font-size: 1.1rem; */
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.7;
`
QuestionInfo.propTypes = {
  question: PropTypes.instanceOf(Object).isRequired,
  answers: PropTypes.instanceOf(Object).isRequired,
  loopIndex: PropTypes.oneOfType([
    isNumber => {
      return isNumber === null
    },
    PropTypes.number,
  ]),
}

export default QuestionInfo

import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

const MobileGridLayout = ({ settings, question, children }) => {
  const { companyLogo, currentQuestionId, logoLocation, backgroundImage } = settings
  const { hideLogo } = question
  return (
    <Container
      style={{
        backgroundImage: settings.mobileBackground
          ? `url(${backgroundImage})`
          : 'linear-gradient(to right bottom, #fff, #fff, #fff, #fff, #fff)',
      }}
    >
      <Content>{children}</Content>
    </Container>
  )
}

const LogoContainer = styled.div`
  display: ${props => (props.hideLogo ? 'none' : 'flex')};
  width: inherit;
  max-width: 800px;
  height: 45px;
  padding: 10px;
`

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  margin: 0 auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(to right bottom, #fff, #fff, #fff, #fff, #fff);
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  height: 100%;
`

MobileGridLayout.propTypes = {
  settings: PropTypes.instanceOf(Object),
  question: PropTypes.instanceOf(Object),
  children: PropTypes.instanceOf(Array),
}

export default MobileGridLayout

import React from 'react'
import styled from 'styled-components/macro'
/**
 * Dynamic component to display Survey Info
 * @param {*} props
 */
const EndPage = () => {
  return <Container />
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  justify-content: center;
  align-items: center;
  @media (min-width: 0px) and (max-width: 479px) {
    margin: 10px;
  }
`

export default EndPage

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import 'antd/dist/antd.css'
import _ from 'lodash'
import { Select } from 'antd'

const DropdownSelectPriority = ({
  answers,
  question,
  choices,
  updateCurrent,
  currentAnswer,
  settings,
  submitAnswer,
}) => {
  const [dynamicChoices, setDynamicChoices] = useState(
    _.isFunction(choices) ? choices({ answers }) : choices,
  )
  const fetchDefaultValues = () => {
    if (question.defaultValues) {
      let initialAnswer = {
        value: question.defaultValues({ answers }),
      }
      updateCurrent(initialAnswer)
    }
  }

  const { id } = currentAnswer
  const { primaryColour, contrastColour } = settings

  const dynamicColour = !_.isUndefined(primaryColour) ? primaryColour : '#40a9ff'
  const dynamicContrast = !_.isUndefined(contrastColour) ? contrastColour : '#FFF'

  useEffect(() => {
    fetchDefaultValues()
    if (question.asyncChoices) {
      question.asyncChoices().then(choices => {
        setDynamicChoices(choices)
      })
    }
  }, [])

  const { Option } = Select
  // const dynamicChoices = _.isFunction(choices)
  //   ? choices({ answers })
  //   : choices
  function onBlur() {}
  function onFocus() {}
  return (
    <ButtonContainer>
      {dynamicChoices.map((questionChoice, index) => {
        return (
          <Item id={questionChoice.id} key={questionChoice.id}>
            <p style={{ fontWeight: 600, marginBottom: '0.5rem', fontSize: '1rem' }}>
              {questionChoice.text}
            </p>
            {questionChoice.disclaimers ? (
              <QuestionDisclaimer>{questionChoice.disclaimers}</QuestionDisclaimer>
            ) : null}
            <Select
              id={questionChoice.id.toString()}
              key={questionChoice.id}
              value={
                currentAnswer.value &&
                currentAnswer.value[index] &&
                currentAnswer.value[index].value
                  ? currentAnswer.value[index].value
                  : 'Please select an answer'
              }
              onChange={e => {
                const answer = {
                  id: questionChoice.id,
                  text: questionChoice.text,
                  value: e,
                }
                let newAnswer = {
                  ...currentAnswer,
                }

                if (!currentAnswer.value || currentAnswer.value === 'skip') {
                  const arrayData = question.rows ? question.rows : question.choices
                  newAnswer.id = answer.id
                  newAnswer.value = new Array(arrayData)
                  newAnswer.value.fill({ text: '', value: '' })
                }

                // newAnswer.value[index] = { [questionChoice.value]: answer.value }
                newAnswer.value[index] = {
                  id: questionChoice.id,
                  text: questionChoice.text,
                  value: answer.value,
                }

                updateCurrent(newAnswer)
                console.log('multiDropdown', currentAnswer.value, newAnswer.value[index].value)

                if (currentAnswer.value) {
                  console.log('data', currentAnswer.value[index].value)
                }
              }}
              optionFilterProp="children"
              onFocus={onFocus}
              onBlur={onBlur}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {question.uniqueValues && questionChoice.uniqueValues
                ? questionChoice.options.map(option => {
                    return (
                      <Option id={option.id} key={option.id} value={option.value}>
                        {option.text}
                      </Option>
                    )
                  })
                : question.options.map(option => {
                    return (
                      <Option id={option.id} key={option.id} value={option.value}>
                        {option.text}
                      </Option>
                    )
                  })}
            </Select>
          </Item>
        )
      })}
      {question.skipButton ? (
        <SkipContainer>
          <Wrapper
            active={question.skipButton.id === id}
            key={question.skipButton.id}
            dynamicChoices={dynamicChoices}
            dynamicColour={dynamicColour}
            dynamicContrast={dynamicContrast}
            gridDisplay={question.verticalGrid}
            style={{ flex: question.flex, height: question.height }}
            onClick={() => {
              const answer = {
                ...currentAnswer,
                id: question.skipButton.id,
                value: question.skipButton.value,
              }

              updateCurrent(answer)
              submitAnswer()
            }}
          >
            <Circle
              dynamicColour={dynamicColour}
              dynamicContrast={dynamicContrast}
              gridDisplay={question.verticalGrid}
              className="svg-wrapper"
              active={question.skipButton.id === id}
            >
              <svg className="svg" version="1.1" id="tick" viewBox="6 5 26 26">
                <polyline
                  className="path"
                  points="
       11.6,20 15.9,24.2 26.4,13.8 "
                />
              </svg>
            </Circle>
            <MButton gridDisplay={question.verticalGrid} type="button">
              <div>{question.skipButton.text}</div>
            </MButton>
          </Wrapper>
        </SkipContainer>
      ) : null}
    </ButtonContainer>
  )
}

DropdownSelectPriority.propTypes = {
  answers: PropTypes.instanceOf(Array),
  choices: PropTypes.instanceOf(Array).isRequired,
  question: PropTypes.instanceOf(Object).isRequired,
  currentAnswer: PropTypes.instanceOf(Object).isRequired,
  updateCurrent: PropTypes.func.isRequired,
}

const ButtonContainer = styled.div`
  display: flex;
  overflow-y: visible;
  align-items: baseline;
  flex-direction: column;
  margin-top: 1rem;
  @media (min-width: 0px) and (max-width: 479px) {
    /* small viewport  */
    align-items: baseline;
    margin-top: 1rem;
    width: 90vw;
  }
  @media (min-width: 480px) and (max-width: 768px) {
    /* medium viewport  */
    align-items: baseline;
  }
`

const Item = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  overflow-y: visible;
  margin-bottom: 1rem;
  width: 28rem;
  @media (min-width: 0px) and (max-width: 479px) {
    padding-bottom: 15px;
    width: 100%;
  }
`

const QuestionDisclaimer = styled.p`
  width: 50rem;
  @media (min-width: 0px) and (max-width: 479px) {
    width: 20rem;
  }
`

// const StyledSelected = styled(Select)`
//   width: 200px;
//   margin-bottom: 1rem;
//   .ant-select-selection--single {
//     margin-bottom: 0.5rem;
//   }
// `

const MButton = styled.button`
  display: contents;
  height: auto;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 3px;
  font-weight: 501;
  div {
    display: flex;
    align-content: baseline;
    /* padding-left: ${props => (props.gridDisplay ? '1rem' : '')}; */
    text-align: justify;
    text-justify: inter-word;
    max-width: ${props => (props.gridDisplay ? '94%' : '')};
    width: 94%;
    span {
      text-align: left;
      text-overflow: ellipsis;
      word-wrap: break-word;
      word-break: break-work;
      font-weight: 501;
    }
  }
`

const Circle = styled.div`
  display: flex;
  align-items: center;
  right: ${props => (props.gridDisplay ? '0px' : '20px')};
  width: 25px;
  height: 25px;
  padding: 2px;
  border-radius: 25px;
  border: ${props => (props.active ? '1px solid white' : 'none')};
  position: ${props => (props.gridDisplay ? 'absolute' : 'absolute')};
  right: ${props => (props.gridDisplay ? '7px' : '')};
  top: ${props => (props.gridDisplay ? '' : '')};
  .svg-wrapper {
    width: 40px;
    display: contents;
    align-items: center;
    justify-content: center;
  }
  background-color: #fff;
  .svg {
    width: 30px;
  }
  .path {
    fill: transparent;
    stroke: ${props => (props.active ? props.dynamicColour : '#53bf8b')};
    stroke-width: 3;
    stroke-dasharray: 25;
    stroke-dashoffset: ${props => (props.active ? 0 : 26)};
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dashoffset 250ms ease-in-out;
  }
`

const Wrapper = styled.div`
  padding-left: ${props => (props.gridDisplay ? '3px' : '1px')};
  width: 33.5rem;
  padding: ${props => (props.dynamicPadding ? props.dynamicPadding : '')};
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  height: 3.3rem;
  display: flex;
  flex-direction: ${props => (props.gridDisplay ? 'row-reverse' : 'column')};
  cursor: pointer;
  background-color: ${props => (props.active ? props.dynamicColour : 'white')};
  margin-bottom: 8px;
  margin-right: 8px;
  border: ${props => (props.active ? 'none' : '1px solid rgba(0, 0, 0, 0.3)')};
  color: ${props => (props.active ? props.dynamicContrast : '#262626')};
  position: relative;
  transition: background 250ms ease-in-out, transform 150ms ease;
  &:hover {
    transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased;
    border-color: ${props => props.dynamicColour};
    border-width: 2px;
    border-color: none;
    /* transform: scale(1.01); */
    -webkit-box-shadow: 0px 6px 11px -8px rgba(0, 0, 0, 0.65);
    box-shadow: 0px 6px 11px -8px rgba(0, 0, 0, 0.65);
    transition: box-shadow 0.08s ease-in-out;
  }
  .svg {
    width: 20px;
  }
  .path {
    fill: transparent;
    stroke: ${props => (props.active ? props.dynamicColour : '#53bf8b')};
    stroke-width: 2;
    stroke-dasharray: 25;
    stroke-dashoffset: ${props => (props.active ? 0 : 26)};
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dashoffset 250ms ease-in-out;
  }

  ${MButton} {
    border: none;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: calc(1vh + 0rem);
    height: 50px;
    width: 90vw;
    .svg-wrapper {
      margin-left: 230px;
    }
  }
  @media (min-width: 480px) and (max-width: 767px) {
  }
`

const SkipContainer = styled.div`
  display: flex;
  align-self: baseline;
`

export default DropdownSelectPriority

/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { Component, createRef } from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import 'antd/dist/antd.css'
import { message } from 'antd'
import { connect } from 'react-redux'
import {
  increment,
  decrement,
  setCurrentQuestion,
  updateAnswer,
  retrieveAnswer,
  goToPreviousQuestion,
  startLoop,
  endLoop,
  breakLoop,
  goToNextQuestion,
  injectCurrent,
  updateCurrent,
} from '../reducers/actions'
import 'react-toastify/dist/ReactToastify.css'
import NextButton from '../ui/NextButton'
import BackButton from '../ui/BackButton'
import SubmitButton from '../ui/SubmitButton'
import StepProgressBar from '../ui/StepProgressBar'
import ProgressBar from '../ui/ProgressBar'
import QuestionWithData from '../reducers/QuestionWithData'
import ResponsiveGridLayout from '../ui/ResponsiveGridLayout'
import { calcTime } from '../helperFunctions'
import updateStore from '../store'
import MobileGridLayout from '../ui/MobileGridLayout'
import DesktopLayout from '../ui/DesktopGridLayout'
import { checkRequired } from '../questions/Question'
import { C } from '@styled-icons/simple-icons'

class Survey extends Component {
  constructor(props) {
    super(props)
    this.selectAnswer = this.selectAnswer.bind(this)
    this.state = { questions: props.questions }
    this.surveyContentRef = createRef()
    this.nextButtonRef = createRef()
  }

  /** functions in here will only fire once */
  componentDidMount() {
    this.shuffleQuestionChoices()
    // this.store()
    updateStore(this.props.settings.surveyName)
    this.props.setCurrentQuestion(
      this.props.currentQuestionId,
      this.props.questions.find(q => q.id === this.props.currentQuestionId).type,
    )
    this.updateDom()

    // dispatch function set initial current question id
  }

  componentDidUpdate(previousProps) {
    if (previousProps.currentQuestionId !== this.props.currentQuestionId) {
      this.updateDom()
    }
  }

  /**
   * loops through question.state, if (question.shuffle) shuffle order of question.choices
   */

  shuffleQuestionChoices = () => {
    this.setState(state => {
      return {
        questions: state.questions.map(question => {
          if (question.shuffle) {
            const choices = question.choices
            const filterArray = _.filter(choices, { type: 'button' })
            const inputElement = _.filter(choices, { type: 'input' })
            const shuffledArray = _.shuffle(filterArray)
            shuffledArray.push(...inputElement)
            return {
              ...question,
              choices: shuffledArray,
            }
          }
          return question
        }),
      }
    })
  }

  previousQuestion = () => {
    const { updateAnswer, retrieveAnswer, goToPreviousQuestion, injectCurrent } = this.props
    const currentTime = new Date().toUTCString()

    updateAnswer()
    goToPreviousQuestion()
    retrieveAnswer()
    injectCurrent({ qStartTime: currentTime })
  }

  /**
   * if (question.required) show toast message, user may not skip this question
   */
  checkRequiredAnswer = () => {
    const { currentAnswer, questions, currentQuestionId, injectCurrent } = this.props
    const question = questions.find(q => q.id === currentQuestionId)
    const emptyObject = _.isUndefined(currentAnswer.value)
    message.config({
      duration: 4,
      maxCount: 2,
    })

    if (
      question.required &&
      question.type === 'multiChoice' &&
      currentAnswer.value === 'Sure thing!' &&
      question.emailInput
    ) {
      console.log('email', currentAnswer.email)
      // console.log('email length', currentAnswer.email.length)

      if (currentAnswer.email) {
        // message.error('can u see me')
        return false
      }

      message.error('Please enter a email address')
      return true
    }

    if (question.type === 'ranking' && question.required) {
      if (
        currentAnswer.value !== undefined &&
        _.isArray(currentAnswer.value) &&
        _.isNumber(currentAnswer.value[0].value) &&
        currentAnswer.value === 'skip'
      ) {
        return false
      }

      if (_.isUndefined(currentAnswer.value) && question.required) {
        const initArray = _.isFunction(question.choices)
          ? question.choices(question['choices'])
          : question.choices
        initArray.map((item, index) => {
          if (item.type !== 'input') {
            return (item.value = index + 1)
          }
          return item.value
        })

        injectCurrent({ ...currentAnswer, questionType: question.type, value: initArray })
        // console.log('hello')
        message.error(
          'We have noticed you have not made any changes. Please confirm that this is the order in which you would rank these issues',
        )
        console.log('this', this.nextButtonRef)
        console.log(ReactDOM.findDOMNode(this.nextButtonRef.current))
        this.nextButtonRef.current.firstChild.data = 'Confirm'
        return true
      }
    }

    if (question.required && emptyObject) {
      if (
        question.type === 'singleTextbox' ||
        question.type === 'autoComplete' ||
        question.type === 'commentBox' ||
        question.type === 'inputFieldNumber'
      ) {
        if (!emptyObject) {
          return false
        }
        message.error('This question requires an answer')
        return true
      }

      const nestedValues =
        _.size(_.pickBy(currentAnswer, val => _.has(val, 'value'))) === question.choices.length
      if (question.required && nestedValues) {
        return false
      }

      message.error('This question requires an answer')
      return true
    }

    // if (question.required && question.type === 'multiChoice' && question.emailInput) {
    //   console.log('email', currentAnswer.email)
    //   // console.log('email length', currentAnswer.email.length)

    //   if (currentAnswer.email) {
    //     // message.error('can u see me')
    //     return false
    //   }

    //   message.error('Please enter a email address')
    //   return true
    // }

    const sum = _.sum(_.map(currentAnswer.value, 'value'))

    if (question.type === 'smartSlider') {
      if (_.size(currentAnswer.value) === question.choices.length && sum === 100) {
        return false
      }
      message.error('The total sum must be 100%')
      return true
    }

    if (question.type === 'multiSlider') {
      if (_.size(currentAnswer.value) === question.choices.length) {
        return false
      }
      message.error('This question requires an answer')
      return true
    }
    // Refactor this to notify user before clicking next
    if (question.type === 'multiSelect' && question.limit) {
      if (_.size(_.pickBy(currentAnswer, val => _.has(val, 'value'))) === question.limit) {
        return false
      }
      message.error(`Please select no more than ${question.limit} options`)
      return true
    }

    // if (question.type === 'responsiveMatrixTable' && question.required) {
    //   console.log('currentAnswer.value', currentAnswer.value)
    //   console.log('question', question)
    //   console.log('length', question.choices.length)
    //   let nan = currentAnswer.value
    //   const qChoices = _.isFunction(question.choices) ? question.choices({ nan }) : question.choices
    //   console.log('qChoices from survey', qChoices)
    //   console.log('qChoices length', qChoices.length)
    //   if (_.size(currentAnswer.value) === question.choices.length) {
    //     console.log('inside if')
    //     console.log('value inside if', currentAnswer.value)
    //     console.log('size inside if', _.size(currentAnswer.value))
    //     console.log('length inside if', question.choices.length)
    //     return false
    //   }
    //   message.error('Please answer all questions')
    //   return true
    // }

    if (
      question.type === 'imageRadioList' ||
      (question.type === 'responsiveMatrix' && question.required)
    ) {
      if (
        _.size(currentAnswer.value) === question.choices.length &&
        _.find(currentAnswer.value, { value: '' }) === undefined
      ) {
        return false
      }
      message.error('Please answer all questions')
      return true
    }

    return false
  }

  nextQuestion = () => {
    message.destroy('ant-message') // remove existing messages

    const {
      updateAnswer,
      retrieveAnswer,
      questions,
      startLoop,
      endLoop,
      goToNextQuestion,
      currentQuestionId,
      breakLoop,
      updateCurrent,
      injectCurrent,
      currentAnswer,
    } = this.props

    if (this.checkRequiredAnswer()) {
      checkRequired()
      return
    }

    const currentTime = new Date().toUTCString()

    const currentTimeTaken = calcTime(currentAnswer.qStartTime, currentTime)

    updateCurrent({
      ...currentAnswer,
      qEndTime: currentTime,
      timeTaken: currentAnswer.timeTaken
        ? currentAnswer.timeTaken + currentTimeTaken
        : currentTimeTaken,
    })

    updateAnswer()

    if (questions.find(q => q.id === currentQuestionId).endLoop) {
      endLoop()
    }
    breakLoop(questions)
    goToNextQuestion(
      questions.find(q => q.id === currentQuestionId).next,
      questions.find(q => q.id === currentQuestionId).breakLoop,
      questions,
    )

    startLoop(questions)
    retrieveAnswer()
    injectCurrent({ qStartTime: currentTime })
  }

  successMessage = () => {
    message.success('Thank you for submitting')
  }

  /**
   * Wait 300ms before executing nextQuestion(),
   * This allows an animation play from start to finish,
   * Resulting in a cleaner UI
   */
  async selectAnswer() {
    await new Promise(r => setTimeout(r, 300)) // Wait 300ms sec before exe next line
    this.nextQuestion()
  }

  async updateDom() {
    if (this.surveyContentRef.current) {
      if (this.nextButtonRef.current) {
        this.nextButtonRef.current.firstChild.data = 'Next'
      }
      this.surveyContentRef.current.scrollTo(0, 0)
      this.surveyContentRef.current.style.overflowY = 'hidden'
      await new Promise(r => setTimeout(r, 500)) // Wait 1ms sec before exe next line
      this.surveyContentRef.current.style.overflowY = 'auto'
    }
  }

  displayBackButton = currentQuestionId => {
    const { questions } = this.props
    const currQuestion = questions.findIndex(q => q.id === currentQuestionId)

    if (currQuestion === 0 || questions[currQuestion].hidePreviousButton) {
      return false
    }

    return true
  }

  hideUi = currentQuestionId => {
    const { questions } = this.props
    const question = questions.find(q => q.id === currentQuestionId)
    if (question.hideUi) {
      return false
    }
    return true
  }

  render() {
    const { questions } = this.state
    const { currentQuestionId, settings, currentDevice, currentAnswer } = this.props
    const { progressBar, useSections, surveyWidth } = settings
    const currentQuestionType = this.props.questions.find(
      q => q.id === this.props.currentQuestionId,
    ).type

    let lastQuestion = questions[questions.length - 2]

    const currentPercentage = Math.round(
      (questions.findIndex(q => q.id === currentQuestionId) * 100) / (questions.length - 1),
    )
    return (
      // TODO: Change this layout based on device
      // TODO: Google fonts based on survey.settings
      <SurveyContainer>
        {console.log('current value', this.props)}
        {settings.showQid ? <QuestionId>{currentQuestionId}</QuestionId> : null}
        {console.log('curr device', currentDevice)}
        {currentDevice === 'mobile' ? (
          <MobileGridLayout
            settings={settings}
            question={questions.find(q => q.id === currentQuestionId)}
          >
            <SurveyContent ref={this.surveyContentRef} surveyWidth={surveyWidth}>
              <QuestionWithData
                question={questions.find(q => q.id === currentQuestionId)}
                settings={settings}
                submitAnswer={this.selectAnswer}
                currentSurveyQuestions={questions}
              />
            </SurveyContent>
            {this.hideUi(currentQuestionId) ? (
              <SurveyControls>
                {this.hideUi(currentQuestionId)}
                <ButtonContainer>
                  {this.displayBackButton(currentQuestionId) ? (
                    <BackButton
                      color={settings.primaryColour ? settings.primaryColour : null}
                      onClick={this.previousQuestion}
                    />
                  ) : null}
                  {currentQuestionType !== 'endPage' ? (
                    <NextButton
                      onKeyPress={this.handleKeyPress}
                      onClick={this.nextQuestion}
                      ref={this.nextButtonRef}
                      color={settings.primaryColour ? settings.primaryColour : null}
                    />
                  ) : null}
                  {currentQuestionId === lastQuestion.id && !settings.hideSubmit ? (
                    <SubmitButton
                      color={settings.primaryColour ? settings.primaryColour : null}
                      onClick={this.successMessage}
                    />
                  ) : null}
                </ButtonContainer>
                {useSections ? (
                  <StepProgressBar settings={settings} currentQuestion={currentQuestionId} />
                ) : null}
                {progressBar ? (
                  <ProgressBar status={`${currentPercentage} %`} percentage={currentPercentage} />
                ) : null}
              </SurveyControls>
            ) : null}
          </MobileGridLayout>
        ) : (
          <DesktopLayout
            settings={settings}
            question={questions.find(q => q.id === currentQuestionId)}
          >
            <SurveyContent ref={this.surveyContentRef} surveyWidth={surveyWidth}>
              <QuestionWithData
                question={questions.find(q => q.id === currentQuestionId)}
                settings={settings}
                submitAnswer={this.selectAnswer}
                currentSurveyQuestions={questions}
              />
            </SurveyContent>
            {this.hideUi(currentQuestionId) ? (
              <SurveyControls>
                {this.hideUi(currentQuestionId)}
                <ButtonContainer>
                  {this.displayBackButton(currentQuestionId) ? (
                    <BackButton
                      color={settings.primaryColour ? settings.primaryColour : null}
                      onClick={this.previousQuestion}
                    />
                  ) : null}
                  {currentQuestionType !== 'endPage' ? (
                    <NextButton
                      onKeyPress={this.handleKeyPress}
                      onClick={this.nextQuestion}
                      ref={this.nextButtonRef}
                      color={settings.primaryColour ? settings.primaryColour : null}
                    />
                  ) : null}
                  {currentQuestionId === lastQuestion.id && !settings.hideSubmit ? (
                    <SubmitButton
                      color={settings.primaryColour ? settings.primaryColour : null}
                      onClick={this.successMessage}
                    />
                  ) : null}
                </ButtonContainer>
                {useSections ? (
                  <StepProgressBar settings={settings} currentQuestion={currentQuestionId} />
                ) : null}
                {progressBar ? (
                  <ProgressBar status={`${currentPercentage} %`} percentage={currentPercentage} />
                ) : null}
              </SurveyControls>
            ) : null}
          </DesktopLayout>
        )}
      </SurveyContainer>
    )
  }
}

Survey.propTypes = {
  currentQuestionId: PropTypes.string.isRequired,
  questions: PropTypes.instanceOf(Object).isRequired,
  settings: PropTypes.instanceOf(Object).isRequired,
  updateAnswer: PropTypes.func.isRequired,
  setCurrentQuestion: PropTypes.func,
  goToPreviousQuestion: PropTypes.func.isRequired,
  goToNextQuestion: PropTypes.func.isRequired,
  injectCurrent: PropTypes.func,
  retrieveAnswer: PropTypes.func.isRequired,
  startLoop: PropTypes.func.isRequired,
  endLoop: PropTypes.func.isRequired,
  currentAnswer: PropTypes.instanceOf(Object),
}

const QuestionId = styled.p`
  color: red;
  position: absolute;
  top: 30px;
  right: 10%;
`

const SurveyControls = styled.div`
  /* background-color: yellow; */
  position: absolute;
  bottom: 0px;
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;
  
  @media (min-width: 0px) and (max-width: 479px) {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 20vh;
    box-shadow: 0px 0px 10px 0px;
    z-index: 99;
    background-color: white;
    opacity: 1;
    /* ${StepProgressBar} {
      display: none;
    } */
  }
`

const SurveyContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
`

const SurveyContent = styled.div`
  /* extra large viewport */
  margin: 0;
  display: flex;
  flex-direction: column;
  width: ${props => (props.surveyWidth ? '1200px' : '800px')};
  /* width: 800px; */
  align-items: flex-start;
  height: auto;
  overflow-y: hidden;
  overflow-x: hidden;
  scrollbar-color: rgba(0, 0, 0, 0.15); /* Moz scrollbar Styling */
  scrollbar-width: thin; /* Moz scrollbar Styling */
  ::-webkit-scrollbar {
    width: 14px;
    height: 18px;
    margin-left: 1rem;
  }
  ::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  @media (min-width: 0px) and (max-width: 479px) {
    /* small viewport  */
    overflow-y: auto !important; /* Override updateDom()  */
    height: inherit;
    width: 100vw;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    /* medium viewport  */
    overflow-y: auto !important;
    align-items: center;
  }
  @media (min-width: 769px) and (max-width: 1025px) {
    width: 1200px;
    /* large viewport  */
    overflow-y: auto !important;
    align-items: center;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px auto;
  width: 345px;
  justify-content: space-between;
  @media (min-width: 0px) and (max-width: 478px) {
    justify-content: space-around;
    ${BackButton} {
      width: 45%;
      height: 3.125rem;
    }
    /* add some dropshadow to show fixed element / or different colour*/
  }
`

Survey.propTypes = {
  currentQuestionId: PropTypes.string.isRequired,
  questions: PropTypes.instanceOf(Object).isRequired,
  updateAnswer: PropTypes.func.isRequired,
  goToPreviousQuestion: PropTypes.func.isRequired,
  retrieveAnswer: PropTypes.func.isRequired,
  startLoop: PropTypes.func.isRequired,
  breakLoop: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  increment,
  decrement,
  setCurrentQuestion,
  updateAnswer,
  retrieveAnswer,
  goToPreviousQuestion,
  startLoop,
  breakLoop,
  endLoop,
  goToNextQuestion,
  updateCurrent,
  injectCurrent,
}

function mapStateToProps(state, props) {
  return {
    currentQuestionId:
      state.currentQuestionId === null ? props.questions[0].id : state.currentQuestionId,
    currentQuestionType:
      state.currentQuestionId === null ? props.questions[0].type : state.currentQuestionType,
    answers: state.userAnswers,
    currentAnswer: state.currentAnswer,
    loopIndex: state.loopIndex,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Survey)

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
/**
 * Navigation button
 * @param {*} props
 */
const NextButton = forwardRef((props, ref) => {
  const { onClick, color, showButton } = props
  return (
    <Button
      showButton={showButton}
      ref={ref}
      color={color}
      type="button"
      onClick={onClick}
      disabled={showButton}
    >
      Next
      <i className="material-icons">keyboard_arrow_right</i>
    </Button>
  )
})

NextButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
}

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  height: 2rem;
  width: 160px;
  font-weight: 501;
  opacity: ${props => (props.showButton ? 0 : 1)};
  background-color: ${props => (props.color ? props.color : '#E82D7C')};
  /* background-color: #7398ff; */
  /* background-color: #ffffff; */
  cursor: pointer;
  transition: background 250ms ease-in-out, transform 150ms ease;
  color: white;
  border-radius: 3px;
  i {
    margin-left: 1rem;
  }
  user-select: none;
  @media (min-width: 0px) and (max-width: 479px) {
    /* width: 90vw;
    height: 3.125rem;
    margin-bottom: 1rem; */
    width: 45%;
    height: 3.125rem;
  }
  /* color: #5d5d5de8; */
`

export default NextButton

import React from 'react'
import styled from 'styled-components/macro'

const Circle = ({ dynamicColour, gridDisplay, active, type }) => {
  return (
    <Container type={type} dynamicColour={dynamicColour} gridDisplay={gridDisplay} active={active}>
      <svg className="svg" version="1.1" id="tick" viewBox="6 5 26 26">
        <polyline
          className="path"
          points="
          11.6,20 15.9,24.2 26.4,13.8 "
        />
      </svg>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 25px;
  height: 25px;
  padding: 2px;
  border-radius: 25px;
  border: ${props => (props.active ? '1px solid white' : 'none')};
  border: ${props =>
    props.type === 'multiSelect' && !props.active ? '1px solid #757575' : 'none'};
  .svg-wrapper {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  background-color: #fff;
  .svg {
    width: 30px;
  }
  .path {
    fill: transparent;
    stroke: ${props => (props.active ? props.dynamicColour : '#53bf8b')};
    stroke-width: 3;
    stroke-dasharray: 25;
    stroke-dashoffset: ${props => (props.active ? 0 : 26)};
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dashoffset 250ms ease-in-out;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    top: auto;
  }
`

export default Circle

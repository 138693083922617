const companyNames = [
  { Org_Name: 'A R Fell Limited' },
  { Org_Name: "Accountants Hawke's Bay Limited" },
  { Org_Name: 'Accounting House (Hamilton) Limited' },
  { Org_Name: 'Accounting HQ Limited' },
  { Org_Name: 'Action Accounting Limited' },
  { Org_Name: 'Agar Fenwick Limited' },
  { Org_Name: 'Allan McNeill Chartered Accountants Limited' },
  { Org_Name: 'Armstrong Fleming Limited' },
  { Org_Name: 'Baker Tilly Staples Rodway Limited' },
  { Org_Name: 'Balance Chartered Accountants Limited' },
  { Org_Name: 'Bavage Chapman Knight Limited' },
  { Org_Name: 'Beker Findlay Allan Limited' },
  { Org_Name: 'Bennett Currie Limited' },
  { Org_Name: 'Bennetts Proactive Accountants Limited' },
  { Org_Name: 'BM Accounting Limited' },
  { Org_Name: 'Bradley Accounting Limited' },
  { Org_Name: 'Brophy Knight Limited' },
  { Org_Name: 'Brown Glassford & Co Limited' },
  { Org_Name: 'Brown Webb Richardson Limited' },
  { Org_Name: 'Browns Real Estate Limited' },
  { Org_Name: 'Bruce Sparrow & Associates Limited' },
  { Org_Name: 'Business One Consulting Limited' },
  { Org_Name: 'CA Trustees (CooperAitken) Limited' },
  { Org_Name: 'Campbell & Co Chartered Accountants Limited' },
  { Org_Name: 'Candy Gillespie Financial Services Limited' },
  { Org_Name: 'Coast Accounting Solutions Limited' },
  { Org_Name: 'Colbert Cooper Trustees Limited' },
  { Org_Name: 'Compass Business Partners Limited' },
  { Org_Name: 'Cookson Forbes & Associates Limited' },
  { Org_Name: 'Co-Pilot Holdings Limited' },
  { Org_Name: 'Craig Anderson Limited' },
  { Org_Name: 'Croy Consultants Limited' },
  { Org_Name: 'DGS Accounting Limited' },
  { Org_Name: 'Diane Myers Chartered Accountant Limited' },
  { Org_Name: 'Diprose Miller - Te Aroha Limited' },
  { Org_Name: 'Drumm Nevatt & Associates Limited' },
  { Org_Name: 'Ean Brown Partners Limited' },
  { Org_Name: 'Enumerate Limited' },
  { Org_Name: 'Epplett & Co. Limited' },
  { Org_Name: 'EQ8 Accounting Limited' },
  { Org_Name: 'F T Dooley Limited' },
  { Org_Name: 'Farmit Accountants Holdings Limited' },
  { Org_Name: 'Finnz Audit Limited' },
  { Org_Name: 'Finnz Limited' },
  { Org_Name: 'Focus Chartered Accountants Limited' },
  { Org_Name: 'Fonterra Co-Operative Group Limited' },
  { Org_Name: 'Gabites Limited' },
  { Org_Name: 'Good for Business - Accountants & Business Advisors Limited' },
  { Org_Name: 'Horton & Co Limited' },
  { Org_Name: 'Koller & Hassall Limited' },
  { Org_Name: 'KPMG Auckland Limited' },
  { Org_Name: 'KPMG Wellington Limited' },
  { Org_Name: 'Lawson Avery Limited' },
  { Org_Name: 'Leech & Partners Limited' },
  { Org_Name: 'Lynch & Partners Limited' },
  { Org_Name: 'Mackay Bailey Limited' },
  { Org_Name: 'Mackrell Murcott LP Limited' },
  { Org_Name: 'Manning Warner Browne Chartered Accountants Limited' },
  { Org_Name: 'Maxims Accounting Limited' },
  { Org_Name: 'Mayston Partners Limited' },
  { Org_Name: 'McCallum & Dallas Limited' },
  { Org_Name: 'McDonald Vague & Partners Limited' },
  { Org_Name: 'McIntyre Dick & Partners Limited' },
  { Org_Name: 'McKenzie Strawbridge Limited' },
  { Org_Name: 'Miller Gale & Winter Limited' },
  { Org_Name: 'Milnes Beatson Limited' },
  { Org_Name: 'MMCA Tasman Limited' },
  { Org_Name: 'Moore Markhams Auckland Limited' },
  { Org_Name: 'Moore Markhams Hawkes Bay Limited' },
  { Org_Name: 'Moore Markhams Wanganui Limited' },
  { Org_Name: 'Murray Kidd Falconer Limited' },
  { Org_Name: 'Nick Hoogeveen & Associates Limited' },
  { Org_Name: 'Noble & Lee Limited' },
  { Org_Name: "O'Connor Richmond Limited" },
  { Org_Name: 'Oldershaw & Co Limited' },
  { Org_Name: 'Osborne Group 2013 Limited' },
  { Org_Name: "Osborne's Chartered Accountants Limited" },
  { Org_Name: 'P S Alexander & Associates Limited' },
  { Org_Name: 'Paul Pearce Limited' },
  { Org_Name: 'Peters Doig Limited' },
  { Org_Name: 'PKF Bay Of Islands Limited' },
  { Org_Name: 'PKF Hamilton Audit Limited' },
  { Org_Name: 'PKF Kerikeri Limited' },
  { Org_Name: 'PKF Nominees Hamilton Limited' },
  { Org_Name: 'PKF Rutherfords Limited' },
  { Org_Name: 'Provident Insurance Corporation Limited' },
  { Org_Name: 'Ross Gallagher Limited' },
  { Org_Name: 'Schurr & Ireland - New Plymouth Limited' },
  { Org_Name: 'Sellar & Sellar Nominees Limited' },
  { Org_Name: 'Silks Audit Chartered Accountants Limited' },
  { Org_Name: 'Southey Sayer Holdings Limited' },
  { Org_Name: 'Spicer Accounting Limited' },
  { Org_Name: 'Strong Chartered Accountants Limited' },
  { Org_Name: 'Sudburys Limited' },
  { Org_Name: 'Sumpter Baughen Chartered Accountants Limited' },
  { Org_Name: 'Tait Pearce & Co (2020) Limited' },
  { Org_Name: 'Tandem Group Chartered Accountants Limited' },
  { Org_Name: 'Tax Matters Limited' },
  { Org_Name: 'UHY Haines Norton (Auckland) Limited' },
  { Org_Name: 'Velocite Limited' },
  { Org_Name: 'Wheeler Campbell Labour Markets Limited' },
  { Org_Name: 'William Buck (NZ) Limited' },
  { Org_Name: 'Xero Limited' },
  { Org_Name: 'Yovich Hayward Pevats Johnston Limited' },
  { Org_Name: 'Hogans Limited' },
  { Org_Name: 'Hudson Kasper Nominees Limited' },
  { Org_Name: 'Humphries Associates Limited' },
  { Org_Name: 'Carbon Forest Services Limited' },
  { Org_Name: 'New Zealand Carbon Farming Limited' },
  { Org_Name: 'Roger Dickie Plantations Limited' },
  { Org_Name: 'Beaumont Shelter Trimming & Mulching Limited' },
  { Org_Name: 'Bradstreet Contractors Limited' },
  { Org_Name: 'Cameron Ag Limited' },
  { Org_Name: 'Capper Contracting 1981 Limited' },
  { Org_Name: 'Cullen E F & E Limited' },
  { Org_Name: 'Darryl Isaac Limited' },
  { Org_Name: 'Davenport Agricultural Services Limited' },
  { Org_Name: 'Dawson Agri Limited' },
  { Org_Name: "D'Emden Contracting 2017 Limited" },
  { Org_Name: 'Downes Services Limited' },
  { Org_Name: 'Gaskell A T Limited' },
  { Org_Name: 'Gorringes Limited' },
  { Org_Name: 'Hawke P J Limited' },
  { Org_Name: 'Kevin Bachada Limited' },
  { Org_Name: 'Manak Contracting Limited' },
  { Org_Name: 'OHT Limited' },
  { Org_Name: 'Suisted R K Limited' },
  { Org_Name: 'Two Mulch Services Limited' },
  { Org_Name: 'Urban Plus Weedsprayers Limited' },
  { Org_Name: 'ABC Milking Solutions Limited' },
  { Org_Name: 'Bay Milking Equipment Limited' },
  { Org_Name: 'Gough Agritech Limited' },
  { Org_Name: 'Ngatea / Waiuku Milking Machines Limited' },
  { Org_Name: 'Pratt Milking Machines Limited' },
  { Org_Name: 'Read Industrial Limited' },
  { Org_Name: 'Opihi Nurseries Limited' },
  { Org_Name: 'Hectre Limited' },
  { Org_Name: 'Tupu.nz Limited' },
  { Org_Name: 'Agricultural Business Associates Limited' },
  { Org_Name: 'Allan Agricultural Consulting Limited' },
  { Org_Name: 'Anderson Consultancy Limited' },
  { Org_Name: 'Brendan Attrill Agriculture Limited' },
  { Org_Name: 'Farm Advisors NZ Limited' },
  { Org_Name: 'Farm Ventures Limited' },
  { Org_Name: 'Fidus Management Group Limited' },
  { Org_Name: 'Geoff Burton Farm Business Management Limited' },
  { Org_Name: 'John Dawson Consulting Limited' },
  { Org_Name: 'KapAg Limited' },
  { Org_Name: 'Kiwi Accounting Solutions Limited' },
  { Org_Name: 'Roy Johnson - R J Rural Limited' },
  { Org_Name: 'Rural Business Solutions Limited' },
  { Org_Name: 'Sheppard Agriculture Limited' },
  { Org_Name: 'Total Ag Control Limited' },
  { Org_Name: 'Walton Farm Consulting Limited' },
  { Org_Name: 'Action Ecology Limited' },
  { Org_Name: 'AgDesign Limited' },
  { Org_Name: 'Integrity Soils Limited' },
  { Org_Name: 'Allen Bell Registered Forestry Consultant Limited' },
  { Org_Name: 'Brett Gilmore Consulting Limited' },
  { Org_Name: 'Forest And Harvesting Managers Limited' },
  { Org_Name: 'Forest Production Systems Limited' },
  { Org_Name: 'ForestStat Limited' },
  { Org_Name: 'Venture Forestry Limited' },
  { Org_Name: 'Avo Alderton Limited' },
  { Org_Name: 'Bio Soil And Crop Limited' },
  { Org_Name: 'DMS Limited' },
  { Org_Name: 'Peter Mulligan Horticultural Consultant Limited' },
  { Org_Name: 'AgSafeNZ Limited' },
  { Org_Name: 'Brent Ingram Consultancy Limited' },
  { Org_Name: 'Target Focus Limited' },
  { Org_Name: 'Abattoirs Association of New Zealand Limited' },
  { Org_Name: 'Dairy Companies Association of New Zealand Limited' },
  { Org_Name: 'Forest Industry Contractors Association (FICA) Limited' },
  { Org_Name: 'GrowingNZ Limited' },
  { Org_Name: 'Organics Aotearoa New Zealand Limited' },
  { Org_Name: 'OSPRI New Zealand Limited' },
  { Org_Name: 'Southern Inshore Fisheries Management Company Limited' },
  { Org_Name: 'The Paua Industry Council Limited' },
  { Org_Name: 'Sheep & Goat Milk Limited' },
  { Org_Name: 'Counties Custom Killing Limited' },
  { Org_Name: 'Homegrown Farm Fresh Meats Limited' },
  { Org_Name: 'Wally Smith Killing & Processing Limited' },
  { Org_Name: 'Ngati Hine Forestry Trust Limited' },
  { Org_Name: 'Pukenui Western Hills Forest Charitable Trust Limited' },
  { Org_Name: 'Puketi Forest Trust Limited' },
  { Org_Name: 'Tanes Tree Trust Limited' },
  { Org_Name: 'The Forest School Limited' },
  { Org_Name: 'Farm Medix Limited' },
  { Org_Name: 'Inforest Training (2003) Limited' },
  { Org_Name: 'Accomplish Accountants Limited' },
  { Org_Name: 'Accountants on Hobson Limited' },
  { Org_Name: 'AgriFocus Limited' },
  { Org_Name: 'Baker Tilly Staples Rodway Taranaki Limited' },
  { Org_Name: 'BDO Invercargill Limited' },
  { Org_Name: 'BDO Rotorua Oldco Limited' },
  { Org_Name: 'Broadway Accounting Solutions Limited' },
  { Org_Name: 'Graham & Dobson Limited' },
  { Org_Name: 'ICL Chartered Accountants Limited' },
  { Org_Name: 'Moore Markhams Otago Limited' },
  { Org_Name: 'Noone Plus Limited' },
  { Org_Name: 'Watershed Chartered Accountants Limited' },
  { Org_Name: 'Grant Thornton (Christchurch) Limited' },
  { Org_Name: 'HWI Cover Limited' },
  { Org_Name: 'Kelman & Co Limited' },
  { Org_Name: 'Malloch McClean (Invercargill) Limited' },
  { Org_Name: 'MAQ Chartered Accountants Limited' },
  { Org_Name: 'Margaret Ward & Co Limited' },
  { Org_Name: 'Martin Wakefield Financial Services Limited' },
  { Org_Name: 'Moore Markhams Wairarapa Limited' },
  { Org_Name: 'Moore Markhams Wellington Audit Limited' },
  { Org_Name: 'Number Wise Limited' },
  { Org_Name: 'Peak Chartered Accountants Limited' },
  { Org_Name: 'Pinny & Associates Limited' },
  { Org_Name: 'Quantum Chartered Accountants Limited' },
  { Org_Name: 'Rightway - Dunedin Limited' },
  { Org_Name: 'Rightway - Wairarapa Limited' },
  { Org_Name: 'Rostrevor Professional Group Limited' },
  { Org_Name: 'Rova Chartered Accountants Limited' },
  { Org_Name: 'Savage & Savage Limited' },
  { Org_Name: 'Schurr & Ireland - Eltham Limited' },
  { Org_Name: 'Sewell & Wilson Limited' },
  { Org_Name: 'Shelley Tweedy & Associates Limited' },
  { Org_Name: 'Smith Mitchell Chartered Accountants Limited' },
  { Org_Name: 'Stephen Lace & Associates Limited' },
  { Org_Name: 'Tag Business Services Limited' },
  { Org_Name: 'TFS Chartered Accountants - Wellington Limited' },
  { Org_Name: 'The Hive Chartered Accountants Limited' },
  { Org_Name: 'Vet Your Pets Limited' },
  { Org_Name: 'Vetfarm Limited' },
  { Org_Name: 'Vetplus Solutions Limited' },
  { Org_Name: 'Vets On Alabama Limited' },
  { Org_Name: 'Withers & Co. Limited' },
  { Org_Name: 'Yardley Lo & Associates Limited' },
  { Org_Name: 'Drylandcarbon GP One Limited' },
  { Org_Name: 'New Zealand Permanent Forests Limited' },
  { Org_Name: 'NZ Forestry Investments Limited' },
  { Org_Name: 'A1 Orchard Spreading Limited' },
  { Org_Name: 'Air8Tors Whakatane Limited' },
  { Org_Name: 'Avomax 2014 Limited' },
  { Org_Name: 'Hort Force Limited' },
  { Org_Name: 'Kaiser Ag. Limited' },
  { Org_Name: 'Litchfields Agricultural Services Limited' },
  { Org_Name: 'Neven Granich (2017) Limited' },
  { Org_Name: 'Neville Marsh Weighbridges Limited' },
  { Org_Name: 'Rydgeview Mowing & Mulching Services Limited' },
  { Org_Name: 'Spray-Tec Consultants Limited' },
  { Org_Name: 'Walling Contracting Southland Limited' },
  { Org_Name: 'Animal Stuff Limited' },
  { Org_Name: 'Essential Nutrition Limited' },
  { Org_Name: 'EM-NZ Limited' },
  { Org_Name: 'Fertco Limited' },
  { Org_Name: 'Osflo Fertiliser Limited' },
  { Org_Name: 'Revital Group Limited' },
  { Org_Name: 'Roots Shoots & Fruits Limited' },
  { Org_Name: 'Magon Contracting Limited' },
  { Org_Name: 'NZ Forestry Investments Limited' },
  { Org_Name: 'The New Zealand Redwood Company' },
  { Org_Name: 'Treescapes Rotorua Limited' },
  { Org_Name: 'Deals On Wheels Limited' },
  { Org_Name: 'Lynwood Avocado Nursery Limited' },
  { Org_Name: 'Tasman Crop Protection 2006 Limited' },
  { Org_Name: 'The Grove Supply Co Limited' },
  { Org_Name: 'ABC Software Limited' },
  { Org_Name: 'Dairy Solutionz (NZ) Limited' },
  { Org_Name: 'Farmiq PGP Limited' },
  { Org_Name: 'I.Agri Limited' },
  { Org_Name: 'EveryCow Limited' },
  { Org_Name: 'Macfarlane Rural Business Limited' },
  { Org_Name: 'NZ Farm Management Limited' },
  { Org_Name: 'WealthDesign Limited' },
  { Org_Name: 'AgKnowledge Publishing Limited' },
  { Org_Name: 'Agvice Limited' },
  { Org_Name: 'Dairy Production Systems Limited' },
  { Org_Name: 'LandVision Limited' },
  { Org_Name: 'Produco Limited' },
  { Org_Name: 'Action Forest Management Limited' },
  { Org_Name: 'Forest Services Limited' },
  { Org_Name: 'Kohntrol Forest Services Limited' },
  { Org_Name: 'Land And Forest Consultants Limited' },
  { Org_Name: 'Laurie Forestry Australia Limited' },
  { Org_Name: 'M&R Forestland Management Limited' },
  { Org_Name: 'Margules Groome Consulting Limited' },
  { Org_Name: 'N.J. Sim Forestry Services Limited' },
  { Org_Name: 'Northland Forest Managers (NFM) Limited' },
  { Org_Name: 'NZ Forestry Investments Limited' },
  { Org_Name: 'Pango Productions Limited' },
  { Org_Name: 'Professional Harvesting Solutions Limited' },
  { Org_Name: 'Sustainable Forest Management NZ (SFM) Limited' },
  { Org_Name: "Tomorrow's Forests Limited" },
  { Org_Name: 'Woodbank Angus Limited' },
  { Org_Name: 'Woodlands Pacific Consulting Limited' },
  { Org_Name: "Agri-Women's Development Trust Limited" },
  { Org_Name: 'Aquaculture New Zealand Limited' },
  { Org_Name: 'Fertiliser Association of New Zealand Limited' },
  { Org_Name: 'Forest Industry Engineering Association Limited' },
  { Org_Name: 'Kiwifruit NZ Limited' },
  { Org_Name: 'Kiwifruit Vine Health Limited' },
  { Org_Name: 'Meat Industry Association of New Zealand Inc (MIA) Limited' },
  { Org_Name: 'New Zealand Apples & Pears Limited' },
  { Org_Name: 'New Zealand Asparagus Council Limited' },
  { Org_Name: 'New Zealand Federation Of Commercial Fishermen Inc (NZFCF - NZ Fish Fed) Limited' },
  { Org_Name: 'New Zealand Truffles Limited' },
  { Org_Name: 'NZ Institute of Primary Industry Management (NZIPIM) Limited' },
  { Org_Name: 'Olives New Zealand Limited' },
  { Org_Name: 'Passionfruit NZ Limited' },
  { Org_Name: 'Retail Meat New Zealand Limited' },
  { Org_Name: 'Rural Contractors New Zealand (RCNZ) Limited' },
  { Org_Name: 'Seafood New Zealand Limited' },
  { Org_Name: 'TomatoesNZ Limited' },
  { Org_Name: 'W M Pacific Export Limited' },
  { Org_Name: 'New Zealand Dairy Goats Limited' },
  { Org_Name: 'Charing Cross Sheep Dairy Co Limited' },
  { Org_Name: 'Fernglen Farm Limited' },
  { Org_Name: 'Spring Sheep Milk Co. Limited' },
  { Org_Name: 'Clelands Timber Products Limited' },
  { Org_Name: 'Herman Timber Limited' },
  { Org_Name: 'N.Z. Future Forest Products Limited' },
  { Org_Name: 'Nz Bio Forestry Limited' },
  { Org_Name: 'Pine Sawmills (New Zealand) Limited' },
  { Org_Name: 'Nature Avoco Limited' },
  { Org_Name: 'Southern Produce Marketing Limited' },
  { Org_Name: 'The Village Press Olive Groves Limited' },
  { Org_Name: 'Marine Foods Limited' },
  { Org_Name: 'Bird & Forest Limited' },
  { Org_Name: 'Ekos Limited' },
  { Org_Name: 'Ovis Management Limited' },
  { Org_Name: 'Rural Women NZ (RWNZ) Limited' },
  { Org_Name: 'Te Wai Māori Trustee Limited' },
  { Org_Name: 'Franklin Institute Of Agri-Technology Limited' },
  { Org_Name: 'Accounting One Limited' },
  { Org_Name: 'AgBiz Accountants Limited' },
  { Org_Name: 'Ake Accounting (2015) Limited' },
  { Org_Name: 'BDO Wellington Partnership Limited' },
  { Org_Name: 'Findex Central Otago - Queenstown Limited' },
  { Org_Name: 'Grant Thornton Wellington Limited' },
  { Org_Name: 'Green Grass Accounting Limited' },
  { Org_Name: '3 Stone Vets Limited' },
  { Org_Name: 'Aorangi Veterinary Services Limited' },
  { Org_Name: 'Atkinson & Associates Vet Services Limited' },
  { Org_Name: 'Bay of Islands Veterinary Services Limited' },
  { Org_Name: 'Bayvet Limited' },
  { Org_Name: 'Canterbury Vets Limited' },
  { Org_Name: 'Clutha Vets Animal Health Centre Limited' },
  { Org_Name: 'Coastal Veterinary Services Limited' },
  { Org_Name: 'Dairy Flat Veterinary Clinic Limited' },
  { Org_Name: 'Dargaville Veterinary Centre 2018 Limited' },
  { Org_Name: 'East Coast Farm Vets Limited' },
  { Org_Name: 'Eastland Veterinary Services Limited' },
  { Org_Name: 'Elders Wairarapa Vet Services Limited' },
  { Org_Name: 'Eltham District Veterinary Services Limited' },
  { Org_Name: 'Hauraki Vertinary Services Limited' },
  { Org_Name: 'Hunterville Veterinary Club Limited' },
  { Org_Name: 'Keinzley Agvet Limited' },
  { Org_Name: 'Matamata Veterinary Services Limited' },
  { Org_Name: 'Murrays Veterinary Clinic Limited' },
  { Org_Name: 'Northland Veterinary Group Limited' },
  { Org_Name: 'NSVets Limited' },
  { Org_Name: 'Opotiki Veterinary Centre Limited' },
  { Org_Name: 'Otautau Vets Limited' },
  { Org_Name: 'Remarkable Vets Arrowtown Limited' },
  { Org_Name: 'Ruapehu Veterinary Services Limited' },
  { Org_Name: 'Selwyn Rakaia Vet services Limited' },
  { Org_Name: 'Southern Rangitikei Veterinary Services Limited' },
  { Org_Name: 'Taranaki Veterinary Centre Hawera Limited' },
  { Org_Name: 'Tararua Veterinary Services Limited' },
  { Org_Name: 'Taupo Veterinary Centre Limited' },
  { Org_Name: 'Tauranga Veterinary Services Limited' },
  { Org_Name: 'Te Aroha Veterinary Services Limited' },
  { Org_Name: 'Te Puke Veterinary Centre Limited' },
  { Org_Name: 'The Cow Vets Morrinsville Limited' },
  { Org_Name: 'The Dairy Vet Limited' },
  { Org_Name: 'Tokoroa & Districts Veterinary Services Limited' },
  { Org_Name: 'Town & Country Veterinary Services Limited' },
  { Org_Name: 'VE Veterinary Services Limited' },
  { Org_Name: 'Vet Marlborough Limited' },
  { Org_Name: 'Vetco Limited' },
  { Org_Name: 'VetsOne Limited' },
  { Org_Name: 'Waihi Veterinary Services Limited' },
  { Org_Name: 'Waikiwi Vet Services Limited' },
  { Org_Name: 'Wanganui Veterinary Services Limited' },
  { Org_Name: 'Warkworth Veterinary Services Limited' },
  { Org_Name: 'West Coast Vets Limited' },
  { Org_Name: 'Animal Breeding Services (ABS) Limited' },
  { Org_Name: 'Samen (NZ) Limited' },
  { Org_Name: 'St Leger Superior Genetics Limited' },
  { Org_Name: 'C&T Walker Construction Limited' },
  { Org_Name: 'Don Chapman Waikato Limited' },
  { Org_Name: 'Farm Buildings Limited' },
  { Org_Name: 'Greenwood sheds Limited' },
  { Org_Name: 'Waikato Shed Company Limited' },
  { Org_Name: 'Aztech Buildings Limited' },
  { Org_Name: 'Bluegrass Contracting Limited' },
  { Org_Name: 'Combined Contractors Limited' },
  { Org_Name: 'Jordaan Contracting Limited' },
  { Org_Name: 'Agrifeeds Limited' },
  { Org_Name: 'Animal Feed Barn Limited' },
  { Org_Name: 'Cambrige Grains Limited' },
  { Org_Name: 'Castlegate James (Nz) Limited' },
  { Org_Name: 'Denver Stock Feeds Limited' },
  { Org_Name: 'NRM Albany Limited' },
  { Org_Name: 'Nutrinza Limited' },
  { Org_Name: 'OSP Stockfeeds Limited' },
  { Org_Name: 'Sharpes Stock Feeds Limited' },
  { Org_Name: 'Takanini Feeds Retail Limited' },
  { Org_Name: 'Winston Stock Feed Limited' },
  { Org_Name: 'Fertilizer New Zealand Limited' },
  { Org_Name: 'Hortigro Limited' },
  { Org_Name: 'Key Industries (1997) Limited' },
  { Org_Name: 'Kiwi Fertiliser Company Limited' },
  { Org_Name: 'Landco Fertiliser Limited' },
  { Org_Name: 'Terracare Limited' },
  { Org_Name: 'Winstone Gypsum Resources Limited' },
  { Org_Name: 'Bear Cat New Zealand Limited' },
  { Org_Name: 'Better Dairy Solutions Limited' },
  { Org_Name: 'Ensign Limited' },
  { Org_Name: 'Kingfarm Services Products Limited' },
  { Org_Name: 'Lely New Zealand Limited' },
  { Org_Name: 'Livestock Feed Systems Limited' },
  { Org_Name: 'Mascus Limited' },
  { Org_Name: 'Ordish & Stevens Limited' },
  { Org_Name: 'Professional Farm Services Limited' },
  { Org_Name: 'Buck Forestry Services Limited' },
  { Org_Name: 'Forest Protection Services (FPS) Limited' },
  { Org_Name: 'LandPrepNZ Limited' },
  { Org_Name: 'Symbiosis Tree Services Limited' },
  { Org_Name: 'Treetech Limited' },
  { Org_Name: 'Treewise Pencils Nz Limited' },
  { Org_Name: 'Forest Floor (2006) Limited' },
  { Org_Name: 'Forest Genetics Limited' },
  { Org_Name: 'Northland Forest Nursery Limited' },
  { Org_Name: 'Aerial Surveys 1980 Limited' },
  { Org_Name: 'Agrecord Limited' },
  { Org_Name: 'Agrigate GP Limited' },
  { Org_Name: 'Agrismart Limited' },
  { Org_Name: 'Asset Forestry Logistics Limited' },
  { Org_Name: 'Concepts Rural Solutions Limited' },
  { Org_Name: 'DroneMate Agriculture Limited' },
  { Org_Name: 'Farmax Limited' },
  { Org_Name: 'FeedFlo Limited' },
  { Org_Name: 'Figured Limited' },
  { Org_Name: 'Interpine Innovation Limited' },
  { Org_Name: 'Overseer Limited' },
  { Org_Name: 'Quality Consultants New Zealand (QCONZ) Limited' },
  { Org_Name: 'Rezare Systems Limited' },
  { Org_Name: 'Smart Farm Software Limited' },
  { Org_Name: 'Timbersmart Limited' },
  { Org_Name: 'Irrigation Services (Southern) Limited' },
  { Org_Name: 'Rainer Irrigation Limited' },
  { Org_Name: 'RXPak Limited' },
  { Org_Name: 'NZ LandCare Trust Limited' },
  { Org_Name: 'Hauraki District Council' },
  { Org_Name: 'Matamata-Piako District Council' },
  { Org_Name: 'Thames-Coromandel District Council' },
  { Org_Name: 'Waipa District Council' },
  { Org_Name: 'Waitomo District Council' },
  { Org_Name: 'Chatham Islands Council' },
  { Org_Name: 'Agri Magic Limited' },
  { Org_Name: 'BakerAg NZ Limited' },
  { Org_Name: 'Compass Agribusiness Management Limited' },
  { Org_Name: 'Fergusson Lockwood & Associates Limited' },
  { Org_Name: 'Perrin Ag Consultants Limited' },
  { Org_Name: 'Rival Wealth Limited' },
  { Org_Name: 'The Agribusiness Group Limited' },
  { Org_Name: 'Landpro Limited' },
  { Org_Name: 'Ravensdown Environmental Limited' },
  { Org_Name: 'Cochrane & Son Logging Limited' },
  { Org_Name: 'Dana (NZ) Limited' },
  { Org_Name: 'Forest Management NZ (FMNZ) Limited' },
  { Org_Name: 'Forme Consulting Group Limited' },
  { Org_Name: 'IFS Growth Limited' },
  { Org_Name: 'Logic Forest Solutions Limited' },
  { Org_Name: 'T.A.M.L Forestry Limited' },
  { Org_Name: 'Edwards Heeney Consulting Limited' },
  { Org_Name: 'Fruition Horticulture (BOP) Limited' },
  { Org_Name: 'Prospa Group Limited' },
  { Org_Name: "Thompson's Horticulture Limited" },
  { Org_Name: 'Southern Cross Horticulture Limited' },
  { Org_Name: "Dairy Women's Network Limited" },
  { Org_Name: 'Federated Farmers Northland.Com Limited' },
  { Org_Name: 'Forest Industry Safety Council (FISC) Limited' },
  { Org_Name: 'Forestry Careers Limited' },
  { Org_Name: "Hawke's Bay Fruitgrowers' Association Limited" },
  { Org_Name: 'Hop New Zealand Limited' },
  { Org_Name: 'Interworld Holdings (NZ) Limited' },
  { Org_Name: 'Irrigation NZ Limited' },
  { Org_Name: 'Maori Kiwifruit Growers Incorporated Limited' },
  { Org_Name: 'New Zealand Avocado Company Limited' },
  { Org_Name: 'New Zealand Buttercup Squash Council Inc Limited' },
  { Org_Name: 'New Zealand Forest Owners Association Limited' },
  { Org_Name: 'New Zealand Institute of Forestry Limited' },
  { Org_Name: 'New Zealand Kiwifruit Growers Incorporated (NZKGI) Limited' },
  { Org_Name: 'New Zealand Meat Board Limited' },
  { Org_Name: 'New Zealand Vegetables Limited' },
  { Org_Name: 'New Zealand Veterinary Association Limited' },
  { Org_Name: 'NZ Feijoa Growers Association Limited' },
  { Org_Name: 'NZ Pork Limited' },
  { Org_Name: 'NZ Young Farmers Limited' },
  { Org_Name: 'Onions New Zealand Inc Limited' },
  { Org_Name: 'Potatoes New Zealand Limited' },
  { Org_Name: 'Strawberry Growers NZ Limited' },
  { Org_Name: 'Over The Moon Dairy Company Limited' },
  { Org_Name: 'Arrow Commodities (NZ) Limited' },
  { Org_Name: 'Kaihu Valley Sawmill Limited' },
  { Org_Name: 'Mahoe Sawmills 2012 Limited' },
  { Org_Name: 'Northland Timber Company Limited' },
  { Org_Name: 'NZ Timber (Whangarei) Limited' },
  { Org_Name: 'Rosvall Sawmill Limited' },
  { Org_Name: 'Auckland Pack & Cool Limited' },
  { Org_Name: 'Golden Bay Fruit Limited' },
  { Org_Name: 'Lumberlink Limited' },
  { Org_Name: 'PF Services Limited' },
  { Org_Name: 'PickMee Fruit Company Limited' },
  { Org_Name: 'Riverlock Dairy Farm Limited' },
  { Org_Name: 'Zeafruit Company Limited' },
  { Org_Name: 'Ample Group Limited' },
  { Org_Name: 'Provernance Limited' },
  { Org_Name: 'Eastern Fishing Limited' },
  { Org_Name: 'Lee Fish Australia Limited' },
  { Org_Name: 'Waikawa Fishing Company Limited' },
  { Org_Name: 'Greenpeace New Zealand Limited' },
  { Org_Name: 'SAFE for Animals Limited' },
  { Org_Name: 'Te Ohu Kaimoana Custodian Limited' },
  { Org_Name: 'Cawthron Institute Limited' },
  { Org_Name: 'New Zealand Dryland Initiative Limited' },
  { Org_Name: 'Agri Training Limited' },
  { Org_Name: 'Dairy Training Limited' },
  { Org_Name: 'Forestry Training Nz Limited' },
  { Org_Name: 'Quality Consultants New Zealand (QCONZ) Limited' },
  { Org_Name: 'Anaro Investments Limited' },
  { Org_Name: 'William Buck Audit (NZ) Limited' },
  { Org_Name: 'Energy Vets Taranaki Limited' },
  { Org_Name: 'Franklin Vets Limited' },
  { Org_Name: 'Levin & Horowhenua Vets Limited' },
  { Org_Name: 'New Plymouth District Vet Group Limited' },
  { Org_Name: 'North Canterbury Veterinary Clinics Limited' },
  { Org_Name: 'Rangiora Veterinary Centre Limited' },
  { Org_Name: 'South Wairarapa Veterinary Services Limited' },
  { Org_Name: 'Vet 111 Limited' },
  { Org_Name: 'Veterinary Centre Limited' },
  { Org_Name: 'Veterinary Services (Dannevirke) Limited' },
  { Org_Name: 'Vetlife Scientific Limited' },
  { Org_Name: 'Vetora Limited' },
  { Org_Name: 'Vets North Helensville Limited' },
  { Org_Name: 'Primacy Group Limited' },
  { Org_Name: 'CRV Enterprises Limited' },
  { Org_Name: 'Genetics Australia - Nz Limited' },
  { Org_Name: 'Semex Nz (North) Limited' },
  { Org_Name: 'Alpine Buildings NZ Limited' },
  { Org_Name: 'Coresteel New Zealand Limited' },
  { Org_Name: 'Field Engineering (2007) Limited' },
  { Org_Name: 'Formsteel Limited' },
  { Org_Name: 'REL Group 2012 Limited' },
  { Org_Name: 'Specialised structures Limited' },
  { Org_Name: 'Pioneer Group Limited' },
  { Org_Name: 'Seales Winslow Limited' },
  { Org_Name: 'Swap Stockfoods Limited' },
  { Org_Name: 'Agrisea New Zealand 2004 Limited' },
  { Org_Name: 'Active VMA Limited' },
  { Org_Name: 'Advantage Feeders Limited' },
  { Org_Name: 'Dairy Cooling Solutions Limited' },
  { Org_Name: 'DC Equipmnt Limited' },
  { Org_Name: 'Komatsu New Zealand Limited' },
  { Org_Name: 'Northland Farm Services Limited' },
  { Org_Name: 'Waikato Milking Systems U.S.A Limited' },
  { Org_Name: 'City Forests Limited' },
  { Org_Name: 'Ernslaw One Limited' },
  { Org_Name: 'Farman Turkington Forestry Limited' },
  { Org_Name: 'Fortus Limited' },
  { Org_Name: 'Land Clearing Solutions NZ Limited' },
  { Org_Name: 'Mark Gorringe Limited' },
  { Org_Name: 'Wenita Forest Products Limited' },
  { Org_Name: 'Wilding Wood Management Limited' },
  { Org_Name: 'AB Equipment Limited' },
  { Org_Name: 'Farmchief Machinery Limited' },
  { Org_Name: 'Horticentre Group Limited' },
  { Org_Name: 'Silvicom Limited' },
  { Org_Name: 'Dairy Solutionz (Processing) Limited' },
  { Org_Name: 'Cash Manager Focus Limited' },
  { Org_Name: 'Dobsons Holdings Limited' },
  { Org_Name: 'Ecolab Limited' },
  { Org_Name: 'Irrigation Express Limited' },
  { Org_Name: 'PGG Wrightson Investments Limited' },
  { Org_Name: 'Te Uru Rakau (Forestry New Zealand) Limited' },
  { Org_Name: 'Worksafe Aanz Limited' },
  { Org_Name: 'Buller District Council' },
  { Org_Name: 'Carterton District Council' },
  { Org_Name: "Central Hawke's Bay District Council" },
  { Org_Name: 'Clutha District Council' },
  { Org_Name: 'Far North District Council' },
  { Org_Name: 'Gore District Council' },
  { Org_Name: 'Grey District Council' },
  { Org_Name: 'Kaikoura District Council' },
  { Org_Name: 'Kaipara District Council' },
  { Org_Name: 'Kawerau District Council' },
  { Org_Name: 'Mackenzie District Council' },
  { Org_Name: 'Opotiki District Council' },
  { Org_Name: 'Otorohanga District Council' },
  { Org_Name: 'Rangitikei District Council' },
  { Org_Name: 'Ruapehu District Council' },
  { Org_Name: 'South Waikato District Council' },
  { Org_Name: 'South Wairarapa District Council' },
  { Org_Name: 'Stratford District Council' },
  { Org_Name: 'Tararua District Council' },
  { Org_Name: 'Waikato District Council' },
  { Org_Name: 'Waimate District Council' },
  { Org_Name: 'Wairoa District Council' },
  { Org_Name: 'Westland District Council' },
  { Org_Name: 'Whangarei District Council' },
  { Org_Name: 'AgFirst Limited' },
  { Org_Name: 'Mcintyre Dick & Partners Limited' },
  { Org_Name: 'Quality Consultants New Zealand (QCONZ) Limited' },
  { Org_Name: 'Forest360 Limited' },
  { Org_Name: 'Hancock Forest Management (NZ) Limited' },
  { Org_Name: 'Indufor Asia Pacific Limited' },
  { Org_Name: 'Northern Forest Products Limited' },
  { Org_Name: 'PF Olsen Nominee Limited' },
  { Org_Name: 'Rayonier Matariki Forests Limited' },
  { Org_Name: 'Ribbonwood N.Z. Limited' },
  { Org_Name: 'Woodmarketing Services Limited' },
  { Org_Name: 'Woodmetrics Limited' },
  { Org_Name: 'LinkSafe Limited' },
  { Org_Name: 'Mataura Valley Milk 2012 Limited' },
  { Org_Name: 'Milkio Foods Limited' },
  { Org_Name: 'Craigpine Timber Limited' },
  { Org_Name: 'Juken New Zealand Limited' },
  { Org_Name: 'Kajavala Forestry Limited' },
  { Org_Name: 'Kiwi Lumber (Dannevirke) Limited' },
  { Org_Name: 'Max Birt Sawmills Limited' },
  { Org_Name: 'Mt Pokaka Timber Products Limited' },
  { Org_Name: 'Pan Pac Forest Products (Otago) Limited' },
  { Org_Name: 'Papakura Timber Processors Limited' },
  { Org_Name: 'South Pine (Nelson) Limited' },
  { Org_Name: 'Southfields Group Limited' },
  { Org_Name: 'Summit Forests New Zealand Limited' },
  { Org_Name: 'Tenco EBS Limited' },
  { Org_Name: 'Westco Lumber Limited' },
  { Org_Name: 'WPI International Limited' },
  { Org_Name: 'Aongatete Avocados Limited' },
  { Org_Name: 'Apata Group Limited' },
  { Org_Name: 'Auckland Pack & Cool (Apac) Limited' },
  { Org_Name: 'Brichwood Limited' },
  { Org_Name: 'DMS Group Limited' },
  { Org_Name: 'Fern Ridge Produce Limited' },
  { Org_Name: 'Hume Orchard Management Limited' },
  { Org_Name: 'JP Import & Export Limited' },
  { Org_Name: 'Just Avocados Limited' },
  { Org_Name: 'KauriPak Limited' },
  { Org_Name: 'Mount Pack & Cool (Mpac) Limited' },
  { Org_Name: 'Opotiki Packaging and Coolstorage (Opac) Limited' },
  { Org_Name: 'Orangewood Limited' },
  { Org_Name: 'Punchbowl Investments Limited' },
  { Org_Name: "Trevelyan's Pack And Cool Limited" },
  { Org_Name: 'Highford Marketing Group Limited' },
  { Org_Name: 'NZCS Operations Limited' },
  { Org_Name: 'Vela Fishing Limited' },
  { Org_Name: 'Lake Taupo Forest Trust Limited' },
  { Org_Name: 'Rural Support Trust Limited' },
  { Org_Name: 'Taitokerau Maori Forests Inc Limited' },
  { Org_Name: 'Landcare Research New Zealand Limited' },
  { Org_Name: 'Scion Limited' },
  { Org_Name: 'Foundation for Arable Research (FAR) Limited' },
  { Org_Name: 'Competenz Limited' },
  { Org_Name: 'Eastern Institute of Technology (EIT) Limited' },
  { Org_Name: 'Nelson Marlborough Institute of Technology (NMIT) Limited' },
  { Org_Name: 'Northtec Limited' },
  { Org_Name: 'Tai Poutini Polytechnic Limited' },
  { Org_Name: 'Te Wananga o Aotearoa Limited' },
  { Org_Name: 'The Waikato Institute of Technology (Wintec) Limited' },
  { Org_Name: 'Baker Tilly Staples Rodway Christchurch Limited' },
  { Org_Name: 'Hammond & Moir Limited' },
  { Org_Name: 'Great South Vets Limited' },
  { Org_Name: 'VetEnt Limited' },
  { Org_Name: 'NZ Agri Brokers (NZAB) Limited' },
  { Org_Name: 'Omega Capital Limited' },
  { Org_Name: 'TSB Limited' },
  { Org_Name: 'Sabre Construction Limited' },
  { Org_Name: 'Far North Roading Group (FNR) Limited' },
  { Org_Name: 'Carrfields Holdings Limited' },
  { Org_Name: 'Deer World Limited' },
  { Org_Name: 'Delaval Lodge Limited' },
  { Org_Name: 'New Holland Agriculture Limited' },
  { Org_Name: 'Norwood Consolidated Limited' },
  { Org_Name: 'Terra Cat Limited' },
  { Org_Name: 'Ravensdown Limited' },
  { Org_Name: 'Native Solutions Limited' },
  { Org_Name: 'Waterforce Limited' },
  { Org_Name: 'Department of Conservation Limited' },
  { Org_Name: 'Ministry for Primary Industries (MPI) Limited' },
  { Org_Name: 'Ministry for the Environment Limited' },
  { Org_Name: 'Tertiary Education Commission (TEC) Limited' },
  { Org_Name: 'Ashburton District Council' },
  { Org_Name: 'Central Otago District Council' },
  { Org_Name: 'Gisborne District Council' },
  { Org_Name: 'Hamilton City Council' },
  { Org_Name: 'Horowhenua District Council' },
  { Org_Name: 'Hurunui District Council' },
  { Org_Name: 'Kapiti Coast District Council' },
  { Org_Name: 'Manawatu District Council' },
  { Org_Name: 'Marlborough District Council' },
  { Org_Name: 'Masterton District Council' },
  { Org_Name: 'Selwyn District Council' },
  { Org_Name: 'South Taranaki District Council' },
  { Org_Name: 'Southland District Council' },
  { Org_Name: 'Tasman District Council' },
  { Org_Name: 'Timaru District Council' },
  { Org_Name: 'Upper Hutt City Council' },
  { Org_Name: 'Waimakariri District Council' },
  { Org_Name: 'Waitaki District Council' },
  { Org_Name: 'Western Bay of Plenty District Council' },
  { Org_Name: 'Whakatane District Council' },
  { Org_Name: 'Whanganui District Council' },
  { Org_Name: 'Nelson City Council' },
  { Org_Name: 'Bay of Plenty Regional Council' },
  { Org_Name: 'Environment Canterbury Regional Council' },
  { Org_Name: 'Environment Southland Regional Council' },
  { Org_Name: 'Greater Wellington Regional Council' },
  { Org_Name: "Hawke's Bay Regional Council" },
  { Org_Name: 'Horizons Regional Council' },
  { Org_Name: 'Manawatu-Wanganui Regional Council' },
  { Org_Name: 'Northland Regional Council' },
  { Org_Name: 'Otago Regional Council' },
  { Org_Name: 'Southland Regional Council' },
  { Org_Name: 'Taranaki Regional Council' },
  { Org_Name: 'Waikato Regional Council' },
  { Org_Name: 'Wellington Regional Council' },
  { Org_Name: 'West Coast Regional Council' },
  { Org_Name: 'Canterbury Regional Council' },
  { Org_Name: 'Ettrick Timberlands Limited' },
  { Org_Name: 'Beef + Lamb New Zealand Limited' },
  { Org_Name: 'Bodco Limited' },
  { Org_Name: 'Dairy Goat Co-Operative (N.Z.) Limited' },
  { Org_Name: 'Lewis Road Creamery Limited' },
  { Org_Name: 'Miraka Limited' },
  { Org_Name: 'Oceania Dairy Group Limited' },
  { Org_Name: 'Tatua Consulting Services Limited' },
  { Org_Name: 'Westland Milk Products Investments Limited' },
  { Org_Name: 'Lumberlink Limited' },
  { Org_Name: 'Niagara (Nz) Limited' },
  { Org_Name: 'SRS International Trade Co. Nz Limited' },
  { Org_Name: 'Eastpack Satara Limited' },
  { Org_Name: 'Freshmax Exports Limited' },
  { Org_Name: 'Yummy Fruits Limited' },
  { Org_Name: 'Fresh Meats Nz Limited' },
  { Org_Name: 'Greenlea Trustees Limited' },
  { Org_Name: 'Ovation Tyres New Zealand Limited' },
  { Org_Name: 'Prime Range Meats Limited' },
  { Org_Name: 'Agresearch (PPGR Consortia) Limited' },
  { Org_Name: 'Callaghan Innovation Research Limited' },
  { Org_Name: 'The New Zealand Institute For Plant And Food Research Limited' },
  { Org_Name: 'Ara Institute Of Canterbury Limited' },
  { Org_Name: 'Otago Polytechnic Limited' },
  { Org_Name: 'Primary ITO Limited' },
  { Org_Name: 'Southern Institute of Technology (SIT) Limited' },
  { Org_Name: 'The Open Polytechnic Of New Zealand Limited' },
  { Org_Name: 'Toi Ohomai Institute Of Technology Limited' },
  { Org_Name: 'Universal College of Learning (UCOL) Limited' },
  { Org_Name: 'Western Institute of Technology at Taranaki (WITT) Limited' },
  { Org_Name: 'Baker Tilly Staples Rodway Hawkes Bay Limited' },
  { Org_Name: 'Baker Tilly Staples Rodway Waikato Limited' },
  { Org_Name: 'Baker Tilly Staples Rodway Wellington Limited' },
  { Org_Name: 'ASB Bank Limited' },
  { Org_Name: 'Heartland Bank Limited' },
  { Org_Name: 'Rabobank New Zealand Limited' },
  { Org_Name: 'Westpac NZ Funding Limited' },
  { Org_Name: 'Livestock Improvement Corporation (LIC) Limited' },
  { Org_Name: 'Ballance Limited' },
  { Org_Name: 'Farm Source Limited' },
  { Org_Name: 'PGG Wrightson Investments Limited' },
  { Org_Name: 'ITMs Limited' },
  { Org_Name: 'Eagle Technology Europe Limited' },
  { Org_Name: 'Dunedin City Council' },
  { Org_Name: 'Hastings District Council' },
  { Org_Name: 'Hutt City Council' },
  { Org_Name: 'Invercargill City Council' },
  { Org_Name: 'Napier City Council' },
  { Org_Name: 'New Plymouth District Council' },
  { Org_Name: 'Palmerston North City Council' },
  { Org_Name: 'Porirua City Council' },
  { Org_Name: 'Queenstown-Lakes District Council' },
  { Org_Name: 'Rotorua District Council' },
  { Org_Name: 'Taupo District Council' },
  { Org_Name: 'Tauranga City Council' },
  { Org_Name: 'Ballance Limited' },
  { Org_Name: 'WSP Limited' },
  { Org_Name: 'Ayrshire New Zealand Limited' },
  { Org_Name: 'DairyNZ Limited' },
  { Org_Name: 'Hereford Associates Limited' },
  { Org_Name: 'Open Country Dairy Management Limited' },
  { Org_Name: 'Synlait Limited' },
  { Org_Name: 'McAlpines Timber Limited' },
  { Org_Name: 'Seeka Limited' },
  { Org_Name: 'Zespri International Trading Limited' },
  { Org_Name: 'Affco France Limited' },
  { Org_Name: 'Blue Sky Meats (N.Z.) Limited' },
  { Org_Name: 'BX Foods Limited' },
  { Org_Name: 'Greenlea Premier Meats Marketing Limited' },
  { Org_Name: 'Silver Fern Farms Holdings Limited' },
  { Org_Name: 'Taylor Preston Limited' },
  { Org_Name: 'Independent Fisheries Atlantic Limited' },
  { Org_Name: 'Sanford Limited' },
  { Org_Name: 'Sealord Group Limited' },
  { Org_Name: "Talley's Group Limited" },
  { Org_Name: 'National Institute of Water and Atmospheric Research (NIWA) Limited' },
  { Org_Name: 'Lincoln Univeristy' },
  { Org_Name: 'Massey University' },
  { Org_Name: 'Otago University' },
  { Org_Name: 'University of Waikato' },
  { Org_Name: 'University of Canterbury' },
  { Org_Name: 'Barr Burgess & Stewart Limited' },
  { Org_Name: 'ANZ Limited' },
  { Org_Name: 'BNZ Services Limited' },
  { Org_Name: 'Gallagher Group Limited' },
  { Org_Name: 'Taylor Farmlands Limited' },
  { Org_Name: 'Christchurch City Council' },
  { Org_Name: 'Wellington City Council' },
  { Org_Name: 'Auckland Council' },
  { Org_Name: 'Fisheries Inshore New Zealand Limited' },
  { Org_Name: 'Fonterra TM Limited' },
  { Org_Name: 'Yashili New Zealand Dairy Co., Limited' },
  { Org_Name: 'Mr Apple (Hawkes Bay) Limited' },
  { Org_Name: 'Alliance Group Asia Limited' },
  { Org_Name: 'ANZCO Foods Waitara Limited' },
  { Org_Name: 'BDO Limited' },
  { Org_Name: 'Cuffs Limited' },
  { Org_Name: 'Sidekick CA Limited' },
  { Org_Name: 'Sidekick Tasman Limited' },
  { Org_Name: 'Eastwood Bourke Limited' },
  { Org_Name: 'A1 Evolution Pty Ltd' },
  { Org_Name: 'Adria Crop Protection Limited' },
  { Org_Name: 'Arandee Limited' },
  { Org_Name: 'ASCC Limited' },
  { Org_Name: 'ATS Silviculture Limited' },
  { Org_Name: 'BASF New Zealand Limited' },
  { Org_Name: 'Bio-Start Limited' },
  { Org_Name: 'Brenntag New Zealand Limited' },
  { Org_Name: 'Chemie-Tech Limited' },
  { Org_Name: 'Clark Products Limited' },
  { Org_Name: 'Commodities NZ (2013) Limited' },
  { Org_Name: 'Consolidated Chemicals (NZ) Limited' },
  { Org_Name: 'Ecogreen Carpets Limited' },
  { Org_Name: 'Envirochem NZ Limited' },
  { Org_Name: 'Envirolyte NZ Limited' },
  { Org_Name: 'Fertilizer New Zealand Limited' },
  { Org_Name: 'Finnz Chartered Accountants Limited' },
  { Org_Name: 'Flavell & Chamberlain Limited' },
  { Org_Name: 'Fluker Denton Investments Limited' },
  { Org_Name: 'Glenn Hawkins & Associates Limited' },
  { Org_Name: 'Grant Thornton Audit Partnership Auckland Limited' },
  { Org_Name: 'Graymont (NZ) Limited' },
  { Org_Name: 'Ham Chem Hamilton Chemicals Limited' },
  { Org_Name: 'IMCD New Zealand Limited' },
  { Org_Name: 'Ingredients Plus (NZ) Limited' },
  { Org_Name: 'Interchem Agencies Limited' },
  { Org_Name: 'IXOM Finance New Zealand Limited' },
  { Org_Name: 'Jagbe Industrial Supplies Limited' },
  { Org_Name: 'Lonza NZ Limited' },
  { Org_Name: 'NANOARC Limited' },
  { Org_Name: 'New Zealand Humates Limited' },
  { Org_Name: 'New Zealand Seaweeds Limited' },
  { Org_Name: 'Nufarm - NZ Limited' },
  { Org_Name: 'Omya New Zealand Limited' },
  { Org_Name: 'Orion Agriscience Limited' },
  { Org_Name: 'Petroleum Logistics Limited' },
  { Org_Name: 'Recochem Inc' },
  { Org_Name: 'Redox Pty Ltd' },
  { Org_Name: 'Southstar Fertilizers Limited' },
  { Org_Name: 'Sulkem Company Limited' },
  { Org_Name: 'Syngenta New Zealand Limited' },
  { Org_Name: 'Syntol New Zealand Limited' },
  { Org_Name: 'T.M.K. Packers Limited' },
  { Org_Name: 'TCL Hunt Limited' },
  { Org_Name: 'TimTech Chemicals Limited' },
  { Org_Name: 'William Cox Minerals Limited' },
  { Org_Name: 'Hammond Davidson Limited' },
  { Org_Name: 'Harvie Green Wyatt Limited' },
  { Org_Name: 'HC Partners LP Limited' },
  { Org_Name: 'Anexa Veterinary services Limited' },
  { Org_Name: 'RVC vet centre Limited' },
  { Org_Name: 'Wormwise Limited' },
  { Org_Name: 'Agribio Limited' },
  { Org_Name: 'Commercial BASE Enterprises Limited' },
  { Org_Name: 'Deerpro Limited' },
  { Org_Name: 'FIL Limited' },
  { Org_Name: 'Hunterville Veterinary Club Inc Limited' },
  { Org_Name: 'MSD Animal Health Limited' },
  { Org_Name: 'Tikana Wapiti Stud Limited' },
  { Org_Name: 'Virbac New Zealand Limited' },
  { Org_Name: 'Zoetis New Zealand Limited' },
  { Org_Name: 'Kiwibank Limited' },
  { Org_Name: 'SBS Bank Limited' },
  { Org_Name: 'The Co-Operative Bank Limited' },
  { Org_Name: 'Bee Smart Breeding Limited' },
  { Org_Name: 'Avalon Genetics Limited' },
  { Org_Name: 'Simmental NZ Limited' },
  { Org_Name: 'TradeDeer.co.nz Limited' },
  { Org_Name: 'XCELL Breeding and Veterinary Services Limited' },
  { Org_Name: 'Dairy Green Limited' },
  { Org_Name: 'Future Build Construction Limited' },
  { Org_Name: 'Lattey Group Limited' },
  { Org_Name: 'Wade Contractors Limited' },
  { Org_Name: 'A Class Contractors Limited' },
  { Org_Name: 'A R Evans Limited' },
  { Org_Name: 'A W & T M Clarke Partnership Limited' },
  { Org_Name: 'A Weir Contracting Limited' },
  { Org_Name: 'A.T. Cook Contracting Limited' },
  { Org_Name: 'Aaron Gray Contracting Limited' },
  { Org_Name: 'AB Contracting & Fencing, Limited' },
  { Org_Name: 'Abernethy Contracting 2008 Limited' },
  { Org_Name: 'Ace Orchard Contracting Limited' },
  { Org_Name: 'Addie Contracting Limited' },
  { Org_Name: 'Advanced Earthmovers Limited' },
  { Org_Name: 'Aercore Services Limited' },
  { Org_Name: 'Ag Worx Contracting Limited' },
  { Org_Name: 'Agman Spraying Taranaki Limited' },
  { Org_Name: 'Agricultural Contracting Otago Limited' },
  { Org_Name: 'AJ Wilson Contracting Limited' },
  { Org_Name: 'Alex Reid Contracting Limited' },
  { Org_Name: 'Alexander Ag Limited' },
  { Org_Name: 'Alexander Spraying Limited' },
  { Org_Name: 'Allan Wilson Contracting Limited' },
  { Org_Name: "Allen's Weed & Pest Limited" },
  { Org_Name: 'Amuri Spraying Limited' },
  { Org_Name: 'Andrew Quigley Contracting Limited' },
  { Org_Name: 'Andrew Swann Contracting Limited' },
  { Org_Name: 'Angela Campbell Contracting Limited' },
  { Org_Name: 'Appchem Groundspray Limited' },
  { Org_Name: 'Aranui Harvesters Limited' },
  { Org_Name: 'Armstrong Contracting Limited' },
  { Org_Name: 'Arnott Agriculture Limited' },
  { Org_Name: 'Aros Contracting Limited' },
  { Org_Name: 'Aspinall Contracting Limited' },
  { Org_Name: 'Atiamuri Earthworks Limited' },
  { Org_Name: 'Awamangu Contracting Limited' },
  { Org_Name: 'B & H Agriculture Limited' },
  { Org_Name: 'B & K Mehrtens Agricultural Spraying Limited' },
  { Org_Name: 'B A Murray Limited' },
  { Org_Name: 'B G Contracting Limited' },
  { Org_Name: 'B H & K Whitmore Contracting Limited' },
  { Org_Name: 'B O P Weed Control Limited' },
  { Org_Name: 'B T Agriculture Limited' },
  { Org_Name: 'Balepro Limited' },
  { Org_Name: 'Ballagh Contracting Limited' },
  { Org_Name: 'Ballakara Contracting Limited' },
  { Org_Name: 'Barker Contracting Limited' },
  { Org_Name: 'Bassett Contracting Hb Limited' },
  { Org_Name: 'Bay Spray Limited' },
  { Org_Name: 'Bayliss Bros Limited' },
  { Org_Name: 'Bede Ryan Contracting Limited' },
  { Org_Name: 'Belton Contracting Limited' },
  { Org_Name: 'Ben Scott Contracting Limited' },
  { Org_Name: 'Bevan Hussey Spraying Limited' },
  { Org_Name: 'Bleeker Ag Services Limited' },
  { Org_Name: 'Bluff Country Contractors Limited' },
  { Org_Name: 'Booth Ag (2008) Limited' },
  { Org_Name: 'Bradfield Associates Limited' },
  { Org_Name: 'Bradfield Farm Limited' },
  { Org_Name: 'Brake Contracting Limited' },
  { Org_Name: 'Brendon Shearing Agricultural Contractors Limited' },
  { Org_Name: 'Broadgate Harvesting Limited' },
  { Org_Name: 'Brosnahan Agricultural Spraying Limited' },
  { Org_Name: 'Bruce Gordon Contracting Limited' },
  { Org_Name: 'BT Spraying Limited' },
  { Org_Name: 'Bullmore Contracting Limited' },
  { Org_Name: 'C & T Contracting Limited' },
  { Org_Name: 'C J Phillips Contracting Limited' },
  { Org_Name: 'Caddy Agricultural Contracting Limited' },
  { Org_Name: 'Caldwell Contracting Limited' },
  { Org_Name: 'Call A Farmer Limited' },
  { Org_Name: 'Cambrilea Weedspraying Services Limited' },
  { Org_Name: 'Cameron Agriculture Limited' },
  { Org_Name: 'Campbell & Green Contracting Limited' },
  { Org_Name: 'Campbell Agriculture Limited' },
  { Org_Name: 'Cargill Contracting Limited' },
  { Org_Name: 'Carmichael Contracting Limited' },
  { Org_Name: 'Carrfields Contracting Limited' },
  { Org_Name: 'Castlerock Contracting Limited' },
  { Org_Name: 'Caulfield Contracting Limited' },
  { Org_Name: 'Cave Spraying Contracting Limited' },
  { Org_Name: 'Central Contracting (Manawatu) Limited' },
  { Org_Name: 'Central Feed Mills Limited' },
  { Org_Name: 'Central Rural Services 2020 Limited' },
  { Org_Name: 'Central Spraying Services Limited' },
  { Org_Name: 'Chamberlain Agriculture Limited' },
  { Org_Name: 'Chapman Agriculture Limited' },
  { Org_Name: 'Chatfield Contracting (Waitaki) Limited' },
  { Org_Name: 'Chertsey Spraying Limited' },
  { Org_Name: 'Chris Woods Contracting Limited' },
  { Org_Name: 'Clark & Son Limited' },
  { Org_Name: 'Cleland Contracting Limited' },
  { Org_Name: 'CMC Contracting Limited' },
  { Org_Name: 'Coastal Contracting 2012 Limited' },
  { Org_Name: 'Coastwide Forage Solutions Limited' },
  { Org_Name: 'Codd Contractors Limited' },
  { Org_Name: 'Collins Spraying Limited' },
  { Org_Name: 'Colton Bros Limited' },
  { Org_Name: 'Comins Contracting Limited' },
  { Org_Name: 'Conan Rowlands Contracting Limited' },
  { Org_Name: 'Conasg Contracting & Engineering Limited' },
  { Org_Name: 'Coogan Contracting/Pukepuna Farm Limited' },
  { Org_Name: 'Copsey Contracting Limited' },
  { Org_Name: 'Coveney Contracting Limited' },
  { Org_Name: 'Cresslands Contracting Limited' },
  { Org_Name: 'Crispin Contracting Limited' },
  { Org_Name: 'Cropcare Spraying and Planting Limited' },
  { Org_Name: 'CT Woodward Contracting Limited' },
  { Org_Name: 'D B & S M Davies Partnership T/A Dirtywork Limited' },
  { Org_Name: 'D G & R Brown Limited' },
  { Org_Name: 'D L Atkins Agricultural Limited' },
  { Org_Name: 'D Thompson Contracting Limited' },
  { Org_Name: 'D W & L J, D G & S D Ladley Limited' },
  { Org_Name: 'Dargaville Grains And Stock Food Limited' },
  { Org_Name: 'Dassler Ag Limited' },
  { Org_Name: 'Dave & Phil Bigbale Contracting Limited' },
  { Org_Name: 'Dave Cooney Contracting Limited' },
  { Org_Name: 'Dave Wilson Contracting Limited' },
  { Org_Name: 'David Bevege Contracting Limited' },
  { Org_Name: "David O'Neill Contracting Limited" },
  { Org_Name: 'David Wordsworth Limited' },
  { Org_Name: 'Davis Contracting Levin Limited' },
  { Org_Name: 'Deep Creek Trust Limited' },
  { Org_Name: 'Deep Stream Contracting Limited' },
  { Org_Name: 'Denis McCarthy Contracting Limited' },
  { Org_Name: 'Dennis McLaren Contracting Limited' },
  { Org_Name: 'Dickson Ag Limited' },
  { Org_Name: 'Direct Drills For Hire Limited' },
  { Org_Name: 'Disher Contracting Limited' },
  { Org_Name: 'Donald Love Contracting & Plains Contracting Limited' },
  { Org_Name: 'Donegal Contracting Limited' },
  { Org_Name: 'Doug Horrell Contracting Limited' },
  { Org_Name: 'Douglas Farm Contracting Limited' },
  { Org_Name: 'Douglas Giles No Tillage Limited' },
  { Org_Name: 'Drummond Contracting 2009 Limited' },
  { Org_Name: 'Duncan Agriculural Contractors Limited' },
  { Org_Name: 'E F & E Cullen Contracting Partnership Limited' },
  { Org_Name: 'E W Sinton Limited' },
  { Org_Name: 'Eames Ag Contracting Limited' },
  { Org_Name: 'East Otago Contracting Limited' },
  { Org_Name: 'Eastern Bay Sprayers Limited' },
  { Org_Name: 'Eaton Agriculture Limited' },
  { Org_Name: 'Eaton Willow Lodge Limited' },
  { Org_Name: 'Eddie Hill Contracting Limited' },
  { Org_Name: 'Ellesmere Agricultural Limited' },
  { Org_Name: 'Ellesmere Chemical Applicators 2018 Limited' },
  { Org_Name: 'Elsworth Ag Contractors Limited' },
  { Org_Name: 'Everitt Contracting Limited' },
  { Org_Name: 'Fairhall Agricultural Services Co Limited' },
  { Org_Name: 'Fairwood Contracting Limited' },
  { Org_Name: 'Farm Chemical Supplies Limited' },
  { Org_Name: 'Farmers Dipping Co Southland Limited' },
  { Org_Name: 'Fensin (N A & D P Roberston trading as) Limited' },
  { Org_Name: 'Ferrydale Agricultural Limited' },
  { Org_Name: 'Field Performance Limited' },
  { Org_Name: 'Finch Contracting Limited' },
  { Org_Name: 'Finer Spraying Services Limited' },
  { Org_Name: 'Flash Agri Limited' },
  { Org_Name: 'Fleming Bros Agricultural Contractors Limited' },
  { Org_Name: 'Fletcher Farms Limited' },
  { Org_Name: 'Flett Contracting Limited' },
  { Org_Name: 'Flintoft Contractors Limited' },
  { Org_Name: 'Fogarty Chemical Applicators Limited' },
  { Org_Name: 'Foley Agrisystems Limited' },
  { Org_Name: 'Fowle Contracting Limited' },
  { Org_Name: 'Frank Simmons Contracting Limited' },
  { Org_Name: 'G & H Agri Limited' },
  { Org_Name: 'G B Davison Contracting Limited' },
  { Org_Name: 'G J Graham Contracting Limited' },
  { Org_Name: 'G M Davis Spraying Contracting Limited' },
  { Org_Name: 'G T Agwork Limited' },
  { Org_Name: 'Gareth Dalley Contracting Limited' },
  { Org_Name: 'Gaskell Contracting Limited' },
  { Org_Name: 'Gavins Limited' },
  { Org_Name: 'Geoff Kidd Limited' },
  { Org_Name: 'Geraldine Spraying Limited' },
  { Org_Name: 'Gilchrist Bros Builders Limited' },
  { Org_Name: 'Glassey Contracting Limited' },
  { Org_Name: 'Glover Contracting Limited' },
  { Org_Name: 'Goodin Ag Limited' },
  { Org_Name: 'Goodwin Earthworks Limited' },
  { Org_Name: 'Gopperth Contracting Limited' },
  { Org_Name: 'Graeme Bagrie Contracts Limited' },
  { Org_Name: 'Graeme Fairless Agriculture Limited' },
  { Org_Name: 'Grant Davis Farms Limited' },
  { Org_Name: 'Green To Gold Limited' },
  { Org_Name: 'Greenline Ag Limited' },
  { Org_Name: 'Greer Ground Spraying Limited' },
  { Org_Name: 'Greg Clarke Contracting Limited' },
  { Org_Name: 'Greg Sowry Contracting Limited' },
  { Org_Name: 'Groubeagriworx Limited' },
  { Org_Name: 'Grounds & Services Properties Limited' },
  { Org_Name: 'H. G. Leach & Company Limited' },
  { Org_Name: 'H. Mackenzie Contracting Limited' },
  { Org_Name: 'Hall & Wilson Limited' },
  { Org_Name: 'Hamish Calder Contracting Limited' },
  { Org_Name: 'Hamish George Contracting Limited' },
  { Org_Name: 'Hamish Lott Contracting Limited' },
  { Org_Name: 'Hardegger Ag Limited' },
  { Org_Name: 'Harliwich Contracting Limited' },
  { Org_Name: 'Harris Harvesting Limited' },
  { Org_Name: 'Harrison Spraying Services Limited' },
  { Org_Name: 'Hartland Agricultural Limited' },
  { Org_Name: 'Hartnell Services Limited' },
  { Org_Name: 'Harvest Haulage Limited' },
  { Org_Name: 'Hauraki Agri Limited' },
  { Org_Name: 'Hauwai Farms Limited' },
  { Org_Name: 'Hawea Contracting . Limited' },
  { Org_Name: 'Hawkes Bay Harvesting Limited' },
  { Org_Name: 'Hawkins River Contracting 2014 Limited' },
  { Org_Name: 'Hay Ruler Feed Supplies 2015 Limited' },
  { Org_Name: 'Hayes Spraying Services Limited' },
  { Org_Name: 'Hemming Group Limited' },
  { Org_Name: 'Henty Agriculture Limited' },
  { Org_Name: 'Henwood Ag Limited' },
  { Org_Name: 'Hermansen Contracting Limited' },
  { Org_Name: 'Hickisey Trust Limited' },
  { Org_Name: 'Hide Spraying Limited' },
  { Org_Name: 'Higgins Contracting Nelson Limited' },
  { Org_Name: 'Hilltop Spraying Limited' },
  { Org_Name: 'Himatangi Service Station (1986) Limited' },
  { Org_Name: 'Hinton Contracting Limited' },
  { Org_Name: 'Holdem Contracting Limited' },
  { Org_Name: 'Holdt Harvesting Limited' },
  { Org_Name: 'Holmes & Chittick Contracting Limited' },
  { Org_Name: 'Hopkins Farming Group Limited' },
  { Org_Name: 'Hughes Contracting (2002) Limited' },
  { Org_Name: 'Hyde Bros. Spraying Limited' },
  { Org_Name: 'Ian Mcdonald Contracting Limited' },
  { Org_Name: 'Ingram Engineering Limited' },
  { Org_Name: 'Ireland Contracting Limited' },
  { Org_Name: 'J & B Murdoch Contracting Limited' },
  { Org_Name: 'J & H Contractors Limited' },
  { Org_Name: 'J & N Berkett Limited' },
  { Org_Name: 'J C & D M McCallum Limited' },
  { Org_Name: 'J C B Contracting Limited' },
  { Org_Name: 'J F M Contracting Limited' },
  { Org_Name: 'J P Morgan Fencing Limited' },
  { Org_Name: 'J R Lyders Contracting Limited' },
  { Org_Name: 'J.P. Sinclair Contracting Limited' },
  { Org_Name: 'Jack Sands Contracting Limited' },
  { Org_Name: 'Jackson Ag Contracting Limited' },
  { Org_Name: 'Jackson Contracting (2005) Limited' },
  { Org_Name: 'Jackson Holmes Contracting Limited' },
  { Org_Name: 'Jacob Holdaway Contracting Limited' },
  { Org_Name: 'Jake Clark Contracting Limited' },
  { Org_Name: 'Jason Johnston Contracting Limited' },
  { Org_Name: 'JFM Contracting Limited' },
  { Org_Name: 'JJ Morris Contracting Limited' },
  { Org_Name: 'Joe & Tash Coogan Contracting Limited' },
  { Org_Name: 'Joe Herbert Contracting Limited' },
  { Org_Name: 'John Austin Limited' },
  { Org_Name: 'John Clark Contracting Limited' },
  { Org_Name: 'John Murdoch Holdings Limited' },
  { Org_Name: 'Joyclas Farms Limited' },
  { Org_Name: 'K & R Wade Limited' },
  { Org_Name: 'K P Contracting Limited' },
  { Org_Name: 'K.E. Carston Contractors Limited' },
  { Org_Name: 'Kaipaki Contracting Limited' },
  { Org_Name: 'Kaipara Farm Supplies Limited' },
  { Org_Name: 'Kalin Contracting Limited' },
  { Org_Name: 'Kaweka Contracting Limited' },
  { Org_Name: 'Kay Kay Contracts 2013 Limited' },
  { Org_Name: 'Kelso Kontracting Company Limited' },
  { Org_Name: 'Ken Deighton Contracting Trust Limited' },
  { Org_Name: 'Kinsman Contracting Limited' },
  { Org_Name: 'Kiwi Bale Limited' },
  { Org_Name: 'Klaus Ag Contracting Limited' },
  { Org_Name: 'Koi-Ag Limited' },
  { Org_Name: 'Krutz & Associates Limited' },
  { Org_Name: 'Kururau Contracting Limited' },
  { Org_Name: 'Kyle Davidson Contracting Limited' },
  { Org_Name: 'Kyle Selman Limited' },
  { Org_Name: 'L J Contracting (NZ) Limited' },
  { Org_Name: 'Laidlaw Contracting Limited' },
  { Org_Name: 'Lake Hawea Contracting Limited' },
  { Org_Name: 'Lancaster Brothers Contractors Limited' },
  { Org_Name: 'Lance Keay Contracting Limited' },
  { Org_Name: 'Landcorp Central Farm Services Limited' },
  { Org_Name: 'Langford Contracting (2012) Limited' },
  { Org_Name: 'Langlands Contracting Limited' },
  { Org_Name: 'Langman Agricultural Contracting Limited' },
  { Org_Name: 'Langsford N & G Contracting Limited' },
  { Org_Name: 'Laurie Cocker Contracting Limited' },
  { Org_Name: 'Lawrence Agriculture Limited' },
  { Org_Name: "Leicester's NZ T/A Leicester's Soil Fumigation Limited" },
  { Org_Name: 'Leslie Bros Contracting Limited' },
  { Org_Name: 'Ludemann Sheep Showers Limited' },
  { Org_Name: 'M A Bruce Limited' },
  { Org_Name: 'M Gardyne Limited' },
  { Org_Name: 'M J & S E Dillon Limited' },
  { Org_Name: 'M R Andrews Limited' },
  { Org_Name: 'M R Rouse Limited' },
  { Org_Name: 'Mabey Contracting Limited' },
  { Org_Name: 'Mackay Contracting Services Limited' },
  { Org_Name: 'Malvern Ag Spraying Limited' },
  { Org_Name: 'Manaroa Contracting Co Limited' },
  { Org_Name: 'Maniototo Contracting (2004) Limited' },
  { Org_Name: 'Mark Carey Contracting Limited' },
  { Org_Name: 'Marlborough No Tillage Limited' },
  { Org_Name: 'Marsh Contracting Limited' },
  { Org_Name: 'Matt Lovett Limited' },
  { Org_Name: 'Maungatapere Transport Limited' },
  { Org_Name: 'Maxwell Agspray Limited' },
  { Org_Name: 'May Brothers Contracting Limited' },
  { Org_Name: 'MB Agriculture Limited' },
  { Org_Name: 'McCall Contracting Limited' },
  { Org_Name: 'McLenaghan Contracting Limited' },
  { Org_Name: 'McLennan Agrispray Limited' },
  { Org_Name: 'McStay Contracting Limited' },
  { Org_Name: 'Methven Contracting Limited' },
  { Org_Name: 'Meyer Contracting 2007 Limited' },
  { Org_Name: 'Michael Wills Contracting Limited' },
  { Org_Name: 'Mid Canterbury Ag Limited' },
  { Org_Name: 'Middle Hills Contracts Limited' },
  { Org_Name: 'Midlands Contracting (Ashburton) Limited' },
  { Org_Name: 'Miers Contractors (1995) Limited' },
  { Org_Name: 'Mike Dynes Contracting Limited' },
  { Org_Name: 'Mike Kettle Contracting Limited' },
  { Org_Name: 'Mike Winder Groundspraying Limited' },
  { Org_Name: 'Miller And Harris Silage Limited' },
  { Org_Name: 'Molloy Agriculture Limited' },
  { Org_Name: 'Moratti Contracting Limited' },
  { Org_Name: 'Mulch And Mow Limited' },
  { Org_Name: 'Multi Ag Limited' },
  { Org_Name: 'Murdoch Agricultural Contracting Limited' },
  { Org_Name: 'Murray Free Contracting Limited' },
  { Org_Name: 'Murray Harvest Investments Limited' },
  { Org_Name: 'N M & T W Roy Contracting Limited' },
  { Org_Name: 'Nagle Contracting Limited' },
  { Org_Name: 'Nightcaps Contracting Limited' },
  { Org_Name: 'North Harbour Weed Control 2009 Limited' },
  { Org_Name: 'North Otago Ditching & Baling Limited' },
  { Org_Name: 'Northland Vegetation Control 2012 Limited' },
  { Org_Name: "O'Connor Agri Services Limited" },
  { Org_Name: 'Olsen Rural Contractors Limited' },
  { Org_Name: 'Oregon Agriculture Limited' },
  { Org_Name: 'Otapiri Ag Limited' },
  { Org_Name: 'Outram Contracting Limited' },
  { Org_Name: 'Oxford Agricultural Services Limited' },
  { Org_Name: 'P B & G M McPherson Limited' },
  { Org_Name: 'P D L Farms Limited' },
  { Org_Name: "P G O'Rorke Family Trust Limited" },
  { Org_Name: 'P J Brogden Contracting Limited' },
  { Org_Name: 'P W Wright Contracting Limited' },
  { Org_Name: 'Paddison Baling Limited' },
  { Org_Name: 'Paeroa Contracting Limited' },
  { Org_Name: 'Pakohe Agriculture Limited' },
  { Org_Name: 'Pankhurst Contracting Limited' },
  { Org_Name: 'Paul Gee Contractors Limited' },
  { Org_Name: 'Paul Jensen Construction Limited' },
  { Org_Name: 'Paul Sanders Contracting Limited' },
  { Org_Name: 'Pearce Ag Contracting Limited' },
  { Org_Name: 'Pedley Contracting Limited' },
  { Org_Name: 'Pedro McHardy Contracting Limited' },
  { Org_Name: 'Peko Contracting (2010) Limited' },
  { Org_Name: 'Pentwyn Farms Limited' },
  { Org_Name: 'Perkins Contracting And Mowing Limited' },
  { Org_Name: 'Peter Corcoran Contracting Limited' },
  { Org_Name: 'Phil Hawke Contracting Limited' },
  { Org_Name: 'Philip Parry Contracting Limited' },
  { Org_Name: 'Piako Ag Spraying Limited' },
  { Org_Name: 'Pig Burn Gorge Agricultural Contracting Limited' },
  { Org_Name: 'Pilet Contracting Limited' },
  { Org_Name: 'Pineline Deer Contracting Limited' },
  { Org_Name: 'Plateau Sprayers Limited' },
  { Org_Name: 'Pollock Spraying Services Limited' },
  { Org_Name: 'Porter Fields Limited' },
  { Org_Name: 'Powdrell Services Limited' },
  { Org_Name: 'Precision Slurry Limited' },
  { Org_Name: 'Precision Works (2004) Limited' },
  { Org_Name: 'Pritchard Agricultural Contracting Services Limited' },
  { Org_Name: 'Progressive Plant Solutions Limited' },
  { Org_Name: 'Prosser Contracting Limited' },
  { Org_Name: 'Pukekawa Contractors Limited' },
  { Org_Name: 'Puniho Engineering Limited' },
  { Org_Name: 'Pye Group Limited' },
  { Org_Name: 'Quality Feeds & Contracting Limited' },
  { Org_Name: 'R & B Litchfield Limited' },
  { Org_Name: 'R & L Drainage Limited' },
  { Org_Name: 'R & S Gray Limited' },
  { Org_Name: 'R B & S A Carey Limited' },
  { Org_Name: 'R K Flint Contracting Limited' },
  { Org_Name: 'R. Jack Contracting Limited' },
  { Org_Name: 'Rabco Ag Limited' },
  { Org_Name: 'Raincliff Agriculture Limited' },
  { Org_Name: 'Ramsay Earthmoving Limited' },
  { Org_Name: 'Randle Contracting Limited' },
  { Org_Name: 'Rata Hill Drainage Limited' },
  { Org_Name: 'Ray Lowe Contracting Limited' },
  { Org_Name: 'Raymond Hicks Contracting Limited' },
  { Org_Name: 'RD3 Contracting Limited' },
  { Org_Name: 'Regence Marketing Co Limited' },
  { Org_Name: 'Reymer Ag Contracting Limited' },
  { Org_Name: 'Richard Simpson Contracting Limited' },
  { Org_Name: 'Richmond Contracting Te Horo Limited' },
  { Org_Name: 'Riverlea Contractors Limited' },
  { Org_Name: 'RMS Contracting (Wellington) Limited' },
  { Org_Name: 'Rnott Contracting 2007 Limited' },
  { Org_Name: 'Robert Alison Contracting Limited' },
  { Org_Name: 'Roger Smith Contracting (1995) Limited' },
  { Org_Name: 'Rogerson Farms Limited' },
  { Org_Name: 'Rose Ag Limited' },
  { Org_Name: 'Roselea Contracting Limited' },
  { Org_Name: 'Ross Brown Contracting Limited' },
  { Org_Name: 'Rotherham Machinery Group Limited' },
  { Org_Name: 'RTB Agri & Engineering Limited' },
  { Org_Name: 'Rural Contracting Services Limited' },
  { Org_Name: 'Ryan Thomas Agricultural Contracting Limited' },
  { Org_Name: 'S & K Versatile Spray Limited' },
  { Org_Name: 'S & R Watson Contracting Limited' },
  { Org_Name: 'S A Crack Contracting Limited' },
  { Org_Name: 'S Black Contracting Limited' },
  { Org_Name: 'S D Nicholson Contracting Limited' },
  { Org_Name: 'S G & G A Levet Contracting Limited' },
  { Org_Name: 'S J Hammond Contracting Limited' },
  { Org_Name: 'Sam Forrester Contracting Limited' },
  { Org_Name: 'Sanson Agriculture Limited' },
  { Org_Name: 'Saywell Contracting Limited' },
  { Org_Name: 'Scannell Spraying Limited' },
  { Org_Name: 'Scott Contracting Limited' },
  { Org_Name: 'Scott Simpson Contracting Limited' },
  { Org_Name: "Scott's Ag Contracting Limited" },
  { Org_Name: 'Sea Breeze Apartments Limited' },
  { Org_Name: 'Searle Contracting Limited' },
  { Org_Name: 'Seaward Contractors Limited' },
  { Org_Name: 'Sheat Contracting Limited' },
  { Org_Name: 'Short Contracting T/A Gladstonw Trust (2007) Limited' },
  { Org_Name: 'Simon McKenzie Contracting Limited' },
  { Org_Name: 'Simply Baling & Ag Limited' },
  { Org_Name: 'Simply Spraying Limited' },
  { Org_Name: 'Sims Contractors (Otaki) Limited' },
  { Org_Name: 'Slattery Contracting Limited' },
  { Org_Name: 'Snowball Contracting Limited' },
  { Org_Name: 'Southern Baling Limited' },
  { Org_Name: 'Southern Cultivation Limited' },
  { Org_Name: 'Southern Precision Spraying Limited' },
  { Org_Name: 'Southern Vegetation Control (Otago) Limited' },
  { Org_Name: 'Southern Vegetation Control Limited' },
  { Org_Name: 'Spray Solutions Limited' },
  { Org_Name: 'Sprayco Forestry Limited' },
  { Org_Name: 'Sprayed-On Services Limited' },
  { Org_Name: 'Spraylink 2018 Limited' },
  { Org_Name: 'Springdale Farming Company Limited' },
  { Org_Name: 'Steve James Contracting Limited' },
  { Org_Name: 'Steve Levet Limited' },
  { Org_Name: 'Steven Shearing Contracting Limited' },
  { Org_Name: 'Strange Contracting Limited' },
  { Org_Name: 'Superior Farm Maintenance Limited' },
  { Org_Name: 'Tasman Bay Contracting Limited' },
  { Org_Name: 'Tasman Harvester Contractors Limited' },
  { Org_Name: 'Taunt Contracting Limited' },
  { Org_Name: 'Te Kauri Downs Limited' },
  { Org_Name: 'Te Pirita Enterprises Limited' },
  { Org_Name: 'Terrace Oaks Services Limited' },
  { Org_Name: 'Tetley-Jones Agriculture Limited' },
  { Org_Name: 'Teviot Contracting Limited' },
  { Org_Name: 'Three Rivers Contracting Limited' },
  { Org_Name: 'Tickle Ag Contracting Limited' },
  { Org_Name: 'Toko Spraying 2015 Limited' },
  { Org_Name: 'Tony Holden Con Limited' },
  { Org_Name: 'Tony Perriam Hedgecutting Limited' },
  { Org_Name: 'Total Ag Spraying Limited' },
  { Org_Name: 'Total Harvest Management Limited' },
  { Org_Name: 'Total Vegetation Control Limited' },
  { Org_Name: 'Town & General Vegetation Control Limited' },
  { Org_Name: 'Townshend Agricultural Contracting Limited' },
  { Org_Name: 'Trevor Newell Limited' },
  { Org_Name: 'Triflor NZ Limited' },
  { Org_Name: 'Trounce Contracting Limited' },
  { Org_Name: 'Trusty Contracting Limited' },
  { Org_Name: 'Tulloch Contracting Limited' },
  { Org_Name: 'Turley Farms Limited' },
  { Org_Name: 'Turner Fry Contracting Limited' },
  { Org_Name: 'Uni Agricultural Spraying Limited' },
  { Org_Name: 'Untill Limited' },
  { Org_Name: 'Urquhart Contracting Limited' },
  { Org_Name: 'Ussher Contracting Limited' },
  { Org_Name: 'V I & A E McBeth Limited' },
  { Org_Name: 'Von Ah Contracting Limited' },
  { Org_Name: 'Vuletich Contracting Limited' },
  { Org_Name: 'Vuletich Cotracting Limited' },
  { Org_Name: 'W & A Price Limited' },
  { Org_Name: 'W Brown Contracting Limited' },
  { Org_Name: 'W M Moore Farm Limited' },
  { Org_Name: 'Waianiwa Hedge Cutting (B F Anderson t/a) Limited' },
  { Org_Name: 'Waiau Spraying Limited' },
  { Org_Name: 'Waipounamu & Waimea Contracting Limited' },
  { Org_Name: 'Waipounamu Contracting Limited' },
  { Org_Name: 'Wairarapa Weedsprayers 1983 Limited' },
  { Org_Name: 'Wairoa Spreading Services Limited' },
  { Org_Name: 'Wairuna Agri-Services Limited' },
  { Org_Name: 'Waitaki Spraying Limited' },
  { Org_Name: 'Waitohi Ag Limited' },
  { Org_Name: 'Waituhi Contracting Limited' },
  { Org_Name: 'Waller Precision Spraying & Direct Drilling Limited' },
  { Org_Name: 'Walsh Contracting Te Anau Limited' },
  { Org_Name: 'Wanaka Agricultural Contracting (2017) Limited' },
  { Org_Name: 'Wanaka Spray Finishes Limited' },
  { Org_Name: 'Warren Smith Contracting Limited' },
  { Org_Name: 'Warwick Buchanan Contracting Limited' },
  { Org_Name: 'Watson Farm Spraying Limited' },
  { Org_Name: 'Wattle Contracting Limited' },
  { Org_Name: 'Wayne Mckenzie Agriculture Limited' },
  { Org_Name: 'Weed Control Services Limited' },
  { Org_Name: 'West Coast Ag Contractors Limited' },
  { Org_Name: 'Whitestone Contracting Limited' },
  { Org_Name: 'Wilkins Farming Co Limited' },
  { Org_Name: 'Wilson Contracting (2017) Limited' },
  { Org_Name: 'Wilton Agriculture Limited' },
  { Org_Name: 'Wolland Contracting Limited' },
  { Org_Name: 'Woodleys Contracting Limited' },
  { Org_Name: 'Wooller Contracting Limited' },
  { Org_Name: 'Worthy Ag Limited' },
  { Org_Name: 'Woudenberg Contracting Limited' },
  { Org_Name: 'Z Contracting Limited' },
  { Org_Name: 'Feed Me Milling Limited' },
  { Org_Name: 'H.R. Fisken & Sons Limited' },
  { Org_Name: 'Image Holdings Limited' },
  { Org_Name: 'MainFeeds Limited' },
  { Org_Name: 'Ngahiwi Limited' },
  { Org_Name: 'Nutritech Investments Limited' },
  { Org_Name: 'Seeds & Cereals Limited' },
  { Org_Name: 'SGT Dan Stockfoods Limited' },
  { Org_Name: 'Topflite Limited' },
  { Org_Name: 'VPMaxx Limited' },
  { Org_Name: 'Agri-Fert Limited' },
  { Org_Name: 'Agvance Marketing Limited' },
  { Org_Name: 'Bio Marinus Limited' },
  { Org_Name: 'Clear Currents Limited' },
  { Org_Name: 'Egmont Chicken Company Limited' },
  { Org_Name: 'Environmental Fertilisers 2007 Limited' },
  { Org_Name: 'Fish IT Limited' },
  { Org_Name: 'Haifa Limited' },
  { Org_Name: 'Mainfert Limited' },
  { Org_Name: 'P&S Seaweed Harvesters Limited' },
  { Org_Name: 'Pacificag Limited' },
  { Org_Name: 'Quinfert Limited' },
  { Org_Name: 'R & J Agri Spray Limited' },
  { Org_Name: 'SGS New Zealand Limited' },
  { Org_Name: 'Southern Humates Limited' },
  { Org_Name: 'Superior Home Limited' },
  { Org_Name: 'Top Soils Limited' },
  { Org_Name: 'Tow and Fert Limited' },
  { Org_Name: 'Viable Agriculture Limited' },
  { Org_Name: 'Viafos Importing Limited' },
  { Org_Name: 'Grant Contractors 2008 Limited' },
  { Org_Name: 'Buster Cover Feed Systems Limited' },
  { Org_Name: 'Dairytech South Limited' },
  { Org_Name: 'ForestQuip Australia Limited' },
  { Org_Name: 'Melasty Milking Equipment Limited' },
  { Org_Name: 'New Zealand Milking & Pumping Trade Association Limited' },
  { Org_Name: 'Pastured Poultry Equipment NZ Limited' },
  { Org_Name: 'Peak Equipment Limited' },
  { Org_Name: 'Prattley Industries Limited' },
  { Org_Name: 'Te Pari Pari Limited' },
  { Org_Name: 'Woodsman Pro Limited' },
  { Org_Name: 'Container Nurseries (1988) Limited' },
  { Org_Name: 'Growing Spectrum New Zealand Limited' },
  { Org_Name: 'Darragh Valuations Limited' },
  { Org_Name: 'Beequip Limited' },
  { Org_Name: 'Capital Beekeeping Supplies Limited' },
  { Org_Name: 'Ceemee Limited' },
  { Org_Name: 'Daltons Landscape Supplies Limited' },
  { Org_Name: 'Deosan New Zealand Limited' },
  { Org_Name: 'Ecrotek Limited' },
  { Org_Name: 'Shoof International Limited' },
  { Org_Name: 'Appletons Trading Limited' },
  { Org_Name: 'Chook Manor Limited' },
  { Org_Name: 'Feathered Friends Limited' },
  { Org_Name: 'Good To Grow NZ Limited' },
  { Org_Name: 'Harrisville Heritage Poultry Collective Limited' },
  { Org_Name: 'Sunset Croft Heritage Poultry Limited' },
  { Org_Name: 'The Village Saddlery Limited' },
  { Org_Name: "Yates' Holdings Limited" },
  { Org_Name: 'Agricom Limited' },
  { Org_Name: 'Cates Grain & Seed Limited' },
  { Org_Name: 'Cropmark Seeds Limited' },
  { Org_Name: 'Germinate Limited' },
  { Org_Name: 'Grasslanz Limited' },
  { Org_Name: 'Kings Seeds (New Zealand) Limited' },
  { Org_Name: 'Luisetti Properties Limited' },
  { Org_Name: 'McCaw Seeds Limited' },
  { Org_Name: 'Midlands Seed Farming Limited' },
  { Org_Name: 'New Zealand Agriseeds Limited' },
  { Org_Name: 'PGG Wrightson Seeds South America Holdings Limited' },
  { Org_Name: 'Seed Force Limited' },
  { Org_Name: 'BraveGen Limited' },
  { Org_Name: 'FishServe Innovations New Zealand (FINNZ) Limited' },
  { Org_Name: 'GPSit Limited' },
  { Org_Name: 'Kiwi Hort Solutions Limited' },
  { Org_Name: 'Oritain Global Limited' },
  { Org_Name: 'TracMap Aviation Limited' },
  { Org_Name: 'Trimble Forestry Limited' },
  { Org_Name: 'Tru-Test Investments Limited' },
  { Org_Name: 'Vine Alert Contracting Limited' },
  { Org_Name: 'Best Practice Beekeeping Limited' },
  { Org_Name: 'Agricultural Consulting Services (Canterbury) Limited' },
  { Org_Name: 'Deeco Services Limited' },
  { Org_Name: 'Effluent & Irrigation Design (NZ) Limited' },
  { Org_Name: 'Hydro-Services Limited' },
  { Org_Name: "Isaac's Plumbing, Pumping & Electrical Limited" },
  { Org_Name: 'Netafim Australia Pty Limited' },
  { Org_Name: 'Paramount Effluent Limited' },
  { Org_Name: 'SSS Irrigation Limited' },
  { Org_Name: 'Think Water (NZ) Pty Limited' },
  { Org_Name: 'Waikato Pump Services Limited' },
  { Org_Name: 'Water Services NZ Limited' },
  { Org_Name: 'Waterworks Irrigation Limited' },
  { Org_Name: 'Fish & Game Adventures Limited' },
  { Org_Name: 'NZ Maritime Systems Limited' },
  { Org_Name: 'Environmental Protection Authority (EPA) Limited' },
  { Org_Name: 'Game Animal Council New Zealand Limited' },
  { Org_Name: 'Land Information New Zealand (LINZ) Limited' },
  { Org_Name: 'NZ Fisheries Limited' },
  { Org_Name: 'Environmental Management Solutions Limited' },
  { Org_Name: 'Nutri Link Limited' },
  { Org_Name: 'NZ Regenerate Limited' },
  { Org_Name: 'Tipu Whenua Limited' },
  { Org_Name: 'Āta Regenerative Limited' },
  { Org_Name: 'AbacusBio Limited' },
  { Org_Name: 'AG Farm Consultants Limited' },
  { Org_Name: 'AgGesign Limited' },
  { Org_Name: 'Agribusiness Consultants & Valuers (Agribuild) Limited' },
  { Org_Name: 'AgriSpecialists NZ Limited' },
  { Org_Name: 'Bosworth Capital Limited' },
  { Org_Name: 'Dairy Farm Management Services (DFMS) Limited' },
  { Org_Name: 'Dunham Consulting Limited' },
  { Org_Name: 'Farm360 Limited' },
  { Org_Name: 'Freeman Farming Consultancy Limited' },
  { Org_Name: 'GoodMan Rural Limited' },
  { Org_Name: 'Harvest Farm Advisory Limited' },
  { Org_Name: 'Headland Group Limited' },
  { Org_Name: 'James Morrison Consulting Limited' },
  { Org_Name: 'Landmark Advisory Pty Limited' },
  { Org_Name: 'Look West Limited' },
  { Org_Name: 'MilkMap Consulting Limited' },
  { Org_Name: 'New Zealand Grazing Company Limited' },
  { Org_Name: 'NutriSense Limited' },
  { Org_Name: 'Olympia Consultants Limited' },
  { Org_Name: 'Organic Farm Systems New Zealand Limited' },
  { Org_Name: 'Paul Reidy Limited' },
  { Org_Name: 'Platfoot Consultancy Limited' },
  { Org_Name: 'Rendle Rural Limited' },
  { Org_Name: 'Rob Gollan Farm Consulting Limited' },
  { Org_Name: 'Roy Evans Limited' },
  { Org_Name: 'Rural Directions Advisory Services Limited' },
  { Org_Name: 'Soil Connection Limited' },
  { Org_Name: 'Soter Rural Compliance Limited' },
  { Org_Name: 'Southern Falcon Consultants Limited' },
  { Org_Name: 'Tambo Nz Limited' },
  { Org_Name: 'Organic Ag Limited' },
  { Org_Name: 'AgResourced Limited' },
  { Org_Name: 'Allegrow Limited' },
  { Org_Name: 'Aspiring Environmental Limited' },
  { Org_Name: 'BioAg Limited' },
  { Org_Name: 'Career Success Limited' },
  { Org_Name: 'CG AG Consulting Limited' },
  { Org_Name: 'Change for Good Consulting Limited' },
  { Org_Name: 'Chisholm Associates and Aquatic Weed Control Limited' },
  { Org_Name: 'Enviroplan Limited' },
  { Org_Name: 'GroundTruth Limited' },
  { Org_Name: 'Irricon Resource Solutions Limited' },
  { Org_Name: 'Latham Ag Limited' },
  { Org_Name: 'Natural Performance Limited' },
  { Org_Name: 'Next Level Grazing, Limited' },
  { Org_Name: 'Soil Matters Limited' },
  { Org_Name: 'Top Paddock Consulting Limited' },
  { Org_Name: 'Water Strategies Limited' },
  { Org_Name: 'Williamson Water And Land Advisory Limited' },
  { Org_Name: 'Agrilink Limited' },
  { Org_Name: 'Andrew Harrison Consulting (AHC ) Limited' },
  { Org_Name: 'JPEC Engineering Limited' },
  { Org_Name: 'Complete Feed Solutions Limited' },
  { Org_Name: 'Assured Food Safety Limited' },
  { Org_Name: 'ATC Consulting Limited' },
  { Org_Name: 'Food Chain Action Limited' },
  { Org_Name: 'Food Compliance Specialist Limited' },
  { Org_Name: 'Foodsafe 360 Limited' },
  { Org_Name: 'FSQSNZ Limited' },
  { Org_Name: 'JWC Consulting (NZ) Limited' },
  { Org_Name: 'MPG Foodtech Limited' },
  { Org_Name: 'Precise Services' },
  { Org_Name: 'Quality Auditing Specialists (QAS) Limited' },
  { Org_Name: 'Rowlly.com Limited' },
  { Org_Name: 'Safe Food Services Limited' },
  { Org_Name: 'SIS Training And Consulting Limited' },
  { Org_Name: 'Southern Food Safety Limited' },
  { Org_Name: 'Lake Rotoaira Forest Trust Limited' },
  { Org_Name: 'SGS Management Limited' },
  { Org_Name: 'No 8 HR Limited' },
  { Org_Name: 'Blueberries New Zealand Inc Limited' },
  { Org_Name: 'Deepwater Group Limited' },
  { Org_Name: 'Eel Enhancement Company Limited' },
  { Org_Name: 'Horticulture New Zealand Limited' },
  { Org_Name: 'Kiwi Mushrooms Limited' },
  { Org_Name: 'Kiwi Saffron (2017) Limited' },
  { Org_Name: 'New Zealand Growers Limited' },
  { Org_Name: 'New Zealand Lavender Growers Association Limited' },
  { Org_Name: 'New Zealand Macadamia Society Limited' },
  { Org_Name: 'NZ Deer Industry Association Limited' },
  { Org_Name: 'NZ Federation of Commercial Fishermen Limited' },
  { Org_Name: 'NZ Rock Lobster Industry Council Limited' },
  { Org_Name: 'Process Vegetables NZ (PVNZ) Limited' },
  { Org_Name: 'Red Meat Profit Partnership General Partner Limited' },
  { Org_Name: 'Taupo Beef and Lamb Limited' },
  { Org_Name: 'The Federated Farmers Meat & Wool Industry Group Limited' },
  { Org_Name: 'The Federated Farmers Rural Butchers Industry Group Limited' },
  { Org_Name: 'Agcarm Limited' },
  { Org_Name: 'American Foulbrood National Pest Management Plan (AFNPMP) Limited' },
  { Org_Name: 'Apiculture New Zealand Limited' },
  { Org_Name: 'Arable Food Industry Council (AFIC) Limited' },
  { Org_Name: 'Ashburton Fanciers Society Inc. Limited' },
  { Org_Name: 'Auckland Poultry and Pigeon Association Limited' },
  { Org_Name: "Blonde d'Aquaitaine Society of Australia and New Zealand Limited" },
  { Org_Name: 'Brown Swiss Cattle Breeders Association of New Zealand Limited' },
  { Org_Name: 'Charolais Breeders New Zealand Limited' },
  { Org_Name: 'Christchurch Hobbyist Beekeepers Club Limited' },
  { Org_Name: 'Christchurch Poultry, Bantam & Pigeon Club Limited' },
  { Org_Name: 'Citrus Services (New Zealand) Limited' },
  { Org_Name: 'Dunedin Poultry, Pigeon & Cage Bird Club Limited' },
  { Org_Name: 'Elk & Wapiti Society of New Zealand (EWSNZ) Limited' },
  { Org_Name: 'Environmental Defence Society (EDS) Limited' },
  { Org_Name: 'For the Love of Bees Limited' },
  { Org_Name: 'Galloway Cattle Society of New Zealand Limited' },
  { Org_Name: 'Hawkes Bay Poultry & Pigeon Fanciers Limited' },
  { Org_Name: 'Invercargill Poultry & Pigeon Club Limited' },
  { Org_Name: 'Irrigo Centre Limited' },
  { Org_Name: 'Jersey New Zealand Limited' },
  { Org_Name: 'Manawatu Beekeepers Club Limited' },
  { Org_Name: 'Manawatu Poultry & Pigeon Club Limited' },
  { Org_Name: 'N.Z. Summerfruit Export Council Limited' },
  { Org_Name: 'Nelson Poultry & Pigeon Association Limited' },
  { Org_Name: 'New Zealand Beekeeping Inc. Limited' },
  { Org_Name: 'New Zealand Feed Manufacturers Association (NZFMA) Limited' },
  { Org_Name: 'New Zealand Flower Exporters Association (NZFEA) Limited' },
  { Org_Name: 'New Zealand Grain & Seed Trade Association Inc. (NZGSTA) Limited' },
  { Org_Name: 'New Zealand Highland Cattle Society Limited' },
  { Org_Name: 'New Zealand holstein-Friesian Association Limited' },
  { Org_Name: 'New Zealand Plant Breeding and Research Association (NZPBRA) Limited' },
  { Org_Name: 'New Zealand Plant Producers Incorporated (NZPPI) Limited' },
  { Org_Name: 'New Zealand Poultry Association Inc. Limited' },
  { Org_Name: 'North Island Rosecomb and Sebright Club Limited' },
  { Org_Name: 'NZ Araucana Club Limited' },
  { Org_Name: 'NZ Beekeepers+ Limited' },
  { Org_Name: 'NZ Grassland Association (NZGA) Limited' },
  { Org_Name: 'NZ Wood Marketing Limited' },
  { Org_Name: 'NZ Wyandotte Club Limited' },
  { Org_Name: 'Oamaru Poultry, Pigeon & Canary Society Limited' },
  { Org_Name: 'Organic Dairy and Pastoral Group (ODPG) Limited' },
  { Org_Name: 'Plant Market Access Council (PMAC) Limited' },
  { Org_Name: 'Quorum Sense Limited' },
  { Org_Name: 'Rare Breeds Conservation Society of New Zealand Limited' },
  { Org_Name: 'Rotorua Honey Bee Club Limited' },
  { Org_Name: 'Royal NZ Institute of Horticulture (RNZIH) Limited' },
  { Org_Name: 'South Devon Cattle Society of New Zealand Limited' },
  { Org_Name: 'Tairawhiti Environment Centre Limited' },
  { Org_Name: 'Taranaki Poultry & Pigeon Club Limited' },
  { Org_Name: 'The Calm Farm Company Limited' },
  { Org_Name: 'The Egg Producers Federation of New Zealand (EPF) Limited' },
  { Org_Name: 'The New Zealand Fishing Industry Guild Limited' },
  { Org_Name: 'The Poultry Industry Association of New Zealand Limited' },
  { Org_Name: 'Waikato Domestic Beekeepers Association Limited' },
  { Org_Name: 'Waikato Poultry and Pigeon Club Limited' },
  { Org_Name: 'Wairarapa Poultry & Pigeon Club Inc Limited' },
  { Org_Name: 'Wellington Beekeepers Association Limited' },
  { Org_Name: 'Wellington, Hutt Valley & Districts Poultry and Pigeon Club Limited' },
  { Org_Name: 'Welsh Black Cattle Breeders Society of NZ Limited' },
  { Org_Name: 'West Coast (S.I.) Poultry & Pigeon Club Limited' },
  { Org_Name: 'Wood-Co Limited' },
  { Org_Name: 'New Zealand Dairy Goat Breeders Association Limited' },
  { Org_Name: 'Aroha Organic Goat Cheese Limited' },
  { Org_Name: 'Baby Steps NZ Limited' },
  { Org_Name: 'Bluebell Goat Milk Limited' },
  { Org_Name: 'Crescent Dairy Co Limited' },
  { Org_Name: 'Dairy Holdings Limited' },
  { Org_Name: 'Goodman Fielder Pty Limited' },
  { Org_Name: 'Wairere Creamery Limited' },
  { Org_Name: 'Maui Sheep Milk Limited' },
  { Org_Name: 'New Zealand Sheep Milk Co. Limited' },
  { Org_Name: 'Dahuti International Limited' },
  { Org_Name: 'Westons Stockfeed Limited' },
  { Org_Name: 'Pinepac Group Limited' },
  { Org_Name: 'Mitchell Bros Cronadun Limited' },
  { Org_Name: 'Aozora New Zealand Limited' },
  { Org_Name: 'Avanza Limited' },
  { Org_Name: 'Balle Bros Exports Limited' },
  { Org_Name: 'Barker Fruit Processors Limited' },
  { Org_Name: 'Bearsley Exports Limited' },
  { Org_Name: 'Blueberry Country Limited' },
  { Org_Name: 'Bostock NZ Irongate Limited' },
  { Org_Name: 'Brownrigg Agriculture Group Limited' },
  { Org_Name: 'Cambridge Farms (NZ) Limited' },
  { Org_Name: 'Cedenco Foods New Zealand Limited' },
  { Org_Name: 'Country Land Foods Limited' },
  { Org_Name: 'Crasborn Fresh Harvest Limited' },
  { Org_Name: 'Ellis Agricultural Services Limited' },
  { Org_Name: 'First Fresh New Zealand Limited' },
  { Org_Name: 'Four Seasons Limited' },
  { Org_Name: 'Fresh Produce Group NZ . Limited' },
  { Org_Name: 'Fresh Solutions Group New Zealand Limited' },
  { Org_Name: 'Frucor Suntory New Zealand Limited' },
  { Org_Name: 'Gibbs Holdings Limited' },
  { Org_Name: 'Juice Products New Zealand Limited' },
  { Org_Name: 'Just Feijoas Limited' },
  { Org_Name: 'Kaiaponi Farms Limited' },
  { Org_Name: 'Kiwi Produce Limited' },
  { Org_Name: 'Kiwi Success Trading Company Limited' },
  { Org_Name: 'Leaderbrand NZ Limited' },
  { Org_Name: 'Midlands Nutrition Limited' },
  { Org_Name: 'Mollex Limited' },
  { Org_Name: 'Monavale Blueberries Limited' },
  { Org_Name: 'N H Packing & Farming Company Limited' },
  { Org_Name: 'New Zealand Growers Limited' },
  { Org_Name: 'New Zealand Pharmaceuticals Limited' },
  { Org_Name: 'NZ Hot House Limited' },
  { Org_Name: 'NZ Onion Company Limited' },
  { Org_Name: 'Produce Agencies Limited' },
  { Org_Name: 'RD2 International Limited' },
  { Org_Name: 'S C Murphy & Co (2012) Limited' },
  { Org_Name: 'S T Growers Limited' },
  { Org_Name: 'SoFresh Produce Limited' },
  { Org_Name: 'Southern Kowhai Exports Limited' },
  { Org_Name: 'Southern Packers Limited' },
  { Org_Name: 'Te Mata Exports Limited' },
  { Org_Name: 'The Feijoa Fruit Company Limited' },
  { Org_Name: 'The Fresh Fruit Company Of New Zealand Limited' },
  { Org_Name: 'The New Zealand Blackcurrant Co-Operative Limited' },
  { Org_Name: 'Viberi New Zealand Limited' },
  { Org_Name: 'Wai Shing Foods Limited' },
  { Org_Name: 'Waione Exports Limited' },
  { Org_Name: 'Mybeehive (A) Limited' },
  { Org_Name: 'New Zealand M?nuka Group Limited' },
  { Org_Name: 'Bostock Brothers Organic Free Range Chicken Limited' },
  { Org_Name: "Brink's Chicken Limited" },
  { Org_Name: 'Canter Valley Holdings Limited' },
  { Org_Name: 'Crozier Services Limited' },
  { Org_Name: 'Inghams Enterprises (NZ) Pty Limited' },
  { Org_Name: 'Tegel Foods Limited' },
  { Org_Name: "Turk's Poultry Farm Limited" },
  { Org_Name: 'Auckland Meat Processors Limited' },
  { Org_Name: 'Cabernet Foods Limited' },
  { Org_Name: 'Crusader Meats New Zealand Limited' },
  { Org_Name: 'Halswell Butchery Processing Services Limited' },
  { Org_Name: 'Progressive Meats Limited' },
  { Org_Name: "Smith's Meat Company Limited" },
  { Org_Name: 'South Pacific NZ Meats Limited' },
  { Org_Name: 'TK Natural Lamb Limited' },
  { Org_Name: 'Windy Meat Processing Limited' },
  { Org_Name: 'Duncan NZ Venison Limited' },
  { Org_Name: 'Mountain River Venison Limited' },
  { Org_Name: 'Garra International Limited' },
  { Org_Name: 'Agribusiness Advisory Limited' },
  { Org_Name: 'Bell Global Limited' },
  { Org_Name: 'Canterbury Seafoods Limited' },
  { Org_Name: 'Solander Limited' },
  { Org_Name: 'Clevedon Coast Oysters Limited' },
  { Org_Name: 'DeepBlue Seafoods NZ Limited' },
  { Org_Name: 'Finestkind Limited' },
  { Org_Name: 'Fiordland Lobster Company Limited' },
  { Org_Name: 'Future Cuisine Marine Farms Limited' },
  { Org_Name: 'Intersea Limited' },
  { Org_Name: 'L & B Taspac Limited' },
  { Org_Name: 'Marconee Seafoods Nz Limited' },
  { Org_Name: 'Ngāi Tahu Seafoods Limited' },
  { Org_Name: 'Oceans North Seafood Limited' },
  { Org_Name: 'OP Columbia (OPC) Limited' },
  { Org_Name: 'Southern Clams Limited' },
  { Org_Name: 'Southfish Limited' },
  { Org_Name: 'United Fisheries (CHCH) Limited' },
  { Org_Name: 'Westfleet Seafoods Limited' },
  { Org_Name: 'Westpac Mussels Distributors Limited' },
  { Org_Name: 'Wildfish Export Limited' },
  { Org_Name: 'Bostock Wines Limited' },
  { Org_Name: 'Joma International Trading Company Limited' },
  { Org_Name: 'New Zealand Wool Services International Limited' },
  { Org_Name: 'Evergreen Olive Estate Limited' },
  { Org_Name: 'The Founders Holding Limited' },
  { Org_Name: 'The Olive Press Limited' },
  { Org_Name: 'Baygold Holdings Limited' },
  { Org_Name: '45 South Management Limited' },
  { Org_Name: 'Aranui Exports Limited' },
  { Org_Name: 'Bay Tropics Limited' },
  { Org_Name: 'Boyds Asparagus Industries Limited' },
  { Org_Name: 'Canterbury Dried Foods Limited' },
  { Org_Name: 'Cape Produce Limited' },
  { Org_Name: 'Cherri Global Limited' },
  { Org_Name: 'Chestnut Traders Limited' },
  { Org_Name: 'Chevalier Wholesale Produce Limited' },
  { Org_Name: 'Claridges Organic Limited' },
  { Org_Name: 'Country Treats Limited' },
  { Org_Name: 'CV Exports Limited' },
  { Org_Name: 'Darling Group Holdings Limited' },
  { Org_Name: 'Delica T/A T&G Global Limited' },
  { Org_Name: 'Exception Limited' },
  { Org_Name: 'Exportplus Limited' },
  { Org_Name: 'Food View Limited' },
  { Org_Name: 'Freschco Limited' },
  { Org_Name: 'Fresh Direct Limited' },
  { Org_Name: 'Freshpro Exports Limited' },
  { Org_Name: 'Frupak Limited' },
  { Org_Name: 'Fsl Foods Limited' },
  { Org_Name: 'Garlico Limited' },
  { Org_Name: 'Gropak Properties Limited' },
  { Org_Name: 'Grove Holdings Limited' },
  { Org_Name: "Heinz Wattie'S (No. 2) Limited" },
  { Org_Name: 'JM France Limited' },
  { Org_Name: 'Kaipara Kumara Limited' },
  { Org_Name: 'Kiwicado Exports Limited' },
  { Org_Name: 'Le Fresh International (NZ) Limited' },
  { Org_Name: "Luv'ya Limited" },
  { Org_Name: 'Mangaweka Asparagus Limited' },
  { Org_Name: 'Matakana Berry Co Limited' },
  { Org_Name: 'Matipou Exports Limited' },
  { Org_Name: "Murphy's New Zealand Limited" },
  { Org_Name: 'New Zealand Cherry Company Limited' },
  { Org_Name: 'New Zealand Gourmet Holdings Limited' },
  { Org_Name: 'NTL Horticulture Limited' },
  { Org_Name: 'NZ Chery Partnership t/a NZ Cherry Corp Limited' },
  { Org_Name: 'NZ Orchard Direct Limited' },
  { Org_Name: 'Oakleys Limited' },
  { Org_Name: 'Onepu Springs Orchard Limited' },
  { Org_Name: 'Oravida NZ Limited' },
  { Org_Name: 'Paragon Produce (NZ) Limited' },
  { Org_Name: "Perry's Berrys Limited" },
  { Org_Name: 'Pole To Pole Exports Limited' },
  { Org_Name: 'Pongs Creek Trading Limited' },
  { Org_Name: 'Primor Produce Limited' },
  { Org_Name: 'Produce Partners NZ Limited' },
  { Org_Name: 'Pure Pac Sales Limited' },
  { Org_Name: 'RJ Flowers Limited' },
  { Org_Name: 'South Seas Exports Limited' },
  { Org_Name: 'T & S International Limited' },
  { Org_Name: 'Tarras Cherry Corp Limited' },
  { Org_Name: 'Tendertips Limited' },
  { Org_Name: 'The Nutritious Kiwi Fruit Company Limited' },
  { Org_Name: 'Valic NZ Limited' },
  { Org_Name: 'Valleyfresh Direct (NZ) Limited' },
  { Org_Name: 'Vocado Limited' },
  { Org_Name: 'Walnuts New Zealand Co-Operative Limited' },
  { Org_Name: 'Zaberri (NZ) Limited' },
  { Org_Name: 'Biodynamics New Zealand Limited' },
  { Org_Name: 'Earthcare Education Aotearoa Limited' },
  { Org_Name: 'Horticulture Charitable Trust Limited' },
  { Org_Name: 'New Zealand Deerstalkers Association (NZDA) Limited' },
  { Org_Name: 'Permaculture Aotearoa Limited' },
  { Org_Name: 'Permaculture in New Zealand (PiNZ) Limited' },
  { Org_Name: 'The New Zealand Trees for Bees Research Trust Limited' },
  { Org_Name: 'Seafood Innovations Limited' },
  { Org_Name: 'Agricultural and Marketing Research and Development Trust (AGMARDT) Limited' },
  { Org_Name: 'University of Auckland' },
  { Org_Name: 'Grow Irrigation Limited' },
  { Org_Name: 'Federation of Māori Authorities (FOMA)' },
  { Org_Name: 'Deloitte' },
]

export default companyNames

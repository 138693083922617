import styled from 'styled-components/macro'
import React from 'react'

export const smButton = {
  width: '15rem',
}

export const regButton = {
  width: '24.5rem',
}

export const lgButton = {
  width: '30rem',
}

export const xlButton = {
  width: '50rem',
}

const ButtonContainer = styled.div`
  display: flex;
  width: 800px;
  margin-top: 1rem;
  align-content: baseline;
  flex-direction: column;
  flex-wrap: wrap;
  height: ${props => (props.dynamicChoices.length >= 10 ? '30rem' : 'auto')};
  @media (min-width: 0px) and (max-width: 479px) {
    width: 90vw;
    flex-wrap: nowrap;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }
`

const ButtonLayout = props => {
  const { dynamicChoices, gridDisplay, children, size } = props
  return (
    <ButtonContainer
      dynamicChoices={dynamicChoices}
      gridDisplay={gridDisplay}
      size={size ? xlButton.width : regButton.width}
    >
      {children}
    </ButtonContainer>
  )
}

export default ButtonLayout

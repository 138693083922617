import React from 'react'
import styled from 'styled-components/macro'
import _ from 'lodash'
import propTypes from 'prop-types'
import { Tooltip, message } from 'antd'
import InputField from './InputField'

const MultiCardChoice = ({
  choices,
  currentAnswer,
  updateCurrent,
  settings,
  question,
  submitAnswer,
}) => {
  const { primaryColour, contrastColour } = settings
  const { id, value } = currentAnswer

  const dynamicColour = !_.isUndefined(primaryColour) ? primaryColour : '#E82D7C'
  const dynamicContrast = !_.isUndefined(contrastColour) ? contrastColour : '#fff'

  const buttonOnClick = questionChoice => {
    const onClick = () => {
      const answer = {
        id: questionChoice.id,
        text: questionChoice.text,
        value: questionChoice.value,
      }
      let newAnswer = {
        ...currentAnswer,
      }

      if (!currentAnswer.value || _.isString(currentAnswer.value)) {
        newAnswer.value = [] // init empty array
      }

      if (questionChoice.warning && !(questionChoice.id in newAnswer)) {
        message.error(questionChoice.warning, 99)
        newAnswer.value = [{ id: answer.id, value: answer.value, warning: true }] // add additional prop to object, will be used for filter
        updateCurrent(newAnswer)
      } else if (questionChoice.submit && !(questionChoice.id in newAnswer)) {
        newAnswer.value = [{ id: answer.id, value: answer.value, submit: true }] // add additional prop to object, will be used for filter
        updateCurrent(newAnswer)
        submitAnswer()
      } else {
        if (newAnswer.value.some(item => item.value === answer.value)) {
          newAnswer.value = newAnswer.value.filter(item => item.value !== answer.value)
        } else {
          newAnswer.value = newAnswer.value.filter(item => !item.submit && !item.warning) // remove items
          message.destroy() // remove warning
          newAnswer.value = [...newAnswer.value, { id: answer.id, value: answer.value }]
        }
        updateCurrent(newAnswer)
      }
    }
    return onClick
  }
  const dynamicButton = questionChoice => {
    const button = (
      <Button
        dynamicColour={dynamicColour}
        dynamicContrast={dynamicContrast}
        type="button"
        key={questionChoice.id}
        // onClick={buttonOnClick(questionChoice)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '-1rem',
          }}
        >
          <span>{questionChoice.icon}</span>

          <p style={{ marginTop: '0', marginBottom: '0' }}>{questionChoice.text}</p>
        </div>
      </Button>
    )
    if (questionChoice.toolTip) {
      return (
        <Tooltip placement="topLeft" title={questionChoice.toolTip}>
          {button}
        </Tooltip>
      )
    }
    return button
  }

  return (
    <>
      {/* {!_.isUndefined(question.disclaimer) ? (
        <Disclaimer>{question.disclaimer}</Disclaimer>
      ) : (
        <Disclaimer>Please select all that apply.</Disclaimer>
      )} */}
      <ButtonContainer>
        {choices.map(questionChoice => {
          if (questionChoice.type === 'input') {
            return (
              <InputWrapper
                style={{ marginTop: question.marginTop }}
                active={questionChoice.id === id}
                key={questionChoice.id}
              >
                <InputField
                  style={{ marginTop: question.otherStyle }}
                  active={questionChoice.id === id}
                  size={'100%'}
                  settings={settings}
                  inputValue={
                    currentAnswer.value &&
                    currentAnswer.value.find(answer => answer.id === questionChoice.id) &&
                    currentAnswer.value.find(answer => answer.id === questionChoice.id).value
                    // currentAnswer.value &&
                    // currentAnswer.value[questionChoice.id] &&
                    // currentAnswer.value[questionChoice.id].value
                  }
                  updateInputValue={e => {
                    const answer = {
                      id: questionChoice.id,
                      text: questionChoice.text,
                      value: e.target.value,
                    }
                    let newAnswer = {
                      ...currentAnswer,
                    }
                    if (!currentAnswer.value) {
                      newAnswer.value = [] // init empty array
                    }
                    // newAnswer.value[questionChoice.id] = { value: answer.value }
                    newAnswer.value = [
                      ...newAnswer.value.filter(item => item.id !== answer.id),
                      {
                        id: answer.id,
                        // value: `${answer.text}: ${answer.value}`,
                        value: answer.value,
                      },
                    ]
                    updateCurrent(newAnswer)
                  }}
                  label={questionChoice.text}
                />
              </InputWrapper>
            )
          }
          return (
            <Wrapper
              dynamicColour={dynamicColour}
              dynamicContrast={dynamicContrast}
              style={{ flex: question.flex, height: question.height }}
              key={questionChoice.id}
              onClick={buttonOnClick(questionChoice)}
              active={
                currentAnswer.value &&
                _.isArray(currentAnswer.value) &&
                currentAnswer.value.some(item => item.value === questionChoice.value)
              }
            >
              <CircleWrapper>
                <Circle
                  dynamicColour={dynamicColour}
                  dynamicContrast={dynamicContrast}
                  className="svg-wrapper"
                >
                  <svg className="svg" version="1.1" id="tick" viewBox="6 5 26 26">
                    <polyline
                      className="path"
                      points="
          11.6,20 15.9,24.2 26.4,13.8 "
                    />
                  </svg>
                </Circle>
              </CircleWrapper>

              {dynamicButton(questionChoice)}
            </Wrapper>
          )
        })}
      </ButtonContainer>
    </>
  )
}

const Button = styled.button`
  display: block;
  height: 100%;
  font-size: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 3px;
  font-weight: 400;
  img {
    height: 100px;
  }
`

const Icon = styled.svg``

const InputWrapper = styled.div`
  /* margin-top: calc(1vh + 0.5rem); */
  width: 517px;
  .svg-wrapper {
    margin-left: 230px;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .svg {
    width: 20px;
  }
  .path {
    fill: transparent;
    stroke: ${props => (props.active ? props.dynamicColour : '#53bf8b')};
    stroke-width: 2;
    stroke-dasharray: 25;
    stroke-dashoffset: ${props => (props.active ? 0 : 26)};
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dashoffset 250ms ease-in-out;
  }
`

const Circle = styled.div`
  /* background: yellow; */
  display: flex;
  // position: absolute;
  align-items: center;
  margin-top: 10px;
  width: 25px;
  height: 25px;
  padding: 2px;
  border-radius: 25px;
  border: ${props => (props.active ? '1px solid white' : 'none')};
  .svg-wrapper {
    width: 40px;
    display: contents;
    align-items: center;
    justify-content: center;
  }
  background-color: #fff;
  .svg {
    width: 30px;
  }
  .path {
    fill: transparent;
    stroke: ${props => (props.active ? props.dynamicColour : '#53bf8b')};
    stroke-width: 3;
    stroke-dasharray: 25;
    stroke-dashoffset: ${props => (props.active ? 0 : 26)};
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dashoffset 250ms ease-in-out;
  }
`
const CircleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 25px;
  width: 95%;
`
const Wrapper = styled.div`
  flex: 0 0 32%;
  max-width: ${props => (props.gridDisplay ? '551px' : '258px')};
  cursor: pointer;
  border-radius: 3px;
  height: ${props => (props.gridDisplay ? '50px' : '175px')};
  /* display: ${props => (props.gridDisplay ? 'flex' : 'block')}; */
  display: flex;
  flex-direction: column;
  /* flex-direction: ${props => (props.gridDisplay ? 'row-reverse' : '')}; */
  justify-content: center;
  /* padding-left: ${props => (props.gridDisplay ? '3px' : '1px')}; */
  /* margin-bottom: ${props =>
    props.gridDisplay ? 'calc(16px + 0rem)' : 'calc(16px + 0.5rem)'}; */
    background-color: ${props => (props.active ? props.dynamicColour : 'white')};

  margin-bottom: 8px;
  margin-right: 8px;
  /* padding-left: 5px; */
  border: ${props => (props.active ? 'none' : '1px solid rgba(0, 0, 0, 0.3)')};
  color: ${props => (props.active ? props.dynamicContrast : props.dynamicColour)};

  transition: background 250ms ease-in-out, transform 150ms ease;
  &:hover {
      transform: translateZ(0);
      -webkit-font-smoothing: subpixel-antialiased;
      border-color: ${props => props.dynamicColour};
      border-width: 2px;
      border-color: none;
      transform: scale(1.01);
    }

  .svg-wrapper {
    /* margin-left: ${props => (props.gridDisplay ? '1rem' : '100px')}; */
    /* margin-top: 10px; */
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .svg {
    width: 20px;
  }
  .path {
    fill: transparent;
    stroke: ${props => (props.active ? props.dynamicColour : '#53bf8b')};
    stroke-width: 2;
    stroke-dasharray: 25;
    stroke-dashoffset: ${props => (props.active ? 0 : 26)};
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dashoffset 250ms ease-in-out;
  }
  ${Icon} {
    path {
      fill: ${props => (props.active ? props.dynamicContrast : props.dynamicColour)};
    }
  }

  ${Button} {
    border: none;
    background-color: transparent;
    /* background-color: ${props => (props.active ? props.dynamicColour : 'white')}; */
    /* border: ${props => (props.active ? 'none' : '1px solid rgba(0, 0, 0, 0.3)')}; */
    /* transition: background 250ms ease-in-out, transform 150ms ease; */
    font-weight: ${props => (props.active ? '500' : '400')};
    /* color: ${props => (props.active ? props.dynamicContrast : props.dynamicColour)}; */
    /* width: ${props => (props.gridDisplay ? '510px' : '251px')}; */
    p {
      font-size: 1rem;
      color: ${props => (props.active ? props.dynamicContrast : '#262626')};
    }
    /* &:hover {
      transform: translateZ(0);
      -webkit-font-smoothing: subpixel-antialiased;
      border-color: ${props => props.dynamicColour};
      border-width: 2px;
      border-color: none;
      transform: scale(1.01);
    } */
  }
  @media (min-width: 0px) and (max-width: 479px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: calc(1vh + 0rem);
    height: 50px !important;
    width: 88vw !important;
    max-width: 360px !important;
    flex: 0 0 0; /* Overrides styling from desktop */
    padding-left: 10px;
    padding-right: 10px;
    ${CircleWrapper} {
      width: auto;
    }
    ${Button} {
      display: flex;
      align-items: center;
      height: inherit;
      span {
        display: none;
      }
    }
    ${Circle} {
      position: inherit;
    }
  }
  @media (min-width: 480px) and (max-width: 767px) {
  }
`

const Disclaimer = styled.p`
  font-style: italic;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: 800px;
  font-size: 14px;
  @media (min-width: 0px) and (max-width: 479px) {
    width: 90vw;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
  }
  @media (min-width: 480px) and (max-width: 768px) {
    display: flex;
    width: 90vw;
    justify-content: flex-start;
    align-items: baseline;
  }
  @media (min-width: 768px) and (max-width: 1025px) {
    display: flex;
    width: 80vw;
    justify-content: flex-start;
    align-items: baseline;
  }
`

const ButtonContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  width: 800px;
  padding-left: 1px;
  flex-direction: ${props => (props.gridDisplay ? 'column' : 'row')};
  align-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;

  @media (min-width: 0px) and (max-width: 479px) {
    flex-direction: column;
    width: 90vw;
    flex-wrap: nowrap;
    align-items: center;
    ${InputWrapper} {
      margin-bottom: 8px;
      margin-right: 0px;
      width: 90vw !important;
    }
  }

  @media (min-width: 480px) and (max-width: 768px) {
    width: auto;
    justify-content: center;
    ${InputWrapper} {
      width: auto !important;
    }
  }

  @media (min-width: 768px) and (max-width: 1025px) {
    justify-content: center;
    width: auto;
  }
`

MultiCardChoice.propTypes = {
  choices: propTypes.instanceOf(Array),
  currentAnswer: propTypes.instanceOf(Object),
  updateCurrent: propTypes.func,
  settings: propTypes.instanceOf(Object),
  question: propTypes.instanceOf(Object),
}
export default MultiCardChoice

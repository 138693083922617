const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

export const actionCodeSettings = {
  url: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}/home`,
  handleCodeInApp: true,
}
//https://us-central1-confer-development-build.cloudfunctions.net
export const functionsUrl = process.env.REACT_APP_FUNCTIONS_URL

export default firebaseConfig

import React from 'react'
import styled from 'styled-components/macro'
import Circle from '../Misc/Circle'
import { smButton, regButton, lgButton, xlButton } from '../Layout/ButtonContainer'
import createRipple from '../Animations/ripple'

const Wrapper = ({
  onClick,
  gridLayout,
  buttonText,
  buttonIcon,
  active,
  mobileHeight,
  dynamicColour,
  dynamicContrast,
  useIcons,
  size,
  fill,
  questionType,
}) => {
  console.log('questionType', questionType)

  // button content

  const renderButton = () => {
    return (
      <>
        {useIcons ? <Icon>{buttonIcon}</Icon> : null}
        <Button>
          {buttonIcon ? (
            <Icon active={active} fill={fill}>
              {buttonIcon}
            </Icon>
          ) : null}
          <Text>{buttonText}</Text>
        </Button>
        <Circle type={questionType} active={active} dynamicColour={dynamicColour} />
      </>
    )
  }

  return (
    <ButtonWrapper
      gridLayout={gridLayout}
      onClick={onClick}
      active={active}
      mobileHeight={mobileHeight}
      dynamicColour={dynamicColour}
      dynamicContrast={dynamicContrast}
      size={size ? size : regButton.width}
    >
      {renderButton()}
    </ButtonWrapper>
  )
}

const Icon = styled.span`
  height: 1rem;
  width: 1rem;
  margin-right: 1rem;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  svg {
    fill: ${props => (props.active ? props.dynamicColour : props.fill)};
  }
`

const Text = styled.span`
  font-size: 1rem;
  text-align: left;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-word;
  margin-bottom: 0rem;
  /* line-height: 1.5; */
`

const Button = styled.button`
  display: flex;
  align-content: baseline;
  text-align: justify;
  text-justify: inter-word;
  width: 90%;
  border: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 3px;
  background-color: transparent;
`

export const ButtonWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  border: ${props => (props.active ? 'none' : '1px solid rgba(0, 0, 0, 0.3)')};
  color: ${props => (props.active ? props.dynamicContrast : '#262626')};
  height: 3.125rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: ${props => props.size};
  justify-content: space-between;
  -webkit-transition: background-color 150ms ease-in-out;
  -moz-transition: background-color 150ms ease-in-out;
  -o-transition: background-color 150ms ease-in-out;
  transition: background-color 150ms ease-in-out;
  background-color: ${props => (props.active ? props.dynamicColour : 'white')};

  &:hover {
    /* transform: translateZ(0); */
    /* -webkit-font-smoothing: subpixel-antialiased; */
    border-color: ${props => props.dynamicColour};
    border-width: 2px;
    border-color: none;
  }

  ${Button} {
    // font-weight: ${props => (props.active ? '501' : '400')};
    font-weight: 400;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    height: ${props => (props.mobileHeight ? props.mobileHeight : '3.125rem')}!important;
    width: 90vw !important;
    margin-right: 0px;
  }
  span.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 500ms linear;
    background-color: rgba(255, 255, 255, 0.7);
  }

  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }
`

export default Wrapper

import {
  INCREMENT,
  DECREMENT,
  UPDATE_ANSWER,
  UPDATE_CURRENT,
  RETRIEVE_ANSWER,
  SET_CURRENT_QUESTION,
  GO_TO_PREVIOUS_QUESTION,
  START_LOOP,
  END_LOOP,
  BREAK_LOOP,
  INJECT_CURRENT,
  GO_TO_NEXT_QUESTION,
  ERROR,
} from './actions'

const getQuestionState = state => {
  return {
    qid: state.currentQuestionId,
    qType: state.currentQuestionType,
    loopIndex: state.loopIndex,
    loopArray: state.loopArray,
    loopStartQid: state.loopStartQid,
  }
}

const intialState = {
  currentQuestionId: null,
  currentQuestionType: null,
  userAnswers: {},
  currentAnswer: {},
  questionHistory: [],
  timeOnCurrentQuestion: null,
  loopIndex: null,
  loopArray: null,
  loopStartQid: null,
  loopState: null,
  error: null,
}

// const calcTime = (subStarted, subEnded) => {
//   const submissionStarted = new Date(subStarted)
//   const submissionEnded = new Date(subEnded)
//   const timeDiff = submissionEnded.getTime() - submissionStarted.getTime()

//   let msec = timeDiff
//   const hh = Math.floor(msec / 1000 / 60 / 60)
//   msec -= hh * 1000 * 60 * 60
//   const mm = Math.floor(msec / 1000 / 60)
//   msec -= mm * 1000 * 60
//   const ss = Math.floor(msec / 1000)
//   msec -= ss * 1000

//   const timeTaken = hh + ':' + mm + ':' + ss
//   return timeTaken
// }

export default function reducer(state = intialState, action) {
  let nextQid
  let nextIndex

  // console.log('reducer', state, action)

  switch (action.type) {
    case ERROR:
      return {
        ...state,
        error: state.error,
      }
    case INCREMENT:
      return {
        ...state,
        currentQuestionId: state.currentQuestionId + 1,
        questionHistory: [getQuestionState(state)].concat(state.questionHistory),
      }
    case DECREMENT:
      return {
        ...state,
        currentQuestionId: state.currentQuestionId - 1,
      }
    case SET_CURRENT_QUESTION:
      switch (state.loopState) {
        case 'restart':
          return {
            ...state,
            loopIndex: state.loopIndex + 1,
            currentQuestionId: state.loopStartQid,
            questionHistory: [getQuestionState(state)].concat(state.questionHistory),
            loopState: null,
          }
        case 'end':
          return {
            ...state,
            loopIndex: null,
            loopArray: null,
            loopStartQid: null,
            loopState: null,
            currentQuestionId: action.qid,
            questionHistory: [getQuestionState(state)].concat(state.questionHistory),
          }
        default:
          return {
            ...state,
            currentQuestionId: action.qid,
            currentQuestionType: action.qType,
            // timeOnCurrentQuestion: qStarted,
            questionHistory: [getQuestionState(state)].concat(state.questionHistory),
          }
      }
    case GO_TO_PREVIOUS_QUESTION:
      if (state.questionHistory.length > 0) {
        return {
          ...state,
          currentQuestionId: state.questionHistory[0].qid,
          loopIndex: state.questionHistory[0].loopIndex,
          loopArray: state.questionHistory[0].loopArray,
          currentQuestionType: state.questionHistory[0].qType,
          loopStartQid: state.questionHistory[0].loopStartQid,
          questionHistory: state.questionHistory.slice(1),
          loopState: null,
        }
      }
      return state
    case UPDATE_ANSWER:
      if (state.loopIndex !== null) {
        return {
          ...state,
          userAnswers: {
            ...state.userAnswers,
            [state.currentQuestionId]: {
              ...state.userAnswers[state.currentQuestionId],
              loop: true,
              [state.loopArray[state.loopIndex]]: state.currentAnswer,
            },
          },
          currentAnswer: {},
        }
      }
      return {
        ...state,
        userAnswers: {
          ...state.userAnswers,
          [state.currentQuestionId]: state.currentAnswer,
        },
        currentAnswer: {},
      }
    case UPDATE_CURRENT:
      return {
        ...state,
        currentAnswer: action.answer,
      }
    case INJECT_CURRENT:
      return {
        ...state,
        currentAnswer: { ...state.currentAnswer, ...action.answer },
      }
    case RETRIEVE_ANSWER:
      if (state.loopIndex !== null) {
        return {
          ...state,
          currentAnswer:
            state.currentQuestionId in state.userAnswers &&
            state.loopArray[state.loopIndex] in state.userAnswers[state.currentQuestionId]
              ? state.userAnswers[state.currentQuestionId][state.loopArray[state.loopIndex]]
              : {},
        }
      }
      return {
        ...state,
        currentAnswer:
          state.currentQuestionId in state.userAnswers
            ? state.userAnswers[state.currentQuestionId]
            : {},
      }
    case START_LOOP:
      if (action.questions.find(q => q.id === state.currentQuestionId).startLoop) {
        return {
          ...state,
          loopIndex: state.loopIndex === null ? 0 : state.loopIndex,
          loopArray:
            state.loopArray === null
              ? action.questions.find(q => q.id === state.currentQuestionId).startLoop
              : state.loopArray,
          loopStartQid: state.loopStartQid === null ? state.currentQuestionId : state.loopStartQid,
        }
      }
      return state
    case END_LOOP:
      if (state.loopIndex >= state.loopArray.length - 1) {
        return {
          ...state,
          loopState: 'end',
        }
      }
      return {
        ...state,
        loopState: 'restart',
      }
    case BREAK_LOOP: {
      const { breakLoop } = action.questions.find(q => q.id === state.currentQuestionId)
      if (
        breakLoop &&
        breakLoop({ answers: state.userAnswers, loopIndex: state.loopIndex }) != null
      ) {
        return {
          ...state,
          loopState: 'break',
        }
      }
      return state
    }
    case GO_TO_NEXT_QUESTION:
      switch (state.loopState) {
        case 'restart':
          return {
            ...state,
            loopIndex: state.loopIndex + 1,
            currentQuestionId: state.loopStartQid,
            questionHistory: [getQuestionState(state)].concat(state.questionHistory),
            loopState: null,
          }
        case 'break':
          if (typeof action.breakLoop === 'function') {
            nextQid = action.breakLoop({ answers: state.userAnswers, loopIndex: state.loopIndex })
          } else {
            nextIndex = action.questions.findIndex(q => q.id === state.currentQuestionId) + 1
            if (nextIndex >= action.questions.length) {
              nextQid = state.currentQuestionId
            } else {
              nextQid = action.questions[nextIndex].id
            }
            // nextQid = state.currentQuestionId + 1
          }
          return {
            ...state,
            loopIndex: null,
            loopArray: null,
            loopStartQid: null,
            loopState: null,
            currentQuestionId: nextQid,
            questionHistory: [getQuestionState(state)].concat(state.questionHistory),
          }

        case 'end':
          if (typeof action.next === 'function') {
            nextQid = action.next({ answers: state.userAnswers, loopIndex: state.loopIndex })
          } else {
            nextIndex = action.questions.findIndex(q => q.id === state.currentQuestionId) + 1
            if (nextIndex >= action.questions.length) {
              nextQid = state.currentQuestionId
            } else {
              nextQid = action.questions[nextIndex].id
            }
          }
          return {
            ...state,
            loopIndex: null,
            loopArray: null,
            loopStartQid: null,
            loopState: null,
            currentQuestionId: nextQid,
            questionHistory: [getQuestionState(state)].concat(state.questionHistory),
          }
        default:
          if (typeof action.next === 'function') {
            nextQid = action.next({ answers: state.userAnswers, loopIndex: state.loopIndex })
          } else {
            nextIndex = action.questions.findIndex(q => q.id === state.currentQuestionId) + 1
            if (nextIndex >= action.questions.length) {
              nextQid = state.currentQuestionId
            } else {
              nextQid = action.questions[nextIndex].id
            }
          }

          return {
            ...state,
            currentQuestionId: nextQid,
            currentQuestionType: action.questions.find(q => q.id === nextQid).type,
            questionHistory: [getQuestionState(state)].concat(state.questionHistory),
          }
      }

    default:
      return state
  }
}

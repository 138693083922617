import { compose, applyMiddleware, createStore } from 'redux'
import * as firebase from 'firebase/app'
import queryString from 'query-string'
import reducer from './reducers/reducers'
import { calcTime, getUserDeviceType } from './helperFunctions'
import Bowser from 'bowser'
import React from 'react'
import ErrorPage from './question-types/ErrorPage'
import moment from 'moment'
import { error } from './reducers/actions'

const middlewares = []

if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENVIRONMENT === `dev`) {
  const { logger } = require(`redux-logger`)

  middlewares.push(logger)
}

export const store = compose(applyMiddleware(...middlewares))(createStore)(reducer)

async function updateStore(surveyName) {
  const db = firebase.firestore()
  const fetchDoc = () => {
    const docRef = db.collection('surveys').doc(surveyName)
    let docInfo
    return docRef
      .get()
      .then(doc => {
        if (doc.exists) {
          docInfo = doc.data().settings.saveResults
        }
        return docInfo
      })
      .catch(err => console.log('error from fetch doc', err))
  }

  const writeToFirestore = await fetchDoc()

  if (writeToFirestore) {
    firebase
      .auth()
      .signInAnonymously()
      .catch(error => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log('firebase Error', errorCode, errorMessage)
      })
    firebase.auth().onAuthStateChanged(firebaseUser => {
      if (firebaseUser) {
        const userUid = firebaseUser.uid
        const lastSignIn = firebaseUser.metadata.creationTime
        let currentValues
        const parsed = queryString.parse(window.location.search)
        const browser = Bowser.getParser(window.navigator.userAgent)

        const anonUser = {
          userId: userUid,
          lastActive: lastSignIn,
          urlId: parsed.id ? parsed.id : 'empty',
          userDetails: {
            device: getUserDeviceType(),
            browser: browser.getBrowserName(),
          },
        }

        console.log('anonUser', anonUser)

        const subRef = db
          .collection('surveys')
          .doc(surveyName)
          .collection('submissions')
          .doc()

        // subRef.onSnapshot(
        //   doc => {
        //     console.log('Current data: ', doc.data())
        //   },
        //   firebaseError => {
        //     console.log('error', firebaseError)
        //     error(firebaseError)
        //     console.log('app state', store.getState())

        //     throw firebaseError // clientside db error
        //   },
        // )

        // const submissionStarted = new Date().toUTCString()
        const submissionStarted = moment().format('LLL')

        // refactor to use await
        subRef.set({ anonUser, submissionStarted }, { merge: true })
        const sendUpdate = () => {
          const previousValues = currentValues
          currentValues = store.getState().userAnswers
          if (store.getState().currentQuestionType === 'endPage') {
            // const submissionEnded = new Date().toUTCString()
            const submissionEnded = moment().format('LLL')

            subRef.set(
              {
                submissionStarted,
                submissionEnded,
                answers: currentValues,
                surveyCompleted: true,
                timeTaken: calcTime(submissionStarted, submissionEnded),
              },
              { merge: true },
            )
          }
          if (previousValues !== currentValues) {
            const lastAnswerTime = moment().format('LLL')
            subRef.set(
              {
                lastAnswerTime,
                answers: currentValues,
                timeInSurvey: calcTime(submissionStarted, lastAnswerTime),
              },
              { merge: true },
            )
          }
        }
        store.subscribe(sendUpdate)
      }
    })
  }
  return store
}

export default updateStore

import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import _ from 'lodash'
import styled from 'styled-components'
import propTypes from 'prop-types'
import { geoMercator, geoPath } from 'd3-geo'
import Tooltip from 'rc-tooltip'

const SimpleMap = ({ question, updateCurrent, currentAnswer, submitAnswer }) => {
  const [hoverInfo, updateHoverInfo] = useState({ id: null, region: null })
  const [mapSize, setMapSize] = useState({ width: 800, height: 500 })
  const [regions, setRegions] = useState([])
  const [mapData, setMapData] = useState({})
  const mapSVG = useRef()

  const onHover = event => {
    const regionId = parseInt(event.target.id)
    if (_.isNumber(regionId)) {
      updateHoverInfo({
        id: regionId,
        region: regions.find(region => region.properties.id === regionId).properties.name,
        // lngLat: event.lngLat,
      })
    }
  }

  const onClick = event => {
    const regionId = parseInt(event.target.id)
    if (_.isNumber(regionId)) {
      updateCurrent({
        ...currentAnswer,
        value: {
          id: regionId,
          region: regions.find(region => region.properties.id === regionId).properties.name,
        },
      })
    }
  }

  // function handleResize() {
  //   setMapSize({
  //     ...mapSize,
  //     width: mapSVG.parentNode.clientWidth,
  //     height: mapSVG.parentNode.clientHeight,
  //   })
  // }

  useEffect(() => {
    // handleResize()
    axios.get(`${process.env.PUBLIC_URL}/nz-geodata-simple.json`).then(response => {
      setMapData(response.data)
      setRegions(response.data.features)
    })
  }, [])

  const { height, width } = mapSize
  const projection = geoMercator().fitSize([width, height], mapData)

  const pathGenerator = geoPath().projection(projection)

  const currentId = currentAnswer.value ? currentAnswer.value.id : null

  const regionPaths = regions.map(d => {
    return (
      <Tooltip placement="left" trigger={['hover']} overlay={<span>{d.properties.name}</span>}>
        <path
          key={d.properties.id}
          id={d.properties.id}
          d={pathGenerator(d)}
          fill={
            d.properties.id === hoverInfo.id || d.properties.id === currentId ? 'green' : 'white'
          }
          fillOpacity={d.properties.id === currentId ? 1 : 0.3}
          stroke="black"
          cursor="pointer"
          className="regions"
          onMouseOver={onHover}
          onFocus={onHover}
          onClick={onClick}
        />
      </Tooltip>
    )
  })

  return (
    <Container className="map">
      <svg width="100%" height="100%" ref={mapSVG}>
        <g>{regionPaths}</g>
      </svg>
    </Container>
  )
}

SimpleMap.propTypes = {
  question: propTypes.instanceOf(Object),
  updateCurrent: propTypes.func,
  currentAnswer: propTypes.instanceOf(Object),
}

const Container = styled.div`
  height: 600px;
  width: 800px;
`

export default SimpleMap

import React, { useRef, useEffect } from 'react'
import styled from 'styled-components/macro'
import _ from 'lodash'
import propTypes from 'prop-types'

const CircleMenu = ({ question, updateCurrent, currentAnswer, settings }) => {
  const { primaryColour } = settings

  const graph = useRef(null)
  useEffect(() => {
    const circlegraph = graph.current
    const circleElements = circlegraph.childNodes // not an array

    let angle = 360 - 90 // angle for items to be placed
    const dangle = 360 / circleElements.length // items placed evenly in circle

    for (let i = 0; i < circleElements.length; i++) {
      const circle = circleElements[i]
      angle += dangle
      circle.style.transform = `rotate(${angle}deg) translate(${circlegraph.clientWidth /
        2}px) rotate(-${angle}deg)`
    }
  }, [])

  return (
    <Container>
      <CircleContainer mainText={question.primaryCircle.text}>
        <div className="circlegraph" ref={graph}>
          {question.choices.map(item => (
            <div className="circle" key={item.id}>
              <InnerText>{item.text}</InnerText>
            </div>
          ))}
        </div>
      </CircleContainer>
    </Container>
  )
}

const InnerText = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 800px;
`

const CircleContainer = styled.div`
  .circlegraph {
    position: relative;
    width: 500px;
    height: 500px;
    margin: calc(100px / 2 + 0px);
    &:before {
      content: '${props => (props.mainText ? props.mainText : 'TESTING')}';
      font-weight: bold;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      height: inherit;
      width: auto;
    }
  }

  .circlegraph .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    margin: calc(-100px / 2);
    background: teal;
    border-radius: 50%;
  }
`

CircleMenu.propTypes = {
  question: propTypes.instanceOf(Object),
  settings: propTypes.instanceOf(Object),
  currentAnswer: propTypes.instanceOf(Object),
  updateCurrent: propTypes.func,
}

export default CircleMenu

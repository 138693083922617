export const INCREMENT = 'INCREMENT'
export const DECREMENT = 'DECREMENT'
export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION'
export const GO_TO_PREVIOUS_QUESTION = 'GO_TO_PREVIOUS_QUESTION'
export const UPDATE_ANSWER = 'UPDATE_ANSWER'
export const UPDATE_CURRENT = 'UPDATE_CURRENT'
export const RETRIEVE_ANSWER = 'RETRIEVE_ANSWER'
export const START_LOOP = 'START_LOOP'
export const END_LOOP = 'END_LOOP'
export const BREAK_LOOP = 'BREAK_LOOP'
export const GO_TO_NEXT_QUESTION = 'GO_TO_NEXT_QUESTION '
export const INJECT_CURRENT = 'INJECT_CURRENT'

export const ERROR = 'ERROR'

export const error = () => ({ type: ERROR })
export const increment = () => ({ type: INCREMENT })
export const decrement = () => ({ type: DECREMENT })
export const setCurrentQuestion = (qid, qType) => ({ type: SET_CURRENT_QUESTION, qid, qType })
export const goToPreviousQuestion = () => ({ type: GO_TO_PREVIOUS_QUESTION }) // update this
export const updateAnswer = () => ({ type: UPDATE_ANSWER })
export const injectCurrent = answer => ({ type: INJECT_CURRENT, answer })
export const updateCurrent = answer => ({ type: UPDATE_CURRENT, answer })
export const retrieveAnswer = () => ({ type: RETRIEVE_ANSWER })
export const startLoop = questions => ({
  type: START_LOOP,
  questions,
})

export const breakLoop = questions => ({
  type: BREAK_LOOP,
  questions,
})

export const endLoop = () => ({ type: END_LOOP })
// eslint-disable-next-line no-shadow
export const goToNextQuestion = (next, breakLoop, questions) => ({
  type: GO_TO_NEXT_QUESTION,
  next,
  breakLoop,
  questions,
}) // update this

import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Input } from 'antd'
import _ from 'lodash'
import PropTypes from 'prop-types'
import InputField from './InputField'
import TextArea from 'antd/lib/input/TextArea'

const CommentBox = ({ question, updateCurrent, currentAnswer, settings }) => {
  const [value, setCurrentValue] = useState()
  const { TextArea } = Input

  const { primaryColour } = settings

  const dynamicColour = !_.isUndefined(primaryColour) ? primaryColour : '#C4E8C5'

  const updateCurrentValue = value => {
    setCurrentValue(value)
  }
  return (
    <Container>
      {question.specialEffects ? (
        <SpeechBubble>
          <SpeechBubbleInput
            type="text"
            allowClear
            dynamicColour={dynamicColour}
            placeholder={question.placeholder}
            value={currentAnswer ? currentAnswer.value : ''}
            autoSize={{ minRows: 4, maxRows: 6 }}
            onChange={e => {
              const inputValues = {
                value: e.target.value,
              }
              updateCurrentValue(value)
              updateCurrent(inputValues)
            }}
          />
        </SpeechBubble>
      ) : (
        <StyledTextArea
          type="text"
          allowClear
          dynamicColour={dynamicColour}
          placeholder={question.placeholder}
          value={currentAnswer ? currentAnswer.value : ''}
          autoSize={{ minRows: 5, maxRows: 7 }}
          onChange={e => {
            const inputValues = {
              value: e.target.value,
            }
            updateCurrentValue(value)
            updateCurrent(inputValues)
          }}
        />
      )}
      {question.emailInput ? (
        <>
          <p style={{ marginTop: '1rem' }}>{question.inputText}</p>
          <InputWrapper
            style={{
              marginTop: question.marginTop,
              width: question.width,
              height: question.height,
            }}
            gridDisplay={question.verticalGrid}
          >
            <InputField
              settings={settings}
              inputValue={currentAnswer.email ? currentAnswer.email : ''}
              updateInputValue={e => {
                const inputValues = {
                  value: e.target.value,
                }
                const newAnswer = {
                  ...currentAnswer,
                }
                newAnswer.email = inputValues.value
                updateCurrent(newAnswer)
              }}
              label="Email address"
            />
          </InputWrapper>
        </>
      ) : null}
    </Container>
  )
}

const SpeechBubble = styled.div`
  width: 780px;
  border-radius: 15px;
`

const SpeechBubbleInput = styled(TextArea)`
  box-shadow: 0px 4px 6px rgba(143, 143, 143, 0.2);
  border-radius: 5px;
  width: 800px;
  .ant-input:focus,
  .ant-input-focused {
    border-color: ${props => props.dynamicColour};
    box-shadow: 0px 4px 6px rgba(143, 143, 143, 0.2);
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 90vw;
    margin: auto;
    align-items: center;
  }
  @media (min-width: 480px) and (max-width: 768px) {
    margin: auto;
    width: 90vw;
    align-items: center;
  }
  @media (min-width: 768px) and (max-width: 1025px) {
    margin: auto;
    width: 80vw;
    align-items: center;
  }
`

// const Tip = styled.div`
//   transform: rotate(-45deg);
//   background: #b22727;
//   box-shadow: 0px 4px 6px rgba(143, 143, 143, 0.2);
//   width: 24px;
//   height: 24px;
//   position: relative;
//   bottom: -15px;
//   left: 95%;
// `

const Container = styled.div`
  @media (min-width: 0px) and (max-width: 479px) {
    padding-top: 1rem;
    width: 90vw;
    margin: auto;
    align-items: center;
  }
  @media (min-width: 480px) and (max-width: 768px) {
    margin: auto;
    width: 90vw;
    align-items: center;
  }
  @media (min-width: 768px) and (max-width: 1025px) {
    margin: auto;
    width: 80vw;
    align-items: center;
  }
  /* margin-top: 1rem; */
`

const StyledTextArea = styled(TextArea)`
  width: 32rem;
  margin-top: 0.5rem;
  .ant-input:focus,
  .ant-input:hover,
  .ant-input-focused {
    border-color: ${props => props.dynamicColour};
    box-shadow: 0 0 0 2px rgb(24 144 255 / 0%);
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 90vw;
    margin: auto;
    align-items: center;
  }
  @media (min-width: 480px) and (max-width: 768px) {
    margin: auto;
    width: 90vw;
    align-items: center;
  }
  @media (min-width: 768px) and (max-width: 1025px) {
    margin: auto;
    width: 80vw;
    align-items: center;
  }
`

const InputWrapper = styled.div`
  margin-bottom: calc(1vh + 0rem);
  /* width: ${props => (props.gridDisplay ? '551px' : '30%')};
  max-width: ${props => (props.gridDisplay ? '551px' : '258px')}; */
  width: 510px;
  .svg-wrapper {
    margin-left: 230px;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .svg {
    width: 20px;
  }
  .path {
    fill: transparent;
    stroke: ${props => (props.active ? props.dynamicColour : '#53bf8b')};
    stroke-width: 2;
    stroke-dasharray: 25;
    stroke-dashoffset: ${props => (props.active ? 0 : 26)};
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dashoffset 250ms ease-in-out;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 90vw !important;
    max-width: 369px !important;
    /* fieldset {
      label {
        top: ${props => (props.mobileProps.top ? '45px' : '')};
      }
    }
    input {
      height: ${props => (props.mobileProps.height ? '80px' : '50px')};
    } */
  }
`

CommentBox.propTypes = {
  updateCurrent: PropTypes.func,
  currentAnswer: PropTypes.instanceOf(Object),
  question: PropTypes.instanceOf(Object),
}

export default CommentBox

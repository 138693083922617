const companies = [
  {
    Org_Name: '3 Stone Vets',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'A1 Orchard Spreading',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'AB equipment',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Abattoirs Association of New Zealand',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Accounted4',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Accounting Extra',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Accounting House Hamilton',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Active VMA',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Advantage Feeders',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Aerial Surveys',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Aerial surveys',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'AFFCO',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'G. 301 - 1000',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'AFFCO New Zealand',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'AgBiz Accountants',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'AgDesign',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Environmental management and planning',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'AgFirst',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'AgKnowledge',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Environmental management and planning',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'AgRecord',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Software and technology supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'AgResearch',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Crown Research Institutes',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'F. 100 - 300',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Agri Training',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Vocational education organisations',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Agricultural Business Associates',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Agrifeeds',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Feed manufacturers and suppliers',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Agrigate',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Software and technology supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'AgriMagic',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Agrisea',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Fertiliser suppliers',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'AgriSmart',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Software and technology supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: "Agri-Women's Development Trust",
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'AgSafe NZ',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Health, safety & wellbeing',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'AgVice',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Environmental management and planning',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Air8tors',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Spraying',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Allan Agricultural Consulting',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Allan Agricultural Consulting',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Allen Bell Registered Forestry Consultant',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Alliance',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Alliance Group',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Alpine Buildings NZ',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Construction and engineering',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'AMI Insurance',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Insurance',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'A. None',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'AMP',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Insurance',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'A. None',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Ample Group',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Analytica Laboratories',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Analytical laboratory',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'E. 31 - 100',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Analytical Research Laboratories',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Analytical laboratory',
    Org_Size_Facing: 'A. None',
    Org_Advisory: 'A. None',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Anderson Consultancy',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Anexa Farmers Vet Club',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Anexa Veterinary services',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Animal Breeding Services',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Genetics and herd services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Animal Feed Barn',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Feed manufacturers and suppliers',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'ANZ',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Banking',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'E. 31 - 100',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'ANZCO Foods',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'AON New Zealand',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Insurance',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Aon NZ',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Insurance',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'A. None',
    Org_Developers: 'E. 31 - 100',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Aongatete Avocados',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Aotearoa Quota Brokers',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Apata',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Apata Group',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Apples & Pears',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Aquaculture New Zealand',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Ara Institute of Canterbury',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Polytechnics',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Arrow Commodities (NZ)',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Feed manufacturers and suppliers',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'ASB',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Banking',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Asset Forestry Logistics',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Software and technology supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Astute Accounting Services',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'AsureQuality',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Analytical laboratory',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Atkinson & Associates Vet Services',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Auckland Meat Processors',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Auckland Pack & Cool (Apac)',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Avo Alderton',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Orchard management',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'AVOCO',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'AvoJect',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Pesticide and fungicide suppliers',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Avomax',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Spraying',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'BakerAg',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Ballance Agri-Nutrients',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Fertiliser suppliers',
    Org_Size_Facing: 'F. 100 - 300',
    Org_Advisory: 'F. 100 - 300',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Ballance Agri-Nutrients',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Environmental management and planning',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Bayleys Real Estate',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Real estate and land valuation',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'BDO New Zealand',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'BDO Northland',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Bear Cat New Zealand',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Beaumont Shelter Trimming & Mulching',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Mulching',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Beef + Lamb New Zealand',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Beef SolutionNZ',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Software and technology supplier',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Bell Global',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Bennetts Proactive',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Better Dairy Solutions',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'BFA',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Bio Soil & Crop',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Orchard management',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Blackburne Group',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Blue Sky Meats NZ',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Bluegrass',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'BNZ',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Banking',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'E. 31 - 100',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'BODCO',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Dairy products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'E. 31 - 100',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'BODCO Dairy New Zealand',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Dairy products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'A. None',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Bradstreet Contractors',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Mulching',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Brendan Attrill Agriculture',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Brent Consulting',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Human resource management',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Brent Kelly Law',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Legal advice',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Brett Gilmore Consulting',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Brichwood',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Brown Glassford & Co',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Brown Pennell',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Brown Webb Richardson (BWR)',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Bruce Sparrow & Associates',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Buck Forestry Services',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Infrastructure & technology',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'BX Foods',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'C&T Walker Construction',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Construction and engineering',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Cabernet Foods',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Callaghan Innovation',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Crown Research Institutes',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'E. 31 - 100',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Cambridge Vets',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Cambrige Grains',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Feed manufacturers and suppliers',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Cameron Ag',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Candy Gillespie',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Canterbury Seafoods',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: "Capper's Contracting",
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Carbon Forest Services',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Carbon forestry',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Carrfields',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Castlegate James',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Feed manufacturers and suppliers',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Catapult Partners Chartered Accountants',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Cawthron Institute',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Other research providers',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'A. None',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'City Forests',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Harvesting',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Cleland Hancox',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'CLELANDS Timber ltd',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Processing & manufacturing',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Clevedon Coast Oysters',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'Cochrane & Son Logging',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Colliers International',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Real estate and land valuation',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Combined Contractors',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Compass Agribusiness Management',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Competenz',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Vocational education organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'CooperAitken',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Co-Pilot Accounting',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Coresteel buildings',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Construction and engineering',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Counties Custom Killing',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Cowley Stanich & Co',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'CraigPine timber ltd',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Processing & manufacturing',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Crighton Anderson Property & Infrastructure',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Real estate and land valuation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Crombie lockwood',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Insurance',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'A. None',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Crop Check',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Analytical laboratory',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'CRS Software',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Software and technology supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Crusader Meats New Zealand',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'CRV Ambreed',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Genetics and herd services',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'E. 31 - 100',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Cullen E F & E',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'd’Emden Contracting',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Dairy Companies Association of New Zealand',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'A. None',
    Org_Advisory: 'A. None',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Dairy Cooling Solutions',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Dairy Farm Management Services (DFMS)',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'Dairy Goat Co-operative',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Goat products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Dairy Green',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Construction and engineering',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Dairy Production Systems',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Environmental management and planning',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Dairy Solutionz',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Dairy Team',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'Dairy Training',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Vocational education organisations',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: "Dairy Women's Network",
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'A. None',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'DairyNZ',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'F. 100 - 300',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'DANA',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Darryl Isaac',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Spraying',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Davenport Agricultural Services',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Dawson Agri',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'DB Chartered Accountants',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'DC Equipmnt',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'de Bruin Chartered Accountants',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Deals on Wheels',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'DeepBlue Seafoods NZ',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'Deepwater Group',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Deer Industry New Zealand',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'DeLaval',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Deloitte New Zealand',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Deosan',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Rural supplies retailer',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'Department of Conservation',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Central',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'DGS Accounting',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Diprose Miller',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'DMS',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Orchard management',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Dobsons',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Water, irrigation & effluent',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Don Chapman Waikato',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Construction and engineering',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Downes Services',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Spraying',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'DroneMate',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Software and technology supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Eagle Technology',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Software and technology supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Eastern Fishing',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Eastern Institute of Technology',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Education',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Eastern Institute of Technology (EIT)',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Polytechnics',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Eastpack',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Eastpak',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'Prospa',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Orchard management',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Ecolab',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Water, irrigation & effluent',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Edwards Heeney Consulting (EHC)',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Orchard management',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Eel Enhancement Company',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Ekos',
    Org_MainCategory: 'Public good / NGOs',
    Org_SubCategory: 'Charitable trusts',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'EMNZ',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Fertiliser suppliers',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Ensign',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Enslow onw ltd',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Forest engineering',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Environment Southland Regional Council',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Regional',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Environmet Canterbury Regional Council',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Regional',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'ERNSLAW ONE LTD',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Harvesting',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'ETC',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Rural supplies retailer',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'EveryCow',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Far North District Council',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Local',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'A. None',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Far North Roading Group (FNR)',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Land clearing and forest engineering',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Farm Advisors NZ',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Farm Build',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Construction and engineering',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Farm Chief',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Farm Forestry New Zealand',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Farm Medix',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Other research providers',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Farm Source',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Rural supplies retailer',
    Org_Size_Facing: 'G. 301 - 1000',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Farm Venture',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Farman Turkington Forestry',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Harvesting',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Farmax',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Software and technology supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'FarmIQ',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Software and technology supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Farmlands',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Rural supplies retailer',
    Org_Size_Facing: 'G. 301 - 1000',
    Org_Advisory: 'F. 100 - 300',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'FarmWise',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Farmyard Feeds',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Feed manufacturers and suppliers',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Federated Farmers',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'FeedFlo',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Software and technology supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Fergusson Lockwood & Associates',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Fern Ridge Produce',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Fertco',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Fertiliser suppliers',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Fertiliser Association of New Zealand',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Fidus Management Group',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Field Engineering',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Construction and engineering',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Figured',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Software and technology supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Figured',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Software and technology supplier',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Finance New Zealand',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Findex',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'FinestKind',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Finnz Chartered Accountants',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Fiordland Lobster Company',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'Fish & Game',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Fisheries Inshore New Zealand',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'H. 1,000+',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'FMG',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Insurance',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'FMG Insurance',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Insurance',
    Org_Size_Facing: 'F. 100 - 300',
    Org_Advisory: 'A. None',
    Org_Developers: 'F. 100 - 300',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Fonterra',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Dairy products',
    Org_Size_Facing: 'F. 100 - 300',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'F. 100 - 300',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Forest & Bird',
    Org_MainCategory: 'Public good / NGOs',
    Org_SubCategory: 'Charitable trusts',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Forest 360',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Forest and Bird',
    Org_MainCategory: 'Public good / NGOs',
    Org_SubCategory: 'Charitable trusts',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Forest Cover',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Insurance',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Forest Enterprises',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Forest Floor',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Plant wholesaler and nursery',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Forest Genetics',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Plant wholesaler and nursery',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Forest Industry Contractors Association (FICA)',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Forest Industry Engineering Association',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Forest Industry Safety Council (FISC)',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Forest Management Group',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Forest Management NZ (FMNZ)',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Forest Protection Services (FPS)',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Land clearing and forest engineering',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Forest Services',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Forest STAT',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forestry management and consulting',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Forestry Careers',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'FORESTRY SALES LIMITED',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forestry management and consulting',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Forestry Training NZ',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Vocational education organisations',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'ForestStat',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Forme Consulting',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Formsteel',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Construction and engineering',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Fortus',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Harvesting',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Foundation for Arable Research',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Other research providers',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Franklin Institute of Agri-Technology',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Vocational education organisations',
    Org_Size_Facing: 'A. None',
    Org_Advisory: 'A. None',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Franklin Vets',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Franklin Vets',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Fraser Farm Finance',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Fresh Meats NZ',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Freshmax',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Fruition Horticulture',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Future Cuisine',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'Gabites',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Gallagher',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Rural supplies retailer',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'F. 100 - 300',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Gallie Miles',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Garra International',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat, dairy and seafood products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Gaskell A T',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Genetics Australia NZ',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Genetics and herd services',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'E. 31 - 100',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Geoff Burton Farm Business Management',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'GHA',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Global Veterinary Services',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'GMS',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Golden Bay Fruit',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Gorringes',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Gough Agritech',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Graham Brown & Co',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Greater Wellington Regional Council',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Regional',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Greenlea',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'F. 100 - 300',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Greenlea Premier Meats',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Greenpeace New Zealand',
    Org_MainCategory: 'Public good / NGOs',
    Org_SubCategory: 'Charitable trusts',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Greenwood sheds',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Construction and engineering',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'GrowingNZ',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'A. None',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'GW Accountants',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Gypsum',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Rural supplies retailer',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Halswell Butchery Processing Services',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Hamilton City Council',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Local',
    Org_Size_Facing: 'A. None',
    Org_Advisory: 'A. None',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Hammond & Moir',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Hancock Forest Management NZ',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Harcourts New Zealand',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Real estate and land valuation',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Harkness Henry',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Legal advice',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Harris & Co Chartered Acountants Matamata',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Hart & Associates',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Hauraki District Council',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Local',
    Org_Size_Facing: 'A. None',
    Org_Advisory: 'A. None',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Hauraki Vets',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Hautapu Vet Clinic',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Hawke P J',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: "Hawke's Bay Regional Council",
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Regional',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'HC Partners LP',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Headlands',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Headlands',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Heartland Bank',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Banking',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Henderson Reeves',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Legal advice',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Herman Timber',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Forest products',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Highford Marketing Group',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat and seafood products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Hogans',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Homegrown Farm Fresh Meats',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Hortforce',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Spraying',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Horticentre Group',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Rural supplies retailer',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Hortigro',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Fertiliser suppliers',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Horticulture New Zealand',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Horton & Co',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Hoticulture New Zealand',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Hume',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'i.Agri',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Software and technology supplier',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'ICL Chartered Accountants',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'IFS Growth',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Independent Fisheries',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: 'G. 301 - 1000',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Indufor',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Inforest Training',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Vocational education organisations',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Integrity Soils',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Environmental management and planning',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Integrow Marketing',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Interpine Innovation',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Software and technology supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Intersea',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'Irrigation Express',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Water, irrigation & effluent',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Irrigation NZ',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Irrigation Services',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Water, irrigation & effluent',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'ITM',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Construction and engineering',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Jackson Contracting',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'JB Visser Vets',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'John Dawson - Consulting',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'John Deere New Zealand',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Joma Wool',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Wool products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Jordaan Contracting',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'JP Exports',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Junken New Zealand',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Forest products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Just Avocados',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Kaihu Valley Sawmill',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Forest products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Kaimai Vets Matamata',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Kaipara District Council',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Local',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'A. None',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Kaiser Ag',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'KapAg',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'KauriPak',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Kelly Andrews Veterinary Services',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Kevin Bachada',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Key Industries',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Rural supplies retailer',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Kingfarm Services Products',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Kiwi As',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Kiwi Fertiliser',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Fertiliser suppliers',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Kiwifruit New Zealand',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Kiwifruit Vine Health',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'KiwiLumber',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Processing & manufacturing',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Kohntrol forest services ltd',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Whole business',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Komatsu New Zealand',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'KPMG New Zealand',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'L & B Taspac',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'Lake Taupo Forest Trust',
    Org_MainCategory: 'Public good / NGOs',
    Org_SubCategory: 'Charitable trusts',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Laminata Group',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Forest products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Land and Forest consultants',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forestry management and consulting',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Land Clearing Solutions NZ',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Land clearing and forest engineering',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Landcare Research',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Crown Research Institutes',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'A. None',
    Org_Developers: 'E. 31 - 100',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Landco Fertiliser',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Fertiliser suppliers',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'LandPrepNZ',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Land clearing and forest engineering',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Landpro Ltd',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Environmental management and planning',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'LandVision',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Environmental management and planning',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Laurie Forestry',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Law North Lawyers',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Legal advice',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Lawson Accounting',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Lay Associates',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Lee Fish',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Lincoln Univeristy',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Universities',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Lincoln University',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Education',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'LinkSafe',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Health, safety & wellbeing',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Litchfields Agricultural Services',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Livestock Feed Systems',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Livestock Improvement Corporation (LIC)',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Genetics and herd services',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'G. 301 - 1000',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Logic Forest Solutions',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'LumberLink',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Forest products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Lynwood Avocado Nursery',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Plant wholesaler and nursery',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Macfarlane Rural Business',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Mackrell Murcott Chartered Accountants',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Magon Contracting',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Land clearing and forest engineering',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Mahoe Sawmills',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Forest products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Manak Contracting',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Horizons Regional Council',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Regional',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Maori Kiwifruit Growers Incorporated',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Marconee Seafoods',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'Margules Groome',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forestry management and consulting',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Marine Foods',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Maritime New Zealand',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Central',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Mark Gorringe',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Land clearing and forest engineering',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Mascus',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Massey University',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Universities',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Massey University Farm Services Clinic',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Matamata-Piako District Council',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Local',
    Org_Size_Facing: 'A. None',
    Org_Advisory: 'A. None',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'MATARIKI FORESTRY GROUP',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forestry management and consulting',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Max Birt Sawmills',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Forest products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'MBS Advisors',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'MBS Advisors',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'McAlpines',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Processing & manufacturing',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'McIntyre Dick',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Meat Industry Association of New Zealand (MIA)',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Meat Industry Association of New Zealand Inc (MIA)',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Merrill and Ring NZ',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Milkio',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Dairy products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'A. None',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'MilkMap',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'Ministry for Primary Industries (MPI)',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Central',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'E. 31 - 100',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Ministry for the Environment',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Central',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'A. None',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Miraka',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Dairy products',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'A. None',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Mosduvin Forest',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Mount Pack & Cool (Mpac)',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Mr Apple',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Mt Pokaka Timber Products',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Forest products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Murray Jamieson',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'N J SIM Forestry Services',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'N.Z. Future Forest Products Ltd',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Processing & manufacturing',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'National Institute of Water and Atmospheric Research (NIWA)',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Crown Research Institutes',
    Org_Size_Facing: 'G. 301 - 1000',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Native Solutions',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Silviculture and planting',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Nelson / Marlborough Institute of Technology (NMIT)',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Polytechnics',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Nelsons forests lts and Nelson forest management ltd',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Processing & manufacturing',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Neven Granich',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Neville Marsh',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'New Holland Agriculture',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'New Zealand Apples and Pears',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'New Zealand Coastal Seafoods',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'New Zealand Dryland Initiative',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Ecosystems',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'New Zealand Forest Owners Association',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'New Zealand Forest Sales',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Real estate and land valuation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'New Zealand Institute of Forestry',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'New Zealand Insurance (NZI)',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Insurance',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'A. None',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'New Zealand Kiwifruit Growers Incorporated (NZKGI)',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'New Zealand Meat Board',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'New Zealand Pork',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'New Zealand Veterinary Association',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Ngai Tahu Seafood',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'Ngati Hine Forestry Trust',
    Org_MainCategory: 'Public good / NGOs',
    Org_SubCategory: 'Charitable trusts',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Niagara',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Processing & manufacturing',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Noone Plus',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Norris Ward McKinnon',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Legal advice',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'North Canterbury Veterinary Clinics',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Northern Forest Products',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Forestry management services',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Northland Farm Services',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Northland Forest Managers (NFM)',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Northland Forest Nursery',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Plant wholesaler and nursery',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Northland Regional Council',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Regional',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Northland Timber Co',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Forest products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Northland Wood Council (NWC)',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Northpine',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Forest products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'NorthTec',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Polytechnics',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Norwood',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'NRM',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Feed manufacturers and suppliers',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Nutrinza',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Feed manufacturers and suppliers',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'NZ Agri Brokers (NZAB)',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Banking',
    Org_Size_Facing: 'F. 100 - 300',
    Org_Advisory: 'F. 100 - 300',
    Org_Developers: 'E. 31 - 100',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'NZ Avocado Industry',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'NZ Bioforestry',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Processing & manufacturing',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'NZ Carbon Farming',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Carbon forestry',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'NZ Farm Management',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'NZ Federation of Commercial Fishermen',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'NZ Fish Fed (NZFCF)',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'NZ Forestry',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Carbon forestry',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'NZ Forestry',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Forestry management services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'NZ Forestry',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Land clearing and forest engineering',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'NZ Forestry',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'NZ Forestry and Land Investments ltd',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Financial / banking',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'NZ Institute of Primary Industry Management (NZIPIM)',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'A. None',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'NZ Landcare Trust',
    Org_MainCategory: 'Public good / NGOs',
    Org_SubCategory: 'Charitable trusts',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'NZ Rock Lobster Industry Council',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'New Zealand Wool Services International',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Wool products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'NZ Young Farmers',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'NZbrokers Management',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Insurance',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'A. None',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Ocean Law New Zealand',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Legal advice',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Oceania Dairy',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Dairy products',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'A. None',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Oceans North Seafood',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'OHTL',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Spraying',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Omega Capital',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Banking',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'E. 31 - 100',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'OP Columbia (OPC)',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'Open Country Dairy',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Dairy products',
    Org_Size_Facing: 'F. 100 - 300',
    Org_Advisory: 'A. None',
    Org_Developers: 'F. 100 - 300',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Opihi Nurseries',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Plant wholesaler and nursery',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Opotiki Packaging and Coolstorage (Opac)',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Oraka Veterinary Solutions',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Orangewood',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Ordish & Stevens',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Organic Ag',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Environmental management and planning',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Organics Aotearoa New Zealand',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'A. None',
    Org_Advisory: 'A. None',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'OSP Stock Feeds',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Feed manufacturers and suppliers',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'OSPRI',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Otago Polytechnic',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Polytechnics',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Otago Regional Council',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Regional',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Otago University',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Universities',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Ovation New Zealand',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Over the Moon Dairy',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Dairy products',
    Org_Size_Facing: 'A. None',
    Org_Advisory: 'A. None',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Overseer',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Software and technology supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Ovis Management',
    Org_MainCategory: 'Public good / NGOs',
    Org_SubCategory: 'Charitable trusts',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Pan Pac forest products ltd',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Processing & manufacturing',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Pango',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Permanent Forests NZ',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Carbon forestry',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Perrin Ag Consultants',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Peter Horticultural Consultants',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Orchard management',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'PF Olsen',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'PGG Wrightson',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Rural supplies retailer',
    Org_Size_Facing: 'G. 301 - 1000',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'PGG Wrightson',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Water, irrigation & effluent',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'PGG Wrightson',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Rural supplies retailer',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'PGG Wrightson',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Rural supplies retailer',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'Pick Mee',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Pine sawmills New Zealand ltd',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Processing & manufacturing',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Pinepac',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: '',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Pioneer',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Feed manufacturers and suppliers',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'PKF Hamilton',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'PKF Rutherfords',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Plant and Food Research',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Crown Research Institutes',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'A. None',
    Org_Developers: 'F. 100 - 300',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Point Timber Ruakaka',
    Org_MainCategory: '',
    Org_SubCategory: '',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'A. None',
    Org_Developers: 'A. None',
    Advice_Charge: '',
  },
  {
    Org_Name: 'Primacy',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Financial / banking',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Primary Industry Capability Alliance',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Primary ITO',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Vocational education organisations',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'E. 31 - 100',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Prime Range Meats',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Process Vegetables NZ (PVNZ)',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Produco',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Health, safety & wellbeing',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Professional Farm Services',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Professional Harvesting Systems (PHS)',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Profruit',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Progressive Meats',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Prospa',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Orchard management',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Provernance',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Pukenui Western Hills Forest Charitable Trust',
    Org_MainCategory: 'Public good / NGOs',
    Org_SubCategory: 'Charitable trusts',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Puketi Forest Trust',
    Org_MainCategory: 'Public good / NGOs',
    Org_SubCategory: 'Charitable trusts',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Punchbowl Packaging',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'PwC New Zealand',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Quality Consultants New Zealand (QCONZ)',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Software and technology supplier',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Quality Consultants New Zealand (QCONZ)',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Environmental management and planning',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Quality Consultants New Zealand (QCONZ) - Training',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Vocational education organisations',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Rabobank NZ',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Banking',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'E. 31 - 100',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Rainer Irrigation',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Water, irrigation & effluent',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Ravensdown - Rural supplies',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Fertiliser suppliers',
    Org_Size_Facing: 'F. 100 - 300',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Ravensdown Environmental',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Environmental management and planning',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Rayonier Matariki Forests',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Red Meat Profit Partnership',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'REL Group',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Construction and engineering',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Retail Meat New Zealand',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Rezare Systems',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Software and technology supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Ribbonwood New Zealand',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Forestry management services',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'RIVAL Wealth',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Riverlock',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'RJ Preston',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Roger Dicker',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Financial / banking',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Roots, Shoots & Fruits',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Fertiliser suppliers',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Rosvall Sawmill',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Forest products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Roy Johnson - R J Rural',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Rural Business solutions',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Rural Contractors New Zealand (RCNZ)',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Rural Solutions',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Rural Support Trust',
    Org_MainCategory: 'Public good / NGOs',
    Org_SubCategory: 'Charitable trusts',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'E. 31 - 100',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Rural Women NZ (RWNZ)',
    Org_MainCategory: 'Public good / NGOs',
    Org_SubCategory: 'Charitable trusts',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'RVC vet centre',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'RXP',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Water, irrigation & effluent',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Rydgeview Mowing & Mulching Services',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Spraying',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Sabre construction',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Construction and engineering',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'SAFE for Animals',
    Org_MainCategory: 'Public good / NGOs',
    Org_SubCategory: 'Charitable trusts',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Safetree',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Health, safety & wellbeing',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Samen NZ',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Genetics and herd services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Sanford',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: 'G. 301 - 1000',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Scion',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Crown Research Institutes',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'E. 31 - 100',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Seafood Innovations',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Other research providers',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Seafood New Zealand',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Seales Winslow',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Feed manufacturers and suppliers',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Sealord',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: 'G. 301 - 1000',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Seeka',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Selwyn Rakaia Vet services',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Semex NZ',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Genetics and herd services',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'E. 31 - 100',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Sharpes Stock Feeds',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Feed manufacturers and suppliers',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Sheppard Agriculture',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Silver Fern Farms',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'F. 100 - 300',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Silver Fern Farms',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Silvicom',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Silviculture and planting',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Simpson Grierson',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Legal advice',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Slattery Contracting',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Smart Farm Software',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Software and technology supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: "Smith's Meat Company",
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Society of Sheep and Beef Cattle Veterinarians',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Solander',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'South Pacific Meats',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'South pine',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Processing & manufacturing',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'South Waikato District Council',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Local',
    Org_Size_Facing: 'A. None',
    Org_Advisory: 'A. None',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'South Waikato Veterinary Services',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'South Wairarapa Veterinary Services',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Southern Clams',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'Southern Inshore Fisheries Management Company',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Southern Institute of Technology (SIT)',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Polytechnics',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Southern produce',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Southfields group',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Whole business',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Southfish',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'Specialised structures',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Construction and engineering',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Spicer Accounting',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Spray-tec Consultants',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Spraying',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'SRS',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: '',
    Org_Size_Facing: 'F. 100 - 300',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'SRVS',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'ST genetics',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Genetics and herd services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'State Insurance',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Insurance',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'A. None',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Suisted R K',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Summit Forests',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Forest products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Sumpter Baughen Chartered Accountants',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Sustainable Forest Management NZ (SFM)',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Forestry management services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Swap Stockfoods',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Feed manufacturers and suppliers',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Symbiosis Tree Services',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Forestry management services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Synlait',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Dairy products',
    Org_Size_Facing: 'F. 100 - 300',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'T.A.M.L Forestry',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Tai Poutini Polytechnic',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Polytechnics',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Taitokerau Maori Forests Inc',
    Org_MainCategory: 'Public good / NGOs',
    Org_SubCategory: 'Charitable trusts',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Takanini Feeds',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Feed manufacturers and suppliers',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: "Talley's Group",
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: 'G. 301 - 1000',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Tanes Tree Trust',
    Org_MainCategory: 'Public good / NGOs',
    Org_SubCategory: 'Charitable trusts',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Taranaki Regional Council',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Regional',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Target Focus',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Health, safety & wellbeing',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'TasmanCrop',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Rural supplies retailer',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Tatua',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Dairy products',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'A. None',
    Org_Developers: 'E. 31 - 100',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Taupo Beef and Lamb',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Taylor Preston',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Te Ohu Kaimoana',
    Org_MainCategory: 'Public good / NGOs',
    Org_SubCategory: 'Māori trust',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Te Uru Rakau (Forestry New Zealand)',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Central',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Te Wai Maori',
    Org_MainCategory: 'Public good / NGOs',
    Org_SubCategory: 'Māori trust',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Te Wananga o Aotearoa',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Vocational education organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Tenco',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Forest products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Terra Cat',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'TerraCare',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Fertiliser suppliers',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Tertiary Education Commission (TEC)',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Central',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'A. None',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Thames-Coromandel District Council',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Local',
    Org_Size_Facing: 'A. None',
    Org_Advisory: 'A. None',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'The AgriBusiness Group',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'The Cow Vets',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'The Federated Farmers Meat & Wool Industry Group',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'The Federated Farmers Rural Butchers Industry Group',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'The Forest School',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Education',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'The New Zealand Redwood Company',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Foresters',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'The Open Polytechnic of New Zealand',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Polytechnics',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'The Paua Industry Council',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'The Poultry Industry Association of New Zealand',
    Org_MainCategory: 'Industry good organisations',
    Org_SubCategory: 'Industry good organisations',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'The Practice Consultants',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'The Vet Centre',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'The Village Press',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'A. None',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'The Waikato Institute of Technology (Wintec)',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Vocational education organisations',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Thompson Wilson Law',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Legal advice',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: "Thompson's Horticulture",
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Orchard management',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Timberlands',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forestry management and consulting',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'TimberSmart',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Software and technology supplier',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'TK Natural Lamb',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Toi Ohomai Institute of Technology',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Polytechnics',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: "Tomorrow's Forests",
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Tompkins Wake',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Legal advice',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Total Ag',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Totally Vets',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Totally Vets',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Tower Insurance',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Insurance',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'A. None',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Treescape',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Forest engineering',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'TreeTech Ltd',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Forest engineering',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Treewise',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Forest engineering',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: "Trevelyan's",
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'TSB',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Banking',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'E. 31 - 100',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Two Mulch Services',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Mulching',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'UCOL - Universal College of Learning',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Polytechnics',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'UHY Haines Norton',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'United Fisheries',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'University of Canterbury',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Universities',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'University of Waikato',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Universities',
    Org_Size_Facing: 'A. None',
    Org_Advisory: 'A. None',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Urban Plus Weedsprayers',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Spraying',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Vela Fishing',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Venture forestry',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forestry management and consulting',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Vero Insurance',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Insurance',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'A. None',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: "Vet services Hawke's Bay",
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Vet Services Wairarapa',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Vet South',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'VetEnt',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'VetEnt',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Veterinary Centre',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Vetora',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'VetPlus',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Waikato District Council',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Local',
    Org_Size_Facing: 'A. None',
    Org_Advisory: 'A. None',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Waikato Milking Systems',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Machinery and infrastructure supplier',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Waikato Regional Council',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Regional',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'A. None',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Waikato Shed Company',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Construction and engineering',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Waikawa Fishing Company',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Waipa District Council',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Local',
    Org_Size_Facing: 'A. None',
    Org_Advisory: 'A. None',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Waitomo District Council',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Local',
    Org_Size_Facing: 'A. None',
    Org_Advisory: 'A. None',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Walling Contracting',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Contractor - Harvesting and cultivation',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Wally Smith',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Walton Farm Consulting',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Warren Forestry',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Financial / banking',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'WaterForce',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Water, irrigation & effluent',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'WealthDesign',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Wenita Forest Products',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Harvesting',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Were & Associates',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Agribusiness and farm systems',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'West Coast Regional Council',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Regional',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'West coast vets',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Westco lumber limited',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Processing & manufacturing',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Western Insitute of Technology',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Education',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Westfleet Seafoods',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'Westland Milk Products',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Dairy products',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'A. None',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Westpac Mussels Distributors',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'Westpac New Zealand',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Banking',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'E. 31 - 100',
    Org_Developers: 'E. 31 - 100',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Whangarei District Council',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Local',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'A. None',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Wildfish Export NZ',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Seafood products',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: '',
  },
  {
    Org_Name: 'Wilding Wood Management',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Forest engineering',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Windy Meat Processing',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Red meat products',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Winston Stock Feed',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Feed manufacturers and suppliers',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'WINTEC',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Education',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Wintec - Waikato Institute of Technology',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Polytechnics',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'WITT - Western Institute of Technology at Taranaki',
    Org_MainCategory: 'Research and education',
    Org_SubCategory: 'Polytechnics',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'C. 4-10',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Woodbank',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Forestry management services',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Woodlands Pacific Consulting',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forest management',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Woodmarketing services ltd',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forestry management and consulting',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Woodmetrics',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Forestry management and consulting',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'WorkSafe New Zealand',
    Org_MainCategory: 'Government',
    Org_SubCategory: 'Central',
    Org_Size_Facing: 'B. 1-3',
    Org_Advisory: 'B. 1-3',
    Org_Developers: 'B. 1-3',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Workwise',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'WPI International',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Processing & manufacturing',
    Org_Size_Facing: '',
    Org_Advisory: '',
    Org_Developers: '',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'WSP',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Environmental management and planning',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Wynn Williams',
    Org_MainCategory: 'Independent advisers and consultants',
    Org_SubCategory: 'Legal advice',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'A. Hourly / daily',
  },
  {
    Org_Name: 'Xero',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Software and technology supplier',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Yovich Hayward Pevats Johnston',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Accountancy and financial services',
    Org_Size_Facing: 'J. ???',
    Org_Advisory: 'J. ???',
    Org_Developers: 'J. ???',
    Advice_Charge: 'B. In service / product cost',
  },
  {
    Org_Name: 'Yummy Fruit',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Zeafruit',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'C. 4-10',
    Org_Advisory: 'C. 4-10',
    Org_Developers: 'A. None',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Zespri International',
    Org_MainCategory: 'Processors and exporters',
    Org_SubCategory: 'Fruit and veg products',
    Org_Size_Facing: 'E. 31 - 100',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'D. 11 - 30',
    Advice_Charge: 'D. NA',
  },
  {
    Org_Name: 'Animal Breeding Services',
    Org_MainCategory: 'Business services and sales',
    Org_SubCategory: 'Animal care',
    Org_Size_Facing: 'D. 11 - 30',
    Org_Advisory: 'D. 11 - 30',
    Org_Developers: 'A. None',
    Advice_Charge: 'B. In service / product cost',
  },
]

export default companies

const companyNames = [
  {
    Org_Name: 'Org_Name',
  },
  {
    Org_Name: 'PwC New Zealand',
  },
  {
    Org_Name: 'Deloitte New Zealand',
  },
  {
    Org_Name: 'KPMG New Zealand',
  },
  {
    Org_Name: 'Heartland Bank',
  },
  {
    Org_Name: 'Westpac New Zealand',
  },
  {
    Org_Name: 'Rabobank NZ',
  },
  {
    Org_Name: 'ASB ',
  },
  {
    Org_Name: 'ANZ',
  },
  {
    Org_Name: 'Aon NZ',
  },
  {
    Org_Name: 'Eagle Technology',
  },
  {
    Org_Name: 'Xero',
  },
  {
    Org_Name: 'WorkSafe New Zealand',
  },
  {
    Org_Name: 'New Zealand Qualifications Authority (NZQA)',
  },
  {
    Org_Name: 'Tertiary Education Commission (TEC)',
  },
  {
    Org_Name: 'Ministry for the Environment',
  },
  {
    Org_Name: 'Ministry for Primary Industries (MPI)',
  },
  {
    Org_Name: 'Department of Conservation ',
  },
  {
    Org_Name: 'Far North District Council',
  },
  {
    Org_Name: 'Kaipara District Council',
  },
  {
    Org_Name: 'Whangarei District Council',
  },
  {
    Org_Name: 'Waikato Regional Council',
  },
  {
    Org_Name: 'Environment Southland Regional Council',
  },
  {
    Org_Name: 'Northland Regional Council',
  },
  {
    Org_Name: 'LandVision',
  },
  {
    Org_Name: 'Simpson Grierson',
  },
  {
    Org_Name: 'GrowingNZ',
  },
  {
    Org_Name: 'Organics Aotearoa New Zealand',
  },
  {
    Org_Name: 'Forest and Bird',
  },
  {
    Org_Name: 'Greenpeace New Zealand',
  },
  {
    Org_Name: 'Rural Support Trust',
  },
  {
    Org_Name: 'Callaghan Innovation',
  },
  {
    Org_Name: 'University of Waikato',
  },
  {
    Org_Name: 'NorthTec',
  },
  {
    Org_Name: 'Toi Ohomai Institute of Technology',
  },
  {
    Org_Name: 'Primary ITO',
  },
  {
    Org_Name: 'The AgriBusiness Group',
  },
  {
    Org_Name: 'Irrigation NZ',
  },
  {
    Org_Name: 'Plant and Food Research',
  },
  {
    Org_Name: 'Crighton Anderson Property & Infrastructure',
  },
  {
    Org_Name: 'Wynn Williams',
  },
  {
    Org_Name: 'Competenz ',
  },
  {
    Org_Name: 'Energy Efficiency and Conservation Authority (EECA)',
  },
  {
    Org_Name: 'Garra International ',
  },
  {
    Org_Name: 'SAFE for Animals',
  },
  {
    Org_Name: 'Highford Marketing Group',
  },
  {
    Org_Name: 'FishServe',
  },
  {
    Org_Name: 'FishServe Innovations New Zealand (FINNZ)',
  },
  {
    Org_Name: 'Maritime NZ',
  },
  {
    Org_Name: 'Ocean Law',
  },
  {
    Org_Name: 'The Paua Industry Council ',
  },
  {
    Org_Name: 'Southern Inshore Fisheries Management Company',
  },
  {
    Org_Name: 'Aquaculture New Zealand',
  },
  {
    Org_Name: 'NZ Fish Fed (NZFCF)',
  },
  {
    Org_Name: 'Seafood New Zealand ',
  },
  {
    Org_Name: 'Fisheries Inshore New Zealand',
  },
  {
    Org_Name: 'Deepwater Group ',
  },
  {
    Org_Name: 'NZ Rock Lobster Industry Council',
  },
  {
    Org_Name: 'Kina Industry Council ',
  },
  {
    Org_Name: 'Eel Enhancement Co (EECo)',
  },
  {
    Org_Name: 'Marine Foods',
  },
  {
    Org_Name: 'Waikawa ',
  },
  {
    Org_Name: 'Lee Fish ',
  },
  {
    Org_Name: 'Eastern Fishing ',
  },
  {
    Org_Name: 'Vela fishing ',
  },
  {
    Org_Name: 'New Zealand Coastal Seafoods ',
  },
  {
    Org_Name: 'Sealord',
  },
  {
    Org_Name: 'Sanford',
  },
  {
    Org_Name: 'Independent Fisheries ',
  },
  {
    Org_Name: "Talley's ",
  },
  {
    Org_Name: 'Canterbury Seafoods ',
  },
  {
    Org_Name: 'Solander',
  },
  {
    Org_Name: 'Bell Global ',
  },
  {
    Org_Name: 'Te Wai Maori ',
  },
  {
    Org_Name: 'Te Ohu Kaimoana',
  },
  {
    Org_Name: 'National Institute of Water and Atmospheric Research (NIWA)',
  },
  {
    Org_Name: 'Cawthron Institute',
  },
  {
    Org_Name: 'Seafood Innovations ',
  },
  {
    Org_Name: 'de Bruin Chartered Accountants',
  },
  {
    Org_Name: 'Finance New Zealand ',
  },
  {
    Org_Name: 'GHA',
  },
  {
    Org_Name: 'BDO Northland',
  },
  {
    Org_Name: 'BDO New Zealand',
  },
  {
    Org_Name: 'AsureQuality ',
  },
  {
    Org_Name: 'Omega Capital',
  },
  {
    Org_Name: 'TSB',
  },
  {
    Org_Name: 'BNZ',
  },
  {
    Org_Name: 'Vero Insurance',
  },
  {
    Org_Name: 'AMP',
  },
  {
    Org_Name: 'New Zealand Insurance (NZI)',
  },
  {
    Org_Name: 'NZbrokers Management',
  },
  {
    Org_Name: 'FMG Insurance',
  },
  {
    Org_Name: 'Crombie lockwood',
  },
  {
    Org_Name: 'AMI Insurance',
  },
  {
    Org_Name: 'State Insurance',
  },
  {
    Org_Name: 'Tower Insurance',
  },
  {
    Org_Name: 'Deals on Wheels',
  },
  {
    Org_Name: 'Mascus',
  },
  {
    Org_Name: 'AB equipment',
  },
  {
    Org_Name: 'Norwood',
  },
  {
    Org_Name: 'Terra Cat',
  },
  {
    Org_Name: 'John Deere New Zealand',
  },
  {
    Org_Name: 'Colliers International ',
  },
  {
    Org_Name: 'Harcourts New Zealand',
  },
  {
    Org_Name: 'Bayleys Real Estate',
  },
  {
    Org_Name: 'PGG Wrightson',
  },
  {
    Org_Name: 'Gallagher',
  },
  {
    Org_Name: 'DroneMate',
  },
  {
    Org_Name: 'Waipa District Council',
  },
  {
    Org_Name: 'Thames-Coromandel District Council',
  },
  {
    Org_Name: 'Waitomo District Council',
  },
  {
    Org_Name: 'Hauraki District Council',
  },
  {
    Org_Name: 'Matamata-Piako District Council',
  },
  {
    Org_Name: 'South Waikato District Council',
  },
  {
    Org_Name: 'Waikato District Council',
  },
  {
    Org_Name: 'Hamilton City Council',
  },
  {
    Org_Name: 'Produco',
  },
  {
    Org_Name: 'Thompson Wilson Law',
  },
  {
    Org_Name: 'NZ Institute of Primary Industry Management (NZIPIM)',
  },
  {
    Org_Name: 'NZ Landcare Trust',
  },
  {
    Org_Name: 'Landcare Research',
  },
  {
    Org_Name: 'Massey University',
  },
  {
    Org_Name: 'Te Wananga o Aotearoa',
  },
  {
    Org_Name: 'Harris & Co Chartered Acountants Matamata',
  },
  {
    Org_Name: 'AgBiz Accountants',
  },
  {
    Org_Name: 'Brown Webb Richardson (BWR)',
  },
  {
    Org_Name: 'Analytical Research Laboratories',
  },
  {
    Org_Name: 'SRVS ',
  },
  {
    Org_Name: 'The Vet Centre ',
  },
  {
    Org_Name: 'Vet Services Wairarapa ',
  },
  {
    Org_Name: 'West coast vets ',
  },
  {
    Org_Name: 'North Canterbury Veterinary Clinics ',
  },
  {
    Org_Name: 'Vetent ',
  },
  {
    Org_Name: 'Vet South ',
  },
  {
    Org_Name: "Vet services Hawke's Bay ",
  },
  {
    Org_Name: 'RVC vet centre ',
  },
  {
    Org_Name: 'Totally Vets ',
  },
  {
    Org_Name: 'NZ Agri Brokers (NZAB)',
  },
  {
    Org_Name: 'Farm Build',
  },
  {
    Org_Name: 'Waikato Shed Company',
  },
  {
    Org_Name: 'Greenwood sheds',
  },
  {
    Org_Name: 'Alpine Buildings NZ',
  },
  {
    Org_Name: 'Coresteel buildings',
  },
  {
    Org_Name: 'Specialised structures',
  },
  {
    Org_Name: 'Formsteel',
  },
  {
    Org_Name: 'Sabre construction',
  },
  {
    Org_Name: 'ITM',
  },
  {
    Org_Name: 'Davenport Agricultural Services',
  },
  {
    Org_Name: 'Arrow Commodities (NZ)',
  },
  {
    Org_Name: 'Agrifeeds',
  },
  {
    Org_Name: 'Roots, Shoots & Fruits',
  },
  {
    Org_Name: 'EMNZ',
  },
  {
    Org_Name: 'Fertco',
  },
  {
    Org_Name: 'TerraCare',
  },
  {
    Org_Name: 'Kiwi Fertiliser',
  },
  {
    Org_Name: 'Agrisea',
  },
  {
    Org_Name: 'Ravensdown - Rural supplies',
  },
  {
    Org_Name: 'Ballance Agri-Nutrients',
  },
  {
    Org_Name: 'Professional Farm Services',
  },
  {
    Org_Name: 'Ordish & Stevens',
  },
  {
    Org_Name: 'Kingfarm Services Products',
  },
  {
    Org_Name: 'Farm Chief',
  },
  {
    Org_Name: 'New Holland Agriculture',
  },
  {
    Org_Name: 'Carrfields',
  },
  {
    Org_Name: 'Gypsum',
  },
  {
    Org_Name: 'Farm Source',
  },
  {
    Org_Name: 'Farmlands',
  },
  {
    Org_Name: 'i.Agri',
  },
  {
    Org_Name: 'Overseer',
  },
  {
    Org_Name: 'AgRecord',
  },
  {
    Org_Name: 'Figured',
  },
  {
    Org_Name: 'AgriSmart',
  },
  {
    Org_Name: 'RXP',
  },
  {
    Org_Name: 'Irrigation Services',
  },
  {
    Org_Name: 'Rainer Irrigation',
  },
  {
    Org_Name: 'PGG Wrightson',
  },
  {
    Org_Name: 'Irrigation Express',
  },
  {
    Org_Name: 'Dobsons',
  },
  {
    Org_Name: 'WaterForce',
  },
  {
    Org_Name: 'John Dawson - Consulting',
  },
  {
    Org_Name: 'Total Ag',
  },
  {
    Org_Name: 'Were & Associates',
  },
  {
    Org_Name: 'Agricultural Business Associates',
  },
  {
    Org_Name: 'Fidus Management Group',
  },
  {
    Org_Name: 'Allan Agricultural Consulting',
  },
  {
    Org_Name: 'Allan Agricultural Consulting ',
  },
  {
    Org_Name: 'Fergusson Lockwood & Associates',
  },
  {
    Org_Name: 'AgriMagic',
  },
  {
    Org_Name: 'BakerAg',
  },
  {
    Org_Name: 'Headlands',
  },
  {
    Org_Name: 'Compass Agribusiness Management',
  },
  {
    Org_Name: 'Perrin Ag Consultants',
  },
  {
    Org_Name: 'AgFirst',
  },
  {
    Org_Name: 'Integrity Soils',
  },
  {
    Org_Name: 'AgDesign',
  },
  {
    Org_Name: 'AgVice',
  },
  {
    Org_Name: 'AgKnowledge',
  },
  {
    Org_Name: 'Ravensdown Environmental',
  },
  {
    Org_Name: 'Ballance Agri-Nutrients',
  },
  {
    Org_Name: 'WSP',
  },
  {
    Org_Name: "Agri-Women's Development Trust",
  },
  {
    Org_Name: 'Fertiliser Association of New Zealand',
  },
  {
    Org_Name: 'AgResearch',
  },
  {
    Org_Name: 'Franklin Institute of Agri-Technology',
  },
  {
    Org_Name: 'The Waikato Institute of Technology (Wintec)',
  },
  {
    Org_Name: 'Meat Industry Association of New Zealand Inc (MIA)',
  },
  {
    Org_Name: 'Crop Check',
  },
  {
    Org_Name: 'd’Emden Contracting',
  },
  {
    Org_Name: 'Cullen E F & E',
  },
  {
    Org_Name: 'Kevin Bachada',
  },
  {
    Org_Name: 'Suisted R K',
  },
  {
    Org_Name: 'Gaskell A T',
  },
  {
    Org_Name: 'Cameron Ag',
  },
  {
    Org_Name: 'Gorringes',
  },
  {
    Org_Name: 'Hawke P J',
  },
  {
    Org_Name: "Capper's Contracting",
  },
  {
    Org_Name: 'Dawson Agri',
  },
  {
    Org_Name: 'Manak Contracting',
  },
  {
    Org_Name: 'Neville Marsh',
  },
  {
    Org_Name: 'Slattery Contracting',
  },
  {
    Org_Name: 'Neven Granich',
  },
  {
    Org_Name: 'Walling Contracting',
  },
  {
    Org_Name: 'Kaiser Ag',
  },
  {
    Org_Name: 'A1 Orchard Spreading',
  },
  {
    Org_Name: 'Jackson Contracting',
  },
  {
    Org_Name: 'Litchfields Agricultural Services',
  },
  {
    Org_Name: 'Combined Contractors',
  },
  {
    Org_Name: 'Bluegrass',
  },
  {
    Org_Name: 'Jordaan Contracting',
  },
  {
    Org_Name: 'Two Mulch Services',
  },
  {
    Org_Name: 'Bradstreet Contractors',
  },
  {
    Org_Name: 'Beaumont Shelter Trimming & Mulching',
  },
  {
    Org_Name: 'Downes Services',
  },
  {
    Org_Name: 'Urban Plus Weedsprayers',
  },
  {
    Org_Name: 'OHTL',
  },
  {
    Org_Name: 'Darryl Isaac',
  },
  {
    Org_Name: 'Spray-tec Consultants',
  },
  {
    Org_Name: 'Air8tors',
  },
  {
    Org_Name: 'Hortforce',
  },
  {
    Org_Name: 'Avomax',
  },
  {
    Org_Name: 'Rydgeview Mowing & Mulching Services',
  },
  {
    Org_Name: 'Hortigro',
  },
  {
    Org_Name: 'Bear Cat New Zealand',
  },
  {
    Org_Name: 'AvoJect',
  },
  {
    Org_Name: 'Opihi Nurseries',
  },
  {
    Org_Name: 'Lynwood Avocado Nursery',
  },
  {
    Org_Name: 'TasmanCrop',
  },
  {
    Org_Name: 'ETC',
  },
  {
    Org_Name: 'Key Industries',
  },
  {
    Org_Name: 'Horticentre Group',
  },
  {
    Org_Name: 'Fruition Horticulture',
  },
  {
    Org_Name: 'Peter Horticultural Consultants',
  },
  {
    Org_Name: 'Bio Soil & Crop',
  },
  {
    Org_Name: 'Avo Alderton',
  },
  {
    Org_Name: 'Edwards Heeney Consulting (EHC)',
  },
  {
    Org_Name: "Thompson's Horticulture",
  },
  {
    Org_Name: 'Prospa',
  },
  {
    Org_Name: 'Rural Contractors New Zealand (RCNZ)',
  },
  {
    Org_Name: 'Apples & Pears',
  },
  {
    Org_Name: 'NZ Avocado Industry ',
  },
  {
    Org_Name: 'New Zealand Kiwifruit Growers Incorporated (NZKGI)',
  },
  {
    Org_Name: 'Maori Kiwifruit Growers Incorporated',
  },
  {
    Org_Name: 'Southern produce',
  },
  {
    Org_Name: 'AVOCO',
  },
  {
    Org_Name: 'The Village Press',
  },
  {
    Org_Name: 'Integrow Marketing',
  },
  {
    Org_Name: 'Profruit',
  },
  {
    Org_Name: 'Pick Mee',
  },
  {
    Org_Name: 'Zeafruit',
  },
  {
    Org_Name: 'Golden Bay Fruit',
  },
  {
    Org_Name: 'Riverlock',
  },
  {
    Org_Name: 'Fern Ridge Produce',
  },
  {
    Org_Name: 'JP Exports',
  },
  {
    Org_Name: 'KauriPak',
  },
  {
    Org_Name: 'GMS',
  },
  {
    Org_Name: 'Apata',
  },
  {
    Org_Name: 'Aongatete Avocados',
  },
  {
    Org_Name: "Trevelyan's",
  },
  {
    Org_Name: 'Just Avocados',
  },
  {
    Org_Name: 'Punchbowl Packaging',
  },
  {
    Org_Name: 'Brichwood',
  },
  {
    Org_Name: 'Orangewood',
  },
  {
    Org_Name: 'Mount Pack & Cool (Mpac)',
  },
  {
    Org_Name: 'Auckland Pack & Cool (Apac)',
  },
  {
    Org_Name: 'Opotiki Packaging and Coolstorage (Opac)',
  },
  {
    Org_Name: 'Hume',
  },
  {
    Org_Name: 'Freshmax',
  },
  {
    Org_Name: 'Yummy Fruit',
  },
  {
    Org_Name: 'Eastpack',
  },
  {
    Org_Name: 'Seeka',
  },
  {
    Org_Name: 'Mr Apple',
  },
  {
    Org_Name: 'AON New Zealand',
  },
  {
    Org_Name: 'Inforest Training',
  },
  {
    Org_Name: 'Catapult Partners Chartered Accountants',
  },
  {
    Org_Name: 'Forest Enterprises ',
  },
  {
    Org_Name: 'Blackburne Group',
  },
  {
    Org_Name: 'Aerial Surveys',
  },
  {
    Org_Name: 'NZ Carbon Farming ',
  },
  {
    Org_Name: 'Carbon Forest Services',
  },
  {
    Org_Name: 'NZ Forestry',
  },
  {
    Org_Name: 'Permanent Forests NZ',
  },
  {
    Org_Name: 'Sustainable Forest Management NZ (SFM)',
  },
  {
    Org_Name: 'NZ Forestry',
  },
  {
    Org_Name: 'Woodbank',
  },
  {
    Org_Name: 'Symbiosis Tree Services',
  },
  {
    Org_Name: 'Northern Forest Products',
  },
  {
    Org_Name: 'Ribbonwood New Zealand',
  },
  {
    Org_Name: 'Forest Cover',
  },
  {
    Org_Name: 'Magon Contracting',
  },
  {
    Org_Name: 'NZ Forestry',
  },
  {
    Org_Name: 'LandPrepNZ',
  },
  {
    Org_Name: 'Forest Protection Services (FPS)',
  },
  {
    Org_Name: 'Mark Gorringe',
  },
  {
    Org_Name: 'Land Clearing Solutions NZ ',
  },
  {
    Org_Name: 'Far North Roading Group (FNR)',
  },
  {
    Org_Name: 'Ensign',
  },
  {
    Org_Name: 'Komatsu New Zealand',
  },
  {
    Org_Name: 'DC Equipmnt',
  },
  {
    Org_Name: 'Forest Floor ',
  },
  {
    Org_Name: 'Northland Forest Nursery',
  },
  {
    Org_Name: 'Forest Genetics ',
  },
  {
    Org_Name: 'New Zealand Forest Sales',
  },
  {
    Org_Name: 'Silvicom',
  },
  {
    Org_Name: 'Native Solutions',
  },
  {
    Org_Name: 'Interpine Innovation',
  },
  {
    Org_Name: 'Asset Forestry Logistics ',
  },
  {
    Org_Name: 'TimberSmart',
  },
  {
    Org_Name: 'Te Uru Rakau (Forestry New Zealand)',
  },
  {
    Org_Name: 'Allen Bell Registered Forestry Consultant',
  },
  {
    Org_Name: 'ForestStat',
  },
  {
    Org_Name: 'Brett Gilmore Consulting',
  },
  {
    Org_Name: "Tomorrow's Forests",
  },
  {
    Org_Name: 'N J SIM Forestry Services ',
  },
  {
    Org_Name: 'Forest Services',
  },
  {
    Org_Name: 'Woodlands Pacific Consulting',
  },
  {
    Org_Name: 'Mosduvin Forest',
  },
  {
    Org_Name: 'Professional Harvesting Systems (PHS)',
  },
  {
    Org_Name: 'Pango',
  },
  {
    Org_Name: 'NZ Forestry',
  },
  {
    Org_Name: 'Laurie Forestry',
  },
  {
    Org_Name: 'Merrill and Ring NZ ',
  },
  {
    Org_Name: 'Logic Forest Solutions ',
  },
  {
    Org_Name: 'IFS Growth ',
  },
  {
    Org_Name: 'Forme Consulting ',
  },
  {
    Org_Name: 'DANA',
  },
  {
    Org_Name: 'T.A.M.L Forestry ',
  },
  {
    Org_Name: 'Cochrane & Son Logging',
  },
  {
    Org_Name: 'Forest Management NZ (FMNZ)',
  },
  {
    Org_Name: 'Forest Management Group',
  },
  {
    Org_Name: 'Indufor',
  },
  {
    Org_Name: 'PF Olsen',
  },
  {
    Org_Name: 'Hancock Forest Management NZ',
  },
  {
    Org_Name: 'Forest 360',
  },
  {
    Org_Name: 'Rayonier Matariki Forests ',
  },
  {
    Org_Name: 'Law North Lawyers ',
  },
  {
    Org_Name: 'Forest Industry Contractors Association (FICA)',
  },
  {
    Org_Name: 'Forest Industry Engineering Association',
  },
  {
    Org_Name: 'Kiwifruit New Zealand',
  },
  {
    Org_Name: 'Kiwifruit Vine Health',
  },
  {
    Org_Name: 'Hoticulture New Zealand',
  },
  {
    Org_Name: 'New Zealand Forest Owners Association',
  },
  {
    Org_Name: 'Farm Forestry New Zealand',
  },
  {
    Org_Name: 'Forestry Careers ',
  },
  {
    Org_Name: 'Forest Industry Safety Council (FISC)',
  },
  {
    Org_Name: 'New Zealand Institute of Forestry',
  },
  {
    Org_Name: 'Herman Timber ',
  },
  {
    Org_Name: 'Kaihu Valley Sawmill',
  },
  {
    Org_Name: 'Rosvall Sawmill',
  },
  {
    Org_Name: 'Mahoe Sawmills',
  },
  {
    Org_Name: 'Northland Timber Co',
  },
  {
    Org_Name: 'Tenco',
  },
  {
    Org_Name: 'Summit Forests ',
  },
  {
    Org_Name: 'Junken New Zealand ',
  },
  {
    Org_Name: 'Max Birt Sawmills ',
  },
  {
    Org_Name: 'Mt Pokaka Timber Products ',
  },
  {
    Org_Name: 'LumberLink      ',
  },
  {
    Org_Name: 'Puketi Forest Trust',
  },
  {
    Org_Name: 'Ngati Hine Forestry Trust',
  },
  {
    Org_Name: 'Pukenui Western Hills Forest Charitable Trust',
  },
  {
    Org_Name: 'Tanes Tree Trust ',
  },
  {
    Org_Name: 'Ekos',
  },
  {
    Org_Name: 'Taitokerau Maori Forests Inc',
  },
  {
    Org_Name: 'Scion',
  },
  {
    Org_Name: 'Forestry Training NZ',
  },
  {
    Org_Name: 'Accounting House Hamilton',
  },
  {
    Org_Name: 'Bruce Sparrow & Associates',
  },
  {
    Org_Name: 'Co-Pilot Accounting',
  },
  {
    Org_Name: 'Hammond & Moir',
  },
  {
    Org_Name: 'Fraser Farm Finance',
  },
  {
    Org_Name: 'Finnz Chartered Accountants',
  },
  {
    Org_Name: 'Mackrell Murcott Chartered Accountants',
  },
  {
    Org_Name: 'DB Chartered Accountants',
  },
  {
    Org_Name: 'Accounting Extra',
  },
  {
    Org_Name: 'Hogans',
  },
  {
    Org_Name: 'Hart & Associates',
  },
  {
    Org_Name: 'Astute Accounting Services',
  },
  {
    Org_Name: 'GW Accountants',
  },
  {
    Org_Name: 'Brown Pennell',
  },
  {
    Org_Name: 'Cowley Stanich & Co',
  },
  {
    Org_Name: 'Gallie Miles',
  },
  {
    Org_Name: 'Horton & Co',
  },
  {
    Org_Name: 'Spicer Accounting',
  },
  {
    Org_Name: 'The Practice Consultants',
  },
  {
    Org_Name: 'DGS Accounting',
  },
  {
    Org_Name: 'MBS Advisors ',
  },
  {
    Org_Name: 'Yovich Hayward Pevats Johnston',
  },
  {
    Org_Name: 'Noone Plus ',
  },
  {
    Org_Name: 'MBS Advisors',
  },
  {
    Org_Name: 'PKF Hamilton',
  },
  {
    Org_Name: 'Accounted4',
  },
  {
    Org_Name: 'Cleland Hancox',
  },
  {
    Org_Name: 'Graham Brown & Co',
  },
  {
    Org_Name: 'Brown Glassford & Co',
  },
  {
    Org_Name: 'Sumpter Baughen Chartered Accountants',
  },
  {
    Org_Name: 'HC Partners LP ',
  },
  {
    Org_Name: 'PKF Rutherfords',
  },
  {
    Org_Name: 'ICL Chartered Accountants ',
  },
  {
    Org_Name: 'Lay Associates ',
  },
  {
    Org_Name: 'Bennetts Proactive ',
  },
  {
    Org_Name: 'RJ Preston',
  },
  {
    Org_Name: 'CooperAitken',
  },
  {
    Org_Name: 'Candy Gillespie',
  },
  {
    Org_Name: 'Diprose Miller',
  },
  {
    Org_Name: 'Gabites',
  },
  {
    Org_Name: 'BFA ',
  },
  {
    Org_Name: 'Findex',
  },
  {
    Org_Name: 'Analytica Laboratories',
  },
  {
    Org_Name: 'Massey University Farm Services Clinic',
  },
  {
    Org_Name: 'The Cow Vets',
  },
  {
    Org_Name: 'Cambridge Vets',
  },
  {
    Org_Name: 'Dairy Goat Co-operative',
  },
  {
    Org_Name: 'Global Veterinary Services',
  },
  {
    Org_Name: 'Hauraki Vets',
  },
  {
    Org_Name: 'Hautapu Vet Clinic',
  },
  {
    Org_Name: 'JB Visser Vets',
  },
  {
    Org_Name: 'Kaimai Vets Matamata',
  },
  {
    Org_Name: 'Kelly Andrews Veterinary Services',
  },
  {
    Org_Name: 'Oraka Veterinary Solutions',
  },
  {
    Org_Name: 'South Waikato Veterinary Services',
  },
  {
    Org_Name: 'Totally Vets',
  },
  {
    Org_Name: 'VetPlus',
  },
  {
    Org_Name: '3 Stone Vets',
  },
  {
    Org_Name: 'Atkinson & Associates Vet Services',
  },
  {
    Org_Name: 'Selwyn Rakaia Vet services ',
  },
  {
    Org_Name: 'Franklin Vets',
  },
  {
    Org_Name: 'Anexa Farmers Vet Club',
  },
  {
    Org_Name: 'Vetora',
  },
  {
    Org_Name: 'VetEnt',
  },
  {
    Org_Name: 'Veterinary Centre ',
  },
  {
    Org_Name: 'South Wairarapa Veterinary Services ',
  },
  {
    Org_Name: 'Franklin Vets ',
  },
  {
    Org_Name: 'Anexa Veterinary services ',
  },
  {
    Org_Name: 'Workwise ',
  },
  {
    Org_Name: 'C&T Walker Construction',
  },
  {
    Org_Name: 'REL Group',
  },
  {
    Org_Name: 'Field Engineering',
  },
  {
    Org_Name: 'NRM',
  },
  {
    Org_Name: 'Sharpes Stock Feeds',
  },
  {
    Org_Name: 'Castlegate James',
  },
  {
    Org_Name: 'Farmyard Feeds',
  },
  {
    Org_Name: 'Takanini Feeds',
  },
  {
    Org_Name: 'Animal Feed Barn',
  },
  {
    Org_Name: 'Cambrige Grains',
  },
  {
    Org_Name: 'OSP Stock Feeds',
  },
  {
    Org_Name: 'Swap Stockfoods',
  },
  {
    Org_Name: 'Seales Winslow',
  },
  {
    Org_Name: 'Landco Fertiliser',
  },
  {
    Org_Name: 'Samen NZ',
  },
  {
    Org_Name: 'ST genetics',
  },
  {
    Org_Name: 'Animal Breeding Services',
  },
  {
    Org_Name: 'CRV Ambreed',
  },
  {
    Org_Name: 'Genetics Australia NZ',
  },
  {
    Org_Name: 'Semex NZ',
  },
  {
    Org_Name: 'Advantage Feeders',
  },
  {
    Org_Name: 'FarmIQ',
  },
  {
    Org_Name: 'Rezare Systems',
  },
  {
    Org_Name: 'CRS Software',
  },
  {
    Org_Name: 'Agrigate',
  },
  {
    Org_Name: 'Farmax',
  },
  {
    Org_Name: 'Smart Farm Software',
  },
  {
    Org_Name: 'Quality Consultants New Zealand (QCONZ)',
  },
  {
    Org_Name: 'Beef SolutionNZ',
  },
  {
    Org_Name: 'Figured ',
  },
  {
    Org_Name: 'Geoff Burton Farm Business Management',
  },
  {
    Org_Name: 'Walton Farm Consulting ',
  },
  {
    Org_Name: 'Kiwi As ',
  },
  {
    Org_Name: 'McIntyre Dick ',
  },
  {
    Org_Name: 'Quality Consultants New Zealand (QCONZ)',
  },
  {
    Org_Name: 'Organic Ag ',
  },
  {
    Org_Name: 'AgSafe NZ',
  },
  {
    Org_Name: 'Brent Kelly Law',
  },
  {
    Org_Name: 'Harkness Henry',
  },
  {
    Org_Name: 'Norris Ward McKinnon',
  },
  {
    Org_Name: 'Tompkins Wake',
  },
  {
    Org_Name: 'Federated Farmers',
  },
  {
    Org_Name: 'New Zealand Veterinary Association',
  },
  {
    Org_Name: 'Rural Women NZ (RWNZ)',
  },
  {
    Org_Name: 'Agri Training',
  },
  {
    Org_Name: 'Quality Consultants New Zealand (QCONZ) - Training',
  },
  {
    Org_Name: 'Sheppard Agriculture',
  },
  {
    Org_Name: 'Anderson Consultancy ',
  },
  {
    Org_Name: 'Abattoirs Association of New Zealand',
  },
  {
    Org_Name: 'Retail Meat New Zealand',
  },
  {
    Org_Name: 'New Zealand Meat Board',
  },
  {
    Org_Name: 'Beef + Lamb New Zealand',
  },
  {
    Org_Name: 'Red Meat Profit Partnership',
  },
  {
    Org_Name: 'Society of Sheep and Beef Cattle Veterinarians',
  },
  {
    Org_Name: 'Deer Industry New Zealand',
  },
  {
    Org_Name: 'The Federated Farmers Meat & Wool Industry Group',
  },
  {
    Org_Name: 'The Federated Farmers Rural Butchers Industry Group',
  },
  {
    Org_Name: 'Meat Industry Association of New Zealand (MIA)',
  },
  {
    Org_Name: 'Taupo Beef and Lamb ',
  },
  {
    Org_Name: 'Wally Smith ',
  },
  {
    Org_Name: 'Counties Custom Killing ',
  },
  {
    Org_Name: 'Homegrown Farm Fresh Meats ',
  },
  {
    Org_Name: 'Provernance ',
  },
  {
    Org_Name: 'Ample Group',
  },
  {
    Org_Name: 'Prime Range Meats',
  },
  {
    Org_Name: 'Fresh Meats NZ ',
  },
  {
    Org_Name: 'Blue Sky Meats NZ',
  },
  {
    Org_Name: 'Taylor Preston',
  },
  {
    Org_Name: 'BX Foods',
  },
  {
    Org_Name: 'Greenlea Premier Meats',
  },
  {
    Org_Name: 'Alliance Group',
  },
  {
    Org_Name: 'Silver Fern Farms ',
  },
  {
    Org_Name: 'AFFCO New Zealand',
  },
  {
    Org_Name: 'ANZCO Foods',
  },
  {
    Org_Name: 'Windy Meat Processing ',
  },
  {
    Org_Name: 'Halswell Butchery Processing Services ',
  },
  {
    Org_Name: 'Progressive Meats',
  },
  {
    Org_Name: 'Crusader Meats New Zealand',
  },
  {
    Org_Name: 'Cabernet Foods ',
  },
  {
    Org_Name: "Smith's Meat Company ",
  },
  {
    Org_Name: 'TK Natural Lamb ',
  },
  {
    Org_Name: 'Auckland Meat Processors',
  },
  {
    Org_Name: 'Don Chapman Waikato',
  },
  {
    Org_Name: 'Nutrinza',
  },
  {
    Org_Name: 'Winston Stock Feed',
  },
  {
    Org_Name: 'Pioneer',
  },
  {
    Org_Name: 'Livestock Improvement Corporation (LIC)',
  },
  {
    Org_Name: 'Livestock Feed Systems',
  },
  {
    Org_Name: 'Better Dairy Solutions',
  },
  {
    Org_Name: 'Waikato Milking Systems',
  },
  {
    Org_Name: 'Dairy Cooling Solutions',
  },
  {
    Org_Name: 'Northland Farm Services',
  },
  {
    Org_Name: 'DeLaval',
  },
  {
    Org_Name: 'FeedFlo',
  },
  {
    Org_Name: 'Ecolab',
  },
  {
    Org_Name: 'Farm Advisors NZ',
  },
  {
    Org_Name: 'Roy Johnson - R J Rural',
  },
  {
    Org_Name: 'Rural Business solutions',
  },
  {
    Org_Name: 'Dairy Solutionz',
  },
  {
    Org_Name: 'EveryCow',
  },
  {
    Org_Name: 'FarmWise',
  },
  {
    Org_Name: 'Dairy Production Systems',
  },
  {
    Org_Name: 'Target Focus',
  },
  {
    Org_Name: 'Dairy Companies Association of New Zealand',
  },
  {
    Org_Name: 'NZ Young Farmers',
  },
  {
    Org_Name: "Dairy Women's Network",
  },
  {
    Org_Name: 'DairyNZ',
  },
  {
    Org_Name: 'Over the Moon Dairy',
  },
  {
    Org_Name: 'BODCO Dairy New Zealand',
  },
  {
    Org_Name: 'Milkio',
  },
  {
    Org_Name: 'BODCO',
  },
  {
    Org_Name: 'Miraka',
  },
  {
    Org_Name: 'Tatua',
  },
  {
    Org_Name: 'Open Country Dairy',
  },
  {
    Org_Name: 'Fonterra',
  },
  {
    Org_Name: 'Farm Medix',
  },
  {
    Org_Name: 'Dairy Training',
  },
  {
    Org_Name: 'FMG',
  },
  {
    Org_Name: 'WealthDesign ',
  },
  {
    Org_Name: 'NZ Farm Management ',
  },
  {
    Org_Name: 'Rural Solutions ',
  },
  {
    Org_Name: 'Headlands ',
  },
  {
    Org_Name: 'RIVAL Wealth ',
  },
  {
    Org_Name: 'Henderson Reeves ',
  },
  {
    Org_Name: 'OSPRI',
  },
  {
    Org_Name: 'New Zealand Pork',
  },
  {
    Org_Name: 'NZ Federation of Commercial Fishermen',
  },
  {
    Org_Name: 'Foundation for Arable Research',
  },
]

import React from 'react'
import styled from 'styled-components'
import * as firebase from 'firebase/app'
import {
  multiChoice,
  multiSelect,
  welcomePage,
  imageChoice,
  singleTextbox,
  responsiveMatrix,
  endPage,
} from '../question-types/QuestionTypes'
import backgroundVideo from '../../assets/images/HCF/pexel-video.mp4'
import conferLogo from '../../assets/images/Confer-logo.png'
import { Textbox } from '../../assets/styles/global'

const db = firebase.firestore()
const surveyData = db.collection('surveys').doc('e350-workshop')

const E350Workshop = {
  questions: [
    {
      id: 'important characteristics',
      text:
        'Select the five characteristics / indicators that you feel are the most important when evaluating cross agency collaboration',
      disclaimer: 'Please select 5 options.',
      type: multiSelect,
      firstQuestion: true,
      verticalGrid: true,
      max: 5,
      width: '600px',
      choices: [
        {
          id: 1,
          text: 'Acceptance of different stakeholders’ objectives, opinions, etc.',
          value: 'Acceptance of different stakeholders’ objectives, opinions, etc.',
        },
        {
          id: 2,
          text: 'Acceptance of others practices as substitutes for their own',
          value: 'Acceptance of others practices as substitutes for their own',
        },
        {
          id: 3,
          text: 'Commitment to collaboration',
          value: 'Commitment to collaboration',
        },
        {
          id: 4,
          text: 'Communication between organisations',
          value: 'Communication between organisations',
        },
        {
          id: 5,
          text: 'Creation of shared objectives',
          value: 'Creation of shared objectives',
        },
        {
          id: 6,
          text: 'Development of new collective knowledge',
          value: 'Development of new collective knowledge',
        },
        {
          id: 7,
          text: 'Knowledge of particular people in other organisations',
          value: 'Knowledge of particular people in other organisations',
        },
        {
          id: 8,
          text: 'Knowledge transfer between organisations',
          value: 'Knowledge transfer between organisations',
        },
        {
          id: 9,
          text: 'Networks between organisations',
          value: 'Networks between organisations',
        },
        {
          id: 10,
          text: 'Opportunity for change',
          value: 'Opportunity for change',
        },
        {
          id: 11,
          text: 'Resource sharing between organisations',
          value: 'Resource sharing between organisations',
        },
        {
          id: 12,
          text: 'Trust within the partnership',
          value: 'Trust within the partnership',
        },
        {
          id: 13,
          text: 'Understanding of what other organisations have to offer',
          value: 'Understanding of what other organisations have to offer',
        },
        {
          id: 14,
          text: 'Additional characteristic A',
          value: 'Additional characteristic A',
        },
        {
          id: 15,
          text: 'Additional characteristic B',
          value: 'Additional characteristic B',
        },
        {
          id: 16,
          text: 'Additional characteristic C',
          value: 'Additional characteristic C',
        },
        {
          id: 17,
          text: 'Additional characteristic D',
          value: 'Additional characteristic D',
        },
      ],
    },
    {
      id: 'wait',
      type: welcomePage,
      text: 'Thanks! Please leave the survey open here until asked to continue.',
    },

    {
      id: 'before',
      type: responsiveMatrix,
      text: (
        <p>
          For each of the top six characteristics, rate the frequency with which each occurred <b>before</b> Extension 350{' '} 
        </p>
      ),
      options: [
        { id: 1, text: 'Not at all', value: 'Not at all' },
        { id: 2, text: 'Rarely', value: 'Rarely' },
        { id: 3, text: 'Time to time', value: 'Time to time' },
        { id: 4, text: 'Regularly', value: 'Regularly' },
        { id: 5, text: 'All the time', value: 'All the time' },
        { id: 6, text: "Don't know", value: "Don't know" },
      ],
      asyncChoices: () => {
        return surveyData.get().then(doc => {
          if (doc.exists) {
            const results = doc.data().aggResults['important characteristics']
            const pairs = Object.keys(results).map(key => [key, results[key]])
            pairs.sort((a, b) => (b[1] - a[1] !== 0 ? b[1] - a[1] : b[0].localeCompare(a[0])))
            return pairs.slice(0, 6).map((val, ind) => ({
              id: ind,
              text: val[0],
              value: val[0],
            }))
          }
          return []
        })
      },
      choices: [
        {
          id: 1,
          text: '',
          value: '',
        },
        {
          id: 2,
          text: '',
          value: '',
        },
        {
          id: 3,
          text: '',
          value: '',
        },
        {
          id: 4,
          text: '',
          value: '',
        },
        {
          id: 5,
          text: '',
          value: '',
        },
        {
          id: 6,
          text: '',
          value: '',
        },
      ],
      columns: [
        { id: 1, text: 'Not at all', value: 'Not at all' },
        { id: 2, text: 'Rarely', value: 'Rarely' },
        { id: 3, text: 'Time to time', value: 'Time to time' },
        { id: 4, text: 'Regularly', value: 'Regularly' },
        { id: 5, text: 'All the time', value: 'All the time' },
        { id: 6, text: "Don't know", value: "Don't know" },
      ],
      rows: [
        {
          id: 1,
          text: '',
          value: '',
        },
        {
          id: 2,
          text: '',
          value: '',
        },
        {
          id: 3,
          text: '',
          value: '',
        },
        {
          id: 4,
          text: '',
          value: '',
        },
        {
          id: 5,
          text: '',
          value: '',
        },
        {
          id: 6,
          text: '',
          value: '',
        },
      ],
    },
    {
      id: 'after',
      type: responsiveMatrix,
      text: (
        <p>
          For each of the top six characteristics, rate the frequency with which each occurred <b>after</b> Extension 350{' '} 
        </p>
      ),
      options: [
        { id: 1, text: 'Not at all', value: 'Not at all' },
        { id: 2, text: 'Rarely', value: 'Rarely' },
        { id: 3, text: 'Time to time', value: 'Time to time' },
        { id: 4, text: 'Regularly', value: 'Regularly' },
        { id: 5, text: 'All the time', value: 'All the time' },
        { id: 6, text: "Don't know", value: "Don't know" },
      ],
      asyncChoices: () => {
        return surveyData.get().then(doc => {
          if (doc.exists) {
            const results = doc.data().aggResults['important characteristics']
            const pairs = Object.keys(results).map(key => [key, results[key]])
            pairs.sort((a, b) => (b[1] - a[1] !== 0 ? b[1] - a[1] : b[0].localeCompare(a[0])))
            return pairs.slice(0, 6).map((val, ind) => ({
              id: ind,
              text: val[0],
              value: val[0],
            }))
          }
          return []
        })
      },
      choices: [
        {
          id: 1,
          text: '',
          value: '',
        },
        {
          id: 2,
          text: '',
          value: '',
        },
        {
          id: 3,
          text: '',
          value: '',
        },
        {
          id: 4,
          text: '',
          value: '',
        },
        {
          id: 5,
          text: '',
          value: '',
        },
        {
          id: 6,
          text: '',
          value: '',
        },
      ],

      columns: [
        { id: 1, text: 'Not at all', value: 'Not at all' },
        { id: 2, text: 'Rarely', value: 'Rarely' },
        { id: 3, text: 'Time to time', value: 'Time to time' },
        { id: 4, text: 'Regularly', value: 'Regularly' },
        { id: 5, text: 'All the time', value: 'All the time' },
        { id: 6, text: "Don't know", value: "Don't know" },
      ],
      rows: [
        {
          id: 1,
          text: '',
          value: '',
        },
        {
          id: 2,
          text: '',
          value: '',
        },
        {
          id: 3,
          text: '',
          value: '',
        },
        {
          id: 4,
          text: '',
          value: '',
        },
        {
          id: 5,
          text: '',
          value: '',
        },
        {
          id: 6,
          text: '',
          value: '',
        },
      ],
    },
    {
      id: 'e350-stakeholders',
      text:
        'Which two of these areas should Extension 350 stakeholders focus on over the next few years?',
      disclaimer: 'Please select 2 options.',
      type: multiSelect,
      verticalGrid: true,
      max: 2,
      width: '600px',
      asyncChoices: () => {
        return surveyData.get().then(doc => {
          if (doc.exists) {
            const results = doc.data().aggResults['important characteristics']
            const pairs = Object.keys(results).map(key => [key, results[key]])
            pairs.sort((a, b) => (b[1] - a[1] !== 0 ? b[1] - a[1] : b[0].localeCompare(a[0])))
            return pairs.slice(0, 6).map((val, ind) => ({
              id: ind,
              text: val[0],
              value: val[0],
            }))
          }
          return []
        })
      },
      choices: [
        {
          id: 1,
          text: '',
          value: '',
        },
        {
          id: 2,
          text: '',
          value: '',
        },
        {
          id: 3,
          text: '',
          value: '',
        },
        {
          id: 4,
          text: '',
          value: '',
        },
        {
          id: 5,
          text: '',
          value: '',
        },
        {
          id: 6,
          text: '',
          value: '',
        },
      ],
    },
    {
      id: 'end-page',
      type: endPage,
      text: (
        <Textbox style={{ padding: '0px' }}>
          <h1 style={{ color: 'rgba(0, 0, 0, 0.65) ', fontSize: '18px', fontWeight: 600 }}>
            Thank you for participating in this workshop!
          </h1>
          <a
            href="https://confer.online"
            rel="noreferrer"
            target="_blank"
            style={{ color: '#E82D7C' }}
          >
            <p>Like this survey and want to create your own? Let&apos;s Confer!</p>
          </a>
          <div style={{ fontSize: '18px' }}>
            <img width="255px" src={conferLogo} alt="confer-logo.jpg" />
          </div>
        </Textbox>
      ),
    },
  ],
  settings: {
    surveyName: 'e350-workshop',
    primaryColour: '#48a54c',
    otherColour: '#72a174',
    useVideo: true,
    backgroundVideo,
    hideSubmit: true,
  },
}

export default E350Workshop

import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components/macro'
import { Tooltip } from 'antd'
import InputField from './InputField'
import TextArea from 'antd/lib/input/TextArea'
import Button from '../ui/Buttons/StandardButton'
import ButtonContainer from '../ui/Layout/ButtonContainer'
import createRipple from '../ui/Animations/ripple'

/**
 * Takes an array of objects to render choices,
 * Only accepts 1 answer,
 * choices may also be used to render dynamic choices
 * @param {*} param
 */
const MultiChoiceQuestions = ({
  currentAnswer,
  updateCurrent,
  submitAnswer,
  choices,
  answers,
  settings,
  loopIndex,
  question,
}) => {
  const { id, value } = currentAnswer
  const dynamicChoices = _.isFunction(choices) ? choices({ answers, loopIndex }) : choices
  const { primaryColour, contrastColour } = settings
  const dynamicColour = !_.isUndefined(primaryColour) ? primaryColour : '#E82D7C'
  const dynamicContrast = !_.isUndefined(contrastColour) ? contrastColour : '#fff'
  const dynamicPadding = !_.isUndefined(question.padding) ? question.padding : ''

  const {
    mobileProps,
    verticalGrid,
    mobileHeight,
    mobilePadding,
    type,
    flex,
    height,
    padding,
    buttonSize,
  } = question

  const handleClick = item => {
    const answer = {
      ...currentAnswer,
      id: item.id,
      value: item.value,
    }
    if (question.noSkip) {
      // flag on question to stop auto 'Next' action
      updateCurrent(answer)
    } else {
      updateCurrent(answer)
      submitAnswer()
    }
  }

  const wrapperProps = {
    question,
  }

  console.log('wrapperProps', wrapperProps)

  return (
    <>
      <ButtonContainer
        dynamicChoices={dynamicChoices}
        gridDisplay={question.verticalGrid}
        size={question.buttonSize}
      >
        {dynamicChoices.map(questionChoice => {
          if (questionChoice.type === 'input') {
            return (
              // find a way to sync button sizes for input and standard buttons
              <InputField
                active={questionChoice.id === id}
                settings={settings}
                inputValue={currentAnswer.id === questionChoice.id ? value : ''}
                updateInputValue={e =>
                  updateCurrent({
                    ...currentAnswer,
                    id: questionChoice.id,
                    value: e.target.value,
                  })
                }
                label={questionChoice.text}
                size={question.buttonSize}
                style={{ marginBottom: questionChoice.marginBottom }}
              />
            )
          }
          return (
            <Button
              active={questionChoice.id === id}
              key={questionChoice.id}
              dynamicPadding={dynamicPadding}
              dynamicChoices={dynamicChoices}
              dynamicColour={dynamicColour}
              dynamicContrast={dynamicContrast}
              mobileProps={question.mobileProps}
              gridDisplay={question.verticalGrid}
              mobileHeight={question.mobileHeight}
              mobilePadding={question.mobilePadding}
              questionType={question.type}
              buttonText={questionChoice.html ? questionChoice.html : questionChoice.text}
              buttonIcon={questionChoice.icon ? questionChoice.icon : null}
              fill={questionChoice.icon && questionChoice.fill ? questionChoice.fill : ''}
              style={{
                flex: question.flex,
                height: question.height,
                width: question.width,
                padding: question.padding,
              }}
              size={question.buttonSize}
              onClick={event => {
                handleClick(questionChoice)
                createRipple(event)
              }}
            ></Button>
          )
        })}
      </ButtonContainer>
      {question.nestedInput && question.nestedInput.trigger === currentAnswer.value ? (
        // find a way to sync button sizes for input and standard buttons
        <InputField
          // active={question.nestedInput.id === id}
          settings={settings}
          inputValue={currentAnswer.comments ? currentAnswer.comments : ''}
          updateInputValue={e =>
            updateCurrent({
              ...currentAnswer,
              comments: e.target.value,
            })
          }
          label={question.nestedInput.text}
          size={question.buttonSize}
        />
      ) : null}
    </>
  )
}

MultiChoiceQuestions.propTypes = {
  choices: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.func]),
  currentAnswer: PropTypes.instanceOf(Object).isRequired,
  updateCurrent: PropTypes.func.isRequired,
  submitAnswer: PropTypes.func.isRequired,
  question: PropTypes.instanceOf(Object),
  answers: PropTypes.instanceOf(Object).isRequired,
  settings: PropTypes.instanceOf(Object),
  loopIndex: PropTypes.oneOfType([
    test => {
      return test === null
    },
    PropTypes.number,
  ]),
}

export default MultiChoiceQuestions

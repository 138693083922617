/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import SurveyWrapper from '../reducers/SurveyWrapper'
import { endLoop } from '../reducers/actions'
import leftBackgroundImage from '../../assets/images/StepChange/cow-left.jpg'
import checkImage from '../../assets/images/StepChange/check.svg'
import crossImage from '../../assets/images/StepChange/cross.svg'
import companyLogo from '../../assets/images/StepChange/DairyNZ.png'
import testImage from '../../assets/images/CNA/agree_green.svg'
import beefImage from '../../assets/images/PIAS/beef_nobg.svg'
import dairyImage from '../../assets/images/PIAS/dairy_nobg.svg'
import fisheriesImage from '../../assets/images/PIAS/fisheries_nobg.svg'
import forestryImage from '../../assets/images/PIAS/forestry.svg'
import horticultureImage from '../../assets/images/PIAS/horticulture_nobg.svg'
import 'antd/dist/antd.css'
import 'typeface-open-sans'

import {
  responsiveMatrix,
  responsiveSlider,
  multiselectDropdown,
  dragDrop,
  matrix,
  multiChoice,
  multiSelect,
  section,
  endSurvey,
  singleTextbox,
  autoComplete,
  slider,
  multiCardChoice,
  imageChoice,
  welcomePage,
  button,
  input,
  multiDropdown,
  socialNetwork,
} from '../question-types/QuestionTypes'
import companies from '../../assets/data/PIAS/companies'

import { SmallText, Textbox } from '../../assets/styles/global'
import MultiSelect from '../question-types/MultiSelect'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  letter-spacing: 0.1em;
  p {
    margin-bottom: 0;
  }
  /* align-items: center; */
`
const GreenText = styled.p`
  font-size: 1rem;
  color: #6fb139;
  font-family: 'Open Sans', sans-serif;
`

const StyledCheckOutlined = styled(CheckOutlined)`
  color: #46e4b5;
`

const StyledCloseOutlined = styled(CloseOutlined)`
  color: #5b3bdf;
`

const SectionInfo = styled.p`
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #9b9b9b;
`

const YellowText = styled.p`
  font-size: 1rem;
  color: #eaae19;
`

const DarkBlueText = styled.p`
  font-size: 1rem;
  color: #474f40;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0;
  width: 90vw;
`

const RedText = styled.p`
  font-size: 1rem;
  color: #84367a;
`

const BlueText = styled.p`
  font-size: 1rem;
  color: #00a7b8;
  font-family: 'Open Sans', sans-serif;
`

const DisclaimerText = styled.p`
  font-size: 1rem;
  width: 90vw;
  margin-bottom: 1rem;
  /* font-style: italic; */
`

const DarkGreenText = styled.p`
  font-size: 1rem;
  color: #4a6f2f;
  font-family: 'Open Sans', sans-serif;
`

const Grey = styled.h1`
  color: #535d52;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.1em;
`

const StyledP = styled.p`
  font-size: 20px;
`

const BoldText = styled.p`
  font-weight: 700;
  margin-top: 1rem;
`

const Container = styled.div`
  /* background-color: red; */
  height: 100vh;
  width: 100vw;
`

const SurveyDemo = {
  questions: [
    // {
    //   id: 0,
    //   type: welcomePage,
    //   text: (
    //     <Textbox>
    //       {/* <Grey> DairyNZ farmer action survey</Grey> */}
    //       <p>
    //         DairyNZ are keen to understand your thoughts and what actions you are taking to meet
    //         your emissions and water quality obligations.
    //       </p>
    //       <p>
    //         The survey will take 5 – 10 mins to complete. Please be assured that all answers you
    //         provide are anonymous.
    //       </p>
    //     </Textbox>
    //   ),
    // },
    // {
    //   id: 'region-question',
    //   section: (
    //     <Wrapper>
    //       <BlueText>Overall</BlueText>
    //     </Wrapper>
    //   ),
    //   type: multiSelect,
    //   text: 'Which region are you in?',
    //   verticalGrid: true,
    //   max: 8,
    //   choices: [
    //     {
    //       id: 1,
    //       value: 'Northland',
    //       text: 'Northland',
    //     },
    //     {
    //       id: 2,
    //       value: 'North Waikato',
    //       text: 'North Waikato',
    //     },
    //     {
    //       id: 3,
    //       value: 'South Waikato',
    //       text: 'South Waikato',
    //       submit: true,
    //     },
    //     {
    //       id: 4,
    //       value: 'Bay of Plenty',
    //       text: 'Bay of Plenty',
    //     },
    //     {
    //       id: 5,
    //       value: 'Taranaki',
    //       text: 'Taranaki',
    //     },
    //     {
    //       id: 6,
    //       value: 'Lower North Island',
    //       text: 'Lower North Island',
    //     },
    //     {
    //       id: 7,
    //       value: 'Top of South Island / West Coast',
    //       text: 'Top of South Island / West Coast',
    //     },
    //     {
    //       id: 8,
    //       value: 'Canterbury / North Otago',
    //       text: 'Canterbury / North Otago',
    //     },
    //     {
    //       id: 9,
    //       value: 'Southland / South Otago',
    //       text: 'Southland / South Otago',
    //     },
    //     {
    //       id: 10,
    //       value: '',
    //       text: 'Other',
    //       type: input,
    //     },
    //   ],
    // },
    // {
    //   id: 2,
    //   section: (
    //     <Wrapper>
    //       <BlueText>Overall</BlueText>
    //     </Wrapper>
    //   ),
    //   type: multiChoice,
    //   buttonSize: 'large',
    //   text:
    //     'Which best describes you in relation to taking action towards environmental sustainability?',
    //   choices: [
    //     {
    //       id: 1,
    //       text: "It's not on my radar",
    //       value: "It's not on my radar",
    //     },
    //     {
    //       id: 2,
    //       text:
    //         "I don't think I should have to, or don't want to, take action towards environmental sustainability",
    //       value:
    //         "I don't think I should have to, or don't want to, take action towards environmental sustainability",
    //     },
    //     {
    //       id: 3,
    //       text:
    //         "I'm willing to take action towards environmental sustainability, but I don't know where to start",
    //       value:
    //         "I'm willing to take action towards environmental sustainability, but I don't know where to start",
    //     },
    //     {
    //       id: 4,
    //       text:
    //         "I'm willing to take action towards environmental sustainability, and I know where to start, but its not a priority so I haven't done anything yet",
    //       value:
    //         "I'm willing to take action towards environmental sustainability, and I know where to start, but its not a priority so I haven't done anything yet",
    //     },
    //     {
    //       id: 5,
    //       text:
    //         "I've taken some action towards environmental sustainability but I'm not confident its enough to meet long term obligations and whether I can be profitable as well",
    //       value:
    //         "I've taken some action towards environmental sustainability but I'm not confident its enough to meet long term obligations and whether I can be profitable as well",
    //     },
    //     {
    //       id: 6,
    //       text:
    //         "I've adapted my farm system to be both environmentally and financially sustainable and I feel confident about the future",
    //       value:
    //         "I've adapted my farm system to be both environmentally and financially sustainable and I feel confident about the future",
    //     },
    //   ],
    // },
    {
      id: 'understanding-goals-and-values-for-producer',
      required: true,
      // hide: true,
      // section: (
      //   <Wrapper style={{ marginBottom: '0' }}>
      //     <QuestionInfo>
      //       This set of questions will help us to gauge where the key gaps in the advisory system
      //       are at the moment.
      //     </QuestionInfo>
      //     {/* <Divider style={{ width: '800px' }} /> */}
      //   </Wrapper>
      // ),
      // text: (

      //   <Wrapper>
      //     <QuestionInfo>In [insert region], are there enough advisors who can…</QuestionInfo>
      //   </Wrapper>
      // ),
      text:
        'This set of questions will help us to gauge where the key gaps in the advisory system are at the moment.',
      type: responsiveMatrix,
      columnLayout: true,
      subText: 'Are there enough advisers who can ..',
      // hide: true,
      width: '2rem',
      // height: '3rem',
      boxWidth: '795px',
      // customBorder: true,
      ignoreWidth: true,
      // useIcons: true,
      // commentBox: true,
      // placeholder: 'Additional comments',
      options: [
        {
          id: 1,
          text: 'No, the number of advisors is critically low',
          value: 'No, the number of advisors is critically low',
        },
        {
          id: 2,
          text: 'We could do with more',
          value: 'We could do with more',
        },
        {
          id: 3,
          text: 'The number of advisors is about right',
          value: 'The number of advisors is about right',
        },
        {
          id: 4,
          text: 'There are plenty of advisors who can do this',
          value: 'There are plenty of advisors who can do this',
        },
        {
          id: 5,
          text: "Don't know / not applicable",
          value: "Don't know / not applicable",
        },
      ],
      // columns: [
      //   {
      //     id: 1,
      //     value: 'No, the number of advisors is critically low',
      //     text: 'No, the number of advisors is critically low',
      //     // icon: <IconAngrySmiley fill="currentColor" />,
      //   },
      //   {
      //     id: 2,
      //     text: 'We could do with more',
      //     value: 'We could do with more',
      //     // icon: <IconSmileyTwo fill="currentColor" />,
      //   },
      //   {
      //     id: 3,
      //     value: 'The number of advisors is about right',
      //     text: 'The number of advisors is about right',
      //     // icon: <IconSmileyFour fill="currentColor" />,
      //   },
      //   {
      //     id: 4,
      //     value: 'There are plenty of advisors who can do this',
      //     text: 'There are plenty of advisors who can do this',
      //     // icon: <IconSmileyFive fill="currentColor" />,
      //   },
      //   {
      //     id: 5,
      //     value: "Don't know / not applicable",
      //     text: "Don't know / not applicable",
      //     // icon: <IconSmileyThree fill="currentColor" />,
      //   },
      // ],
      // rows: [
      //   {
      //     id: 1,
      //     // hide: true,
      //     text:
      //       'A)	Advise on the production system as a whole (i.e., are recognised as farm system consultants)',
      //     value: 'Advise on the production system as a whole',
      //   },
      //   {
      //     id: 2,
      //     // hide: true,
      //     text:
      //       'B)	Advise on land use practices that are both productive and environmentally sustainable',
      //     value:
      //       'Advise on land use practices that are both productive and environmentally sustainable',
      //   },
      //   {
      //     id: 3,
      //     // hide: true,
      //     text: 'C)	Advise on changing land use',
      //     value: 'Advise on changing land use',
      //   },
      //   {
      //     id: 4,
      //     // hide: true,
      //     text: 'D)	Confidently use Farmax to compare different land use scenarios',
      //     value: 'Confidently use Farmax to compare different land use scenarios',
      //   },
      //   {
      //     id: 5,
      //     // hide: true,
      //     text: 'E)	Write and prepare farm environment plans',
      //     value: 'Write and prepare farm environment plans',
      //   },
      //   {
      //     id: 6,
      //     // hide: true,
      //     text: 'F)	Write and prepare farm environment plans',
      //     value: 'Write and prepare farm environment plans',
      //   },
      // ],
      choices: [
        {
          id: 1,
          // hide: true,
          text:
            'A)	Advise on the production system as a whole (i.e., are recognised as farm system consultants)',
          value:
            'Advise on the production system as a whole (i.e., are recognised as farm system consultants)',
        },
        {
          id: 2,
          // hide: true,
          text:
            'B)	Advise on land use practices that are both productive and environmentally sustainable',
          value:
            'Advise on land use practices that are both productive and environmentally sustainable',
        },
        {
          id: 3,
          // hide: true,
          text: 'C)	Advise on changing land use',
          value: 'Advise on changing land use',
        },
        {
          id: 4,
          // hide: true,
          text: 'D)	Confidently use Farmax to compare different land use scenarios',
          value: 'Confidently use Farmax to compare different land use scenarios',
        },
        {
          id: 5,
          // hide: true,
          text: 'E)	Write and prepare farm environment plans',
          value: 'Write and prepare farm environment plans',
        },
        {
          id: 6,
          // hide: true,
          text: 'F)	Write and prepare farm environment plans',
          value: 'Write and prepare farm environment plans',
        },
      ],
      // next: ({ answers }) => {
      //   const landRelated = [
      //     'Environment management',
      //     'Land production and optimisation',
      //     'Policy, regulation, and compliance',
      //     'Nutrient management',
      //     'Whole system advice (all of the above)',
      //   ]
      //   if (answers['advisor-capacity-reach'].value.some(ans => landRelated.includes(ans.value))) {
      //     return 'rate-own-expertise'
      //   }
      //   return 'education'
      // },
    },
    {
      id: 3,
      section: (
        <Wrapper>
          <GreenText>Financial management</GreenText>
        </Wrapper>
      ),
      type: 'imageRadioList',
      text: 'Which of the following is true for you?',
      choices: [
        {
          id: 1,
          text: 'I have a written business plan and am implementing it',
          value: 'I have a written business plan and am implementing it',
          answerChoices: [
            {
              id: 1,
              text: 'Yes',
              value: 'Yes',
              image: <StyledCheckOutlined />,
            },
            {
              id: 2,
              text: 'No',
              value: 'No',
              image: <StyledCloseOutlined />,
            },
            // { id: 1, value: 'Yes' },
            // { id: 2, value: 'No' },
          ],
        },
        {
          id: 2,
          text:
            'I use a budgeting and cashflow tool (template, resource, software etc.) to manage my finances',
          value:
            'I use a budgeting and cashflow tool (template, resource, software etc.) to manage my finances',
          answerChoices: [
            { id: 3, value: 'Yes', image: <StyledCheckOutlined /> },
            { id: 4, value: 'No', image: <StyledCloseOutlined /> },
          ],
        },
        {
          id: 3,
          text:
            'I use financial benchmarking and key performance indicators (e.g. operating profit/ha, farm working expenses, return on assets etc.) to help me achieve my finance goals',
          value:
            'I use financial benchmarking and key performance indicators (e.g. operating profit/ha, farm working expenses, return on assets etc.) to help me achieve my finance goals',
          answerChoices: [
            { id: 5, value: 'Yes', image: <StyledCheckOutlined /> },
            { id: 6, value: 'No', image: <StyledCloseOutlined /> },
          ],
        },
        {
          id: 4,
          text:
            'We have a clear plan to manage our debt over time (pay off plan, maintaining a debt to asset ratio)',
          value:
            'We have a clear plan to manage our debt over time (pay off plan, maintaining a debt to asset ratio)',
          answerChoices: [
            { id: 7, value: 'Yes', image: <StyledCheckOutlined /> },
            { id: 8, value: 'No', image: <StyledCloseOutlined /> },
          ],
        },
      ],
    },
    {
      id: 4,
      section: (
        <Wrapper>
          <DarkBlueText>Freshwater Farm Plan / Farm Environment Plans</DarkBlueText>
          <DisclaimerText>
            Note: currently many Freshwater Farm Plans are known as Farm Environment Plans.
          </DisclaimerText>
        </Wrapper>
      ),
      disclaimer: 'Note: currently many Freshwater Farm Plans are known as Farm Environment Plans.',

      type: multiChoice,
      text: 'Which of the following best describes you with regards to a plan for your farm?',
      choices: [
        {
          id: 1,
          text:
            'Our farm has a Freshwater Farm Plan that was developed with input from a qualified advisor',
          value:
            'Our farm has a Freshwater Farm Plan that was developed with input from a qualified advisor',
        },
        {
          id: 2,
          text:
            'Our farm has a Freshwater Farm Plan but I am not confident that it is up to date and fit-for-purpose',
          value:
            'Our farm has a Freshwater Farm Plan but I am not confident that it is up to date and fit-for-purpose',
        },
        {
          id: 3,
          text: 'I have a plan in place to get a Freshwater Farm Plan',
          value: 'I have a plan in place to get a Freshwater Farm Plan',
        },
        {
          id: 4,
          text: 'I don’t plan to get a Freshwater Farm Plan',
          value: 'I don’t plan to get a Freshwater Farm Plan',
        },
      ],
    },
    {
      id: 5,
      section: (
        <Wrapper>
          <DarkBlueText>Freshwater Farm Plan / Farm Environment Plans</DarkBlueText>
          <DisclaimerText>
            Note: currently many Freshwater Farm Plans are known as Farm Environment Plans.
          </DisclaimerText>
        </Wrapper>
      ),
      type: imageChoice,
      text: 'Is the following true for you?',
      // text:
      //   'We are recording when we complete the actions recommended in the Freshwater Farm Plan',
      statement: () => {
        return (
          <div>
            <BoldText>
              We are recording when we complete the actions recommended in the Freshwater Farm Plan.
            </BoldText>
          </div>
        )
      },
      choices: [
        {
          id: 1,
          value: 'Yes',
          image: 'StepChange/check.svg',
        },
        {
          id: 2,
          value: 'No',
          image: 'StepChange/cross.svg',
        },
      ],
    },
    {
      id: 6,
      section: (
        <Wrapper>
          <DarkBlueText>Nutrient management</DarkBlueText>
        </Wrapper>
      ),
      text: 'Which of the following is true for you?',
      type: 'imageRadioList',
      options: [
        { id: 1, value: 'Yes' },
        { id: 2, value: 'No' },
        { id: 3, value: 'Yes' },
        { id: 4, value: 'No' },
        { id: 5, value: 'Yes' },
        { id: 6, value: 'No' },
      ],
      headers: [
        'Which of the following is true for you?',
        'We understand Good Management Practices that apply to nutrient management and fertiliser application and follow these ',
      ],
      choices: [
        {
          id: 1,
          text: 'A nutrient budget is completed every year and reviewed with a qualified advisor',
          value: 'A nutrient budget is completed every year and reviewed with a qualified advisor',
          answerChoices: [
            { id: 1, value: 'Yes', image: <StyledCheckOutlined /> },
            { id: 2, value: 'No', image: <StyledCloseOutlined /> },
          ],
        },
        {
          id: 2,
          text:
            'Soil phosphorous levels are monitored through regular soil testing and maintained at or below agronomic optimum levels',
          value:
            'Soil phosphorous levels are monitored through regular soil testing and maintained at or below agronomic optimum levels',
          answerChoices: [
            { id: 3, value: 'Yes', image: <StyledCheckOutlined /> },
            { id: 4, value: 'No', image: <StyledCloseOutlined /> },
          ],
        },
      ],
      otherChoices: [
        {
          id: 3,
          text:
            'Manage the amount and timing of fertiliser inputs, taking into account all sources of nutrients, to match plant requirements and minimise risk of losses.',
          value:
            'Manage the amount and timing of fertiliser inputs, taking into account all sources of nutrients, to match plant requirements and minimise risk of losses.',
          answerChoices: [
            { id: 5, value: 'Yes', image: <StyledCheckOutlined /> },
            { id: 6, value: 'No', image: <StyledCloseOutlined /> },
          ],
        },
        {
          id: 4,
          text:
            'Store and load fertiliser to minimise risk of spillage, leaching and loss into water bodies.',
          value:
            'Store and load fertiliser to minimise risk of spillage, leaching and loss into water bodies.',
          answerChoices: [
            { id: 7, value: 'Yes', image: <StyledCheckOutlined /> },
            { id: 8, value: 'No', image: <StyledCloseOutlined /> },
          ],
        },
        {
          id: 5,
          text: 'Ensure equipment for spreading fertiliser is well maintained and calibrated.',
          value: 'Ensure equipment for spreading fertiliser is well maintained and calibrated.',
          answerChoices: [
            { id: 9, value: 'Yes', image: <StyledCheckOutlined /> },
            { id: 10, value: 'No', image: <StyledCloseOutlined /> },
          ],
        },
        {
          id: 6,
          text:
            'Store, transport and distribute feed to minimise wastage, leachate and soil damage.',
          value:
            'Store, transport and distribute feed to minimise wastage, leachate and soil damage.',
          answerChoices: [
            { id: 11, value: 'Yes', image: <StyledCheckOutlined /> },
            { id: 12, value: 'No', image: <StyledCloseOutlined /> },
          ],
        },
      ],
    },
    {
      id: 7,
      section: (
        <Wrapper>
          <DarkBlueText>Nutrient management</DarkBlueText>
        </Wrapper>
      ),
      text: 'Which of the following is true for you?',
      type: 'imageRadioList',
      choices: [
        {
          id: 1,
          text:
            'Manage the amount and timing of fertiliser inputs, taking into account all sources of nutrients, to match plant requirements and minimise risk of losses.',
          value:
            'Manage the amount and timing of fertiliser inputs, taking into account all sources of nutrients, to match plant requirements and minimise risk of losses.',
          answerChoices: [
            { id: 1, value: 'Yes', image: <StyledCheckOutlined /> },
            { id: 2, value: 'No', image: <StyledCloseOutlined /> },
          ],
        },
        {
          id: 2,
          text:
            'Store and load fertiliser to minimise risk of spillage, leaching and loss into water bodies.',
          value:
            'Store and load fertiliser to minimise risk of spillage, leaching and loss into water bodies.',
          answerChoices: [
            { id: 3, value: 'Yes', image: <StyledCheckOutlined /> },
            { id: 4, value: 'No', image: <StyledCloseOutlined /> },
          ],
        },
        {
          id: 3,
          text: 'Ensure equipment for spreading fertiliser is well maintained and calibrated.',
          value: 'Ensure equipment for spreading fertiliser is well maintained and calibrated.',
          answerChoices: [
            { id: 5, value: 'Yes', image: <StyledCheckOutlined /> },
            { id: 6, value: 'No', image: <StyledCloseOutlined /> },
          ],
        },
        {
          id: 4,
          text:
            'Store, transport and distribute feed to minimise wastage, leachate and soil damage.',
          value:
            'Store, transport and distribute feed to minimise wastage, leachate and soil damage.',
          answerChoices: [
            { id: 7, value: 'Yes', image: <StyledCheckOutlined /> },
            { id: 8, value: 'No', image: <StyledCloseOutlined /> },
          ],
        },
      ],
    },
    {
      id: 8,
      required: true,
      section: (
        <Wrapper>
          <BlueText>Water quality risk assessment</BlueText>
        </Wrapper>
      ),
      type: 'multiSlider',
      text: 'What percentage of farmers in your area do you think are doing the following?',
      choices: [
        {
          id: 1,
          value: 0,
          showPercentage: 'true',
          text:
            'They have assessed the risk from all our farm practices to water quality (e.g. from farm dairy effluent, management of critical source areas – where water accumulates during wet periods; water use and irrigation; crop and pasture grazing principles.)',
        },
        {
          id: 2,
          value: 0,
          showPercentage: 'true',
          text:
            'For all high and medium risk areas identified they have developed time-bound actions to mitigate impacts.',
        },
      ],
    },
    {
      id: 9,
      section: (
        <Wrapper>
          <GreenText>Greenhouse gases</GreenText>
        </Wrapper>
      ),
      type: multiChoice,
      text: 'Which of the following best describes you with regards to emissions on your farm? ',
      choices: [
        {
          id: 1,
          text:
            'I have not estimated greenhouse gas emissions or set targets / actions for my farm',
          value:
            'I have not estimated greenhouse gas emissions or set targets / actions for my farm',
        },
        {
          id: 2,
          text:
            'I have estimated how much greenhouse gases are emitted from my farm but have not set targets / actions',
          value:
            'I have estimated how much greenhouse gases are emitted from my farm but have not set targets / actions',
        },
        {
          id: 3,
          text: 'I have set targets and / or identified actions but have not taken action yet',
          value: 'I have set targets and / or identified actions but have not taken action yet',
        },
        {
          id: 4,
          text:
            'I have taken specific actions to change the amount of greenhouse gases produced by my farm',
          value:
            'I have taken specific actions to change the amount of greenhouse gases produced by my farm',
        },
      ],
    },
    {
      id: 10,
      text: 'Your answers have been submitted. Thank you for your time.',
      type: welcomePage,
    },
  ],
  settings: {
    writeToFirestore: true,
    surveyName: 'test-build',
    companyLogo,
    leftBackgroundImage,
    backgroundImage: leftBackgroundImage,
  },
}
export default SurveyDemo

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import _ from 'lodash'
import { Tooltip } from 'antd'

import TextArea from 'antd/lib/input/TextArea'

/**
 * This question requires a multiple arrays,
 * the 'options' array is used to render a scale,
 * multiChoice array is used to render choices,
 * results are recorded with radio buttons
 * @param {*} param
 */
const MultiCheckBox = ({
  answers,
  question,
  multiChoiceArray,
  columnsArray,
  updateCurrent,
  currentAnswer,
  injectCurrent,
  submitAnswer,
  settings,
  options,
}) => {
  const qChoices = _.isFunction(question.choices) ? question.choices({ answers }) : question.choices
  console.log('init cohices', options)
  console.log('qChoices', qChoices)
  const [dynamicChoices, setDynamicChoices] = useState(_.uniqBy(qChoices, 'id'))
  console.log('dynamicChoices', dynamicChoices)

  const dynamicColumns = _.isFunction(options) ? options({ answers }) : options
  // const dynamicRows = _.isFunction(multiChoiceArray)
  //   ? multiChoiceArray({ answers })
  //   : multiChoiceArray

  const { id } = currentAnswer

  console.log('question ', question.id)
  console.log('question choices', question.choices)

  const dynamicSection = _.isFunction(question.headerText)
    ? question.headerText({ answers })
    : question.headerText

  const fetchDefaultValues = () => {
    if (question.defaultValues) {
      const initialAnswer = {
        value: question.defaultValues({ answers }),
        questionType: question.type,
      }
      injectCurrent(initialAnswer)
    }
  }

  useEffect(() => {
    fetchDefaultValues()
    if (question.asyncChoices) {
      question.asyncChoices().then(choices => {
        setDynamicChoices(choices)
      })
    }
  }, [])

  // const dynamicChoices = _.isFunction(multiChoiceArray)
  //   ? multiChoiceArray({ answers })
  //   : multiChoiceArray

  const { primaryColour, contrastColour } = settings

  const dynamicColour = !_.isUndefined(primaryColour) ? primaryColour : '#40a9ff'
  const dynamicContrast = !_.isUndefined(contrastColour) ? contrastColour : '#FFF'

  const dynamicButton = item => {
    let output
    if (question.useValues) {
      output = item.value
      return output
    }
    if (question.useIcons) {
      output = (
        <Column>
          {item.icon}
          {item.value}
        </Column>
      )
      return output
    }
    output = item.id
    return output
  }

  const theme = {
    background: dynamicColour,
    border: '1px solid rgba(0,0,0,0.3)',
    color: '#262626',
  }

  const iconTheme = {
    marginTop: '0.5rem',
    background: 'white',
    color: dynamicColour,
    border: 'none',
  }

  const matrixButton = (row, col, index) => {
    // console.log('rowIndex', rowIndex)
    let button = (
      <Button
        dynamicChoices={dynamicChoices}
        dynamicColour={dynamicColour}
        dynamicContrast={dynamicContrast}
        useValues={!_.isUndefined(question.useValues)}
        theme={question.useIcons ? iconTheme : theme}
        customBorder={question.customBorder}
        type="button"
        style={{
          width: question.ignoreWidth ? '' : question.width,
          height: question.height,
        }}
        active={
          currentAnswer.value &&
          currentAnswer.value[index] &&
          currentAnswer.value[index].value === row.value
        }
        onClick={() => {
          const answer = {
            value: col.value,
          }
          const newAnswer = {
            ...currentAnswer,
          }

          if (!currentAnswer.value || currentAnswer.value === 'skip') {
            newAnswer.id = col.id
            newAnswer.value = new Array(qChoices.length)
            newAnswer.value.fill({ text: '', value: '' })
          }
          newAnswer.value[index] = {
            id: col.id,
            text: answer.value,
            value: row.value,
          }
          updateCurrent(newAnswer)
        }}
      >
        {dynamicButton(row)}
      </Button>
    )
    if (row.tooltip) {
      button = (
        <Tooltip placement="topLeft" title={row.tooltip}>
          {button}
        </Tooltip>
      )
    }
    return button
  }

  return (
    <Container>
      {qChoices.map((item, itemIndex) => {
        return (
          <HeaderElement style={{ paddingLeft: item.paddingLeft }}>
            <h3>{item.text}</h3>
            <Row style={{ marginBottom: question.useIcons ? '3rem' : '' }}>
              {question.options.map(btn => {
                return matrixButton(btn, item, itemIndex)
              })}
            </Row>
          </HeaderElement>
        )
      })}

      <div>
        {question.commentBox ? (
          <>
            <h3>Additional thoughts</h3>
            <StyledTextArea
              type="text"
              allowClear
              dynamicColour={dynamicColour}
              placeholder={question.placeholder}
              value={currentAnswer.comments ? currentAnswer.comments : ''}
              autoSize={{ minRows: 3, maxRows: 6 }}
              onChange={e => {
                const inputValues = {
                  value: e.target.value,
                }
                const newAnswer = {
                  ...currentAnswer,
                }
                newAnswer.comments = inputValues.value
                updateCurrent(newAnswer)
              }}
            />
          </>
        ) : null}
      </div>
      {question.additionalContent ? (
        <div style={{ marginTop: '1rem' }}>{question.additionalContent}</div>
      ) : null}

      {question.skipButton ? (
        <SkipContainer>
          <Wrapper
            active={question.skipButton.id === id}
            key={question.skipButton.id}
            useValues={!_.isUndefined(question.useValues)}
            dynamicChoices={dynamicChoices}
            dynamicColour={dynamicColour}
            dynamicContrast={dynamicContrast}
            gridDisplay={question.verticalGrid}
            style={{ flex: question.flex, height: question.height }}
            onClick={() => {
              const answer = {
                ...currentAnswer,
                id: question.skipButton.id,
                value: question.skipButton.value,
              }

              updateCurrent(answer)
              submitAnswer()
            }}
          >
            <Circle
              dynamicColour={dynamicColour}
              dynamicContrast={dynamicContrast}
              gridDisplay={question.verticalGrid}
              className="svg-wrapper"
              active={question.skipButton.id === id}
            >
              <svg className="svg" version="1.1" id="tick" viewBox="6 5 26 26">
                <polyline
                  className="path"
                  points="
       11.6,20 15.9,24.2 26.4,13.8 "
                />
              </svg>
            </Circle>
            <MButton gridDisplay={question.verticalGrid} type="button">
              <div>{question.skipButton.text}</div>
            </MButton>
          </Wrapper>
        </SkipContainer>
      ) : null}
    </Container>
  )
}

MultiCheckBox.propTypes = {
  answers: PropTypes.instanceOf(Object),
  multiChoiceArray: PropTypes.instanceOf(Array).isRequired,
  currentAnswer: PropTypes.instanceOf(Object).isRequired,
  updateCurrent: PropTypes.func.isRequired,
  question: PropTypes.instanceOf(Object).isRequired,
  settings: PropTypes.instanceOf(Object),
  injectCurrent: PropTypes.func,
}

const StyledTableRow = styled.tr`
  height: 50px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  td {
    font-weight: 500;
  }
`

const ColumnContainer = styled.div`
  width: 800px;
  display: flex;
  flex-direction: column;
  h1 {
    color: rgba(0, 0, 0, 0.65);
    font-size: 18px;
    font-weight: 501;
    /* margin-top: 2rem; */
    margin-bottom: 0rem;
  }

  div {
    display: flex;
    flex-direction: row;
  }

  tbody {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
`

const StyledUL = styled.ul`
  margin-top: -1rem;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    margin-bottom: 0.5rem;
  }
`

const Container = styled.div`
  margin-top: 1rem;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 800px;
  @media (min-width: 480px) and (max-width: 768px) {
    /* background: red; */
  }

  @media (min-width: 768px) and (max-width: 1025px) {
    ${StyledTableRow} {
      td {
        padding-left: 5px;
      }
    }
  }
`

const Button = styled.button`
  font-size: 1rem;
  width: 9rem;
  text-align: center;
  outline: none;
  height: 4rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 3px;
  padding: 5px;
  font-weight: 400;
  background-color: ${props => (props.active ? props.theme.background : 'white')};
  font-weight: ${props => (props.active ? '500' : '400')};
  color: ${props => (props.active && props.useValues ? '#fff' : '#262626')};
  border: ${props => props.theme.border};
  border: ${props => (props.active && props.customBorder ? '2px solid green' : props.theme.border)};
  transition: background 250ms ease-in-out, transform 150ms ease;
  &:hover {
    border: ${props => props.theme.border};
  }
  svg {
    fill: ${props => (props.active && props.useValues ? props.theme.color : '')};
    g {
      g {
        #border {
          stroke: ${props => (props.active ? 'black' : 'white')};
        }
      }
    }
  }
  ${Column} {
    margin-bottom: 1rem;
  }
`

const HeaderElement = styled.div`
  word-wrap: break-word;
  width: inherit;
  font-size: 1rem;
  margin-bottom: 1.5rem;
`

const StyledTd = styled.div`
  display: flex;
  width: 6rem;
  align-items: center;
  /* justify-content: center; */
`

const StyledTextArea = styled(TextArea)`
  width: 790px;
  padding-right: 10px;
  .ant-input:focus,
  .ant-input-focused {
    border-color: ${props => props.dynamicColour};
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 90vw;
    margin: auto;
    align-items: center;
  }
  @media (min-width: 480px) and (max-width: 768px) {
    margin: auto;
    width: 90vw;
    align-items: center;
  }
  @media (min-width: 768px) and (max-width: 1025px) {
    margin: auto;
    width: 80vw;
    align-items: center;
  }
`

const MButton = styled.button`
  display: contents;
  height: auto;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 3px;
  font-weight: 501;
  div {
    display: flex;
    align-content: baseline;
    /* padding-left: ${props => (props.gridDisplay ? '1rem' : '')}; */
    text-align: justify;
    text-justify: inter-word;
    max-width: ${props => (props.gridDisplay ? '94%' : '')};
    width: 94%;
    span {
      text-align: left;
      text-overflow: ellipsis;
      word-wrap: break-word;
      word-break: break-work;
      font-weight: 501;
    }
  }
`

const Circle = styled.div`
  display: flex;
  align-items: center;
  right: ${props => (props.gridDisplay ? '0px' : '20px')};
  width: 25px;
  height: 25px;
  padding: 2px;
  border-radius: 25px;
  border: ${props => (props.active ? '1px solid white' : 'none')};
  position: ${props => (props.gridDisplay ? 'absolute' : 'absolute')};
  right: ${props => (props.gridDisplay ? '7px' : '')};
  top: ${props => (props.gridDisplay ? '' : '')};
  .svg-wrapper {
    width: 40px;
    display: contents;
    align-items: center;
    justify-content: center;
  }
  background-color: #fff;
  .svg {
    width: 30px;
  }
  .path {
    fill: transparent;
    stroke: ${props => (props.active ? props.dynamicColour : '#53bf8b')};
    stroke-width: 3;
    stroke-dasharray: 25;
    stroke-dashoffset: ${props => (props.active ? 0 : 26)};
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dashoffset 250ms ease-in-out;
  }
`

const Wrapper = styled.div`
  padding-left: ${props => (props.gridDisplay ? '3px' : '1px')};
  width: 33.5rem;
  padding: ${props => (props.dynamicPadding ? props.dynamicPadding : '')};
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  height: 3.3rem;
  display: flex;
  flex-direction: ${props => (props.gridDisplay ? 'row-reverse' : 'column')};
  cursor: pointer;
  background-color: ${props => (props.active ? props.dynamicColour : 'white')};
  margin-bottom: 8px;
  margin-right: 8px;
  border: ${props => (props.active ? 'none' : '1px solid rgba(0, 0, 0, 0.3)')};
  color: ${props => (props.active ? props.dynamicContrast : '#262626')};
  position: relative;
  transition: background 250ms ease-in-out, transform 150ms ease;
  &:hover {
    transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased;
    border-color: ${props => props.dynamicColour};
    border-width: 2px;
    border-color: none;
    /* transform: scale(1.01); */
    -webkit-box-shadow: 0px 6px 11px -8px rgba(0, 0, 0, 0.65);
    box-shadow: 0px 6px 11px -8px rgba(0, 0, 0, 0.65);
    transition: box-shadow 0.08s ease-in-out;
  }
  .svg {
    width: 20px;
  }
  .path {
    fill: transparent;
    stroke: ${props => (props.active ? props.dynamicColour : '#53bf8b')};
    stroke-width: 2;
    stroke-dasharray: 25;
    stroke-dashoffset: ${props => (props.active ? 0 : 26)};
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dashoffset 250ms ease-in-out;
  }

  ${MButton} {
    border: none;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: calc(1vh + 0rem);
    height: 50px;
    width: 90vw;
    .svg-wrapper {
      margin-left: 230px;
    }
  }
  @media (min-width: 480px) and (max-width: 767px) {
  }
`

const SkipContainer = styled.div`
  display: flex;
  align-self: baseline;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 49rem;
`

export default MultiCheckBox

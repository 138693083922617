import React from 'react'
import _ from 'lodash'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

const NumberRating = ({ question, updateCurrent, currentAnswer }) => {
  const numberRange = Array.from({ length: question.maxRange }, (value, key) => key + 1) // create Array based on maxRange
  const numberObj = Object.fromEntries(
    numberRange.map(number => [
      number,
      {
        id: number,
        value: number,
      },
    ]),
  ) // create object for each element inside numberRange array
  const numArray = _.values(numberObj) // convert numberObj to array
  const questionLabels = question.labels
  return (
    <Container>
      <Row>
        {questionLabels.map(label => (
          <span>{label}</span>
        ))}
      </Row>
      <Row>
        {numArray.map(number => (
          <Number
            active={number.id === currentAnswer.id}
            key={number.id}
            type="button"
            onClick={() => {
              const answer = {
                id: number.id,
                value: number.value,
              }
              updateCurrent(answer)
            }}
          >
            {number.value}
          </Number>
        ))}
      </Row>
    </Container>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${Row}:nth-of-type(1) {
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  ${Row}:nth-of-type(2) {
    align-self: center;
    justify-content: space-between;
    width: 500px;
  }
  max-width: 500px;
  overflow: hidden;
`

const Number = styled.button`
  width: 3rem;
  height: 3rem;
  border-style: solid;
  border-width: 1px;
  border-color: #c7c7c76b;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 3px;
  background-color: ${props => (props.active ? '#C4E8C5' : 'white')};
  opacity: ${props => (props.active ? 1 : 0.45)};
  transition: background 250ms ease-in-out, transform 150ms ease;
  &:hover {
    opacity: 1;
    border-color: #c4e8c5;
    border-width: 2px;
    transform: scale(1.05);
  }
`

NumberRating.propTypes = {
  question: PropTypes.instanceOf(Object),
  currentAnswer: PropTypes.instanceOf(Object).isRequired,
  updateCurrent: PropTypes.func.isRequired,
}

export default NumberRating

import React from 'react'
import {
  multiChoice,
  multiSelect,
  welcomePage,
  singleTextbox,
  responsiveMatrix,
} from '../question-types/QuestionTypes'
import styled from 'styled-components'
import { ReactComponent as SmileyOne } from '../../assets/images/PIAS/smiley-17.svg'
import { ReactComponent as SmileyTwo } from '../../assets/images/PIAS/smiley-4.svg'
import { ReactComponent as SmileyThree } from '../../assets/images/PIAS/smiley-6.svg'
import { ReactComponent as SmileyFour } from '../../assets/images/PIAS/smiley-2.svg'
import { ReactComponent as SmileyFive } from '../../assets/images/PIAS/smiley-13.svg'
import { ReactComponent as Help } from '../../assets/images/PIAS/help.svg'
import { ReactComponent as SmileyAngry } from '../../assets/images/PIAS/angry-smiley.svg'

const IconAngrySmiley = styled(SmileyAngry)`
  width: 45px;
  height: 45px;
`

const IconSmileyOne = styled(SmileyOne)`
  width: 45px;
  height: 45px;
  fill: red;
`
const IconSmileyTwo = styled(SmileyTwo)`
  width: 45px;
  height: 45px;
`
const IconSmileyThree = styled(SmileyThree)`
  width: 45px;
  height: 45px;
`
const IconSmileyFour = styled(SmileyFour)`
  width: 45px;
  height: 45px;
`
const IconSmileyFive = styled(SmileyFive)`
  width: 45px;
  height: 45px;
`

const LandingPage = {
  questions: [
    {
      id: 'Test-2',
      text: 'Choose your favorite color',
      type: multiSelect,
      choices: [
        { id: 'red', text: 'red', value: 'red' },
        { id: 'blue', text: 'blue', value: 'blue' },
        { id: 'green', text: 'green', value: 'green' },
        { id: 'pink', text: 'pink', value: 'pink' },
      ],
    },
    {
      id: 'survey-rating',
      useIcons: true,
      type: responsiveMatrix,
      text: 'Overall..',
      options: [
        { id: 1, text: 'Terrible', value: '1' },
        { id: 2, text: 'Not so great', value: '2' },
        { id: 3, text: 'It was okay', value: '3' },
        { id: 4, text: 'Good', value: '4' },
        { id: 5, text: 'Great', value: '5' },
      ],
      choices: [
        {
          id: 1,
          text: 'How enjoyable did you find the survey?',
          value: 'How enjoyable did you find the survey?',
        },
        {
          id: 2,
          text: 'How straightforward was it to complete',
          value: 'How straightforward was it to complete',
        },
      ],
      columns: [
        {
          id: 1,
          value: '1',
          icon: <IconAngrySmiley />,
        },
        {
          id: 2,
          value: '2',
          icon: <IconSmileyTwo />,
        },
        {
          id: 3,
          value: '3',
          icon: <IconSmileyThree />,
        },
        { id: 4, value: '4', icon: <IconSmileyFour /> },
        { id: 5, value: '5', icon: <IconSmileyFive /> },
      ],
      rows: [
        {
          id: 1,
          text: 'How enjoyable did you find the survey?',
          value: 'How enjoyable did you find the survey?',
        },
        {
          id: 2,
          text: 'How straightforward was it to complete',
          value: 'How straightforward was it to complete',
        },
      ],
    },
    {
      id: 'Test-matrix',
      type: welcomePage,
      text: 'Aggregate results',
      output: {
        type: 'bar-chart',
        xLabel: 'Option',
        yLabel: 'Count',
        targetQuestionId: 'survey-rating',
        nestedQuestionId: 'How enjoyable did you find the survey?',
        smart: true,
        surveyName: 'landing-page-test',
      },
    },
    {
      id: 'Results',
      type: welcomePage,
      text: 'Aggregate results',
      output: {
        type: 'bar-chart',
        xLabel: 'Option',
        yLabel: 'Count',
        targetQuestionId: 'Test-2',
        surveyName: 'landing-page-test',
      },
    },
    // {
    //   id: 'Results-2',
    //   type: welcomePage,
    //   text: 'Aggregate results',
    //   output: {
    //     type: 'doughnut-chart',
    //     xLabel: 'Option',
    //     yLabel: 'Count',
    //     questionId: 'Test',
    //     surveyName: 'landing-page-test',
    //   },
    // },
    // {
    //   id: 'wordCloud-test',
    //   type: singleTextbox,
    //   inputType: 'text',
    //   placeholder: '',
    //   text: 'Random text',
    // },
    // {
    //   id: 'NewQuestion',
    //   type: singleTextbox,
    //   inputType: 'text',
    //   placeholder: '',
    //   text: 'Random text',
    // },
  ],
  settings: {
    surveyName: 'landing-page-test',
    primaryColour: '#48a54c',
    otherColour: '#72a174',
    hideSubmit: true,
  },
}

export default LandingPage

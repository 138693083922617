import React from 'react'
import 'antd/dist/antd.css'
import { List, Radio } from 'antd'
import _ from 'lodash'

const RadioList = ({ answers, question, choices, updateCurrent, currentAnswer }) => {
  const handleChange = () => {}
  return (
    <List
      style={{ backgroundColor: 'white' }}
      itemLayout="horizontal"
      dataSource={choices}
      onChange={handleChange}
      renderItem={item => (
        <List.Item
          style={{ paddingLeft: 20 }}
          actions={[
            <Radio.Group buttonStyle="solid">
              {item.answerChoices.map(choice => (
                <Radio.Button
                  key={choice.id}
                  value={choice.value}
                  checked={
                    item.id in currentAnswer && currentAnswer[item.id].value === choice.value
                  }
                  onChange={e => {
                    const answer = {
                      id: item.id,
                      text: item.text,
                      value: e.target.value,
                      valueId: item.id,
                    }
                    updateCurrent({
                      ...currentAnswer,
                      [item.id]: answer,
                    })
                  }}
                >
                  {choice.value}
                </Radio.Button>
              ))}
            </Radio.Group>,
          ]}
        >
          <List.Item.Meta description={item.text} />
        </List.Item>
      )}
    />
  )
}

export default RadioList

import React from 'react'
import PropTypes from 'prop-types'
import ReactWordcloud from 'react-wordcloud'

import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'

const WordCloud = ({ words, options }) => {
  return <ReactWordcloud words={words} options={options} />
}

WordCloud.propTypes = {
  words: PropTypes.instanceOf(Array),
  options: PropTypes.instanceOf(Object),
}

export default WordCloud

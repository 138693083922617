import React from 'react'
import { useMediaQuery } from 'beautiful-react-hooks'
import PropTypes from 'prop-types'
import Matrix from './Matrix'
import MultiDropdown from './MultiDropdown'
import styled from 'styled-components/macro'

const ResponsiveMultiCheckBox = ({
  answers,
  question,
  currentAnswer,
  updateCurrent,
  submitAnswer,
  injectCurrent,
  dynamicColour,
  dynamicContrast,
  settings,
  loopIndex,
}) => {
  const mobileMultiCheckBox = useMediaQuery('(max-width: 768px)') // small and medium devices
  const desktopMultiCheckBox = useMediaQuery('(min-width: 769px)') // large and extra large devices

  const questionProps = {
    currentAnswer: currentAnswer,
    choices: question.choices,
    options: question.options,
    question,
    answers,
    updateCurrent,
    submitAnswer,
    settings,
    loopIndex,
    injectCurrent,
    dynamicColour,
    dynamicContrast,
  }
  return (
    <Container>
      {mobileMultiCheckBox ? <MultiDropdown {...questionProps} /> : <Matrix {...questionProps} />}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
`

ResponsiveMultiCheckBox.propTypes = {
  answers: PropTypes.instanceOf(Object),
  question: PropTypes.instanceOf(Object).isRequired,
  currentAnswer: PropTypes.instanceOf(Object).isRequired,
  updateCurrent: PropTypes.func.isRequired,
  submitAnswer: PropTypes.func.isRequired,
}

export default ResponsiveMultiCheckBox

const companies = [
  { value: 'Abalone Divers of New Zealand Company Limited' },
  { value: 'Abattoirs Association' },
  { value: 'Active VMA' },
  { value: 'Advance Marketing' },
  { value: 'AFFCO' },
  { value: 'AFFCO NZ Ltd' },
  { value: 'AgDesign Ltd' },
  { value: 'AgFirst' },
  { value: 'AgKnowledge' },
  { value: 'AgResearch' },
  { value: "Agri-Women's Development Trust" },
  { value: 'Agricultural Business Associates' },
  { value: 'Agrifeeds' },
  { value: 'AgriMagic' },
  { value: 'AgSafe' },
  { value: 'AgVice Ltd' },
  { value: 'Alfa Laval' },
  { value: 'Allan Agricultural Consulting Ltd' },
  { value: 'Alliance' },
  { value: 'Alliance Group Ltd' },
  { value: 'Ample Group Ltd' },
  { value: 'Analytica' },
  { value: 'Anexa FVC Vet Services' },
  { value: 'ANZ' },
  { value: 'ANZCO' },
  { value: 'ANZCO Foods Ltd' },
  { value: 'AON' },
  { value: 'AON New Zealand Ltd' },
  { value: 'APAC' },
  { value: 'Apata' },
  { value: 'Apata Group Ltd' },
  { value: 'Aquaculture New Zealand' },
  { value: 'Arrow Commodities (NZ) Ltd' },
  { value: 'ASB' },
  { value: 'Auckland Meat Processors Ltd' },
  { value: 'AusPac Ingredients Pty Ltd' },
  { value: 'Avo Alderton' },
  { value: 'Avo ject' },
  { value: 'AVOCO' },
  { value: 'Bakels Edible Oils' },
  { value: 'BakerAg' },
  { value: 'Bakerag (Consultants)' },
  { value: 'Ballance' },
  { value: 'Ballande New Zealand Ltd' },
  { value: 'Bay packers NZ Ltd' },
  { value: 'BDO' },
  { value: 'Bear cat' },
  { value: 'Beef + Lamb New Zealand Inc' },
  { value: 'Beef + Lamb New Zealand Ltd' },
  { value: 'Beef and Lamb NZ' },
  { value: 'Beef and Lamb NZ Genetics' },
  { value: 'Beef SolutionNZ' },
  { value: 'Beef.org.nz' },
  { value: 'Bell Global' },
  { value: 'Blue Sky Meats NZ Ltd' },
  { value: 'BODCO' },
  { value: 'Brendan Attrill Agriculture Limited' },
  { value: 'Brent Consulting' },
  { value: 'Brichwood' },
  { value: 'Burton Geoff Farm Consultant' },
  { value: 'BX Foods' },
  { value: 'c3' },
  { value: 'Canterbury Seafoods' },
  { value: 'Chem jet' },
  { value: 'Claymark ltd' },
  { value: 'CLELANDS Timber ltd' },
  { value: 'Compass Agribusiness Management' },
  { value: 'Crusader Meats New Zealand Ltd' },
  { value: 'CRV' },
  { value: 'Dairy Companies Association of New Zealand' },
  { value: 'Dairy Cooling Solutions' },
  { value: 'Dairy Green Ltd' },
  { value: 'Dairy Solutions Ltd' },
  { value: 'Dairy Training Ltd' },
  { value: "Dairy Women's Network" },
  { value: 'DairyNZ' },
  { value: 'Dawson John - Consulting Ltd' },
  { value: 'Deep water group' },
  { value: 'Deer Industry New Zealand' },
  { value: 'DeLaval' },
  { value: 'Deloitte' },
  { value: 'DMS' },
  { value: 'dns Forest Services' },
  { value: 'Don Chapman Waikato LTD' },
  { value: 'Eastern fishing' },
  { value: 'Eastpak' },
  { value: 'Ecolab' },
  { value: 'Eel encancement co' },
  { value: 'Enslow onw ltd' },
  { value: 'Environment Southland Regional Council' },
  { value: 'ETC' },
  { value: 'EveryCow' },
  { value: 'Farm Advisors NZ Ltd' },
  { value: 'Farm Medix' },
  { value: 'Farm Source' },
  { value: 'Farm Succession Planning Co Ltd' },
  { value: 'Farm Venture' },
  { value: 'FARMAX' },
  { value: 'FarmIQ' },
  { value: 'Farmlands' },
  { value: 'FarmWise Consultants/LIC' },
  { value: 'Federated Farmers' },
  { value: 'Fergusson Lockwood & Assoc Ltd' },
  { value: 'Fertiliser Association on New Zealand' },
  { value: 'Fidus management group' },
  { value: 'Findlay & Co' },
  { value: 'FINNZ' },
  { value: 'Fish & Game' },
  { value: 'Fish serve' },
  { value: 'Fisheries inshore' },
  { value: 'FMG' },
  { value: 'Fonterra' },
  { value: 'Forest & Bird' },
  { value: 'Foundation for Arable Research' },
  { value: 'Franklin vets' },
  { value: 'Fruition Horticulture' },
  { value: 'Gallagher' },
  { value: 'Genetic Enterprises' },
  { value: 'GHA' },
  { value: 'GMS' },
  { value: 'Good Earth Matters' },
  { value: 'Gough Agritech' },
  { value: 'Greenlea' },
  { value: 'Greenlea Premier Meats Ltd' },
  { value: 'Greenpeace' },
  { value: 'GrowingNZ' },
  { value: 'Gypsum' },
  { value: 'Headlands / Intelact' },
  { value: 'Heartland Bank' },
  { value: 'Highford' },
  { value: 'Horticentre' },
  { value: 'HortNZ' },
  { value: 'Hortwatch' },
  { value: 'Hoticulture New Zealand' },
  { value: 'Hume' },
  { value: 'Independent fisheries' },
  { value: 'Integrity Soils' },
  { value: 'Irrigation Express' },
  { value: 'IrrigationNZ' },
  { value: 'John Deere' },
  { value: 'Joma wool' },
  { value: 'JP exports' },
  { value: 'JSWAP' },
  { value: 'Just avocados' },
  { value: 'Kapag' },
  { value: 'Kauripak' },
  { value: 'Key Industries' },
  { value: 'Kina industry council' },
  { value: 'Kintyre Meats' },
  { value: 'Kiwi fertiliser' },
  { value: 'Kiwifruit New Zealand' },
  { value: 'Kiwifruit vine health' },
  { value: 'KiwiLumber' },
  { value: 'KPMG' },
  { value: 'Landcare Trust' },
  { value: 'Landpro Ltd' },
  { value: 'LandVision Limited' },
  { value: 'Lee fish' },
  { value: 'LIC' },
  { value: 'Lindsay Young Farm Consultant' },
  { value: 'LinkSafe' },
  { value: 'Livestock feed systems' },
  { value: 'Lynwood' },
  { value: 'Mace Murray' },
  { value: 'Macfarlane Rural Business Ltd' },
  { value: 'Maori kiwifruit' },
  { value: 'Marine foods limited' },
  { value: 'Maritime NZ' },
  { value: 'Massey University Farm Services Clinic' },
  { value: 'MBS Advisors' },
  { value: 'McAlpines' },
  { value: 'Meat Industry Association of New Zealand Inc (MIA)' },
  { value: 'MILKIO' },
  { value: 'Ministry for the Environment' },
  { value: 'Moana NZ' },
  { value: 'MPAC' },
  { value: 'MPI' },
  { value: 'Murray Jamieson' },
  { value: 'N.Z. Future Forest Products Ltd' },
  { value: 'New Holland Agriculture' },
  { value: 'New Zealand Apples and Pears' },
  { value: 'New Zealand Association of Chartered accountants' },
  { value: 'New Zealand Carbon Farming' },
  { value: 'New Zealand coastal seafoods' },
  { value: 'NEW ZEALAND FISHING INDUSTRY GUILD' },
  { value: 'New Zealand Food Safety and Science Research Centre' },
  { value: 'New Zealand Forest Owners Association' },
  { value: 'New Zealand Institute of Primary Industry Management' },
  { value: 'New Zealand Meat Board' },
  { value: 'New Zealand Pork' },
  { value: 'New Zealand Vetinary Association' },
  { value: 'Ngai Tahu' },
  { value: 'Niagara' },
  { value: 'NIWA' },
  { value: 'Norwood' },
  { value: 'NRM' },
  { value: 'Nutrinza' },
  { value: 'NZ Avocado Growers Association Inc. (NZAGA)' },
  { value: 'NZ Avocado Industry Limited' },
  { value: 'NZ Bioforestry ltd' },
  { value: 'NZ fishing Fed' },
  { value: 'NZ Landcare Trust' },
  { value: 'NZ Rock Lobster Industry Council' },
  { value: 'Nz Wool Services' },
  { value: 'NZ Young Farmers' },
  { value: 'NZKGI' },
  { value: 'NZQA' },
  { value: 'Ocean Law' },
  { value: 'OHTL' },
  { value: 'OPAC' },
  { value: 'Open Country' },
  { value: 'Opihi nurseries' },
  { value: 'Orangewood' },
  { value: 'Organics Aotearoa New Zealand' },
  { value: 'OSPRI' },
  { value: 'Ovation' },
  { value: 'Ovation New Zealand Ltd' },
  { value: 'Overseer' },
  { value: 'Ovis Management Ltd' },
  { value: 'Pan Pac forest products ltd' },
  { value: 'Parry Matthews & Associates Ltd' },
  { value: 'Perrin Ag' },
  { value: 'PGG Wrightsons' },
  { value: 'Pine sawmills New Zealand ltd' },
  { value: 'Pinepac' },
  { value: 'Pioneer' },
  { value: 'Plant and food' },
  { value: 'Plant and Food Research' },
  { value: 'Primacy' },
  { value: 'Primary Industry Capability Alliance' },
  { value: 'Primary ITO' },
  { value: 'Prime Range Meats Ltd' },
  { value: 'Process vegetables' },
  { value: 'Produco' },
  { value: 'Punch bowl' },
  { value: 'PwC' },
  { value: 'Rabobank' },
  { value: 'Ravensdown (Env)' },
  { value: 'Red meat association' },
  { value: 'Red Meat Profit Partnership' },
  { value: 'Retail Meat New Zealand' },
  { value: 'Riverlock' },
  { value: 'Roy Johnson - R J Rural' },
  { value: 'Rural support trust' },
  { value: 'Rural Women New Zealand' },
  { value: 'SAFE' },
  { value: 'Saiato' },
  { value: 'Samen' },
  { value: 'Sanford' },
  { value: 'Sanfords' },
  { value: 'Seafood innovations' },
  { value: 'Seafood New Zealand' },
  { value: 'Sealord' },
  { value: 'Sealords' },
  { value: 'Seeka' },
  { value: 'Semex' },
  { value: 'Sheppard Agriculture' },
  { value: 'Silver Fern Farms' },
  { value: 'Society of Sheep and Beef Cattle Veterinarians' },
  { value: 'Solander' },
  { value: 'South Pacific Meats' },
  { value: 'South pine' },
  { value: 'Southern inshore fisheries management company LTD' },
  { value: 'Southern produce' },
  { value: 'SRS' },
  { value: 'Synlait' },
  { value: "Talley's" },
  { value: 'Target Focus Ltd' },
  { value: 'TasmanCrop' },
  { value: 'Tatua' },
  { value: 'Te Ohu Kaimoana' },
  { value: 'Te Wai Maori' },
  { value: 'TEC' },
  { value: 'The Agribusiness Group' },
  { value: 'The Cow Vets' },
  { value: 'The Federated Farmers Meat & Wool Industry Group' },
  { value: 'The Federated Farmers Rural Butchers Industry Group' },
  { value: 'The Paua Industry Council' },
  { value: 'The Poultry Industry Association of New Zealand' },
  { value: 'The village press' },
  { value: 'Total Ag' },
  { value: 'Total Ag Ltd' },
  { value: "Trevelyan's" },
  { value: "Trevelyan's Pack and cool" },
  { value: 'University of Waikato' },
  { value: 'Vela fishing' },
  { value: 'Vet Ent' },
  { value: 'Vetora' },
  { value: 'Waikato Institute of Technology (WINTEC)' },
  { value: 'Waikato Milking Systems' },
  { value: 'Waikato Regional Council' },
  { value: 'Waikawa' },
  { value: 'Waterforce Ltd' },
  { value: 'Were & Associates' },
  { value: 'Westco lumber limited' },
  { value: 'Westpac' },
  { value: 'Winston Stockfeed' },
  { value: 'Woodmarketing services ltd' },
  { value: 'Woodmetrics' },
  { value: 'Worksafe NZ' },
  { value: 'WPI International' },
  { value: 'WSP' },
  { value: 'Zeafruit' },
  { value: 'Zespri' },
]

export default companies

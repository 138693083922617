import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import '../../css/progress-bar.css'
/**
 * Survey progress
 * @param {*} props
 */
const ProgressBar = ({ percentage, status, settings }) => {
  // const { percentage, status } = props
  return (
    <Container>
      <StatusText>
        <p>progress</p>
        <p>{status}</p>
      </StatusText>
      <div className="progress-bar">
        <Filler percentage={percentage} />
      </div>
    </Container>
  )
}

const Filler = ({ percentage, colour }) => {
  // eslint-disable-next-line react/prop-types
  // const { percentage, colour } = props
  return <div className="filler" colour={colour} style={{ width: `${percentage}%` }} />
}

const Container = styled.div`
  @media (min-width: 0px) and (max-width: 479px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`

const StatusText = styled.div`
  display: flex;
  justify-content: space-between;
  width: 350px;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  .filler {
    background: ${props => (props.color ? props.color : '#E82D7C')};
  }
  p {
    color: #a7a7a7;
  }

  @media (min-width: 0px) and (max-width: 479px) {
    width: 90%;
    justify-content: space-space-between;
    .progress-bar {
      display: flex;
      width: 100%;
    }
  }
`

ProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
}
export default ProgressBar

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components/macro'
import { Tooltip, message } from 'antd'
import { InformationCircle } from '@styled-icons/ionicons-outline/InformationCircle'
import InputField from './InputField'
import Button from '../ui/Buttons/StandardButton'
import ButtonContainer from '../ui/Layout/ButtonContainer'
import createRipple from '../ui/Animations/ripple'

/**
 * Takes an array of objects to render choices,
 * Only accepts 1 answer,
 * choices may also be used to render dynamic choices
 * @param {*} param
 */
const MultiSelect = ({
  choices,
  currentAnswer,
  updateCurrent,
  submitAnswer,
  answers,
  settings,
  loopIndex,
  question,
}) => {
  const [dynamicChoices, setDynamicChoices] = useState(
    _.isFunction(choices) ? choices({ answers, loopIndex }) : choices,
  )
  const { id, value } = currentAnswer
  const { primaryColour, contrastColour } = settings
  const dynamicColour = !_.isUndefined(primaryColour) ? primaryColour : '#E82D7C'
  const dynamicContrast = !_.isUndefined(contrastColour) ? contrastColour : '#fff'

  useEffect(() => {
    if (question.asyncChoices) {
      question.asyncChoices().then(choices => {
        setDynamicChoices(choices)
      })
    }
  })

  const error = () => {
    message.config({
      duration: 4,
      maxCount: 2,
    })
    message.error(
      `Oops, looks like you have tried to select more than ${question.max} answers. Clicking a selected answer again removes it from selection.`,
    )
  }

  const handleClick = questionChoice => {
    const answer = {
      id: questionChoice.id,
      text: questionChoice.text,
      value: questionChoice.value,
    }
    const newAnswers = {
      ...currentAnswer,
    }

    if (!currentAnswer.value) {
      newAnswers.value = [] // init empty array
    }

    const filterAnswer = answerArray => {
      answerArray.value = answerArray.value.filter(
        clickedItem => clickedItem.value !== answer.value,
      )
    }

    const addAnswer = answerArray => {
      answerArray.value = [...answerArray.value, { id: answer.id, value: answer.value }]
    }

    if (questionChoice.submit && !(questionChoice.id in newAnswers)) {
      newAnswers.value = [{ id: answer.id, value: answer.value, submit: true }] // add additional prop to object
      updateCurrent(newAnswers)
      submitAnswer()
    } else {
      if (newAnswers.value.some(item => item.value === answer.value)) {
        // remove clicked answer on second click
        filterAnswer(newAnswers)
      } else if (!question.max || (question.max && newAnswers.value.length !== question.max)) {
        newAnswers.value = newAnswers.value.filter(item => item.submit === answer.submit) // remove answer that has submit prop and add new answer
        addAnswer(newAnswers)
      }
      if (
        // Do not add anymore answers if max limit is reached
        question.max &&
        question.max === newAnswers.value.length &&
        !newAnswers.value.some(item => item.value === answer.value)
      ) {
        error()
      }
      updateCurrent(newAnswers)
    }
  }

  const handleTextInput = (element, questionChoice) => {
    const answer = {
      id: questionChoice.id,
      text: questionChoice.text,
      value: element.target.value,
    }
    const newAnswer = {
      ...currentAnswer,
    }
    if (!currentAnswer.value) {
      newAnswer.value = [] // init empty array
    }
    if (question.max && question.max === newAnswer.value.length) {
      console.log('error')
      // set a limit on how many error notifications can appear
      error()
    } else if (answer.value === '') {
      newAnswer.value = newAnswer.value.filter(item => item.id !== answer.id)
      updateCurrent(newAnswer)
    } else {
      newAnswer.value = [
        ...newAnswer.value.filter(item => item.id !== answer.id),
        {
          id: answer.id,
          value: `${answer.text}: ${answer.value}`,
          rawValue: answer.value,
        },
      ]
      updateCurrent(newAnswer)
    }
  }

  return (
    <ButtonContainer
      dynamicChoices={dynamicChoices}
      gridDisplay={question.verticalGrid}
      size={question.buttonSize}
    >
      {dynamicChoices.map(questionChoice => {
        if (questionChoice.type === 'input') {
          return (
            <InputField
              key={questionChoice.id}
              active={questionChoice.id === id}
              settings={settings}
              inputValue={
                currentAnswer.value &&
                currentAnswer.value.find(answer => answer.id === questionChoice.id) &&
                currentAnswer.value.find(answer => answer.id === questionChoice.id).rawValue
              }
              updateInputValue={e => handleTextInput(e, questionChoice)}
              label={questionChoice.text}
              size={question.buttonSize}
              marginBottom={questionChoice.marginBottom}
            />
          )
        }
        return (
          <Button
            active={
              currentAnswer.value &&
              currentAnswer.value.some(item => item.value === questionChoice.value)
            }
            key={questionChoice.id}
            dynamicChoices={dynamicChoices}
            dynamicColour={dynamicColour}
            dynamicContrast={dynamicContrast}
            mobileProps={question.mobileProps}
            gridDisplay={question.verticalGrid}
            mobileHeight={question.mobileHeight}
            mobilePadding={question.mobilePadding}
            questionType={question.type}
            buttonText={questionChoice.html ? questionChoice.html : questionChoice.text}
            style={{
              flex: question.flex,
              height: question.height,
              width: questionChoice.width,
              padding: question.padding,
            }}
            size={question.buttonSize}
            onClick={event => {
              handleClick(questionChoice)
              createRipple(event)
            }}
          />
        )
      })}
    </ButtonContainer>
  )
}

MultiSelect.propTypes = {
  choices: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.func]),
  currentAnswer: PropTypes.instanceOf(Object).isRequired,
  updateCurrent: PropTypes.func.isRequired,
  submitAnswer: PropTypes.func.isRequired,
  question: PropTypes.instanceOf(Object),
  answers: PropTypes.instanceOf(Object).isRequired,
  settings: PropTypes.instanceOf(Object),
  loopIndex: PropTypes.oneOfType([
    test => {
      return test === null
    },
    PropTypes.number,
  ]),
}

export default MultiSelect

import React from 'react'
import styled from 'styled-components/macro'

const OutdatedBrowser = () => {
  return (
    <Container>
      <svg
        width="546"
        height="315"
        viewBox="0 0 546 315"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="545" height="314" fill="white" stroke="black" />
        <rect x="0.5" y="0.5" width="545" height="37.0625" fill="#F3F3F3" stroke="black" />
        <ellipse cx="217.872" cy="169.229" rx="10.8716" ry="11.2292" fill="#4F4D48" />
        <ellipse cx="327.623" cy="169.229" rx="10.8716" ry="11.2292" fill="#4F4D48" />
        <path
          d="M252.363 207.729C250.183 207.729 248.365 205.924 249.013 203.842C249.318 202.865 249.737 201.906 250.268 200.976C251.491 198.836 253.283 196.89 255.542 195.252C257.802 193.613 260.484 192.313 263.436 191.427C266.388 190.54 269.552 190.083 272.747 190.083C275.943 190.083 279.107 190.54 282.059 191.427C285.011 192.313 287.693 193.613 289.953 195.252C292.212 196.89 294.004 198.836 295.227 200.976C295.758 201.906 296.177 202.865 296.481 203.842C297.13 205.924 295.312 207.729 293.131 207.729V207.729C290.951 207.729 289.239 205.886 288.317 203.91C288.2 203.66 288.072 203.412 287.932 203.168C287.106 201.722 285.896 200.407 284.369 199.301C282.843 198.194 281.031 197.316 279.037 196.717C277.043 196.118 274.906 195.809 272.747 195.809C270.589 195.809 268.452 196.118 266.458 196.717C264.463 197.316 262.652 198.194 261.125 199.301C259.599 200.407 258.388 201.722 257.562 203.168C257.423 203.412 257.294 203.66 257.178 203.91C256.256 205.886 254.544 207.729 252.363 207.729V207.729Z"
          fill="#4F4D48"
        />
        <ellipse cx="527.117" cy="19.0312" rx="10.2375" ry="9.84375" fill="white" />
        <rect
          width="2.23168"
          height="13.3901"
          transform="matrix(0.720833 -0.693109 0.720833 0.693109 521.43 15.3125)"
          fill="#4F4D48"
        />
        <rect
          width="2.23168"
          height="13.3901"
          transform="matrix(-0.720833 -0.693109 -0.720833 0.693109 532.805 15.1093)"
          fill="#4F4D48"
        />
        <ellipse cx="466.148" cy="19.0312" rx="10.2375" ry="9.84375" fill="white" />
        <rect
          width="2.1875"
          height="13.65"
          transform="matrix(0 -1 -1 0 473.2 20.125)"
          fill="#4F4D48"
        />
        <ellipse cx="496.633" cy="19.0312" rx="10.2375" ry="9.84375" fill="white" />
        <rect
          x="494.814"
          y="14.25"
          width="7.25568"
          height="6.42708"
          fill="white"
          stroke="black"
          stroke-width="0.5"
        />
        <rect
          x="491.25"
          y="16.375"
          width="7.25568"
          height="6.42708"
          fill="white"
          stroke="black"
          stroke-width="0.5"
        />
      </svg>

      <h1>Unsupported Browser</h1>
      <p>Your web browser appears to be outdated. Our website may not look quite right.</p>
      <p>Please consider updating your browser to enjoy an optimal experience.</p>
      <Row>
        <BrowerLinks
          href="https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=en-GB"
          target="_blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-brand-chrome"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#2c3e50"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="12" cy="12" r="9" />
            <circle cx="12" cy="12" r="3" />
            <line x1="12" y1="9" x2="20.4" y2="9" />
            <line x1="12" y1="9" x2="20.4" y2="9" transform="rotate(120 12 12)" />
            <line x1="12" y1="9" x2="20.4" y2="9" transform="rotate(240 12 12)" />
          </svg>
          Chrome
        </BrowerLinks>
        <BrowerLinks href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-brand-firefox"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#2c3e50"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4.028 7.82a9 9 0 1 0 12.823 -3.4c-1.636 -1.02 -3.064 -1.02 -4.851 -1.02h-1.647" />
            <path d="M4.914 9.485c-1.756 -1.569 -.805 -5.38 .109 -6.17c.086 .896 .585 1.208 1.111 1.685c.88 -.275 1.313 -.282 1.867 0c.82 -.91 1.694 -2.354 2.628 -2.093c-1.082 1.741 -.07 3.733 1.371 4.173c-.17 .975 -1.484 1.913 -2.76 2.686c-1.296 .938 -.722 1.85 0 2.234c.949 .506 3.611 -.995 4.545 .354c-1.698 .102 -1.536 3.107 -3.983 2.727c2.523 .957 4.345 .462 5.458 -.34c1.965 -1.52 2.879 -3.542 2.879 -5.557c-.014 -1.398 .194 -2.695 -1.26 -4.75" />
          </svg>
          Firefox
        </BrowerLinks>
        <BrowerLinks href="https://www.microsoft.com/en-us/edge" target="_blank">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-brand-edge"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#2c3e50"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M20.978 11.372a9 9 0 1 0 -1.593 5.773" />
            <path d="M20.978 11.372c.21 2.993 -5.034 2.413 -6.913 1.486c1.392 -1.6 .402 -4.038 -2.274 -3.851c-1.745 .122 -2.927 1.157 -2.784 3.202c.28 3.99 4.444 6.205 10.36 4.79" />
            <path d="M3.022 12.628c-.283 -4.043 8.717 -7.228 11.248 -2.688" />
            <path d="M12.628 20.978c-2.993 .21 -5.162 -4.725 -3.567 -9.748" />
          </svg>
          Edge
        </BrowerLinks>
      </Row>
    </Container>
  )
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  svg {
    margin-top: 1rem;
  }
`

const BrowerLinks = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  width: 15rem;
  align-items: center;
  justify-content: space-between;
`

export default OutdatedBrowser

import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'
import React from 'react'
import PropTypes from 'prop-types'
import { Slider } from 'antd'
import styled from 'styled-components/macro'
import _ from 'lodash'

/**
 * Slider component
 * @param {*} param
 */
const SliderRating = ({
  labelsArray,
  currentAnswer,
  updateCurrent,
  question,
  dynamicColour,
  dynamicContrast,
}) => {
  let marks = _.keyBy(labelsArray, 'text')
  let marksv2 = _.mapValues(_.keyBy(labelsArray, 'text'))
  // console.log('dynamicColour', dynamicColor)
  console.log('marks', marks)
  console.log('marksv2', marksv2)
  console.log('marksv3', _.pick(marksv2, ['text']))

  function onChange(value) {
    console.log('onChange: ', value)
  }

  function onAfterChange(value) {
    console.log('onAfterChange: ', value)
  }

  return (
    <SliderContainer>
      <LabelContainer>
        {!_.isUndefined(question.marks)
          ? question.marks.map(label => {
              return <p key={label.id}>{label.text}</p>
            })
          : null}
      </LabelContainer>
      <Row>
        <Slider
          min={question.choices[0]}
          max={question.max ? question.max : question.choices[question.choices.length]}
          value={currentAnswer && currentAnswer.value ? currentAnswer.value : question.default}
          step={question.step ? question.step : 1}
          defaultValue={
            currentAnswer && currentAnswer.value ? currentAnswer.value : question.default
          }
          onChange={e => {
            console.log('e', e)
            const answer = {
              value: e,
            }
            updateCurrent({
              value: answer.value,
            })
          }}
        />
        {question.showValue !== undefined ? (
          <h1>{currentAnswer && currentAnswer.value ? currentAnswer.value : question.default}</h1>
        ) : null}
        {question.usePercent !== undefined ? <h1>%</h1> : null}
      </Row>
    </SliderContainer>
  )
}

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  p {
    max-width: 11rem;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    /* small viewport  */
    p {
      max-width: 5rem;
      margin-bottom: 0rem;
    }
  }
  @media (min-width: 480px) and (max-width: 768px) {
    /* medium viewport  */
    p {
      max-width: 5rem;
      margin-bottom: 0rem;
    }
  }
`

const Row = styled.div`
  margin: auto;
  display: flex;
  width: 49rem;
  align-items: center;
  justify-content: space-between;
  .ant-slider {
    width: 90%;
    margin: auto;
  }

  @media (min-width: 0px) and (max-width: 479px) {
    /* small viewport  */
    width: 80vw;
    margin-top: 1rem;
    h1 {
      margin-left: 1rem;
    }
  }
  @media (min-width: 480px) and (max-width: 768px) {
    /* medium viewport  */
    width: 80vw;
    h1 {
      margin-left: 1rem;
    }
  }
  @media (min-width: 769px) and (max-width: 1025px) {
    width: 80vw;
  }
`

const SliderContainer = styled.div`
  width: 49rem;
  margin-top: 2rem;
  min-height: 5rem;

  @media (min-width: 0px) and (max-width: 479px) {
    /* small viewport  */
    width: 80vw;
  }
  @media (min-width: 480px) and (max-width: 768px) {
    /* medium viewport  */
    width: 80vw;
  }
  @media (min-width: 769px) and (max-width: 1025px) {
    width: 80vw;
  }
`

SliderRating.propTypes = {
  labelsArray: PropTypes.instanceOf(Object).isRequired,
  currentAnswer: PropTypes.instanceOf(Object).isRequired,
  updateCurrent: PropTypes.func.isRequired,
  showValue: PropTypes.bool,
  question: PropTypes.instanceOf(Object),
}

export default SliderRating

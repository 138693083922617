import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import {
  multiChoice,
  welcomePage,
  responsiveMultiMap,
  responsiveMatrix,
  multiCardChoice,
  imageChoice,
  commentBox,
  multiSelect,
  input,
  endPage,
} from '../question-types/QuestionTypes'
import backgroundImage from '../../assets/images/PIAS/PIAS.jpg'
import backgroundVideo from '../../assets/images/HCF/pexel-video.mp4'
// import backgroundVideo from '../../assets/images/HCF/Pexel-Video.m4v'

import { ReactComponent as Male } from '../../assets/images/HCF/male-icon.svg'
import { ReactComponent as Female } from '../../assets/images/HCF/female-icon.svg'
import leftBackgroundImage from '../../assets/images/HCF/backgroundimage.jpg' // Todo compress image
import HCFLogo from '../../assets/images/HCF/test.svg'
import BeefLamb from '../../assets/images/HCF/beeflamb.jpg'
import MBIE from '../../assets/images/HCF/Slice 1.svg'
import { ReactComponent as Advisor } from '../../assets/images/PIAS/meeting-table.svg'
import { ReactComponent as Builder } from '../../assets/images/PIAS/builder.svg'
import { ReactComponent as Retailer } from '../../assets/images/PIAS/retail.svg'
import { ReactComponent as Researcher } from '../../assets/images/PIAS/research.svg'
import { ReactComponent as Educator } from '../../assets/images/PIAS/educator.svg'
import { ReactComponent as Developer } from '../../assets/images/PIAS/developer.svg'
import { ReactComponent as Farmer } from '../../assets/images/HCF/Farmer.svg'
import { ReactComponent as Professional } from '../../assets/images/HCF/Professional.svg'

import { Textbox } from '../../assets/styles/global'

const IconMale = styled(Male)`
  width: 45px;
  height: 80px;
`
const IconFemale = styled(Female)`
  width: 45px;
  height: 80px;
`

const IconFarmer = styled(Farmer)`
  width: 75px;
  height: 80px;
`

const IconProfessional = styled(Professional)`
  width: 45px;
  height: 80px;
`

const StyledP = styled.p`
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0rem;
  margin-top: 1rem;
`

const HeaderQ = styled.h1`
  font-size: 1rem;
  font-weight: bold;
  color: #48a54c;
`

const IconAdvisor = styled(Advisor)`
  width: 120px;
  height: 55px;
`

const IconBuilder = styled(Builder)`
  width: 120px;
  height: 55px;
`

const IconDeveloper = styled(Developer)`
  width: 120px;
  height: 55px;
`

const IconRetailer = styled(Retailer)`
  width: 120px;
  height: 55px;
`
const IconResearcher = styled(Researcher)`
  width: 120px;
  height: 55px;
`

const IconEducator = styled(Educator)`
  width: 120px;
  height: 55px;
`

const List = styled.ul`
  li {
    font-size: 13px;
    margin-top: 0.5rem;
  }
  width: 32%;
  padding: 25px;
  box-shadow: 0px 4px 6px rgba(143, 143, 143, 0.2);
  border-radius: 5px;
  margin-bottom: 0.5rem;
  background-color: white;
`

const ULContainer = styled.div`
  width: 780px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`

const E350Preworkshop = {
  questions: [
    {
      id: 'intro-page',
      type: welcomePage,
      text: (
        <Textbox>
          <StyledP>
            To help us prepare for the Evaluation Group December workshop we invite to you to spend
            a few minutes answering a couple of pre workshop questions. Your responses will help
            shape and inform the workshop sessions
          </StyledP>
        </Textbox>
      ),
    },
    {
      id: 'end-of-programme-report',
      type: commentBox,
      text: (
        <Textbox>
          <HeaderQ>End of programme report</HeaderQ>
          <StyledP style={{ fontWeight: 400 }}>
            As part of phase one, Scarlatti are working with stakeholders to clarify their reporting
            needs and expectations, along with deciding on the right structure and evaluation
            questions for the end of programme evaluation report (final report due 31 August 2022).
            This process aims to ensure the report will deliver on stakeholders’ desired outcomes,
            along with clarifying the analysis required and identifying any additional information /
            data that needs to be collected over the next two years.
          </StyledP>
          <StyledP style={{ fontWeight: 700 }}>
            What are your / your organisation’s needs and expectations of the end of programme
            evaluation report?
          </StyledP>
        </Textbox>
      ),
      placeholder: 'My thoughts are...',
      specialEffects: true,
    },
    {
      id: 'Cross-agency-collaboration',
      type: welcomePage,
      text: (
        <Textbox>
          <HeaderQ>Cross agency collaboration</HeaderQ>
          <StyledP>
            A desired outcome for the Extension 350 programme is to{' '}
            <b>increase cross agency collaboration amongst Extension 350 partners</b>. As part of
            Extension 350 evaluation activities, Scarlatti will work with stakeholders to design and
            implement a methodology that will enable us to evaluate how effectively the programme
            has achieved this desired outcome. The December 2020 evaluation group meeting provides
            an opportunity to test and refine the methodology for identifying the characteristics or
            indicators of successful cross agency collaboration and establishing retrospective
            baselines in these areas.
          </StyledP>
        </Textbox>
      ),
      placeholder: 'My thoughts are...',
      specialEffects: true,
    },
    {
      id: 'Cross-agency-collaboration-input',
      type: commentBox,
      text: (
        <Textbox>
          <StyledP style={{ fontWeight: 700 }}>
            What are the characteristics or indicators of successful cross agency collaboration?
          </StyledP>
          <StyledP style={{ fontStyle: 'italic', fontSize: '14px' }}>
            Please add to the following list and indicate any in the list that you don’t think are
            characteristics of cross agency collaboration.
          </StyledP>
          <ULContainer>
            <List>
              <li>Increased acceptance of different stakeholders’ objectives, opinions, etc.</li>
              <li>Increased commitment to collaboration</li>
              <li>Increased communication between organisations</li>
              <li>Increased creation of shared objectives </li>
            </List>
            <List>
              <li>Increased development of new collective knowledge</li>
              <li>Increased development of new collective knowledge</li>
              <li>Increased inter organisational networks</li>
              <li>Increased knowledge transfer between organisations</li>
            </List>
            <List>
              <li>
                Increased leveraging of stakeholder expertise and experience across organisations
              </li>
              <li>Increased sharing of resources between organisations</li>
              <li>Increased trust within the partnership</li>
            </List>
          </ULContainer>
        </Textbox>
      ),
      placeholder: 'My thoughts are...',
      specialEffects: true,
    },

    {
      id: 'survey-end',
      type: endPage,
      text: 'End of survey',
    },
  ],
  settings: {
    surveyName: 'E350-Preworkshop',
    hideSubmit: true,
    showQid: false,
    // logoFirstPageOnly: true,
    // companyLogo: {
    //   images: [{ image: HCFLogo }, { image: MBIE }, { image: BeefLamb }],
    // },
    useVideo: true,
    backgroundVideo,
    logoStyle: {
      marginTop: '1rem',
    },
    logoLocation: 'space-between',
    // fullLength: true,
    backgroundImage,
    // backgroundVideo,
    progressBar: true,
  },
}

export default E350Preworkshop

import React, { useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import 'antd/dist/antd.css'
import { Rate } from 'antd'

const StarRating = ({ question, updateCurrent, currentAnswer }) => {
  const labels = _.map(question.choices, 'text')
  const [value, setValue] = useState(1)

  // eslint-disable-next-line no-shadow
  const handleChange = value => {
    setValue(value)
    const answer = {
      id: question.id,
      text: labels[value - 1],
      value,
    }
    updateCurrent(answer)
  }
  return (
    <Container>
      <span>
        <Rate
          tooltips={labels}
          onChange={handleChange}
          value={currentAnswer.value ? currentAnswer.value : value}
        />
        {currentAnswer.value ? (
          <span className="ant-rate-text">{labels[currentAnswer.value - 1]}</span>
        ) : (
          <span className="ant-rate-text">{labels[value - 1]}</span>
        )}
      </span>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-rate-star {
    font-size: 3em;
  }
  .ant-rate-text {
    margin-left: 2rem;
  }
`

StarRating.propTypes = {
  question: PropTypes.instanceOf(Object),
  currentAnswer: PropTypes.instanceOf(Object).isRequired,
  updateCurrent: PropTypes.func.isRequired,
}

export default StarRating

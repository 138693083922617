import React from 'react'

/**
 * 404 when route does not exist
 * @param {*} props
 */
const ErrorPage = props => (
  <div>
    <p>Page Not found!!</p>
  </div>
)

export default ErrorPage
